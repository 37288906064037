// import { useShareDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useState } from 'react';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { useHandleOnFeature } from './useArtifactsOnFeature';
import { useHandleOnWelcome } from './useArtifactsOnWelcome';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Star,
  StarBorder,
  Delete as DeleteIcon,
  Undo as UndoIcon,
  ThumbUp as ThumbUpIcon,
  ThumbDown as ThumbDownIcon,
  ThumbDownOutlined as ThumbDownOutlinedIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  EmojiPeople,
  EmojiPeopleOutlined,
} from '@mui/icons-material';

export interface ArtifactsTableActionsProps {
  useForTable?: boolean;
  userId: number | undefined;
  dealRoomRole: string | undefined;
  artifact: DealRoomArtifact;
  organizationSlug: string;
  dealRoomId: number;
  refreshDealRoom: () => Promise<unknown>;
  refetchArtifacts: () => Promise<unknown>;
  onDelete: ((id: DealRoomArtifact['id']) => Promise<unknown>) | undefined;
}

export const ArtifactsTableActions: React.FC<ArtifactsTableActionsProps> = ({
  useForTable,
  userId,
  dealRoomRole,
  artifact,
  organizationSlug,
  dealRoomId,
  refreshDealRoom,
  refetchArtifacts,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { getAccessTokenSilently } = useAuth0();

  // const {
  //   createDeferred: createConfirmShareDeferred,
  //   dialog: confirmShareDialog,
  // } = useShareDealRoomArtifactDialog({
  //   organizationSlug: organizationSlug || '',
  //   artifactName: artifact?.name || '',
  // });

  const handleClickDelete = useCallback(
    async (
      e:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.KeyboardEvent<HTMLElement>
        | undefined,
      soft: boolean,
    ) => {
      e?.preventDefault();
      e?.stopPropagation();

      try {
        if (!artifact || userId !== artifact.creator?.id || !onDelete) {
          return;
        }
        const token = await getAccessTokenSilently();

        // DELETE ITEM
        await toast.promise(
          DealRoomsApiClient.deleteArtifact(
            {
              organizationSlug,
              dealRoomId,
              artifactId: artifact.id,
              soft: soft,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Deleting Artifact',
            success: (_response) => {
              refetchArtifacts();
              onDelete(artifact.id);
              if (soft) {
                return 'Successfully deleted Artifact.';
              } else {
                return 'Successfully removed Artifact from Trash.';
              }
            },
            error: (err: unknown) => {
              return 'Something went wrong deleting the artifact, please try again later.';
            },
          },
        );
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [
      organizationSlug,
      dealRoomId,
      artifact,
      onDelete,
      getAccessTokenSilently,
      userId,
      refetchArtifacts,
    ],
  );

  const handleClickRestore = useCallback(
    async (
      e:
        | React.MouseEvent<HTMLElement, MouseEvent>
        | React.KeyboardEvent<HTMLElement>
        | undefined,
    ) => {
      e?.preventDefault();
      e?.stopPropagation();
      try {
        if (!artifact || !onDelete) {
          return;
        }
        const token = await getAccessTokenSilently();

        // RESTORE ITEM
        await toast.promise(
          DealRoomsApiClient.undeleteArtifact(
            organizationSlug!,
            dealRoomId,
            artifact.id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Restoring Artifact',
            success: (_response) => {
              refetchArtifacts();
              onDelete(artifact.id);
              return 'Successfully restored Artifact.';
            },
            error: (err: unknown) => {
              return 'Something went wrong restoring Artifact, please try again later';
            },
          },
        );
      } catch (err: unknown) {
        console.error(err);
      }
    },
    [
      organizationSlug,
      getAccessTokenSilently,
      dealRoomId,
      artifact,
      onDelete,
      refetchArtifacts,
    ],
  );

  const handleUpvote = async (
    e:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
      | undefined,
  ) => {
    e?.preventDefault();
    e?.stopPropagation();
    try {
      if (!artifact) {
        return;
      }
      const token = await getAccessTokenSilently();
      await toast.promise(
        !artifact.upvoted
          ? DealRoomsApiClient.upvoteArtifact(
              organizationSlug!,
              dealRoomId,
              artifact.id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            )
          : DealRoomsApiClient.removeArtifactUpvote(
              organizationSlug!,
              dealRoomId,
              artifact.id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
        {
          loading: 'Upvoting Artifact',
          success: (_response) => {
            refreshDealRoom();
            return `Upvoted Artifact`;
          },
          error: (err: unknown) => {
            return 'Something went wrong upvoting Artifact, please try again later';
          },
        },
      );
      await refetchArtifacts();
      await refreshDealRoom();
    } catch (err: unknown) {
      console.error(err);
    }
  };

  const handleDownvote = async (
    e:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
      | undefined,
  ) => {
    e?.preventDefault();
    e?.stopPropagation();
    try {
      if (!artifact) {
        return;
      }
      const token = await getAccessTokenSilently();
      await toast.promise(
        !artifact.downvoted
          ? DealRoomsApiClient.downvoteArtifact(
              organizationSlug!,
              dealRoomId,
              artifact.id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            )
          : DealRoomsApiClient.removeArtifactDownvote(
              organizationSlug!,
              dealRoomId,
              artifact.id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
        {
          loading: 'Downvoting Artifact',
          success: (_response) => {
            return 'Downvoted Artifact';
          },
          error: (err: unknown) => {
            return 'Something went wrong downvoting Artifact, please try again later';
          },
        },
      );
      await refetchArtifacts();
      await refreshDealRoom();
    } catch (err: unknown) {
      console.error(err);
    }
  };

  const { handleOnFeature } = useHandleOnFeature({
    organizationSlug,
    dealRoomId,
    refetchArtifacts,
  });

  const { handleOnWelcome } = useHandleOnWelcome({
    organizationSlug,
    dealRoomId,
    refetchArtifacts,
  });

  const menuItems = !artifact
    ? []
    : [
        ...(artifact.deletedAt
          ? [
              {
                disabled: userId !== artifact.creator?.id,
                key: 'restore',
                text: 'Restore',
                icon: <UndoIcon fontSize="small" />,
                onClick: (
                  ev?:
                    | React.MouseEvent<HTMLElement>
                    | React.KeyboardEvent<HTMLElement>,
                ) => {
                  handleClickRestore(ev);
                },
              },
              {
                disabled: userId !== artifact.creator?.id,
                key: 'delete',
                text: 'Remove Permanently',
                icon: <DeleteIcon fontSize="small" />,
                onClick: (
                  ev?:
                    | React.MouseEvent<HTMLElement>
                    | React.KeyboardEvent<HTMLElement>,
                ) => {
                  handleClickDelete(ev, false); // hard delete
                },
              },
            ]
          : [
              {
                disabled: userId !== artifact.creator?.id,
                key: 'delete',
                text: 'Delete',
                icon: <DeleteIcon fontSize="small" />,
                onClick: (
                  ev?:
                    | React.MouseEvent<HTMLElement>
                    | React.KeyboardEvent<HTMLElement>,
                ) => {
                  handleClickDelete(ev, true); // soft delete
                },
              },
            ]),
        {
          disabled: !!artifact.deletedAt,
          key: 'upvote',
          text: artifact.upvoted ? 'Remove Upvote' : 'Upvote',
          icon: artifact.upvoted ? (
            <ThumbUpIcon fontSize="small" />
          ) : (
            <ThumbUpOutlinedIcon fontSize="small" />
          ),
          onClick: (
            ev?:
              | React.MouseEvent<HTMLElement>
              | React.KeyboardEvent<HTMLElement>,
          ) => {
            handleUpvote(ev);
          },
        },
        {
          disabled: !!artifact.deletedAt,
          key: 'downvote',
          text: artifact.downvoted ? 'Remove Downvote' : 'Downvote',
          icon: artifact.downvoted ? (
            <ThumbDownIcon fontSize="small" />
          ) : (
            <ThumbDownOutlinedIcon fontSize="small" />
          ),
          onClick: (
            ev?:
              | React.MouseEvent<HTMLElement>
              | React.KeyboardEvent<HTMLElement>,
          ) => {
            handleDownvote(ev);
          },
        },
        {
          disabled: dealRoomRole !== 'SELLER' || !!artifact.deletedAt,
          key: 'welcome',
          text:
            artifact.placement === 'WELCOME'
              ? 'Remove as a Welcome Artifact'
              : 'Add as a Welcome Artifact',
          icon:
            artifact.placement === 'WELCOME' ? (
              <EmojiPeople fontSize="small" />
            ) : (
              <EmojiPeopleOutlined fontSize="small" />
            ),
          onClick: (
            ev?:
              | React.MouseEvent<HTMLElement>
              | React.KeyboardEvent<HTMLElement>,
          ) => {
            handleOnWelcome(!(artifact.placement === 'WELCOME'), artifact.id);
          },
        },
        {
          disabled: dealRoomRole !== 'SELLER' || !!artifact.deletedAt,
          key: 'feature',
          text: artifact.featuredAt
            ? 'Remove from Featured Artifacts'
            : 'Add to Featured Artifacts',
          icon: artifact.featuredAt ? (
            <Star fontSize="small" />
          ) : (
            <StarBorder fontSize="small" />
          ),
          onClick: (
            ev?:
              | React.MouseEvent<HTMLElement>
              | React.KeyboardEvent<HTMLElement>,
          ) => {
            handleOnFeature(!artifact.featuredAt, artifact.id);
          },
        },
      ];

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        gap: '.25rem',
      }}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <IconButton
        size="small"
        sx={{
          padding: '4px',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            elevation: 2,
            sx: {
              minWidth: '220px',
              '& .MuiMenuItem-root': {
                py: 1,
                px: 2,
                typography: 'body2',
                '& .MuiSvgIcon-root': {
                  fontSize: '20px',
                  marginRight: 1.5,
                  color: 'text.secondary',
                },
              },
            },
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.key}
            onClick={item.onClick}
            disabled={item.disabled}
          >
            {item.icon}
            {item.text}
          </MenuItem>
        ))}
      </Menu>
      {/* {confirmShareDialog} */}
      {/* {confirmDeleteDialog} */}
    </Box>
  );
};
