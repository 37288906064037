import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

const ARTIFACT_HEIGHT = 210;

export const StyledArtifactContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  maxHeight: ARTIFACT_HEIGHT,
  marginBottom: theme.spacing(0),
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  // border: `1px solid ${theme.palette.divider}`,
  overflow: 'hidden',
  cursor: 'pointer',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    maxHeight: 'none',
  },
}));

export const StyledVideoContainer = styled(Box)(({ theme }) => ({
  flex: '0 0 auto',
  height: ARTIFACT_HEIGHT,
  // Allow the container to size based on content
  width: 'auto',
  maxWidth: '334px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    maxWidth: '100%',
    height: `${ARTIFACT_HEIGHT}px`,
  },
}));

export const StyledMediaContainer = styled(Box)(({ theme }) => ({
  flex: '0 0 auto',
  height: ARTIFACT_HEIGHT,
  // Allow the container to size based on content
  width: 'auto',
  minWidth: '334px',
  maxWidth: '50%',
  maxHeight: 'calc(100% - .5rem)',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0',
  position: 'relative',
  backgroundColor: DEALROOMS_COLORS.cloudburst,
  [theme.breakpoints.down('md')]: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    width: '100%',
    maxWidth: '100%',
    height: `${ARTIFACT_HEIGHT}px`,
  },
}));

export const StyledArtifactVideo = styled('video')({
  height: '100%',
  width: '100%',
  objectFit: 'contain',
  background: DEALROOMS_COLORS.cloudburst,
});

export const StyledArtifactAudio = styled('audio')(({ theme }) => ({
  width: '90%',
  marginTop: theme.spacing(2),
}));

export const StyledAudioContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledContentContainer = styled(Box)`
  flex: 1 1 auto;
  padding: ${({ theme }) => theme.spacing(3)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto !important;
  overflow-x: hidden;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding: 1rem;
    width: 100%;
    max-width: 100%;
    height: 100px;
  }
`;

StyledContentContainer.defaultProps = {
  className: 'highlights-card-content',
};

export const StyledArtifactHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(0.5),
  position: 'sticky',
  top: '-1.5rem',
  zIndex: 1,
  width: 'calc(100% - 2rem)',
  marginLeft: '-1rem',
  marginTop: '0rem',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  padding: '1.5rem 3rem 1.5rem 1rem',

  [theme.breakpoints.down('md')]: {
    top: '-1rem',
  },
}));

export const StyledArtifactName = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: `calc(100% - ${theme.spacing(3)})`,
}));

export const StyledArtifactDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  lineHeight: 1.5,
  marginBottom: theme.spacing(1),
  maxWidth: `calc(100% - ${theme.spacing(3)})`,

  '*': {
    fontSize: '0.875rem',
  },
}));

export const StyledArtifactTimestamp = styled(Typography)(({ theme }) => ({
  display: 'block',
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
}));

export const StyledActionsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '1rem',
  right: '1.5rem',
  zIndex: 2,
}));
