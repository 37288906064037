import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';
import { toContentPath } from '../../../Helpers/FileHelpers';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';

export type MeetingflowArtifactCardProps = Omit<BaseArtifactCardProps, 'artifact'> & {
  artifact: DealRoomArtifact;
};

export const MeetingflowArtifactCard = ({
  artifact,
  ...rest
}: MeetingflowArtifactCardProps) => {
  const organizationSlug = useOrganizationSlug() || '';
  
  // Safe access to properties that might not exist on all artifact types
  const thumbnailFileName = artifact && 'thumbnailFileName' in artifact ? artifact.thumbnailFileName : undefined;
  const thumbnailUrl = artifact && 'thumbnailUrl' in artifact ? artifact.thumbnailUrl : undefined;
  const meetingflowId = artifact && 'meetingflowId' in artifact ? artifact.meetingflowId : undefined;

  return (
    <BaseArtifactCard
      className="meetingflow-artifact"
      artifact={artifact}
      {...rest}
    >
      <ImageWithFallback
        src={toContentPath(thumbnailFileName, organizationSlug, meetingflowId) || thumbnailUrl || undefined}
        alt={artifact?.label || artifact?.name || ''}
        fallback={<ArtifactIconPreview type="MEETINGFLOW" />}
      />
    </BaseArtifactCard>
  );
};
