import { styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const PanelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

export const HeaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  position: 'relative',
  borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  backgroundColor: 'transparent',
  zIndex: 1,
  flex: '0 0 auto', // Don't grow or shrink, use auto height
});

export const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto', // Allow growing and shrinking
  overflow: 'auto',
  backgroundColor: 'transparent',
});
