import { useState, useMemo } from 'react';
import {
  Box,
  Divider,
  Typography,
  CircularProgress,
  Paper,
  Popper,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import {
  ChartContainer,
  LegendToggleBox,
  LegendColorBox,
  LegendPanel,
  LegendColumnBox,
  LegendItemRow,
  ChartLegendColorBox,
  LegendCaptionText,
  CenteredContentContainer,
  UserRowContainer,
  TooltipPaper,
  TooltipItemRow,
  TooltipActivityIndicator,
  DateHeaderRow,
  FlexColumnContainer,
  DateHeaderCell,
  UserActivityRow,
  UserBubblesContainer,
  HorizontalConnectingLine,
  EmptyDayCell,
  ActivityDayCell,
  BubbleContainer,
  ActivityBubble,
  TotalRow,
  TotalBubblesContainer,
  TotalRowConnectingLine,
  TotalDayCell,
  TotalBubbleContainer,
  TotalActivityBubble,
} from './DSSellerHubMemberActivityBubbleChart.styles';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { ANALYTICS_TYPES } from './DSSellerHubTypes';
import { UserActivityWithId } from './context/DSActivityContext';
import { DSContactAvatarAndDetails } from '../Tabs/Contacts/DSContactAvatarAndDetails';
import { Contact, DetailedUser } from '@meetingflow/common/Api/data-contracts';

// List of activity types to filter out (too noisy)
const FILTERED_ACTIVITY_TYPES = ['VIEW_DEAL_ROOM'];

interface DSSellerHubMemberActivityBubbleChartProps {
  chartData: Array<Record<string, number | string>>;
  uniqueActivityTypes: string[];
  activities: UserActivityWithId[];
  isLoading?: boolean;
  hideTotal?: boolean; // Add prop to hide the total row when filtering by user
}

interface BubbleDataPoint {
  x: number; // day index
  y: number; // user index
  z: number; // bubble size (activity count)
  day: string; // actual day string
  userId: number; // user ID
  userName: string; // user name
  activityType: string; // activity type
  activityCount: number; // number of activities
}

function DSSellerHubMemberActivityBubbleChart({
  chartData,
  uniqueActivityTypes,
  activities,
  isLoading = false,
  hideTotal = false,
}: DSSellerHubMemberActivityBubbleChartProps) {
  // State for legend visibility and tooltip
  const [legendVisible, setLegendVisible] = useState(false);

  // Tooltip data state
  interface TooltipData {
    content: Array<{ type: string; count: number }>;
    anchorEl: HTMLElement;
    date?: string; // Add date to tooltip data
  }
  const [tooltipData, setTooltipData] = useState<TooltipData | null>(null);
  const { dealRoom } = useDealRoom();

  // Generate colors from DEALROOMS_COLORS
  const CHART_COLORS = [
    DEALROOMS_COLORS.themePrimary,
    DEALROOMS_COLORS.themeSecondary,
    DEALROOMS_COLORS.crimson,
    DEALROOMS_COLORS.peacock,
    DEALROOMS_COLORS.orchid,
    DEALROOMS_COLORS.frost,
    DEALROOMS_COLORS.peach,
    DEALROOMS_COLORS.oink,
    DEALROOMS_COLORS.artic,
    DEALROOMS_COLORS.cloudburst,
  ];

  // Process data for the bubble chart
  const { bubbleData, userList, daysList, aggregatedData } = useMemo(() => {
    if (!activities || activities.length === 0 || !dealRoom?.contacts) {
      return {
        bubbleData: [],
        userList: [],
        daysList: [],
        aggregatedData: new Map(),
      };
    }

    // Get all unique days from chartData and sort them chronologically
    const days = [
      ...new Set(chartData.map((item) => item.day as string)),
    ].sort();

    // First, create the user list from contacts with activities
    const userMap = new Map<
      number,
      {
        userId: number;
        contact?: Contact | DetailedUser;
        name: string;
        index: number;
      }
    >();

    // Get all users with activities
    activities.forEach((activity) => {
      if (!activity.userId) return;

      if (!userMap.has(activity.userId)) {
        const contact = dealRoom.contacts.find(
          (c) => c.userId === activity.userId,
        );
        
        // Get user name from activity.user if available (matching the table's logic)
        const user = activity.user;
        const currentUserName = user ? 
          [user.firstName, user.lastName].filter(Boolean).join(' ') || '' : '';
        
        userMap.set(activity.userId, {
          userId: activity.userId,
          contact,
          name: contact?.name || currentUserName || `User ${activity.userId}`,
          index: userMap.size, // Assign sequential indices
        });
      }
    });

    // Convert map to array and sort by name for consistent display
    const userList = Array.from(userMap.values()).sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    // Update indices after sorting
    userList.forEach((user, index) => {
      user.index = index;
    });

    // Create a map of userIds to their index
    const userIndexMap = new Map<number, number>();
    userList.forEach((user) => {
      userIndexMap.set(user.userId, user.index);
    });

    // Create a map of days to their index
    const dayIndexMap = new Map<string, number>();
    days.forEach((day, index) => {
      dayIndexMap.set(day, index);
    });

    // Group activities by user, day, and activity type
    const activityGroups = new Map<string, BubbleDataPoint>();

    // Filter out noisy activity types
    const filteredActivities = activities.filter(
      (activity) => !FILTERED_ACTIVITY_TYPES.includes(activity.type),
    );

    filteredActivities.forEach((activity) => {
      if (!activity.userId) return;

      const activityDate = new Date(activity.createdAt);
      const dayKey = activityDate.toISOString().split('T')[0];
      const dayIndex = dayIndexMap.get(dayKey);
      const userIndex = userIndexMap.get(activity.userId);

      if (dayIndex === undefined || userIndex === undefined) return;

      // Get the activity type label
      const activityType =
        ANALYTICS_TYPES[activity.type]?.label ||
        activity.type
          .split('_')
          .map((word) => word.toLowerCase())
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

      // Find user in our list
      const user = userList.find((u) => u.userId === activity.userId);
      if (!user) return;

      // Create a unique key for this combination
      const groupKey = `${activity.userId}-${dayKey}-${activityType}`;

      if (!activityGroups.has(groupKey)) {
        activityGroups.set(groupKey, {
          x: dayIndex,
          y: userIndex, // This now matches the sorted user list index
          z: 1,
          day: dayKey,
          userId: activity.userId,
          userName: user.name,
          activityType,
          activityCount: 1,
        });
      } else {
        const group = activityGroups.get(groupKey)!;
        group.z += 1;
        group.activityCount += 1;
      }
    });

    // Calculate aggregated data for each day (total activities across all users)
    const aggregatedByDay = new Map<
      string,
      { activityTypes: Map<string, number> }
    >();

    // Initialize with all days
    days.forEach((day) => {
      aggregatedByDay.set(day, { activityTypes: new Map<string, number>() });
    });

    // Aggregate activities by day and type
    Array.from(activityGroups.values()).forEach((bubble) => {
      const dayData = aggregatedByDay.get(bubble.day);
      if (dayData) {
        const currentCount =
          dayData.activityTypes.get(bubble.activityType) || 0;
        dayData.activityTypes.set(
          bubble.activityType,
          currentCount + bubble.activityCount,
        );
      }
    });

    return {
      bubbleData: Array.from(activityGroups.values()),
      userList,
      daysList: days,
      aggregatedData: aggregatedByDay,
    };
  }, [activities, chartData, dealRoom?.contacts]);

  if (isLoading) {
    return (
      <ChartContainer>
        <CenteredContentContainer>
          <CircularProgress color="primary" size={24} />
        </CenteredContentContainer>
      </ChartContainer>
    );
  }

  if (bubbleData.length === 0) {
    return (
      <ChartContainer>
        <CenteredContentContainer>
          <Typography variant="body1" color="text.secondary">
            No user activity
          </Typography>
        </CenteredContentContainer>
      </ChartContainer>
    );
  }

  return (
    <ChartContainer>
      {/* Custom tooltip */}
      <Popper
        open={!!tooltipData}
        anchorEl={tooltipData?.anchorEl}
        placement="top"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              boundary: document.body,
            },
          },
        ]}
        sx={{ zIndex: 1500 }}
      >
        <TooltipPaper elevation={3}>
          <Typography variant="subtitle2" sx={{ fontWeight: '500', mb: 1 }}>
            {tooltipData?.date || 'Activities'}
          </Typography>
          {tooltipData?.content
            .sort((a, b) => b.count - a.count) // Sort by count in descending order
            .map((item, index) => {
              const colorIndex =
                uniqueActivityTypes.indexOf(item.type) % CHART_COLORS.length;
              return (
                <TooltipItemRow key={`tooltip-${index}`}>
                  <TooltipActivityIndicator color={CHART_COLORS[colorIndex]} />
                  <Typography
                    variant="body2"
                    sx={{ mr: 'auto', fontSize: '0.85rem' }}
                  >
                    {item.type}:
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: '500', ml: 1, fontSize: '0.85rem' }}
                  >
                    {item.count}
                  </Typography>
                </TooltipItemRow>
              );
            })}
        </TooltipPaper>
      </Popper>
      <Box sx={{ position: 'relative' }}>
        {/* Legend Toggle Button with hover functionality - moved to lower right */}
        <LegendToggleBox
          id="legend-toggle"
          onMouseEnter={() => setLegendVisible(true)}
          onMouseLeave={() => setLegendVisible(false)}
        >
          <LegendColorBox
            sx={{
              backgroundColor: DEALROOMS_COLORS.themePrimary,
            }}
          />
          <Typography variant="caption" sx={{ fontWeight: 'medium' }}>
            Legend
          </Typography>
        </LegendToggleBox>

        {/* Collapsible Legend Panel in a portal to prevent cutoff */}
        <Popper
          open={legendVisible}
          anchorEl={document.getElementById('legend-toggle')}
          placement="bottom-end"
          style={{ zIndex: 1100, width: 'auto', maxWidth: '800px' }}
        >
          <LegendPanel
            onMouseEnter={() => setLegendVisible(true)}
            onMouseLeave={() => setLegendVisible(false)}
          >
            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: '500' }}>
              Activity Types
            </Typography>
            <Divider sx={{ mb: 1 }} />
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {(() => {
                // Determine optimal number of columns based on number of activity types
                const maxItemsPerColumn = 4; // Maximum items per column for readability
                const numColumns = Math.ceil(uniqueActivityTypes.length / maxItemsPerColumn);
                const itemsPerColumn = Math.ceil(uniqueActivityTypes.length / numColumns);
                const columns = [];
                
                // Distribute items across columns
                for (let i = 0; i < numColumns; i++) {
                  const startIdx = i * itemsPerColumn;
                  const endIdx = Math.min(
                    startIdx + itemsPerColumn,
                    uniqueActivityTypes.length,
                  );
                  if (startIdx < uniqueActivityTypes.length) {
                    columns.push(uniqueActivityTypes.slice(startIdx, endIdx));
                  }
                }

                return columns.map((column, colIndex) => (
                  <LegendColumnBox
                    key={`col-${colIndex}`}
                    isLastColumn={colIndex === columns.length - 1}
                  >
                    {column.map((type, index) => (
                      <LegendItemRow key={`legend-${colIndex}-${index}`}>
                        <ChartLegendColorBox
                          color={
                            CHART_COLORS[
                              (colIndex * itemsPerColumn + index) %
                                CHART_COLORS.length
                            ]
                          }
                        />
                        <LegendCaptionText variant="caption">
                          {type}
                        </LegendCaptionText>
                      </LegendItemRow>
                    ))}
                  </LegendColumnBox>
                ));
              })()}
            </Box>
          </LegendPanel>
        </Popper>

        {/* New approach: Use a flex container for user rows and bubbles */}
        <FlexColumnContainer>
          {/* Date header row (moved from bottom to top) */}
          <DateHeaderRow>
            {daysList.map((day, dayIndex) => (
              <DateHeaderCell
                key={`header-${dayIndex}`}
                isLabelVisible={dayIndex % 3 === 0}
              >
                {dayIndex % 3 === 0 &&
                  new Date(day).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                  })}
              </DateHeaderCell>
            ))}
          </DateHeaderRow>
          {/* User rows with activity bubbles */}
          {userList.map((user, index) => (
            <UserActivityRow key={`user-row-${user.userId}`}>
              {/* User info */}
              <Box sx={{ width: '32px', flexShrink: 0, pl: 2, mr: 2 }}>
                <UserRowContainer>
                  <DSContactAvatarAndDetails
                    contact={
                      user.contact ||
                      ({
                        name: user.name,
                        email: '',
                      } as Contact)
                    }
                    size="small"
                    hideSocials
                    hideEmailAddress
                    avatarOnly
                  />
                </UserRowContainer>
              </Box>

              {/* Bubbles for this user */}
              <UserBubblesContainer>
                {/* Horizontal connecting line for user's bubbles */}
                <HorizontalConnectingLine />
                {daysList.map((day, dayIndex) => {
                  // Find bubbles for this user and day
                  const userBubbles = bubbleData.filter(
                    (b) => b.userId === user.userId && b.x === dayIndex,
                  );

                  // Skip days with no activity
                  if (userBubbles.length === 0) {
                    return (
                      <EmptyDayCell key={`day-${dayIndex}`}></EmptyDayCell>
                    );
                  }

                  return (
                    <ActivityDayCell key={`day-${dayIndex}`}>
                      {/* Render concentric bubbles */}
                      <BubbleContainer
                        onMouseEnter={(e) => {
                          setTooltipData({
                            content: userBubbles
                              .map((b) => ({
                                type: b.activityType,
                                count: b.activityCount,
                              }))
                              .sort((a, b) => b.count - a.count), // Sort by count in descending order
                            anchorEl: e.currentTarget,
                            date: new Date(day).toLocaleDateString('en-US', {
                              weekday: 'short',
                              month: 'short',
                              day: 'numeric',
                            }),
                          });
                        }}
                        onMouseLeave={() => setTooltipData(null)}
                      >
                        {/* Render bubbles from largest to smallest */}
                        {userBubbles
                          .sort((a, b) => b.activityCount - a.activityCount) // Sort descending so larger bubbles are drawn first
                          .map((bubble, bIndex) => {
                            const colorIndex =
                              uniqueActivityTypes.indexOf(bubble.activityType) %
                              CHART_COLORS.length;
                            const maxSize = 24; // Maximum bubble size (significantly reduced)
                            const minSize = 4; // Minimum bubble size (reduced to 4px for single activities)

                            // Use absolute activity count for sizing instead of proportion
                            // This ensures more activities = bigger bubbles consistently across the chart
                            const maxActivityCount = 20; // Cap for scaling purposes
                            const normalizedCount = Math.min(
                              bubble.activityCount,
                              maxActivityCount,
                            );
                            const size =
                              minSize +
                              (normalizedCount / maxActivityCount) *
                                (maxSize - minSize);

                            return (
                              <ActivityBubble
                                key={`bubble-${bIndex}`}
                                size={size}
                                colorIndex={colorIndex}
                                bIndex={bIndex}
                                chartColor={CHART_COLORS[colorIndex]}
                              />
                            );
                          })}

                        {/* Removed the numbers from inside the bubbles */}
                      </BubbleContainer>
                    </ActivityDayCell>
                  );
                })}
              </UserBubblesContainer>
            </UserActivityRow>
          ))}

          {/* Aggregate row showing totals - only show when not filtered by user */}
          {!hideTotal && (
            <TotalRow>
              {/* Total label */}
              <Box sx={{ width: '32px', flexShrink: 0, pl: 2, mr: 2 }}>
                <Typography variant="subtitle2" sx={{ fontWeight: '500' }}>
                  Total
                </Typography>
              </Box>

              {/* Bubbles for aggregated data */}
              <TotalBubblesContainer>
                {/* Horizontal connecting line */}
                <TotalRowConnectingLine />

                {daysList.map((day, dayIndex) => {
                  // Get aggregated data for this day
                  const dayData = aggregatedData.get(day);
                  if (!dayData) return null;

                  // Convert the activity types map to an array for rendering
                  type ActivityEntry = [string, number];
                  const aggregatedBubbles = Array.from(
                    dayData.activityTypes.entries() as IterableIterator<ActivityEntry>,
                  ).map((entry) => {
                    const [type, count] = entry;
                    return {
                      activityType: type,
                      activityCount: count,
                    };
                  });

                  // If no activities for this day, render empty space
                  if (aggregatedBubbles.length === 0) {
                    return (
                      <TotalDayCell
                        key={`total-day-${dayIndex}`}
                        isLast={dayIndex === daysList.length - 1}
                      />
                    );
                  }

                  return (
                    <TotalDayCell
                      key={`total-day-${dayIndex}`}
                      isLast={dayIndex === daysList.length - 1}
                    >
                      {/* Render concentric bubbles for aggregated data */}
                      <TotalBubbleContainer
                        onMouseEnter={(e) => {
                          setTooltipData({
                            content: aggregatedBubbles
                              .map((b) => ({
                                type: b.activityType,
                                count: b.activityCount,
                              }))
                              .sort(
                                (a, b) =>
                                  (b.count as number) - (a.count as number),
                              ),
                            anchorEl: e.currentTarget,
                            date: new Date(day).toLocaleDateString('en-US', {
                              weekday: 'short',
                              month: 'short',
                              day: 'numeric',
                            }),
                          });
                        }}
                        onMouseLeave={() => setTooltipData(null)}
                      >
                        {/* Render bubbles from largest to smallest */}
                        {aggregatedBubbles
                          .sort(
                            (a, b) =>
                              (b.activityCount as number) -
                              (a.activityCount as number),
                          )
                          .map((bubble, bIndex) => {
                            const colorIndex =
                              uniqueActivityTypes.indexOf(bubble.activityType) %
                              CHART_COLORS.length;

                            // Calculate size - use the same logic as individual bubbles
                            const maxSize = 24; // Maximum bubble size (same as individual bubbles)
                            const minSize = 4; // Minimum bubble size (same as individual bubbles)
                            const maxActivityCount = 20; // Same cap as individual bubbles for consistent sizing
                            const normalizedCount = Math.min(
                              bubble.activityCount,
                              maxActivityCount,
                            );
                            const size =
                              minSize +
                              (normalizedCount / maxActivityCount) *
                                (maxSize - minSize);

                            return (
                              <TotalActivityBubble
                                key={`total-bubble-${bIndex}`}
                                size={size}
                                colorIndex={colorIndex}
                                bIndex={bIndex}
                                chartColor={CHART_COLORS[colorIndex]}
                              />
                            );
                          })}
                      </TotalBubbleContainer>
                    </TotalDayCell>
                  );
                })}
              </TotalBubblesContainer>
            </TotalRow>
          )}
        </FlexColumnContainer>
      </Box>
    </ChartContainer>
  );
}

export default DSSellerHubMemberActivityBubbleChart;
