import { ReactNode } from 'react';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import DSDetail from './DSDetail';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { toast } from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import { OrganizationDealRoomsQuery } from '../../../../QueryNames';
import { DateTime } from 'luxon';

interface DSDetailTargetCloseProps {
  editable?: boolean;
}

export const DSDetailTargetClose = ({
  editable = true,
}: DSDetailTargetCloseProps) => {
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { dealRoom, refetch: refetchDealRoom } = useDealRoom();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  // Format date for display
  const formatDate = (
    dateString?: string | null,
    format: string = 'MMM d, yyyy',
  ) => {
    if (!dateString) return 'No date';
    const date = DateTime.fromISO(dateString);
    return date.toFormat(format);
  };

  // Handle target close date update
  const handleTargetCloseDateUpdate = async (newValue: string) => {
    if (!organizationSlug || !dealRoomId) return;

    try {
      const token = await getAccessTokenSilently();
      const payload = { targetCloseDate: newValue };

      const response = await toast.promise(
        DealRoomsApiClient.updateDealRoom(
          organizationSlug,
          dealRoomId,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
        {
          loading: 'Updating target close date...',
          success: 'Target close date updated successfully',
          error: 'Failed to update target close date',
        },
      );

      // Force a refetch of the deal room data to ensure we have the latest state
      queryClient.invalidateQueries([
        OrganizationDealRoomsQuery,
        organizationSlug,
        dealRoomId,
      ]);
      await refetchDealRoom();
    } catch (error) {
      console.error('Error updating target close date:', error);
    }
  };

  // Return null if not editable and targetCloseDate is not set
  if (!editable && !dealRoom?.targetCloseDate) {
    return null;
  }

  return (
    <DSDetail
      label="Target Close"
      value={formatDate(dealRoom?.targetCloseDate)}
      icon={
        <CalendarTodayIcon
          sx={{ fontSize: '20px', color: DEALROOMS_COLORS.cloudburst }}
        />
      }
      editable={editable}
      type="date"
      onSave={handleTargetCloseDateUpdate}
    />
  );
};
