import React, { createContext, useContext, ReactNode, useState, useMemo, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import { UserActivity, UserActivityType } from '@meetingflow/common/Api/data-contracts';
import { useListDealRoomActivities } from '../hooks/useDealRoomActivity';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useSearchParams } from 'react-router-dom';
import { ANALYTICS_TYPES } from '../DSSellerHubTypes';
import { useDealRoom } from '../../../../Hooks/useDealRoom';

// Extended type that includes the id field from the server response
export interface UserActivityWithId extends UserActivity {
  id: string;
}

export interface AutocompleteContact {
  id: string;
  name: string;
  email: string;
  userId: number | null;
}

interface DSActivityContextType {
  activitiesData: { 
    pages: Array<{
      activities: UserActivityWithId[]; 
      totalCount: number;
      hasMore: boolean;
      currentPage: number;
    }> 
  } | undefined;
  pages: Array<{
      activities: UserActivityWithId[]; 
      totalCount: number;
      hasMore: boolean;
      currentPage: number;
    }>;
  activities: UserActivityWithId[];
  totalCount: number;
  hasMore: boolean;
  currentPage: number;
  isActivitiesLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
  refetchActivities: () => void;
  selectedContacts: AutocompleteContact[];
  setSelectedContacts: React.Dispatch<React.SetStateAction<AutocompleteContact[]>>;
  selectedActivityTypes: UserActivityType[];
  setSelectedActivityTypes: React.Dispatch<React.SetStateAction<UserActivityType[]>>;
  startDate: DateTime | null;
  setStartDate: React.Dispatch<React.SetStateAction<DateTime | null>>;
  endDate: DateTime | null;
  setEndDate: React.Dispatch<React.SetStateAction<DateTime | null>>;
  order: 'asc' | 'desc';
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  orderBy: 'createdAt' | 'user.name';
  setOrderBy: React.Dispatch<React.SetStateAction<'createdAt' | 'user.name'>>;
  enableUrlSync: boolean;
  setEnableUrlSync: React.Dispatch<React.SetStateAction<boolean>>;
}

interface DSActivityProviderProps {
  children: ReactNode;
  userIds?: number[];
  enableUrlSync?: boolean;
  instanceId?: string;
}

// Default number of days to show in the activity chart
const DEFAULT_DATE_RANGE_DAYS = 30;
// Use a very high page size to ensure we get all data for the date range
const PAGE_SIZE = 1000;

// Create context with default values
const DSActivityContext = createContext<DSActivityContextType>({
  activitiesData: undefined,
  pages: [],
  activities: [],
  totalCount: 0,
  hasMore: false,
  currentPage: 0,
  isActivitiesLoading: false,
  isFetchingNextPage: false,
  hasNextPage: false,
  fetchNextPage: () => {},
  refetchActivities: () => {},
  selectedContacts: [],
  setSelectedContacts: () => {},
  selectedActivityTypes: [],
  setSelectedActivityTypes: () => {},
  startDate: null,
  setStartDate: () => {},
  endDate: null,
  setEndDate: () => {},
  order: 'desc',
  setOrder: () => {},
  orderBy: 'createdAt',
  setOrderBy: () => {},
  enableUrlSync: true,
  setEnableUrlSync: () => {},
});

export const DSActivityProvider: React.FC<DSActivityProviderProps> = ({
  children,
  userIds,
  enableUrlSync = true,
  instanceId = 'default',
}) => {
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  
  // Filter state
  const [selectedContacts, setSelectedContacts] = useState<AutocompleteContact[]>([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState<UserActivityType[]>([]);
  
  // Date filter state - default to past 30 days
  const [startDate, setStartDate] = useState<DateTime | null>(null);
  const [endDate, setEndDate] = useState<DateTime | null>(
    DateTime.now().endOf('day'),
  );
  
  // Sorting state
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<'createdAt' | 'user.name'>('createdAt');

  // By default, if not provided, query for the last 30 days
  const isValidDateRange = useMemo(() => {
    return Boolean(startDate || endDate);
  }, [startDate, endDate]);

  // Keep track of the state derived from URL
  const derivedFromUrl = useRef(false);
  const processingUrlChange = useRef(false);
  const initializedDates = useRef(false);

  // Set default date range if not explicitly set
  useEffect(() => {
    // Skip if we're processing URL changes or already initialized
    if (processingUrlChange.current || initializedDates.current) {
      return;
    }
    
    // Only initialize if at least one date is missing
    if (!startDate || !endDate) {
      
      // Only set start date if it's not already set
      if (!startDate) {
        setStartDate(
          DateTime.now().startOf('day').minus({ days: DEFAULT_DATE_RANGE_DAYS })
        );
      }
      
      // Only set end date if it's not already set
      if (!endDate) {
        setEndDate(DateTime.now().endOf('day'));
      }
      
      initializedDates.current = true;
    }
  }, [startDate, endDate, setStartDate, setEndDate]);

  // Get deal room data
  const { dealRoom, isLoading: isDealRoomLoading } = useDealRoom();

  // Transform deal room contacts into the format needed for the Autocomplete
  // Only include contacts that have a userId value
  const autocompleteContacts = useMemo(() => {
    if (!dealRoom?.contacts) return [];

    return dealRoom.contacts
      .filter(
        (contact) => contact.userId !== null && contact.userId !== undefined,
      )
      .map((contact) => ({
        id: contact.id.toString(),
        name: contact.name || '',
        email: contact.email || '',
        userId: contact.userId,
      }));
  }, [dealRoom?.contacts]);

  // Format userIds for API request
  const appliedUserIds = useMemo(() => {
    if (selectedContacts.length > 0) {
      return selectedContacts
        .filter((contact) => contact.userId !== null)
        .map((contact) => contact.userId!) as number[];
    }
    return userIds;
  }, [selectedContacts, userIds]);

  // Get the available activity types
  const availableActivityTypes = useMemo(() => {
    return Object.keys(ANALYTICS_TYPES) as UserActivityType[];
  }, []);

  // URL sync state
  const [searchParams, setSearchParams] = useSearchParams();
  const isUpdatingURL = useRef(false);
  const isInitialRender = useRef(true);
  const [enableUrlSyncState, setEnableUrlSyncState] = useState(enableUrlSync);
  const hasInitializedFromURL = useRef(false);

  // Make this provider instance aware of other instances changing the URL
  const previousUrlUserFilter = useRef<string | null>(null);

  // Initialize state from URL params
  useEffect(() => {
    // Skip if URL synchronization is disabled
    if (!enableUrlSyncState) return;
    
    // Skip if we're in the middle of updating the URL from state
    if (isUpdatingURL.current) {
      return;
    }
    
    const userFilterParam = searchParams.get('userFilter');
    
    // Only process changes in userFilter
    if (userFilterParam !== previousUrlUserFilter.current) {
      
      // Prevent processing this URL change multiple times
      processingUrlChange.current = true;
      
      try {
        // Store the current userFilter to prevent recursive updates
        previousUrlUserFilter.current = userFilterParam;
        
        // Handle user filter
        if (userFilterParam) {
          const userIds = userFilterParam.split(',').map(id => parseInt(id, 10)).filter(id => !isNaN(id));
          
          if (userIds.length > 0) {
            // Try to find contacts for these user IDs
            const contactsToSelect = userIds.map((userId: number) => {
              const existingContact = autocompleteContacts.find(
                (contact) => contact.userId === userId
              );
              
              return existingContact || {
                id: `synthetic-${userId}`,
                name: `User ${userId}`,
                email: '',
                userId: userId,
              };
            });
            
            // Mark this update as derived from URL
            derivedFromUrl.current = true;
            
            // Set the selected contacts
            setSelectedContacts(contactsToSelect);
          }
        } else {
          // If userFilter is null or empty and it changed, clear selection
          if (selectedContacts.length > 0) {
            derivedFromUrl.current = true;
            setSelectedContacts([]);
          }
        }
      } finally {
        // Reset processing flag after a short delay
        setTimeout(() => {
          processingUrlChange.current = false;
        }, 50);
      }
    }
    
    // Handle activity types only if we're not processing a URL change
    if (!processingUrlChange.current) {
      const activityTypesParam = searchParams.get('activityTypes');
      if (activityTypesParam) {
        const types = activityTypesParam.split(',') as UserActivityType[];
        const validTypes = types.filter(type => 
          availableActivityTypes.includes(type)
        );
        if (validTypes.length > 0) {
          setSelectedActivityTypes(validTypes);
        }
      }

      // Handle date filters
      const startDateParam = searchParams.get('startDate');
      if (startDateParam) {
        const parsedDate = DateTime.fromISO(startDateParam);
        if (parsedDate.isValid) {
          setStartDate(parsedDate);
        }
      }

      const endDateParam = searchParams.get('endDate');
      if (endDateParam) {
        const parsedDate = DateTime.fromISO(endDateParam);
        if (parsedDate.isValid) {
          setEndDate(parsedDate);
        }
      }
    }
  }, [searchParams, enableUrlSyncState, autocompleteContacts, availableActivityTypes, selectedContacts.length, setSelectedContacts, setSelectedActivityTypes, setStartDate, setEndDate]);

  // Initialize selectedContacts based on userIds prop when available
  useEffect(() => {
    // Skip if we're using URL synchronization or already processing changes
    if (enableUrlSyncState && (processingUrlChange.current || isUpdatingURL.current)) {
      return;
    }
    
    // Only run if we have userIds and contacts to work with
    if (userIds && userIds.length > 0) {
      
      // Find contacts matching the userIds
      const contactsToSelect = autocompleteContacts.filter(
        (contact) => contact.userId !== null && userIds.includes(contact.userId)
      );

      if (contactsToSelect.length > 0) {
        setSelectedContacts(contactsToSelect);
      } else {
        // Create synthetic contacts if no matching ones found
        const syntheticContacts = userIds.map((userId: number) => ({
          id: `synthetic-${userId}`,
          name: `User ${userId}`,
          email: '',
          userId: userId,
        }));
        setSelectedContacts(syntheticContacts);
      }
    }
  }, [userIds, autocompleteContacts, enableUrlSyncState, setSelectedContacts]);

  // Sync selected contacts to URL
  useEffect(() => {
    if (!enableUrlSyncState) return;
    
    // Skip if the current selection was derived from URL change
    if (derivedFromUrl.current) {
      derivedFromUrl.current = false;
      return;
    }
    
    // Skip if we're processing a URL change
    if (processingUrlChange.current) {
      return;
    }
    
    // Flag to prevent recursion
    isUpdatingURL.current = true;
    
    try {
      // Create a new URLSearchParams to avoid mutating the current one
      const newParams = new URLSearchParams(searchParams);
      
      // Handle contact filters
      if (selectedContacts.length > 0) {
        // Get user IDs from selected contacts
        const userIds = selectedContacts
          .filter(c => c.userId !== null)
          .map(c => c.userId);
          
        if (userIds.length > 0) {
          const userFilterValue = userIds.join(',');
          
          // Only update if the value actually changed
          if (newParams.get('userFilter') !== userFilterValue) {
            newParams.set('userFilter', userFilterValue);
            
            // Track what value we're setting for debugging
            previousUrlUserFilter.current = userFilterValue;
            
            // Update the URL without navigating to a new page
            setSearchParams(newParams);
          }
        } else {
          if (newParams.has('userFilter')) {
            newParams.delete('userFilter');
            previousUrlUserFilter.current = null;
            setSearchParams(newParams);
          }
        }
      } else {
        // If no contacts selected, ALWAYS remove the userFilter param
        if (newParams.has('userFilter')) {
          newParams.delete('userFilter');
          previousUrlUserFilter.current = null;
          setSearchParams(newParams);
        }
      }
    } finally {
      // Reset flag after a short delay to allow for the URL update to complete
      setTimeout(() => {
        isUpdatingURL.current = false;
      }, 50);
    }
  }, [searchParams, selectedContacts, enableUrlSyncState, setSearchParams]);

  // Use the activities hook with proper options format
  const {
    data: activitiesData,
    isLoading: isActivitiesLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch: refetchActivities,
  } = useListDealRoomActivities({
    organizationSlug,
    dealRoomId,
    page: 1,
    size: PAGE_SIZE,
    userIds: appliedUserIds?.join(','),
    activityTypes: selectedActivityTypes.length ? selectedActivityTypes.join(',') : undefined,
    startDate: startDate?.toISO() || undefined,
    endDate: endDate?.toISO() || undefined,
    sort: `${orderBy},${order}`,
    enabled: Boolean(isValidDateRange),
  });

  const value = useMemo<DSActivityContextType>(
    () => ({
      activitiesData,
      pages: activitiesData?.pages || [],
      activities: activitiesData?.pages?.[0]?.activities || [],
      totalCount: activitiesData?.pages?.[0]?.totalCount || 0,
      hasMore: activitiesData?.pages?.[0]?.hasMore || false,
      currentPage: activitiesData?.pages?.[0]?.currentPage || 0,
      isActivitiesLoading,
      isFetchingNextPage,
      hasNextPage,
      fetchNextPage,
      refetchActivities,
      selectedContacts,
      setSelectedContacts,
      selectedActivityTypes,
      setSelectedActivityTypes,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      order,
      setOrder,
      orderBy,
      setOrderBy,
      enableUrlSync: enableUrlSyncState,
      setEnableUrlSync: setEnableUrlSyncState,
    }),
    [
      activitiesData,
      isActivitiesLoading,
      isFetchingNextPage,
      hasNextPage,
      fetchNextPage,
      refetchActivities,
      selectedContacts,
      selectedActivityTypes,
      startDate,
      endDate,
      order,
      orderBy,
      enableUrlSyncState,
    ],
  );

  return (
    <DSActivityContext.Provider value={value}>
      {children}
    </DSActivityContext.Provider>
  );
};

// Custom hook to use the activity context
export const useDSActivity = (instanceId?: string | number) => {
  const context = useContext(DSActivityContext);
  if (context === undefined) {
    throw new Error('useDSActivity must be used within a DSActivityProvider');
  }
  
  // Use a derived hook instance ID to separate context instances
  // This helps prevent multiple instances from interfering with each other
  useEffect(() => {
    if (instanceId) {
    }
  }, [instanceId]);
  
  return context;
};
