import { styled } from '@mui/material/styles';
import { Box, Avatar, TextField, Autocomplete } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { isTooDark } from '../../../../Helpers/ColorHelpers';
import { DealRoom } from '@meetingflow/common/Api/data-contracts';

export const AddToDecisionSiteContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginLeft: '.25rem',
  whiteSpace: 'nowrap',
  flexShrink: 0,
});

export const DecisionSiteOptionBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  width: '100%',
  py: 0.5,
});

export const DecisionSiteOptionAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
}));

export const DecisionSiteOptionContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const DecisionSiteChipContent = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  marginLeft: '-6px',
});

export const DecisionSiteChipAvatar = styled(Avatar)({
  width: 12,
  height: 12,
  padding: '2px',
  backgroundColor: 'white',
});

export const StyledAutocomplete = styled(Autocomplete)`
  display: inline-flex;
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiAutocomplete-endAdornment {
    display: none;
  }
  & .MuiInputBase-root {
    padding: 2px 4px;
    min-width: fit-content;
  }
  & .MuiAutocomplete-input {
    width: 45px !important;
    min-width: 45px !important;
  }
` as typeof Autocomplete;

export const StyledTextField = styled(TextField)({
  display: 'inline-flex',
  borderRadius: '1rem',
  '& .MuiInputBase-root': {
    margin: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    display: 'inline-flex',
    minWidth: 'fit-content',
  },
  '& .MuiInputBase-input': {
    fontSize: '12px',
    width: '45px',
    minWidth: '45px',
  },
  '&:hover': {
    backgroundColor: DEALROOMS_COLORS.financialLighterGray,
  },
  '& .MuiInputBase-root.Mui-focused': {
    backgroundColor: DEALROOMS_COLORS.financialLighterGray,
    borderRadius: '1rem',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none',
  },
});

export const getChipStyles = (primaryColor: string | null) => ({
  transition: 'all 0.3s ease-in-out',
  backgroundColor: primaryColor ? `${primaryColor}1A` : 'rgba(0, 0, 0, 0.1)', // 10% opacity (1A in hex)
  border: `1px solid ${primaryColor ? `${primaryColor}50` : DEALROOMS_COLORS.woodsmoke}`,
  color: 'rgba(0, 0, 0, 0.87)', // Standard dark text for readability
  height: 20,
  margin: 0,
  padding: 0,
  '& .MuiChip-label': {
    padding: '0 8px 0 8px',
    fontSize: '.75rem',
  },
  '& .MuiChip-deleteIcon': {
    opacity: 0,
    width: 0,
    margin: 0,
    paddingRight: '0',
    transition: 'all 0.3s ease-in-out',
    color: primaryColor || 'rgba(0, 0, 0, 0.87)',
    '&:hover': {
      color: `${primaryColor || 'rgba(0, 0, 0, 0.87)'} !important`,
      opacity: 0.8,
    },
  },
  '&:hover .MuiChip-deleteIcon': {
    opacity: 1,
    width: '16px',
    marginLeft: '4px',
  },
  '& .MuiChip-deleteIcon:hover': {
    opacity: 0.8,
  },
});
