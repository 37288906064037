import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const DSSectionTitle = ({
  icon,
  title = '',
}: {
  icon?: React.ReactElement<SvgIconProps>;
  title?: string | ReactNode;
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1.5}
      sx={{
        width: '100%',
        overflow: 'hidden',
        maxWidth: '100%',
        alignItems: 'center',
        height: '2rem',
        lineHeight: '2rem',
        marginBottom: '.75rem',
        marginLeft: '-1rem',
        minHeight: '2rem', // Ensure space is reserved even when empty
      }}
    >
      {icon &&
        React.cloneElement(icon, {
          sx: { fontSize: 20, color: DEALROOMS_COLORS.themeSecondary },
        })}
      <Typography
        sx={{
          color: DEALROOMS_COLORS.themeSecondary,
          fontWeight: 500,
          fontSize: '1rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 'calc(100% - 3rem)',
          display: 'inline-flex',
          alignItems: 'center',
          minHeight: '2rem', // Ensure space is reserved even when empty
          lineHeight: '2rem',
        }}
      >
        {title || '\u00A0'}{' '}
        {/* Non-breaking space to maintain height when empty */}
      </Typography>
    </Box>
  );
};
