import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import { DSSellerHubActivityTable } from './DSSellerHubActivityTable';
import { DSContactsListDetail } from '../Tabs/Contacts/DSContactsListDetail';
import DSJourneyHighlightsCarousel from './components/DSJourneyHighlightsCarousel';
import { DSCompaniesListDetail } from '../Tabs/Companies/DSCompaniesListDetail';
import { Box, useTheme, useMediaQuery, Avatar, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import PeopleIcon from '@mui/icons-material/People';
import {
  RootContainer,
  PrimaryContentContainer,
  SecondaryContentContainer,
  PrimaryContentModule,
  StyledSectionTitle,
  SecondaryContentModule,
} from './DSSellerHubRoot.styles';
import { DSActivityProvider } from './context/DSActivityContext';
import { DSDecisionSiteDetails } from './components/DSDecisionSiteDetails';
import { Timeline } from '@mui/icons-material';
import { DSSectionTitle } from '../DS/DSSectionTitle';
import { DSJourneySummaryFetcher } from './components/DSJourneySummaryFetcher';

// Logo styling moved to DSDetailDecisionSiteName.styles.ts

export const DSSellerHubRoot = () => {
  const [containerWidth, setContainerWidth] = useState(600);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const primaryContainerRef = useRef<HTMLDivElement>(null);
  // Start with hasJourneySummary set to false to hide the component until we confirm data exists
  const [hasJourneySummary, setHasJourneySummary] = useState(false);

  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();

  // Set up ResizeObserver to track container width changes
  useEffect(() => {
    if (!primaryContainerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // Update the container width state
        const newWidth = entry.contentRect.width;
        setContainerWidth(newWidth);
      }
    });

    resizeObserver.observe(primaryContainerRef.current);

    // Handle window resize to update window height
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!organizationSlug || !dealRoomId) {
    return null;
  }

  return (
    <DSActivityProvider>
      <DSDecisionSiteDetails editable={true} version="SELLER" />
      
      {/* Always render the data fetcher to check for journey summary data */}
      <DSJourneySummaryFetcher setHasJourneySummary={setHasJourneySummary} />

      <RootContainer>
        <SecondaryContentContainer>
          <StyledSectionTitle>
            <DSSectionTitle
              icon={<PeopleIcon />}
              title="Members and Contributors"
            />
          </StyledSectionTitle>
          <SecondaryContentModule
            sx={{
              backgroundColor: `rgba(0, 0, 0, 0.025)`,
            }}
          >
            <DSCompaniesListDetail />
          </SecondaryContentModule>
          {/* Panel-only version of list/detail for contacts, since they're actually listed in the company table */}
          <DSContactsListDetail panelOnly />
        </SecondaryContentContainer>

        <PrimaryContentContainer ref={primaryContainerRef}>
          {hasJourneySummary && (
            <>
              <StyledSectionTitle>
                <DSSectionTitle icon={<Timeline />} title="Journey Summary" />
              </StyledSectionTitle>
              <PrimaryContentModule
                sx={{
                  maxWidth: `${containerWidth}px`,
                  backgroundColor: `rgba(0, 0, 0, 0.025)`,
                  paddingTop: '1rem',
                }}
              >
                <DSJourneyHighlightsCarousel
                  setHasJourneySummary={setHasJourneySummary}
                />
              </PrimaryContentModule>
            </>
          )}
          <StyledSectionTitle>
            <DSSectionTitle icon={<BarChartIcon />} title="Member Activity" />
          </StyledSectionTitle>
          <PrimaryContentModule>
            <DSSellerHubActivityTable
              allowExpand={true}
              customMaxHeight={'100%'}
            />
          </PrimaryContentModule>
        </PrimaryContentContainer>
      </RootContainer>
    </DSActivityProvider>
  );
};
