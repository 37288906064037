import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useDecisionSiteNavigationStore } from '../Stores/decisionSiteNavigationStore';

// The context only handles visibility (show/hide in DOM)
// For expansion state (collapsed/expanded), we use the existing Zustand store
type DSGlobalSidebarContextType = {
  // Visibility control - completely include/exclude the sidebar from DOM
  isSidebarVisible: boolean;
  excludeSidebar: () => void;
  includeSidebar: () => void;
  toggleSidebarVisibility: () => void;
  
  // Expansion control - from existing Zustand store
  isExpanded: boolean;
  setExpanded: (isExpanded: boolean) => void;
  toggleExpanded: () => void;
};

const DSGlobalSidebarContext = createContext<DSGlobalSidebarContextType | undefined>(undefined);

export const DSGlobalSidebarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Visibility state managed locally by context
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  
  // Expansion state from Zustand store
  const { isDecisionSitesOpen, setIsDecisionSitesOpen } = useDecisionSiteNavigationStore();
  
  // Visibility functions
  const excludeSidebar = () => setIsSidebarVisible(false);
  const includeSidebar = () => setIsSidebarVisible(true);
  const toggleSidebarVisibility = () => setIsSidebarVisible((prev) => !prev);
  
  // Expansion functions (delegated to Zustand store)
  const setExpanded = (isExpanded: boolean) => setIsDecisionSitesOpen(isExpanded);
  const toggleExpanded = () => setIsDecisionSitesOpen(!isDecisionSitesOpen);

  return (
    <DSGlobalSidebarContext.Provider 
      value={{ 
        // Visibility state
        isSidebarVisible, 
        excludeSidebar, 
        includeSidebar, 
        toggleSidebarVisibility,
        
        // Expansion state (from Zustand store)
        isExpanded: isDecisionSitesOpen,
        setExpanded,
        toggleExpanded
      }}
    >
      {children}
    </DSGlobalSidebarContext.Provider>
  );
};

export const useDSGlobalSidebar = (): DSGlobalSidebarContextType => {
  const context = useContext(DSGlobalSidebarContext);
  if (context === undefined) {
    throw new Error('useDSGlobalSidebar must be used within a DSGlobalSidebarProvider');
  }
  return context;
};
