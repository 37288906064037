import { useEffect, useRef } from 'react';
import { useBreadcrumb, BreadcrumbItem } from '../Context/BreadcrumbContext';

// Helper function to debounce function calls
function debounce<T extends unknown[]>(func: (...args: T) => void, wait: number) {
  let timeout: NodeJS.Timeout | null = null;
  return function(...args: T) {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

/**
 * A hook that manages dynamic breadcrumb updates for components that need to
 * override the default breadcrumb with more specific information.
 * 
 * This hook handles:
 * 1. Setting the breadcrumb only once when data is available
 * 2. Cleaning up when the component unmounts
 * 3. Optionally resetting breadcrumbs to a default state on unmount
 * 
 * @param breadcrumb The breadcrumb item to set when data is available
 * @param dependencies Array of dependencies that should trigger a breadcrumb update
 * @param resetOptions Optional configuration for resetting breadcrumbs on unmount
 * @returns An object containing the breadcrumb state
 */
export const useDynamicBreadcrumb = (
  breadcrumb: BreadcrumbItem | null,
  dependencies: unknown[],
  resetOptions?: {
    shouldReset: boolean;
    resetBreadcrumbs?: BreadcrumbItem[];
    pathCheck?: string; // Only reset if current path includes this string
  }
) => {
  const { setCurrentBreadcrumb, setBreadcrumbs, breadcrumbs } = useBreadcrumb();
  const breadcrumbSetRef = useRef<boolean>(false);
  
  // Create a stable debounced version of setCurrentBreadcrumb
  const debouncedSetBreadcrumb = useRef(
    debounce<[BreadcrumbItem]>((crumb: BreadcrumbItem) => {
      setCurrentBreadcrumb(crumb);
    }, 50)
  ).current;

  // Set breadcrumb when data is available
  useEffect(() => {
    if (breadcrumb) {
      // Always update the breadcrumb when dependencies change, not just the first time
      // This ensures the breadcrumb stays set even if other components try to change it
      breadcrumbSetRef.current = true;
      debouncedSetBreadcrumb(breadcrumb);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, setCurrentBreadcrumb]);
  
  // Clean up on unmount
  useEffect(() => {
    return () => {
      // Reset the ref so it can be set again if the component remounts
      breadcrumbSetRef.current = false;
      
      // Reset breadcrumbs if configured to do so
      if (resetOptions?.shouldReset && resetOptions.resetBreadcrumbs) {
        // If pathCheck is provided, only reset if current path includes it
        if (!resetOptions.pathCheck || window.location.pathname.includes(resetOptions.pathCheck)) {
          // Use a longer delay for resetting to ensure it doesn't conflict with setting
          setTimeout(() => {
            // Ensure resetBreadcrumbs is not undefined before calling setBreadcrumbs
            if (resetOptions.resetBreadcrumbs) {
              // We should preserve the existing breadcrumbs up to the section level (first 2 items)
              // Detail panels should only be responsible for their own detail breadcrumb
              if (breadcrumbs.length > 2) {
                // Keep only the first two breadcrumbs (root and section)
                setBreadcrumbs(breadcrumbs.slice(0, 2));
              } else if (resetOptions.resetBreadcrumbs && resetOptions.resetBreadcrumbs.length > 0) {
                // Fallback to original behavior if something is missing
                setBreadcrumbs(resetOptions.resetBreadcrumbs);
              }
            }
          }, 100);
        }
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBreadcrumbs, breadcrumbs, resetOptions]);
  
  return {
    breadcrumbSet: breadcrumbSetRef.current,
    resetBreadcrumb: () => {
      breadcrumbSetRef.current = false;
    }
  };
};
