import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { ImageWithFallback } from '../../../Components/Common/ImageWithFallback';

export type FileArtifactCardProps = Omit<BaseArtifactCardProps, 'artifact'> & {
  artifact: DealRoomArtifact;
};

export const FileArtifactCard = ({
  artifact,
  ...rest
}: FileArtifactCardProps) => {
  // Safe access to properties that might not exist on all artifact types
  const fileName = artifact && 'fileName' in artifact ? artifact.fileName : '';
  const thumbnailUrl = artifact && 'thumbnailUrl' in artifact ? artifact.thumbnailUrl : undefined;
  const mimeType = artifact && 'mimeType' in artifact ? artifact.mimeType : '';
  
  return (
    <BaseArtifactCard
      className="file-artifact"
      artifact={artifact}
      {...rest}
    >
      <ImageWithFallback
        src={thumbnailUrl || undefined}
        fallback={
          <ArtifactIconPreview
            type="FILE"
            fileExtension={fileName.split('.')?.pop() || ''}
            mimeType={mimeType}
          />
        }
      />
    </BaseArtifactCard>
  );
};
