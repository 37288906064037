import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { Typography, IconButton, Collapse, Box } from '@mui/material';
import { DSStatusMessage } from '../../Components/DSStatusMessage';
import { useNavigate } from 'react-router-dom';
import {
  LightbulbOutlined,
  ForumOutlined,
  AutoAwesomeOutlined,
  ExpandMoreOutlined,
  ExpandLessOutlined,
  AccessTime,
  Refresh,
} from '@mui/icons-material';
import {
  MomentsContainer,
  RecommendationCard,
  RecommendationTitle,
  RecommendationSection,
  SectionContent,
  CallCitationBox,
  List,
  EmptyStateContainer,
  IconWrapper,
  ListItemDot,
  RefreshButtonContainer,
  RefreshButton,
  CardsContainer,
} from './DSContactDetailMoments.styles';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import {
  TimestampContainer,
  TimestampIcon,
  TimestampText,
} from './DSKeyStatement.styles';
import { DSTooltip } from '../../DS';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

// Define types as provided in the requirements
export type CallRecordingCitation = {
  call_recording_id: string;
  meeting_plan_id: string;
  timestamp: number;
  quotes: string[];
};

export type ExistingArtifactRecommendation = {
  artifact_id: string;
  artifact_name: string;
  buyer_insights: string;
  rationale: string;
  suggested_modifications: string[];
  call_recording_citations: CallRecordingCitation[];
};

export type NewArtifactRecommendation = {
  artifact_name: string;
  buyer_insights: string;
  recommendation: string;
  rationale: string;
  suggested_elements: string[];
  call_recording_citations: CallRecordingCitation[];
};

export type ArtifactRecommendation =
  | ExistingArtifactRecommendation
  | NewArtifactRecommendation;

export type ArtifactRecommendations = {
  artifactRecommendations: ArtifactRecommendation[];
};

interface DSContactDetailMomentsProps {
  contactId: number;
}

// Pure utility functions moved outside the component
function formatTimestamp(seconds: number | null | undefined): string {
  if (seconds === null || seconds === undefined) return '00:00';

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  return [
    hours > 0 ? hours.toString().padStart(2, '0') : '',
    minutes.toString().padStart(2, '0'),
    secs.toString().padStart(2, '0'),
  ]
    .filter(Boolean)
    .join(':');
}

function cleanQuote(quote: unknown): string {
  if (typeof quote !== 'string') {
    return 'Unable to display quote';
  }
  // Replace any HTML tags with empty string - this will remove any CSS styling
  return quote.replace(/<[^>]*>/g, '');
}

function isExistingArtifactRecommendation(
  recommendation: ArtifactRecommendation,
): recommendation is ExistingArtifactRecommendation {
  return 'artifact_id' in recommendation;
}

export function DSContactDetailMoments({
  contactId,
}: DSContactDetailMomentsProps) {
  const { slug: organizationSlug } = useOrganization();
  const { dealRoom } = useDealRoom();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [expandedCards, setExpandedCards] = useState<Record<number, boolean>>(
    {},
  );

  // Data fetching function
  async function fetchArtifactRecommendations() {
    const token = await getAccessTokenSilently();

    if (!organizationSlug || !dealRoom?.id) {
      return { artifactRecommendations: [] };
    }

    const response = await DealRoomsApiClient.getArtifactRecommendations(
      organizationSlug,
      dealRoom?.id,
      contactId,
      { headers: { Authorization: `Bearer ${token}` } },
    );

    return response.data;
  }

  const {
    data: recommendationsData,
    isLoading,
    error,
    refetch,
    isRefetching,
  } = useQuery<ArtifactRecommendations, Error>(
    ['artifactRecommendations', organizationSlug, dealRoom?.id, contactId],
    fetchArtifactRecommendations,
    {
      enabled: true, // Run on mount
      retry: 1, // Only retry once if it fails
      staleTime: Infinity, // Data never becomes stale automatically
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  );

  // UI interaction function
  function toggleCardExpansion(index: number) {
    setExpandedCards((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }

  // Navigation function
  function navigateToRecording(meeting_plan_id: string, timestamp: number) {
    const url = dealRoom?.id
      ? `/organization/${organizationSlug}/decisionsite/${dealRoom.id}/journey?meeting=${encodeURIComponent(meeting_plan_id)}&recordingTimestamp=${encodeURIComponent(timestamp)}`
      : `/organization/${organizationSlug}/meetings?meeting=${encodeURIComponent(meeting_plan_id)}&recordingTimestamp=${encodeURIComponent(timestamp)}`;
    navigate(url);
  }

  function navigateToArtifact(artifactId: string) {
    if (organizationSlug && dealRoom?.id) {
      navigate(
        `/organization/${organizationSlug}/decisionsite/${dealRoom.id}/artifacts/${encodeURIComponent(artifactId)}`,
      );
    }
  }

  // UI rendering functions
  function renderCitationQuotes(quotes: string[]) {
    if (!quotes || quotes.length === 0) {
      return <div>No quotes available</div>;
    }

    return quotes.map((quote, qIndex) => (
      <div key={qIndex}>{cleanQuote(quote)}</div>
    ));
  }

  function renderCitation(citation: CallRecordingCitation, index: number) {
    return (
      <CallCitationBox key={index}>
        {renderCitationQuotes(citation.quotes)}
        <TimestampContainer
          onClick={() =>
            navigateToRecording(citation.meeting_plan_id, citation.timestamp)
          }
        >
          <TimestampIcon>
            <AccessTime fontSize="small" sx={{ fontSize: '0.875rem' }} />
          </TimestampIcon>
          <TimestampText>{formatTimestamp(citation.timestamp)}</TimestampText>
        </TimestampContainer>
      </CallCitationBox>
    );
  }

  function renderCitations(citations: CallRecordingCitation[]) {
    if (!citations || citations.length === 0) return null;

    return (
      <>{citations.map((citation, index) => renderCitation(citation, index))}</>
    );
  }

  function renderCardHeader(title: React.ReactNode, index: number) {
    const isExpanded = !!expandedCards[index];

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '4px',
          marginBottom: '10px',
        }}
      >
        <RecommendationTitle
          style={{ marginBottom: 0, display: 'flex', alignItems: 'center' }}
        >
          <AutoAwesomeOutlined
            fontSize="small"
            style={{ marginRight: '8px', color: DEALROOMS_COLORS.crimson }}
          />
          {title}
        </RecommendationTitle>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            toggleCardExpansion(index);
          }}
          sx={{
            borderRadius: '4px',
            padding: '4px',
          }}
        >
          {isExpanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </IconButton>
      </div>
    );
  }

  function renderExistingArtifactTitle(rec: ExistingArtifactRecommendation) {
    return (
      <>
        Feature Artifact:
        <Box
          component="span"
          sx={{
            fontWeight: 'normal',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
            ml: 0.5,
          }}
          onClick={() => navigateToArtifact(rec.artifact_id)}
        >
          {rec.artifact_name}
        </Box>
      </>
    );
  }

  function renderNewArtifactTitle(rec: NewArtifactRecommendation) {
    return (
      <>
        Create Artifact:{' '}
        <span style={{ fontWeight: 'normal' }}>{rec.artifact_name}</span>
      </>
    );
  }

  function renderExistingArtifactRecommendation(
    rec: ExistingArtifactRecommendation,
    index: number,
  ) {
    const isExpanded = !!expandedCards[index];

    return (
      <RecommendationCard>
        {renderCardHeader(renderExistingArtifactTitle(rec), index)}

        {/* Main content - always visible */}
        <RecommendationSection>
          <SectionContent>{rec.buyer_insights}</SectionContent>
        </RecommendationSection>

        {/* Expandable detailed content */}
        <Collapse in={isExpanded} timeout="auto">
          <RecommendationSection
            sx={{
              paddingTop: '1rem',
            }}
          >
            <SectionContent>{rec.rationale}</SectionContent>
          </RecommendationSection>

          {renderElementsList(rec.suggested_modifications)}
          {renderRecommendationCitations(rec.call_recording_citations)}
        </Collapse>
      </RecommendationCard>
    );
  }

  function renderElementsList(elements: string[] | undefined) {
    if (!elements || elements.length === 0) return null;

    return (
      <RecommendationSection>
        <List>
          {elements.map((element, idx) => (
            <SectionContent key={idx}>
              <ListItemDot />
              {element}
            </SectionContent>
          ))}
        </List>
      </RecommendationSection>
    );
  }

  function renderRecommendationCitations(
    citations: CallRecordingCitation[] | undefined,
  ) {
    if (!citations || citations.length === 0) return null;

    return (
      <RecommendationSection>
        {renderCitations(citations)}
      </RecommendationSection>
    );
  }

  function renderNewArtifactRecommendation(
    rec: NewArtifactRecommendation,
    index: number,
  ) {
    const isExpanded = !!expandedCards[index];

    return (
      <RecommendationCard>
        {renderCardHeader(renderNewArtifactTitle(rec), index)}

        {/* Main content - always visible */}
        <RecommendationSection>
          <SectionContent>{rec.buyer_insights}</SectionContent>
        </RecommendationSection>

        {/* Expandable detailed content */}
        <Collapse in={isExpanded} timeout="auto">
          <RecommendationSection
            sx={{
              paddingTop: '1rem',
            }}
          >
            <SectionContent>{rec.recommendation}</SectionContent>
          </RecommendationSection>

          <RecommendationSection>
            <SectionContent>{rec.rationale}</SectionContent>
          </RecommendationSection>

          {renderElementsList(rec.suggested_elements)}
          {renderRecommendationCitations(rec.call_recording_citations)}
        </Collapse>
      </RecommendationCard>
    );
  }

  function renderRecommendation(
    recommendation: ArtifactRecommendation,
    index: number,
  ) {
    if (isExistingArtifactRecommendation(recommendation)) {
      return renderExistingArtifactRecommendation(recommendation, index);
    } else {
      return renderNewArtifactRecommendation(recommendation, index);
    }
  }

  function renderEmptyState() {
    return (
      <EmptyStateContainer>
        <IconWrapper>
          <ForumOutlined />
        </IconWrapper>
        <Typography variant="h6" color="text.primary" sx={{ mb: 1 }}>
          No Recommendations Available
        </Typography>
        <Typography variant="body2" color="text.secondary">
          No artifacts have been recommended for this contact yet.
        </Typography>
      </EmptyStateContainer>
    );
  }

  function renderLoadingState() {
    return (
      <DSStatusMessage
        icon={LightbulbOutlined}
        title="Generating Artifact Recommendations..."
        subtitle="Please wait while we analyze data and generate recommendations"
        iconSize={48}
      />
    );
  }

  function renderErrorState() {
    return (
      <DSStatusMessage
        icon={LightbulbOutlined}
        title="Unable to Generate Recommendations"
        subtitle="There was an error generating artifact recommendations. Please try again later."
        iconColor="error.main"
        titleColor="error"
        iconSize={48}
      />
    );
  }

  // Component rendering logic
  if (isLoading || isRefetching) {
    return renderLoadingState();
  }

  if (error) {
    return renderErrorState();
  }

  const hasRecommendations =
    recommendationsData?.artifactRecommendations &&
    recommendationsData.artifactRecommendations.length > 0;

  return (
    <MomentsContainer>
      <CardsContainer>
        {hasRecommendations ? (
          <>
            {recommendationsData?.artifactRecommendations.map(
              (recommendation, index) => (
                <React.Fragment key={index}>
                  {renderRecommendation(recommendation, index)}
                </React.Fragment>
              ),
            )}
          </>
        ) : (
          renderEmptyState()
        )}
      </CardsContainer>
      <RefreshButtonContainer>
        <DSTooltip title="Refresh recommendations">
          <RefreshButton
            onClick={() => refetch()}
            size="small"
            disabled={isLoading || isRefetching}
          >
            <Refresh />
          </RefreshButton>
        </DSTooltip>
      </RefreshButtonContainer>
    </MomentsContainer>
  );
}
