import React, { useState, useEffect } from 'react';
import {
  ApiClient,
  CompaniesApiClient,
} from '../../../../Services/NetworkCommon';
import { useQuery } from 'react-query';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';
import { DSCompanyAvatarAndDetails } from './DSCompanyAvatarAndDetails';
import { StyledSpinner } from '../../../StyledSpinner';
import { Text, Stack } from '@fluentui/react';

import {
  PanelWrapper,
  HeaderWrapper,
  ContentWrapper,
} from './DSCompanyDetail.styles';
import { DSOrgChartLoadingError } from './DSOrgChartLoadingError';

type TheOrgChartCompany = {
  id: string;
  name: string;
  logoUrl: string;
  domains: string[];
  orgChartUrl: string;
  embedUrl: string;
};

type TheOrgChartResult = {
  companies: TheOrgChartCompany[];
};

interface DSCompanyDetailProps {
  companyId: number;
}

export const DSCompanyDetail: React.FC<DSCompanyDetailProps> = ({
  companyId,
}) => {
  const { organization } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();
  const [theOrgData, setTheOrgData] = useState<TheOrgChartResult | null>(null);
  const [isOrgChartLoading, setIsOrgChartLoading] = useState(false);
  const [orgChartError, setOrgChartError] = useState<Error | null>(null);

  const {
    data: companyData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['company', organization?.slug, companyId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const response = await CompaniesApiClient.getCompany(
        organization?.slug ?? '',
        companyId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response;
    },
    enabled: !!organization?.slug,
  });

  // Fetch org chart data outside of React Query
  useEffect(() => {
    const fetchOrgChartData = async () => {
      if (!organization?.slug) return;

      setIsOrgChartLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await ApiClient.get<TheOrgChartResult>(
          `/organization/${organization.slug}/companies/${companyId}/org-chart`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setTheOrgData(response.data);
      } catch (err) {
        setOrgChartError(err as Error);
        console.error('Error fetching org chart data:', err);
      } finally {
        setIsOrgChartLoading(false);
      }
    };

    fetchOrgChartData();
  }, [organization?.slug, companyId, getAccessTokenSilently]);

  const company = companyData?.data;
  const hasOrgChartData = theOrgData?.companies?.length;

  if (isLoading) {
    return (
      <PanelWrapper>
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          style={{ height: '100%' }}
        >
          <StyledSpinner />
          <Text>Loading company details...</Text>
        </Stack>
      </PanelWrapper>
    );
  }

  if (error) {
    return (
      <PanelWrapper>
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          style={{ height: '100%' }}
        >
          <Text variant="large">Error loading company details</Text>
          <Text>{(error as Error).message || 'An unknown error occurred'}</Text>
        </Stack>
      </PanelWrapper>
    );
  }

  if (!company) {
    return null;
  }

  return (
    <PanelWrapper>
      <HeaderWrapper>
        <DSCompanyAvatarAndDetails company={company} size="large" />
      </HeaderWrapper>

      {isOrgChartLoading ? (
        <ContentWrapper>
          <Stack
            horizontalAlign="center"
            verticalAlign="center"
            style={{ height: '100%' }}
          >
            <StyledSpinner />
            <Text>Loading organization chart...</Text>
          </Stack>
        </ContentWrapper>
      ) : orgChartError ? (
        <DSOrgChartLoadingError errorMessage={orgChartError.message} />
      ) : hasOrgChartData ? (
        <ContentWrapper sx={{ overflow: 'hidden' }}>
          <Stack verticalFill style={{ height: '100%' }}>
            <Stack
              horizontal
              horizontalAlign="center"
              style={{
                backgroundColor: '#FFF4CE',
                padding: '8px 12px',
                marginBottom: '12px',
                borderRadius: '2px',
              }}
            >
              <Text style={{ color: '#605E5C' }}>
                <i>This data may be outdated</i>
              </Text>
            </Stack>
            <div style={{ flex: 1, height: 'calc(100% - 40px)' }}>
              <iframe
                sandbox="allow-scripts allow-same-origin"
                src={theOrgData!.companies[0].embedUrl}
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Organization Chart"
              />
            </div>
          </Stack>
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <Stack
            horizontalAlign="center"
            verticalAlign="center"
            style={{ height: '100%' }}
          >
            <Text
              variant="large"
              style={{ color: '#605E5C', marginBottom: '8px' }}
            >
              No organization chart available
            </Text>
            <Text
              style={{
                color: '#605E5C',
                textAlign: 'center',
                maxWidth: '400px',
              }}
            >
              There is no organization chart data present for this company.
            </Text>
          </Stack>
        </ContentWrapper>
      )}
    </PanelWrapper>
  );
};
