import { Box, Typography, Chip, styled, Theme } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const getClassificationColor = (classification: string) => {
  switch (classification) {
    case 'CRITICAL':
      return DEALROOMS_COLORS.crimson;
    case 'SUPPORTIVE':
    case 'POSITIVE':
      return DEALROOMS_COLORS.peacock;
    case 'NEGATIVE':
      return DEALROOMS_COLORS.crimson;
    case 'NEUTRAL':
      return DEALROOMS_COLORS.cloudburst;
    default:
      return DEALROOMS_COLORS.cloudburst;
  }
};

interface QuoteContainerProps {
  classification: string;
}

export const QuoteContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'classification',
})<QuoteContainerProps>(({ theme, classification }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  paddingRight: 0,
  marginBottom: theme.spacing(3),
  backgroundColor: `${getClassificationColor(classification)}15`, // 15 = 10% opacity
  borderRadius: theme.spacing(1),
  borderLeft: `4px solid ${getClassificationColor(classification)}`,
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const QuoteText = styled(Typography)(({ theme }) => ({
  fontSize: '0.95rem',
  lineHeight: 1.4,
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  position: 'relative',
  top: '-2px',
  '& span': {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
}));

export const ClassificationChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'classification',
})<QuoteContainerProps>(({ theme, classification }) => ({
  backgroundColor: getClassificationColor(classification),
  color: '#FFF',
  height: '28px',
  lineHeight: '28px',
  position: 'absolute',
  top: '-.65rem',
  right: '.5rem',
  border: '4px solid white',
  '& .MuiChip-label': {
    fontSize: '0.75rem',
    padding: '0 8px',
  },
}));

export const MetadataContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
}));

export const SpeakerInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
}));

export const MeetingInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const baseLineStyles = (theme: Theme) => ({
  fontSize: '0.875rem',
  lineHeight: '1rem',
  margin: '0',
  padding: 0,
  color: theme.palette.text.secondary,
});

export const SpeakerLine = styled(Typography)(({ theme }) => ({
  ...baseLineStyles(theme),
}));

export const MeetingTitleLine = styled(Typography)(({ theme }) => ({
  ...baseLineStyles(theme),
  fontWeight: '500',
  color: DEALROOMS_COLORS.woodsmoke,
  margin: '0 0 .25rem 0 ',
}));

export const DateTimeLine = styled(Typography)(({ theme }) => ({
  ...baseLineStyles(theme),
  fontSize: '0.75rem',
}));

export const TimestampContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  marginLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
  verticalAlign: 'middle',
  position: 'relative',
  top: '-1px',
  fontSize: '0.875rem',
  backgroundColor: 'rgba(255, 255, 255, 0.75)',
  padding: '0 .5rem',
  borderRadius: '4px',
  cursor: 'pointer',
  height: '21px',
}));

export const TimestampIcon = styled(Box)(({ theme }) => ({
  fontSize: '0.875rem',
  position: 'relative',
  top: '3px',
  display: 'block',
  lineHeight: '21px',
  color: DEALROOMS_COLORS.woodsmoke,
}));

export const TimestampText = styled(Typography)(({ theme }) => ({
  position: 'relative',
  top: '0px',
  display: 'block',
  lineHeight: '21px',
  color: DEALROOMS_COLORS.cloudburst,
  fontSize: '0.75rem',
}));
