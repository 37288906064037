import { Stack, Image, Text } from '@fluentui/react';
import { Button } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DSResponsiveModal } from '../DS/DSResponsiveModal';
import toast from 'react-hot-toast';
import { pickFile } from '../../../Helpers/FileHelpers';
import {
  getFileWithMimeType,
  hasValidFileExtension,
  validUploadImageTypes,
} from '../../../utils/buyerDecisionSiteUtils';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';

interface DSLogoManagementModalProps {
  open: boolean;
  onDismiss: () => void;
  organizationSlug: string;
  dealRoomId: number;
  buyerLogoUrl?: string;
  onLogoUpdate: () => void;
}

export const DSLogoManagementModal: React.FC<DSLogoManagementModalProps> = ({
  open,
  onDismiss,
  organizationSlug,
  dealRoomId,
  buyerLogoUrl,
  onLogoUpdate,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const handleLogoUpload = async () => {
    try {
      const file = await pickFile({ fileTypes: 'image' });
      if (!file) {
        toast.error('The uploaded file is not a valid image.');
        return;
      }

      if (!hasValidFileExtension(file)) {
        toast.error(
          'Please select a file with an extension, like .jpg or .png',
        );
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        toast.error('The uploaded image size exceeds the 2MB limit.');
        return;
      }

      const fileCopyWithType = getFileWithMimeType(file);

      if (!validUploadImageTypes.includes(fileCopyWithType.type)) {
        toast.error('The uploaded file is not a valid image.');
        return;
      }

      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.uploadBuyersLogo(
          organizationSlug,
          dealRoomId,
          { file: fileCopyWithType },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
        {
          loading: 'Uploading...',
          success: () => {
            onLogoUpdate();
            onDismiss();
            return 'Logo uploaded successfully';
          },
          error: 'Failed to upload logo',
        },
      );
    } catch (error) {
      console.error('Failed to upload logo:', error);
      toast.error('Failed to upload logo');
    }
  };

  const handleLogoRemove = async () => {
    try {
      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.updateDealRoom(
          organizationSlug,
          dealRoomId,
          { buyerLogoUrl: null },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
        {
          loading: 'Removing logo...',
          success: () => {
            onLogoUpdate();
            onDismiss();
            return 'Logo removed successfully';
          },
          error: 'Failed to remove logo',
        },
      );
    } catch (error) {
      console.error('Failed to remove logo:', error);
      toast.error('Failed to remove logo');
    }
  };

  return (
    <DSResponsiveModal
      open={open}
      onDismiss={onDismiss}
      title="Upload Logo"
      customMaxWidth={500}
    >
      <Stack
        tokens={{ childrenGap: 24 }}
        styles={{ root: { padding: '8px 4px' } }}
      >
        {buyerLogoUrl && (
          <Stack horizontalAlign="center">
            <Image
              src={buyerLogoUrl}
              width={200}
              height={200}
              style={{ borderRadius: '12px', objectFit: 'contain' }}
            />
          </Stack>
        )}
        <Text
          styles={{
            root: {
              fontSize: '14px',
              color: DEALROOMS_COLORS.neutralSecondary,
              textAlign: 'center',
            },
          }}
        >
          Update or remove your buyer's logo.
          <br />
          Your changes will be visible across the entire DecisionSite.
        </Text>
        <Stack horizontal tokens={{ childrenGap: 12 }} horizontalAlign="center">
          <Button
            variant="contained"
            onClick={handleLogoUpload}
            sx={{
              textTransform: 'none',
              minWidth: '120px',
              backgroundColor: DEALROOMS_COLORS.themePrimary,
              '&:hover': {
                backgroundColor: DEALROOMS_COLORS.themeSecondary,
              },
            }}
          >
            Upload Logo
          </Button>
          {buyerLogoUrl && (
            <Button
              variant="outlined"
              color="error"
              onClick={handleLogoRemove}
              sx={{
                textTransform: 'none',
                minWidth: '120px',
                borderColor: DEALROOMS_COLORS.basicErrorMessage,
                color: DEALROOMS_COLORS.basicErrorMessage,
                '&:hover': {
                  color: DEALROOMS_COLORS.white,
                  borderColor: DEALROOMS_COLORS.darkerRed,
                  backgroundColor: DEALROOMS_COLORS.darkerRed,
                },
              }}
            >
              Remove
            </Button>
          )}
        </Stack>
      </Stack>
    </DSResponsiveModal>
  );
};
