import React from 'react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { User } from '@meetingflow/common/Api/data-contracts';
import { Typography, Avatar } from '@mui/material';
import { getMUIIconForArtifactType } from '../../../Helpers/IconHelpers';
import { avatarPropsFromString } from '../DS/DSUserAvatar';
import { 
  StyledExampleCard,
  Header,
  TitleContainer,
  Title,
  Preview,
  Activity
} from './ExampleArtifactCard.styles';

// Create a type for our example card props
export type ExampleArtifactCardProps = {
  children?: React.ReactNode;
  id?: number;
  orgSlug?: string;
  dealRoomId?: number;
  type?: string;
  label?: string;
  name?: string;
  description?: string;
};

/**
 * ExampleArtifactCard - Displays a placeholder artifact card with example data
 * Used when there are no real artifacts to display
 */
export const ExampleArtifactCard: React.FC<ExampleArtifactCardProps> = ({
  children,
  id = 0,
  orgSlug = '',
  dealRoomId = 0,
  type = 'DOCUMENT',
  label = 'Example Artifact',
  name = 'Sample Artifact',
  description = 'This is an example of what an artifact will look like.',
}) => {
  // Create example creator
  const creator = {
    id: 0,
    firstName: 'Example',
    lastName: 'User',
    email: 'example@example.com',
    name: 'Example User',
    avatarUrl: null,
    timezone: 'America/Los_Angeles',
  } as User;

  // Render our own card without using BaseArtifactCard to avoid the actions menu
  return (
    <StyledExampleCard elevation={0}>
      <Header>
        <TitleContainer>
          {React.createElement(getMUIIconForArtifactType(type as 'DOCUMENT', null), {
            sx: {
              width: 20,
              height: 20,
              color: DEALROOMS_COLORS.cloudburst,
            },
          })}
          <Title>{label || name}</Title>
        </TitleContainer>
        {/* No ActionsButton here */}
      </Header>
      <Preview>{children}</Preview>
      <Activity>
        <Avatar
          {...avatarPropsFromString(creator.name || '')}
          sx={{ width: 24, height: 24 }}
        />
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {description}
        </Typography>
      </Activity>
    </StyledExampleCard>
  );
};
