import { Box, Stack, styled, Checkbox } from '@mui/material';
import { NotificationsOff } from '@mui/icons-material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const DSInboxRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 'calc(100% - 2rem)',
  height: 'calc(100% - 2rem)',
  overflow: 'auto',
  margin: '1rem',

  [theme.breakpoints.down('sm')]: {
    margin: '0',
    width: '100%',
    maxWidth: '100%',
    height: '100%',
  },
}));

export const DSInboxHeader = styled('div')(({ theme }) => ({
  width: 'calc(100% - 2rem)',
  padding: '1rem',
  flexShrink: 0,
  fontWeight: 400,
  fontSize: '1.75rem',
  lineHeight: '2rem',
  color: DEALROOMS_COLORS.themePrimary,
}));

export const DSInboxContent = styled('div')(({ theme }) => ({
  flex: 1,
  width: 'calc(100% - 2rem)',
  height: '100%',
  flexGrow: 1,
  minHeight: 0,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'transparent',
  margin: '0 1rem .5rem 1rem',

  [theme.breakpoints.down('sm')]: {
    width: 'calc(100% - 1rem)',
    margin: '0 auto .5rem auto',
  },

  '& > *': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
  '& [role="tabpanel"]': {
    flex: 1,
    minHeight: 0,
    height: '100%',
    display: 'flex',
  },

  // MUI tab bar
  '& .MuiTabs-root': {
    flexShrink: 0,
    paddingBottom: '1rem',
  },
}));

export const DSInboxNotificationsListToolbar = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: '.5rem 1.65rem',
  gap: theme.spacing(1),
  borderBottom: `1px solid rgb(232, 232, 236)`,
  flex: 1,
  flexWrap: 'wrap',
}));

export const DSInboxNotificationsListDetailRoot = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    width: 'calc(100% - 2px)',
    height: '100%',
    borderRadius: '.25rem',
    overflow: 'hidden',
    border: `1px solid rgb(232, 232, 236)`,
  }),
);

export const DSInboxNotificationsList = styled('div')<{
  hasActiveNotification?: boolean;
}>(({ theme, hasActiveNotification }) => ({
  flex: hasActiveNotification ? '0 0 45%' : '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  borderRight: hasActiveNotification
    ? `1px solid ${theme.palette.divider}`
    : 'none',
  overflowY: 'auto',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  minHeight: 0,
}));

export const DSInboxNotificationDetailWrapper = styled('div')(({ theme }) => ({
  flex: '1 1 auto',
  overflow: 'auto',
  backgroundColor: theme.palette.background.default,
  borderRadius: '0 .25rem .25rem 0',
}));

export const DSInboxNotificationPreviewWrapper = styled('div')<{
  selected?: boolean;
}>(({ theme, selected }) => ({
  padding: '.75rem',
  cursor: 'pointer',
  transition: 'background-color 0.2s ease, font-size 0.2s ease',
  width: '100%',
  fontSize: '0.875rem', // Base font size for the entire wrapper
  backgroundColor: selected ? theme.palette.action.selected : 'transparent',
  '&:hover': {
    backgroundColor: selected
      ? theme.palette.action.selected
      : theme.palette.action.hover,
  },
}));

export const DSInboxNotificationPreviewContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
  }),
);

export const DSInboxNotificationContent = styled(
  'div',
)<DSInboxNotificationDetailTitleProps>(({ theme, isNew }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  opacity: isNew ? 1 : 0.5,
  fontSize: '0.875rem', // Explicit font size to prevent resizing
  transition: 'opacity 0.2s ease',
}));

interface DSInboxNotificationDetailTitleProps {
  isNew?: boolean;
}

export const DSInboxNotificationTitle = styled(
  'div',
)<DSInboxNotificationDetailTitleProps>(({ theme, isNew }) => ({
  fontWeight: isNew ? 500 : 400,
  fontSize: '0.875rem', // Explicit font size to prevent resizing
  lineHeight: '1.25rem',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(0.25),
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  paddingLeft: isNew ? '0' : '1rem',
  transition: 'font-weight 0.2s ease, padding-left 0.2s ease',
}));

export const DSInboxNotificationTimestamp = styled(
  'div',
)<DSInboxNotificationDetailTitleProps>(({ theme, isNew }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.75rem', // Smaller explicit font size for timestamp
  fontWeight: isNew ? 500 : 400,
  paddingLeft: '1rem',
  lineHeight: '1rem', // Fixed line height to prevent shifting
  transition: 'font-weight 0.2s ease',
}));

export const DSInboxNotificationStatusDot = styled('div')<{ isNew?: boolean }>(
  ({ theme, isNew }) => ({
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: isNew ? DEALROOMS_COLORS.cloudburst : 'transparent',
    flexShrink: 0,
    display: isNew ? 'block' : 'none',
  }),
);

export const DSInboxNotificationDetailContainer = styled('div')(
  ({ theme }) => ({
    padding: '1.5rem',
  }),
);

export const DSInboxNotificationDetailHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

export const DSInboxNotificationDetailContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const DSInboxNotificationDetailTitle = styled(
  'div',
)<DSInboxNotificationDetailTitleProps>(({ theme, isNew }) => ({
  fontSize: '1.25rem',
  fontWeight: isNew ? 600 : 400,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(0.5),
}));

export const DSInboxNotificationsListWrapper = styled('div')(({ theme }) => ({
  paddingTop: '.75rem',
}));

export const DSInboxNotificationDetailTimestamp = styled('div')(
  ({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  }),
);

export const DSInboxNotificationListItem = styled(Box)<{ active?: boolean }>(
  ({ theme, active }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: active ? theme.palette.action.selected : 'transparent',
    transition: 'background-color 0.2s ease',
    padding: '0 1rem',
    '&:hover': {
      backgroundColor: active
        ? theme.palette.action.selected
        : theme.palette.action.hover,
    },
  }),
);

export const DSInboxNotificationCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: 'transparent',

    '& .MuiSvgIcon-root': {
      transition: 'color 0.3s ease-in-out',
      color: `${DEALROOMS_COLORS.cloudburst}`,
    },
  },
  '& .MuiSvgIcon-root': {
    color: `${DEALROOMS_COLORS.cloudburst}80`,
    fontSize: 24,
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      color: DEALROOMS_COLORS.cloudburst,
    },
  },
}));

export const DSInboxEmptyStateWrapper = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  color: theme.palette.text.secondary,
  padding: '2rem',
  textAlign: 'center',
}));

export const DSInboxEmptyStateTitle = styled('div')(({ theme }) => ({
  fontSize: '1.125rem',
  marginBottom: '0.5rem',
}));

export const DSInboxEmptyStateSubtitle = styled('div')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.disabled,
}));

export const DSInboxEmptyStateIcon = styled(NotificationsOff)(({ theme }) => ({
  fontSize: 32,
  color: theme.palette.text.secondary,
  marginBottom: '1rem',
}));

export const DSToolbarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: DEALROOMS_COLORS.white,
}));
