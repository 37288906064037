import { styled } from '@mui/material/styles';
import { Typography, Box, Card } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

// Create styled components similar to BaseArtifactCard but without actions
export const StyledExampleCard = styled(Card)({
  opacity: 0.4,
  border: `2px dashed ${DEALROOMS_COLORS.themeSecondary}`,
  borderRadius: '.25rem',
  transition: 'opacity 0.3s ease-out',
  backgroundColor: DEALROOMS_COLORS.white,
  maxHeight: '286px',
  maxWidth: '300px',
  minWidth: '300px',
  padding: '.5rem 1rem .5rem 1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '.25rem',
  position: 'relative',
  boxShadow: 'none',
});

export const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '.5rem',
  height: 'auto',
  position: 'relative',
  marginBottom: '.5rem',
});

export const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  flex: 1,
  minWidth: 0,
});

export const Title = styled(Typography)({
  fontSize: '1rem',
  margin: 0,
  padding: '.25rem 0 0 0',
  fontWeight: 600,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const Preview = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '8rem',
});

export const Activity = styled(Box)({
  display: 'flex',
  gap: '.5rem',
  alignItems: 'flex-start',
  marginTop: '.5rem',
});
