/**
 * DecisionSiteMeetingflowCard - A component that displays meeting information with progressive enhancement
 *
 * Data Loading Strategy:
 * ---------------------
 * This component implements a progressive enhancement strategy for loading and displaying meetingflow data:
 *
 * 1. Initial Render:
 *    - Can accept a pre-fetched meetingflow via the `meetingflow` prop (basic or detailed)
 *    - If provided, uses this data immediately for fast initial render
 *    - Shows basic information like title, date, time immediately
 *
 * 2. Detailed Data:
 *    - Always queries for detailed meetingflow data in the background using useMeetingflow hook
 *    - Certain features require detailed data and won't render until it's available:
 *      - Conference joining (needs conferenceInfo)
 *      - Summary icon (needs textSummary)
 *      - Attendees list (needs full attendee data)
 *      - Context menu (needs complete meetingflow data)
 *
 * 3. Data Sources:
 *    - providedMeetingflow: Pre-fetched data passed as prop (optional)
 *    - detailedMeetingflow: Full data fetched via hook
 *    - Components use the most detailed source available
 *
 * This approach ensures:
 * - Immediate rendering with basic data when available
 * - No loading spinner for basic information
 * - Graceful enhancement as detailed data loads
 * - Type safety between basic and detailed meetingflow types
 */

import { useAuth0 } from '@auth0/auth0-react';
import {
  BaseButton,
  Button,
  FontIcon,
  FontSizes,
  FontWeights,
  NeutralColors,
  mergeStyles,
} from '@fluentui/react';
import {
  Company,
  Contact,
  DetailedMeetingflow,
  Meetingflow,
  ListArtifactsData,
  DealRoomArtifact,
  DealRoomMeetingflowArtifact,
} from '@meetingflow/common/Api/data-contracts';
import { GroupBy } from '@meetingflow/common/ArrayHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useOrganization } from '../../Hooks/useOrganization';
import { useUserProfile } from '../../Hooks/useProfile';
import { useMeetingflow } from '../DealRoom/Components/Journey/hooks/useMeetingflow';
import {
  DealRoomsApiClient,
  MeetingflowsApiClient,
} from '../../Services/NetworkCommon';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { ModifierAwareLink } from '../Common/ModifierAwareLink';
import StyledDateTime from '../StyledDateTime';
import {
  MeetingflowCardContainer,
  MeetingflowCardStack,
  DateTimeContainer,
  CompletedEventIcon,
} from './DecisionSiteMeetingflowCard.styles';
import { Typography, CircularProgress } from '@mui/material';
import {
  createMeetingflowLinkArtifact,
  deleteMeetingflowAndDecisionSiteArtifact,
  multipleDeleteMeetingflowAndDecisionSiteArtifact,
  removeMeetingflowArtifactFromDecisionSite,
  toggleMeetingflowFeature,
  toggleMeetingflowWelcome,
} from '../../utils/DecisionSiteMeetingflowHelpers';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { AsyncSpan } from '../HOC/AsyncIntrinsicElement';
import { useModifierAwareNavigate } from '../../Hooks/useModifierAwareNavigate';
import { DSTooltip } from '../DealRoom/DS/DSTooltip';
import randomColor from 'randomcolor';
import { Y } from '@syncedstore/core';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import { useCollabProvider } from '../../Hooks/useCollabProvider';
import DecisionSiteMeetingflowRecordButton from '../DealRoom/Components/Journey/DecisionSiteMeetingflowRecordButton';
import { MeetingPlanCallRecorderStatusQuery } from '../../QueryNames';
import { DecisionSiteMeetingflowCardSummaryIcon } from './DecisionSiteMeetingflowCard/DecisionSiteMeetingflowCardSummaryIcon';
import { DecisionSiteMeetingflowCardLastLoggedIcon } from './DecisionSiteMeetingflowCard/DecisionSiteMeetingflowCardLastLoggedIcon';
import { DecisionSiteMeetingflowCardJoinConferenceIcon } from './DecisionSiteMeetingflowCard/DecisionSiteMeetingflowCardJoinConferenceIcon';
import { DecisionSiteMeetingflowCardAttendeesIcon } from './DecisionSiteMeetingflowCard/DecisionSiteMeetingflowCardAttendeesIcon';
import { DecisionSiteMeetingflowCardContextMenu } from './DecisionSiteMeetingflowCard/DecisionSiteMeetingflowCardContextMenu';

const MAX_NUM_COMPANY_LOGOS_INLINE = 5;

export type MeetingFlowCardMeetingflow = Pick<
  Meetingflow,
  | 'id'
  | 'title'
  | 'startTime'
  | 'endTime'
  | 'externalId'
  | 'externalSeriesId'
  | 'conferenceInfo'
  | 'textSummary'
  | 'lastLoggedToCrm'
  | 'location'
  | 'callRecording'
  | 'owner'
  | 'organizer'
  | 'creator'
  | 'attendees'
  | 'companies'
>;

export type DecisionSiteMeetingflowCardProps = {
  meetingflowId: string;
  meetingflow?: Meetingflow | DetailedMeetingflow;
  onClick?: (meetingflowId: DetailedMeetingflow['id']) => void;
  onTextClick?: () => void;
  onContactClick?: (c: Contact['id']) => void;
  onCompanyClick?: (c: Company['id']) => void;
  hideShowConferenceJoinButton?: boolean;
  hideCRMIcon?: boolean;
  hideSummaryIcon?: boolean;
  hideAttendeesIcon?: boolean;
  hideCallRecordingIcon?: boolean;
  hideCallRecordingButton?: boolean;
  noTitleLink?: boolean;
  showTimesOnly?: boolean;
  showEndTime?: boolean;
  showContextualMenu?: boolean;
  showAddToDecisionSiteButton?: boolean;
  miniConferenceJoinButton?: boolean;
  handleCloseDialog?: () => void;
  refetchEvents?: () => Promise<unknown>;
};

export const DecisionSiteMeetingflowCard: React.FC<
  DecisionSiteMeetingflowCardProps
> = ({
  meetingflowId,
  meetingflow: providedMeetingflow,
  onTextClick,
  onClick,
  onContactClick,
  onCompanyClick,
  hideCRMIcon = false,
  hideSummaryIcon = false,
  hideAttendeesIcon = false,
  hideCallRecordingIcon = false,
  noTitleLink = false,
  hideCallRecordingButton = false,
  showTimesOnly = false,
  showEndTime = false,
  showContextualMenu = false,
  showAddToDecisionSiteButton = false,
  miniConferenceJoinButton = true,
  handleCloseDialog,
  refetchEvents,
}) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const userEmail = user?.email || '';
  const {
    slug: organizationSlug,
    role,
    organization,
    canCreatePlans,
    hasEntitlement,
    internalDomains,
  } = useOrganization();
  const { dealRoom, dealRoomRole } = useDealRoom();
  const dealRoomId = dealRoom?.id;

  const {
    userId,
    user: mfUser,
    workspacePreferences,
    updateWorkspacePreferencesAsync,
  } = useUserProfile();

  const { isDark } = useLightOrDarkMode();
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const modifierAwareNavigate = useModifierAwareNavigate();

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, meetingflowId]);

  const client = useQueryClient();

  const handleDelete = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflowId || !organizationSlug) return;

    const token = await getAccessTokenSilently();

    await deleteMeetingflowAndDecisionSiteArtifact({
      organizationSlug,
      dealRoomId,
      meetingflowId,
      token,
      queryClient,
      appInsights,
    });
  };

  const handleDeleteMeetingflow = useCallback(
    async (meetingflowId: string) => {
      if (!meetingflowId || !organizationSlug) return;
      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          MeetingflowsApiClient.deleteMeetingflow(
            organizationSlug,
            meetingflowId,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Deleting meetingflow...',
            success: () => {
              queryClient.invalidateQueries('ownedMeetingflows');
              handleCloseDialog?.();
              refetchEvents?.();

              return 'Meetingflow deleted successfully!';
            },
            error: 'Failed to delete meetingflow',
          },
        );
      } catch (err: unknown) {
        console.error('Error deleting meetingflow:', err);
      }
    },
    [
      organizationSlug,
      queryClient,
      getAccessTokenSilently,
      handleCloseDialog,
      refetchEvents,
    ],
  );

  const handleMultipleDelete = useCallback(
    async (meetingflow: DetailedMeetingflow) => {
      if (!organizationSlug || !meetingflow || !('dealRoomIds' in meetingflow))
        return;

      try {
        const token = await getAccessTokenSilently();
        await multipleDeleteMeetingflowAndDecisionSiteArtifact({
          organizationSlug,
          dealRoomIds: meetingflow.dealRoomIds as Array<number>,
          meetingflowId,
          token,
          appInsights,
          callback: () => {
            queryClient.invalidateQueries('ownedMeetingflows');
            queryClient.invalidateQueries('user-meetingflows');
            queryClient.invalidateQueries('organization');
            refetchEvents?.();
            handleCloseDialog?.();
          },
        });
      } catch (err: unknown) {
        console.error('Error deleting meetingflow:', err);
      }
    },
    [
      appInsights,
      getAccessTokenSilently,
      handleCloseDialog,
      meetingflowId,
      organizationSlug,
      queryClient,
      refetchEvents,
    ],
  );

  const handleRemoveArtifact = useCallback(async () => {
    if (!meetingflowId || !organizationSlug || !dealRoomId) return;

    try {
      const token = await getAccessTokenSilently();
      await removeMeetingflowArtifactFromDecisionSite({
        organizationSlug,
        dealRoomId,
        meetingflowId,
        token,
        queryClient,
        appInsights,
        callback: () => {
          queryClient.invalidateQueries('ownedMeetingflows');
          refetchEvents?.();
          handleCloseDialog?.();
        },
      });
    } catch (err: unknown) {
      console.error('Error removing meetingflow from decision site:', err);
    }
  }, [
    appInsights,
    dealRoomId,
    getAccessTokenSilently,
    handleCloseDialog,
    meetingflowId,
    organizationSlug,
    queryClient,
    refetchEvents,
  ]);

  const getMeetingDetails = useCallback(async () => {
    if (!meetingflowId || !organizationSlug) return;

    const token = await getAccessTokenSilently();
    return MeetingflowsApiClient.getMeetingflow(
      organizationSlug,
      meetingflowId,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  }, [meetingflowId, organizationSlug, getAccessTokenSilently]);

  const handleRemoveFromDecisionSite = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(null);
      // Get the meetingflow details to double check if it's part of some deal rooms
      const meetingflowData = await getMeetingDetails();
      const meetingflow = meetingflowData?.data;

      // If the meetingflow is also part of some deal rooms, delete it from all of them and delete it after that
      if (
        !dealRoomId &&
        organizationSlug &&
        meetingflow &&
        'artifactIds' in meetingflow &&
        'dealRoomIds' in meetingflow
      ) {
        // If the meetingflow is also part of some deal rooms, delete it from all of them and delete it after that
        await handleMultipleDelete(meetingflow);
        return;
      } else if (
        !dealRoomId ||
        (dealRoomId && !('dealRoomIds' in (meetingflow || {})))
      ) {
        // If the meetingflow is not part of any deal rooms, delete it
        await handleDeleteMeetingflow(meetingflowId);
        return;
      }

      await handleRemoveArtifact();
    },
    [
      dealRoomId,
      meetingflowId,
      organizationSlug,
      handleDeleteMeetingflow,
      getMeetingDetails,
      handleMultipleDelete,
      handleRemoveArtifact,
    ],
  );

  const [isArtifactFeatured, setIsArtifactFeatured] = useState(false);
  const [isArtifactInWelcome, setIsArtifactInWelcome] = useState(false);
  const [artifactId, setArtifactId] = useState<number | undefined>();

  const hookResult = useMeetingflow(meetingflowId);

  const {
    meetingflow: queriedMeetingflow,
    meetingflowFetched,
    refetchMeetingflow,
    recorderStatus,
    isLoading,
    refetchRecorderStatus,
    withDealRoomContacts,
    sortedAttendeesByDomain = {} as Record<string, Contact[]>,
    companies = [],
  } = providedMeetingflow
    ? {
        meetingflow: undefined,
        meetingflowFetched: true,
        refetchMeetingflow: () => {},
        recorderStatus: undefined,
        isLoading: false,
        refetchRecorderStatus: () => {},
        withDealRoomContacts: undefined as Contact[] | undefined,
        sortedAttendeesByDomain: hookResult.sortedAttendeesByDomain,
        companies: providedMeetingflow.companies || [],
      }
    : hookResult;

  const { meetingflow: detailedMeetingflow } = useMeetingflow(meetingflowId);

  const activeMeeting = providedMeetingflow || queriedMeetingflow;

  const { data: artifactsData } = useQuery<ListArtifactsData>(
    ['artifacts', dealRoomId, organizationSlug, meetingflowId],
    async () => {
      if (!dealRoomId || !organizationSlug || !meetingflowId) {
        throw new Error('Missing required parameters');
      }
      const token = await getAccessTokenSilently();
      const response = await DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    },
    {
      enabled: !!dealRoomId && !!organizationSlug && !!meetingflowId,
    },
  );

  useEffect(() => {
    if (artifactsData) {
      const artifact = artifactsData.find(
        (a: DealRoomArtifact) =>
          a.type === 'MEETINGFLOW' &&
          'meetingflowId' in a &&
          a.meetingflowId === meetingflowId,
      );
      setIsArtifactFeatured(!!artifact?.featuredAt);
      setIsArtifactInWelcome(artifact?.placement === 'WELCOME');
      setArtifactId(artifact?.id);
    }
  }, [artifactsData, meetingflowId]);

  const handleFeature = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflowId || !organizationSlug) return;

    const token = await getAccessTokenSilently();

    try {
      const newFeaturedState = await toggleMeetingflowFeature({
        organizationSlug,
        dealRoomId,
        meetingflowId,
        token,
        queryClient,
        appInsights,
      });
      setIsArtifactFeatured(newFeaturedState);
    } catch (err: unknown) {
      console.error('Error featuring meetingflow:', err);
    }
  };

  const handleWelcome = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflowId || !organizationSlug) return;

    const token = await getAccessTokenSilently();

    try {
      const newWelcomeState = await toggleMeetingflowWelcome({
        organizationSlug,
        dealRoomId,
        meetingflowId,
        token,
        queryClient,
        appInsights,
      });
      setIsArtifactInWelcome(newWelcomeState);
    } catch (err: unknown) {
      console.error('Error toggling welcome status for meetingflow:', err);
    }
  };

  useEffect(() => {
    if (meetingflowId) {
      refetchMeetingflow();
    }
  }, [meetingflowId, refetchMeetingflow]);

  const isAssociatedWithEvent = !!providedMeetingflow?.externalId;

  const peopleByDomain = useMemo(
    () =>
      GroupBy(
        providedMeetingflow?.attendees || [],
        (a) => a?.emailDomain || '',
      ),
    [providedMeetingflow?.attendees],
  );

  const attendeesWithoutCompany = useMemo(() => {
    const companyDomains = providedMeetingflow?.companies
      ?.map((c) => c.domains?.map((d) => d?.domain || '') || [])
      .flat();
    return (
      Object.keys(peopleByDomain || {})
        .filter((key) => !companyDomains?.includes(key))
        .flatMap((key) => peopleByDomain[key] || []) ?? []
    );
  }, [providedMeetingflow?.companies, peopleByDomain]);

  const externalCompanies = useMemo(
    () => providedMeetingflow?.companies?.filter((c) => !c?.isInternal) || [],
    [providedMeetingflow?.companies],
  );

  const badgeWidth = 2.125;
  const iconWidth = 1.466;

  const eventIsInPast = useMemo(() => {
    if (!providedMeetingflow?.endTime) return false;
    const now = DateTime.now();
    const endTime = DateTime.fromISO(providedMeetingflow?.endTime);
    return now > endTime;
  }, [providedMeetingflow?.endTime]);

  const isNowOrSoon = providedMeetingflow
    ? providedMeetingflow?.conferenceInfo &&
      DateTime.fromISO(providedMeetingflow?.startTime).diffNow('minutes')
        .minutes < 60 &&
      DateTime.fromISO(providedMeetingflow?.endTime).diffNow('minutes')
        .minutes > -10
    : false;

  const beforeStart = providedMeetingflow
    ? DateTime.fromISO(providedMeetingflow.startTime).diffNow().milliseconds > 0
    : true;
  const beforeEnd = providedMeetingflow
    ? DateTime.fromISO(providedMeetingflow.endTime).diffNow().milliseconds > 0
    : true;

  const companyClass = mergeStyles({
    display: 'block',
    marginBottom: '.5rem',
  });

  const companyNameClass = mergeStyles({
    transition: '.3s ease-in-out all',
    color: DEALROOMS_COLORS.cloudburst,
    cursor: 'pointer',
  });

  const onCompanyCardClick = useCallback(
    (id: number, e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e?.stopPropagation();
      navigate(`/organization/${organizationSlug}/library/companies/${id}`);
    },
    [navigate, organizationSlug],
  );

  const onConferenceIconClick = useCallback(() => {
    window.open(providedMeetingflow?.conferenceInfo?.joinUri, '_blank');
    appInsights.trackEvent({
      name: 'JOIN_CONFERENCE_CALL',
      properties: {
        organizationId: organization?.id,
        type: providedMeetingflow?.conferenceInfo?.type,
      },
    });
  }, [
    appInsights,
    providedMeetingflow?.conferenceInfo?.joinUri,
    providedMeetingflow?.conferenceInfo?.type,
    organization?.id,
  ]);

  const onWSMessage = useCallback(
    (messageType: (typeof WS_MESSAGE)[keyof typeof WS_MESSAGE]) => {
      switch (messageType) {
        case WS_MESSAGE.REFRESH_PLAN: {
          refetchMeetingflow();
          return true;
        }
        case WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS: {
          if (
            !!organizationSlug &&
            !!meetingflowId &&
            hasEntitlement('CALL_RECORDING') &&
            !!providedMeetingflow?.callRecording?.id
          ) {
            client.removeQueries(
              MeetingPlanCallRecorderStatusQuery(
                organizationSlug,
                meetingflowId,
              ),
            );

            refetchRecorderStatus();
          }
          return true;
        }

        case WS_MESSAGE.HEARTBEAT:
        case WS_MESSAGE.RECREATE:
        case WS_MESSAGE.SAVE: {
          return true;
        }
        default: {
          return false;
        }
      }
    },
    [
      client,
      hasEntitlement,
      meetingflowId,
      organizationSlug,
      providedMeetingflow?.callRecording?.id,
      refetchRecorderStatus,
      refetchMeetingflow,
    ],
  );

  const { provider } = useCollabProvider({
    providerName: 'MEETINGPLAN',
    documentName: `MeetingPlan__${organizationSlug || ''}__${meetingflowId || ''}`,
    ydoc,
    color,
    email: userEmail || '',
    name: mfUser?.name || user?.name || '',
    picture: mfUser?.avatarFileUrl || mfUser?.avatarUrl || user?.picture || '',
    onMessage: onWSMessage,
  });
  const addLinkClass = mergeStyles({
    marginRight: '0',
    color: MEETINGFLOW_COLORS.white,
    cursor: 'pointer',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    backgroundColor: DEALROOMS_COLORS.themeSecondary,
    borderRadius: '.5rem',
    padding: '0 .5rem .0 .5rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    justifyItems: 'center',
    maxHeight: '1rem',
    textTransform: 'uppercase',
    transition: 'all .3s ease-in-out',
    lineHeight: '18px',
    position: 'relative',
    top: '0',

    '&:hover': {
      backgroundColor: DEALROOMS_COLORS.themePrimary,
    },

    i: {
      marginRight: '.25rem',
      fontWeight: FontWeights.regular,
      color: 'white',
    },
  });

  const addMeetingflowToDecisionSite = async (
    e: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement
    >,
    scheduleCallRecording = false,
  ): Promise<unknown> => {
    e.stopPropagation();
    const token = await getAccessTokenSilently();

    try {
      if (!dealRoomId) {
        throw new Error('No dealRoomId provided');
      }

      return await createMeetingflowLinkArtifact(
        organizationSlug!,
        dealRoomId,
        meetingflowId,
        queryClient,
        token,
      );
    } catch (error) {
      toast.error('Failed to add meetingflow to Decision Site');
      return error;
    }
  };

  const mfIconSx = useMemo(
    () => ({
      color: DEALROOMS_COLORS.cloudburst,
      cursor: 'pointer',
      height: '16px',
      width: '16px',
      borderRadius: '50%',
    }),
    [],
  );

  const mfIconContainerSx = useMemo(
    () => ({
      width: '26px',
      height: '26px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
    }),
    [],
  );

  const addToDecisionSiteButton = useMemo(() => {
    if (!activeMeeting || dealRoomRole !== 'SELLER') {
      return null;
    }

    return (
      <AsyncSpan
        className={classNames(addLinkClass, '')}
        onClick={addMeetingflowToDecisionSite}
      >
        Add
      </AsyncSpan>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addLinkClass, addMeetingflowToDecisionSite]);

  const conferenceIcon = useMemo(() => {
    if (detailedMeetingflow?.conferenceInfo) {
      return (
        <DecisionSiteMeetingflowCardJoinConferenceIcon
          meetingflow={detailedMeetingflow}
          onConferenceIconClick={onConferenceIconClick}
          mini={miniConferenceJoinButton}
        />
      );
    }
    return null;
  }, [detailedMeetingflow, onConferenceIconClick, miniConferenceJoinButton]);

  const summaryIcon = useMemo(() => {
    if (detailedMeetingflow?.textSummary && !hideSummaryIcon) {
      return (
        <DecisionSiteMeetingflowCardSummaryIcon
          meetingflow={detailedMeetingflow}
          hideSummaryIcon={hideSummaryIcon}
        />
      );
    }
    return null;
  }, [detailedMeetingflow, hideSummaryIcon]);

  const attendeesIcon = useMemo(() => {
    if (
      detailedMeetingflow?.attendees &&
      sortedAttendeesByDomain &&
      !hideAttendeesIcon &&
      Object.keys(sortedAttendeesByDomain).length > 0
    ) {
      return (
        <DecisionSiteMeetingflowCardAttendeesIcon
          meetingflow={detailedMeetingflow}
          hideAttendeesIcon={hideAttendeesIcon}
          sortedAttendeesByDomain={sortedAttendeesByDomain}
          companies={companies || []}
          withDealRoomContacts={withDealRoomContacts}
          mfIconSx={mfIconSx}
          findCompanyByDomain={(
            companies: Company[] | undefined,
            domain: string,
          ) => {
            return companies?.find((company) =>
              company.domains?.some((d) => d.domain === domain),
            );
          }}
        />
      );
    }
    return null;
  }, [
    detailedMeetingflow,
    hideAttendeesIcon,
    sortedAttendeesByDomain,
    companies,
    withDealRoomContacts,
    mfIconSx,
  ]);

  const lastLoggedIcon = useMemo(() => {
    if (!!detailedMeetingflow?.lastLoggedToCrm?.loggedAt && !hideCRMIcon) {
      return (
        <DecisionSiteMeetingflowCardLastLoggedIcon
          meetingflow={detailedMeetingflow}
          hideCRMIcon={hideCRMIcon}
        />
      );
    }
    return null;
  }, [detailedMeetingflow, hideCRMIcon]);

  const completedLinkClass = mergeStyles({
    marginRight: '.5rem',
    color: isDark ? DEALROOMS_COLORS.themePrimary : DEALROOMS_COLORS.woodsmoke,
    cursor: 'default',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.regular,
    borderRadius: '8px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    justifyItems: 'center',
    width: '16px',
    height: '16px',
    transition: 'all .3s ease-in-out',
    backgroundColor: isDark ? NeutralColors.gray160 : NeutralColors.gray30,

    i: {
      margin: 0,
      fontSize: '10px',
    },
  });

  const handleStatusChange = useCallback(() => {
    refetchMeetingflow();
    refetchRecorderStatus();
  }, [refetchMeetingflow, refetchRecorderStatus]);

  const card = (
    <MeetingflowCardStack
      direction="row"
      onClick={() => onClick && onClick(meetingflowId)}
    >
      <div className="mf-details">
        <Typography
          className="meetingflow-title"
          onClick={() => onClick && onClick(meetingflowId)}
          aria-label={activeMeeting?.title}
          title={activeMeeting?.title}
          noWrap
        >
          {showTimesOnly && activeMeeting?.startTime ? (
            <>
              <CompletedEventIcon>
                {eventIsInPast && !showTimesOnly && (
                  <DSTooltip
                    title={`This event was in the past and can no longer be recorded. ${showAddToDecisionSiteButton ? 'You can still add to your Decision Site.' : ''}`}
                    placement="top"
                  >
                    <span className={completedLinkClass}>
                      <FontIcon iconName="CheckMark" />
                    </span>
                  </DSTooltip>
                )}
              </CompletedEventIcon>
              <span className="short-time">
                <StyledDateTime
                  useRelativeDates
                  dateTime={activeMeeting?.startTime}
                  displayComponents={
                    showTimesOnly ? ['time'] : ['date', 'time']
                  }
                  shortMonths
                />
              </span>
            </>
          ) : null}{' '}
          {noTitleLink ? (
            activeMeeting?.title
          ) : (
            <ModifierAwareLink
              defaultToNewTab={false}
              href={`/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${meetingflowId}`}
              as={'a'}
            >
              {activeMeeting?.title}
            </ModifierAwareLink>
          )}
        </Typography>
        <Typography
          className="mf-datetime"
          onClick={() => onClick && onClick(meetingflowId)}
          noWrap
        >
          {activeMeeting?.startTime ||
          (activeMeeting?.location &&
            !activeMeeting?.conferenceInfo?.joinUri) ? (
            <DateTimeContainer>
              {!showTimesOnly && (
                <>
                  {eventIsInPast && (
                    <DSTooltip
                      title={`This event was in the past and can no longer be recorded. ${showAddToDecisionSiteButton ? 'You can still add to your Decision Site.' : ''}`}
                      placement="top"
                    >
                      <span className={completedLinkClass}>
                        <FontIcon iconName="CheckMark" />
                      </span>
                    </DSTooltip>
                  )}
                  <StyledDateTime
                    useRelativeDates
                    dateTime={activeMeeting?.startTime}
                    displayComponents={
                      showTimesOnly ? ['time'] : ['date', 'time']
                    }
                    shortMonths
                  />
                  {activeMeeting?.endTime && showEndTime && (
                    <>
                      {'–'}{' '}
                      <StyledDateTime
                        dateTime={activeMeeting?.endTime}
                        displayComponents={['time']}
                        includeTimeZone
                      />
                    </>
                  )}
                </>
              )}
            </DateTimeContainer>
          ) : null}
        </Typography>
      </div>
    </MeetingflowCardStack>
  );

  const activeMeetingflow = useMemo(() => {
    const findCompanyByDomain = (domain: string) => {
      return companies?.find((company) =>
        company.domains?.some((d) => d.domain === domain),
      );
    };

    return providedMeetingflow || queriedMeetingflow || detailedMeetingflow;
  }, [providedMeetingflow, queriedMeetingflow, detailedMeetingflow, companies]);

  if (!activeMeetingflow || !meetingflowId || !organizationSlug) {
    return null;
  }

  if (isLoading) {
    return (
      <MeetingflowCardContainer
        sx={{
          justifyContent: 'center',
          minHeight: '40px',
        }}
      >
        <CircularProgress size={24} />
      </MeetingflowCardContainer>
    );
  }

  return (
    <MeetingflowCardContainer>
      <div className="meetingflow-details">{card}</div>
      <div
        className="mf-icons"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px',
          lineHeight: '12px',
        }}
      >
        {summaryIcon}
        {lastLoggedIcon}
        {attendeesIcon}
        {!hideCallRecordingButton && (
          <DecisionSiteMeetingflowRecordButton
            meetingflowId={meetingflowId!}
            onStatusChange={handleStatusChange}
            mini
            iconOnly={'when-inactionable'}
          />
        )}
        {hideCallRecordingIcon ? null : conferenceIcon}
        {showAddToDecisionSiteButton && dealRoomRole === 'SELLER'
          ? addToDecisionSiteButton
          : null}
      </div>
      {showContextualMenu && organizationSlug && detailedMeetingflow ? (
        <DecisionSiteMeetingflowCardContextMenu
          meetingflow={detailedMeetingflow}
          dealRoomRole={dealRoomRole}
          organizationSlug={organizationSlug}
          meetingflowId={meetingflowId}
          isArtifactFeatured={isArtifactFeatured}
          isArtifactInWelcome={isArtifactInWelcome}
          hasEntitlement={hasEntitlement}
          getAccessTokenSilently={getAccessTokenSilently}
          handleFeature={handleFeature}
          handleWelcome={handleWelcome}
          handleRemoveFromDecisionSite={handleRemoveFromDecisionSite}
          handleDelete={handleDelete}
          provider={provider}
          client={client}
          refetchRecorderStatus={refetchRecorderStatus}
          refetchMeetingflow={refetchMeetingflow}
        />
      ) : null}
    </MeetingflowCardContainer>
  );
};
