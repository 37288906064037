import {
  styled,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

// Define a type for the size variants to ensure consistency
type SizeVariant = 'default' | 'large' | 'small' | 'xsmall';

interface StyledProps {
  $size?: SizeVariant;
}

export const ContactListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== '$size',
})<StyledProps>(({ $size }) => {
  return {
    margin: 0,
    padding: 0,
    alignItems: $size === 'large' ? 'flex-start' : 'center',
    display: 'flex',
    gap:
      $size === 'large'
        ? '1rem'
        : $size === 'small'
          ? '.5rem'
          : $size === 'xsmall'
            ? '.25rem'
            : '.75rem',
  };
});

export const ContactListItemAvatar = styled(ListItemAvatar, {
  shouldForwardProp: (prop) => prop !== '$size',
})<StyledProps>(({ $size }) => {
  const baseSize =
    $size === 'large'
      ? 40
      : $size === 'small'
        ? 24
        : $size === 'xsmall'
          ? 20
          : 32;
  return {
    width: `${baseSize}px`,
    minWidth: `${baseSize}px`,
    position: 'relative',
    height: `${baseSize}px`,
    backgroundColor: 'transparent',
    marginRight: $size === 'xsmall' ? '.5rem' : '0',
  };
});

export const LinkedInBadge = styled('a', {
  shouldForwardProp: (prop) => prop !== '$size',
})<StyledProps>(({ $size }) => {
  const badgeSize =
    $size === 'large'
      ? 20
      : $size === 'small'
        ? 12
        : $size === 'xsmall'
          ? 10
          : 16;
  const iconSize =
    $size === 'large'
      ? 15
      : $size === 'small'
        ? 8
        : $size === 'xsmall'
          ? 6
          : 12;
  return {
    position: 'absolute',
    bottom: -4,
    right: -4,
    width: badgeSize,
    height: badgeSize,
    borderRadius: '50%',
    backgroundColor: '#0077b5', // LinkedIn Blue
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid white',
    '& svg': {
      width: iconSize,
      height: iconSize,
      color: 'white',
    },
  };
});

export const ContactListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== '$size',
})<StyledProps>(({ theme, $size }) => ({
  margin: 0,
  padding: $size === 'small' || $size === 'xsmall' ? '0' : '.125rem 0 0 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', // Help with vertical centering
  height: $size === 'xsmall' ? '28px' : $size === 'small' ? '32px' : '40px',
  gap: $size === 'small' || $size === 'xsmall' ? '0' : '.125rem',
  position: 'relative',
  '& .MuiListItemText-primary': {
    margin: 0,
    lineHeight:
      $size === 'xsmall' ? '14px' : $size === 'small' ? '16px' : '20px',
    fontSize:
      $size === 'xsmall'
        ? '0.75rem'
        : $size === 'small'
          ? '0.85rem'
          : 'inherit',
    whiteSpace: $size === 'small' || $size === 'xsmall' ? 'nowrap' : 'initial',
    overflow: $size === 'small' || $size === 'xsmall' ? 'hidden' : 'initial',
    textOverflow:
      $size === 'small' || $size === 'xsmall' ? 'ellipsis' : 'initial',
  },
  '& .MuiListItemText-secondary': {
    color: DEALROOMS_COLORS.cloudburst,
    margin: 0,
    position: 'relative',
    top:
      $size === 'xsmall'
        ? '-.1rem'
        : $size === 'small'
          ? '-.125rem'
          : '-.25rem',
    fontSize:
      $size === 'xsmall' ? '0.7rem' : $size === 'small' ? '0.75rem' : 'inherit',
    whiteSpace: $size === 'small' || $size === 'xsmall' ? 'nowrap' : 'initial',
    overflow: $size === 'small' || $size === 'xsmall' ? 'hidden' : 'initial',
    textOverflow:
      $size === 'small' || $size === 'xsmall' ? 'ellipsis' : 'initial',
  },
}));

export const SocialIconsStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== '$size',
})<StyledProps>(({ $size }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap:
    $size === 'xsmall' ? '0.1rem' : $size === 'small' ? '0.125rem' : '0.25rem',
  marginLeft:
    $size === 'xsmall'
      ? '-0.1rem'
      : $size === 'small'
        ? '-0.125rem'
        : '-0.25rem',
}));
