import {
  IconButton,
  mergeStyleSets,
  Modal,
  Stack,
  Text,
} from '@fluentui/react';
import {
  CheckMarkIconProps,
  ChromeCloseIconProps,
} from '../../../utils/iconProps';
import {
  DealRoomAsyncPrimaryButton,
  DealRoomCommandBarButton,
} from '../Components/DealRoomButton';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { useMilestonesSummary } from '../../../Hooks/useMilestonesSummary';
import { useActionItems } from '../../../Hooks/useActionItems';
import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';

type DeleteTaskDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  onResolve: (value: unknown) => void;
  onDismiss: () => void;
  taskId: number;
};

export const DeleteTaskDialog = ({
  organizationSlug,
  dealRoomId,
  onResolve,
  onDismiss,
  taskId,
}: DeleteTaskDialogProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const { milestonesSummary, refetch: refetchMilestonesSummary } =
    useMilestonesSummary(organizationSlug, dealRoomId);
  const { actionItems, refetch: refetchOrganizationActionItems } =
    useActionItems(organizationSlug);

  const handleDeleteTask = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.deleteActionItem(
          organizationSlug,
          dealRoomId,
          taskId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Deleting task',
          success: () => {
            Promise.all([
              refetchMilestonesSummary(),
              refetchMutualPlan(),
              refetchOrganizationActionItems(),
            ]);
            onResolve(null);
            return 'Successfully deleted the task';
          },
          error: 'Something went wrong deleting the task',
        },
      );
    } catch (error) {}
  }, [
    dealRoomId,
    getAccessTokenSilently,
    onResolve,
    organizationSlug,
    refetchMilestonesSummary,
    refetchMutualPlan,
    taskId,
    refetchOrganizationActionItems,
  ]);

  const styles = mergeStyleSets({
    modalWrapper: {
      '& > .ms-Overlay': {
        backdropFilter: 'blur(3px)',
      },
      '.ms-Dialog-main': {
        minHeight: 'fit-content',
        backgroundColor: 'transparent',
      },
    },
    contentContainer: {
      width: 'calc(100vw - 3rem)',
      padding: '1rem 1.5rem 1.5rem',
      maxWidth: '640px',
      backgroundColor: DEALROOMS_COLORS.white,
      borderRadius: '.5rem',
      boxShadow: '0px 8px 16px 0px #00000024',
      gap: '1.5rem',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      fontWeight: '400',
    },
    closeButton: {
      i: {
        fontSize: '.75rem',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    footerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: '1rem',
      flexWrap: 'wrap',
      '> .cancel-button': {
        padding: '0.5rem 1rem',
        justifyContent: 'center',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.darkerGray,
        },
      },
      '> .delete-button': {
        padding: '0.5rem 1rem',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.white,
        },
      },
    },
    contentTitle: {
      fontWeight: '400',
      fontSize: '.9375rem',
      lineHeight: '1.5rem',
      color: DEALROOMS_COLORS.themeSecondary,
    },
  });

  return (
    <Modal className={styles.modalWrapper} isOpen onDismiss={onDismiss}>
      <Stack className={styles.contentContainer}>
        <Stack.Item className={styles.headerContainer}>
          <Text className={styles.title}>
            Are you sure you want to delete this task?
          </Text>
          <IconButton
            className={styles.closeButton}
            ariaLabel="Close"
            iconProps={ChromeCloseIconProps}
            onClick={onDismiss}
            alt="Close image"
          />
        </Stack.Item>
        <Stack.Item className={styles.content}>
          <Text className={styles.contentTitle}>
            This action cannot be undone, and all associated data will be
            permanently lost.
          </Text>
        </Stack.Item>
        <Stack.Item className={styles.footerContainer}>
          <DealRoomCommandBarButton
            customClasses="cancel-button"
            buttonStyleType="COMMAND_BAR"
            text="Cancel"
            onClick={onDismiss}
          />
          <DealRoomAsyncPrimaryButton
            customClasses="delete-button"
            iconProps={CheckMarkIconProps}
            onClick={handleDeleteTask}
            text="Delete task"
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};
