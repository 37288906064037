import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { getSortableItemStyles } from './DSSortableItem.styles';

interface DSSortableItemProps {
  id: number | string;
  children: React.ReactNode;
  disableDrag?: boolean;
}

export const DSSortableItem: React.FC<DSSortableItemProps> = ({
  id,
  children,
  disableDrag = false,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
  } = useSortable({
    id,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  const styles = getSortableItemStyles(
    CSS.Transform.toString(transform) || '',
    transition,
    isDragging,
  );

  return (
    <div ref={setNodeRef} className={styles.root}>
      {React.cloneElement(children as React.ReactElement, {
        dragHandleProps: disableDrag ? null : { ...attributes, ...listeners },
        isDragging,
        isSorting,
      })}
    </div>
  );
};
