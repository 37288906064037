export const getUrlFileType = (url: string) => {
  try {
    const u = new URL(url);
    const ext = u.pathname.split('.').pop();
    return ext === '/' ? undefined : ext?.toLowerCase();
  } catch (_e: unknown) {
    return undefined;
  }
};

export const absoluteUrl = (urlString: string) => {
  try {
    return new URL(urlString);
  } catch (_e) {
    return new URL(urlString, document.baseURI);
  }
};

interface UrlValidationOptions {
  allowMailto?: boolean;
}

export const isUrl = (maybeUrl: string, options: UrlValidationOptions = {}) => {
  // Special handling for mailto links if allowed
  if (options.allowMailto && maybeUrl.toLowerCase().startsWith('mailto:')) {
    // Validates a mailto link by checking it has:
    // - mailto: at the start
    // - optionally // (for mailto://user@example.com)
    // - a local part (before the @ symbol) consisting of one or more of the following characters:
    //   - alphanumeric characters (a-z, 0-9, A-Z)
    //   - dots (.)
    //   - underscores (_)
    //   - percent signs (%)
    //   - plus signs (+)
    //   - hyphens (-)
    // - an @ symbol
    // - a domain name (after the @ symbol) consisting of one or more of the following characters:
    //   - alphanumeric characters (a-z, 0-9, A-Z)
    //   - dots (.)
    //   - hyphens (-)
    // - a dot
    // - a domain suffix (after the last dot) consisting of between 2 and 63 letters
    const mailtoRegex =
      /^mailto:(\/\/)?([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,63})$/;
    return mailtoRegex.test(maybeUrl);
  }

  // try {
  //   new URL(maybeUrl);
  //   return true;
  // } catch (_e: unknown) {
  //   return false;
  // }

  // This regex pattern validates URLs with the following criteria:
  // - Optional protocol (http, https, ftp)
  // - Optional authentication (username:password@)
  // - Domain name or IP address (excluding private IP ranges)
  // - Optional port number
  // - Optional path, query parameters, and fragment

  const urlPattern =
    /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

  // Detailed breakdown of the regex:
  // ^                                            Start of the string
  // (?:(?:(?:https?|ftp):)?\/\/)?                Optional protocol (http:// or https:// or ftp://)
  // (?:\S+(?::\S*)?@)?                           Optional authentication (username:password@)
  // (?:
  //   (?!(?:10|127)(?:\.\d{1,3}){3})             Negative lookahead for private IP ranges
  //   (?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})
  //   (?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})
  //   (?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])         First octet of IP address
  //   (?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}    Second and third octets
  //   (?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))  Fourth octet
  // |                                            OR
  //   (?:(?:[a-z\u00a1-\uffff0-9]-*)*            Domain name
  //   [a-z\u00a1-\uffff0-9]+)
  //   (?:\.(?:[a-z\u00a1-\uffff0-9]-*)*
  //   [a-z\u00a1-\uffff0-9]+)*
  //   (?:\.(?:[a-z\u00a1-\uffff]{2,}))           TLD
  // )
  // (?::\d{2,5})?                                Optional port number
  // (?:[/?#]\S*)?                                Optional path, query parameters, and fragment
  // $                                            End of the string
  // i                                            Case-insensitive flag

  return urlPattern.test(maybeUrl);
};

export const ensureValidProtocol = (url: string): string => {
  // List of protocols considered valid
  const validProtocols = ['http', 'https', 'ftp', 'mailto'];

  // Extract the protocol from the URL
  const protocol = url.trim().split(':')[0].toLowerCase();

  // Check if the extracted protocol is in the list of valid protocols
  if (validProtocols.includes(protocol)) {
    // If valid, return the original URL
    return url;
  } else {
    // If not valid, prepend 'http://' to the URL
    // This assumes 'http' as the default protocol
    return `http://${url}`;
  }
};

export const validateDomain = (
  domain: string,
): { isValid: boolean; message: string } => {
  if (!domain) {
    return {
      isValid: false,
      message: 'Company domain is required',
    };
  }

  try {
    // Remove protocol if present
    const cleanDomain = domain.replace(/^(https?:\/\/)/, '');
    // Basic domain validation regex
    const domainRegex =
      /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

    if (!domainRegex.test(cleanDomain)) {
      return {
        isValid: false,
        message: 'Please enter a valid domain (e.g., augment.co)',
      };
    }

    return {
      isValid: true,
      message: '',
    };
  } catch (error) {
    return {
      isValid: false,
      message: 'Invalid domain format',
    };
  }
};

export const isExternalURL = (urlString: string) => {
  try {
    const url = new URL(urlString);

    // DOES THIS URL ORIGINATE FROM THIS WEBSITE?
    if (url.origin !== new URL(document.URL, document.baseURI).origin) {
      return true;
    }
  } catch (_e) {
    new URL(urlString, document.baseURI);
  }

  return false;
};

export const isMeetingflowURL = (maybeUrl: string) =>
  isUrl(maybeUrl) &&
  ['app.meetingflow.com', 'localhost'].includes(new URL(maybeUrl).hostname);

export const isExternalImageUrl = (maybeUrl: string) =>
  isUrl(maybeUrl) &&
  !['app.meetingflow.com', 'localhost'].includes(new URL(maybeUrl).hostname) &&
  ['png', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'webp', 'tiff'].includes(
    getUrlFileType(maybeUrl) || '',
  );

export const canAccessUrl = async (maybeUrl: string): Promise<boolean> => {
  if (!isUrl(maybeUrl)) {
    return false;
  }

  try {
    const response = await fetch(maybeUrl, { method: 'GET' });
    return response.ok;
  } catch (_e: unknown) {
    return false;
  }
};

export const externalize = (urlString: string) => {
  try {
    new URL(urlString);
    return urlString;
  } catch (e: unknown) {
    if (urlString.startsWith(`/`)) {
      return window.origin + urlString;
    }

    return `http://${urlString}`;
  }
};

export type PageMeta = {
  type?: string;
  siteName?: string;
  locale?: string;
  title?: string;
  description?: string;
  image?: string;
  imageAlt?: string;
  url?: string;
};

export const getPageMeta = async (
  token: string,
  urlString: string,
): Promise<PageMeta> => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);
  const response = await fetch(
    `/api/proxy?url=${encodeURIComponent(externalize(urlString))}`,
    { method: 'GET', headers },
  );
  if (!response.ok) {
    throw new Error(`Failed to proxy request`);
  }
  const html = await response.text();
  const page = new DOMParser().parseFromString(html, 'text/html');

  const type =
    page.querySelector("meta[property='og:type']")?.getAttribute('content') ||
    undefined;
  const locale =
    page.querySelector("meta[property='og:locale']")?.getAttribute('content') ||
    undefined;
  const siteName =
    page
      .querySelector("meta[property='og:site_name']")
      ?.getAttribute('content') ||
    page
      .querySelector("meta[name='application-name']")
      ?.getAttribute('content') ||
    undefined;
  const title =
    page.querySelector("meta[property='og:title']")?.getAttribute('content') ||
    page.querySelectorAll('title')[0]?.innerText;
  const description =
    page
      .querySelector("meta[property='og:description']")
      ?.getAttribute('content') ||
    page.querySelector("meta[name='description']")?.getAttribute('content') ||
    undefined;
  const image =
    page.querySelector("meta[property='og:image']")?.getAttribute('content') ||
    undefined;
  const imageAlt =
    page
      .querySelector("meta[property='og:image:alt']")
      ?.getAttribute('content') || undefined;
  const url =
    page.querySelector("meta[property='og:url']")?.getAttribute('content') ||
    undefined;

  return {
    type,
    siteName,
    locale,
    title,
    description,
    image,
    imageAlt,
    url,
  };
};

export const isUrlReachable = (
  url: string,
  timeout = 6000,
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (!isUrl(url)) resolve(false);
    try {
      fetch(url)
        .then(() => resolve(true))
        .catch(() => resolve(false));
      setTimeout(() => {
        resolve(false);
      }, timeout);
    } catch (e) {
      reject(e);
    }
  });
};
