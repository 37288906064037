import { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  LightbulbOutlined,
  PlaylistAddCheckOutlined,
  TrendingUpOutlined,
  InsightsOutlined,
  SummarizeOutlined,
} from '@mui/icons-material';
import {
  HighlightsLoadingContainer,
  HighlightsStateContainer,
} from './DSHighlightsCarousel.styles';
import { DSHighlightsCard } from './DSHighlightsCard';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import DSHighlightsCarousel from './DSHighlightsCarousel';
import { DSTabs, DSTab } from '../../DS';
import { useJourneySummary, USE_MOCK_DATA, MOCK_SUMMARY_DATA, SummaryResponse } from '../hooks/useJourneySummary';

type SummaryField = keyof Omit<SummaryResponse, 'generated_at'>;

interface DSJourneyHighlightsCarouselProps {
  showSideButtons?: boolean; // Optional prop to control side button visibility
  setHasJourneySummary?: (hasJourneySummary: boolean) => void; // Optional callback to update parent state
}

export const DSJourneyHighlightsCarousel: FC<
  DSJourneyHighlightsCarouselProps
> = ({
  showSideButtons = false, // Hidden by default
  setHasJourneySummary,
}) => {
  const [timestamp, setTimestamp] = useState<string | null>(null);
  const { data, isLoading, error } = useJourneySummary();

  // Set timestamp when data is loaded
  useEffect(() => {
    if (data?.generated_at) {
      setTimestamp(data.generated_at);
    }
  }, [data]);

  // If we're using mock data, we'll always have data to display
  const displayData = USE_MOCK_DATA ? MOCK_SUMMARY_DATA : data;

  if (isLoading && !USE_MOCK_DATA) {
    return (
      <HighlightsLoadingContainer>
        <CircularProgress size={20} />
      </HighlightsLoadingContainer>
    );
  }

  if (error && !USE_MOCK_DATA) {
    return (
      <HighlightsStateContainer>
        <Typography align="center">
          Failed to load highlights. Please try again later.
        </Typography>
      </HighlightsStateContainer>
    );
  }

  if (!displayData) {
    return (
      <HighlightsStateContainer>
        <Typography align="center">No highlights available.</Typography>
      </HighlightsStateContainer>
    );
  }

  // Define highlight card configuration type
  interface HighlightConfig {
    key: string;
    title: string;
    content: string;
    icon: React.ReactNode;
  }

  // Define highlight card configurations
  const highlightConfigs = [
    displayData?.overview
      ? {
          key: 'overview',
          title: 'Overview',
          content: displayData.overview,
          icon: (
            <InsightsOutlined
              sx={{ fontSize: 20, color: DEALROOMS_COLORS.peacock }}
            />
          ),
        }
      : null,
    displayData?.key_changes
      ? {
          key: 'key_changes',
          title: 'Key Changes',
          content: displayData.key_changes,
          icon: (
            <LightbulbOutlined
              sx={{ fontSize: 20, color: DEALROOMS_COLORS.orchid }}
            />
          ),
        }
      : null,
    displayData?.next_steps
      ? {
          key: 'next_steps',
          title: 'Next Steps',
          content: displayData.next_steps,
          icon: (
            <PlaylistAddCheckOutlined
              sx={{ fontSize: 20, color: DEALROOMS_COLORS.artic }}
            />
          ),
        }
      : null,
    displayData?.risks_opportunities
      ? {
          key: 'risks_opportunities',
          title: 'Risks & Opportunities',
          content: displayData.risks_opportunities,
          icon: (
            <TrendingUpOutlined
              sx={{ fontSize: 20, color: DEALROOMS_COLORS.crimson }}
            />
          ),
        }
      : null,
  ].filter(Boolean) as HighlightConfig[];

  // Create an array of React nodes for the carousel
  const carouselItems = highlightConfigs.map((config) => (
    <DSHighlightsCard
      key={config.key}
      title={config.title}
      content={config.content}
      icon={config.icon}
    />
  ));

  return (
    <DSHighlightsCarousel
      items={carouselItems}
      timestamp={timestamp}
      showSideButtons={showSideButtons}
    />
  );
};

export default DSJourneyHighlightsCarousel;
