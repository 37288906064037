import { ReactNode, useState, useRef, useEffect, useCallback } from 'react';
import {
  DetailContainer,
  DetailLabel,
  DetailValue,
  DetailValueContainer,
  EditIcon,
  MeetingTypeLabel,
  StyledDatePicker,
  StyledSelect,
} from './DSDetail.styles';
import EditIconMui from '@mui/icons-material/Edit';
import {
  Box,
  ClickAwayListener,
  InputBase,
  styled,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import DSMenuItem from '../../DS/DSMenuItem';
import DSDatePicker from '../../DS/DSDatePicker';
import { DateTime } from 'luxon';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

// Custom inline input that preserves text position
const InlineInput = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'alignment',
})<{ alignment?: 'left' | 'right'; error?: boolean }>(
  ({ alignment = 'right', error = false }) => ({
    fontSize: '0.875rem',
    fontWeight: 600,
    padding: 0,
    lineHeight: 1.2, // Match the line height of DetailValue
    textAlign: alignment, // Align text based on prop
    '& .MuiInputBase-input': {
      padding: '0px',
      textAlign: alignment, // Align text based on prop
      color: error ? DEALROOMS_COLORS.crimson : 'inherit',
    },
  }),
);

interface DSDetailProps {
  label: string;
  value: string;
  icon?: ReactNode;
  editable?: boolean;
  type?: 'text' | 'date' | 'currency' | 'select';
  options?: string[];
  onSave?: (newValue: string) => void;
  maxWidth?: string | number;
  onClick?: () => void;
  alignment?: 'left' | 'right';
  overline?: string;
}

const DSDetail = ({
  label,
  value,
  icon,
  editable = false,
  type = 'text',
  options = [],
  onSave,
  maxWidth,
  onClick,
  alignment = 'left',
  overline,
}: DSDetailProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [hasInputError, setHasInputError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const valueRef = useRef<HTMLSpanElement>(null);
  const [inputWidth, setInputWidth] = useState<number | undefined>(undefined);

  // Calculate the width of the text to size the input field appropriately
  useEffect(() => {
    if (isEditing && valueRef.current) {
      // Add a small buffer to prevent text cutoff
      const calculatedWidth = valueRef.current.offsetWidth + 8;
      setInputWidth(Math.max(calculatedWidth, 80)); // Minimum width of 80px
    }
  }, [isEditing]);

  // Store the original container width to maintain it during editing
  const containerRef = useRef<HTMLDivElement>(null);
  const valueContainerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | undefined>(
    undefined,
  );
  const [valueContainerWidth, setValueContainerWidth] = useState<
    number | undefined
  >(undefined);

  // State to track hover
  const [isHovering, setIsHovering] = useState(false);

  // Capture widths when the component mounts
  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
    if (valueContainerRef.current) {
      setValueContainerWidth(valueContainerRef.current.offsetWidth);
    }
  }, []);

  const handleEditClick = () => {
    // Capture the current width right before editing starts
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
    if (valueContainerRef.current) {
      setValueContainerWidth(valueContainerRef.current.offsetWidth);
    }

    setInputValue(value);
    setHasInputError(false);
    setIsEditing(true);
  };

  // Check if a value is a valid number
  const isValidNumber = useCallback((val: string): boolean => {
    // Allow empty string
    if (!val) return true;

    // Must be a parseable number
    const num = Number(val);
    return !isNaN(num);
  }, []);

  const handleSave = useCallback(() => {
    // For currency type, validate before saving
    if (type === 'currency' && !isValidNumber(inputValue)) {
      // Don't save invalid numbers
      setIsEditing(false);
      return;
    }

    if (onSave && inputValue !== value) {
      onSave(inputValue);
    }
    setIsEditing(false);
  }, [type, inputValue, value, onSave, setIsEditing, isValidNumber]);

  // Add keyboard event handling for date fields
  useEffect(() => {
    if (isEditing && type === 'date' && containerRef.current) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          // Save and close on Enter key press
          handleSave();
        } else if (e.key === 'Escape') {
          // Cancel on Escape key press
          setIsEditing(false);
          setInputValue(value);
        }
      };

      // Attach to the component's container instead of document
      const currentRef = containerRef.current;
      currentRef.addEventListener('keydown', handleKeyDown as EventListener);
      
      return () => {
        currentRef.removeEventListener('keydown', handleKeyDown as EventListener);
      };
    }
  }, [isEditing, type, inputValue, handleSave, value]);

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    // Don't close the editing mode if the click is on a select or menu element
    const target = event.target as HTMLElement;
    const isSelectElement =
      target.closest('.MuiSelect-root') ||
      target.closest('.MuiMenu-root') ||
      target.closest('.MuiMenuItem-root');

    if (isEditing && !isSelectElement) {
      if (type === 'select') {
        // For select type, just close without saving
        setIsEditing(false);
        setInputValue(value); // Reset to original value
      } else {
        // For other types, save the value
        handleSave();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSave();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setInputValue(value);
    }
  };

  // Handle currency input change - only allow numeric input
  const handleCurrencyInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newValue = e.target.value;

    // Only update with valid numeric input (digits and decimal point)
    const sanitizedValue = newValue.replace(/[^0-9.]/g, '');

    // Ensure we don't have multiple decimal points
    const parts = sanitizedValue.split('.');
    const validValue =
      parts.length > 2
        ? `${parts[0]}.${parts.slice(1).join('')}`
        : sanitizedValue;

    setInputValue(validValue);

    // Show red text if the input would be invalid for saving
    setHasInputError(!isValidNumber(validValue));
  };

  // Format currency value for display
  const formatCurrencyValue = (val: string) => {
    // Check for empty values
    if (!val) return 'N/A';
    
    // Handle numeric values
    const numValue = parseFloat(val);
    return isNaN(numValue) ? 'N/A' : `$${val}`;
  };

  const handleSelectChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode,
  ) => {
    const newValue = event.target.value as string;
    setInputValue(newValue);

    if (onSave) {
      onSave(newValue);
    }
    setIsEditing(false);
  };

  const renderEditableValue = () => {
    if (isEditing) {
      if (type === 'date') {
        return (
          <Box
            sx={{ display: 'inline-block', position: 'relative', zIndex: 1 }}
          >
            <StyledDatePicker
              value={inputValue ? DateTime.fromISO(inputValue) : null}
              onChange={(newDate: DateTime | null) => {
                // Important: Does not automatically close the edit mode
                // This allows manual typing to work correctly
                // Only save when the user explicitly selects a date from the pop-up
                // or clicks outside the component (handled by ClickAwayListener)
                if (newDate) {
                  // Store the new date value but don't save or close
                  setInputValue(newDate.toISO() || '');
                } else {
                  setInputValue('');
                }
              }}
              size="xsmall"
              autoFocus
            />
          </Box>
        );
      } else if (type === 'currency') {
        return (
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Box
              component="span"
              sx={{ fontSize: '0.875rem', fontWeight: 600, marginRight: '2px' }}
            >
              $
            </Box>
            <InlineInput
              inputRef={inputRef}
              value={inputValue}
              onChange={handleCurrencyInputChange}
              onKeyDown={handleKeyDown}
              autoFocus
              alignment={alignment}
              error={hasInputError}
              sx={{
                width: inputWidth ? `${inputWidth}px` : 'auto',
                maxWidth: '100%',
              }}
              onBlur={handleSave}
            />
          </Box>
        );
      } else if (type === 'select' && options && options.length > 0) {
        return (
          <Box
            sx={{ position: 'absolute', zIndex: 1, right: '0', top: '-40px' }}
          >
            <StyledSelect
              value={inputValue}
              onChange={handleSelectChange}
              autoFocus
              open={true} // Force the select to stay open
              size="small"
              MenuProps={{
                // Keep the menu open until a selection is made
                disableAutoFocusItem: true,
                // Allow the menu to close when clicking outside
                disablePortal: false,
                // Prevent scrolling issues
                disableScrollLock: true,
                // Position the menu properly to avoid covering the icon
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                // Allow the menu to close when clicking outside
                onClose: (event) => {
                  // Close the editing mode when clicking outside
                  setIsEditing(false);
                },
              }}
            >
              {options.map((option) => (
                <DSMenuItem key={option} value={option} primaryText={option} />
              ))}
            </StyledSelect>
          </Box>
        );
      } else {
        return (
          <InlineInput
            inputRef={inputRef}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
            alignment={alignment}
            sx={{
              width: inputWidth ? `${inputWidth}px` : 'auto',
              maxWidth: '100%',
            }}
            onBlur={handleSave}
          />
        );
      }
    } else {
      return (
        <>
          {/* Hidden span to measure text width */}
          {(type === 'text' || type === 'date') && (
            <span
              ref={valueRef}
              style={{
                position: 'absolute',
                visibility: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {value}
            </span>
          )}
          <DetailValue
            onClick={editable ? handleEditClick : undefined}
            sx={{ cursor: 'inherit', textAlign: alignment }}
          >
            {type === 'currency' ? formatCurrencyValue(value) : value}
          </DetailValue>
        </>
      );
    }
  };

  // Always use ClickAwayListener to allow clicking away from dropdown
  const contentWithClickAway = (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {/* Wrapper div needed for ClickAwayListener */}
        {DetailContainer && (
          <DetailContainer
            ref={containerRef}
            sx={{
              ...(containerWidth && isEditing ? { width: containerWidth } : {}),
              position: 'relative',
              cursor: onClick ? 'pointer' : editable ? 'text' : 'default',
            }}
            onClick={onClick && !isEditing ? onClick : undefined}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {editable && (isHovering || isEditing) && (
              <EditIcon
                onClick={handleEditClick}
                sx={{
                  position: 'absolute',
                  top: isEditing ? '1px' : '0px',
                  right: '4px',
                  zIndex: 1,
                  opacity: isEditing ? 1 : 0.7,
                  transition: 'opacity 0.2s ease-in-out',
                  height: '24px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <EditIconMui
                  sx={{
                    fontSize: '14px',
                    color: isEditing ? DEALROOMS_COLORS.peacock : 'inherit',
                  }}
                />
              </EditIcon>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              {overline && (
                <MeetingTypeLabel variant="caption">
                  {overline}
                </MeetingTypeLabel>
              )}
              {/* When editing date values, use an empty placeholder to maintain spacing */}
              {icon &&
                (isEditing && type === 'date' ? (
                  <Box
                    sx={{
                      marginRight: '8px',
                      minWidth: '32px',
                      display: 'flex',
                      justifyContent: 'center',
                      visibility: 'hidden', // Hidden but takes up space
                    }}
                  >
                    {icon}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      marginRight: '8px',
                      minWidth: '32px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {icon}
                  </Box>
                ))}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: alignment === 'left' ? 'flex-start' : 'flex-end',
                }}
              >
                <DetailValueContainer
                  ref={valueContainerRef}
                  onClick={editable && !isEditing ? handleEditClick : undefined}
                  sx={{
                    position: 'relative',
                    width:
                      valueContainerWidth && isEditing
                        ? valueContainerWidth
                        : 'auto',
                    maxWidth: maxWidth || 'max-content',
                    overflow: maxWidth ? 'hidden' : 'visible',
                  }}
                >
                  {renderEditableValue()}
                </DetailValueContainer>
                <DetailLabel sx={{ marginTop: isEditing ? '1px' : '2px' }}>
                  {label}
                </DetailLabel>
              </Box>
            </Box>
          </DetailContainer>
        )}
      </div>
    </ClickAwayListener>
  );

  return contentWithClickAway;
};

export default DSDetail;
