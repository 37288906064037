import {
  Image,
  Stack,
  Text,
  PrimaryButton,
  mergeStyleSets,
  ColorPicker,
  IColor,
  TextField,
} from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { useCallback, useMemo, useState } from 'react';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { useOrganizationSlug } from '../../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../../Hooks/useDealRoomId';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import {
  DealRoomAsyncCommandBarButton,
  DealRoomAsyncPrimaryButton,
} from '../../../Components/DealRoomButton';
import { DSConfigurationHeaderRow } from '../../Components/DSConfigurationHeaderRow';
import { useAuth0 } from '@auth0/auth0-react';
import { pickFile } from '../../../../../Helpers/FileHelpers';
import toast from 'react-hot-toast';
import useDeviceType from '../../../../../Hooks/deviceDetection';
import { OrganizationDealRoomsQuery } from '../../../../../QueryNames';
import { useQueryClient } from 'react-query';
import {
  getFileWithMimeType,
  hasValidFileExtension,
  validUploadImageTypes,
} from '../../../../../utils/buyerDecisionSiteUtils';
import { useDealRooms } from '../../../../../Hooks/useDealRooms';

// Define styles for various components using mergeStyleSets
const getStyles = (isMobile?: boolean) => {
  return mergeStyleSets({
    header: {
      padding: '20px',
      borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    },
    content: {
      padding: '0 0 1.5rem 0',
      flex: 1,
    },
    footer: {
      padding: '20px',
      borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    description: {
      color: DEALROOMS_COLORS.neutralSecondary,
      marginBottom: '20px',
    },
    colorPicker: {
      '.ms-ColorPicker-colorRect': {
        minWidth: '36px',
        minHeight: '36px',
      },
    },
  });
};

export const DSConfigurationCustomization = () => {
  const { isMobile, isTablet } = useDeviceType();
  const styles = getStyles(isMobile);

  // Hooks for getting organization and deal room information
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { dealRoom, refetch: refetchDealRoom } = useDealRoom();
  const { getAccessTokenSilently } = useAuth0();

  // State for selected color and logo modification status
  const [selectedColor, setSelectedColor] = useState<string | undefined>();
  const [isLogoModified, setIsLogoModified] = useState(false);
  // const [isEditingName, setIsEditingName] = useState(false);
  // const [newName, setNewName] = useState(dealRoom?.name || '');

  // QueryClient instance
  const queryClient = useQueryClient();

  // Memoized initial primary color
  const initialPrimaryColor = useMemo(() => {
    if (dealRoom?.primaryColor) {
      return dealRoom.primaryColor;
    }
    return DEALROOMS_COLORS.themePrimary;
  }, [dealRoom]);

  const { refetch: refetchDealRooms } = useDealRooms(organizationSlug!);

  // Callback function to save changes
  const onSave = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!organizationSlug || !dealRoomId) {
      return;
    }

    if (!selectedColor) {
      await refetchDealRoom();
      return;
    }

    try {
      // Update deal room with new primary color
      await DealRoomsApiClient.updateDealRoom(
        organizationSlug,
        dealRoomId,
        {
          primaryColor: '#' + selectedColor,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      await refetchDealRoom();
    } catch (error) {
      console.error('Failed to update deal room color:', error);
    }
  }, [
    getAccessTokenSilently,
    selectedColor,
    organizationSlug,
    dealRoomId,
    refetchDealRoom,
  ]);

  // const onSaveName = useCallback(async () => {
  //   const token = await getAccessTokenSilently();

  //   if (!organizationSlug || !dealRoomId || !newName) {
  //     return;
  //   }

  //   try {
  //     await DealRoomsApiClient.updateDealRoom(
  //       organizationSlug,
  //       dealRoomId,
  //       {
  //         name: newName,
  //       },
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       },
  //     );
  //     // Invalidate deal rooms query to reflect updated name
  //     await refetchDealRoom();
  //     await refetchDealRooms();
  //     setIsEditingName(false);
  //   } catch (error) {
  //     console.error('Failed to update deal room name:', error);
  //   }
  // }, [
  //   getAccessTokenSilently,
  //   organizationSlug,
  //   dealRoomId,
  //   newName,
  //   refetchDealRoom,
  //   refetchDealRooms,
  // ]);

  return (
    <Stack tokens={{ childrenGap: 20 }} styles={{ root: { width: '100%' } }}>
      {/* Header section with save button */}
      <DSConfigurationHeaderRow description="">
        {<></>}
      </DSConfigurationHeaderRow>

      <Stack>
        {/* <Stack className={styles.content} tokens={{ childrenGap: 10 }}>
          <Text variant="large">Decision Site Name</Text>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <TextField
              value={newName}
              onChange={(_, newValue) => setNewName(newValue || '')}
              onFocus={() => setIsEditingName(true)}
              styles={{
                root: { width: '100%', maxWidth: isMobile ? 'auto' : '300px' },
              }}
            />
            {isEditingName && (
              <PrimaryButton
                onClick={onSaveName}
                text="Save Name"
                disabled={!newName || newName === dealRoom?.name}
              />
            )}
          </Stack>
        </Stack> */}
        <Stack horizontal={!(isMobile || isTablet)}>
          {/* Theme color selection section */}
          <Stack className={styles.content}>
            <Text variant="large">Theme Color</Text>
            <Text className={styles.description}>
              Choose a color to customize your Decision Site's appearance.
            </Text>

            <Stack horizontalAlign="start" tokens={{ childrenGap: 20 }}>
              <ColorPicker
                color={selectedColor || initialPrimaryColor}
                showPreview={true}
                alphaType="none"
                onChange={(ev, color: IColor) => setSelectedColor(color.hex)}
              />
              <PrimaryButton
                onClick={() => onSave()}
                text="Save Color"
                disabled={
                  !selectedColor || selectedColor === initialPrimaryColor
                }
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DSConfigurationCustomization;
