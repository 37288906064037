import {
  DialogContent,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  styled,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { FileFormat } from '../../../types/MilestoneTypes';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { useMilestonesSummary } from '../../../Hooks/useMilestonesSummary';
import { DSResponsiveModal } from '../DS';

type RequestAttachmentsDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  onResolve: (value: unknown) => void;
  onDismiss: () => void;
  milestoneId: number;
};

const formatOptions: {
  text: string;
  key: FileFormat;
}[] = [
  { text: 'PDF', key: 'PDF' },
  { text: 'Excel', key: 'Excel' },
  { text: 'Word', key: 'Doc' },
  { text: 'PPT', key: 'PPT' },
];

const StyledDialogContent = styled(DialogContent)({
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

export const RequestAttachmentsDialog = ({
  organizationSlug,
  dealRoomId,
  onResolve,
  onDismiss,
  milestoneId,
}: RequestAttachmentsDialogProps) => {
  const [description, setDescription] = useState('');
  const [ownerId, setOwnerId] = useState<number | null>(null);
  const [selectedMilestoneId, setSelectedMilestoneId] = useState<number | null>(
    milestoneId,
  );
  const [fileFormat, setFileFormat] = useState<FileFormat | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedMilestoneId(milestoneId);
  }, [milestoneId]);

  const { getAccessTokenSilently } = useAuth0();

  const { dealRoom } = useDealRoom(organizationSlug, dealRoomId);
  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const { milestonesSummary, refetch: refetchMilestonsSummary } =
    useMilestonesSummary(organizationSlug, dealRoomId);

  const dealRoomContactsOptions = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return [];

    return dealRoom.contacts.map((contact) => ({
      key: contact.id,
      text: contact.name || contact?.email,
    }));
  }, [dealRoom?.contacts]);

  const isRequestButtonDisabled = useMemo(() => {
    return loading || !description || ownerId === null || fileFormat === null;
  }, [loading, description, fileFormat, ownerId]);

  const handleRequestAttachment = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    try {
      if (ownerId === null || fileFormat === null) return;

      // Convert contact ID to user ID for the API
      const assigneeContact = dealRoom?.contacts?.find(
        (contact) => contact.id === ownerId,
      );

      if (!assigneeContact?.userId) {
        console.error(
          '[RequestAttachmentsDialog] Contact not found or missing userId:',
          {
            contactId: ownerId,
            availableContacts: dealRoom?.contacts,
          },
        );
        return toast.error(
          'Selected user is not available in this Decision Site. Please try again.',
        );
      }

      const token = await getAccessTokenSilently();
      await toast.promise(
        // organizationSlug: OrganizationSlug,
        // dealRoomId: number,
        // milestoneId: number,
        // data: CreateMilestoneAttachmentRequestPayload,
        // params: RequestParams = {}
        DealRoomsApiClient.createMilestoneAttachmentRequest(
          organizationSlug,
          dealRoomId,
          milestoneId,
          /*
            export interface CreateMilestoneAttachmentRequestPayload {
                assigneeId: number;
                details: string;
            }
          */
          {
            assigneeId: assigneeContact.userId,
            details: description,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Requesting Artifact',
          success: () => {
            setLoading(false);
            onResolve(null);
            Promise.all([refetchMilestonsSummary(), refetchMutualPlan()]);
            return 'Successfully requested the artifact';
          },
          error: 'Something went wrong requesting the artifact',
        },
      );
    } catch (err) {
      setLoading(false);
    }
  }, [
    loading,
    dealRoomId,
    description,
    fileFormat,
    getAccessTokenSilently,
    onResolve,
    organizationSlug,
    ownerId,
    refetchMilestonsSummary,
    refetchMutualPlan,
    dealRoom?.contacts,
    milestoneId,
  ]);

  return (
    <DSResponsiveModal
      open
      onDismiss={onDismiss}
      maxWidth="md"
      title={'Request Artifact'}
      customMaxWidth={550}
      primaryFooterButtons={[
        {
          children: 'Request',
          onClick: handleRequestAttachment,
          disabled: isRequestButtonDisabled,
        },
      ]}
      secondaryFooterButtons={[
        {
          children: 'Cancel',
          onClick: onDismiss,
        },
      ]}
    >
      <StyledDialogContent>
        <Typography
          variant="body1"
          //align="center"
          sx={{
            fontWeight: 500,
            fontSize: '.9375rem',
            lineHeight: '1.5rem',
            color: DEALROOMS_COLORS.neutralDark,
            // maxWidth: '340px',
            margin: '0 auto',
          }}
        >
          Requesting an Artifact creates a task assigned to the person you're
          requesting from
        </Typography>

        <FormControl fullWidth required>
          <InputLabel>Request Artifact from...</InputLabel>
          <Select
            value={ownerId || ''}
            label="Request Artifact From"
            onChange={(e) => setOwnerId(e.target.value as number)}
          >
            {dealRoomContactsOptions.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          required
          label="Details"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <FormControl required>
          <InputLabel sx={{ transform: 'translate(0, 0rem)' }}>
            Document format
          </InputLabel>
          <RadioGroup
            row
            value={fileFormat || ''}
            onChange={(e) => setFileFormat(e.target.value as FileFormat)}
            sx={{ gap: '1.5rem', marginTop: '2rem' }}
          >
            {formatOptions.map((option) => (
              <FormControlLabel
                key={option.key}
                value={option.key}
                control={<Radio />}
                label={option.text}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </StyledDialogContent>
    </DSResponsiveModal>
  );
};
