/**
 * MilestoneActionItem handles both existing and new action items in the DealRoom.
 *
 * Key behaviors:
 * 1. New Action Items (actionItem.id is undefined):
 *    - Shows a placeholder "Add a new Task..." until editing begins
 *    - Maintains local state (newTitleValue, newDueDate, etc.) without saving
 *    - Only saves when all required fields are filled and "Add to Mutual Plan" is clicked
 *    - Automatically sets current time as due date for new tasks
 *
 * 2. Existing Action Items (actionItem.id exists):
 *    - Shows actual task title and details
 *    - Saves changes immediately when individual fields are updated
 *    - Supports inline editing of the title when allowInlineTitleEdit is true
 *
 * The component handles different states:
 * - Title editing (controlled by isEditing state)
 * - Status selection (with color-coded backgrounds)
 * - Owner assignment
 * - Milestone association
 * - Comments and attachments (for existing items only)
 */
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import {
  DealRoomActionItem,
  DealRoomActionItemStatus,
} from '@meetingflow/common/Api/data-contracts';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import {
  dealRoomActionItemStatusOptions,
  getStatusBackgroundColor,
  getStatusTextColor,
} from './milestonesUtils';
import { ActionItemOwnerContainer } from './ActionItemOwnerContainer';
import { ActionItemAttachmentsList } from './ActionItemAttachmentsList';
import { OpenCommentsButton } from './TaskComments/OpenCommentsButton';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DateTime } from 'luxon'; // Import DateTime from luxon
import { useMilestonesSummary } from '../../../Hooks/useMilestonesSummary';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useActionItems } from '../../../Hooks/useActionItems';
import { useMeetingflow } from '../../../Components/DealRoom/Components/Journey/hooks/useMeetingflow';
import { RemoveCircle } from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material';
import {
  DSMenuItem,
  DSTextField,
  DSDatePicker,
  DSButton,
  DSTooltip,
} from '../DS';
import {
  Container,
  Row,
  TitleSection,
  ControlsSection,
  TaskTitle,
  ButtonContainer,
  AttachmentsContainer,
  StatusSelect,
  StyledSelect,
} from './MilestoneActionItem.styles';
import { MilestoneActionItemSelectMilestoneButton } from './MilestoneActionItemSelectMilestoneButton';

type MilestoneActionItemProps = {
  actionItem: DealRoomActionItem | Partial<DealRoomActionItem>;
  setTaskIdForComments: (taskId: number) => void;
  onActionItemClick?: () => void;
  isLastItem?: boolean;
  allowInlineTitleEdit?: boolean;
  showDeleteButton?: boolean;
  onDelete?: () => void;

  // For tasks coming in from meetingflow (will not yet have an id)
  fromMeetingflowId?: string;
  showMilestoneEditor?: boolean;
  isSuggestedItem?: boolean;
  onSuggestedItemAdded?: () => Promise<void>;
};

export const MilestoneActionItem: React.FC<MilestoneActionItemProps> = ({
  actionItem,
  setTaskIdForComments,
  onActionItemClick,
  isLastItem = false,
  allowInlineTitleEdit = false,
  showDeleteButton = false,
  onDelete,
  fromMeetingflowId,
  showMilestoneEditor = false,
  isSuggestedItem,
  onSuggestedItemAdded,
}: MilestoneActionItemProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug } = useOrganization();
  const { dealRoomId, dealRoom } = useDealRoom();
  const { refetchMeetingflow } = useMeetingflow();
  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug || '',
    dealRoomId || 0,
  );

  const { milestonesSummary, refetch: refetchMilestonesSummary } =
    useMilestonesSummary(organizationSlug || '', dealRoomId || 0);

  const { actionItems, refetch: refetchOrganizationActionItems } =
    useActionItems(organizationSlug || '');

  // Get list of all milestones from the mutual plan
  const milestones = useMemo(() => mutualPlan?.milestones || [], [mutualPlan]);

  // Filter visible milestones and format for dropdown
  const milestoneOptions = useMemo(() => {
    return (milestones || [])
      .filter((milestone) => milestone.visible)
      .map((milestone) => ({
        key: milestone.id,
        text: milestone.title,
      }));
  }, [milestones]);

  // Find the meetingflow artifact ID if this task came from a meetingflow
  const meetingflowArtifactId = useMemo(() => {
    return (
      dealRoom?.artifacts.find(
        (artifact) =>
          artifact.type === 'MEETINGFLOW' &&
          artifact.meetingflowId === fromMeetingflowId,
      )?.id ?? null
    );
  }, [dealRoom?.artifacts, fromMeetingflowId]);

  // Track whether the user is currently editing the task title
  const [isEditing, setIsEditing] = useState(
    !actionItem?.id && !actionItem?.actionItem,
  );
  // For new tasks, stores the draft title before saving
  const [newTitleValue, setNewTitleValue] = useState(
    actionItem?.actionItem || '',
  );
  // New tasks default to current time, existing tasks use their saved due date
  const [newDueDate, setNewDueDate] = useState<string | null>(
    actionItem?.dueDate || DateTime.now().toISO(),
  );
  // Tracks the milestone type for new tasks before saving
  const [newMilestoneId, setNewMilestoneId] = useState<number | undefined>(
    actionItem?.milestone?.id,
  );
  // Tracks the owner ID for new tasks before saving
  const [newOwnerId, setNewOwnerId] = useState<number | null>(
    actionItem?.assignee?.id || null,
  );
  // Tracks the status for new tasks before saving
  const [newStatus, setNewStatus] = useState<DealRoomActionItemStatus>(
    actionItem?.status || 'NOT_STARTED',
  );

  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  /**
   * Updates a field for an existing task or updates local state for a new task.
   * For existing tasks (actionItem.id exists), immediately saves to the server.
   * For new tasks, just updates the local state until the full task is saved.
   */
  const handleUpdateOrCreateTask = useCallback(
    async (
      field: 'title' | 'dueDate' | 'status' | 'milestone' | 'owner',
      value: string | number | null | { id: number } | DealRoomActionItemStatus,
    ): Promise<void> => {
      try {
        // For new tasks, just update local state
        if (!actionItem?.id) {
          switch (field) {
            case 'title':
              setNewTitleValue(value as string);
              break;
            case 'dueDate':
              setNewDueDate(value as string | null);
              break;
            case 'milestone':
              setNewMilestoneId(value as number);
              break;
            case 'owner':
              setNewOwnerId(typeof value === 'number' ? value : null);
              break;
            case 'status':
              setNewStatus(value as DealRoomActionItemStatus);
              break;
          }
          return;
        }

        if (field === 'status') {
          if (
            typeof value !== 'string' ||
            !dealRoomActionItemStatusOptions.some(
              (option) => option.key === value,
            )
          ) {
            return;
          }

          await toast.promise(
            (async () => {
              if (!organizationSlug || !dealRoomId || !actionItem?.id) {
                throw new Error('Missing required IDs');
              }

              const token = await getAccessTokenSilently();
              const result = await DealRoomsApiClient.updateActionItemStatus(
                organizationSlug,
                dealRoomId,
                actionItem.id,
                { status: value as DealRoomActionItemStatus },
                {
                  headers: { Authorization: `Bearer ${token}` },
                },
              );

              await Promise.all([
                refetchMutualPlan(),
                refetchMilestonesSummary(),
                refetchOrganizationActionItems(),
              ]);

              return result;
            })(),
            {
              loading: 'Updating task status...',
              success: 'Successfully updated task status',
              error: 'Failed to update task status',
            },
          );
          return;
        }

        if (field === 'owner') {
          await toast.promise(
            (async () => {
              if (!organizationSlug || !dealRoomId || !actionItem?.id) {
                throw new Error('Missing required IDs');
              }

              const token = await getAccessTokenSilently();
              const assigneeId = value as number | null;
              const result = await DealRoomsApiClient.updateActionItem(
                organizationSlug,
                dealRoomId,
                actionItem.id,
                { assigneeId },
                {
                  headers: { Authorization: `Bearer ${token}` },
                },
              );

              await Promise.all([
                refetchMutualPlan(),
                refetchMilestonesSummary(),
                refetchOrganizationActionItems(),
              ]);

              return result;
            })(),
            {
              loading: 'Updating task owner...',
              success: 'Successfully updated task owner',
              error: 'Failed to update task owner',
            },
          );
          return;
        }

        // For existing tasks, make the API call
        const updateData = {
          // Map 'title' field to 'actionItem' for the API, ensuring it's a string
          ...(field === 'title'
            ? { actionItem: value as string }
            : { [field]: value }),
        };

        await toast.promise(
          (async () => {
            if (!organizationSlug || !dealRoomId || !actionItem?.id) {
              throw new Error('Missing required IDs');
            }

            const token = await getAccessTokenSilently();
            const response = await DealRoomsApiClient.updateActionItem(
              organizationSlug,
              dealRoomId,
              actionItem.id,
              updateData,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            );

            await Promise.all([
              refetchMutualPlan(),
              refetchMilestonesSummary(),
              refetchOrganizationActionItems(),
            ]);

            return response;
          })(),
          {
            loading: 'Updating task...',
            success: 'Successfully updated task',
            error: 'Failed to update task',
          },
        );
      } catch (error) {
        toast.error('Failed to save task');
      } finally {
        setIsEditing(false);
      }
    },
    [
      actionItem,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchMutualPlan,
      refetchMilestonesSummary,
      refetchOrganizationActionItems,
    ],
  );

  /**
   * Creates a new task when all required fields are filled.
   * - Validates milestone is selected
   * - Creates task with current state values
   * - Links to meetingflow if task came from there
   * - Resets form state for next task
   * - Refreshes related data after save
   */
  const handleSaveNewTask = useCallback(async () => {
    if (!newMilestoneId) {
      toast.error('Please select a milestone');
      return;
    }
    if (!newTitleValue.trim()) {
      toast.error('Please enter a task title');
      return;
    }

    try {
      const token = await getAccessTokenSilently();

      if (!organizationSlug || !dealRoomId) {
        return;
      }

      await toast.promise(
        (async () => {
          // Create the task
          const result = await DealRoomsApiClient.createMilestoneActionItem(
            organizationSlug,
            dealRoomId,
            newMilestoneId,
            {
              actionItem: newTitleValue,
              dueDate: newDueDate,
              ...(newOwnerId ? { assigneeId: newOwnerId } : {}),
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );

          // If we have a meetingflow ID and artifact ID, create the relation
          if (
            fromMeetingflowId &&
            meetingflowArtifactId &&
            result.data.id &&
            organizationSlug
          ) {
            await DealRoomsApiClient.putActionItemArtifact(
              organizationSlug,
              dealRoomId,
              result.data.id,
              meetingflowArtifactId,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            );
          }

          return result;
        })(),
        {
          loading: 'Creating task...',
          success: 'Task created successfully',
          error: 'Failed to create task',
        },
      );

      // Refresh the data
      await Promise.all(
        [
          refetchMilestonesSummary(),
          refetchMutualPlan(),
          refetchMeetingflow?.(),
        ].filter(Boolean),
      );

      // If this was a suggested item, delete it after successful addition
      if (isSuggestedItem && onSuggestedItemAdded) {
        await onSuggestedItemAdded();
      }

      // Reset all local state after successful creation
      setNewTitleValue('');
      setNewDueDate(DateTime.now().toISO());
      setNewMilestoneId(undefined);
      setNewOwnerId(null);
      setNewStatus('NOT_STARTED');
      setIsEditing(false);
    } catch (error) {
      toast.error('Failed to create task');
    }
  }, [
    dealRoomId,
    getAccessTokenSilently,
    newDueDate,
    newMilestoneId,
    newOwnerId,
    newTitleValue,
    organizationSlug,
    refetchMilestonesSummary,
    refetchMutualPlan,
    fromMeetingflowId,
    meetingflowArtifactId,
    refetchMeetingflow,
    isSuggestedItem,
    onSuggestedItemAdded,
  ]);

  const handleUpdateDueDate = useCallback(
    (newDate: DateTime | null) =>
      handleUpdateOrCreateTask('dueDate', newDate?.toISO() ?? null),
    [handleUpdateOrCreateTask],
  );

  /**
   * Updates the task title.
   * For existing tasks, saves immediately.
   * For new tasks, updates local state and exits edit mode.
   */
  const handleUpdateTitle = useCallback(
    (newTitle: string) => {
      // Skip update if title hasn't changed
      if (actionItem?.id && newTitle === actionItem.actionItem) {
        setIsEditing(false);
        return;
      }
      handleUpdateOrCreateTask('title', newTitle);
      if (!actionItem?.id) {
        setIsEditing(false);
      }
    },
    [actionItem?.id, actionItem?.actionItem, handleUpdateOrCreateTask],
  );

  /**
   * Updates the task status.
   * For existing tasks, saves immediately.
   * For new tasks, just updates local state.
   */
  const handleUpdateStatus = useCallback(
    (newStatusValue: string | number | undefined) => {
      if (typeof newStatusValue !== 'string') return;

      if (!actionItem?.id) {
        setNewStatus(newStatusValue as DealRoomActionItemStatus);
        return;
      }
      if (newStatusValue === actionItem?.status) {
        return;
      }
      handleUpdateOrCreateTask(
        'status',
        newStatusValue as DealRoomActionItemStatus,
      );
    },
    [actionItem?.id, actionItem?.status, handleUpdateOrCreateTask],
  );

  /**
   * Updates the task's milestone.
   * Skips if the new milestone is the same as current.
   * For new tasks, just updates local state.
   */
  const handleUpdateMilestone = useCallback(
    (newMilestoneId: number) => {
      if (newMilestoneId === actionItem?.milestone?.id) return;
      if (!actionItem?.id) {
        // For new tasks, just update local state
        setNewMilestoneId(newMilestoneId);
        return;
      }
      // Only make API call for existing tasks
      handleUpdateOrCreateTask('milestone', newMilestoneId);
    },
    [actionItem?.id, actionItem?.milestone?.id, handleUpdateOrCreateTask],
  );

  const handleClick = useCallback(() => {
    onActionItemClick?.();
  }, [onActionItemClick]);

  // Memoized values for task properties to prevent unnecessary re-renders
  const dueDate = useMemo(() => {
    return actionItem?.dueDate ? DateTime.fromISO(actionItem.dueDate) : null;
  }, [actionItem?.dueDate]);

  const status = useMemo(() => {
    return actionItem?.status;
  }, [actionItem?.status]);

  const assignee = useMemo(() => {
    return actionItem?.assignee;
  }, [actionItem?.assignee]);

  useEffect(() => {
    // Remove auto-selection of milestone
  }, [milestoneOptions, newMilestoneId]);

  if (!organizationSlug || !dealRoomId) return null;

  return (
    <Container isLastItem={isLastItem} removeLeftPadding={isSuggestedItem}>
      <Row>
        <TitleSection onClick={onActionItemClick}>
          {isEditing && allowInlineTitleEdit ? (
            <DSTextField
              autoFocus
              size="small"
              multiline
              minRows={1}
              maxRows={4}
              value={newTitleValue}
              placeholder="Enter Task Name..."
              onChange={(e) => setNewTitleValue(e.target.value)}
              onBlur={() => {
                if (actionItem?.id) {
                  handleUpdateTitle(newTitleValue);
                  setIsEditing(false);
                }
                // For new tasks, don't clear the title or exit edit mode
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  if (actionItem?.id) {
                    handleUpdateTitle(newTitleValue);
                  }
                } else if (e.key === 'Escape') {
                  setIsEditing(false);
                }
              }}
              style={{
                flex: 1,
                minWidth: 0,
              }}
            />
          ) : (
            <TaskTitle
              $isPlaceholder={!actionItem?.actionItem}
              onClick={() => {
                if (allowInlineTitleEdit) {
                  setNewTitleValue(actionItem?.actionItem || '');
                  setIsEditing(true);
                } else {
                  handleClick();
                }
              }}
            >
              {actionItem?.actionItem || 'Add a new Task...'}
            </TaskTitle>
          )}
        </TitleSection>

        <ControlsSection>
          {actionItem?.id && (
            <>
              <DSDatePicker
                value={dueDate}
                size="xsmall"
                format="MM/dd/yy"
                formatDensity="dense"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiOutlinedInput-input': {
                    height: '24px',
                    color: DEALROOMS_COLORS.cloudburst,
                  },
                  '& .MuiInputBase-root': {
                    backgroundColor: DEALROOMS_COLORS.neutralLighter,
                    maxWidth: '100px',
                  },
                }}
                onChange={(newValue) => {
                  // Only accept valid dates after year 2000
                  if (
                    newValue?.isValid &&
                    newValue.year > 2000 &&
                    newValue.year < 2100
                  ) {
                    handleUpdateDueDate(newValue);
                  }
                }}
                minDate={DateTime.fromObject({ year: 2000, month: 1, day: 1 })}
                maxDate={DateTime.fromObject({
                  year: 2100,
                  month: 12,
                  day: 31,
                })}
                slotProps={{
                  textField: {
                    inputProps: {
                      min: '2000-01-01',
                      max: '2100-12-31',
                      placeholder: 'MM/DD/YYYY',
                    },
                  },
                }}
              />
              <StatusSelect
                value={actionItem?.id ? actionItem?.status : newStatus}
                onChange={(e: SelectChangeEvent<string>) => {
                  if (typeof e.target.value === 'string') {
                    handleUpdateStatus(e.target.value);
                  }
                }}
                size="small"
                IconComponent={() => <span />}
                sx={{
                  backgroundColor: getStatusBackgroundColor(
                    actionItem?.id ? actionItem?.status : newStatus,
                  ),
                  '& .MuiSelect-select': {
                    color: getStatusTextColor(
                      actionItem?.id ? actionItem?.status : newStatus,
                    ),
                  },
                  '& .MuiOutlinedInput-input': {
                    color: getStatusTextColor(
                      actionItem?.id ? actionItem?.status : newStatus,
                    ),
                  },
                  '& .MuiSvgIcon-root': {
                    color: getStatusTextColor(
                      actionItem?.id ? actionItem?.status : newStatus,
                    ),
                  },
                }}
                renderValue={(value) => {
                  return dealRoomActionItemStatusOptions.find(
                    (option) => option.key === value,
                  )?.text;
                }}
              >
                {dealRoomActionItemStatusOptions.map((option) => (
                  <DSMenuItem
                    key={option.key}
                    value={option.key}
                    primaryText={option.text}
                  />
                ))}
              </StatusSelect>
              <ActionItemOwnerContainer
                contacts={dealRoom?.contacts}
                selectedContactId={
                  actionItem?.id
                    ? dealRoom?.contacts?.find(
                        (contact) => contact.userId === assignee?.id,
                      )?.id
                    : dealRoom?.contacts?.find(
                        (contact) => contact.userId === newOwnerId,
                      )?.id
                }
                onOwnerChange={(newOwner) => {
                  if (!actionItem?.id) {
                    // For new tasks, just update local state
                    setNewOwnerId(newOwner.userId || null);
                    return;
                  }
                  // Only make API call for existing tasks
                  handleUpdateOrCreateTask('owner', newOwner.userId || null);
                }}
              />
              <OpenCommentsButton
                organizationSlug={organizationSlug}
                dealRoomId={dealRoomId}
                taskId={actionItem.id}
                setTaskIdForComments={setTaskIdForComments}
              />
            </>
          )}
        </ControlsSection>

        {showMilestoneEditor && (
          <MilestoneActionItemSelectMilestoneButton
            milestoneOptions={milestoneOptions.map((opt) => ({
              key: opt.key,
              text: opt.text || '',
            }))}
            selectedMilestoneId={
              (actionItem?.milestone?.id || newMilestoneId) ?? null
            }
            onMilestoneSelected={setNewMilestoneId}
            onAddToMilestone={handleSaveNewTask}
          />
          // <StyledSelect
          //   value={actionItem?.milestone?.id || newMilestoneId}
          //   onChange={(e: SelectChangeEvent<unknown>) => {
          //     handleUpdateMilestone(e.target.value as number);
          //   }}
          //   size="small"
          //   displayEmpty
          //   renderValue={(value) => {
          //     if (!value) return 'Select Milestone';
          //     return milestoneOptions.find((opt) => opt.key === value)?.text;
          //   }}
          // >
          //   {milestoneOptions.map((option) => (
          //     <DSMenuItem
          //       key={option.key}
          //       value={option.key}
          //       primaryText={option.text}
          //     />
          //   ))}
          // </StyledSelect>
        )}

        <ButtonContainer>
          {showDeleteButton && onDelete && (
            <DSTooltip
              title={isSuggestedItem ? 'Ignore Suggested Task' : 'Delete Task'}
            >
              <DSButton
                onClick={onDelete}
                color="error"
                variant={isSuggestedItem ? 'text' : 'contained'}
                startIcon={<RemoveCircle />}
                iconOnly={isSuggestedItem}
              >
                {isSuggestedItem ? 'Ignore' : 'Delete'}
              </DSButton>
            </DSTooltip>
          )}
        </ButtonContainer>
      </Row>
      <AttachmentsContainer hasAttachments={!!actionItem?.artifacts?.length}>
        <ActionItemAttachmentsList
          actionItem={actionItem as DealRoomActionItem}
          excludeArtifactIds={
            meetingflowArtifactId ? [meetingflowArtifactId] : []
          }
        />
      </AttachmentsContainer>
    </Container>
  );
};
