import { styled, Box, Typography, Select } from '@mui/material';
import DSDatePicker from '../../DS/DSDatePicker';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const MeetingTypeLabel = styled(Typography)({
  position: 'absolute',
  top: '-10px',
  left: '40px',
  textTransform: 'uppercase',
  fontSize: '.5rem',
  color: DEALROOMS_COLORS.cloudburst,
});

export const DetailContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 'fit-content', // Prevent shrinking below content size
  maxWidth: 'max-content', // Prevent expanding beyond content
  flexShrink: 0, // Prevent shrinking
  borderRight: '1px solid #E0E0E0',
  paddingRight: '1.5rem',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  transition: 'background-color 0.2s ease',
});

export const DetailLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'alignment',
})<{ alignment?: 'left' | 'right' }>(({ alignment = 'right' }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: '#666666',
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  textAlign: alignment, // Align text based on prop
  lineHeight: 1.2, // Tighter line height to reduce vertical space
  whiteSpace: 'nowrap', // Prevent text wrapping
  cursor: 'inherit', // Inherit cursor from parent
}));

export const DetailValueContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'alignment',
})<{ alignment?: 'left' | 'right' }>(({ alignment = 'right' }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: alignment === 'left' ? 'flex-start' : 'flex-end', // Align content based on prop
  gap: '4px',
  maxWidth: 'max-content', // Prevent expanding beyond content
  position: 'relative', // For positioning the edit icon
  cursor: 'inherit', // Inherit cursor from parent
}));

export const EditIcon = styled(Box)({
  marginLeft: '4px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#666666',
  opacity: 0.6,
  '& svg': {
    fontSize: '0.75rem',
  },
  '&:hover': {
    color: '#333333',
    opacity: 1,
  },
});

export const DetailValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'alignment',
})<{ alignment?: 'left' | 'right' }>(({ alignment = 'right' }) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
  textAlign: alignment, // Align text based on prop
  lineHeight: 1.2, // Slightly tighter line height
  paddingTop: '1px', // Add a small padding to match input position
  whiteSpace: 'nowrap', // Prevent text wrapping
  overflow: 'hidden', // Hide overflow
  textOverflow: 'ellipsis', // Add ellipsis for truncated text
  cursor: 'inherit', // Inherit cursor from parent
  maxWidth: '200px',
  minWidth: '80px',
}));

export const StyledDatePicker = styled(DSDatePicker)({
  width: '120px', // Reasonable width that won't overflow
  position: 'relative',
  right: '-2.25rem',
  top: '-.25rem',
  '& .MuiInputBase-root': {
    height: '24px',
    fontSize: '0.875rem',
    fontWeight: 600,
    padding: 0,
    backgroundColor: 'white',
    border: '1px solid #CCCCCC',
    borderRadius: '4px',
    '&:hover': {
      borderColor: DEALROOMS_COLORS.cloudburst,
    },
    '&.Mui-focused': {
      borderColor: DEALROOMS_COLORS.cloudburst,
      boxShadow: `0 0 0 1px ${DEALROOMS_COLORS.cloudburst}`,
    },
  },
  '& .MuiInputBase-input': {
    padding: '0px 4px',
    height: '24px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none', // Remove the default outline
  },
  '& .MuiIconButton-root': {
    padding: '2px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
  },
});

// Using an empty object as the generic type to satisfy the constraint
export const StyledSelect = styled(Select)<{}>({
  fontSize: '0.875rem',
  fontWeight: 600,
  minWidth: '120px',
  height: '24px',
  position: 'relative',
  right: '-5rem',
  top: '-.25rem',
  '& .MuiSelect-select': {
    padding: '2px 8px',
  },
});
