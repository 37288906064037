import { Box, styled } from '@mui/material';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { AudioArtifactCard } from '../Artifacts/AudioArtifactCard';
import { DocumentArtifactCard } from '../Artifacts/DocumentArtifactCard';
import { FileArtifactCard } from '../Artifacts/FileArtifactCard';
import { ImageArtifactCard } from '../Artifacts/ImageArtifactCard';
import { LinkArtifactCard } from '../Artifacts/LinkArtifactCard';
import { MeetingflowArtifactCard } from '../Artifacts/MeetingflowArtifactCard';
import { VideoArtifactCard } from '../Artifacts/VideoArtifactCard';
import { useDealRoom } from '../../../Hooks/useDealRoom';

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

import { SortField, SortDirection } from './DealRoomArtifacts';
import { DateTime } from 'luxon';

interface ArtifactsGridViewProps {
  artifacts: DealRoomArtifact[];
  onClick: (id: DealRoomArtifact['id']) => void;
  onDelete: (id: DealRoomArtifact['id']) => Promise<void>;
}

export const ArtifactsGridView = ({
  artifacts,
  onClick,
  onDelete,
}: ArtifactsGridViewProps) => {
  const { dealRoom, refetch: refetchDealRoom } = useDealRoom();

  return (
    <GridContainer>
      {artifacts.map((artifact) => {
        switch (artifact.type) {
          case 'MEETINGFLOW':
            return (
              <MeetingflowArtifactCard
                key={artifact.id}
                artifact={artifact}
                onClick={() => onClick(artifact.id)}
                onDelete={onDelete}
              />
            );

          case 'AUDIO':
            return (
              <AudioArtifactCard
                key={artifact.id}
                artifact={artifact}
                onClick={() => onClick(artifact.id)}
                onDelete={onDelete}
              />
            );

          case 'DOCUMENT':
            return (
              <DocumentArtifactCard
                key={artifact.id}
                artifact={artifact}
                onClick={() => onClick(artifact.id)}
                onDelete={onDelete}
              />
            );

          case 'FILE':
            return (
              <FileArtifactCard
                key={artifact.id}
                artifact={artifact}
                onClick={() => onClick(artifact.id)}
                onDelete={onDelete}
              />
            );

          case 'IMAGE':
            return (
              <ImageArtifactCard
                key={artifact.id}
                artifact={artifact}
                onClick={() => onClick(artifact.id)}
                onDelete={onDelete}
              />
            );

          case 'LINK':
            return (
              <LinkArtifactCard
                key={artifact.id}
                artifact={artifact}
                onClick={() => onClick(artifact.id)}
                onDelete={onDelete}
              />
            );

          case 'VIDEO':
            return (
              <VideoArtifactCard
                key={artifact.id}
                artifact={artifact}
                onClick={() => onClick(artifact.id)}
                onDelete={onDelete}
              />
            );
        }
      })}
    </GridContainer>
  );
};
