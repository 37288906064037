import { Drawer, styled } from '@mui/material';

export interface StyledDrawerProps {
  isMobile: boolean;
  widthPercentage?: number;
  mobileWidthPercentage?: number;
  minWidth?: number;
  mobileMinWidth?: number;
  maxWidth?: number;
  mobileMaxWidth?: number;
  padding?: string;
}

export const TRANSITION_DURATION = 300;

export const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) =>
    ![
      'isMobile',
      'widthPercentage',
      'mobileWidthPercentage',
      'minWidth',
      'mobileMinWidth',
      'maxWidth',
      'mobileMaxWidth',
      'padding',
    ].includes(prop as string),
})<StyledDrawerProps>(({ theme, isMobile, padding, ...props }) => {
  const {
    widthPercentage = 70,
    mobileWidthPercentage = 90,
    minWidth = 500,
    mobileMinWidth = 200,
    maxWidth = 1000,
    mobileMaxWidth,
  } = props;

  return {
    '& .MuiDrawer-paper': {
      width: isMobile ? `${mobileWidthPercentage}%` : `${widthPercentage}%`,
      minWidth: isMobile ? `${mobileMinWidth}px` : `${minWidth}px`,
      maxWidth: isMobile
        ? mobileMaxWidth
          ? `${mobileMaxWidth}px`
          : 'none'
        : `${maxWidth}px`,
      boxSizing: 'border-box',
      padding: padding || '0 24px',
      backgroundColor: '#f6f6f8',
      overflowY: 'auto',
    },
    '& .MuiBackdrop-root': {
      transition: theme.transitions.create('opacity', {
        duration: TRANSITION_DURATION,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  };
});
