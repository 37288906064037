import {
  Skeleton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  styled,
} from '@mui/material';

const SkeletonTableRow = styled(TableRow)({
  '& > td': {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: '16px',
  },
});

const SkeletonTableCell = styled(TableCell)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

interface Column {
  id: string;
  label: string;
  minWidth?: number;
}

interface DSContactTableLoadingPlaceholderProps {
  columns: Column[];
  rowsPerPage: number;
  isFirstPage?: boolean;
}

export const DSContactTableLoadingPlaceholder = ({
  columns,
  rowsPerPage,
  isFirstPage = true,
}: DSContactTableLoadingPlaceholderProps) => {
  return (
    <Table>
      {isFirstPage && (
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {[...Array(rowsPerPage)].map((_, i) => (
          <SkeletonTableRow key={i}>
            {columns.map((column) => (
              <SkeletonTableCell key={column.id}>
                <Skeleton variant="circular" width={32} height={32} />
                <div style={{ flex: 1 }}>
                  <Skeleton variant="text" width="60%" height={16} />
                  <Skeleton variant="text" width="40%" height={16} />
                </div>
              </SkeletonTableCell>
            ))}
          </SkeletonTableRow>
        ))}
      </TableBody>
    </Table>
  );
};
