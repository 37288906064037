/**
 * @fileoverview Component for managing the association between calendar events/meetingflows
 * and Decision Sites (DealRooms). Allows users to add/remove meetings to/from multiple
 * Decision Sites through an autocomplete interface.
 */

import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import {
  CalendarEvent,
  DealRoom,
} from '@meetingflow/common/Api/data-contracts';
import {
  Chip,
  Typography,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@mui/material';
import { useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

// Utility functions for managing meetingflow artifacts in Decision Sites
import {
  createMeetingflowLinkArtifact,
  removeMeetingflowArtifactFromDecisionSite,
  createMeetingflowAndDecisionSiteArtifact,
} from '../../../../utils/DecisionSiteMeetingflowHelpers';

// Styled components for the Decision Site selection interface
import {
  AddToDecisionSiteContainer,
  DecisionSiteOptionBox,
  DecisionSiteOptionAvatar,
  DecisionSiteOptionContent,
  DecisionSiteChipContent,
  DecisionSiteChipAvatar,
  StyledAutocomplete,
  StyledTextField,
  getChipStyles,
} from './DSMeetingsAddToDecisionSites.styles';

// Hook for fetching available DealRooms
import { useDealRooms } from '../../../../Hooks/useDealRooms';

/**
 * Props for the DSMeetingsAddToDecisionSites component
 * @interface DSMeetingsAddToDecisionSitesProps
 * @property {CalendarEvent} event - The calendar event to be associated with Decision Sites
 */
interface DSMeetingsAddToDecisionSitesProps {
  event: CalendarEvent;
}

/**
 * Main component for managing Decision Site associations for a calendar event.
 * Memoized to prevent unnecessary re-renders.
 *
 * @component
 * @param {DSMeetingsAddToDecisionSitesProps} props - Component props
 */
export const DSMeetingsAddToDecisionSites: React.FC<DSMeetingsAddToDecisionSitesProps> =
  React.memo(({ event }) => {
    const organizationSlug = useOrganizationSlug();
    const { dealRooms } = useDealRooms(organizationSlug!, false);
    const [selectedDealRoomIds, setSelectedDealRoomIds] = useState<number[]>(
      event.dealRoomIds || [],
    );

    return (
      <DSMeetingAddToDecisionSite
        meetingflowId={event.meetingplanId}
        event={event}
        dealRooms={dealRooms}
        selectedDealRoomIds={selectedDealRoomIds}
        setSelectedDealRoomIds={setSelectedDealRoomIds}
      />
    );
  });

/**
 * Props for the inner DSMeetingAddToDecisionSite component
 * @interface DSMeetingAddToDecisionSiteProps
 * @property {string | undefined} meetingflowId - ID of the existing meetingflow, if any
 * @property {CalendarEvent} event - The calendar event being managed
 * @property {DealRoom[]} dealRooms - Available Decision Sites/DealRooms
 * @property {number[]} selectedDealRoomIds - Currently selected DealRoom IDs
 * @property {Function} setSelectedDealRoomIds - State setter for selected DealRoom IDs
 */
interface DSMeetingAddToDecisionSiteProps {
  meetingflowId: string | undefined;
  event: CalendarEvent;
  dealRooms: DealRoom[];
  selectedDealRoomIds: number[];
  setSelectedDealRoomIds: React.Dispatch<React.SetStateAction<number[]>>;
}

/**
 * Inner component that handles the actual Decision Site selection interface.
 * Contains the business logic for adding/removing meetingflows to/from Decision Sites.
 * Memoized to prevent unnecessary re-renders.
 *
 * @component
 * @param {DSMeetingAddToDecisionSiteProps} props - Component props
 */
const DSMeetingAddToDecisionSite: React.FC<DSMeetingAddToDecisionSiteProps> =
  React.memo(
    ({
      meetingflowId,
      event,
      dealRooms,
      selectedDealRoomIds,
      setSelectedDealRoomIds,
    }) => {
      const organizationSlug = useOrganizationSlug();
      const { getAccessTokenSilently } = useAuth0();
      const queryClient = useQueryClient();
      const navigate = useNavigate();

      // Memoize token getter
      // Memoized function to get authentication token
      // Prevents unnecessary re-creation of the token getter function
      const getToken = useCallback(async () => {
        return await getAccessTokenSilently();
      }, [getAccessTokenSilently]);

      /**
       * Handles changes to the Decision Site selection.
       * Manages both adding new associations and removing existing ones.
       * For events without a meetingflow, creates one before establishing the association.
       */
      const handleChange = useCallback(
        async (
          _event: React.SyntheticEvent,
          newValue: DealRoom[],
          reason: AutocompleteChangeReason,
          _details?: AutocompleteChangeDetails<DealRoom>,
        ) => {
          if (!organizationSlug) return;

          const token = await getToken();
          const newIds = newValue.map((dr) => dr.id);
          const removedIds = selectedDealRoomIds.filter(
            (id: number) => !newIds.includes(id),
          );
          const addedIds = newIds.filter(
            (id: number) => !selectedDealRoomIds.includes(id),
          );

          if (meetingflowId) {
            // For existing meetingflows, handle add/remove
            await Promise.all([
              ...removedIds.map((id) =>
                removeMeetingflowArtifactFromDecisionSite({
                  organizationSlug,
                  dealRoomId: id,
                  meetingflowId,
                  token,
                  queryClient,
                  callback: () => {
                    queryClient.invalidateQueries('ownedMeetingflows');
                  },
                }),
              ),
              ...addedIds.map((id) =>
                createMeetingflowLinkArtifact(
                  organizationSlug,
                  id,
                  meetingflowId,
                  queryClient,
                  token,
                  false,
                  true,
                ),
              ),
            ]);
          } else {
            // For events without a meetingflow
            await Promise.all(
              addedIds.map((id) =>
                createMeetingflowAndDecisionSiteArtifact({
                  event,
                  organizationSlug,
                  token,
                  dealRoomId: id,
                  queryClient,
                }),
              ),
            );
          }

          setSelectedDealRoomIds(newIds);
          queryClient.invalidateQueries('ownedMeetingflows');
          queryClient.invalidateQueries('user-meetingflows');
        },
        [
          organizationSlug,
          meetingflowId,
          event,
          selectedDealRoomIds,
          getToken,
          queryClient,
          setSelectedDealRoomIds,
        ],
      );

      const handleClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }, []);

      return (
        <AddToDecisionSiteContainer onClick={handleClick}>
          <StyledAutocomplete<DealRoom, true>
            size="small"
            multiple
            options={dealRooms.filter(
              (dr) => !selectedDealRoomIds.includes(dr.id),
            )}
            getOptionLabel={(option) => option.name}
            value={dealRooms.filter((dr) =>
              selectedDealRoomIds.includes(dr.id),
            )}
            onChange={handleChange}
            slotProps={{
              popper: {
                sx: {
                  width: 'fit-content !important',
                  minWidth: '250px !important',
                  '& .MuiAutocomplete-listbox': {
                    width: 'fit-content !important',
                    minWidth: '100% !important',
                  },
                },
              },
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <DecisionSiteOptionBox>
                  <DecisionSiteOptionAvatar
                    src={option.buyerLogoUrl ?? undefined}
                    alt={option.name}
                  >
                    {option.name.charAt(0)}
                  </DecisionSiteOptionAvatar>
                  <DecisionSiteOptionContent>
                    <Typography variant="body2">{option.name}</Typography>
                  </DecisionSiteOptionContent>
                </DecisionSiteOptionBox>
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option.id}
                  onClick={(e) => {
                    // Don't navigate if clicking delete button
                    if (
                      (e.target as HTMLElement).closest('.MuiChip-deleteIcon')
                    )
                      return;
                    navigate(
                      `/organization/${organizationSlug}/decisionsite/${option.id}`,
                    );
                  }}
                  label={
                    <DecisionSiteChipContent>
                      <DecisionSiteChipAvatar
                        src={option.buyerLogoUrl ?? undefined}
                        alt={option.name}
                      >
                        {option.name.charAt(0)}
                      </DecisionSiteChipAvatar>
                      {option.name}
                    </DecisionSiteChipContent>
                  }
                  sx={getChipStyles(option.primaryColor)}
                  onDelete={async () => {
                    const token = await getAccessTokenSilently();
                    if (!organizationSlug || !meetingflowId) return;
                    removeMeetingflowArtifactFromDecisionSite({
                      organizationSlug,
                      dealRoomId: option.id,
                      meetingflowId,
                      token,
                      queryClient,
                      callback: () => {
                        queryClient.invalidateQueries('ownedMeetingflows');
                      },
                    });
                    setSelectedDealRoomIds(
                      selectedDealRoomIds.filter((id) => id !== option.id),
                    );
                  }}
                />
              ))
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                variant="standard"
                placeholder=" + Add"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )}
          />
        </AddToDecisionSiteContainer>
      );
    },
  );

export default DSMeetingsAddToDecisionSites;
