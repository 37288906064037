import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  DealRoomArtifact,
  DealRoomVideoArtifact,
  DealRoomImageArtifact,
  DealRoomLinkArtifact,
  DealRoomAudioArtifact,
  DealRoomFileArtifact,
  DealRoomMeetingflowArtifact,
  DealRoomDocumentArtifact,
} from '@meetingflow/common/Api/data-contracts';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DSHighlightsCarousel from '../Analytics/components/DSHighlightsCarousel';
import { DSWelcomeVideoArtifact } from './DSWelcomeVideoArtifact';
import { DSWelcomeImageArtifact } from './DSWelcomeImageArtifact';
import { DSWelcomeLinkArtifact } from './DSWelcomeLinkArtifact';
import { DSWelcomeAudioArtifact } from './DSWelcomeAudioArtifact';
import { DSWelcomeFileArtifact } from './DSWelcomeFileArtifact';
import { DSWelcomeMeetingflowArtifact } from './DSWelcomeMeetingflowArtifact';
import { DSWelcomeDocumentArtifact } from './DSWelcomeDocumentArtifact';
import { WelcomeArtifactCardContainer } from './DSWelcomeArtifactCarousel.styles';
import { DSWelcomeExampleArtifact } from './DSWelcomeExampleArtifact';
import {
  VideocamOutlined,
  ImageOutlined,
  LinkOutlined,
} from '@mui/icons-material';

interface DSWelcomeArtifactCarouselProps {
  artifacts: DealRoomArtifact[];
  ownerName?: string | null;
}

// Wrapper component to apply the highlights-card-content class
const ArtifactWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="highlights-card-content">{children}</div>;
};

export const DSWelcomeArtifactCarousel: React.FC<
  DSWelcomeArtifactCarouselProps
> = ({ artifacts, ownerName }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  // Add state to track the current carousel index
  const [currentIndex, setCurrentIndex] = useState(0);
  const prevArtifactsLengthRef = useRef(artifacts?.length || 0);

  // Monitor changes in the artifacts array
  useEffect(() => {
    // If an artifact was removed
    if (artifacts && artifacts.length < prevArtifactsLengthRef.current) {
      setCurrentIndex(prevIndex => {
        // If the current index is now out of bounds, adjust it
        if (prevIndex >= artifacts.length && artifacts.length > 0) {
          return artifacts.length - 1;
        } else if (artifacts.length === 0) {
          // If all artifacts were removed, reset to 0
          return 0;
        }
        return prevIndex;
      });
    }
    // Update the ref value (doesn't trigger re-renders)
    prevArtifactsLengthRef.current = artifacts?.length || 0;
  }, [artifacts]); // Only depend on artifacts

  // Define handleIndexChange before any conditional returns
  const handleIndexChange = useCallback((index: number) => {
    setCurrentIndex(index);
  }, []);

  // If no artifacts, create example cards
  if (!artifacts || artifacts.length === 0) {
    const exampleCards = [
      {
        id: 1,
        type: 'VIDEO',
        name: 'Introduction to Our Company (Example)',
        description: `A quick overview of our company and what we do. This welcome video helps new users understand our products and services.`,
        icon: <VideocamOutlined sx={{ fontSize: 60 }} />,
      },
      {
        id: 2,
        type: 'IMAGE',
        name: 'Product Features Overview (Example)',
        description:
          'Visual representation of our product features and benefits. Images help communicate complex ideas quickly and effectively.',
        icon: <ImageOutlined sx={{ fontSize: 60 }} />,
      },
      {
        id: 3,
        type: 'LINK',
        name: 'Documentation & Resources (Example)',
        description:
          'Links to helpful documentation and resources for your reference. These resources will help you get started with our platform.',
        icon: <LinkOutlined sx={{ fontSize: 60 }} />,
      },
    ];

    const exampleCarouselItems = exampleCards.map((card) => (
      <WelcomeArtifactCardContainer key={`example-${card.type}-${card.id}`}>
        <ArtifactWrapper>
          <DSWelcomeExampleArtifact
            type={card.type}
            name={card.name}
            description={card.description}
            icon={card.icon}
            creatorName={ownerName || 'Example User'}
          />
        </ArtifactWrapper>
      </WelcomeArtifactCardContainer>
    ));

    const carouselProps = {
      items: exampleCarouselItems,
      height: isSmallScreen ? '420px' : '250px',
    };

    return (
      <DSHighlightsCarousel 
        {...carouselProps} 
        currentIndex={currentIndex}
        onIndexChange={handleIndexChange}
      />
    );
  }

  const carouselItems = artifacts
    .map((artifact, index) => {
      switch (artifact.type) {
        case 'VIDEO':
          return (
            <WelcomeArtifactCardContainer
              key={`welcome-video-${artifact.id || index}`}
            >
              <ArtifactWrapper>
                <DSWelcomeVideoArtifact
                  videoArtifact={artifact as DealRoomVideoArtifact}
                />
              </ArtifactWrapper>
            </WelcomeArtifactCardContainer>
          );
        case 'IMAGE':
          return (
            <WelcomeArtifactCardContainer
              key={`welcome-image-${artifact.id || index}`}
            >
              <ArtifactWrapper>
                <DSWelcomeImageArtifact
                  imageArtifact={artifact as DealRoomImageArtifact}
                />
              </ArtifactWrapper>
            </WelcomeArtifactCardContainer>
          );
        case 'LINK':
          return (
            <WelcomeArtifactCardContainer
              key={`welcome-link-${artifact.id || index}`}
            >
              <ArtifactWrapper>
                <DSWelcomeLinkArtifact
                  linkArtifact={artifact as DealRoomLinkArtifact}
                />
              </ArtifactWrapper>
            </WelcomeArtifactCardContainer>
          );
        case 'AUDIO':
          return (
            <WelcomeArtifactCardContainer
              key={`welcome-audio-${artifact.id || index}`}
            >
              <ArtifactWrapper>
                <DSWelcomeAudioArtifact
                  audioArtifact={artifact as DealRoomAudioArtifact}
                />
              </ArtifactWrapper>
            </WelcomeArtifactCardContainer>
          );
        case 'FILE':
          return (
            <WelcomeArtifactCardContainer
              key={`welcome-file-${artifact.id || index}`}
            >
              <ArtifactWrapper>
                <DSWelcomeFileArtifact
                  fileArtifact={artifact as DealRoomFileArtifact}
                />
              </ArtifactWrapper>
            </WelcomeArtifactCardContainer>
          );
        case 'DOCUMENT':
          return (
            <WelcomeArtifactCardContainer
              key={`welcome-document-${artifact.id || index}`}
            >
              <ArtifactWrapper>
                <DSWelcomeDocumentArtifact
                  documentArtifact={artifact as DealRoomDocumentArtifact}
                />
              </ArtifactWrapper>
            </WelcomeArtifactCardContainer>
          );
        case 'MEETINGFLOW':
          return (
            <WelcomeArtifactCardContainer
              key={`welcome-meetingflow-${artifact.id || index}`}
            >
              <ArtifactWrapper>
                <DSWelcomeMeetingflowArtifact
                  meetingflowArtifact={artifact as DealRoomMeetingflowArtifact}
                />
              </ArtifactWrapper>
            </WelcomeArtifactCardContainer>
          );
        default:
          return null;
      }
    })
    .filter(Boolean);

  const carouselProps = {
    items: carouselItems,
    height: isSmallScreen ? '420px' : '250px',
  };

  return (
    <DSHighlightsCarousel
      {...carouselProps}
      currentIndex={currentIndex}
      onIndexChange={handleIndexChange}
    />
  );
};

export default DSWelcomeArtifactCarousel;
