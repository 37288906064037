import { Stack, Text } from '@fluentui/react';
import { Box } from '@mui/material';
import { getStyles } from '../UploadDealRoomAttachmentsDialog.styles';
import { useFileUpload } from './hooks';
import { DSTabs, DSTab } from '../../DS';
import React from 'react';
import {
  CommonUploadArtifactsFile,
  CommonUploadArtifactsURL,
} from '../CommonUploadArtifacts';

import { TabsRef } from '../../DS/DSTabs';

export interface UploadFormProps {
  title: string;
  setTitle: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  selectedFile: File | null;
  setSelectedFile: (value: File | null) => void;
  url: string;
  setUrl: (value: string) => void;
  dealRoomRole: string | undefined;
  pivotRef: React.RefObject<TabsRef>;
  selectedTab?: string;
  setSelectedTab?: (value: string) => void;
}

export const UploadForm: React.FC<UploadFormProps> = ({
  title,
  setTitle,
  description,
  setDescription,
  selectedFile,
  setSelectedFile,
  url,
  setUrl,
  pivotRef,
  selectedTab,
  setSelectedTab,
}) => {
  const styles = getStyles();

  const { handleSelectFile, handleDrop } = useFileUpload({
    setSelectedFile,
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <DSTabs
        ref={pivotRef}
        defaultValue="file"
        value={selectedTab}
        onChange={(_, newValue) => setSelectedTab?.(newValue as string)}
        aria-label="attachment tabs"
      >
        <DSTab
          value="file"
          label={'Upload File'}
          panelStyles={{
            padding: '1rem 0 0 0',
          }}
          panel={
            <CommonUploadArtifactsFile
              actionItemId={null}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              title={title}
              setTitle={setTitle}
              description={description}
              setDescription={setDescription}
            />
          }
        />

        <DSTab
          value="url"
          label="Create from URL"
          panel={
            <Stack.Item className={styles.content}>
              <CommonUploadArtifactsURL
                url={url}
                setUrl={setUrl}
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
              />
            </Stack.Item>
          }
        />
      </DSTabs>
    </Box>
  );
};
