import { IGroup } from '@fluentui/react';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { DateTime } from 'luxon';
import { CalendarEvent } from '@meetingflow/common/Api/data-contracts';

export const getAdjustedEventStartTimeString = (
  event: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>,
) => getAdjustedEventStartDateTime(event).toISO();

export const getAdjustedEventEndTimeString = (
  event: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>,
) => getAdjustedEventEndDateTime(event).toISO();

export const getAdjustedEventStartDateTime = (
  event: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>,
) => {
  return DateTime.fromISO(event.startTime, { setZone: true }).setZone('local', {
    keepLocalTime: event.isAllDay,
  });
};

export const getAdjustedEventEndDateTime = (
  event: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>,
) => {
  return DateTime.fromISO(event.startTime, { setZone: true }).setZone('local', {
    keepLocalTime: event.isAllDay,
  });
};

export const toIGroups = (
  events?: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>[],
): IGroup[] => {
  if (!events?.length) {
    return [];
  }
  let dayOfYear = getAdjustedEventStartDateTime(events[0]).ordinal;
  const groups: IGroup[] = [
    {
      key: `${dayOfYear}`,
      name: humanizeDateTime(getAdjustedEventStartTimeString(events[0]), {
        displayComponents: ['date'],
        dateFormatOptions: {
          weekday: 'long',
          month: 'short',
          day: 'numeric',
          year: '2-digit',
        },
      }),
      startIndex: 0,
      count: 0,
      level: 0,
    },
  ];
  events.forEach((event, idx) => {
    const latestDayOfYear = getAdjustedEventStartDateTime(event).ordinal;
    if (latestDayOfYear === dayOfYear) {
      groups[groups.length - 1]!.count += 1;
    } else {
      groups.push({
        key: `${latestDayOfYear}`,
        name: humanizeDateTime(getAdjustedEventStartTimeString(event), {
          displayComponents: ['date'],
          dateFormatOptions: {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
            year: '2-digit',
          },
        }),
        startIndex: idx,
        count: 1,
        level: 0,
      });
    }
    dayOfYear = latestDayOfYear;
  });
  return groups;
};

export const groupEventsByDay = (
  events?: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>[],
) => {
  if (!events?.length) {
    return [];
  }

  // First, ensure events are sorted chronologically
  const sortedEvents = [...events].sort((a, b) => {
    const dateA = DateTime.fromISO(a.startTime);
    const dateB = DateTime.fromISO(b.startTime);
    return dateA.valueOf() - dateB.valueOf();
  });

  const groups: {
    key: string;
    name: string;
    startIndex: number;
    count: number;
    level: number;
    items: Pick<CalendarEvent, 'startTime' | 'endTime' | 'isAllDay'>[];
  }[] = [];

  // Group events by day
  sortedEvents.forEach((event, idx) => {
    const eventDateTime = getAdjustedEventStartDateTime(event);
    // Create a more unique key using year + day of year
    const yearDayKey = `${eventDateTime.year}-${eventDateTime.ordinal}`;
    
    // Format the day name
    const dayName = humanizeDateTime(getAdjustedEventStartTimeString(event), {
      displayComponents: ['date'],
      dateFormatOptions: {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
        year: '2-digit',
      },
    });
    
    // Check if we already have a group for this day
    const existingGroupIndex = groups.findIndex(group => group.key === yearDayKey);
    
    if (existingGroupIndex >= 0) {
      // Add to existing group
      groups[existingGroupIndex].count += 1;
      groups[existingGroupIndex].items.push(event);
    } else {
      // Create new group
      groups.push({
        key: yearDayKey,
        name: dayName,
        startIndex: idx,
        count: 1,
        level: 0,
        items: [event],
      });
    }
  });

  return groups;
};
