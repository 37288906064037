import { useCallback, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { Save, Cancel, CheckCircle } from '@mui/icons-material';
import { DSSwitch, DSButton } from '../../DS';
import {
  KeyFactCard,
  KeyFactTitle,
  KeyFactValue,
  BuyerEditToggle,
  ToggleLabel,
  StyledMuiTextField,
  StyledMuiMultilineTextField,
} from './DealRoomKeyFacts.styles';
import { DealRoomFact } from '@meetingflow/common/Api/data-contracts';
import toast from 'react-hot-toast';

interface DealRoomKeyFactCreateModeProps {
  organizationSlug: string | undefined;
  dealRoomId: number | undefined;
  keyFact: DealRoomFact;
  onSave: (keyFact: DealRoomFact, title: string, value: string) => void;
  onCancel: () => void;
}

export const DealRoomKeyFactCreateMode = ({
  organizationSlug,
  dealRoomId,
  keyFact,
  onSave,
  onCancel,
}: DealRoomKeyFactCreateModeProps) => {
  const [titleValue, setTitleValue] = useState(keyFact.title || '');
  const [textValue, setTextValue] = useState(keyFact.value || '');
  const [editableByBuyer, setEditableByBuyer] = useState(
    !!keyFact.editableByBuyer,
  );
  const [valueRef, setValueRef] = useState<HTMLTextAreaElement | null>(null);
  const [isTogglingBuyerEdit, setIsTogglingBuyerEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Store a reference to the title input element
  const titleInputRef = useRef<HTMLInputElement | null>(null);

  // Use callback ref for title input to focus on mount
  const titleRef = useCallback((node: HTMLInputElement) => {
    titleInputRef.current = node;
    if (node !== null) {
      node.focus();
    }
  }, []);

  const handleSave = useCallback(async () => {
    // Only save if values have changed
    if (
      titleValue === keyFact.title &&
      textValue === keyFact.value &&
      editableByBuyer === keyFact.editableByBuyer
    ) {
      onCancel(); // No changes to save
      return;
    }

    if (!titleValue.trim()) {
      toast.error('Title is required');
      return;
    }

    if (!textValue.trim()) {
      toast.error('Value is required');
      return;
    }

    setIsSubmitting(true);

    try {
      // Call the onSave callback with the updated key fact data
      await onSave(
        {
          ...keyFact,
          title: titleValue,
          value: textValue,
          editableByBuyer,
        },
        titleValue,
        textValue,
      );
      // No need to reset isSubmitting here as the component will be unmounted after successful save
    } catch (error) {
      console.error('Error saving key fact:', error);
      toast.error('Failed to save key fact');
      setIsSubmitting(false);
    }
  }, [titleValue, textValue, editableByBuyer, keyFact, onSave, onCancel]);

  return (
    <KeyFactCard>
      <KeyFactTitle
        variant="subtitle2"
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <StyledMuiTextField
            label="Title"
            value={titleValue}
            onChange={(e) => setTitleValue(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Enter') {
                e.preventDefault();
                if (!textValue.trim()) {
                  // If value is empty, focus the value field
                  if (valueRef) {
                    valueRef.focus();
                  }
                } else if (titleValue.trim() && textValue.trim()) {
                  // If both fields have content, save the card
                  handleSave();
                }
              }
            }}
            placeholder="Enter title"
            size="small"
            inputRef={titleRef}
            variant="outlined"
            fullWidth
            required
            disabled={isSubmitting}
            margin="dense"
          />
        </Box>
      </KeyFactTitle>

      <KeyFactValue
        variant="body1"
        isLongText={(textValue || '').length > 12}
        sx={{
          width: '100%',
          marginTop: 0,
          position: 'relative',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <StyledMuiMultilineTextField
            label="Value"
            value={textValue}
            onChange={(e) => {
              setTextValue(e.target.value);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                if (!titleValue.trim()) {
                  // If title is empty, focus the title field
                  if (titleInputRef.current) {
                    titleInputRef.current.focus();
                  }
                } else if (titleValue.trim() && textValue.trim()) {
                  // If both fields have content, save the card
                  handleSave();
                }
              }
            }}
            placeholder="Enter value"
            multiline
            minRows={3}
            maxRows={8}
            size="small"
            fullWidth
            inputRef={(el) => {
              setValueRef(el);
            }}
            variant="outlined"
            required
            disabled={isSubmitting}
            margin="dense"
            InputProps={{
              style: { width: '100%' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                width: '100%',
              },
            }}
          />
        </Box>
      </KeyFactValue>

      <BuyerEditToggle
        onClick={(e) => {
          // Prevent the click from bubbling up
          e.stopPropagation();
        }}
        onMouseDown={(e) => {
          // Prevent onBlur from firing on the inputs
          e.preventDefault();
        }}
        onMouseEnter={() => setIsTogglingBuyerEdit(true)}
        onMouseLeave={() => setIsTogglingBuyerEdit(false)}
      >
        <DSSwitch
          checked={editableByBuyer}
          onChange={(e) => {
            // Prevent the change event from bubbling up
            e.stopPropagation();
            setEditableByBuyer(e.target.checked);
          }}
          size="small"
          branded
          onMouseDown={(e) => {
            // Prevent onBlur from firing on the inputs
            e.preventDefault();
          }}
          onClick={(e) => {
            // Prevent the click from bubbling up
            e.stopPropagation();
          }}
          disabled={isSubmitting}
        />
        <ToggleLabel>Editable by buyer</ToggleLabel>
      </BuyerEditToggle>

      {/* Buttons at the bottom */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 2,
          gap: 1,
          marginLeft: 'auto',
        }}
      >
        <DSButton
          variant="outlined"
          color="secondary"
          onClick={onCancel}
          disabled={isSubmitting}
          iconOnly
          size="small"
          startIcon={<Cancel />}
        />
        <DSButton
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={isSubmitting}
          size="small"
          iconOnly
          startIcon={<CheckCircle />}
        />
      </Box>
    </KeyFactCard>
  );
};
