import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const WelcomeArtifactCardContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  overflow: 'hidden',
  pointerEvents: 'auto', // Ensure the container is clickable
  cursor: 'pointer', // Show hand cursor to indicate clickability
}));
