import { PrimaryButton } from '@fluentui/react';
import classNames from 'classnames';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import {
  UserSettingsGroupElemType,
  UserSettingsGroupsType,
} from './UserSettingsWrapper';

interface UserSettingsButtonProps {
  group: UserSettingsGroupElemType;
  selectedSettingsGroup: string;
  index: number;
  setSelectedSettingsGroup: React.Dispatch<
    React.SetStateAction<keyof UserSettingsGroupsType>
  >;
}

export const UserSettingsButton = ({
  group,
  selectedSettingsGroup,
  index,
  setSelectedSettingsGroup,
}: UserSettingsButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams, setQueryParams] = useSearchParams();

  return (
    <PrimaryButton
      key={`settings_group_${index}`}
      className={classNames('settings_group_content', {
        ['settings_group_content_active']:
          group.value === selectedSettingsGroup,
      })}
      onClick={() => {
        setSelectedSettingsGroup(group.value);
        queryParams.set('group', group.value);
        navigate({
          pathname: location.pathname,
          search: queryParams.toString(),
        });
      }}
    >
      {group.icon(
        group.value === selectedSettingsGroup
          ? DEALROOMS_COLORS.darkerGray
          : null,
      )}
      <div
        className={classNames('settings_group_label', {
          ['settings_group_label_active']:
            group.value === selectedSettingsGroup,
        })}
      >
        {group.label}
      </div>
    </PrimaryButton>
  );
};
