import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { DSButton } from '../DS';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const OverviewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '-3rem',
  overflow: 'visible !important',

  [theme.breakpoints.down('md')]: {
    marginTop: '-2rem',
  },
}));

export const OverviewHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  position: 'relative',
  overflow: 'visible !important',
}));

export const OverviewContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(6),
  overflow: 'visible !important',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(0),
  },
}));

export const ContentMain = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'visible !important',
  gap: theme.spacing(2),
}));

export const ContentSidebar = styled(Box)(({ theme }) => ({
  width: '300px',
  overflow: 'visible !important',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const AddArtifactButton = styled(DSButton)({
  display: 'inline-flex',
  minWidth: 'unset',
  marginLeft: '8px',
  padding: '0',
  position: 'relative',
  top: '-1px',
  color: `${DEALROOMS_COLORS.cloudburst}99`, // 60% opacity (99 in hex is ~60%)
  transition: 'all 0.3s ease-in-out',
  '& .MuiButton-startIcon': {
    marginRight: 0,
  },
  '& .MuiButton-startIcon > *:nth-of-type(1)': {
    fontSize: 16,
  },
  '& .MuiButton-endIcon': {
    display: 'none',
  },

  '&:hover': {
    color: `${DEALROOMS_COLORS.cloudburst}`,
  },
});
