import { FC, useEffect, useState, useRef, useCallback, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  HighlightsCarouselContainer,
  CarouselBoxContainer,
  CarouselCloneCard,
  CarouselItem,
  TimestampTypography,
  CustomCarouselContainer,
  CarouselTrack,
  CarouselSlide,
  CarouselNavigation,
  CarouselDot,
  PrevButton,
  NextButton,
} from './DSHighlightsCarousel.styles';

interface DSHighlightsCarouselProps {
  items: ReactNode[]; // Array of ReactNode items to display in the carousel
  timestamp?: string | null; // Optional timestamp to display
  showSideButtons?: boolean; // Optional prop to control side button visibility
  height?: string; // Optional prop to control the carousel height
  currentIndex?: number; // Optional prop to control the current index externally
  onIndexChange?: (index: number) => void; // Optional callback for index changes
}

export const DSHighlightsCarousel: FC<DSHighlightsCarouselProps> = ({
  items,
  timestamp,
  showSideButtons = false, // Hidden by default
  height = '250px', // Default height
  currentIndex: externalCurrentIndex,
  onIndexChange,
}) => {
  // Custom carousel implementation
  const [internalCurrentIndex, setInternalCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  // Use either the external or internal index
  const currentIndex = externalCurrentIndex !== undefined ? externalCurrentIndex : internalCurrentIndex;

  // Filter out any null or undefined items
  const carouselItems = items.filter(Boolean);

  // Update the current index when external index changes
  useEffect(() => {
    if (externalCurrentIndex !== undefined) {
      setInternalCurrentIndex(externalCurrentIndex);
    }
  }, [externalCurrentIndex]);

  const handleNext = useCallback(() => {
    const nextIndex = currentIndex === carouselItems.length - 1 ? 0 : currentIndex + 1;
    setInternalCurrentIndex(nextIndex);
    if (onIndexChange) {
      onIndexChange(nextIndex);
    }
  }, [carouselItems.length, currentIndex, onIndexChange]);

  const handlePrev = useCallback(() => {
    const prevIndex = currentIndex === 0 ? carouselItems.length - 1 : currentIndex - 1;
    setInternalCurrentIndex(prevIndex);
    if (onIndexChange) {
      onIndexChange(prevIndex);
    }
  }, [carouselItems.length, currentIndex, onIndexChange]);

  // Handle mouse wheel scrolling
  const handleWheel = useCallback(
    (e: WheelEvent) => {
      // Check if the event target is inside a card content area
      const target = e.target as HTMLElement;
      if (target.closest('.highlights-card-content')) {
        // If inside card content, don't do anything - let the browser handle scrolling
        return;
      }

      // Only handle wheel events outside of card content
      // Handle both vertical and horizontal scrolling
      const delta =
        Math.abs(e.deltaY) > Math.abs(e.deltaX) ? e.deltaY : e.deltaX;

      // Only trigger if the scroll is significant
      if (Math.abs(delta) < 10) return;

      // Add debounce to prevent rapid scrolling through slides
      const now = Date.now();
      if (now - lastWheelTime.current < 300) return;
      lastWheelTime.current = now;

      if (delta > 0) {
        handleNext();
      } else {
        handlePrev();
      }
      e.preventDefault();
    },
    [handleNext, handlePrev],
  );

  // Ref to track last wheel event time for debouncing
  const lastWheelTime = useRef(0);

  // Set up wheel event listener
  useEffect(() => {
    const carousel = carouselRef.current;
    if (carousel) {
      // Ensure the event is properly captured with the correct options
      carousel.addEventListener('wheel', handleWheel, { passive: false });

      // Also listen for touchpad gestures on Macs
      carousel.addEventListener('mousewheel', handleWheel as EventListener, {
        passive: false,
      });
      carousel.addEventListener(
        'DOMMouseScroll',
        handleWheel as EventListener,
        { passive: false },
      );

      return () => {
        carousel.removeEventListener('wheel', handleWheel);
        carousel.removeEventListener(
          'mousewheel',
          handleWheel as EventListener,
        );
        carousel.removeEventListener(
          'DOMMouseScroll',
          handleWheel as EventListener,
        );
      };
    }
  }, [handleWheel]);

  if (carouselItems.length === 0) {
    return <Typography align="center">No items available.</Typography>;
  }

  const handleDotClick = (index: number) => {
    setInternalCurrentIndex(index);
    if (onIndexChange) {
      onIndexChange(index);
    }
  };

  // Handle touch events for mobile swipe
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
    setTouchEnd(e.targetTouches[0].clientX); // Reset touchEnd to prevent unintended swipes
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    // Detect swipe with a threshold of 50px
    const threshold = 50;
    const diff = touchStart - touchEnd;

    if (diff > threshold) {
      // Swipe left, go to next slide
      handleNext();
    } else if (diff < -threshold) {
      // Swipe right, go to previous slide
      handlePrev();
    }
    // Reset values
    setTouchStart(0);
    setTouchEnd(0);
  };

  return (
    <HighlightsCarouselContainer>
      <CarouselBoxContainer height={height}>
        <CustomCarouselContainer>
          {/* Fixed-position carousel with absolute positioning for slides */}
          <CarouselTrack
            ref={carouselRef}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onClick={(e) => {
              // Get click position relative to carousel track
              const trackRect = carouselRef.current?.getBoundingClientRect();
              if (!trackRect) return;
              
              const clickX = e.clientX - trackRect.left;
              const trackWidth = trackRect.width;
              
              // Determine if click was on left or right side
              if (clickX < trackWidth / 2) {
                // Left side click - go to previous
                handlePrev();
              } else {
                // Right side click - go to next
                handleNext();
              }
              e.preventDefault();
            }}
            sx={{ pointerEvents: 'auto' }} // Make the track clickable
          >
            {/* Render the last card to the left of the first card when the first card is active */}
            {currentIndex === 0 && carouselItems.length > 1 && (
              <CarouselCloneCard
                key="last-card-clone"
                className="last-card-clone"
                onClick={(e) => {
                  // Prevent any child elements from handling the click
                  e.stopPropagation();
                  setInternalCurrentIndex(carouselItems.length - 1);
                  if (onIndexChange) {
                    onIndexChange(carouselItems.length - 1);
                  }
                }}
                sx={{
                  transform: `translateX(-50%) translateX(${-1 * 80}%) scale(0.98)`,
                  zIndex: 5, // Match the z-index in styles
                }}
              >
                <CarouselSlide active={false}>
                  {carouselItems[carouselItems.length - 1]}
                </CarouselSlide>
              </CarouselCloneCard>
            )}

            {/* Render the main carousel items */}
            {carouselItems.map((item, index) => {
              // Calculate position relative to current index
              const position = index - currentIndex;
              return (
                <CarouselItem
                  key={index}
                  isActive={index === currentIndex}
                  onClick={(e) => {
                    if (index !== currentIndex) {
                      // Prevent any child elements from handling the click
                      e.stopPropagation();
                      setInternalCurrentIndex(index);
                      if (onIndexChange) {
                        onIndexChange(index);
                      }
                    }
                  }}
                  sx={{
                    transform:
                      index === currentIndex
                        ? `translateX(-50%) translateX(${position * 80}%)`
                        : `translateX(-50%) translateX(${position * 80}%) scale(0.98)`,
                    zIndex: index === currentIndex ? 20 : 5, // Match the z-index in styles
                  }}
                >
                  <CarouselSlide active={index === currentIndex}>
                    {item}
                  </CarouselSlide>
                </CarouselItem>
              );
            })}

            {/* Render the first card to the right of the last card when the last card is active */}
            {currentIndex === carouselItems.length - 1 &&
              carouselItems.length > 1 && (
                <CarouselCloneCard
                  key="first-card-clone"
                  className="first-card-clone"
                  onClick={(e) => {
                    // Prevent any child elements from handling the click
                    e.stopPropagation();
                    setInternalCurrentIndex(0);
                    if (onIndexChange) {
                      onIndexChange(0);
                    }
                  }}
                  sx={{
                    transform: `translateX(-50%) translateX(${1 * 80}%) scale(0.98)`,
                    zIndex: 5, // Match the z-index in styles
                  }}
                >
                  <CarouselSlide active={false}>
                    {carouselItems[0]}
                  </CarouselSlide>
                </CarouselCloneCard>
              )}

            {carouselItems.length > 1 && showSideButtons && (
              <>
                <PrevButton
                  onClick={handlePrev}
                  size="small"
                  disabled={false} // Never disabled since we can always go to the previous slide in a circular carousel
                  sx={{ zIndex: 20 }}
                >
                  <ChevronLeft sx={{ fontSize: 16 }} />
                </PrevButton>
                <NextButton
                  onClick={handleNext}
                  size="small"
                  disabled={false} // Never disabled since we can always go to the next slide in a circular carousel
                  sx={{ zIndex: 20 }}
                >
                  <ChevronRight sx={{ fontSize: 16 }} />
                </NextButton>
              </>
            )}
          </CarouselTrack>

          {carouselItems.length > 1 && (
            <CarouselNavigation>
              {carouselItems.map((_, index) => (
                <CarouselDot
                  key={index}
                  active={currentIndex === index}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </CarouselNavigation>
          )}
        </CustomCarouselContainer>
        {timestamp && (
          <TimestampTypography
            variant="caption"
            color="text.secondary"
            align="right"
          >
            Last updated: {new Date(timestamp).toLocaleString()}
          </TimestampTypography>
        )}
      </CarouselBoxContainer>
    </HighlightsCarouselContainer>
  );
};

// Default export with type information for the props
export default DSHighlightsCarousel;
