import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

const HEADER_HEIGHT = 80;
const HEADER_OFFSET = HEADER_HEIGHT + 98;

export const RootContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(3),
  height: `calc(100vh - ${HEADER_OFFSET}px)`,
  minHeight: `calc(100vh - ${HEADER_OFFSET}px)`,
  maxHeight: `calc(100vh - ${HEADER_OFFSET}px)`,
  overflow: 'visible !important',
  marginTop: '-2rem',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    overflow: 'auto',
    gap: theme.spacing(2),
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 'none',
  },
}));

export const PrimaryContentContainer = styled(Box)(({ theme }) => ({
  flex: '3 1 75%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '.25rem',
  height: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  width: '100%',
  overflow: 'visible !important',
  '& > *': {
    // Target all direct children to ensure they take only the space they need
    height: 'auto',
    flex: '0 0 auto',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible !important',
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 'none',
    overflow: 'visible',
  },
}));

export const SecondaryContentContainer = styled(Box)(({ theme }) => ({
  flex: '1 1 25%',
  minWidth: '150px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '.25rem',
  height: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  width: '100%',
  overflow: 'visible !important',
  '& > *': {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible !important',
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    height: 'auto',
    maxHeight: '300px',
    minHeight: 'auto',

    overflow: 'visible',
    marginBottom: theme.spacing(2),
  },
}));

export const PrimaryContentModule = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: DEALROOMS_COLORS.white,
  borderRadius: '.25rem',
  height: 'auto',
  width: '100%',
  overflow: 'visible !important',
  marginBottom: theme.spacing(2), // Add spacing between modules
  '&:last-child': {
    marginBottom: 0, // Remove margin from last child
  },
}));

export const SecondaryContentModule = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: DEALROOMS_COLORS.white,
  borderRadius: '.25rem',
  // flex: 1, // Enable to force full height
  width: '100%',
  maxWidth: '100%',
  overflow: 'visible !important',
  '&:last-child': {
    marginBottom: 0, // Remove margin from last child
  },
}));

// Styled component for the section title to ensure it's full width and left-aligned
export const StyledSectionTitle = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  marginTop: '1rem',
  '&:first-of-type': {
    marginTop: 0,
  },
}));
