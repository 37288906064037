import { useAuth0 } from '@auth0/auth0-react';
import {
  DatePicker,
  FontSizes,
  FontWeights,
  ITextField,
  Stack,
  Text,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Meetingflow } from '@meetingflow/common/Api/data-contracts';
import { toDateOrUndefined } from '@meetingflow/common/DateHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Y } from '@syncedstore/core';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import TimePicker from 'react-time-picker';
import * as yup from 'yup';
import { DEFAULT_STACK_TOKENS } from '../../../Helpers/Layout';
import { hasContent } from '../../../Helpers/yjsHelpers';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { ApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import MeetingPlanNotesSummaryForm from '../../Common/NotesSummaryForm';
import { AsyncDefaultButton, AsyncPrimaryButton } from '../../HOC/AsyncButton';

interface MeetingPlanEditDetailsFormProps {
  meetingPlan: Meetingflow;
  organizationSlug: string;
  notesYArray: Y.XmlText;
  fields?: ('title' | 'date' | 'summary')[];
  submitButtonLabel?: string;
  insertBeforeButtons?: React.ReactNode;
  hideLabels?: boolean;
  initialSummary?: string;
  onSuccess?: (formValues: MeetingPlanEditDetailsFormData) => void;
  onCancel?: () => void;
}

export type MeetingPlanEditDetailsFormData = {
  title: string;
  date: Date;
  startTime: Date;
  endTime: Date;
  summary: string;
};

const formSchema = yup
  .object({
    title: yup.string().min(1, 'The title cannot be empty').required(),
    date: yup.date().required(),
    startTime: yup.date().required(),
    endTime: yup.date().required(),
    summary: yup.string(),
  })
  .required();

const mergeDateAndTime = (date: Date, time: Date): Date =>
  DateTime.fromObject({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: time.getHours(),
    minute: time.getMinutes(),
    second: time.getSeconds(),
  }).toJSDate();

export const MeetingPlanEditDetailsForm = ({
  meetingPlan,
  organizationSlug,
  onSuccess,
  onCancel,
  notesYArray,
  fields = ['title', 'date', 'summary'],
  submitButtonLabel = 'Update',
  insertBeforeButtons,
  hideLabels = false,
  initialSummary,
}: MeetingPlanEditDetailsFormProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const appInsights = useAppInsightsContext();
  const { isDark } = useLightOrDarkMode();

  const editTitleRef = useRef<ITextField>(null);

  const formDefaultValues = useMemo(
    () => {
      const date = toDateOrUndefined(meetingPlan.startTime);
      const startTime = toDateOrUndefined(meetingPlan.startTime);
      const endTime = toDateOrUndefined(meetingPlan.endTime);
      
      return {
        title: meetingPlan.title,
        date: date || new Date(),
        startTime: startTime || new Date(),
        endTime: endTime || new Date(),
        summary: initialSummary || (meetingPlan.textSummary as string) || '',
      };
    },
    [
      initialSummary,
      meetingPlan.title,
      meetingPlan.startTime,
      meetingPlan.endTime,
      meetingPlan.textSummary,
    ],
  );

  const { getValues, handleSubmit, control, formState, reset, setValue } =
    useForm<MeetingPlanEditDetailsFormData>({
      defaultValues: formDefaultValues,
      resolver: yupResolver(formSchema, { abortEarly: false }),
      reValidateMode: 'onChange',
      mode: 'onChange',
    });

  const onSubmit = handleSubmit(async (data) => {
    if (formState.isValid) {
      const startTime = mergeDateAndTime(data.date, data.startTime);
      let endTime = mergeDateAndTime(data.date, data.endTime);

      if (startTime >= endTime) {
        endTime = DateTime.fromJSDate(endTime).plus({ day: 1 }).toJSDate();
      }

      const token = await getAccessTokenSilently();
      const result = await ApiClient.patch(
        `/organization/${organizationSlug}/plan/${meetingPlan.id}`,
        {
          organizationSlug,
          title: fields.includes('date') ? data.title : undefined,
          startTime: fields.includes('date') ? startTime : undefined,
          endTime: fields.includes('date') ? endTime : undefined,
          textSummary: fields.includes('summary') ? data.summary : undefined,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (result.status === 200) {
        toast.success(`Meetingflow details updated.`);
        appInsights.trackEvent({
          name: 'UPDATE_MEETINGPLAN_DETAILS',
          properties: {
            organizationSlug,
            title: data.title,
            startTime: startTime,
            endTime: endTime,
            summary: data.summary,
          },
        });
        onSuccess && onSuccess(data);
        reset();
      } else {
        toast.error('There was a problem updating the Meetingflow details.');
      }
    } else {
      toast.error(
        `Meetingflow details could not be updated because the form values are invalid. Please check them and try again.`,
      );
    }
  });

  useEffect(() => {
    editTitleRef.current?.focus();
  }, []);

  useEffect(() => {
    if (initialSummary) {
      setValue('summary', initialSummary);
    } else if (meetingPlan?.textSummary) {
      setValue('summary', meetingPlan?.textSummary);
    }
  }, [initialSummary, meetingPlan.textSummary, setValue]);

  const reactTimePickerWrapperClass = mergeStyles({
    '.react-time-picker-label': {
      display: 'block',
      padding: '5px 0',
      fontWeight: FontWeights.semibold,
    },
    '.react-time-picker__inputGroup': {},
    '.react-time-picker__wrapper': {
      height: '32px',
      border: '1px solid rgb(200, 198, 196)',
      transition: '.3s ease-in-out all',
      borderRadius: '2px',
      ':hover': {
        border: `1px solid ${MEETINGFLOW_COLORS.purplePrimary}`,
      },
    },
    'input, select': {
      color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
      border: `1px solid transparent`,
      position: 'relative',
      top: '-1px',
    },
    'input:focus, select:focus, input:focus-visible, select::focus-visible ': {
      outlineColor: `${MEETINGFLOW_COLORS.purplePrimary} `,
      outline: 'none !important',
      border: `1px solid ${MEETINGFLOW_COLORS.purplePrimary}`,
      borderRadius: '2px',
    },
    '.react-time-picker__inputGroup__divider': {
      position: 'relative',
      top: '-2px',
    },
    '.react-time-picker__inputGroup__leadingZero': {
      position: 'relative',
      top: '-1px',
    },
  });

  return (
    <>
      <Stack tokens={DEFAULT_STACK_TOKENS}>
        <Stack tokens={DEFAULT_STACK_TOKENS} style={{ marginBottom: '1rem' }}>
          {fields.includes('title') ? (
            <Stack.Item grow={1}>
              <Controller
                name="title"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    componentRef={editTitleRef}
                    errorMessage={formState.errors.title?.message}
                    autoFocus
                    label={hideLabels ? undefined : 'Title'}
                    value={value}
                    onBlur={onBlur}
                    onChange={(_e, newValue) => onChange(newValue || '')}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                  />
                )}
              />
            </Stack.Item>
          ) : undefined}

          {fields.includes('date') ? (
            <Stack.Item>
              <Stack horizontal tokens={DEFAULT_STACK_TOKENS}>
                <Stack.Item grow={1}>
                  <Controller
                    name="date"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        placeholder="Select date..."
                        ariaLabel="Select date"
                        label={hideLabels ? undefined : 'Date'}
                        value={value instanceof Date ? value : undefined}
                        onSelectDate={(date: Date | null | undefined) => {
                          if (date) {
                            onChange(date);
                          }
                        }}
                      />
                    )}
                  />
                </Stack.Item>
                <>
                  <Stack.Item
                    grow={1}
                    styles={{ root: { maxWidth: '6.65rem' } }}
                  >
                    <Controller
                      name="startTime"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <div className={reactTimePickerWrapperClass}>
                            <Text className={'react-time-picker-label'}>
                              Start Time
                            </Text>
                            <TimePicker
                              disableClock={true}
                              value={value}
                              clearIcon={null}
                              onChange={(value) => {
                                const date = getValues('date');
                                if (!date || !value) {
                                  return;
                                }
                                const merged =
                                  typeof value === 'string'
                                    ? mergeDateAndTime(
                                        date,
                                        DateTime.fromISO(value).toJSDate(),
                                      )
                                    : mergeDateAndTime(
                                        date,
                                        value,
                                      );
                                onChange(merged);
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item
                    grow={1}
                    styles={{ root: { maxWidth: '6.65rem' } }}
                  >
                    <Controller
                      name="endTime"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <div className={reactTimePickerWrapperClass}>
                            <Text className={'react-time-picker-label'}>
                              End Time
                            </Text>
                            <TimePicker
                              disableClock={true}
                              value={formDefaultValues.endTime}
                              clearIcon={null}
                              onChange={(value) => {
                                const date = getValues('date');
                                if (!date || !value) {
                                  return;
                                }
                                const merged =
                                  typeof value === 'string'
                                    ? mergeDateAndTime(
                                        date,
                                        DateTime.fromISO(value).toJSDate(),
                                      )
                                    : mergeDateAndTime(
                                        date,
                                        value,
                                      );
                                onChange(merged);
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                  </Stack.Item>
                </>
              </Stack>
            </Stack.Item>
          ) : null}

          {fields.includes('summary') ? (
            <Stack.Item>
              <MeetingPlanNotesSummaryForm
                summaryType="MEETING_SUMMARY"
                summarizeDisabled={
                  !hasContent(notesYArray) &&
                  !meetingPlan?.callRecording?.transcript?.length
                }
                meetingplan={meetingPlan}
                organizationSlug={organizationSlug}
                displaySubject={false}
                onSummaryChange={(value) => setValue('summary', value)}
                onSummaryGenerated={(value) => setValue('summary', value)}
                surfaceName={'MEETINGPLAN_SUMMARY'}
                bodyLabel={'Summary'}
                initialSummaryValue={meetingPlan.textSummary || ''}
                hideLabels={hideLabels}
              />
            </Stack.Item>
          ) : null}
        </Stack>
        {insertBeforeButtons ? insertBeforeButtons : null}
        <Stack horizontal horizontalAlign={'end'} tokens={DEFAULT_STACK_TOKENS}>
          {onCancel ? (
            <Stack.Item>
              <AsyncDefaultButton
                text="Cancel"
                // @ts-ignore
                onClick={onCancel}
                styles={{
                  root: {
                    height: '24px !important',
                    border: 'none !important',
                    position: 'relative',
                    top: insertBeforeButtons ? undefined : '-.75rem',
                  },
                  label: {
                    fontSize: FontSizes.small,
                  },
                }}
              />
            </Stack.Item>
          ) : null}
          <Stack.Item>
            <AsyncPrimaryButton
              disabled={!formState.isValid}
              text={submitButtonLabel}
              onClick={onSubmit}
              type="submit"
              styles={{
                root: {
                  height: '24px !important',
                  backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
                  border: 'none !important',
                  position: 'relative',
                  top: insertBeforeButtons ? undefined : '-.75rem',
                },
                label: {
                  fontSize: FontSizes.small,
                },
              }}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </>
  );
};
