import {
  styled,
  Box,
  TextField,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { DSButton } from '../../DS';
import ReactMarkdown from 'react-markdown';

export const KeyFactsGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
  gridAutoFlow: 'dense',
  gridAutoRows: 'auto',
  gap: '1rem',
  width: '100%',
  '@container (max-width: 600px)': {
    gridTemplateColumns: 'none',
  },
}));

export const KeyFactCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '4px',
  border: `1px solid ${DEALROOMS_COLORS.financialLightGray}`,
  boxSizing: 'border-box',
  padding: '1rem',
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '&:hover .edit-container': {
    opacity: 1,
  },
  '&[data-draggable="true"]': {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
}));

export const KeyFactHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '1rem',
  marginBottom: '0',
  width: 'calc(100% - 3rem)',
}));

export const KeyFactTitle = styled(Typography)(({ theme }) => ({
  color: DEALROOMS_COLORS.themePrimary,
  lineHeight: '1rem',
  marginRight: '2rem',
}));

interface EditButtonProps {
  isActive: boolean;
}

export const EditButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<EditButtonProps>(({ theme, isActive }) => ({
  padding: '2px',
  opacity: isActive ? 1 : 0.6,
  color: isActive
    ? DEALROOMS_COLORS.themePrimary
    : DEALROOMS_COLORS.themeSecondary,
  backgroundColor: isActive ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
  '&:hover': {
    opacity: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  width: '24px',
  height: '24px',
  position: 'relative',
}));

export const EditContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out',
  gap: '0.25rem',
  zIndex: 1,
}));

export const DeleteButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<EditButtonProps>(({ theme, isActive }) => ({
  padding: '2px',
  opacity: isActive ? 1 : 0.6,
  color: '#d32f2f',
  backgroundColor: isActive ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
  '&:hover': {
    opacity: 1,
    backgroundColor: 'rgba(211, 47, 47, 0.04)',
  },
  width: '24px',
  height: '24px',
}));

// Add styled MUI TextField components for edit mode
export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.75rem',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
  },
}));

export const StyledMultilineTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.75rem',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
  },
  '& .MuiInputBase-inputMultiline': {
    lineHeight: '1.25rem',
  },
}));

export const StyledTextArea = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginTop: '0.5rem',
  '& .MuiInputBase-input': {
    padding: '0.5rem',
  },
}));

export const StyledMuiTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
    height: '40px', // Match the height of CalloutCards fields
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.875rem', // Increase label font size to match CalloutCards
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
  },
  margin: '4px 0', // Reduce vertical spacing
}));

export const StyledMuiMultilineTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
    minHeight: '80px', // Match the height of CalloutCards multiline fields
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.875rem', // Increase label font size to match CalloutCards
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
  },
  '& .MuiInputBase-inputMultiline': {
    lineHeight: '1.25rem',
  },
  margin: '4px 0', // Reduce vertical spacing
  width: '100%',
}));

export const SortableFactsGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
  gridAutoFlow: 'row',
  gridAutoRows: '1fr',
  gap: '1rem',
  width: '100%',
  '@container (max-width: 600px)': {
    gridTemplateColumns: 'none',
  },
}));

export const SaveButton = styled(Box)(({ theme }) => ({
  minWidth: 'unset',
  padding: '.25rem',
}));

interface KeyFactValueProps {
  isLongText: boolean;
}

export const KeyFactValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isLongText',
})<KeyFactValueProps>(({ isLongText, theme }) => ({
  fontSize: isLongText ? '.875rem' : '1.5rem',
  lineHeight: isLongText ? '1.25rem' : '1rem',
  paddingTop: isLongText ? '0' : '.5rem',
  color: DEALROOMS_COLORS.themePrimary,
}));

export const KeyFactMarkdown = styled(ReactMarkdown)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${DEALROOMS_COLORS.themePrimary};
  width: 100%;
  margin: 0;
  padding: 0;

  p {
    margin: 0;
    padding: 0;
  }

  a {
    color: ${DEALROOMS_COLORS.themePrimary};
    text-decoration: underline;
  }

  ul,
  ol {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
  }

  code {
    background-color: rgba(0, 0, 0, 0.04);
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-family: monospace;
  }

  blockquote {
    margin: 0.5rem 0;
    padding-left: 1rem;
    border-left: 3px solid ${DEALROOMS_COLORS.financialLightGray};
    color: ${DEALROOMS_COLORS.themeSecondary};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0.5rem 0 0.25rem 0;
    font-weight: 500;
  }

  h1 {
    font-size: 1.25rem;
  }
  h2 {
    font-size: 1.1rem;
  }
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0.5rem 0;
  }

  th,
  td {
    border: 1px solid ${DEALROOMS_COLORS.financialLightGray};
    padding: 0.25rem 0.5rem;
    text-align: left;
  }

  th {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

interface StyledInputProps {
  isEditing: boolean;
}

export const StyledInput = styled('input', {
  shouldForwardProp: (prop) => prop !== 'isEditing',
})<StyledInputProps>(({ theme, isEditing }) => ({
  border: 'none',
  outline: 'none',
  background: isEditing ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
  fontSize: '0.875rem',
  fontWeight: 500,
  color: DEALROOMS_COLORS.themePrimary,
  padding: isEditing ? '0 8px' : '0',
  margin: '0',
  width: '100%',
  position: 'relative',
  left: '-.5rem',
  fontFamily: 'inherit',
  borderRadius: '4px',
}));

interface StyledTextAreaProps {
  isLongText: boolean;
  isEditing: boolean;
}

export const TextAreaField = styled('textarea', {
  shouldForwardProp: (prop) => prop !== 'isLongText' && prop !== 'isEditing',
})<StyledTextAreaProps>(({ theme, isLongText, isEditing }) => ({
  border: 'none',
  outline: 'none',
  background: isEditing ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
  fontSize: isLongText ? '.875rem' : '1.5rem',
  lineHeight: isLongText ? '1.25rem' : '1rem',
  color: DEALROOMS_COLORS.themePrimary,
  padding: isEditing ? '0 8px' : '0',
  margin: '0',
  position: 'relative',
  left: '-.5rem',
  width: '100%',
  fontFamily: 'inherit',
  resize: 'none',
  overflow: 'auto',
  minHeight: '2.5rem',
  height: 'auto',
  borderRadius: '4px',
}));

export const BuyerEditToggle = styled(Box)(({ theme }) => ({
  marginTop: '1rem',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));

export const ToggleLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  color: DEALROOMS_COLORS.themeSecondary,
}));

export const ExampleKeyFactCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '0',
  borderRadius: '.25rem',
  padding: '1rem',
  backgroundColor: DEALROOMS_COLORS.white,
  border: `2px dashed ${DEALROOMS_COLORS.themeSecondary}`,
  position: 'relative',
  height: 'calc(100% - 2rem - 4px)', // Adjusted for thicker border
  opacity: 0.4,
  transition: 'opacity 0.3s ease-out',
}));

export const AddKeyFactButton = styled(DSButton)({
  display: 'inline-flex',
  minWidth: 'unset',
  marginLeft: '8px',
  padding: '0',
  position: 'relative',
  top: '-1px',
  color: `${DEALROOMS_COLORS.cloudburst}99`, // 60% opacity (99 in hex is ~60%)
  transition: 'all 0.3s ease-in-out',
  '& .MuiButton-startIcon': {
    marginRight: 0,
  },
  '& .MuiButton-startIcon > *:nth-of-type(1)': {
    fontSize: 16,
  },
  '& .MuiButton-endIcon': {
    display: 'none',
  },

  '&:hover': {
    color: `${DEALROOMS_COLORS.cloudburst}`,
  },
});
