import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

/**
 * Container for the action buttons section
 */
export const ActionButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexGrow: 0.5,
  gap: theme.spacing(0.5),
}));

/**
 * Wrapper for animated icons that appear on hover
 */
export const AnimatedIconWrapper = styled('div')(() => ({
  opacity: 0,
  transform: 'translateY(4px)',
  transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
  '@container (max-width: 500px)': {
    opacity: 1,
    transform: 'translateY(0)',
  },
  '&.visible': {
    opacity: 1,
    transform: 'translateY(0)',
  },
}));

/**
 * Container for attendees tooltip content
 */
export const AttendeesTooltipContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
}));

/**
 * Container for attendee list items
 */
export const AttendeesList = styled(Box)(({ theme }) => ({
  margin: 0,
  padding: 0,
  listStyle: 'none',
}));

/**
 * Container for attendee list item
 */
export const AttendeeItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));

/**
 * Container for attendee count display
 */
export const AttendeeCountContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  marginLeft: theme.spacing(1),
}));
