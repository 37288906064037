import React, { useCallback, useRef, useState } from 'react';
import {
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  MenuList,
  ClickAwayListener,
  Typography,
} from '@mui/material';
import { AddTask, ArrowDropDown } from '@mui/icons-material';
import { DSMenuItem } from '../DS';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

interface MilestoneOption {
  key: number;
  text: string;
}

interface MilestoneActionItemSelectMilestoneButtonProps {
  milestoneOptions?: MilestoneOption[];
  selectedMilestoneId: number | null;
  onMilestoneSelected: (milestoneId: number) => void;
  onAddToMilestone: () => void | Promise<void>;
}

export const MilestoneActionItemSelectMilestoneButton: React.FC<
  MilestoneActionItemSelectMilestoneButtonProps
> = ({
  milestoneOptions,
  selectedMilestoneId,
  onMilestoneSelected,
  onAddToMilestone,
}) => {
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleAddToMilestone = useCallback(async () => {
    setIsLoading(true);
    try {
      await onAddToMilestone();
    } finally {
      setIsLoading(false);
    }
  }, [onAddToMilestone]);

  const handleAddButtonClick = useCallback(() => {
    if (selectedMilestoneId !== null) {
      handleAddToMilestone();
    } else {
      setSplitButtonOpen(true);
    }
  }, [selectedMilestoneId, handleAddToMilestone]);

  return (
    <div
      style={{
        position: 'relative',
        top: '.25rem',
      }}
    >
      <ButtonGroup
        variant="outlined"
        sx={{
          height: '24px',
          transition: '.3s ease-in-out all',
          backgroundColor: selectedMilestoneId
            ? DEALROOMS_COLORS.woodsmoke
            : DEALROOMS_COLORS.cloudburst,
          width: 'fit-content',
          '& .MuiButton-root:last-child': {
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            backgroundColor: `${DEALROOMS_COLORS.cloudburst} !important`,
            border: 'none',
            borderLeft: selectedMilestoneId
              ? `1px solid ${DEALROOMS_COLORS.white}`
              : `1px solid ${DEALROOMS_COLORS.white}`,
          },
        }}
      >
        <Button
          onClick={handleAddButtonClick}
          disabled={isLoading}
          startIcon={selectedMilestoneId ? <AddTask /> : undefined}
          sx={{
            backgroundColor: selectedMilestoneId
              ? `${DEALROOMS_COLORS.successDarkGreen} !important`
              : `${DEALROOMS_COLORS.cloudburst} !important`,
            color: `${DEALROOMS_COLORS.white} !important`,
            fontSize: '12px',
            lineHeight: 1,
            border: 'none',
            padding: '.25rem .5rem',
            minWidth: {
              xs: '6.25rem',
              sm: '7.5rem',
            },
            maxWidth: {
              xs: '10rem',
              sm: '13rem',
            },
            '& .MuiButton-startIcon': {
              marginRight: '.25rem',
              flexShrink: 0,
            },
            '& .MuiSvgIcon-root': {
              color: `${DEALROOMS_COLORS.white} !important`,
              fontSize: '1rem',
            },
            '&:hover': {
              backgroundColor: selectedMilestoneId
                ? `${DEALROOMS_COLORS.successLightGreen} !important`
                : undefined,
            },
          }}
        >
          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            {selectedMilestoneId !== null
              ? `Add to ${
                  milestoneOptions?.find(
                    (opt) => opt.key === selectedMilestoneId,
                  )?.text || 'Milestone'
                }`
              : 'Select Milestone'}
          </Typography>
        </Button>
        <Button
          onClick={() => setSplitButtonOpen((prev) => !prev)}
          ref={anchorRef}
          aria-label="select milestone"
          sx={{
            padding: '0 4px !important',
            minWidth: '24px',
            backgroundColor: selectedMilestoneId
              ? `${DEALROOMS_COLORS.woodsmoke} !important`
              : `${DEALROOMS_COLORS.cloudburst} !important`,
            color: `${DEALROOMS_COLORS.white} !important`,
            '& .MuiSvgIcon-root': {
              color: `${DEALROOMS_COLORS.white} !important`,
              fontSize: '16px',
            },
            '&:hover': {
              backgroundColor: selectedMilestoneId
                ? `${DEALROOMS_COLORS.woodsmoke} !important`
                : undefined,
            },
          }}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1300 }}
        className="MuiMilestonePopper-root"
        open={splitButtonOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={() => setSplitButtonOpen(false)}>
                <MenuList
                  autoFocusItem
                  sx={{
                    maxWidth: {
                      xs: '15.625rem',
                      sm: '18.75rem',
                    },
                    maxHeight: {
                      xs: '18.75rem',
                      sm: '25rem',
                    },
                    overflowY: 'auto',
                    '& .MuiMenuItem-root': {
                      fontSize: {
                        xs: '0.875rem',
                        sm: '1rem',
                      },
                      padding: {
                        xs: '0.375rem 0.75rem',
                        sm: '0.5rem 1rem',
                      },
                    },
                  }}
                >
                  {milestoneOptions?.map((option) => (
                    <DSMenuItem
                      key={option.key}
                      selected={Number(option.key) === selectedMilestoneId}
                      onClick={() => {
                        onMilestoneSelected(Number(option.key));
                        setSplitButtonOpen(false);
                      }}
                      primaryText={option.text}
                      sx={{
                        '& .primaryText': {
                          wordBreak: 'break-all',
                        },
                      }}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
