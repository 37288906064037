import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';
import { getYouTubeVideoId } from '../utils/youtubeUtils';
import { DSYouTubeEmbed } from '../DS/DSYouTubeEmbed';
import { getLoomVideoId } from '../utils/loomUtils';
import { getVimeoVideoId } from '../utils/vimeoUtils';
import { DSLoomEmbed, DSVimeoEmbed } from '../DS';

export type LinkArtifactCardProps = Omit<BaseArtifactCardProps, 'artifact'> & {
  artifact: DealRoomArtifact;
};

export const LinkArtifactCard = ({
  artifact,
  ...rest
}: LinkArtifactCardProps) => {
  // Safe access to properties that might not exist on all artifact types
  const url = artifact && 'url' in artifact ? artifact.url : '';
  const thumbnailUrl = artifact && 'thumbnailUrl' in artifact ? artifact.thumbnailUrl : undefined;

  // For Artifacts with type LINK, we are special-casing YouTube videos based on their URL
  // so that we can embed them in the card in a useful way using YouTube's embed API
  const youtubeVideoId = url ? getYouTubeVideoId(url) : null;
  const isYouTubeVideo = !!youtubeVideoId;

  const loomVideoId = url ? getLoomVideoId(url) : null;
  const isLoomVideo = !!loomVideoId;

  const vimeoVideoId = url ? getVimeoVideoId(url) : null;
  const isVimeoVideo = !!vimeoVideoId;

  return (
    <BaseArtifactCard
      className="link-artifact"
      artifact={artifact}
      {...rest}
    >
      {isYouTubeVideo ? (
        <DSYouTubeEmbed videoId={youtubeVideoId} title={artifact?.label || artifact?.name || ''} />
      ) : isLoomVideo ? (
        <DSLoomEmbed videoId={loomVideoId} title={artifact?.label || artifact?.name || ''} />
      ) : isVimeoVideo ? (
        <DSVimeoEmbed videoId={vimeoVideoId} title={artifact?.label || artifact?.name || ''} />
      ) : (
        <ImageWithFallback
          src={
            thumbnailUrl ||
            `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=50`
          }
          fallbackSrc={
            thumbnailUrl
              ? `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=50`
              : undefined
          }
          alt={artifact?.label || artifact?.name || ''}
          fallback={<ArtifactIconPreview type="LINK" />}
          objectFit="contain"
        />
      )}
    </BaseArtifactCard>
  );
};
