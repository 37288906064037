import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  keyframes,
  List,
  ListItem,
  ListSubheader,
  Stack,
  styled,
  Typography,
} from '@mui/material/';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const EventCardContainer = styled('div')(({ theme }) => ({
  containerType: 'inline-size',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(1),
  minHeight: '24px',
  transition: '.3s ease-in-out all',
  position: 'relative',

  '@container (max-width: 500px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },

  '.event-details': {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    minWidth: 0, // Prevents flex items from overflowing
    flexGrow: 1,

    '.event-title': {
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
      fontSize: '.875rem',
      lineHeight: '1.25rem',
      fontWeight: '500',
      color: theme.palette.text.primary,
    },

    '.event-datetime-row': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginTop: '.25rem',
    },

    '.event-datetime': {
      display: 'block',
      fontSize: '.75rem',
      lineHeight: '1rem',
      fontWeight: 'regular',
      color: theme.palette.text.secondary,
    },

    '.short-time': {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: 'regular',
      color: theme.palette.text.secondary,
      marginRight: '.5rem',
      marginLeft: '.5rem',
    },
  },

  '.event-actions-column': {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: theme.spacing(5),
  },
}));

export const EventCardStack = styled(Stack)(({ onClick }) => ({
  cursor: onClick ? 'pointer' : 'default',
}));

export const AttendeesList = styled(List)(({ theme }) => ({
  py: 0,
}));

export const AttendeesListSubheader = styled(ListSubheader)(({ theme }) => ({
  py: 0,
  lineHeight: 1.2,
}));

export const AttendeesListItem = styled(ListItem)(({ theme }) => ({
  py: 0.25,
  minHeight: 0,
}));

export const AttendeesTypography = styled(Typography)(({ theme }) => ({
  lineHeight: 1.2,
  paddingLeft: '1rem',
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  position: 'relative',
}));

export const AttendeesAvatar = styled(Avatar)(({ theme }) => ({
  width: 24,
  height: 24,
  fontSize: '0.875rem',
}));

export const AttendeesBadge = styled(Badge)(({ theme }) => ({
  marginLeft: '.5rem',
  '& .MuiBadge-badge': {
    fontSize: '0.65rem',
    height: '16px',
    lineHeight: '16px',
    minWidth: '16px',
    padding: '0 4px',
    textAlign: 'center',
    backgroundColor: DEALROOMS_COLORS.white,
    color: DEALROOMS_COLORS.cloudburst,
  },
}));

export const StyledAvatarGroup = styled(AvatarGroup)({
  '& .MuiAvatar-root': {
    width: '16px',
    height: '16px',
    fontSize: '10px',
    border: '1px solid white',
    marginRight: '4px',
  },
});

export const MeetingflowIconContainer = styled(Box)(({ theme }) => ({
  minWidth: '26px',
  height: '26px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
}));

export const MeetingflowIcon = styled(Box)(({ theme }) => ({
  color: DEALROOMS_COLORS.cloudburst,
  cursor: 'pointer',
  height: '16px',
  width: '16px',
  borderRadius: '50%',
  '& svg': {
    color: 'inherit',
    height: '16px',
    width: '16px',
  },
}));

export const ActionButton = styled('button')(
  ({
    color = DEALROOMS_COLORS.white,
    hoverBackgroundColor = DEALROOMS_COLORS.themePrimary,
    backgroundColor,
  }: {
    color: string;
    hoverBackgroundColor: string;
    backgroundColor: string;
  }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    padding: '8px 12px',
    width: '195px',
    minWidth: '195px',
    border: 'none',
    borderRadius: '6px',
    backgroundColor: backgroundColor,
    color: color,
    cursor: 'pointer',
    fontSize: '0.875rem',
    fontWeight: 500,
    transition: 'all 0.3s ease-in-out',
    '@container (max-width: 500px)': {
      padding: '4px 8px',
      width: 'auto',
      minWidth: 'auto',
    },
    '&:hover': {
      backgroundColor: hoverBackgroundColor,
    },
  }),
);
