import { Icon } from '@fluentui/react';
import { SortField, SortDirection } from './DealRoomArtifacts';
import { DateTime } from 'luxon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import {
  DealRoomArtifact,
  DealRoomMeetingflowArtifact,
  DetailedDealRoom,
} from '@meetingflow/common/Api/data-contracts';
import { getMUIIconForArtifactType } from '../../../Helpers/IconHelpers';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { ArtifactsTableActions } from './ArtifactsTableActions';
import { useCallback, useMemo, useState } from 'react';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import useDeviceType from '../../../Hooks/deviceDetection';
import {
  MainContainerStyles,
  MainContainerWrapperStyles,
  detailsHeader,
  artifactName,
  deletedArtifact,
} from './DealRoomArtifactsTable.style';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import { useHandleOnFeature } from './useArtifactsOnFeature';

export type DealRoomArtifactsTableProps = {
  onClick?: (id: DealRoomArtifact['id']) => void;
  onDelete?: (id: DealRoomArtifact['id']) => Promise<unknown>;
  organizationSlug: string;
  dealRoomId: number;
  refreshDealRoom: () => Promise<unknown>;
  artifacts: DealRoomArtifact[];
  refetchArtifacts: () => Promise<unknown>;
  sortField: SortField;
  sortDirection: SortDirection;
  onSortChange: (field: SortField, direction: SortDirection) => void;
};

// Helper functions for working with artifacts
const isFeatured = (artifact: DealRoomArtifact): boolean =>
  !!artifact.featuredAt;
const getArtifactName = (artifact: DealRoomArtifact): string =>
  artifact.label || artifact.name || '';
const getArtifactDescription = (artifact: DealRoomArtifact): string =>
  artifact.description || '';
const getOwnerName = (artifact: DealRoomArtifact): string =>
  artifact.creator?.name || '';
const getFormattedSharedDate = (artifact: DealRoomArtifact): string =>
  DateTime.fromISO(artifact.createdAt).toFormat('M/d/yy');
const getMimeType = (artifact: DealRoomArtifact): string | undefined | null =>
  'mimeType' in artifact ? artifact.mimeType : undefined;
const getVoteScore = (artifact: DealRoomArtifact): number =>
  (artifact.upvoteCount || 0) - (artifact.downvoteCount || 0);

export const DealRoomArtifactsTable = ({
  onClick,
  organizationSlug,
  dealRoomId,
  refreshDealRoom,
  onDelete,
  artifacts,
  refetchArtifacts,
  sortField,
  sortDirection,
  onSortChange,
}: DealRoomArtifactsTableProps) => {
  const { isMobile } = useDeviceType();
  const breakpoints = useBreakpoints();

  const { userId } = useUserProfile();

  const { dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);

  // Map parent sort field to artifact property
  const getArtifactSortProperty = (field: SortField): string => {
    switch (field) {
      case 'name':
        return 'name';
      case 'owner':
        return 'creator';
      case 'lastModified':
        return 'updatedAt';
      default:
        return 'updatedAt';
    }
  };

  const orderBy = getArtifactSortProperty(sortField);
  const order = sortDirection;

  const mainContainerStyles = MainContainerStyles(isMobile, breakpoints.xl);
  const mainContainerWrapperStyles = MainContainerWrapperStyles();

  const { handleOnFeature } = useHandleOnFeature({
    organizationSlug,
    dealRoomId,
    refetchArtifacts,
  });

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    const fieldToSort = (() => {
      switch (property) {
        case 'name':
          return 'name';
        case 'creator':
          return 'owner';
        case 'updatedAt':
          return 'lastModified';
        default:
          return 'lastModified';
      }
    })() as SortField;

    onSortChange(fieldToSort, isAsc ? 'desc' : 'asc');
  };

  const renderArtifactIcon = useCallback((artifact: DealRoomArtifact) => {
    const IconComponent = getMUIIconForArtifactType(
      artifact.type,
      getMimeType(artifact),
    );
    return (
      <IconComponent
        sx={{ color: DEALROOMS_COLORS.cloudburst, fontSize: '1.25rem' }}
      />
    );
  }, []);

  const renderLastModified = (date: string) =>
    humanizeDateTime(date, {
      displayComponents: ['date'],
      dateFormatOptions: {
        dateStyle: 'medium',
      },
      useRelativeDates: true,
    });

  // Sort artifacts based on current sort settings
  const sortedArtifacts = useMemo(() => {
    const compareArtifacts = (a: DealRoomArtifact, b: DealRoomArtifact) => {
      let aValue: string | number | Date | null | undefined;
      let bValue: string | number | Date | null | undefined;

      // Special handling for different columns
      switch (orderBy) {
        case 'name':
          aValue = getArtifactName(a).toLowerCase();
          bValue = getArtifactName(b).toLowerCase();
          break;
        case 'description':
          aValue = getArtifactDescription(a).toLowerCase();
          bValue = getArtifactDescription(b).toLowerCase();
          break;
        case 'creator':
          aValue = getOwnerName(a).toLowerCase();
          bValue = getOwnerName(b).toLowerCase();
          break;
        case 'updatedAt':
          aValue = new Date(a.updatedAt).getTime();
          bValue = new Date(b.updatedAt).getTime();
          break;
        case 'upvotes':
          aValue = getVoteScore(a);
          bValue = getVoteScore(b);
          break;
        case 'featured':
          aValue = isFeatured(a) ? 1 : 0;
          bValue = isFeatured(b) ? 1 : 0;
          break;
        default:
          // For any other properties, access them directly
          aValue = (
            a as unknown as Record<string, string | number | null | undefined>
          )[orderBy];
          bValue = (
            b as unknown as Record<string, string | number | null | undefined>
          )[orderBy];
      }

      // Handle null/undefined values
      if (aValue === null || aValue === undefined) aValue = '';
      if (bValue === null || bValue === undefined) bValue = '';

      // Compare the values
      if (bValue < aValue) {
        return order === 'desc' ? -1 : 1;
      }
      if (bValue > aValue) {
        return order === 'desc' ? 1 : -1;
      }
      return 0;
    };

    // Use a single sort operation with a combined comparator function
    return [...artifacts].sort((a, b) => {
      // First check featured status - featured items always come first
      if (isFeatured(a) && !isFeatured(b)) return -1;
      if (!isFeatured(a) && isFeatured(b)) return 1;

      // If featured status is the same, then apply the user's sort preference
      return compareArtifacts(a, b);
    });
  }, [artifacts, order, orderBy]);

  return (
    <div className={mainContainerWrapperStyles}>
      <div className={mainContainerStyles}>
        <TableContainer
          sx={{
            width: '100%',
            overflowX: 'auto',
            boxShadow: 'none',
          }}
        >
          <Table
            aria-label="artifacts table"
            size="small"
            sx={{ tableLayout: 'auto' }}
          >
            <TableHead>
              <TableRow>
                <TableCell className="featured_artifact"></TableCell>
                <TableCell
                  className={detailsHeader}
                  sx={{
                    width: 'auto',
                    minWidth: !breakpoints.sm ? 'none' : '150px',
                  }}
                >
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>

                {breakpoints.md && (
                  <>
                    {breakpoints.lg && (
                      <TableCell
                        className={detailsHeader}
                        sx={{
                          width: '100%',
                          minWidth: '200px',
                        }}
                      >
                        <Box component="span" sx={{ display: 'block' }}>
                          Description
                        </Box>
                      </TableCell>
                    )}
                    <TableCell className={detailsHeader} align="left">
                      <TableSortLabel
                        active={orderBy === 'creator'}
                        direction={orderBy === 'creator' ? order : 'asc'}
                        onClick={() => handleRequestSort('creator')}
                      >
                        Owner
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={detailsHeader} align="right">
                      <TableSortLabel
                        active={orderBy === 'updatedAt'}
                        direction={orderBy === 'updatedAt' ? order : 'asc'}
                        onClick={() => handleRequestSort('updatedAt')}
                      >
                        Last Modified
                      </TableSortLabel>
                    </TableCell>
                  </>
                )}
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedArtifacts.map((artifact, index) => (
                <TableRow
                  key={`${artifact.id}_${index}`}
                  sx={
                    {
                      // '&:last-child td, &:last-child th': { border: 0 }
                    }
                  }
                  className="table_row"
                  onClick={(e) => {
                    if (artifact.deletedAt) return;
                    onClick && onClick(artifact.id);
                  }}
                >
                  <TableCell className="featured_artifact">
                    {!artifact.deletedAt && (
                      <Icon
                        iconName={
                          !!artifact.featuredAt
                            ? 'FavoriteStarFill'
                            : 'FavoriteStar'
                        }
                        styles={{
                          root: {
                            color: DEALROOMS_COLORS.themeSecondary,
                            fontSize: '16px',
                            opacity: isFeatured(artifact) ? '1' : '0.4',
                            cursor:
                              dealRoomRole === 'SELLER'
                                ? 'pointer'
                                : 'not-allowed',
                            padding: '5px 10px',
                          },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (dealRoomRole === 'SELLER') {
                            handleOnFeature(!artifact.featuredAt, artifact.id);
                          }
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    className="fit_table_cell"
                    align="left"
                    sx={{
                      width: breakpoints.md ? 'auto' : '100px',
                      minWidth: breakpoints.md ? 'auto' : '100px',
                    }}
                  >
                    <Box className={artifactName}>
                      {renderArtifactIcon(artifact)}
                      <span
                        className={`${artifact.deletedAt ? deletedArtifact : ''}`}
                      >
                        {getArtifactName(artifact)}
                      </span>
                    </Box>
                  </TableCell>

                  {breakpoints.md && (
                    <>
                      {breakpoints.lg && (
                        <TableCell
                          className="fit_table_cell"
                          align="left"
                          sx={{
                            width: '100%',
                            minWidth: '200px',
                          }}
                        >
                          {getArtifactDescription(artifact) || '-'}
                        </TableCell>
                      )}
                      <TableCell
                        className="fit_table_cell"
                        align="left"
                        sx={{
                          width: 'auto',
                          minWidth: '100px',
                          maxWidth: '150px',
                        }}
                      >
                        {getOwnerName(artifact)}
                      </TableCell>
                      <TableCell
                        className="fit_table_cell"
                        align="right"
                        sx={{ width: '150px', minWidth: '120px' }}
                      >
                        {renderLastModified(artifact.updatedAt)}
                      </TableCell>
                    </>
                  )}

                  <TableCell
                    className="fit_table_cell actions_table_cell"
                    align="right"
                    sx={{ width: '32px', minWidth: '32px' }}
                  >
                    <ArtifactsTableActions
                      useForTable={true}
                      userId={userId}
                      dealRoomRole={dealRoomRole}
                      artifact={artifact}
                      organizationSlug={organizationSlug}
                      dealRoomId={dealRoomId}
                      refreshDealRoom={refreshDealRoom}
                      refetchArtifacts={refetchArtifacts}
                      onDelete={onDelete ? onDelete : undefined}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
