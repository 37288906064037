import React from 'react';
import {
  ContactsApiClient,
  MeetingflowsApiClient,
} from '../../../../Services/NetworkCommon';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';
import { HumanticDataType } from './DSContactDetail';
import { Typography, Divider, Box, CircularProgress } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { OrganizationMeetingPlansQuery } from '../../../../QueryNames';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import DecisionSiteMeetingflowList from '../../Components/Journey/DecisionSiteMeetingflowList';
import { useNavigate } from 'react-router';
import { DSStatusMessage } from '../../Components/DSStatusMessage';
import EventNoteIcon from '@mui/icons-material/EventNote';

interface DSContactDetailMeetingsProps {
  contactId: number;
}

export const DSContactDetailMeetings: React.FC<
  DSContactDetailMeetingsProps
> = ({ contactId }) => {
  const { slug: organizationSlug, organization } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();
  const dealRoomId = useDealRoomId();
  const navigate = useNavigate();
  const {
    data: contactData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['contact', organization?.slug, contactId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const response = await ContactsApiClient.getContact(
        organization?.slug ?? '',
        contactId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response;
    },
    enabled: !!organization?.slug,
  });

  const contact = contactData?.data;

  /**
   * Infinite query hook to fetch meeting plans with pagination
   * Includes support for filtering via search params
   */
  const {
    data: meetingPlansPages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [
      ...OrganizationMeetingPlansQuery(
        organizationSlug || '',
        '',
        50,
        0,
        dealRoomId,
      ),
      contactId,
    ],
    async ({ pageParam = 0 }) => {
      const token = await getAccessTokenSilently();
      const apiParams = {
        organizationSlug: organizationSlug!,
        limit: 50,
        skip: pageParam * 50,
        dealRoomId,
        attendees: [contactId],
      };
      const response = await MeetingflowsApiClient.listPlans(apiParams, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    },
    {
      enabled: !!organizationSlug && !!dealRoomId,
      cacheTime: 300000,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      getNextPageParam: (lastPage, pages) => {
        const totalItems = lastPage.data.length;
        const currentPageCount = pages.length;
        return totalItems === 50 ? currentPageCount : undefined;
      },
    },
  );

  if (isLoading) {
    return (
      <DSStatusMessage
        icon={EventNoteIcon}
        title="Loading Meetings..."
        iconSize={48}
      />
    );
  }

  if (error) {
    return (
      <DSStatusMessage
        icon={EventNoteIcon}
        title="Unable to Load Meetings"
        subtitle="There was an error loading the meetings. Please try again later."
        iconColor="error.main"
        titleColor="error"
        iconSize={48}
      />
    );  
  }

  if (!contact) {
    return null;
  }
  
  if (!meetingPlansPages?.pages?.[0]?.data?.length) {
    return (
      <DSStatusMessage
        icon={EventNoteIcon}
        title="No Meetings Available"
        subtitle="No meetings have been recorded for this contact yet."
        iconColor="text.secondary"
        titleColor="text.secondary"
        iconSize={48}
      />
    );
  }

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <DecisionSiteMeetingflowList
        meetingPlansPages={meetingPlansPages}
        currentPage={0}
        onPageChange={() => {}}
        className=""
        onSelectMeetingflow={(meetingflowId) => {
          navigate(
            `/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${meetingflowId}`,
          );
        }}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </Box>
  );
};
