import { FC } from 'react';
import { DateTime } from 'luxon';
import {
  StyledArtifactContainer,
  StyledMediaContainer,
  StyledContentContainer,
  StyledArtifactHeader,
  StyledArtifactName,
  StyledArtifactDescription,
  StyledArtifactTimestamp,
  StyledAudioContainer,
  StyledActionsContainer,
} from './DSWelcomeArtifactCard.styles';
import { DealRoomAudioArtifact } from '@meetingflow/common/Api/data-contracts';
import { DSContactAvatar } from '../DS/DSContactAvatar';
import { ArtifactIconPreview } from '../Artifacts/ArtifactIconPreview';
import { useNavigate as useRouterNavigate } from 'react-router-dom';
import { getArtifactLink } from '../utils/artifactUtils';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import { Box } from '@mui/material';
import { ArtifactsTableActions } from '../Artifacts/ArtifactsTableActions';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useQueryClient } from 'react-query';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';

interface DSWelcomeAudioArtifactProps {
  audioArtifact: DealRoomAudioArtifact;
}

export const DSWelcomeAudioArtifact: FC<DSWelcomeAudioArtifactProps> = ({
  audioArtifact,
}) => {
  const navigate = useRouterNavigate();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { userId } = useUserProfile();
  const { dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);
  const queryClient = useQueryClient();

  if (!audioArtifact) return null;

  // Format date for display
  const formatDate = (dateString?: string) => {
    if (!dateString) return '';
    const date = DateTime.fromISO(dateString);
    return date.toFormat('MMM d, yyyy');
  };

  const handleClick = (e: React.MouseEvent) => {
    // Don't navigate if the click was on the audio element or its controls
    if (
      e.target instanceof HTMLAudioElement ||
      (e.target instanceof HTMLElement && e.target.closest('audio'))
    ) {
      return;
    }

    // Don't navigate if clicking on the actions menu
    if (
      e.target instanceof HTMLElement &&
      (e.target.closest('.artifacts-actions-menu') ||
        e.target.closest('.ms-Button'))
    ) {
      e.stopPropagation();
      return;
    }

    // Navigate to the artifact detail page
    if (dealRoomId && organizationSlug) {
      const link = getArtifactLink(organizationSlug, dealRoomId, audioArtifact);

      navigate(link, { replace: false });
    }
  };

  const refreshDealRoom = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries([
        'dealRoom',
        organizationSlug,
        dealRoomId,
      ]);
    }
  };

  const refetchArtifacts = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries(
        OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
      );
    }
  };

  const handleDelete = async () => {
    await refetchArtifacts();
    await refreshDealRoom();
  };

  return (
    <StyledArtifactContainer onClick={handleClick}>
      <StyledActionsContainer className="artifacts-actions-menu">
        <ArtifactsTableActions
          userId={userId}
          dealRoomRole={dealRoomRole}
          organizationSlug={organizationSlug || ''}
          dealRoomId={dealRoomId || 0}
          refreshDealRoom={refreshDealRoom}
          refetchArtifacts={refetchArtifacts}
          onDelete={handleDelete}
          artifact={audioArtifact}
        />
      </StyledActionsContainer>
      <StyledMediaContainer>
        <StyledAudioContainer
          sx={{
            padding: '16px',
            boxSizing: 'border-box',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
          }}
        >
          <Box
            sx={{
              width: '100%',
              marginTop: 2,
              padding: '8px',
              backgroundColor: '#f5f5f5',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <audio
              controls
              src={audioArtifact.fileUrl}
              onClick={(e) => e.stopPropagation()}
              style={{
                width: '90%',
                minHeight: '40px',
              }}
            >
              Your browser does not support the audio element.
            </audio>
          </Box>
        </StyledAudioContainer>
      </StyledMediaContainer>
      <StyledContentContainer>
        <StyledArtifactHeader>
          {audioArtifact.creator && (
            <DSContactAvatar
              contact={audioArtifact.creator}
              size={28}
              sx={{ marginRight: 1 }}
            />
          )}
          <StyledArtifactName>
            {audioArtifact.label ||
              audioArtifact.name ||
              `An Audio from ${audioArtifact.creator?.name || 'the Team'}`}
          </StyledArtifactName>
        </StyledArtifactHeader>

        {audioArtifact.description && (
          <StyledArtifactDescription variant="body2">
            {audioArtifact.description}
          </StyledArtifactDescription>
        )}

        <StyledArtifactTimestamp variant="caption">
          {formatDate(audioArtifact.createdAt)}
        </StyledArtifactTimestamp>
      </StyledContentContainer>
    </StyledArtifactContainer>
  );
};
