/**
 * UserMeetingflowList is a component that displays a list of meetings that the user has access to
 * but are not yet part of the current Decision Site. It allows users to add these meetings to the
 * Decision Site.
 *
 * Features:
 * - Displays meetings the user has access to
 * - Allows adding meetings to the current Decision Site
 * - Filters out meetings that are already part of the Decision Site
 */

import { FC, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { MeetingflowsApiClient } from '../../../../Services/NetworkCommon';
import { OrganizationMeetingPlansQuery } from '../../../../QueryNames';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { Meetingflow } from '@meetingflow/common/Api/data-contracts';
import {
  Typography,
  CircularProgress,
  List,
  TextField,
  InputAdornment,
  Divider,
} from '@mui/material';
import { Add, Search as SearchIcon } from '@mui/icons-material';
import { toast } from 'react-hot-toast';
import {
  StyledHeaderSection,
  StyledDateNavigation,
  StyledListContainer,
  StyledListItem,
  StyledEmptyState,
  StyledDayHeader,
  StyledLoadingContainer,
  StyledMeetingThumbnail,
  StyledThumbnailImage,
  StyledThumbnailPlaceholder,
  StyledPlaceholderIcon,
  StyledAddButton,
  StyledUserMeetingflowList,
} from './UserMeetingflowList.styles';
import { DateTime } from 'luxon';
import { createMeetingflowLinkArtifact } from '../../../../utils/DecisionSiteMeetingflowHelpers';
import DSJourneyMeetingflowRow from './DSJourneyMeetingflowRow';
import { toContentPath } from '../../../../Helpers/FileHelpers';
import { DSButton } from '../../DS';
import useUserCalendar from './hooks/useUserCalendar';

interface UserMeetingflowListProps {
  onAddMeetingflow?: (meetingflowId: string) => void;
}

const UserMeetingflowList: FC<UserMeetingflowListProps> = ({
  onAddMeetingflow,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get('search') || '',
  );
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug } = useOrganization();
  const { dealRoomId } = useDealRoom();
  const queryClient = useQueryClient();
  const [isAdding, setIsAdding] = useState<Record<string, boolean>>({});
  const [debouncedSearch] = useDebounce(searchValue, 300);
  const [currentStartDate, setCurrentStartDate] = useState(() => {
    const now = DateTime.now();
    return now.startOf('day');
  });
  const { refetchEvents } = useUserCalendar({
    searchString: '',
    selectedDate: DateTime.now().startOf('week'),
    showInternal: false,
  });

  // Apply debounced search to URL params
  useEffect(() => {
    const currentSearch = searchParams.get('search') || '';
    if (debouncedSearch !== currentSearch) {
      const newParams = new URLSearchParams(searchParams);
      if (debouncedSearch) {
        newParams.set('search', debouncedSearch);
      } else {
        newParams.delete('search');
      }
      setSearchParams(newParams, { replace: true });
    }
  }, [debouncedSearch, searchParams, setSearchParams]);

  // Calculate date range
  const maxDate = currentStartDate.endOf('day').toISO();
  const minDate = currentStartDate.minus({ days: 6 }).startOf('day').toISO();

  // Fetch meeting plans for the current date range
  const { data: meetingPlansResponse, isLoading } = useQuery(
    ['user-meetingflows', organizationSlug, debouncedSearch, minDate, maxDate],
    async () => {
      if (!organizationSlug) return { data: [] };
      const token = await getAccessTokenSilently();
      const response = await MeetingflowsApiClient.listPlans(
        {
          organizationSlug: organizationSlug,
          q: debouncedSearch || undefined,
          minDate,
          maxDate,
          sortBy: 'startTime',
          sortOrder: 'desc',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response;
    },
    {
      enabled: !!organizationSlug,
      keepPreviousData: true, // Keep showing previous data while loading new data
      staleTime: 30000, // Consider data fresh for 30 seconds
      cacheTime: 300000, // Keep in cache for 5 minutes
    },
  );

  // Fetch meeting plans that are already in the deal room
  const { data: dealRoomMeetingPlansResponse } = useQuery(
    [
      ...OrganizationMeetingPlansQuery(
        organizationSlug || '',
        '',
        50,
        0,
        dealRoomId,
      ),
      'dealroom-meetingflows',
    ],
    async () => {
      const token = await getAccessTokenSilently();
      const response = await MeetingflowsApiClient.listPlans(
        {
          organizationSlug: organizationSlug!,
          dealRoomId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response;
    },
    {
      enabled: !!organizationSlug && !!dealRoomId,
      staleTime: 30000, // Consider data fresh for 30 seconds
      cacheTime: 300000, // Keep in cache for 5 minutes
    },
  );

  // Create a set of meeting plan IDs that are already in the deal room
  const dealRoomMeetingPlanIds = new Set<string>();
  if (dealRoomMeetingPlansResponse?.data) {
    dealRoomMeetingPlansResponse.data.forEach((meetingPlan) => {
      dealRoomMeetingPlanIds.add(meetingPlan.id);
    });
  }

  // Filter out meeting plans that are already in the deal room
  const filteredMeetingPlans: Meetingflow[] = [];
  if (meetingPlansResponse?.data) {
    meetingPlansResponse.data.forEach((meetingPlan) => {
      if (!dealRoomMeetingPlanIds.has(meetingPlan.id)) {
        filteredMeetingPlans.push(meetingPlan);
      }
    });
  }

  // Mutation to add a meeting plan to the deal room
  const addMeetingflowMutation = useMutation(
    async ({ meetingflowId }: { meetingflowId: string }) => {
      const token = await getAccessTokenSilently();
      return await createMeetingflowLinkArtifact(
        organizationSlug!,
        dealRoomId!,
        meetingflowId,
        queryClient,
        token,
      );
    },
    {
      onSuccess: (_, variables) => {
        // Invalidate queries is handled by createMeetingflowLinkArtifact
        toast.success('Meeting added to Decision Site');

        // Call the callback if provided
        if (onAddMeetingflow) {
          onAddMeetingflow(variables.meetingflowId);
        }
      },
      onError: () => {
        toast.error('Failed to add meeting to Decision Site');
      },
    },
  );

  const handleAddMeetingflow = async (meetingflowId: string) => {
    setIsAdding((prev) => ({ ...prev, [meetingflowId]: true }));
    try {
      await addMeetingflowMutation.mutateAsync({ meetingflowId });
    } finally {
      setIsAdding((prev) => ({ ...prev, [meetingflowId]: false }));
      refetchEvents?.();
      queryClient.invalidateQueries('ownedMeetingflows');
    }
  };

  // Format date for display
  const formatDate = (dateString?: string, format: string = 'MMM d, yyyy') => {
    if (!dateString) return 'No date';
    const date = DateTime.fromISO(dateString);
    return date.toFormat(format);
  };

  type DayGroup = {
    date: DateTime;
    meetings: Meetingflow[];
  };

  type GroupedMeetings = {
    noDate?: Meetingflow[];
    [key: string]: DayGroup | Meetingflow[] | undefined;
  };

  // Group meetings by day
  const groupMeetingsByDay = (meetings: Meetingflow[]) => {
    const groups = meetings.reduce<GroupedMeetings>((acc, meeting) => {
      if (!meeting.startTime) {
        if (!acc.noDate) acc.noDate = [];
        (acc.noDate as Meetingflow[]).push(meeting);
        return acc;
      }

      const date = DateTime.fromISO(meeting.startTime);
      const dayKey = date.toFormat('yyyy-MM-dd');

      if (!acc[dayKey]) {
        acc[dayKey] = {
          date,
          meetings: [],
        } as DayGroup;
      }
      (acc[dayKey] as DayGroup).meetings.push(meeting);
      return acc;
    }, {});

    // Sort days
    return Object.entries(groups).sort(([keyA], [keyB]) => {
      if (keyA === 'noDate') return 1;
      if (keyB === 'noDate') return -1;
      return keyA.localeCompare(keyB);
    });
  };

  return (
    <StyledUserMeetingflowList>
      <StyledHeaderSection>
        <Typography variant="subtitle1">Available Meetings</Typography>
        <TextField
          fullWidth
          size="small"
          placeholder="Search meetings..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <StyledDateNavigation>
          <DSButton
            size="small"
            onClick={() =>
              setCurrentStartDate((prev) => prev.minus({ days: 7 }))
            }
          >
            Previous
          </DSButton>
          <Typography variant="body2">
            {(() => {
              const startDate = currentStartDate.minus({ days: 6 });
              const endDate = currentStartDate;

              // If years are different, show both years
              if (startDate.year !== endDate.year) {
                return `${startDate.toFormat('MMM d, yyyy')} - ${endDate.toFormat('MMM d, yyyy')}`;
              }
              // If same year, only show it once at the end
              return `${startDate.toFormat('MMM d')} - ${endDate.toFormat('MMM d, yyyy')}`;
            })()}
          </Typography>
          <DSButton
            size="small"
            onClick={() =>
              setCurrentStartDate((prev) => prev.plus({ days: 7 }))
            }
          >
            Next
          </DSButton>
        </StyledDateNavigation>
      </StyledHeaderSection>

      <StyledListContainer>
        {isLoading ? (
          <StyledLoadingContainer>
            <CircularProgress size={24} />
          </StyledLoadingContainer>
        ) : filteredMeetingPlans.length === 0 ? (
          <StyledEmptyState>
            <Typography variant="body1">
              No additional meetings available to add
            </Typography>
          </StyledEmptyState>
        ) : (
          <List disablePadding>
            {groupMeetingsByDay(filteredMeetingPlans).map(([dayKey, group]) => (
              <div key={dayKey}>
                <StyledDayHeader variant="subtitle2">
                  {dayKey === 'noDate'
                    ? 'No Date'
                    : formatDate(
                        (group as DayGroup).date.toISO() || undefined,
                        'EEEE, MMMM d',
                      )}
                </StyledDayHeader>
                {(dayKey === 'noDate'
                  ? (group as Meetingflow[])
                  : (group as { meetings: Meetingflow[] }).meetings
                ).map((meetingPlan) => (
                  <div key={meetingPlan.id}>
                    <StyledListItem disableGutters>
                      <div className="MeetingContent">
                        <StyledMeetingThumbnail
                          onClick={() => {
                            const newParams = new URLSearchParams(searchParams);
                            newParams.set('meeting', meetingPlan.id);
                            setSearchParams(newParams);
                          }}
                        >
                          {meetingPlan.callRecording?.thumbnailFileName ? (
                            <StyledThumbnailImage
                              src={toContentPath(
                                meetingPlan?.callRecording?.thumbnailFileName,
                                organizationSlug,
                                meetingPlan.id,
                              )}
                              alt="Meeting thumbnail"
                            />
                          ) : (
                            <StyledThumbnailPlaceholder>
                              <StyledPlaceholderIcon aria-hidden={true} />
                            </StyledThumbnailPlaceholder>
                          )}
                        </StyledMeetingThumbnail>
                        <div className="MeetingCard">
                          <DSJourneyMeetingflowRow
                            meetingflow={meetingPlan}
                            showConferenceJoinButton={false}
                            showAddToDecisionSites={false}
                            showRecordButton={false}
                            showStatusButton={false}
                            showContextualMenu={false}
                            onClick={() => {
                              const newParams = new URLSearchParams(
                                searchParams,
                              );
                              newParams.set('meeting', meetingPlan.id);
                              setSearchParams(newParams);
                            }}
                          />
                        </div>
                      </div>
                      <div className="AddButton">
                        <StyledAddButton
                          variant="contained"
                          size="small"
                          startIcon={
                            isAdding[meetingPlan.id] ? (
                              <CircularProgress size={16} color="inherit" />
                            ) : (
                              <Add />
                            )
                          }
                          onClick={() => handleAddMeetingflow(meetingPlan.id)}
                          disabled={isAdding[meetingPlan.id]}
                        >
                          Add
                        </StyledAddButton>
                      </div>
                    </StyledListItem>
                    <Divider component="li" />
                  </div>
                ))}
              </div>
            ))}
          </List>
        )}
      </StyledListContainer>
    </StyledUserMeetingflowList>
  );
};

export default UserMeetingflowList;
