import { useCallback } from 'react';
import { useDealRoomActivity } from './useDealRoomActivity';

interface UseRecordAIActivityProps {
  organizationSlug: string;
  dealRoomId: number;
}

/**
 * Hook for recording AI question activities in DealRoom analytics
 */
export const useRecordAIActivity = ({
  organizationSlug,
  dealRoomId,
}: UseRecordAIActivityProps) => {
  const { recordActivity } = useDealRoomActivity();

  const recordAIQuestion = useCallback(
    (message: string) => {
      const currentMessage = message.trim();
      recordActivity({
        activityType: 'DS_ARTIFACTS_ASK_AI',
        organizationSlug,
        dealRoomId,
        metadata: {
          question: currentMessage,
          questionWords: currentMessage.split(' ').length,
        },
      });
    },
    [recordActivity, organizationSlug, dealRoomId],
  );

  return { recordAIQuestion };
};
