import { styled, Box, Stack, Avatar, List, ListItem } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

// Layout components
export const PanelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

export const HeaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  position: 'relative',
  borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  backgroundColor: 'transparent',
  zIndex: 1,
  flex: '0 0 auto', // Don't grow or shrink, use auto height
});

export const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto', // Allow growing and shrinking
  overflow: 'auto',
  backgroundColor: 'transparent',
  padding: '1rem',
});

export const ContentStack = styled(Stack)({
  width: '100%',
  maxHeight: '100%',
});

export const ImageColumn = styled(Box)({
  width: '33%',
});

export const InfoColumn = styled(Box)({
  width: '66%',
  overflowY: 'auto',
  paddingLeft: '.5rem',
});

// Profile elements
export const ProfileImage = styled('img')({
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  borderRadius: '.25rem',
  objectFit: 'cover',
});

export const ProfileAvatar = styled(Avatar)({
  width: '100%',
  height: 'auto',
  aspectRatio: '1/1',
});

// List components
export const AdjectiveChipsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '.25rem',
  minHeight: '24px',
  paddingBottom: '8px',
});

export const BackgroundListItem = styled(ListItem)({
  display: 'list-item',
  padding: '0 0',
  minHeight: '24px',
  paddingBottom: '8px',

  strong: {
    fontWeight: 500,
    color: DEALROOMS_COLORS.woodsmoke,
  },
});
export const adjectiveColors = [
  DEALROOMS_COLORS.peach,
  DEALROOMS_COLORS.artic,
  DEALROOMS_COLORS.plum50,
  DEALROOMS_COLORS.oink,
  DEALROOMS_COLORS.orchid,
  DEALROOMS_COLORS.frost,
  DEALROOMS_COLORS.actionItemStatusInProgress,
  DEALROOMS_COLORS.actionItemStatusCompleted,
];

export const AdjectiveChip = {
  backgroundColor: DEALROOMS_COLORS.white,
  color: DEALROOMS_COLORS.cloudburst,
  fontSize: '12px',
  height: '24px',
  lineHeight: '24px',
  '& .MuiChip-label': {
    fontWeight: 400,
    fontSize: '12px',
  },
};

export const ChipsLabel = {
  color: DEALROOMS_COLORS.woodsmoke,
  fontWeight: 500,
  fontSize: '14px',
  minWidth: '40px',
};

export const SkillsChip = {
  backgroundColor: DEALROOMS_COLORS.neutralGray,
  margin: '0',
  color: DEALROOMS_COLORS.cloudburst,
  fontSize: '12px',
  height: '24px',
  lineHeight: '24px',
  '& .MuiChip-label': {
    fontWeight: 400,
    fontSize: '12px',
  },
};

export const Description = {
  color: DEALROOMS_COLORS.cloudburst,
  fontSize: '14px',
  lineHeight: '1rem',
};
