import { useTitle } from '../Hooks/useTitle';
import { useNavigate } from 'react-router-dom';
import { Text } from '@fluentui/react';
import { DealRoomPrimaryButton } from './DealRoom/Components/DealRoomButton';
import { NotFoundIllustration } from './DealRoom/Components/Illustrations/NotFoundIllustration';
import { NotFoundLayout } from './Layouts/NotFoundLayout';
import { useCallback, useEffect } from 'react';
import { useNotFoundContentStyles } from '../Hooks/styles/useNotFoundContentStyles';
import { useDSGlobalSidebar } from '../Context/DSGlobalSidebarContext';

export const NotFound = () => {
  useTitle('Not Found');
  const navigate = useNavigate();
  const styles = useNotFoundContentStyles();
  const { excludeSidebar, includeSidebar } = useDSGlobalSidebar();
  
  // Remove sidebar from DOM when component mounts, restore it when unmounting
  useEffect(() => {
    excludeSidebar();
    return () => includeSidebar();
  }, [excludeSidebar, includeSidebar]);

  const handleClickActionButton = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <NotFoundLayout illustration={<NotFoundIllustration />}>
      <Text className={styles.title}>404</Text>
      <div className={styles.subtitleContainer}>
        <Text className="subtitle-item">Oops!</Text>
        <Text className="subtitle-item">Page Not Found</Text>
      </div>
      <div className={styles.descriptionContainer}>
        <Text className="description-item">
          This page doesn't exist or was removed!
        </Text>
        <Text className="description-item">Please return to the main page</Text>
      </div>
      <DealRoomPrimaryButton
        customClasses={styles.actionButton}
        onClick={handleClickActionButton}
      >
        Back to Main Page
      </DealRoomPrimaryButton>
    </NotFoundLayout>
  );
};

export default NotFound;
