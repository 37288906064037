/**
 * DecisionSiteMeetingflowListWithFilters is a component that displays a filterable
 * list of meetings within a Decision Site. It combines filtering capabilities with
 * a paginated list view of meetings.
 * 
 * Features:
 * - Collapsible filter section
 * - Configurable filter options (search, date, attendees, etc.)
 * - Infinite scrolling meeting list
 * - Integration with deal room context
 * - Real-time filter updates
 */

import { FC, PropsWithChildren, useState } from 'react';
import { DecisionSiteMeetingflowFilters } from './DecisionSiteMeetingflowFilters';
import DecisionSiteMeetingflowList from './DecisionSiteMeetingflowList';
import { useInfiniteQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { MeetingflowsApiClient } from '../../../../Services/NetworkCommon';
import { OrganizationMeetingPlansQuery } from '../../../../QueryNames';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useSearchParams } from 'react-router-dom';
import { collectToRecord } from '../../../../Helpers/SearchParamHelpers';
import {
  StyledListWithFilters,
  StyledFilterSection,
  StyledFilterHeader,
  StyledHeaderSection,
} from './DecisionSiteMeetingflowListWithFilters.styles';
import { IconButton, Collapse, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

/**
 * Props interface for DecisionSiteMeetingflowListWithFilters
 * @property onFiltersChange - Callback when filters are modified
 * @property onSelectMeetingflow - Callback when a meeting is selected
 * @property availableFilters - Configuration object for enabled filter types
 */
interface DecisionSiteMeetingflowListWithFiltersProps {
  onFiltersChange?: (filters: string) => void;
  onSelectMeetingflow?: (meetingflowId: string) => void;
  availableFilters?: {
    search: boolean;
    dateRange: boolean;
    attendees: boolean;
    companies: boolean;
    tags: boolean;
    hasRecording: boolean;
  };
}

/**
 * Component that combines meeting list with configurable filters
 * Uses infinite query for efficient data loading and pagination
 */
const DecisionSiteMeetingflowListWithFilters: FC<
  PropsWithChildren<DecisionSiteMeetingflowListWithFiltersProps>
> = ({
  onFiltersChange,
  onSelectMeetingflow,
  availableFilters = {
    search: true,
    dateRange: false,
    attendees: false,
    companies: false,
    tags: false,
    hasRecording: true,
  },
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug } = useOrganization();
  const { dealRoomId } = useDealRoom();
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  // Create a new URLSearchParams without the 'meeting' parameter
  const filterParams = new URLSearchParams(searchParams);
  filterParams.delete('meeting');
  // Only include relevant filter parameters in the query key
  const relevantFilters = new URLSearchParams();
  for (const [key, value] of filterParams.entries()) {
    if (['q', 'dateRange', 'attendees', 'companies', 'tags', 'hasRecording'].includes(key)) {
      relevantFilters.append(key, value);
    }
  }

  /**
   * Infinite query hook to fetch meeting plans with pagination
   * Includes support for filtering via search params
   */
  const { data: meetingPlansPages, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery(
    [
      ...OrganizationMeetingPlansQuery(
        organizationSlug || '',
        '',
        50,
        0,
        dealRoomId,
      ),
      relevantFilters.toString(),
    ],
    async ({ pageParam = 0 }) => {
      const token = await getAccessTokenSilently();
      const apiParams = {
        organizationSlug: organizationSlug!,
        limit: 50,
        skip: pageParam * 50,
        dealRoomId,
        ...collectToRecord(relevantFilters),
      };
      const response = await MeetingflowsApiClient.listPlans(
        apiParams,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response;
    },
    {
      enabled: !!organizationSlug && !!dealRoomId,
      cacheTime: 300000,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      getNextPageParam: (lastPage, pages) => {
        const totalItems = lastPage.data.length;
        const currentPageCount = pages.length;
        return totalItems === 50 ? currentPageCount : undefined;
      },
    },
  );

  /**
   * Handler for meeting selection
   * Invokes the onSelectMeetingflow callback if provided
   */
  const handleSelectMeetingflow = (meetingflowId: string) => {
    if (onSelectMeetingflow) {
      onSelectMeetingflow(meetingflowId);
    }
  };

  return (
    <StyledListWithFilters>
      {/* Header section with title */}
      <StyledHeaderSection>
        {' '}
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 'normal',
          }}
        >
          Meetings in this Decision Site
        </Typography>
      </StyledHeaderSection>

      {/* Collapsible filters section */}
      <div className="list-filters">
        <StyledFilterSection>
          <StyledFilterHeader>
            <IconButton
              size="small"
              color="secondary"
              onClick={() => setIsFilterExpanded(!isFilterExpanded)}
            >
              {isFilterExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </StyledFilterHeader>

          <Collapse in={isFilterExpanded}>
            <DecisionSiteMeetingflowFilters
              onFiltersChange={onFiltersChange}
              availableFilters={availableFilters}
            />
          </Collapse>
        </StyledFilterSection>
      </div>

      {/* Meeting list container with pagination */}
      <div className="list-container">
        <DecisionSiteMeetingflowList
          meetingPlansPages={meetingPlansPages}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          onSelectMeetingflow={handleSelectMeetingflow}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
        />
      </div>
    </StyledListWithFilters>
  );
};

export default DecisionSiteMeetingflowListWithFilters;
