import React, { useCallback, useState } from 'react';
import { TextField, DatePicker, Text, Stack } from '@fluentui/react';
import { Add as AddIcon } from '@mui/icons-material';
import { DSButton, DSResponsiveModal } from '../../../DS';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { dsConfigurationMutualPlanStyles } from './styles';
import toast from 'react-hot-toast';

interface DSConfigurationMutualPlanCreateMilestoneButtonProps {
  organizationSlug: string;
  dealRoomId: number;
  refetchMilestones: () => void;
  refetchMutualPlan: () => void;
}

export const DSConfigurationMutualPlanCreateMilestoneButton: React.FC<
  DSConfigurationMutualPlanCreateMilestoneButtonProps
> = ({
  organizationSlug,
  dealRoomId,
  refetchMilestones,
  refetchMutualPlan,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Handle milestone creation
  const handleCreateMilestone = useCallback(
    async (title: string, dueDate: Date | null) => {
      try {
        const token = await getAccessTokenSilently();
        // Create milestone using API and show toast notifications
        await toast.promise(
          DealRoomsApiClient.saveMilestones(
            organizationSlug,
            dealRoomId,
            [
              {
                title,
                dueDate: dueDate?.toISOString() || null,
                visible: true,
              },
            ],
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Creating milestone...',
            success: () => {
              // Refetch data and close dialog on success
              refetchMilestones();
              refetchMutualPlan();
              setIsDialogOpen(false);
              return 'Milestone created successfully';
            },
            error: 'Failed to create milestone',
          },
        );
      } catch (error) {
        console.error('Error creating milestone:', error);
        toast.error('Failed to create milestone');
      }
    },
    [
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchMilestones,
      refetchMutualPlan,
    ],
  );

  return (
    <>
      {/* Button to open create milestone dialog */}
      <DSButton
        onClick={() => setIsDialogOpen(true)}
        startIcon={<AddIcon />}
        size="small"
        color="secondary"
      >
        Create Milestone
      </DSButton>

      {/* Create milestone dialog component */}
      <DSConfigurationMutualPlanCreateMilestoneDialog
        isOpen={isDialogOpen}
        onConfirm={handleCreateMilestone}
        onDismiss={() => setIsDialogOpen(false)}
      />
    </>
  );
};

interface DSConfigurationMutualPlanAddMilestoneDialogProps {
  isOpen: boolean;
  onConfirm: (title: string, dueDate: Date | null) => void;
  onDismiss: () => void;
}

const DSConfigurationMutualPlanCreateMilestoneDialog: React.FC<
  DSConfigurationMutualPlanAddMilestoneDialogProps
> = ({ isOpen, onConfirm, onDismiss }) => {
  const [title, setTitle] = useState('');
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [titleError, setTitleError] = useState('');
  const styles = dsConfigurationMutualPlanStyles;

  const validateTitle = useCallback((value: string) => {
    if (value.length === 0) {
      return 'Title is required';
    }

    return '';
  }, []);

  const handleTitleChange = useCallback(
    (newValue: string | undefined) => {
      const value = newValue || '';
      setTitle(value);
      setTitleError(validateTitle(value));
    },
    [validateTitle],
  );

  const handleConfirm = () => {
    // Continue if valid
    onConfirm(title, dueDate);
    setTitle('');
    setDueDate(null);
  };

  const handleDismiss = () => {
    onDismiss();
    setTitle('');
    setDueDate(null);
    setTitleError('');
  };

  return (
    <DSResponsiveModal
      open={isOpen}
      onDismiss={handleDismiss}
      title="Create New Milestone"
      subText="Create a new milestone for your mutual plan"
      fullScreenOnMobile
      maxWidth="sm"
      primaryFooterButtons={[
        {
          children: 'Create',
          onClick: handleConfirm,
          disabled: !title || !!titleError,
        },
      ]}
      secondaryFooterButtons={[
        {
          children: 'Cancel',
          onClick: handleDismiss,
        },
      ]}
    >
      <Stack className={styles.contentContainer}>
        {/* Milestone title input */}
        <div className={styles.field}>
          <div className={styles.label}>
            <Text>Title</Text>
            <Text className={styles.required}>*</Text>
          </div>
          <TextField
            placeholder="Enter milestone title..."
            value={title}
            onChange={(e, newValue) => handleTitleChange(newValue)}
            errorMessage={titleError}
            required
          />
        </div>
        {/* Milestone due date picker */}
        <div className={styles.field}>
          <div className={styles.label}>
            <Text>Due Date</Text>
          </div>
          <DatePicker
            placeholder="Select a date..."
            value={dueDate || undefined}
            onSelectDate={(date) => setDueDate(date || null)}
          />
        </div>
      </Stack>
    </DSResponsiveModal>
  );
};
