import React from 'react';
import { Stack, Text, PrimaryButton } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import useDeviceType from '../../../../Hooks/deviceDetection';

type DSConfigurationHeaderRowProps = {
  title?: string;
  description: string;
  children: React.ReactNode;
};

export const DSConfigurationHeaderRow = ({
  title,
  description,
  children,
}: DSConfigurationHeaderRowProps) => {
  const { isMobile } = useDeviceType();

  const styles = {
    description: {
      color: DEALROOMS_COLORS.neutralSecondary,
      maxWidth: '600px',
    },
  };

  return (
    <Stack
      horizontal={!isMobile}
      verticalAlign="start"
      horizontalAlign="space-between"
      styles={{ root: { width: '100%', gap: '10px', flexWrap: 'wrap' } }}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <Text variant="xLarge" block>
          {title}
        </Text>
        <Text style={styles.description}>{description}</Text>
      </Stack>
      <Stack
        horizontal
        horizontalAlign={isMobile ? 'center' : 'end'}
        tokens={{ childrenGap: 10 }}
        styles={{
          root: {
            // width: '100%',
            gap: '10px',
            flexWrap: 'wrap',
            '> *': { margin: '0 !important', borderRadius: '4px' },
          },
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};
