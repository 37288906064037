import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  marginBottom: '1rem',
}));

interface NoArtifactsProps {
  justifyContent?: string;
}

export const NoArtifacts = styled(Box)<NoArtifactsProps>(
  ({ theme, justifyContent = 'center' }) => ({
    display: 'flex',
    justifyContent,
    alignItems: 'center',
    width: '100%',
    minHeight: '100px',
    color: DEALROOMS_COLORS.neutralSecondary,
  }),
);

// @ts-ignore
export const ExampleArtifactsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: '1rem',
  width: '100%',
  overflowX: 'auto !important',
  // Hide scrollbar by default
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  // Show scrollbar on hover
  '&:hover::-webkit-scrollbar': {
    display: 'block',
    height: '8px',
  },
  '&:hover': {
    scrollbarWidth: 'thin',
  },
}));

// @ts-ignore
export const ArtifactScroll = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: '1rem',
  width: '100%',
  overflowX: 'auto !important',
  paddingBottom: '1rem',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'nowrap',
  },
  '& > *': {
    flex: '0 0 auto',
    maxWidth: '300px',
    minWidth: '300px',
  },
  // Hide scrollbar by default
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  // Show scrollbar on hover
  '&:hover::-webkit-scrollbar': {
    display: 'block',
    height: '8px',
  },
  '&:hover': {
    scrollbarWidth: 'thin',
  },
  // Ensure smooth scrolling
  scrollBehavior: 'smooth',
}));
