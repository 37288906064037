import {
  mergeStyleSets,
  Spinner,
  Stack,
  Text,
  useTheme,
} from '@fluentui/react';
import { useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import ResourceNotFound from '../ResourceNotFound';

import { DealRoomBuyersArtifactsTab } from './Tabs/DealRoomBuyersArtifactsTab';
import { DealRoomBuyersOverviewTab } from './Tabs/DealRoomBuyersOverviewTab';
import { DealRoomBuyersJourneyTab } from './Tabs/DealRoomBuyersJourneyTab';
import { UserSettingsWrapper } from './Components/UserSettings/UserSettingsWrapper';
import { useUserProfile } from '../../Hooks/useProfile';
import { DealRoomMutualProgressTab } from './Tabs/DealRoomMutualProgressTab';
import { useQueryClient } from 'react-query';
import useDeviceType from '../../Hooks/deviceDetection';
import { useUploadDealRoomArtifactDialog } from '../../Hooks/Modals/DealRoom/useUploadDealRoomArtifactDialog';
import { DSButton } from './DS';
import { AddOutlined, AutoAwesome } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DEFAULT_STACK_TOKENS } from '../../Helpers/Layout';
import DSInbox from './Tabs/DSInbox';
import DSContactsTab from './Tabs/Contacts/DealRoomContactsTab';
import DSCompaniesTab from './Tabs/Companies/DealRoomCompaniesTab';
import { useDealRoomActivity } from './Analytics/hooks/useDealRoomActivity';
import { DealRoomSettingsButton } from './Components/DealRoomSettingsButton';
import { DSSellerHubRoot } from './Analytics/DSSellerHubRoot';
// BreadcrumbProvider and BreadcrumbManager are now in DSRoot

export type DealRoomBuyersViewProps = {
  organizationSlug: string;
  dealRoomId: number;
};

export const DealRoomBuyersView = ({
  organizationSlug,
  dealRoomId,
}: DealRoomBuyersViewProps) => {
  const { isMobile } = useDeviceType();

  const { user } = useUserProfile();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const {
    dealRoom,
    isLoading,
    isFetched,
    refetch: refetchDealRoomRoot,
  } = useDealRoom(organizationSlug, dealRoomId);

  const queryClient = useQueryClient();

  const refetchDealRoom = useMemo(
    () => async () => {
      await queryClient.invalidateQueries({
        queryKey: ['artifacts', dealRoomId, organizationSlug],
      });
      await refetchDealRoomRoot();
    },
    [queryClient, dealRoomId, organizationSlug, refetchDealRoomRoot],
  );

  const {
    createDeferred: showAddDealRoomArtifactDialog,
    dialog: addDealRoomArtifactDialog,
  } = useUploadDealRoomArtifactDialog({
    organizationSlug,
    dealRoomId,
  });

  const { dealRoomRole } = useDealRoom();

  const location = useLocation();

  const activeTab = useMemo(() => {
    const path = `/organization/:organizationSlug/decisionsite/:dealRoomId`;

    if (matchPath(`${path}/inbox`, location.pathname)) {
      return 'inbox';
    }

    if (matchPath(`${path}/artifacts`, location.pathname)) {
      return 'artifacts';
    }

    if (matchPath(`${path}/journey`, location.pathname)) {
      return 'journey';
    }

    if (matchPath(`${path}/mutual-action-plan`, location.pathname)) {
      return 'mutual-action-plan';
    }

    if (matchPath(`${path}/settings`, location.pathname)) {
      return 'settings';
    }

    if (matchPath(`${path}/hub`, location.pathname)) {
      return 'hub';
    }

    if (matchPath(`${path}/contacts`, location.pathname)) {
      return 'contacts';
    }

    if (matchPath(`${path}/companies`, location.pathname)) {
      return 'companies';
    }

    // Check for explicit overview path
    if (matchPath(`${path}/overview`, location.pathname)) {
      return 'overview';
    }

    // Only apply default tab logic when the URL matches exactly the base decisionsite path
    // This ensures navigation history works correctly when using browser back button
    const exactBasePathMatch = matchPath(`${path}`, location.pathname);
    
    if (exactBasePathMatch) {
      // Default tab based on role - sellers see hub, buyers see overview
      // This only applies when at the root decisionsite URL with no specific tab
      return dealRoomRole === 'SELLER' ? 'hub' : 'overview';
    }
    
    // If we're here, we have a URL that doesn't match any known pattern
    // Return overview as a safe default to ensure navigation works properly
    return 'overview';
  }, [location.pathname, dealRoomRole]);

  const styles = mergeStyleSets({
    header: {
      // ...(activeTab === 'mutual-action-plan'
      //   ? {
      //       maxWidth: 'calc(80% - 42px)',
      //     }
      //   : {}),
      marginBottom: '1rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: '1rem',
      justifyContent: 'space-between',

      '.title': {
        gridArea: 'title',
      },

      '.section-title': {
        fontWeight: 400,
        fontSize: '1.75rem',
        lineHeight: '2rem',
        color: DEALROOMS_COLORS.themePrimary,
      },

      '.subtitle': {
        gridArea: 'subtitle',
      },

      '.actions': {
        gridArea: 'actions',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '1rem',
      },
    },

    content: {
      '& [role="tabpanel"]': {
        margin: '0.5rem',
      },
    },

    mainContent: {
      width: '100%',
      height: '100%',
      overflowX: 'auto',
      padding: isMobile ? '1rem' : '2rem',
    },

    prioritiesWrapper: {
      paddingBottom: '3.5rem',
    },

    headerContentWrapper: {
      marginLeft: 'auto',
    },

    tabHeaderContentWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      button: {
        height: '2.5rem',
      },
    },

    mainWrapper: {
      display: 'flex',
      width: '100%',
      minHeight: '100%',
    },
  });

  const content = useMemo(() => {
    if (!dealRoom) {
      return null;
    }

    switch (activeTab) {
      case 'overview':
        return (
          <DealRoomBuyersOverviewTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            dealRoom={dealRoom!}
            refreshDealRoom={refetchDealRoom}
          />
        );
      case 'artifacts':
        return (
          <DealRoomBuyersArtifactsTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            dealRoom={dealRoom!}
            refreshDealRoom={refetchDealRoom}
          />
        );
      case 'journey':
        return <DealRoomBuyersJourneyTab />;
      case 'mutual-action-plan':
        return (
          <DealRoomMutualProgressTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
          />
        );
      case 'settings':
        return (
          <UserSettingsWrapper
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            refetchDealRoom={refetchDealRoom}
          />
        );

      case 'hub':
        return <DSSellerHubRoot />;
      case 'inbox':
        return <DSInbox />;
      case 'contacts':
        return <DSContactsTab />;
      case 'companies':
        return <DSCompaniesTab />;
      default:
        return null;
    }
  }, [activeTab, dealRoom, dealRoomId, organizationSlug, refetchDealRoom]);

  const activeTabTitle = useMemo(() => {
    switch (activeTab) {
      case 'overview':
        return null;
      case 'artifacts':
        return 'Artifacts';
      case 'journey':
        return 'Journey';
      case 'mutual-action-plan':
        return 'Mutual Plan';
      case 'hub':
        return null;
      case 'settings':
        return 'Settings';
      case 'inbox':
        return 'Inbox';
      case 'contacts':
        return 'Contacts';
      case 'companies':
        return 'Companies';
    }
  }, [activeTab]);

  const appInsights = useAppInsightsContext();

  const [isCreatingQuickMeeting, setIsCreatingQuickMeeting] = useState(false);

  const { recordActivity } = useDealRoomActivity();

  const activeTabHeaderContent = useMemo(() => {
    switch (activeTab) {
      case 'artifacts':
        return (
          <>
            <DSButton
              onClick={async (ev) => {
                recordActivity({
                  activityType: 'DS_ARTIFACTS_CLICK_ADD_ARTIFACT',
                  organizationSlug,
                  dealRoomId: dealRoomId,
                  metadata: {
                    decisionSiteId: dealRoomId,
                    decisionSiteName: dealRoom?.name,
                  },
                });

                try {
                  const result = await showAddDealRoomArtifactDialog().promise;
                  if (result) {
                    await refetchDealRoom();
                  }
                } catch (error) {
                  // Ignore synthetic events and cancellation
                  if (
                    (error &&
                      typeof error === 'object' &&
                      '_reactName' in error) ||
                    error === 'Cancelled' ||
                    error === undefined
                  ) {
                    return;
                  }
                  throw error;
                }
              }}
            >
              <AddOutlined />
              Add Artifact
            </DSButton>
          </>
        );
      case 'journey':
        return null;
      case 'overview':
        return null;
      case 'hub':
        return null;
      case 'settings':
        return null;
      case 'inbox':
        return null;
      case 'contacts':
        return null;
      case 'companies':
        return null;
      case 'mutual-action-plan':
        return (
          <Stack horizontal tokens={DEFAULT_STACK_TOKENS}>
            <DSButton
              onClick={() => {
                navigate(
                  `/organization/${organizationSlug}/decisionsite/${dealRoomId}/mutual-action-plan?suggested=true`,
                );
              }}
              startIcon={<AutoAwesome />}
            >
              Suggested Tasks
            </DSButton>
          </Stack>
        );
      default:
        return null;
    }
  }, [
    activeTab,
    showAddDealRoomArtifactDialog,
    refetchDealRoom,
    organizationSlug,
    dealRoomId,
    navigate,
    recordActivity,
    dealRoom,
  ]);

  if (isLoading && !isFetched) {
    return <Spinner />;
  }

  if (!dealRoom) {
    return <ResourceNotFound resourceType="Deal Room" />;
  }

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.mainContent}>
        <div className={styles.header}>
          <Text className="section-title" variant="xxLargePlus" block>
            {activeTabTitle}
          </Text>
          <div className={styles.tabHeaderContentWrapper}>
            {activeTabHeaderContent}
          </div>
        </div>
        <div className={styles.content}>{content}</div>
      </div>
      {addDealRoomArtifactDialog}
    </div>
  );
};

export default DealRoomBuyersView;
