import { useCallback, useMemo, useRef } from 'react';
import { mergeStyleSets, Text, Stack, IconButton } from '@fluentui/react';
import { PrioritiesHeaderLogo } from './Illustrations/PrioritiesHeaderLogo';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DealRoomCommandBarButton } from './DealRoomButton';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useUserProfile } from '../../../Hooks/useProfile';
import { buyerOrientationSurveyOptions } from './BuyerOrientationSurvey/buyerOrientationSurveyUtils';
import { SurveyOptionCard } from './BuyerOrientationSurvey/SurveyOptionCard';
import { useStateWithCallback } from '../../../Hooks/useStateWithCallback';
import { ChevronDownIconProps } from '../../../utils/iconProps';
import { useBuyerPrioritiesDialog } from '../../../Hooks/Modals/DealRoom/useBuyerPrioritiesDialog';
import { useBoolean } from '@fluentui/react-hooks';

type DealRoomUserPrioritiesContainerProps = {
  organizationSlug?: string;
  dealRoomId?: number;
};

const heightDefinedValues = {
  closed: '0px',
  fitContent: 'fit-content',
};

export const DealRoomUserPrioritiesContainer = ({
  organizationSlug,
  dealRoomId,
}: DealRoomUserPrioritiesContainerProps) => {
  const [maxHeight, setMaxHeight] = useStateWithCallback(
    heightDefinedValues.closed,
  );
  const [showDialog, { setTrue: setShowDialog, setFalse: setHideDialog }] =
    useBoolean(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { user } = useUserProfile();
  const { dealRoom } = useDealRoom(organizationSlug, dealRoomId);

  const { userPriorities, otherPriorityText, userDealRole } = useMemo(() => {
    const currentUserDetails = dealRoom?.contacts?.find(
      (contact) => contact.email.toLowerCase() === user?.email.toLowerCase(),
    );

    if (currentUserDetails) {
      return {
        userPriorities:
          'priorities' in currentUserDetails &&
          Array.isArray(currentUserDetails.priorities)
            ? (currentUserDetails.priorities as string[])
            : [],
        otherPriorityText:
          'otherPriorityText' in currentUserDetails
            ? (currentUserDetails.otherPriorityText as string)
            : '',
        userDealRole: currentUserDetails.role,
      };
    }

    return {
      userPriorities: [],
      otherPriorityText: '',
      userDealRole: null,
    };
  }, [dealRoom, user]);

  const buyerPrioritiesDialog = useBuyerPrioritiesDialog({
    organizationSlug,
    dealRoomId,
    handleCloseModal: setHideDialog,
    userDealRole,
  });

  const styles = mergeStyleSets({
    wrapper: {
      width: '100%',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '4px',
      boxShadow: '0px 2px 3px 0px #00000008',
      marginBottom: '1rem',
      marginTop: '1rem',
      backgroundColor: DEALROOMS_COLORS.white,
    },
    header: {
      alignItems: 'center',
      gap: '.5rem',
      padding: '.5rem 1rem',
    },
    illustration: {
      width: '28px',
      height: '28px',
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontWeight: '400',
      fontSize: '1rem',
      lineHeight: '2.5rem',
      flexGrow: 1,
      '@media(max-width: 1080px)': {
        fontSize: '1rem',
      },
      '@media(max-width: 820px)': {
        lineHeight: '1rem',
      },
    },
    editButton: {
      padding: '0.5rem 0.75rem',
      marginRight: '1.5rem',
    },
    generalButton: {
      height: '1.5rem',
      width: '1.5rem',
      i: {
        transition: 'all 0.5s ease',
        ...(maxHeight !== heightDefinedValues.closed
          ? {
              transform: 'rotate(180deg)',
            }
          : {}),
      },
    },
    contentContainer: {
      maxHeight: maxHeight,
      transition: 'max-height 0.5s ease',
      overflow: 'hidden',
      backgroundColor: DEALROOMS_COLORS.neutralGray,
      borderRadius: '0 0 4px 4px',
    },
    content: {
      boxSizing: 'border-box',
      padding: '1.5rem',
      borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    },
    noPreferences: {
      fontSize: '1rem',
      lineHeight: '1rem',
      fontWeight: '400',
      color: DEALROOMS_COLORS.themePrimary,
    },
    priorities: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      gap: '1.5rem',
    },
  });

  const resetTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  const handleToggle = useCallback(() => {
    if (containerRef.current) {
      const fullHeight = `${containerRef.current.scrollHeight}px`;
      resetTimeout();

      if (maxHeight === heightDefinedValues.closed) {
        setMaxHeight(fullHeight, () => {
          timeoutRef.current = setTimeout(() => {
            // set fit-content for the container in order to be responsive
            setMaxHeight(heightDefinedValues.fitContent);
            resetTimeout();
          }, 500);
        });
      } else {
        // set fix height in order to be able to animate the collapse effect
        if (maxHeight !== fullHeight) {
          setMaxHeight(fullHeight, () => {
            setMaxHeight(heightDefinedValues.closed);
          });
        } else {
          setMaxHeight(heightDefinedValues.closed);
        }
      }
    }
  }, [maxHeight, resetTimeout, setMaxHeight]);

  return (
    <Stack className={styles.wrapper}>
      <Stack horizontal className={styles.header}>
        <div className={styles.illustration}>
          <PrioritiesHeaderLogo />
        </div>
        <Text className={styles.title}>
          {maxHeight !== '0px'
            ? 'Help us better serve you by keeping your priorities up to date'
            : 'Your Priorities'}
        </Text>
        {maxHeight !== heightDefinedValues.closed && (
          <DealRoomCommandBarButton
            onClick={setShowDialog}
            text="Edit"
            customClasses={styles.editButton}
            buttonStyleType="COMMAND_BAR"
          />
        )}
        <IconButton
          className={styles.generalButton}
          onClick={handleToggle}
          iconProps={ChevronDownIconProps}
        ></IconButton>
      </Stack>
      <div ref={containerRef} className={styles.contentContainer}>
        <div className={styles.content}>
          {userPriorities.length ? (
            <div className={styles.priorities}>
              {buyerOrientationSurveyOptions?.priorities?.options
                ?.filter((option) => userPriorities.includes(option.value))
                .map((item) => (
                  <SurveyOptionCard
                    key={item.optionId}
                    option={item}
                    isSelected={false}
                    {...(item.value === 'OTHER'
                      ? {
                          showOtherPriorityText: true,
                          otherPriorityText: otherPriorityText,
                        }
                      : {})}
                  />
                ))}
            </div>
          ) : (
            <Text className={styles.noPreferences}>
              You do not have any preferences set.
            </Text>
          )}
        </div>
      </div>
      {showDialog && buyerPrioritiesDialog}
    </Stack>
  );
};
