import {
  Meetingflow,
  CalendarEvent,
  DealRoomArtifact,
  DealRoomMeetingflowArtifact,
} from '@meetingflow/common/Api/data-contracts';
import { DateTime } from 'luxon';
import { useMemo, useState, useEffect } from 'react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import StyledDateTime from '../../../StyledDateTime';
import { EventCardContainer } from '../../DSHome/Meetings/DSMeetingsCalendarEventRow.styles';
import { Stack, Box, Typography } from '@mui/material';
import { DSTooltip } from '../../DS/DSTooltip';
import { DSMeetingJoinButton } from '../../DSHome/Meetings/DSMeetingJoinButton';
import { DSMeetingRecordButton } from '../../DSHome/Meetings/DSMeetingRecordButton';
import { DSMeetingStatusButton } from '../../DSHome/Meetings/DSMeetingStatusButton';
import {
  ActionButtonsContainer,
  AnimatedIconWrapper,
  AttendeeCountContainer,
  AttendeeItem,
  AttendeesList,
  AttendeesTooltipContent,
} from './DSJourneyMeetingflowRow.styles';
import {
  CheckCircle as CheckCircleIcon,
  People,
  ShowChartOutlined,
} from '@mui/icons-material';
import DSMeetingsAddToDecisionSites from '../../DSHome/Meetings/DSMeetingsAddToDecisionSites';
import { DecisionSiteMeetingflowCardContextMenu } from '../../../../Components/MeetingPlans/DecisionSiteMeetingflowCard/DecisionSiteMeetingflowCardContextMenu';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';
import {
  deleteMeetingflowAndDecisionSiteArtifact,
  removeMeetingflowArtifactFromDecisionSite,
  toggleMeetingflowFeature,
  toggleMeetingflowWelcome,
} from '../../../../utils/DecisionSiteMeetingflowHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useQueryClient } from 'react-query';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';

interface DSJourneyMeetingflowRowProps {
  meetingflow: Meetingflow;
  showAddToDecisionSites?: boolean;
  showConferenceJoinButton?: boolean;
  showRecordButton?: boolean;
  showStatusButton?: boolean;
  showContextualMenu?: boolean;
  onClick?: () => void;
  refetchEvents?: () => Promise<unknown>;
}

const DSJourneyMeetingflowRow = ({
  meetingflow,
  showAddToDecisionSites = false,
  showConferenceJoinButton = true,
  showRecordButton = true,
  showStatusButton = true,
  showContextualMenu = true,
  onClick,
  refetchEvents,
}: DSJourneyMeetingflowRowProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { slug: organizationSlug, hasEntitlement } = useOrganization();
  const dealRoomId = useDealRoomId();
  const { dealRoom, dealRoomRole } = useDealRoom();
  const { getAccessTokenSilently } = useAuth0();
  const appInsights = useAppInsightsContext();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isArtifactFeatured, setIsArtifactFeatured] = useState(false);
  const [isArtifactInWelcome, setIsArtifactInWelcome] = useState(false);

  useEffect(() => {
    if (dealRoom?.artifacts) {
      const artifact = dealRoom.artifacts.find(
        (a: DealRoomArtifact): a is DealRoomMeetingflowArtifact =>
          a.type === 'MEETINGFLOW' &&
          'meetingflowId' in a &&
          a.meetingflowId === meetingflow.id,
      );
      setIsArtifactFeatured(!!artifact?.featuredAt);
      setIsArtifactInWelcome(artifact?.placement === 'WELCOME');
    }
  }, [dealRoom, meetingflow.id]);

  const handleFeature = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflow.id || !organizationSlug) return;

    const token = await getAccessTokenSilently();

    try {
      await toggleMeetingflowFeature({
        organizationSlug,
        dealRoomId,
        meetingflowId: meetingflow.id,
        token,
        queryClient,
        appInsights,
      });
    } catch (err: unknown) {
      console.error('Error featuring meetingflow:', err);
    }
  };

  const handleWelcome = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflow.id || !organizationSlug) return;

    const token = await getAccessTokenSilently();

    try {
      const newWelcomeState = await toggleMeetingflowWelcome({
        organizationSlug,
        dealRoomId,
        meetingflowId: meetingflow.id,
        token,
        queryClient,
        appInsights,
      });
      setIsArtifactInWelcome(newWelcomeState);
    } catch (err: unknown) {
      console.error('Error toggling welcome status for meetingflow:', err);
    }
  };

  const handleRemoveFromDecisionSite = async (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!meetingflow.id || !organizationSlug || !dealRoom) return;

    const token = await getAccessTokenSilently();

    try {
      await removeMeetingflowArtifactFromDecisionSite({
        organizationSlug,
        dealRoomId: dealRoom?.id,
        meetingflowId: meetingflow.id,
        token,
        queryClient,
        appInsights,
        callback: () => {
          refetchEvents?.();
          queryClient.invalidateQueries('ownedMeetingflows');
        },
      });
    } catch (err: unknown) {
      console.error('Error removing meetingflow from decision site:', err);
    }
  };

  const handleDelete = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflow.id || !organizationSlug) return;

    const token = await getAccessTokenSilently();

    try {
      await deleteMeetingflowAndDecisionSiteArtifact({
        organizationSlug,
        dealRoomId,
        meetingflowId: meetingflow.id,
        token,
        queryClient,
        appInsights,
      });
    } catch (err: unknown) {
      console.error('Error deleting meetingflow:', err);
    }
  };

  // Create a CalendarEvent-like object from the Meetingflow
  const eventData = useMemo(() => {
    return {
      title: meetingflow.title,
      startTime: meetingflow.startTime,
      endTime: meetingflow.endTime,
      meetingplanId: meetingflow.id,
      attendees: meetingflow.attendees,
      // Add any other properties needed by the child components
    } as CalendarEvent;
  }, [meetingflow]);

  return (
    <EventCardContainer
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <EventDetails
        meetingflow={meetingflow}
        eventData={eventData}
        showAddToDecisionSites={showAddToDecisionSites}
      />

      <ActionButtonsContainer>
        {showConferenceJoinButton && (
          <AnimatedIconWrapper className={isHovered ? 'visible' : ''}>
            <DSMeetingJoinButton event={eventData} />
          </AnimatedIconWrapper>
        )}
        {showRecordButton && <DSMeetingRecordButton event={eventData} />}
        {showStatusButton && (
          <DSMeetingStatusButton meetingPlanId={meetingflow.id} />
        )}
      </ActionButtonsContainer>

      {showContextualMenu && (
        <DecisionSiteMeetingflowCardContextMenu
          meetingflow={meetingflow}
          dealRoomRole={dealRoomRole}
          organizationSlug={organizationSlug}
          meetingflowId={meetingflow.id}
          isArtifactFeatured={isArtifactFeatured}
          isArtifactInWelcome={isArtifactInWelcome}
          hasEntitlement={hasEntitlement}
          getAccessTokenSilently={getAccessTokenSilently}
          handleFeature={handleFeature}
          handleWelcome={handleWelcome}
          handleRemoveFromDecisionSite={handleRemoveFromDecisionSite}
          handleDelete={handleDelete}
          client={queryClient}
        />
      )}
    </EventCardContainer>
  );
};

const EventDetails = ({
  meetingflow,
  eventData,
  showAddToDecisionSites,
}: {
  meetingflow: Meetingflow;
  eventData: CalendarEvent;
  showAddToDecisionSites: boolean;
}) => {
  const { dealRoom, dealRoomRole } = useDealRoom();
  const { slug: organizationSlug, hasEntitlement } = useOrganization();
  const dealRoomId = dealRoom?.id;
  const { getAccessTokenSilently } = useAuth0();
  const eventIsInPast = useMemo(() => {
    const now = DateTime.now();
    const endTime = DateTime.fromISO(
      meetingflow.endTime || meetingflow.startTime,
    );
    return now > endTime;
  }, [meetingflow.endTime, meetingflow.startTime]);

  return (
    <div className="event-details">
      <Typography component={'div'} className="event-title">
        {meetingflow.title}
      </Typography>

      {meetingflow?.startTime ? (
        <Stack
          direction="row"
          spacing={0.5}
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
        >
          {eventIsInPast && (
            <DSTooltip
              title="This event was in the past and can no longer be recorded"
              placement="top"
            >
              <CheckCircleIcon
                sx={{
                  color: DEALROOMS_COLORS.themeSecondary,
                  fontSize: '14px',
                }}
              />
            </DSTooltip>
          )}
          <Typography component={'span'} className="event-datetime">
            <StyledDateTime
              useRelativeDates
              dateTime={meetingflow.startTime}
              displayComponents={['date', 'time']}
              shortMonths
              includeTimeZone={true}
            />
          </Typography>

          {meetingflow.attendees && meetingflow.attendees.length > 0 && (
            <DSTooltip
              title={
                <AttendeesTooltipContent>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 600, color: 'white', mb: 1 }}
                  >
                    Attendees from Meeting
                  </Typography>
                  <AttendeesList>
                    {meetingflow.attendees.map((attendee, index) => (
                      <AttendeeItem key={index}>
                        <Typography variant="body2" sx={{ color: 'white' }}>
                          {attendee.name || attendee.email}
                        </Typography>
                      </AttendeeItem>
                    ))}
                  </AttendeesList>
                </AttendeesTooltipContent>
              }
              placement="top"
            >
              <AttendeeCountContainer>
                <People
                  sx={{
                    fontSize: '16px',
                    color: DEALROOMS_COLORS.themeSecondary,
                  }}
                />
                <Typography
                  variant="caption"
                  sx={{
                    ml: 0.5,
                    color: DEALROOMS_COLORS.themeSecondary,
                    fontWeight: 500,
                  }}
                >
                  {meetingflow.attendees.length}
                </Typography>
              </AttendeeCountContainer>
            </DSTooltip>
          )}

          {/* Use DSMeetingsAddToDecisionSites with the memoized eventData */}
          {showAddToDecisionSites && (
            <DSMeetingsAddToDecisionSites event={eventData} />
          )}
        </Stack>
      ) : null}
    </div>
  );
};

export default DSJourneyMeetingflowRow;
