import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDealRoomActivity } from '../Analytics/hooks/useDealRoomActivity';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { Edit, Delete } from '@mui/icons-material';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { CalloutCard } from './DSCalloutCards';
import toast from 'react-hot-toast';
import {
  CalloutCard as CalloutCardStyled,
  CalloutCardHeader,
  CalloutCardTitle,
  EditButton,
  EditContainer,
  DeleteButton,
  CalloutCardValue,
  CalloutCardMarkdown,
} from './DSCalloutCards.styles';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { ensureValidProtocol } from '../../../Helpers/URLHelpers';
import { useNavigate } from '../../../Hooks/useNavigate';
import { getArtifactLink } from '../utils/artifactUtils';
import remarkGfm from 'remark-gfm';
import { DSCalloutCardCreateMode } from './DSCalloutCardCreateMode';
import { DSCalloutCardEditMode } from './DSCalloutCardEditMode';

interface DSCalloutCardItemProps {
  organizationSlug: string | undefined;
  dealRoomId: number | undefined;
  card: CalloutCard;
  onDelete?: () => void;
  onSave?: (
    card: CalloutCard,
    title: string,
    description: string,
    ctaLabel: string,
    link: string | null,
    artifactId: number | null,
  ) => void;
  dragHandleProps?: React.DOMAttributes<HTMLDivElement>;
  isDragging?: boolean;
  totalCards: number;
}

export const DSCalloutCardItem = ({
  organizationSlug,
  dealRoomId,
  card,
  onDelete,
  onSave,
  dragHandleProps,
  isDragging,
  totalCards,
}: DSCalloutCardItemProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const isNew = card.id < 0; // Negative ID indicates a new card
  const [isEditing, setIsEditing] = useState(isNew);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [artifacts, setArtifacts] = useState<DealRoomArtifact[]>([]);
  const { recordActivity } = useDealRoomActivity();
  const { dealRoom, dealRoomRole } = useDealRoom();
  const navigate = useNavigate();

  // Load artifacts when component mounts
  useEffect(() => {
    const loadArtifacts = async () => {
      if (!organizationSlug || dealRoomId === undefined) return;

      try {
        const token = await getAccessTokenSilently();
        const response = await DealRoomsApiClient.listArtifacts(
          {
            organizationSlug,
            dealRoomId,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        setArtifacts(response.data);
      } catch (error) {
        console.error('Error loading artifacts:', error);
      }
    };

    loadArtifacts();
  }, [organizationSlug, dealRoomId, getAccessTokenSilently]);

  const handleDeleteCard = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      // Check if organizationSlug and dealRoomId are defined
      if (!organizationSlug || dealRoomId === undefined) {
        console.error('Organization slug or dealRoomId is undefined');
        return;
      }

      await DealRoomsApiClient.deleteCalloutCard(
        organizationSlug,
        dealRoomId,
        card.id,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (recordActivity && organizationSlug && dealRoomId && dealRoom) {
        recordActivity({
          activityType: 'DS_OVERVIEW_CLICK_CALLOUT_CARD',
          organizationSlug,
          dealRoomId: dealRoomId,
          metadata: {
            cardId: card.id,
            cardTitle: card.title,
            decisionSiteId: dealRoomId,
            decisionSiteName: dealRoom?.name,
          },
        });
      }

      toast.success('Card deleted successfully');

      // Call the onDelete callback to refresh the list
      if (onDelete) {
        onDelete();
      }
    } catch (error) {
      toast.error('Failed to delete card');
    } finally {
      setDeleteDialogOpen(false);
    }
  }, [
    getAccessTokenSilently,
    organizationSlug,
    dealRoomId,
    card.id,
    card.title,
    recordActivity,
    onDelete,
    dealRoom,
  ]);

  // Determine if the user can edit this card
  const canEdit = useMemo(() => {
    return dealRoomRole === 'SELLER';
  }, [dealRoomRole]);

  const handleEditButtonClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
  }, []);

  // Only apply drag handle props if not in edit mode
  const effectiveDragHandleProps = isEditing ? undefined : dragHandleProps;

  // Render based on mode
  if (isNew) {
    return (
      <DSCalloutCardCreateMode
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        card={card}
        onSave={(card, title, description, ctaLabel, link, artifactId) => {
          if (onSave) {
            onSave(card, title, description, ctaLabel, link, artifactId);
          }
        }}
        onCancel={() => {
          if (onDelete) {
            onDelete();
          }
        }}
        artifacts={artifacts}
      />
    );
  }

  if (isEditing) {
    return (
      <DSCalloutCardEditMode
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        card={card}
        onSave={(
          updatedCard: CalloutCard,
          title: string,
          description: string,
          ctaLabel: string,
          link: string | null,
          artifactId: number | null,
        ) => {
          setIsEditing(false);
          // Refresh the parent component by calling both onDelete and onSave
          if (onDelete) {
            onDelete();
          }
          if (onSave) {
            onSave(updatedCard, title, description, ctaLabel, link, artifactId);
          }
        }}
        onCancel={() => setIsEditing(false)}
        artifacts={artifacts}
      />
    );
  }

  // View mode
  return (
    <CalloutCardStyled
      {...effectiveDragHandleProps}
      data-draggable={
        canEdit && !isEditing && totalCards && totalCards > 1 ? 'true' : 'false'
      }
      onClick={
        !isEditing && !isNew
          ? async () => {
              if (card.artifactId) {
                try {
                  if (
                    recordActivity &&
                    organizationSlug &&
                    dealRoomId &&
                    dealRoom
                  ) {
                    recordActivity({
                      activityType: 'DS_OVERVIEW_CLICK_CALLOUT_CARD',
                      organizationSlug,
                      dealRoomId: dealRoomId,
                      metadata: {
                        cardId: card.id,
                        cardTitle: card.title,
                        artifactId: card.artifactId,
                        decisionSiteId: dealRoomId,
                        decisionSiteName: dealRoom?.name,
                      },
                    });
                  }

                  // Navigate to artifact
                  navigate(
                    `/organization/${organizationSlug}/decisionsite/${dealRoomId}/artifacts?artifact=${card.artifactId}`,
                  );
                } catch (error) {
                  console.error('Error navigating to artifact:', error);
                  toast.error('Failed to navigate to artifact');
                }
              } else if (card.link) {
                if (
                  recordActivity &&
                  organizationSlug &&
                  dealRoomId &&
                  dealRoom
                ) {
                  recordActivity({
                    activityType: 'DS_OVERVIEW_CLICK_CALLOUT_CARD',
                    organizationSlug,
                    dealRoomId: dealRoomId,
                    metadata: {
                      cardId: card.id,
                      cardTitle: card.title,
                      hasLink: true,
                      linkUrl: card.link,
                      decisionSiteId: dealRoomId,
                      decisionSiteName: dealRoom?.name,
                    },
                  });
                }

                // Open external link
                window.open(card.link, '_blank');
              }
            }
          : undefined
      }
      sx={{
        cursor:
          !isEditing && !isNew && (card.artifactId || card.link)
            ? 'pointer'
            : 'default',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <CalloutCardTitle
          variant="subtitle2"
          sx={{
            position: 'relative',
          }}
        >
          {card.title}
        </CalloutCardTitle>

        {canEdit && !isNew && !isEditing && (
          <EditContainer className="action-buttons">
            <EditButton onClick={handleEditButtonClick} isActive={isEditing}>
              <Edit fontSize="small" />
            </EditButton>
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                setDeleteDialogOpen(true);
              }}
              isActive={deleteDialogOpen}
            >
              <Delete fontSize="small" />
            </DeleteButton>
          </EditContainer>
        )}
      </Box>

      <CalloutCardValue
        variant="body1"
        isLongText={card.description?.length > 12}
        sx={{
          width: '100%',
          marginTop: '0.5rem',
          position: 'relative',
        }}
      >
        {card.description ? (
          <CalloutCardMarkdown remarkPlugins={[remarkGfm]}>
            {card.description}
          </CalloutCardMarkdown>
        ) : (
          'N/A'
        )}
      </CalloutCardValue>

      {/* CTA Button (only shown in view mode) */}
      {card.ctaLabel && (
        <Box sx={{ marginTop: '1rem' }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={async (e) => {
              e.stopPropagation();
              if (card.artifactId) {
                try {
                  if (
                    recordActivity &&
                    organizationSlug &&
                    dealRoomId &&
                    dealRoom
                  ) {
                    recordActivity({
                      activityType: 'DS_OVERVIEW_CLICK_CALLOUT_CARD',
                      organizationSlug,
                      dealRoomId: dealRoomId,
                      metadata: {
                        cardId: card.id,
                        cardTitle: card.title,
                        artifactId: card.artifactId,
                        decisionSiteId: dealRoomId,
                        decisionSiteName: dealRoom?.name,
                      },
                    });
                  }

                  // Navigate to artifact
                  navigate(
                    `/organization/${organizationSlug}/decisionsite/${dealRoomId}/artifacts?artifact=${card.artifactId}`,
                  );
                } catch (error) {
                  console.error('Error navigating to artifact:', error);
                  toast.error('Failed to navigate to artifact');
                }
              } else if (card.link) {
                if (
                  recordActivity &&
                  organizationSlug &&
                  dealRoomId &&
                  dealRoom
                ) {
                  recordActivity({
                    activityType: 'DS_OVERVIEW_CLICK_CALLOUT_CARD',
                    organizationSlug,
                    dealRoomId: dealRoomId,
                    metadata: {
                      cardId: card.id,
                      cardTitle: card.title,
                      hasLink: true,
                      linkUrl: card.link,
                      decisionSiteId: dealRoomId,
                      decisionSiteName: dealRoom?.name,
                    },
                  });
                }

                // Open external link
                window.open(card.link, '_blank');
              }
            }}
            sx={{
              textTransform: 'none',
              marginTop: 'auto',
            }}
          >
            {card.ctaLabel}
          </Button>
        </Box>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Card</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete "{card.title}"? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteCard} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </CalloutCardStyled>
  );
};
