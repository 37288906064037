import React from 'react';
import {
  DealRoomArtifact,
  UserActivity,
  UserActivityType,
} from '@meetingflow/common/Api/data-contracts';
import { DSTooltip } from '../DS';
import {
  Upload,
  Visibility,
  Share,
  Star,
  StarOutline,
  ThumbUp,
  ThumbDown,
  Delete,
  Restore,
  Event,
  Edit,
  Assignment,
  CheckCircle,
  PersonAdd,
  PersonRemove,
  Comment,
  Create,
  Description,
  Videocam,
  VideocamOff,
  Schedule,
  Cancel,
  Home,
  Inbox,
  Collections,
  Timeline,
  Settings,
  Build,
  Analytics,
  CallToAction,
  Article,
  EditNote,
  AddCircle,
  SmartToy,
} from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import {
  ArtifactLink,
  HighlightedText,
} from './DSSellerHubActivityTable.styles';

interface ActivityRendererProps {
  activity: UserActivity;
  organizationSlug?: string;
  dealRoomId?: string;
  allArtifacts?: DealRoomArtifact[];
  allActionItemIds?: number[];
}

interface ActivityRenderer {
  label: string;
  renderer: (props: ActivityRendererProps) => React.ReactNode;
}

const renderActivityIcon = (icon: React.ReactElement) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'action.active',
      mr: 1,
      '& .MuiSvgIcon-root': {
        fontSize: '16px',
      },
    }}
  >
    {icon}
  </Box>
);

const renderActivityLayout = (
  icon: React.ReactElement,
  text: React.ReactNode,
) => (
  <Box
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
  >
    {icon}
    <Box
      component="span"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 0.5,
        flexWrap: 'wrap',
        ml: 0,
      }}
    >
      <Typography variant="caption" color="text.primary">
        {text}
      </Typography>
    </Box>
  </Box>
);

const renderArtifactLink = ({
  activity,
  organizationSlug,
  dealRoomId,
  allArtifacts,
}: ActivityRendererProps) => {
  const artifactId = activity.additionalDetails?.artifactId;
  if (!artifactId) return null;

  const artifact = allArtifacts?.find((a) => a.id === artifactId);

  if (!artifact) {
    let label = 'a deleted artifact';
    if (activity.additionalDetails?.name) {
      label = label + ' - ' + activity.additionalDetails?.name;
    }
    return <>{label}</>;
  }

  if (activity.additionalDetails.artifactType === 'MEETINGFLOW') {
    return (
      <ArtifactLink
        to={`/organization/${organizationSlug || 'augment-ai'}/decisionsite/${dealRoomId}/journey?meeting=${'meetingflowId' in artifact ? artifact.meetingflowId : ''}`}
      >
        {artifact.name || 'Untitled'}
      </ArtifactLink>
    );
  }

  return (
    <ArtifactLink
      to={`/organization/${organizationSlug || 'augment-ai'}/decisionsite/${dealRoomId}/artifact/${artifact.id}`}
    >
      {artifact.name || 'Untitled'}
    </ArtifactLink>
  );
};

const renderMeetingflowActivityIcon = () =>
  renderActivityIcon(<Event fontSize="small" />);

export const ANALYTICS_TYPES: Partial<
  Record<UserActivityType, ActivityRenderer>
> = {
  UPLOAD_ARTIFACT: {
    label: 'Upload Artifact',
    renderer: ({
      activity,
      organizationSlug,
      dealRoomId,
      allArtifacts,
    }: ActivityRendererProps) => {
      const isMeetingflow =
        activity.additionalDetails?.artifactType === 'MEETINGFLOW';
      const icon = isMeetingflow
        ? renderMeetingflowActivityIcon()
        : renderActivityIcon(<Upload fontSize="small" />);
      return renderActivityLayout(
        icon,
        <>
          <Typography variant="caption" color="text.secondary">
            Added {isMeetingflow ? 'a Meeting' : 'an Artifact'}
          </Typography>{' '}
          {renderArtifactLink({
            activity,
            organizationSlug,
            dealRoomId,
            allArtifacts,
          })}
        </>,
      );
    },
  },
  VIEW_ARTIFACT: {
    label: 'View Artifact',
    renderer: ({
      activity,
      organizationSlug,
      dealRoomId,
      allArtifacts,
    }: ActivityRendererProps) => {
      const isMeetingflow =
        activity.additionalDetails?.artifactType === 'MEETINGFLOW';
      const icon = isMeetingflow
        ? renderMeetingflowActivityIcon()
        : renderActivityIcon(<Visibility fontSize="small" />);
      return renderActivityLayout(
        icon,
        <>
          <Typography variant="caption" color="text.secondary">
            Viewed {isMeetingflow ? 'a Meeting' : 'an Artifact'}
          </Typography>{' '}
          {renderArtifactLink({
            activity,
            organizationSlug,
            dealRoomId,
            allArtifacts,
          })}
        </>,
      );
    },
  },
  FEATURE_ARTIFACT: {
    label: 'Feature Artifact',
    renderer: ({
      activity,
      organizationSlug,
      dealRoomId,
      allArtifacts,
    }: ActivityRendererProps) => {
      const isMeetingflow =
        activity.additionalDetails?.artifactType === 'MEETINGFLOW';
      const icon = isMeetingflow
        ? renderMeetingflowActivityIcon()
        : renderActivityIcon(<Star fontSize="small" />);
      return renderActivityLayout(
        icon,
        <>
          <Typography variant="caption" color="text.secondary">
            Featured {isMeetingflow ? 'a Meeting' : 'an Artifact'}
          </Typography>{' '}
          {renderArtifactLink({
            activity,
            organizationSlug,
            dealRoomId,
            allArtifacts,
          })}
        </>,
      );
    },
  },
  UNFEATURE_ARTIFACT: {
    label: 'Unfeature Artifact',
    renderer: ({
      activity,
      organizationSlug,
      dealRoomId,
      allArtifacts,
    }: ActivityRendererProps) => {
      const isMeetingflow =
        activity.additionalDetails?.artifactType === 'MEETINGFLOW';
      const icon = isMeetingflow
        ? renderMeetingflowActivityIcon()
        : renderActivityIcon(<StarOutline fontSize="small" />);
      return renderActivityLayout(
        icon,
        <>
          <Typography variant="caption" color="text.secondary">
            Unfeatured {isMeetingflow ? 'a Meeting' : 'an Artifact'}
          </Typography>{' '}
          {renderArtifactLink({
            activity,
            organizationSlug,
            dealRoomId,
            allArtifacts,
          })}
        </>,
      );
    },
  },
  UPVOTE_ARTIFACT: {
    label: 'Upvote Artifact',
    renderer: ({
      activity,
      organizationSlug,
      dealRoomId,
      allArtifacts,
    }: ActivityRendererProps) => {
      const isMeetingflow =
        activity.additionalDetails?.artifactType === 'MEETINGFLOW';
      const icon = isMeetingflow
        ? renderMeetingflowActivityIcon()
        : renderActivityIcon(<ThumbUp fontSize="small" />);
      return renderActivityLayout(
        icon,
        <>
          <Typography variant="caption" color="text.secondary">
            Upvoted {isMeetingflow ? 'a Meeting' : 'an Artifact'}
          </Typography>{' '}
          {renderArtifactLink({
            activity,
            organizationSlug,
            dealRoomId,
            allArtifacts,
          })}
        </>,
      );
    },
  },
  DOWNVOTE_ARTIFACT: {
    label: 'Downvote Artifact',
    renderer: ({
      activity,
      organizationSlug,
      dealRoomId,
      allArtifacts,
    }: ActivityRendererProps) => {
      const isMeetingflow =
        activity.additionalDetails?.artifactType === 'MEETINGFLOW';
      const icon = isMeetingflow
        ? renderMeetingflowActivityIcon()
        : renderActivityIcon(<ThumbDown fontSize="small" />);
      return renderActivityLayout(
        icon,
        <>
          <Typography variant="caption" color="text.secondary">
            Downvoted {isMeetingflow ? 'a Meeting' : 'an Artifact'}
          </Typography>{' '}
          {renderArtifactLink({
            activity,
            organizationSlug,
            dealRoomId,
            allArtifacts,
          })}
        </>,
      );
    },
  },
  DELETE_ARTIFACT: {
    label: 'Delete Artifact',
    renderer: ({ activity }: ActivityRendererProps) => {
      const isSoftDelete = activity.additionalDetails?.soft;
      let label = 'Permanently trashed an artifact';
      if (isSoftDelete) {
        label = 'Trashed an artifact';
      }

      if (activity.additionalDetails?.name) {
        label = label + ' - ' + activity.additionalDetails?.name;
      }
      return renderActivityLayout(
        renderActivityIcon(<Delete fontSize="small" />),
        <>{label}</>,
      );
    },
  },
  UNDELETE_ARTIFACT: {
    label: 'Restored an artifact',
    renderer: ({
      activity,
      organizationSlug,
      dealRoomId,
      allArtifacts,
    }: ActivityRendererProps) => {
      const isMeetingflow =
        activity.additionalDetails?.artifactType === 'MEETINGFLOW';
      const icon = isMeetingflow
        ? renderMeetingflowActivityIcon()
        : renderActivityIcon(<Restore fontSize="small" />);
      return renderActivityLayout(
        icon,
        <>
          <Typography variant="caption" color="text.secondary">
            Restored {isMeetingflow ? 'a Meeting' : 'an Artifact'}
          </Typography>{' '}
          {renderArtifactLink({
            activity,
            organizationSlug,
            dealRoomId,
            allArtifacts,
          })}
        </>,
      );
    },
  },
  CREATE_DEAL_ROOM: {
    label: 'Create Deal Room',
    renderer: () =>
      renderActivityLayout(
        renderActivityIcon(<Create fontSize="small" />),
        <>Created this Decision Site</>,
      ),
  },
  ADD_DEAL_ROOM_CONTACT: {
    label: 'Share Decision Site',
    renderer: ({ activity }) => {
      // needed to check both fields for backwards compatability
      const email =
        activity.additionalDetails?.dealRoomEmail ||
        activity.additionalDetails?.contactEmail;

      return renderActivityLayout(
        renderActivityIcon(<PersonAdd fontSize="small" />),
        <>
          <Typography variant="caption" color="text.secondary">
            Shared this Decision Site with{' '}
          </Typography>
          <HighlightedText>{email}</HighlightedText>
        </>,
      );
    },
  },
  UPDATE_DEAL_ROOM_CONTACT: {
    label: 'Updated Preferences',
    renderer: ({ activity }) => {
      // Convert the changes object into a readable format
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const readableChanges = (obj: Record<string, any>): string => {
        if (!obj) return '';

        return Object.entries(obj)
          .filter(([key, value]) => value !== undefined)
          .map(([key, value]) => {
            switch (key) {
              case 'communicationPreference':
                return `preferred communication method to ${value}`;
              case 'consumptionPreference':
                return `content format preference to ${value}`;
              case 'assistancePreference':
                return `assistance level to ${value}`;
              case 'phoneNumber':
                return `phone number to ${value}`;
              case 'priorities':
                return `priorities to ${Array.isArray(value) ? value.join(', ') : value}`;
              case 'role':
                return `role to ${value}`;
              case 'hasWatchedWelcomeVideo':
                return value
                  ? 'completed watching welcome video'
                  : 'unwatched welcome video';
              case 'hasCompletedBuyerOrientation':
                return value
                  ? 'completed buyer orientation'
                  : 'incomplete buyer orientation';
              case 'fields':
                return readableChanges(value); // needed for now for backwards compatability
              default:
                return undefined;
            }
          })
          .filter(Boolean)
          .join(', ');
      };

      // needed to check both fields for backwards compatability
      const email =
        activity.additionalDetails?.dealRoomEmail ||
        activity.additionalDetails?.contactEmail;

      return renderActivityLayout(
        renderActivityIcon(<Assignment fontSize="small" />),
        <>
          <Typography variant="caption" color="text.secondary">
            Updated <HighlightedText>{email}</HighlightedText>
          </Typography>{' '}
          {readableChanges(activity.additionalDetails)}
        </>,
      );
    },
  },
  REMOVE_DEAL_ROOM_CONTACT: {
    label: 'Unshare Decision Site',
    renderer: ({ activity }) => {
      // needed to check both fields for backwards compatability
      const email =
        activity.additionalDetails?.dealRoomEmail ||
        activity.additionalDetails?.contactEmail;

      return renderActivityLayout(
        renderActivityIcon(<PersonRemove fontSize="small" />),
        <>
          <Typography variant="caption" color="text.secondary">
            Unshared this Decision Site with{' '}
          </Typography>{' '}
          <HighlightedText>{email}</HighlightedText>
        </>,
      );
    },
  },
  VIEW_DEAL_ROOM: {
    label: 'Viewed Decision Site',
    renderer: ({ activity }) =>
      renderActivityLayout(
        renderActivityIcon(<Visibility fontSize="small" />),
        <Typography variant="caption" color="text.primary">
          Viewed Decision Site
        </Typography>,
      ),
  },
  VIEW_PLAN: {
    label: 'Viewed Meeting Plan',
    renderer: ({ activity }) => {
      return renderActivityLayout(
        renderActivityIcon(<Visibility fontSize="small" />),
        <Typography variant="caption" color="text.secondary">
          Viewed a Meeting
        </Typography>,
      );
    },
  },
  SCHEDULE_CALL_RECORDING: {
    label: 'Scheduled Call Recording',
    renderer: ({ activity }) => {
      return renderActivityLayout(
        renderActivityIcon(<Schedule fontSize="small" />),
        <Typography variant="caption" color="text.secondary">
          Scheduled a Call Recording
        </Typography>,
      );
    },
  },
  CANCEL_SCHEDULED_CALL_RECORDING: {
    label: 'Cancelled Scheduled Call Recording',
    renderer: ({ activity }) => {
      return renderActivityLayout(
        renderActivityIcon(<Cancel fontSize="small" />),
        <Typography variant="caption" color="text.secondary">
          Cancelled a Scheduled Recording
        </Typography>,
      );
    },
  },
  START_CALL_RECORDING: {
    label: 'Started Call Recording',
    renderer: ({ activity }) => {
      return renderActivityLayout(
        renderActivityIcon(<Videocam fontSize="small" />),
        <Typography variant="caption" color="text.secondary">
          Started Recording a Call
        </Typography>,
      );
    },
  },
  STOP_CALL_RECORDING: {
    label: 'Stopped Call Recording',
    renderer: ({ activity }) => {
      return renderActivityLayout(
        renderActivityIcon(<VideocamOff fontSize="small" />),
        <Typography variant="caption" color="text.secondary">
          Stopped Recording a Call
        </Typography>,
      );
    },
  },
  DS_NAV_CLICK_OVERVIEW: {
    label: 'Viewed the Overview page',
    renderer: () =>
      renderActivityLayout(
        renderActivityIcon(<Home />),
        'Viewed the Overview page',
      ),
  },
  DS_NAV_CLICK_INBOX: {
    label: 'Viewed the Inbox page',
    renderer: () =>
      renderActivityLayout(renderActivityIcon(<Inbox />), 'Viewed the Inbox'),
  },
  DS_NAV_CLICK_ARTIFACTS: {
    label: 'Viewed the Artifacts page',
    renderer: () =>
      renderActivityLayout(
        renderActivityIcon(<Collections />),
        'Viewed the Artifacts page',
      ),
  },
  DS_NAV_CLICK_JOURNEY: {
    label: 'Viewed the Journey page',
    renderer: () =>
      renderActivityLayout(
        renderActivityIcon(<Timeline />),
        'Viewed the Journey page',
      ),
  },
  DS_NAV_CLICK_MUTUAL_PLAN: {
    label: 'Viewed the Mutual Plan page',
    renderer: () =>
      renderActivityLayout(
        renderActivityIcon(<Assignment />),
        'Viewed the Mutual Plan',
      ),
  },
  DS_NAV_CLICK_SETTINGS: {
    label: 'Viewed Settings page',
    renderer: () =>
      renderActivityLayout(renderActivityIcon(<Settings />), 'Viewed Settings'),
  },
  DS_NAV_CLICK_ANALYTICS: {
    label: 'Viewed Analytics page',
    renderer: () =>
      renderActivityLayout(
        renderActivityIcon(<Analytics />),
        'Viewed Analytics',
      ),
  },
  DS_OVERVIEW_CLICK_CALLOUT_CARD: {
    label: 'Clicked on callout card',
    renderer: ({ activity }) =>
      renderActivityLayout(
        renderActivityIcon(<CallToAction />),
        <>
          Clicked on callout card{' '}
          <HighlightedText>
            {activity.additionalDetails?.cardTitle || 'Unknown Card'}
          </HighlightedText>
        </>,
      ),
  },
  DS_OVERVIEW_CLICK_FEATURED_ARTIFACT: {
    label: 'Clicked on featured artifact',
    renderer: ({ activity, organizationSlug, dealRoomId, allArtifacts }) =>
      renderActivityLayout(
        renderActivityIcon(<Article />),
        <>
          Clicked on featured artifact{' '}
          {renderArtifactLink({
            activity,
            organizationSlug,
            dealRoomId,
            allArtifacts,
          })}
        </>,
      ),
  },
  DS_OVERVIEW_CLICK_KEY_DETAIL_EDIT: {
    label: 'Clicked to edit key detail',
    renderer: ({ activity }) =>
      renderActivityLayout(
        renderActivityIcon(<EditNote />),
        <>
          Clicked to edit key detail{' '}
          <HighlightedText>
            {activity.additionalDetails?.keyFactTitle || 'Unknown Detail'}
          </HighlightedText>
        </>,
      ),
  },
  DS_ARTIFACTS_CLICK_ADD_ARTIFACT: {
    label: 'Clicked Add Artifact',
    renderer: () =>
      renderActivityLayout(
        renderActivityIcon(<AddCircle />),
        'Clicked Add Artifact',
      ),
  },
  DS_ARTIFACTS_ASK_AI: {
    label: 'Asked AI',
    renderer: ({ activity }) =>
      renderActivityLayout(
        renderActivityIcon(<SmartToy />),
        <>
          Asked AI:{' '}
          <HighlightedText>
            {activity.additionalDetails?.question || 'Unknown Question'}
          </HighlightedText>
          {activity.additionalDetails?.questionWords && (
            <Typography
              variant="caption"
              sx={{ ml: 1, color: 'text.secondary' }}
            >
              ({activity.additionalDetails.questionWords} words)
            </Typography>
          )}
        </>,
      ),
  },
  DELETE_MUTUAL_ACTION_ITEM: {
    label: 'Delete Mutual Plan Task',
    renderer: () =>
      renderActivityLayout(
        renderActivityIcon(<Delete fontSize="small" />),
        'Deleted a mutual plan task',
      ),
  },
  ADD_MUTUAL_ACTION_ITEM: {
    label: 'Add Mutual Plan Task',
    renderer: ({
      activity,
      organizationSlug,
      dealRoomId,
      allActionItemIds,
    }) => {
      const actionItemId = activity.additionalDetails?.dealRoomActionItemId;
      const isActionItemActive =
        actionItemId && allActionItemIds?.includes(Number(actionItemId));

      return renderActivityLayout(
        renderActivityIcon(<AddCircle fontSize="small" />),
        isActionItemActive ? (
          <ArtifactLink
            to={`/organization/${organizationSlug || 'augment-ai'}/decisionsite/${dealRoomId}/mutual-action-plan?task=${actionItemId}`}
          >
            Added a Mutual Plan task
          </ArtifactLink>
        ) : (
          'Added a deleted Mutual Plan task'
        ),
      );
    },
  },
  UPDATE_MUTUAL_ACTION_ITEM: {
    label: 'Update Mutual Plan Task',
    renderer: ({
      activity,
      organizationSlug,
      dealRoomId,
      allActionItemIds,
    }) => {
      const actionItemId = activity.additionalDetails?.dealRoomActionItemId;
      const isActionItemActive =
        actionItemId && allActionItemIds?.includes(Number(actionItemId));

      return renderActivityLayout(
        renderActivityIcon(<EditNote fontSize="small" />),
        isActionItemActive ? (
          <ArtifactLink
            to={`/organization/${organizationSlug || 'augment-ai'}/decisionsite/${dealRoomId}/mutual-action-plan?task=${actionItemId}`}
          >
            Updated a Mutual Plan task
          </ArtifactLink>
        ) : (
          'Updated a deleted Mutual Plan task'
        ),
      );
    },
  },
  REQUEST_ARTIFACT_ACTION_ITEM: {
    label: 'Create a Task to Request an Artifact',
    renderer: ({
      activity,
      organizationSlug,
      dealRoomId,
      allActionItemIds,
    }) => {
      const actionItemId = activity.additionalDetails?.dealRoomActionItemId;
      const isActionItemActive =
        actionItemId && allActionItemIds?.includes(Number(actionItemId));

      return renderActivityLayout(
        renderActivityIcon(<AddCircle fontSize="small" />),
        isActionItemActive ? (
          <ArtifactLink
            to={`/organization/${organizationSlug || 'augment-ai'}/decisionsite/${dealRoomId}/mutual-action-plan?task=${actionItemId}`}
          >
            Created a Mutual Plan task to request an artifact
          </ArtifactLink>
        ) : (
          'Created a deleted Mutual Plan task to request an artifact'
        ),
      );
    },
  },
  UPDATE_DEAL_ROOM: {
    label: 'Update Decision Site',
    renderer: () =>
      renderActivityLayout(
        renderActivityIcon(<Edit fontSize="small" />),
        'Updated Decision Site settings',
      ),
  },
  VIEW_MEETING: {
    label: 'Viewed Meeting',
    renderer: ({ activity }) =>
      renderActivityLayout(
        renderMeetingflowActivityIcon(),
        <>
          <Typography variant="caption" color="text.secondary">
            Viewed Meeting
          </Typography>
          {activity.additionalDetails?.meetingName && (
            <Box component="span" sx={{ ml: 0.5 }}>
              {activity.additionalDetails.meetingName}
            </Box>
          )}
        </>,
      ),
  },
  UPDATE_KEY_DETAILS: {
    label: 'Edited Key Detail',
    renderer: ({ activity }) =>
      renderActivityLayout(
        renderActivityIcon(<EditNote fontSize="medium" />),
        <>
          Edited key detail{' '}
          {activity.additionalDetails?.keyFactTitle && (
            <Box component="span" sx={{ ml: 0.5 }}>
              {activity.additionalDetails.keyFactTitle}
            </Box>
          )}
        </>,
      ),
  },
  MAKE_WELCOME_ARTIFACT: {
    label: 'Add Welcome Artifact',
    renderer: ({ activity, organizationSlug, dealRoomId, allArtifacts }) => {
      const { additionalDetails } = activity;
      const icon = additionalDetails.artifactType === 'VIDEO' 
        ? <Videocam fontSize="small" />
        : <AddCircle fontSize="small" />;
      
      return renderActivityLayout(
        renderActivityIcon(icon),
        <>
          Added{' '}
          {renderArtifactLink({
            activity,
            organizationSlug,
            dealRoomId,
            allArtifacts,
          })}{' '}
          to the Welcome section
        </>,
      );
    },
  },
  UNMAKE_WELCOME_ARTIFACT: {
    label: 'Remove Welcome Artifact',
    renderer: ({ activity, organizationSlug, dealRoomId, allArtifacts }) => {
      const { additionalDetails } = activity;
      const icon = additionalDetails.artifactType === 'VIDEO' 
        ? <Videocam fontSize="small" />
        : <Cancel fontSize="small" />;
      
      return renderActivityLayout(
        renderActivityIcon(icon),
        <>
          Removed{' '}
          {renderArtifactLink({
            activity,
            organizationSlug,
            dealRoomId,
            allArtifacts,
          })}{' '}
          from the Welcome section
        </>,
      );
    },
  },
};
