import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useEffect, useLayoutEffect, useState, useMemo } from 'react';
import Bugsnag from '@bugsnag/js';
import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import {
  Route,
  Routes,
  useSearchParams,
  useNavigate,
  useParams,
  Navigate,
} from 'react-router-dom';
import { AdminBillingList } from './Components/AdminBillingList';
import { AdminEmailPreview } from './Components/AdminEmailPreview';
import { AdminUsageList } from './Components/AdminUsageList';
import { AdminUsageSummaryList } from './Components/AdminUsageSummaryList';
import AuthCallback from './Components/AuthCallback';
import { DealRoomRedirect, Redirect } from './Components/Common/Redirect';
import { DealRoomDetailView } from './Components/DealRoom/DealRoomDetailView';
import { DSRoot } from './Components/DealRoom/DSRoot';
import EventLookup from './Components/EventLookup';
import { Home } from './Components/Home';
import { ConfigureHubSpotIntegration } from './Components/Integrations/HubSpot/ConfigureHubSpotIntegration';
import { ConfigureSalesforceIntegration } from './Components/Integrations/Salesforce/ConfigureSalesforceIntegration';
import { BaseLayout } from './Components/Layouts/BaseLayout';
import MeetingPlanAutoCreate from './Components/MeetingPlans/MeetingPlanAutoCreate';
import { MeetingPlanRoot } from './Components/MeetingPlans/MeetingPlanRoot';
import { NotFound } from './Components/NotFound';
import { DSGlobalSidebarProvider } from './Context/DSGlobalSidebarContext';
import { CreateOrganization } from './Components/Organization/CreateOrganization';
import { PlansPane } from './Components/Organization/Home/PlansPane';
import { HubSpotPipelineView } from './Components/Organization/HubspotPipelineView';
import CompanyBrowser from './Components/Organization/Library/CompanyBrowser';
import ContactBrowser from './Components/Organization/Library/ContactBrowser';
import TemplateEditor from './Components/Organization/Library/Templates/TemplateEditor';
import { OrganizationHome } from './Components/Organization/OrganizationHome';
import OrganizationInviteMember from './Components/Organization/OrganizationInviteMember';
import OrganizationInvites from './Components/Organization/OrganizationInvites';
import OrganizationRequests from './Components/Organization/OrganizationRequests';
import { OrganizationRoot } from './Components/Organization/OrganizationRoot';
import { UsageReport } from './Components/Organization/OrganizationUsageReport';
import { SalesforcePipelineView } from './Components/Organization/SalesforcePipelineView';
import { Search } from './Components/Organization/Search';
import OrganizationSettings from './Components/Organization/Settings/OrganizationSettings';
import { LayoutTestPage } from './Components/Pages/LayoutTestPage';
import { Profile } from './Components/Profile';
import { StyledSpinner } from './Components/StyledSpinner';
import { UserInvites } from './Components/UserInvites';
import WelcomeWizard from './Components/Welcome/WelcomeWizard';
import { DISMISSED_HAPPENING_SOON_EVENT_IDS } from './Constants';
import {
  isConsentRequiredError,
  isGenericError,
  isLoginRequiredError,
  isUnknownOrInvalidRefreshToken,
} from './Helpers/Auth0Helpers';
import { isAxiosErrorResponse } from './Helpers/AxiosHelpers';
import { deleteValue } from './Helpers/SearchParamHelpers';
import { useInteractionMode } from './Hooks/useInteractionMode';
import { useLightOrDarkMode } from './Hooks/useLightOrDarkMode';
import { useLocalStorageState } from './Hooks/useLocalStorageState';
import { useOrganization } from './Hooks/useOrganization';
import { useUserProfile } from './Hooks/useProfile';
import { InvitesQuery, OrganizationsQuery } from './QueryNames';
import {
  InvitesApiClient,
  OrganizationsApiClient,
} from './Services/NetworkCommon';
import { AdminMeetingflowCopy } from './Components/AdminMeetingflowCopy';

export type AppProps = {
  appInsights: ApplicationInsights;
};

export const App = ({ appInsights }: AppProps) => {
  const {
    user,
    isLoading: isUserAuthLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, name, given_name, family_name, phone_number, sub } = user!;

  const {
    organization,
    slug: organizationSlug,
    refetch: refetchOrganization,
    forbidden,
    role: userRole,
    canCreatePlans,
    isGuest,
  } = useOrganization();

  const {
    user: userProfile,
    isLoading: isUserProfileLoading,
    isFetched: isUserProfileFetched,
    refetch: refetchUser,
  } = useUserProfile();

  // useLayoutEffect to set user context as early as possible
  useLayoutEffect(() => {
    if (!email || !userProfile || !organization) {
      return;
    }

    appInsights.setAuthenticatedUserContext(
      email.toLowerCase(),
      organization.slug,
      true,
    );

    Bugsnag.setUser(userProfile.id?.toString() || sub, email, name);
    Bugsnag.addMetadata('organization', {
      id: organization.id,
      slug: organization.slug,
    });

    // Appears userId can not be an email address in GA4
    // https://support.google.com/analytics/answer/9267744?hl=en
    ReactGA.set({
      userId: userProfile.id?.toString() || sub,
      contentGroup1: organization.slug,
    });
  }, [
    appInsights,
    email,
    family_name,
    given_name,
    name,
    phone_number,
    sub,
    userProfile,
    organization,
  ]);

  const client = useQueryClient();

  const [lastSessionId, setLastSessionId] = useLocalStorageState<string>(
    'LAST_SESSION_ID',
    '',
  );
  const sessionId = appInsights.context.getSessionId();
  const { currentTheme } = useLightOrDarkMode();

  // URL parameters and invite handling state
  const [searchParams, setSearchParams] = useSearchParams();
  const [acceptingInvite, setAcceptingInvite] = useState(false);

  // Determine if device supports touch input
  const { supportsTouch } = useInteractionMode();

  // State for dismissed notifications
  const [, setDismissedHappeningSoonEventIds] = useLocalStorageState<string[]>(
    DISMISSED_HAPPENING_SOON_EVENT_IDS,
    [],
  );

  // invites this user has received
  const {
    data: pendingInvites,
    isLoading: arePendingInvitesLoading,
    isFetched: arePendingInvitesFetched,
    refetch: refetchPendingInvites,
  } = useQuery(InvitesQuery('RECEIVED', organizationSlug), async () => {
    const token = await getAccessTokenSilently();
    return InvitesApiClient.getInvites(
      { type: 'RECEIVED', organization: organizationSlug },
      { headers: { Authorization: `Bearer ${token}` } },
    );
  });

  // organizations this user is a part of
  const {
    data: organizations,
    isLoading: areOrganizationsLoading,
    isFetched: areOrganizationsFetched,
    refetch: refetchOrganizations,
  } = useQuery(OrganizationsQuery, async () => {
    const token = await getAccessTokenSilently();
    return OrganizationsApiClient.listOrganizations(
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  });

  // Reset dismissed notifications on mount
  useEffect(() => {
    setDismissedHappeningSoonEventIds([], true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle authentication token refresh on tab focus
  useEffect(() => {
    // On tab focus, try and get an Auth0 token silently
    // If the user has left the tab open for a prolonged period
    // The session may have expired, causing a login_required, or consent_required error
    // If so, trigger loginWithRedirect for interactive refresh to get new tokens
    const onFocus = () => {
      getAccessTokenSilently().catch((err: unknown) => {
        if (isLoginRequiredError(err) || isConsentRequiredError(err)) {
          loginWithRedirect();
        } else if (isUnknownOrInvalidRefreshToken(err)) {
          appInsights.trackEvent({ name: 'UNKNOWN_OR_INVALID_REFRESH_TOKEN' });
          loginWithRedirect();
        } else if (isGenericError(err)) {
          appInsights.trackEvent({
            name: 'AUTH0_GENERIC_ERROR',
            properties: {
              ...err,
            },
          });
          loginWithRedirect();
        } else {
          appInsights.trackException({
            exception: err instanceof Error ? err : new Error(`${err}`),
          });
          throw err;
        }
      });
    };

    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [appInsights, getAccessTokenSilently, loginWithRedirect]);

  // Handle session tracking and analytics
  useEffect(() => {
    if (sessionId !== lastSessionId) {
      setLastSessionId(sessionId);
    }

    // TODO - not sure if I got the ternary flipped here
    appInsights.trackEvent({
      name: sessionId === lastSessionId ? 'SESSION_START' : 'BROWSER_REFRESH',
      properties: {
        sub: user?.sub,
        name: user?.name,
        email: user?.email,
        theme: currentTheme,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        nativeWidth: window.screen.width * window.devicePixelRatio,
        nativeHeight: window.screen.height * window.devicePixelRatio,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        supportsTouch: !!supportsTouch,
        utmSource: searchParams.get('utm_source') ?? undefined,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  // Auto-accept organization invites from URL parameters
  useLayoutEffect(() => {
    const inviteId = searchParams.get('inviteId');
    const invite =
      !!inviteId &&
      pendingInvites?.data?.find(
        (pendingInvite) => pendingInvite.id === inviteId,
      );

    if (acceptingInvite || !invite) {
      return;
    }

    setAcceptingInvite(true);

    getAccessTokenSilently()
      .then(async (token) => {
        try {
          await InvitesApiClient.acceptInvite(invite.id, {
            headers: { Authorization: `Bearer ${token}` },
          });

          refetchUser();
          refetchOrganizations();
          refetchPendingInvites();
          refetchOrganization();

          appInsights.trackEvent({
            name: 'AUTO_ACCEPT_INVITE',
            properties: {
              inviteId,
              organizationId: organization?.id,
              utmSource: searchParams.get('utm_source') ?? undefined,
            },
          });

          setSearchParams(deleteValue(searchParams, 'inviteId'));
        } catch (err) {
          appInsights.trackEvent({
            name: 'AUTO_ACCEPT_INVITE_FAILED',
            properties: {
              inviteId,
              organizationId: organization?.id,
              utmSource: searchParams.get('utm_source') ?? undefined,
              status: isAxiosErrorResponse(err)
                ? err.response?.status
                : undefined,
              statusText: isAxiosErrorResponse(err)
                ? err.response?.statusText
                : undefined,
            },
          });
        }
      })
      .finally(() => {
        setAcceptingInvite(false);
      });
  }, [
    acceptingInvite,
    appInsights,
    client,
    getAccessTokenSilently,
    pendingInvites?.data,
    organization?.id,
    organizationSlug,
    refetchPendingInvites,
    refetchOrganization,
    refetchOrganizations,
    refetchUser,
    searchParams,
    setSearchParams,
  ]);

  // Show loading spinner while fetching initial data
  if (
    isUserAuthLoading ||
    (isUserProfileLoading && !isUserProfileFetched) ||
    (areOrganizationsLoading && !areOrganizationsFetched) ||
    (arePendingInvitesLoading && !arePendingInvitesFetched) ||
    acceptingInvite
  ) {
    return <StyledSpinner />;
  }

  if (!userProfile || !organizations || !organizations.data) {
    return <StyledSpinner />;
  }

  // Determine if welcome wizard should be shown based on user state
  const didUserAcceptTOS = !!userProfile.tosAccepted;
  const didUserSetTimezone = !!userProfile.timezone;
  const doesUserHaveOrganizations = !!organizations.data.length;
  const doesUserHavePendingInvites = !!pendingInvites?.data?.length;

  const isUserInOrganization = !!organizationSlug;
  const isUserForbiddenFromOrganization = isUserInOrganization && forbidden;

  const showWelcomeWizard =
    !didUserAcceptTOS || // terms of service not accepted
    !didUserSetTimezone || // timezone not set
    !doesUserHaveOrganizations || // no organizations
    doesUserHavePendingInvites || // user has pending invites
    isUserForbiddenFromOrganization; // user is forbidden from organization
  // Determine if full width layout should be used based on route
  const useFullWidthLayout =
    window.location.pathname.includes('pipeline') ||
    window.location.pathname.includes('admin-usage-summary');

  return (
    <BaseLayout fullWidth={useFullWidthLayout}>
      {showWelcomeWizard ? (
        <WelcomeWizard
          userProfile={userProfile}
          userRole={userRole}
          organizationSlug={organizationSlug}
          refetchUser={refetchUser}
          pendingInvites={pendingInvites?.data}
          refetchInvites={refetchPendingInvites}
          refetchAll={() =>
            Promise.all([
              refetchUser(),
              refetchOrganizations(),
              refetchPendingInvites(),
              refetchOrganization(),
            ])
          }
        />
      ) : (
        <AppRoutes isGuest={isGuest} canCreatePlans={canCreatePlans} />
      )}
      <Toaster />
    </BaseLayout>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <StyledSpinner />,
});

const ArtifactRedirect = () => {
  const navigate = useNavigate();
  const params = useParams<{
    organizationSlug: string;
    dealRoomId: string;
    artifactId: string;
  }>();

  useEffect(() => {
    if (params.organizationSlug && params.dealRoomId && params.artifactId) {
      navigate(
        `/organization/${params.organizationSlug}/decisionsite/${params.dealRoomId}/artifacts?artifact=${params.artifactId}`,
      );
    }
  }, [navigate, params.organizationSlug, params.dealRoomId, params.artifactId]);

  return null;
};

const AppRoutes = ({
  isGuest: isOrgGuest,
  canCreatePlans,
}: {
  isGuest: boolean;
  canCreatePlans: boolean;
}) => {
  return (
    <div style={{ height: '100%' }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/invites" element={<UserInvites />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/event" element={<EventLookup />} />
        <Route path="/organization/create" element={<CreateOrganization />} />
        <Route
          path="/organization/:organizationSlug"
          element={<OrganizationRoot />}
        >
          <Route path="search" element={<Search />} />
          <Route path="usage" element={<UsageReport />} />
          <Route
            path="salesforce/pipeline"
            element={<SalesforcePipelineView />}
          />
          <Route path="hubspot/pipeline" element={<HubSpotPipelineView />} />
          <Route
            index
            element={
              isOrgGuest ? (
                <Redirect to="/organization/:organizationSlug/library/plans" />
              ) : (
                <OrganizationHome />
              )
            }
          />
          <Route path="library">
            <Route
              index
              element={
                <Redirect to="/organization/:organizationSlug/library/plans" />
              }
            />
            <Route path="plans" element={<PlansPane />} />
            <Route
              path="templates"
              element={
                !canCreatePlans ? (
                  <Redirect to="/organization/:organizationSlug/library/plans" />
                ) : (
                  <TemplateEditor />
                )
              }
            />
            <Route
              path="templates/:objectId"
              element={
                !canCreatePlans ? (
                  <Redirect to="/organization/:organizationSlug/library/plans" />
                ) : (
                  <TemplateEditor />
                )
              }
            />

            <Route
              path="companies"
              element={
                !canCreatePlans ? (
                  <Redirect to="/organization/:organizationSlug/library/plans" />
                ) : (
                  <CompanyBrowser />
                )
              }
            />
            <Route
              path="companies/:objectId"
              element={
                !canCreatePlans ? (
                  <Redirect to="/organization/:organizationSlug/library/plans" />
                ) : (
                  <CompanyBrowser />
                )
              }
            />

            <Route
              path="contacts"
              element={
                !canCreatePlans ? (
                  <Redirect to="/organization/:organizationSlug/library/plans" />
                ) : (
                  <ContactBrowser />
                )
              }
            />
            <Route
              path="contacts/:objectId"
              element={
                !canCreatePlans ? (
                  <Redirect to="/organization/:organizationSlug/library/plans" />
                ) : (
                  <ContactBrowser />
                )
              }
            />

            <Route
              path="*"
              element={
                <Redirect to="/organization/:organizationSlug/library/plans" />
              }
            />
          </Route>
          <Route path="settings" element={<OrganizationSettings />} />
          <Route path="requests" element={<OrganizationRequests />} />
          <Route path="invites" element={<OrganizationInvites />} />
          <Route path="invite" element={<OrganizationInviteMember />} />
          <Route
            path="configuration/salesforce/:configurationId/:sObjectType"
            element={<ConfigureSalesforceIntegration />}
          />
          <Route
            path="configuration/hubspot/:configurationId/:objectType"
            element={<ConfigureHubSpotIntegration />}
          />
          <Route path="decisionsite/:dealRoomId" element={<DSRoot />}>
            <Route
              path="artifacts/:artifactId"
              element={<ArtifactRedirect />}
            />
            <Route path="artifact/:artifactId" element={<ArtifactRedirect />} />
            <Route index element={<DealRoomDetailView />} />
            <Route path="overview" element={<DealRoomDetailView />} />
            <Route path="artifacts" element={<DealRoomDetailView />} />
            <Route path="journey" element={<DealRoomDetailView />} />
            <Route path="mutual-action-plan" element={<DealRoomDetailView />} />
            <Route path="hub" element={<DealRoomDetailView />} />
            <Route
              path="*"
              element={
                <Redirect to="/organization/:organizationSlug/decisionsite/:dealRoomId" />
              }
            />
          </Route>
          <Route path="dealroom/:dealRoomId/*" element={<DealRoomRedirect />} />
          <Route path="plan/:meetingPlanId" element={<MeetingPlanRoot />} />
          <Route path="plan/create" element={<MeetingPlanAutoCreate />} />
        </Route>
        <Route path="/emailPreview" element={<AdminEmailPreview />} />
        <Route path="/adminBilling" element={<AdminBillingList />} />
        <Route path="/adminUsage" element={<AdminUsageList />} />
        <Route
          path="/adminMeetingflowCopy"
          element={<AdminMeetingflowCopy />}
        />
        <Route
          path="/admin-usage-summary"
          element={<AdminUsageSummaryList />}
        />
        {import.meta.env.DEV ? (
          <Route path="/layout-test" element={<LayoutTestPage />} />
        ) : null}

        <Route path="*" element={<DSGlobalSidebarProvider><NotFound /></DSGlobalSidebarProvider>} />
      </Routes>
    </div>
  );
};
