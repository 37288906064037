import { DatePickerProps } from '@mui/x-date-pickers';
import { DSDatePickerRoot } from './DSDatePicker.styles';
import { DateTime } from 'luxon';
import { DSTextFieldSize } from './DSTextField';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { styled } from '@mui/material';

const CompactWrapper = styled('div')({
  width: '120px',
  minWidth: '120px',
  maxWidth: '120px',
  '& .MuiInputBase-root': {
    height: '24px !important',
    minHeight: '24px !important',
    maxHeight: '24px !important',
  },
  '& .MuiInputBase-input': {
    height: '24px !important',
    minHeight: '24px !important',
    maxHeight: '24px !important',
    padding: '2px 8px !important',
    fontSize: '0.75rem !important',
  },
  '& .MuiIconButton-root': {
    padding: '2px !important',
    width: '20px !important',
    height: '20px !important',
    marginRight: '2px !important',
  },
  '& .MuiSvgIcon-root': {
    width: '16px !important',
    height: '16px !important',
  },
  // Ensure proper positioning
  position: 'relative',
  zIndex: 1,
});

export type DSDatePickerProps = DatePickerProps<DateTime> & {
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  size?: DSTextFieldSize;
};

const DSDatePicker = ({ size, sx, ...props }: DSDatePickerProps) => {
  const muiSize = size === 'xsmall' ? 'small' : size;

  if (size === 'xsmall') {
    return (
      <CompactWrapper>
        <DSDatePickerRoot
          {...props}
          slotProps={{
            textField: {
              size: muiSize,
              sx: {
                '& .MuiInputBase-root': {
                  backgroundColor: DEALROOMS_COLORS.neutralLighter,
                  fontSize: '0.75rem',
                },
                ...sx,
              },
            },
          }}
        />
      </CompactWrapper>
    );
  }

  return (
    <DSDatePickerRoot
      {...props}
      slotProps={{
        textField: {
          size: muiSize,
          sx,
        },
      }}
    />
  );
};

export default DSDatePicker;
