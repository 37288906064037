import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';

export type VideoArtifactCardProps = Omit<BaseArtifactCardProps, 'artifact'> & {
  artifact: DealRoomArtifact;
};

export const VideoArtifactCard = ({
  artifact,
  ...rest
}: VideoArtifactCardProps) => {
  // Safe access to properties that might not exist on all artifact types
  const fileName = artifact && 'fileName' in artifact ? artifact.fileName : '';
  const fileUrl = artifact && 'fileUrl' in artifact ? artifact.fileUrl : undefined;
  const thumbnailUrl = artifact && 'thumbnailUrl' in artifact ? artifact.thumbnailUrl : undefined;
  const mimeType = artifact && 'mimeType' in artifact ? artifact.mimeType : '';

  return (
    <BaseArtifactCard
      className="video-artifact"
      artifact={artifact}
      {...rest}
    >
      <ImageWithFallback
        src={thumbnailUrl || fileUrl}
        fallbackSrc={thumbnailUrl ? fileUrl : undefined}
        alt={artifact?.label || artifact?.name || ''}
        fallback={
          <ArtifactIconPreview
            type="VIDEO"
            fileExtension={fileName.split('.')?.pop() || ''}
            mimeType={mimeType}
          />
        }
      />
    </BaseArtifactCard>
  );
};
