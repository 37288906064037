import { Box, Typography, TableRow, TableCell } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const ContactRowContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isLastMember',
})<{
  $isLastMember?: boolean;
}>(({ $isLastMember }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: $isLastMember
    ? 'none'
    : `1px solid ${DEALROOMS_COLORS.neutralLight}80`,
  gap: '0.5rem',
  marginBottom: $isLastMember ? '.125rem' : '0',
  position: 'relative',
  '&:hover .invite-button, &:hover .activity-button': {
    opacity: 1,
  },
}));

export const InviteButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: '16px',
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out',
  padding: '4px 8px',
  minWidth: 'unset',
  fontSize: '0.75rem',
  backgroundColor: DEALROOMS_COLORS.woodsmoke,
  color: '#fff',
  '&:hover': {
    backgroundColor: DEALROOMS_COLORS.cloudburst,
  },
  '&.activity-button': {
    backgroundColor: DEALROOMS_COLORS.themePrimary,
    '&:hover': {
      backgroundColor: DEALROOMS_COLORS.woodsmoke,
    },
  },
  '&.pending': {
    backgroundColor: DEALROOMS_COLORS.cloudburst,
    '&:hover': {
      backgroundColor: DEALROOMS_COLORS.cloudburst,
    },
  },
  '&.Mui-disabled': {
    color: '#fff',
    cursor: 'not-allowed !important',
  },
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '16px',
  '&.pending': {
    cursor: 'not-allowed !important',
  },
}));

export const ContactDetailsContainer = styled(Box)(() => ({
  display: 'inline-flex',
  cursor: 'pointer',
  marginLeft: '2.125rem',
  paddingBottom: '.5rem',
  paddingTop: '.5rem',

  '*': {
    cursor: 'pointer',
  },
}));

export const BorderlessTableRow = styled(TableRow)(() => ({
  border: 'none',
  maxWidth: '100%',
  overflow: 'hidden',
  display: 'block',
}));

export const CompanyIconCell = styled(TableCell)(() => ({
  border: 'none',
  padding: 0,
  paddingRight: '.5rem',
}));

export const CompanyDetailsCell = styled(TableCell)(() => ({
  width: '100%',
  border: 'none',
  padding: 0,
}));

interface ContactsCollapseCell {
  $showMembersOnly: boolean;
}

export const ContactsCollapseCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== '$showMembersOnly',
})<ContactsCollapseCell>(({ $showMembersOnly }) => ({
  border: 'none',
  padding: 0,
  paddingTop: !$showMembersOnly ? '.5rem' : '0',
  display: 'block',
}));

export const GroupHeaderContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  cursor: 'pointer',
  paddingLeft: '2rem',
  marginBottom: '.125rem',
}));

export const GroupHeaderContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));

export const SectionHeader = styled(Typography)(() => ({
  textTransform: 'uppercase',
  fontSize: '.75rem',
  fontWeight: 500,
  color: DEALROOMS_COLORS.cloudburst,
}));

export const ControlsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '8px 8px',
  boxSizing: 'border-box',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  width: '100%',
  gap: '8px',
  backgroundColor: '#f9f9f9',
}));

export const SearchField = styled(TextField)(() => ({
  flex: '1 1 auto',
  maxWidth: '100%',
  '& .MuiInputBase-root': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    padding: '8px 0px',
    fontSize: '0.875rem',
    borderRadius: '1rem',
  },
  '& .MuiInputAdornment-root': {
    '& svg': {
      width: '0.85rem',
      height: '0.85rem',
    },
  },
}));

export const MembersOnlyToggleContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // marginLeft: '0.5rem',
  marginTop: '.25rem',
  flex: '0 0 auto',
}));

export const InternalToggleContainer = styled(MembersOnlyToggleContainer)(
  () => ({
    marginLeft: '0.5rem',
  }),
);

export const MembersOnlyFormControlLabel = styled(FormControlLabel)(() => ({
  flexDirection: 'column',
  margin: 0,
  alignItems: 'center',
  '.MuiFormControlLabel-label': {
    fontSize: '0.6rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: DEALROOMS_COLORS.cloudburst,
    whiteSpace: 'nowrap',
    marginTop: '.125rem',
    textAlign: 'center',
  },
}));

export const InternalFormControlLabel = styled(MembersOnlyFormControlLabel)(
  () => ({}),
);

export const StyledInfoIcon = styled(InfoOutlinedIcon)(() => ({
  marginLeft: '0.5rem',
  fontSize: '0.875rem',
  color: 'text.secondary',
  cursor: 'help',
  verticalAlign: 'middle',
  position: 'relative',
  top: '-1px',
}));

export const StyledSmallInfoIcon = styled(InfoOutlinedIcon)(() => ({
  fontSize: 'small',
}));
