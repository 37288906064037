import { Application } from '@meetingflow/common/Api/data-contracts';
import { HubSpotObjectType } from './types/HubSpotObjectTypes';
import { SalesforceObjectType } from './types/SalesforceObjectTypes';

export const ProfileQuery = 'Profile';
export const CollabAuthQuery = 'CollabAuth';
export const InvitesQuery = (
  type?: 'ALL' | 'SENT' | 'RECEIVED',
  organizationSlug?: string,
) => `Invites_${type ? type : 'ALL'}_${organizationSlug}`;
export const PendingRequestsQuery = 'PendingRequests';
export const OrgSuggestionsQuery = 'SuggestedOrganizations';
export const OrganizationsQuery = 'Organizations';
export const BundleHash = 'BundleHash';
export const AccessEnabledQuery = 'AccessEnabled';
export const OrganizationQuery = (slug: string) => 'Organization_' + slug;

export const OrganizationUserPreferencesQuery = (slug?: string) =>
  `OrganizationUserPreferences_${slug}`;

export const OrganizationCallRecorderThumbnailQuery = (slug: string) =>
  `OrganizationCallRecorderThumbnail_${slug}`;

export const OrganizationSearchQuery = (slug: string, params?: string) =>
  `Search_${slug}_${params}`;

export const OrganizationGlobalSearchQuery = (slug: string, params?: string) =>
  `GlobalSearch_${slug}_${params}`;

export const OrganizationInvitesQuery = (slug?: string) =>
  `OrganizationInvites_${slug}`;

export const CompanyByDomain = (domain: string) => 'CompanyByDomain_' + domain;
export const OrganizationRoleQuery = (slug: string) =>
  'OrganizationRole_' + slug;
export const OrganizationMembersQuery = (
  slug: string,
  withPlans?: boolean,
  query?: string,
) => `OrganizationMembers_${slug}_${withPlans ?? 'false'}_${query}`;

export const OrganizationTagsQuery = (slug: string, query?: string) =>
  `OrganizationTags_${slug}_${query}`;

export const OrganizationMemberQuery = (slug: string, id?: number) =>
  `OrganizationMember_${slug}_${id}`;

export const OrganizationTagQuery = (slug: string, id?: string) =>
  `OrganizationTag_${slug}_${id}`;

export const OrganizationContactsQuery = (
  slug: string,
  withPlans?: boolean,
  internal?: boolean,
  invitable?: boolean,
  domains?: string[],
  q?: string,
) =>
  `OrganizationContacts_${slug}_${withPlans ?? 'false'}_${
    internal ?? 'false'
  }_${invitable ?? 'false'}_${q}_${domains}`;

export const OrganizationContactsQueryInfinite = (
  slug: string,
  withPlans?: boolean,
  internal?: boolean,
  invitable?: boolean,
  q?: string,
) =>
  `OrganizationContactsInfinite_${slug}_${withPlans ?? 'false'}_${
    internal ?? 'false'
  }_${invitable ?? 'false'}_${q}`;

export const OrganizationDealRoomsQuery = (
  slug: string,
  filters: string = '',
  limit?: number,
  skip?: number,
) => `OrganizationDealRooms_${slug}_${filters}_${limit}_${skip}`;

export const OrganizationDealRoomQuery = (slug?: string, dealRoomId?: number) =>
  `OrganizationDealRoom_${slug}_${dealRoomId}`;

export const OrganizationDealRoomArtifactsQuery = (
  slug?: string,
  dealRoomId?: number,
  featured?: boolean,
  includeDeleted?: boolean,
) =>
  `OrganizationDealRoomArtifacts_${slug}_${dealRoomId}_${featured}_${includeDeleted}`;

export const OrganizationDealRoomArtifactQuery = (
  slug: string,
  dealRoomId: number,
  artifactId: number,
) => `OrganizationDealRoomArtifact_${slug}_${dealRoomId}_${artifactId}`;

export const OrganizationContactQuery = (slug: string, id?: string | number) =>
  `OrganizationContact_${slug}_${id}`;

export const DealRoomContactsQuery = (slug: string, dealRoomId: number) =>
  `OrganizationContact_${slug}_${dealRoomId}`;

export const OrganizationUserActionItemsQuery = (slug: string) =>
  `OrganizationUserActionItems_${slug}`;

export const OrganizationCompanyQuery = (slug: string, companyId?: number) =>
  `OrganizationCompany_${slug}_${companyId}`;

export const OrganizationOrganizerContactsQuery = (slug: string) =>
  `OrganizationOrganizerContactsQuery_${slug}`;

export const OrganizationDomainRulesQuery = (slug: string) =>
  'OrganizationDomainRule_' + slug;

export const OrganizationMemberSuggestionsQuery = (slug: string) =>
  'OrganizationMemberSuggestions_' + slug;

export const OrganizationPendingRequestsQuery = (slug: string) =>
  'OrganizationPendingRequests_' + slug;

export const OrganizationMeetingPlansQuery = (
  organizationSlug: string,
  filters: string = '',
  limit?: number,
  skip?: number,
  dealRoomId?: number,
) => [
  'organization',
  organizationSlug,
  'meetingplans',
  filters,
  limit,
  skip,
  dealRoomId,
];

export const OrganizationSampleMeetingPlansQuery = (orgSlug: string) =>
  `OrganizationSamplePlans_${orgSlug}`;

export const OrganizationTemplatesQuery = (orgSlug: string) =>
  `OrganizationTemplates_${orgSlug}`;

export const OrganizationTemplateQuery = (
  orgSlug: string,
  templateId: string | number,
) => `OrganizationTemplate_${orgSlug}_${templateId}`;

export const OrganizationUpcomingMeetings = (orgSlug: string) =>
  `OrganizationUpcomingMeetings_${orgSlug}`;

export const OrganizationExternalServiceConfigurationsQuery = (
  orgSlug: string,
  app?: Application,
  withToken?: boolean,
) =>
  `OrganizationExternalServiceConfigurations_${orgSlug}_${app ?? 'ALL'}_${
    withToken ? 'WITH_TOKEN' : 'ALL'
  }`;

export const OrganizationExternalServiceConfigurationCustomFieldSetsQuery = (
  orgSlug: string,
  configurationId?: number,
) => `OrganizationExternalServiceCustomFieldSets_${orgSlug}_${configurationId}`;

export const OrganizationExternalServiceConfigurationCustomFieldSetQuery = (
  orgSlug: string,
  configurationId?: number,
  customFieldSetId?: number | null,
) =>
  `OrganizationExternalServiceCustomFieldSet_${orgSlug}_${configurationId}_${customFieldSetId}`;

export const OrganizationMeetingsHappeningSoon = (orgSlug: string) =>
  `OrganizationMeetingsHappeningSoon_${orgSlug}`;

export const OrganizationRecentlyCreatedPlans = (orgSlug: string) =>
  `OrganizationRecentlyCreatedPlans${orgSlug}`;

export const OrganizationUsageQuery = (orgSlug: string) =>
  `OrganizationUsage_${orgSlug}`;

export const OrganizationCallRecordingUsageQuery = (orgSlug: string) =>
  `MeetingPlanCallRecordingUsage_${orgSlug}}`;

export const MeetingPlanQuery = (orgSlug: string, meetingPlanId: string) =>
  `MeetingPlan_${orgSlug}_${meetingPlanId}`;

export const MeetingPlanSuggestedPromptsQuery = (
  orgSlug: string,
  meetingPlanId: string,
) => `MeetingPlanSuggestedAIPrompts_${orgSlug}_${meetingPlanId}`;

export const MeetingPlanCallRecorderStatusQuery = (
  orgSlug: string,
  meetingPlanId: string,
) => `MeetingPlanCallRecorderStatus_${orgSlug}_${meetingPlanId}`;

export const MeetingPlanCallTranscriptQuery = (
  orgSlug: string,
  meetingPlanId: string,
) => `MeetingPlanCallTranscript_${orgSlug}_${meetingPlanId}`;

export const MeetingPlanLastLoggedQuery = (
  orgSlug: string,
  meetingPlanId: string,
) => `MeetingPlanLastLogged_${orgSlug}_${meetingPlanId}`;

export const SalesforcePlanContextQuery = (
  orgSlug: string,
  meetingPlanId: string,
  configurationId?: number,
) => `SalesforcePlanContext_${orgSlug}_${meetingPlanId}_${configurationId}`;

export const SalesforceContactContextQuery = (
  orgSlug: string,
  contactId: number,
  configurationId?: number,
) => `SalesforceContactContext_${orgSlug}_${contactId}_${configurationId}`;

export const SalesforceCompanyContextQuery = (
  orgSlug: string,
  contactId: number,
  configurationId?: number,
) => `SalesforceCompanyContext_${orgSlug}_${contactId}_${configurationId}`;

export const HubSpotPlanContextQuery = (
  orgSlug: string,
  meetingPlanId: string,
  configurationId?: number,
) => `HubSpotPlanContext_${orgSlug}_${meetingPlanId}_${configurationId}`;

export const HubSpotContactContextQuery = (
  orgSlug: string,
  contactId: number,
  configurationId?: number,
) => `HubSpotContactContext_${orgSlug}_${contactId}_${configurationId}`;

export const HubSpotCompanyContextQuery = (
  orgSlug: string,
  contactId: number,
  configurationId?: number,
) => `HubSpotCompanyContext_${orgSlug}_${contactId}_${configurationId}`;

export const MeetingPlanPastPlansQuery = (
  orgSlug: string,
  meetingPlanId: string,
) => `MeetingPlanPastPlans_${orgSlug}_${meetingPlanId}`;

export const MeetingPlanRelatedPlansQuery = (
  orgSlug: string,
  meetingPlanId: string,
) => `MeetingPlanRelatedPlans_${orgSlug}_${meetingPlanId}`;

export const MeetingPlanCompanyProfilesQuery = (
  orgSlug: string,
  meetingPlanId: string,
) => `MeetingPlanCompanyProfiles_${orgSlug}_${meetingPlanId}`;

export const MeetingPlanSharesQuery = (
  orgSlug: string,
  meetingPlanId: string,
  onlyGuests?: boolean,
) => `MeetingPlanShares_${orgSlug}_${meetingPlanId}_${onlyGuests || false}`;

export const MeetingPlanShadowEventQuery = (
  orgSlug: string,
  meetingPlanId: string,
) => `MeetingPlanShadowEvent_${orgSlug}_${meetingPlanId}`;

export const OrganizationUserTasksQuery = (orgSlug: string) =>
  `OrganizationUserTasks_${orgSlug}`;

export const MeetingPlanTasksQuery = (orgSlug: string, meetingPlanId: string) =>
  `MeetingPlanTasks_${orgSlug}_${meetingPlanId}`;

export const CompanyProfile = (orgSlug: string, domain: string) =>
  `CompanyProfile_${orgSlug}_${domain}`;

export const OrganizationCompanies = (orgSlug: string, withPlans?: boolean) =>
  `OrganizationCompanies_${orgSlug}_${withPlans ?? 'false'}`;

export const OrganizationCompaniesInfinite = (
  orgSlug: string,
  withPlans?: boolean,
) => `OrganizationCompaniesInfinite_${orgSlug}_${withPlans ?? 'false'}`;

export const ContactLinkedInData = (
  orgSlug: string,
  contactIdOrEmail: string | number,
) => `ContactLinkedIn_${orgSlug}_${contactIdOrEmail}`;

export const ExternalServiceConfigurationsQuery = (
  orgSlug: string,
  app: Application,
) => `ExternalServiceConfigurations_${orgSlug}_${app}`;

export const SalesforceAccountsQuery = (
  orgSlug: string,
  configurationId?: number,
) => `SalesforceAccounts_${orgSlug}_${configurationId}`;

export const SalesforceContactsQuery = (
  orgSlug: string,
  configurationId?: number,
) => `SalesforceContacts_${orgSlug}_${configurationId}`;

export const SalesforceAccountSuggestionsQuery = (
  orgSlug: string,
  configurationId?: number,
  externalDomains?: string[],
) =>
  `SalesforceAccountSuggestions_${orgSlug}_${configurationId}_${externalDomains?.join(
    ',',
  )}`;

export const SalesforceObjectQuery = (
  orgSlug: string,
  configurationId?: number,
  sObject?: SalesforceObjectType,
  objectId?: string,
) => `SalesforceObject_${orgSlug}_${configurationId}_${sObject}_${objectId}`;

export const SalesforceAccountQuery = (
  orgSlug: string,
  configurationId?: number,
  accountId?: string,
) => `SalesforceAccount_${orgSlug}_${configurationId}_${accountId}`;

export const SalesforceContactQuery = (
  orgSlug: string,
  configurationId?: number,
  contactId?: string,
) => `SalesforceContact_${orgSlug}_${configurationId}_${contactId}`;

export const SalesforceContactSuggestionsQuery = (
  orgSlug: string,
  configurationId?: number,
  externalContactIds?: number[],
) =>
  `SalesforceContactSuggestions_${orgSlug}_${configurationId}_${externalContactIds?.join(
    ',',
  )}`;

export const SalesforceLeadsQuery = (
  orgSlug: string,
  configurationId?: number,
  accountId?: string,
) => `SalesforceLeads_${orgSlug}_${configurationId}_${accountId}`;

export const SalesforceLeadSuggestionsQuery = (
  orgSlug: string,
  configurationId?: number,
  accountId?: string,
  externalContactIds?: number[],
) =>
  `SalesforceLeadSuggestions_${orgSlug}_${configurationId}_${accountId}_${externalContactIds?.join(
    ',',
  )}`;

export const SalesforceUsersQuery = (
  orgSlug: string,
  configurationId?: number,
) => `SalesforceUsers_${orgSlug}_${configurationId}`;

export const SalesforceLeadQuery = (
  orgSlug: string,
  configurationId?: number,
  leadId?: string,
) => `SalesforceLead_${orgSlug}_${configurationId}_${leadId}`;

export const SalesforceOpportunitiesQuery = (
  orgSlug: string,
  configurationId?: number,
  accountId?: string,
) => `SalesforceOpportunities_${orgSlug}_${configurationId}_${accountId}`;

export const SalesforceOpportunitySuggestionsQuery = (
  orgSlug: string,
  configurationId?: number,
  accountId?: string,
  externalDomains?: string[],
) =>
  `SalesforceOpportunitySuggestions_${orgSlug}_${configurationId}_${accountId}_${externalDomains?.join(
    ',',
  )}`;

export const SalesforceOpportunityQuery = (
  orgSlug: string,
  configurationId?: number,
  opportunityId?: string,
) => `SalesforceOpportunity_${orgSlug}_${configurationId}_${opportunityId}`;

export const SalesforceOpportunityStatsQuery = (
  orgSlug: string,
  configurationId?: number,
) => `SalesforceOpportunityStats_${orgSlug}_${configurationId}`;

export const SalesforceSObjectSchemaQuery = (
  orgSlug: string,
  configurationId?: number,
  sObject?: string,
  customFieldSetId?: number | null,
) =>
  `SalesforceSchema_${orgSlug}_${configurationId}_${sObject}_${customFieldSetId}`;

export const SalesforceSObjectLayoutQuery = (
  orgSlug: string,
  configurationId?: number,
  sObject?: SalesforceObjectType,
  layoutId?: string,
) =>
  `SalesforceObjectLayout_${orgSlug}_${configurationId}_${sObject}_${layoutId}`;

export const OAuthTokenQuery = (orgSlug: string, app: Application) =>
  `OAuthToken_${orgSlug}_${app}`;

export const SalesforceContactsByEmailsQuery = (
  orgSlug: string,
  emails: string,
) => `SalesforceContactsByEmails_${orgSlug}_${emails}`;

export const SalesforceContactsByDomainQuery = (
  orgSlug: string,
  emails: string,
) => `SalesforceContactsByDomain_${orgSlug}_${emails}`;

export const AccountByIdQuery = (orgSlug: string, id: string) =>
  `SalesforceAccountById_${orgSlug}_${id}`;

export const CompanyNewsById = (orgSlug: string, id: number) =>
  `CompanyNewsById_${orgSlug}_${id}`;

export const ContactRecentPlansQuery = (
  orgSlug: string,
  contactEmail: string,
  currentMeetingPlanId?: string,
) =>
  `ContactRecentPlans_${orgSlug}_${contactEmail}_${
    currentMeetingPlanId ?? 'all'
  }`;

export const CompanyRecentPlansQuery = (
  orgSlug: string,
  companyId: string,
  currentMeetingPlanId?: string,
) =>
  `CompanyRecentPlans_${orgSlug}_${companyId}_${currentMeetingPlanId ?? 'all'}`;

export const SlackChannelsQuery = (orgSlug: string) =>
  `SlackChannels_${orgSlug}`;

export const TeamsTeamsQuery = (orgSlug: string, configurationId?: number) =>
  `TeamsTeams_${orgSlug}_${configurationId}`;

export const TeamsChannelsQuery = (
  orgSlug: string,
  configurationId?: number,
  teamId?: string,
) => `TeamsChannels_${orgSlug}_${configurationId}_${teamId}`;

export const HubSpotDealStatsQuery = (
  orgSlug: string,
  configurationId?: number,
) => `HubSpotDealStats_${orgSlug}_${configurationId}`;

export const HubSpotDealsQuery = (orgSlug: string, configurationId?: number) =>
  `HubSpotDeals_${orgSlug}_${configurationId}`;

export const HubSpotCompanyDealsQuery = (
  orgSlug: string,
  configurationId?: number,
  companyId?: string | number,
) => `HubSpotCompanyDeals_${orgSlug}_${configurationId}_${companyId}`;

export const HubSpotCompaniesQuery = (
  orgSlug: string,
  configurationId?: number,
) => `HubSpotCompanies_${orgSlug}_${configurationId}`;

export const HubSpotDealSuggestionsQuery = (
  orgSlug: string,
  configurationId?: number,
  externalDomains?: string[],
) =>
  `HubSpotDealSuggestions_${orgSlug}_${configurationId}_${externalDomains?.join(
    ',',
  )}`;

export const HubSpotCompanySuggestionsQuery = (
  orgSlug: string,
  configurationId?: number,
  externalDomains?: string[],
) =>
  `HubSpotCompanySuggestions_${orgSlug}_${configurationId}_${externalDomains?.join(
    ',',
  )}`;

export const HubSpotOwnersQuery = (orgSlug: string, configurationId?: number) =>
  `HubSpotOwners_${orgSlug}_${configurationId}`;

export const HubSpotOwnerQuery = (
  orgSlug: string,
  configurationId?: number,
  ownerId?: string | number,
) => `HubSpotOwner_${orgSlug}_${configurationId}_${ownerId}`;

export const HubSpotDealQuery = (
  orgSlug: string,
  configurationId?: number,
  dealId?: string,
) => `HubSpotDeal_${orgSlug}_${configurationId}_${dealId}`;

export const HubSpotDealPipelinesQuery = (
  orgSlug: string,
  configurationId?: number,
) => `HubSpotDealPipelines_${orgSlug}_${configurationId}`;

export const HubSpotDealPipelineQuery = (
  orgSlug: string,
  configurationId?: number,
  pipelineId?: string,
) => `HubSpotDealPipeline_${orgSlug}_${configurationId}_${pipelineId}`;

export const HubSpotPipelineDealStagesQuery = (
  orgSlug: string,
  configurationId?: number,
  pipelineId?: string,
) => `HubSpotPipelineDealStages_${orgSlug}_${configurationId}_${pipelineId}`;

export const HubSpotObjectSchemaQuery = (
  orgSlug: string,
  configurationId?: number,
  hubspotObject?: HubSpotObjectType,
  customFieldSetId?: number | null,
) =>
  `HubSpotSchema_${orgSlug}_${configurationId}_${hubspotObject}_${customFieldSetId}`;

export const HubSpotObjectQuery = (
  orgSlug: string,
  configurationId?: number,
  hubspotObject?: HubSpotObjectType,
  hubspotObjectId?: string,
) =>
  `HubSpotObject_${orgSlug}_${configurationId}_${hubspotObject}_${hubspotObjectId}`;

export const OrganizationMutualPlanQuery = (
  slug?: string,
  dealRoomId?: number,
) => `OrganizationMutualPlan_${slug}_${dealRoomId}`;

export const OrganizationMilestonesQuery = (
  slug?: string,
  dealRoomId?: number,
) => `OrganizationMilestones_${slug}_${dealRoomId}`;

export const OrganizationMilestonesSummaryQuery = (
  slug?: string,
  dealRoomId?: number,
) => `OrganizationMilestonesSummary_${slug}_${dealRoomId}`;

export const OrganizationCalloutCardsQuery = (
  slug?: string,
  dealRoomId?: number,
) => `OrganizationCalloutCards_${slug}_${dealRoomId}`;

export const OrganizationKeyDetailsQuery = (
  slug?: string,
  dealRoomId?: number,
) => `OrganizationKeyDetails_${slug}_${dealRoomId}`;

export const OrganizationMutualPlanTemplatesQuery = (
  slug?: string,
) => `OrganizationMutualPlanTemplates_${slug}`;

export const OrganizationTaskCommentsQuery = (
  slug?: string,
  dealRoomId?: number,
  taskId?: number,
) => `OrganizationTaskComments_${slug}_${dealRoomId}_${taskId}`;

export const OrganizationArtifactCommentsQuery = (
  slug?: string,
  dealRoomId?: number,
  artifactId?: number,
) => `OrganizationArtifactComments_${slug}_${dealRoomId}_${artifactId}`;

export const OrganizationNotificationsSettingsQuery = (
  slug?: string,
  dealRoomId?: number,
) => `OrganizationNotificationsSettings_${slug}_${dealRoomId}`;

export const OrganizationNotificationsDataQuery = (
  slug?: string,
  dealRoomId?: number,
) => `OrganizationNotificationsData_${slug}_${dealRoomId}`;
