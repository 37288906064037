import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

interface InfoSectionContentProps {
  direction?: 'row' | 'column';
  gap?: string | number;
}

export const InfoSectionContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  borderRadius: '.25rem',
  containerType: 'inline-size',
  marginBottom: '1rem',
  overflow: 'visible !important',
}));

export const InfoSectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '.25rem .25rem 0 0',
  gap: '.5rem',
  marginLeft: '.5rem',
  overflow: 'visible !important',
}));

export const InfoSectionContent = styled(Box)<InfoSectionContentProps>(
  ({ theme, direction = 'column', gap = 0 }) => ({
    display: 'flex',
    flexDirection: direction,
    gap: gap,
    borderRadius: '0 0 .25rem .25rem',
    overflow: 'auto',
    '& > div': {
      overflow: 'visible',
      '& > button': {
        zIndex: 1,
      },
    },
  }),
);
