import { styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  flexWrap: 'wrap',
  marginBottom: '.5rem',
  padding: '0',
  borderBottom: `1px solid ${DEALROOMS_COLORS.financialPrimaryGray}`,
});

export const Title = styled('div')({
  fontSize: '1.125rem',
  fontWeight: '400',
  color: DEALROOMS_COLORS.themePrimary,
  padding: '0',
  display: 'flex',
  alignItems: 'center',
  minWidth: '150px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRadius: '4px',
  },
});

export const ButtonsContainer = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
});

export const datePickerStyles = {
  position: 'relative',
  top: '1px',
  gap: 0,
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    backgroundColor: 'transparent',
  },

  '& .MuiOutlinedInput-input': {
    color: DEALROOMS_COLORS.cloudburst,
    minWidth: '70px',
    maxWidth: '70px',
  },

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    color: DEALROOMS_COLORS.cloudburst,
  },
};

export const milestoneDatePickerStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    backgroundColor: 'transparent',
  },

  '& .MuiOutlinedInput-input': {
    color: DEALROOMS_COLORS.cloudburst,
    minWidth: '70px',
    padding: '4.5px 0 4.5px 14px',
    maxWidth: '106px',
    textAlign: 'right',
  },

  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    color: DEALROOMS_COLORS.cloudburst,
  },
  '& .MuiInputAdornment-root': {
    margin: 0,
  },
};
