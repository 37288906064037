import { Box, Paper, styled } from '@mui/material';
import ReactMarkdown from 'react-markdown';

export const DecisionSiteJourneySummaryContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const DecisionSiteJourneySummaryStack = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'totalFields' && prop !== 'layout',
})<{
  layout: 'vertical' | 'horizontal';
  totalFields: number;
}>(({ theme, layout, totalFields }) => ({
  display: 'flex',
  flexDirection: layout === 'horizontal' ? 'row' : 'column',
  flexWrap: layout === 'horizontal' ? 'wrap' : 'nowrap',
  gap: totalFields > 1 ? theme.spacing(2) : 0,
  overflow: 'auto',
  height: '100%',
  padding: layout === 'horizontal' ? 0 : '1.5rem',
  '& > *': {
    flex:
      layout === 'horizontal'
        ? `0 0 calc(100% / ${totalFields} - ${totalFields > 1 ? theme.spacing(2) : 0})`
        : '0 0 auto',
  },
}));

export const DecisionSiteJourneySummaryCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '.25rem',
  boxSizing: 'border-box',
}));

export const DecisionSiteJourneySummaryCardHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0',
});

export const DecisionSiteJourneySummaryIconWrapper = styled(Box)({
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const DecisionSiteJourneySummaryCardContent = styled(Box)({
  overflow: 'visible',
  padding: '0 0 0 2rem',
});

export const DecisionSiteJourneySummaryStyledMarkdown = styled(ReactMarkdown)`
  height: 100%;
  overflow: auto;
  margin-bottom: 0;

  h1 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0.75rem;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0.75rem;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
  }

  ul {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }

  li {
    line-height: 1.25rem;
  }
`;

export const DecisionSiteJourneySummaryTimestampText = styled('div')(
  ({ theme }) => ({
    display: 'block',
    textAlign: 'right',
    color: theme.palette.text.secondary,
    typography: theme.typography.caption,
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '.5rem',
    backgroundColor: theme.palette.divider,
    borderTopLeftRadius: '.25rem',
    fontSize: '11px',
    border: `1px solid ${theme.palette.divider}`,
  }),
);

export const DecisionSiteJourneySummaryStateContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
  }),
);

export const DecisionSiteJourneySummaryLoadingContainer = styled(
  DecisionSiteJourneySummaryStateContainer,
)({
  flexDirection: 'column',
  '& > *:first-of-type': {
    marginBottom: 16,
  },
});
