import { DealRoomMilestone } from '@meetingflow/common/Api/data-contracts';
import { mergeStyleSets } from '@fluentui/react';
import DealRoomAsyncCommandBarButton, {
  DealRoomCommandBarButton,
} from '../Components/DealRoomButton';
import { MilestoneMoreOptions } from './MilestoneMoreOptions';
import { DSButton, DSTooltip, DSDatePicker } from '../DS';
import {
  MoveToInbox,
  AddTask,
  ExpandMore,
  ExpandLess,
  Edit as EditIcon,
} from '@mui/icons-material';
import { DateTime } from 'luxon';
import {
  HeaderContainer,
  Title,
  ButtonsContainer,
  datePickerStyles,
  milestoneDatePickerStyles,
} from './MilestoneHeader.styles';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/useField';
import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';

type MilestoneHeaderProps = {
  isSeller: boolean;
  milestone: DealRoomMilestone;
  organizationSlug: string;
  dealRoomId: number;
  onDueDateChange: (
    newDate: DateTime | null,
    context: FieldChangeHandlerContext<string | null>,
  ) => void;
  onRequestArtifact: () => Promise<void>;
  onAddTask: () => void;
  onTitleChange: (newTitle: string, callback?: () => void) => Promise<void>;
  milestoneDueDate: string | null;
  isExpanded: boolean;
  onToggleExpand: () => void;
};

export const MilestoneHeader = ({
  isSeller,
  milestone,
  organizationSlug,
  dealRoomId,
  onDueDateChange,
  onRequestArtifact,
  onAddTask,
  onTitleChange,
  milestoneDueDate,
  isExpanded,
  onToggleExpand,
}: MilestoneHeaderProps) => {
  const [isMobile, setIsMobile] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [titleValue, setTitleValue] = useState(milestone.title);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handler = (e: MediaQueryListEvent) => setIsMobile(e.matches);
    mediaQuery.addEventListener('change', handler);
    return () => mediaQuery.removeEventListener('change', handler);
  }, []);

  // Update local title state when milestone title changes
  useEffect(() => {
    setTitleValue(milestone.title);
  }, [milestone.title]);

  const handleDueDateChange = (
    newDate: DateTime | null,
    context: FieldChangeHandlerContext<string | null>,
  ) => {
    onDueDateChange(newDate, context);
    setDatePickerOpen(false);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value);
  };

  const handleTitleSubmit = async () => {
    // Don't update if title is empty or unchanged
    if (!titleValue || !titleValue.trim() || titleValue === milestone.title) {
      setTitleValue(milestone.title);
      setIsEditingTitle(false);
      return;
    }

    // Save the current title in case we need to revert
    const originalTitle = milestone.title;

    // Submit the title change
    await onTitleChange(titleValue, () => {
      // This callback will be called if there's an error
      setTitleValue(originalTitle);
    });

    setIsEditingTitle(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleTitleSubmit();
    } else if (e.key === 'Escape') {
      setTitleValue(milestone.title);
      setIsEditingTitle(false);
    }
  };

  return (
    <>
      <HeaderContainer>
        <span>
          <DSButton
            onClick={onToggleExpand}
            startIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
            variant="text"
            iconOnly
            sx={{
              '& .MuiSvgIcon-root': { color: DEALROOMS_COLORS.cloudburst },
            }}
          >
            {isExpanded ? 'Collapse' : 'Expand'}
          </DSButton>
        </span>
        {isEditingTitle ? (
          <TextField
            value={titleValue}
            onChange={handleTitleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleTitleSubmit}
            autoFocus
            size="small"
            fullWidth
            sx={{
              maxWidth: '300px',
              '& .MuiOutlinedInput-root': {
                fontSize: '1.125rem',
                fontWeight: '400',
                color: DEALROOMS_COLORS.themePrimary,
              },
            }}
          />
        ) : (
          <Title
            onClick={() => setIsEditingTitle(true)}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem',
              wordBreak: 'break-all',
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            {milestone.title}
          </Title>
        )}

        <ButtonsContainer>
          {!isMobile && (
            <DSDatePicker
              value={
                milestoneDueDate ? DateTime.fromISO(milestoneDueDate) : null
              }
              onChange={handleDueDateChange}
              size="small"
              sx={milestoneDatePickerStyles}
            />
          )}
          {!isMobile && (
            <DSTooltip title="Request Artifact">
              <span>
                <DSButton
                  onClick={onRequestArtifact}
                  startIcon={<MoveToInbox />}
                  iconOnly
                  variant="text"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      color: DEALROOMS_COLORS.cloudburst,
                    },
                  }}
                >
                  Request Artifact
                </DSButton>
              </span>
            </DSTooltip>
          )}

          <span>
            <DSButton
              onClick={onAddTask}
              startIcon={<AddTask />}
              variant="text"
              iconOnly={isMobile}
              sx={{
                '& .MuiSvgIcon-root': { color: DEALROOMS_COLORS.cloudburst },
              }}
            >
              Add Task
            </DSButton>
          </span>

          <MilestoneMoreOptions
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            milestoneId={milestone.id}
            onRequestArtifact={isMobile ? onRequestArtifact : undefined}
            onEditDate={isMobile ? () => setDatePickerOpen(true) : undefined}
            isMobile={isMobile}
            isSeller={isSeller}
            isVisible={milestone.visible}
          />
        </ButtonsContainer>
      </HeaderContainer>

      {isMobile && (
        <Dialog
          open={datePickerOpen}
          onClose={() => setDatePickerOpen(false)}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>Edit Due Date</DialogTitle>
          <DialogContent>
            <DSDatePicker
              value={
                milestoneDueDate ? DateTime.fromISO(milestoneDueDate) : null
              }
              onChange={handleDueDateChange}
              size="medium"
              sx={{ width: '100%', marginTop: 2 }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
