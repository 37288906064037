import { DealRoomArtifact, DealRoomAudioArtifact } from '@meetingflow/common/Api/data-contracts';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';
import { ImageWithFallback } from '../../Common/ImageWithFallback';

export type AudioArtifactCardProps = Omit<BaseArtifactCardProps, 'artifact'> & {
  artifact: DealRoomArtifact;
};

export const AudioArtifactCard = ({
  artifact,
  ...rest
}: AudioArtifactCardProps) => {
  // Safe access to properties that might not exist on all artifact types
  const fileName = artifact && 'fileName' in artifact ? artifact.fileName : '';
  const thumbnailUrl = artifact && 'thumbnailUrl' in artifact ? artifact.thumbnailUrl : undefined;
  const mimeType = artifact && 'mimeType' in artifact ? artifact.mimeType : '';

  return (
    <BaseArtifactCard
      className="audio-artifact"
      artifact={artifact}
      {...rest}
    >
      <ImageWithFallback
        src={thumbnailUrl || undefined}
        fallback={<ArtifactIconPreview
          type="AUDIO"
          fileExtension={fileName.split('.')?.pop() || ''}
          mimeType={mimeType}
        />}
      />
      
    </BaseArtifactCard>
  );
};
