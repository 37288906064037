/**
 * DSArtifactPanelTabs provides a tabbed interface for artifact-related features
 * including AI assistance and comments.
 */

import React, { useEffect, useMemo, useState } from 'react';
import { DSTabs, DSTab } from '../DS/DSTabs';
import { useSearchParams } from 'react-router-dom';
import {
  SmartToyOutlined,
  ChatOutlined,
  DescriptionOutlined,
} from '@mui/icons-material';
import { TabLabelWithIcon } from './DSArtifactPanel.styles';
import { DealRoomArtifactAssistantPanel } from '../Assistant/DealRoomArtifactAssistantPanel';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { DealRoomArtifactCommentsPanel } from './DealRoomArtifactCommentsPanel';
import { DealRoomArtifactDescriptionPanel } from './DealRoomArtifactDescriptionPanel';
import { useArtifactComments } from '../../../Hooks/useArtifactComments';
import useDeviceType from '../../../Hooks/deviceDetection';

/**
 * Props interface for DSArtifactPanelTabs
 * @property artifactId - ID of the current artifact
 * @property onTabChange - Optional callback for tab changes
 */
interface DSArtifactPanelTabsProps {
  artifactId: number;
  onTabChange?: (tab: string | number) => void;
}

/**
 * Tabbed interface component for artifact features
 * Provides tabs for AI interaction and comments
 */
export const DSArtifactPanelTabs: React.FC<DSArtifactPanelTabsProps> = ({
  artifactId,
  onTabChange,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('tab') || 'description';
  const dealRoomId = useDealRoomId();
  const { dealRoom } = useDealRoom();
  const { slug: organizationSlug } = useOrganization();
  const { user: mfUser } = useUserProfile();
  const { isMobile } = useDeviceType();
  const [currentThreadId, setCurrentThreadId] = useState<number | undefined>(
    undefined,
  );

  const { artifactComments } = useArtifactComments(
    organizationSlug,
    dealRoomId,
    artifactId,
  );
  const convNumber = useMemo(
    () => (artifactComments || []).length,
    [artifactComments],
  );

  const { currentContactId, currentContactCompanyId } = useMemo(() => {
    if (!dealRoom || !mfUser?.email)
      return {
        currentContactId: undefined,
        currentContactCompanyId: undefined,
      };

    const currentContact = dealRoom.contacts?.find(
      (contact) => contact.email.toLowerCase() === mfUser.email.toLowerCase(),
    );

    const currentCompany = dealRoom.companies?.[0];

    return {
      currentContactId: currentContact?.id,
      currentContactCompanyId: currentCompany?.id,
    };
  }, [dealRoom, mfUser?.email]);

  // Update URL when tab changes
  const handleTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    newTab: string | number,
  ) => {
    // Reset currentThreadId when clicking the Comments tab
    if (newTab === 'comments' && currentTab === 'comments') {
      setCurrentThreadId(undefined);
    }
    searchParams.set('tab', String(newTab));
    setSearchParams(searchParams);
    onTabChange?.(newTab);
  };

  // Update URL params when tab changes via props
  useEffect(() => {
    if (currentTab) {
      searchParams.set('tab', String(currentTab));
      setSearchParams(searchParams);
    }
  }, [currentTab, searchParams, setSearchParams]);

  return (
    <DSTabs
      className="artifact-panel-tabs"
      value={currentTab}
      onChange={handleTabChange}
      sx={{
        ...(isMobile
          ? {
              paddingRight: '3.5rem',
            }
          : {}),
      }}
    >
      {organizationSlug && dealRoomId && (
        <DSTab
          value="description"
          label={
            <TabLabelWithIcon>
              <DescriptionOutlined sx={{ fontSize: '1.2rem' }} />
              Description
            </TabLabelWithIcon>
          }
          panel={
            <DealRoomArtifactDescriptionPanel
              organizationSlug={organizationSlug!}
              dealRoomId={dealRoomId}
              artifactId={artifactId}
            />
          }
        />
      )}
      {organizationSlug && dealRoomId && (
        <DSTab
          value="ask-ai"
          label={
            <TabLabelWithIcon>
              <SmartToyOutlined sx={{ fontSize: '1.2rem' }} />
              Ask AI
            </TabLabelWithIcon>
          }
          panel={
            <DealRoomArtifactAssistantPanel
              key={artifactId}
              organizationSlug={organizationSlug!}
              dealRoomId={dealRoomId}
              artifactId={artifactId}
            />
          }
        />
      )}
      {organizationSlug && dealRoomId && (
        <DSTab
          value="comments"
          count={convNumber}
          label={
            <TabLabelWithIcon>
              <ChatOutlined sx={{ fontSize: '1.2rem' }} />
              Comments
            </TabLabelWithIcon>
          }
          panel={
            <DealRoomArtifactCommentsPanel
              organizationSlug={organizationSlug!}
              dealRoomId={dealRoomId}
              artifactId={artifactId}
              currentThreadId={currentThreadId}
              setCurrentThreadId={(convId: number) =>
                setCurrentThreadId(convId)
              }
              currentContactId={currentContactId}
              currentContactCompanyId={currentContactCompanyId}
            />
          }
        />
      )}
    </DSTabs>
  );
};

export default DSArtifactPanelTabs;
