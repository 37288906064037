import { useAuth0 } from '@auth0/auth0-react';
import { styled } from '@mui/material/styles';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { createMeetingflow } from '../../../../utils/DecisionSiteMeetingflowHelpers';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useMeetingflow } from '../../Components/Journey/hooks/useMeetingflow';
import { DetailedMeetingflow } from '@meetingflow/common/Api/data-contracts';
import { ActionButton } from './DSMeetingsCalendarEventRow.styles';
import { Box } from '@mui/material';
import { Close, CheckCircle } from '@mui/icons-material';

interface Props {
  meetingPlanId: string | undefined;
}

export const DSMeetingStatusButton = ({ meetingPlanId }: Props) => {
  const { meetingflow: detailedMeetingflow, scheduleCallRecording } =
    useMeetingflow(meetingPlanId);

  const callRecording = detailedMeetingflow?.callRecording;

  const status = callRecording?.lastStatus;

  const button = useMemo(() => {
    if (!detailedMeetingflow || !callRecording) {
      return null;
    }

    switch (status) {
      case 'scheduled':
        return (
          <DSMeetingScheduledButton
            meetingflow={detailedMeetingflow}
            cancelMeetingflow={scheduleCallRecording} // yes, this function is called scheduleCallRecording :()
          />
        );
      case 'in_call_recording':
        return (
          <DSMeetingRecordingButton
            meetingflow={detailedMeetingflow}
            cancelMeetingflow={scheduleCallRecording} // yes, this function is called scheduleCallRecording :()
          />
        );
      case 'media_expired':
      case 'done':
        if (detailedMeetingflow.callRecording?.recordingFileName) {
          return <DSMeetingDoneButton meetingflow={detailedMeetingflow} />;
        }
        return null;
      default:
        return null;
    }
  }, [status, detailedMeetingflow, scheduleCallRecording, callRecording]);

  if (!detailedMeetingflow) {
    return null;
  }

  if (!callRecording) {
    return null;
  }

  return <>{button}</>;
};

const DSMeetingScheduledButton = ({
  meetingflow,
  cancelMeetingflow,
}: {
  meetingflow: DetailedMeetingflow;
  cancelMeetingflow: (e: React.MouseEvent) => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ActionButton
        hoverBackgroundColor={DEALROOMS_COLORS.crimson}
        backgroundColor={DEALROOMS_COLORS.bone}
        color={
          isHovered ? DEALROOMS_COLORS.white : DEALROOMS_COLORS.themeSecondary
        }
        onClick={cancelMeetingflow}
      >
        {isHovered ? (
          <>
            <Close sx={{ fontSize: '16px', color: DEALROOMS_COLORS.white }} />
            Cancel Recording
          </>
        ) : (
          <>
            <CheckCircle
              sx={{ fontSize: '16px', color: DEALROOMS_COLORS.themeSecondary }}
            />
            Recording Scheduled
          </>
        )}
      </ActionButton>
    </Box>
  );
};

const DSMeetingRecordingButton = ({
  meetingflow,
  cancelMeetingflow,
}: {
  meetingflow: DetailedMeetingflow;
  cancelMeetingflow: (e: React.MouseEvent) => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ActionButton
        hoverBackgroundColor={DEALROOMS_COLORS.crimson}
        backgroundColor={DEALROOMS_COLORS.peach}
        color={
          isHovered ? DEALROOMS_COLORS.white : DEALROOMS_COLORS.themeSecondary
        }
        onClick={cancelMeetingflow}
      >
        {isHovered ? (
          <>
            <Close sx={{ fontSize: '16px', color: DEALROOMS_COLORS.white }} />
            Cancel Recording
          </>
        ) : (
          'Recording In Progress'
        )}
      </ActionButton>
    </Box>
  );
};

const DSMeetingDoneButton = ({
  meetingflow,
  cancelMeetingflow,
}: {
  meetingflow: DetailedMeetingflow;
  cancelMeetingflow?: (e: React.MouseEvent) => void;
}) => {
  return (
    <Box>
      <ActionButton
        hoverBackgroundColor={DEALROOMS_COLORS.peacock}
        backgroundColor={DEALROOMS_COLORS.peacock}
        color={DEALROOMS_COLORS.white}
        disabled={true}
      >
        <CheckCircle
          sx={{ fontSize: '16px', color: DEALROOMS_COLORS.white, mr: 1 }}
        />
        Meeting Recorded
      </ActionButton>
    </Box>
  );
};
