import {
  Contact,
  DetailedUser,
  User,
} from '@meetingflow/common/Api/data-contracts';
import { DSContactAvatar } from '../../DS';
import {
  ContactListItem,
  ContactListItemAvatar,
  ContactListItemText,
  LinkedInBadge,
  SocialIconsStack,
} from './DSContactAvatarAndDetails.styles';
import { OmitUndefinedValues } from '@meetingflow/common/ObjectHelpers';
import { IconButton, Link, Stack } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

// Define a type for the size variants to ensure consistency
type SizeVariant = 'default' | 'large' | 'small' | 'xsmall';

interface DSContactAvatarAndDetailsProps {
  contact: Contact | DetailedUser;
  primaryText?: string;
  secondaryText?: string;
  avatarSize?: number;
  avatarOnly?: boolean;
  hideSocials?: boolean;
  hideEmailAddress?: boolean;
  size?: SizeVariant;
  onClick?: () => void;
  titleOverride?: string;
}

const DSContactSocialsList = ({
  contact,
  size = 'default',
}: {
  contact: Contact | User;
  size?: SizeVariant;
}) => {
  const socials = OmitUndefinedValues({
    linkedIn: contact.linkedInUrl ?? undefined,
  });

  return (
    <Stack direction="row" spacing={0}>
      {Object.entries(socials).map(([platform, url]) => {
        let icon;
        switch (platform) {
          case 'linkedIn':
            icon = (
              <LinkedInIcon
                fontSize={
                  size === 'small' || size === 'xsmall' ? 'inherit' : 'small'
                }
                sx={{
                  fontSize:
                    size === 'xsmall'
                      ? '14px'
                      : size === 'small'
                        ? '16px'
                        : '20px',
                }}
              />
            );
            break;
          default:
            return null;
        }

        return (
          <IconButton
            key={platform}
            size="small"
            component={Link}
            href={url}
            target="_blank"
            rel="noopener"
            onClick={(e) => e.stopPropagation()}
            sx={{
              color: DEALROOMS_COLORS.neutralDark,
              height:
                size === 'xsmall' ? '18px' : size === 'small' ? '20px' : '24px',
              width:
                size === 'xsmall' ? '18px' : size === 'small' ? '20px' : '24px',
              padding: 0,
              margin: 0,
              transition: 'all 0.3s ease',
              backgroundColor: 'transparent',

              '&:hover': {
                color: DEALROOMS_COLORS.neutralDarker,
                backgroundColor: 'transparent',
              },
            }}
          >
            {icon}
          </IconButton>
        );
      })}
    </Stack>
  );
};

export const DSContactAvatarAndDetails = ({
  contact,
  primaryText,
  secondaryText,
  avatarSize,
  avatarOnly = false,
  size = 'default',
  hideSocials = false,
  hideEmailAddress = false,
  onClick,
  titleOverride,
}: DSContactAvatarAndDetailsProps) => {
  // Calculate avatar size based on the size prop if not explicitly provided
  const computedAvatarSize =
    avatarSize ??
    (size === 'large'
      ? 40
      : size === 'small'
        ? 24
        : size === 'xsmall'
          ? 20
          : 32);

  return (
    <ContactListItem
      $size={size}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      <ContactListItemAvatar $size={size}>
        <DSContactAvatar
          contact={contact}
          size={computedAvatarSize}
          titleOverride={titleOverride}
          hideEmailAddress={hideEmailAddress}
        />
        {/* {contact.linkedInUrl && (
          <LinkedInBadge
            href={contact.linkedInUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
            $size={size}
          >
            <LinkedInIcon />
          </LinkedInBadge>
        )} */}
      </ContactListItemAvatar>
      {!avatarOnly && (
        <Stack direction="column" spacing={0}>
          <ContactListItemText
            $size={size}
            primary={primaryText || contact.name || contact.email}
            secondary={
              hideEmailAddress ? null : (
                <>
                  {!hideSocials ? (
                    <SocialIconsStack $size={size}>
                      <DSContactSocialsList contact={contact} size={size} />
                      {secondaryText || contact.email}
                    </SocialIconsStack>
                  ) : (
                    <>{secondaryText || contact.email}</>
                  )}
                </>
              )
            }
            sx={
              hideEmailAddress
                ? {
                    '& .MuiListItemText-primary': {
                      // Dynamically set line height based on size to vertically center text
                      lineHeight:
                        size === 'xsmall'
                          ? '18px'
                          : size === 'small'
                            ? '32px'
                            : size === 'large'
                              ? '40px'
                              : '40px',
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop:
                        size === 'xsmall' || size === 'small'
                          ? '2px'
                          : undefined,
                    },
                  }
                : undefined
            }
          />
        </Stack>
      )}
    </ContactListItem>
  );
};
