import React, { createContext, useContext, useState, ReactNode } from 'react';
import { SvgIconProps } from '@mui/material';

export type BreadcrumbItem = {
  label: string;
  path?: string;
  icon?: React.ReactElement<SvgIconProps>;
  canBeOverridden?: boolean; // Flag to indicate if this breadcrumb can be overridden by a child component
};

type BreadcrumbContextType = {
  breadcrumbs: BreadcrumbItem[];
  setBreadcrumbs: (breadcrumbs: BreadcrumbItem[]) => void;
  setCurrentBreadcrumb: (breadcrumb: BreadcrumbItem) => void;
};

const BreadcrumbContext = createContext<BreadcrumbContextType>({
  breadcrumbs: [],
  setBreadcrumbs: () => {},
  setCurrentBreadcrumb: () => {},
});

export const useBreadcrumb = () => useContext(BreadcrumbContext);

type BreadcrumbProviderProps = {
  children: ReactNode;
  initialBreadcrumbs?: BreadcrumbItem[];
};

export const BreadcrumbProvider: React.FC<BreadcrumbProviderProps> = ({
  children,
  initialBreadcrumbs = [],
}) => {
  const [breadcrumbs, setBreadcrumbs] =
    useState<BreadcrumbItem[]>(initialBreadcrumbs);

  const setCurrentBreadcrumb = (breadcrumb: BreadcrumbItem) => {
    // This function is used by leaf components to set their breadcrumb
    // without affecting the rest of the breadcrumb path
    setBreadcrumbs((prevBreadcrumbs) => {
      // Replace the last breadcrumb or add if there are none
      if (prevBreadcrumbs.length === 0) {
        return [breadcrumb];
      }

      // Check if the last breadcrumb can be overridden
      const lastBreadcrumb = prevBreadcrumbs[prevBreadcrumbs.length - 1];

      // If the last breadcrumb is already the same as what we're trying to set, don't update
      if (lastBreadcrumb.label === breadcrumb.label) {
        return prevBreadcrumbs;
      }

      if (lastBreadcrumb.canBeOverridden) {
        return [...prevBreadcrumbs.slice(0, -1), breadcrumb];
      }

      // If we can't override, just append the new breadcrumb
      return [...prevBreadcrumbs, breadcrumb];
    });
  };

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbs,
        setBreadcrumbs,
        setCurrentBreadcrumb,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};
