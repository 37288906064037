import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { KeyStatement } from '@meetingflow/common/Api/data-contracts';
import { MeetingflowsApiClient } from '../../../../Services/NetworkCommon';
import { DSKeyStatement } from '../../Tabs/Contacts/DSKeyStatement';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { DSStatusMessage } from '../../Components/DSStatusMessage';
import { useMeetingflow } from './hooks/useMeetingflow';

interface DecisionSiteMeetingPanelKeyStatementsTabProps {
  meetingPlanId: string;
}

interface KeyStatementsResponse {
  keyStatements: KeyStatement[];
  extractionStatus: 'in_progress' | 'completed' | 'failed';
}

// Mock data that matches the API response structure
const MOCK_KEY_STATEMENTS: KeyStatementsResponse = {
  keyStatements: [
    {
      speaker: 'andrew@augment.co',
      text: "By the way, in the investor call today, Yes. Marcel made the point strongly that While it was interesting to have internal meetings that Progres deal flows. It was gonna be necessary because that's what the Grid X guys are doing. It was gonna be necessary for the sellers to decide what is and isn't progressive for the deal room. So even if the Internal meeting was pertinent to the deal room progress, it wasn't necessarily ready for the clients to see it. And in other cases, it might be. So make sure that the seller version of the deal room allowed for the possibility of internal meetings that don't get published. Makes sense?",
      sentimentScore: 0.8679,
      classification: 'CRITICAL',
      confidence: 0.9,
      timestamp: null,
      meetingPlanId: 'f3b68575-9fa1-43d9-9ab9-af6f967f4863',
    },
    {
      speaker: 'tyler@meetingflow.com',
      text: 'I love that idea.',
      sentimentScore: 0.6369,
      classification: 'SUPPORTIVE',
      confidence: 0.9,
      timestamp: null,
      meetingPlanId: 'f3b68575-9fa1-43d9-9ab9-af6f967f4863',
    },
    {
      speaker: 'julien@augment.co',
      text: "Well, yeah. Yeah. Full recording. Yeah. Yeah. And Yeah. For the the pretty much a Meetingflow flow view needs to be seen by the seller and the buyer. Next step. Like, suggested next step. Once you're done with the call, I'd like to roast this point. What what you should try to work for towards So action plan for seller. So it's not just the extension plan. Like, right, AI could even create an email template that recaps the call. Not only you have the trans transcript, but it creates an email based on the transcript That recaps it. Thanks Cahen for attending. And then suggest the next step. Or recaps the next step that was discussed on the call if one was discussed on the call.",
      sentimentScore: 0.9418,
      classification: 'SUPPORTIVE',
      confidence: 0.9,
      timestamp: null,
      meetingPlanId: 'f3b68575-9fa1-43d9-9ab9-af6f967f4863',
    },
  ],
  extractionStatus: 'completed',
};

// Toggle for mock data - can be controlled by env var or feature flag
const USE_MOCK_DATA = false; // TODO: Replace with proper feature flag or env var

export const DecisionSiteMeetingPanelKeyStatementsTab: React.FC<
  DecisionSiteMeetingPanelKeyStatementsTabProps
> = ({ meetingPlanId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { organization } = useOrganization();
  const { meetingflow } = useMeetingflow(meetingPlanId);

  const {
    data: keyStatementsResponse,
    isLoading,
    error,
  } = useQuery<KeyStatementsResponse>(
    ['keyStatements', organization?.slug, meetingPlanId],
    async () => {
      if (USE_MOCK_DATA) {
        // Simulate network delay
        await new Promise((resolve) => setTimeout(resolve, 500));
        return MOCK_KEY_STATEMENTS;
      }

      if (!organization?.slug) {
        return {
          keyStatements: [],
          extractionStatus: 'completed',
        } as KeyStatementsResponse;
      }
      const token = await getAccessTokenSilently();
      const response =
        await MeetingflowsApiClient.getCallRecordingKeyStatements(
          {
            organizationSlug: organization.slug,
            meetingPlanId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
      return response.data as KeyStatementsResponse;
    },
    {
      enabled: !!organization?.slug && !!meetingPlanId,
    },
  );

  if (isLoading) {
    return (
      <DSStatusMessage
        icon={FormatQuoteIcon}
        title="Loading Key Statements..."
        iconSize={48}
      />
    );
  }

  if (error) {
    return (
      <DSStatusMessage
        icon={FormatQuoteIcon}
        title="Unable to Load Key Statements"
        subtitle="There was an error loading the key statements. Please try again later."
        iconColor="error.main"
        titleColor="error"
        iconSize={48}
      />
    );
  }

  const keyStatements = keyStatementsResponse?.keyStatements || [];
  const extractionStatus = keyStatementsResponse?.extractionStatus;

  if (!keyStatements.length) {
    if (extractionStatus === 'in_progress') {
      return (
        <DSStatusMessage
          icon={FormatQuoteIcon}
          title="Extracting Key Statements"
          subtitle="We're analyzing this meeting to extract key statements."
          iconColor="primary.main"
          titleColor="primary"
          iconSize={48}
        />
      );
    }

    return (
      <DSStatusMessage
        icon={FormatQuoteIcon}
        title="No Key Statements Available"
        subtitle="No key statements have been analyzed for this meeting yet."
        iconColor="text.secondary"
        titleColor="text.secondary"
        iconSize={48}
      />
    );
  }

  // Find matching contact from meetingflow attendees for each key statement
  const getContactForStatement = (statement: KeyStatement) => {
    if (!meetingflow?.attendees || !statement.speaker) return undefined;

    // Find the attendee whose email matches the speaker
    // Use the useMeetingflowSpeakers hook's getContactForParticipant function
    return meetingflow.attendees.find((attendee) => {
      // Get the email from the attendee object (structure may vary)
      const attendeeEmail = attendee.email || '';
      return attendeeEmail.toLowerCase() === statement.speaker?.toLowerCase();
    });
  };

  return (
    <Box sx={{ paddingTop: '.5rem' }}>
      {keyStatements.map((statement: KeyStatement, index: number) => {
        const matchingAttendee = getContactForStatement(statement);

        return (
          <DSKeyStatement
            key={index}
            statement={statement}
            contact={matchingAttendee}
            displaySpeakerName
            displayMeetingInfo={false}
          />
        );
      })}
    </Box>
  );
};
