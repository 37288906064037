import React, { useEffect, useMemo, useState, useRef } from 'react';
import {
  Box,
  Divider,
  TextField,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  Chip,
} from '@mui/material';
import TableViewIcon from '@mui/icons-material/TableView';
import ViewListIcon from '@mui/icons-material/ViewList';
import FilterListIcon from '@mui/icons-material/FilterList';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  CompactAutocomplete,
  CollapsibleTableContainer,
  MainContainer,
  FilterContainer,
  compactDatePickerTextFieldProps,
  ButtonContainer,
  FlexibleContainer,
} from './DSSellerHubActivityTable.styles';
import DSTooltip from '../DS/DSTooltip';
import DSSellerHubMemberActivityChart from './DSSellerHubMemberActivityChart';
import DSSellerHubMemberActivityBubbleChart from './DSSellerHubMemberActivityBubbleChart';
import DSButton from '../DS/DSButton';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocalStorageState } from '../../../Hooks/useLocalStorageState';
import { useSearchParams } from 'react-router-dom';
import {
  UserActivity,
  UserActivityType,
  Contact,
} from '@meetingflow/common/Api/data-contracts';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { useQuery, useQueryClient } from 'react-query';
import { useOrganization } from '../../../Hooks/useOrganization';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { ANALYTICS_TYPES } from './DSSellerHubTypes';
import DSListAndDetailPanel, { Column } from '../DS/DSListAndDetailPanel';
import {
  useDSActivity,
  UserActivityWithId,
  AutocompleteContact,
} from './context/DSActivityContext';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { DSContactAvatarAndDetails } from '../Tabs/Contacts/DSContactAvatarAndDetails';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import DSResponsiveDrawer from '../../../Components/Common/DSResponsiveDrawer';
import CloseIcon from '@mui/icons-material/Close';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete';

// Use a very high page size to ensure we get all data for the date range (server now allows max: 1000)
const PAGE_SIZE = 1000;

// Default number of days to show in the activity chart
const DEFAULT_DATE_RANGE_DAYS = 30;

type Order = 'asc' | 'desc';
type OrderBy = 'createdAt' | 'user.name';
interface DSSellerHubActivityTableProps {
  userIds?: number[];
  hideUserFilter?: boolean;
  allowExpand?: boolean;
  tableMaxHeightOffset?: number;
  customMaxHeight?: string;
  disableUrlSync?: boolean;
  instanceId?: number | string; // Add instanceId prop for multiple instances
}

// Types now imported from DSActivityContext

interface ActivitiesResponse {
  activities: UserActivityWithId[];
  totalCount: number;
  hasMore: boolean;
  currentPage: number;
}

interface ColumnWithSort extends Column<UserActivityWithId, OrderBy> {
  sortKey?: OrderBy;
}

export const DSSellerHubActivityTable = ({
  userIds,
  hideUserFilter = false,
  allowExpand = false,
  tableMaxHeightOffset,
  customMaxHeight = 'none',
  disableUrlSync = false,
  instanceId,
}: DSSellerHubActivityTableProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { organization } = useOrganization();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  // Get dealRoom using the proper hook
  const {
    dealRoom,
    refetch: refetchDealRoom,
    isLoading: isDealRoomLoading,
  } = useDealRoom();
  const dealRoomId = dealRoom?.id;

  // For performance with large datasets
  const virtualScrollEnabled = true;

  // Get values from context
  const {
    activitiesData,
    isActivitiesLoading,
    selectedContacts,
    setSelectedContacts,
    selectedActivityTypes,
    setSelectedActivityTypes,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    fetchNextPage,
    isFetchingNextPage,
    refetchActivities,
    hasNextPage,
    // Get URL sync related values
    enableUrlSync,
    setEnableUrlSync,
  } = useDSActivity(instanceId);

  // Add a debug effect to log when user IDs change
  useEffect(() => {
    if (userIds && userIds.length > 0) {
    }
  }, [userIds]);

  // Special handling for disableUrlSync: set contacts directly without URL updates
  useEffect(() => {
    if (disableUrlSync && userIds && userIds.length > 0) {
      const syntheticContacts = userIds.map((userId) => ({
        id: `synthetic-${userId}`,
        name: `User ${userId}`,
        email: '',
        userId: userId,
      }));
      setSelectedContacts(syntheticContacts);
    }
  }, [disableUrlSync, userIds, setSelectedContacts]);

  // Set the enableUrlSync value based on the disableUrlSync prop
  useEffect(() => {
    if (enableUrlSync !== !disableUrlSync) {
      setEnableUrlSync(!disableUrlSync);
    }
  }, [disableUrlSync, enableUrlSync, setEnableUrlSync]);

  const { mutualPlan } = useMutualPlan(organization?.slug, dealRoomId);

  // Memoize the extraction of action item IDs from the mutual plan
  const allActionItemIds = useMemo(() => {
    // Return an empty array if there's no mutual plan or no milestones
    if (!mutualPlan?.milestones?.length) return [];

    // Flatten all action items from all milestones and extract their IDs
    return mutualPlan.milestones.flatMap(
      (milestone) => milestone.actionItems?.map((item) => item.id) ?? [],
    );
  }, [mutualPlan]);

  // Filter menu state
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const filterMenuOpen = Boolean(filterAnchorEl);

  // Use the same localStorage key as in DSCompaniesListDetail
  const [showInternal, setShowInternal] = useLocalStorageState<boolean>(
    'dealroom-show-internal',
    false,
  );

  // Handle opening the filter menu
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  // Handle closing the filter menu
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  // Handle toggling the show internal option
  const handleShowInternalToggle = () => {
    setShowInternal(!showInternal, true); // Pass true to emit storage event
  };

  // All these derived values are now handled in the context

  const [selectedActivity, setSelectedActivity] =
    useState<UserActivityWithId | null>(() => {
      const activityId = searchParams.get('activity');
      if (!activityId) return null;

      const activities =
        activitiesData?.pages?.flatMap((page) => page.activities) || [];

      return activities.find((a) => a.id === activityId) || null;
    });

  // Make API call to get artifacts list for rendering activities
  const {
    data: allArtifactsData,
    isLoading: isArtifactsLoading,
    refetch: refetchArtifacts,
  } = useQuery(
    ['artifacts-analytics', dealRoomId, organization?.slug],
    async () => {
      const token = await getAccessTokenSilently();
      const slug = organization?.slug || '';
      const id = dealRoomId || '';

      if (!slug || !id) {
        return { data: [] };
      }

      return DealRoomsApiClient.listArtifacts(
        {
          organizationSlug: slug,
          dealRoomId: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: Boolean(organization?.slug) && Boolean(dealRoomId),
    },
  );

  useEffect(() => {
    refetchArtifacts();
  }, [refetchArtifacts]);

  useEffect(() => {
    refetchDealRoom();
  }, [refetchDealRoom]);

  useEffect(() => {
    refetchActivities();
  }, [refetchActivities]);

  useEffect(() => {
    queryClient?.invalidateQueries('dealroom-activities');
  }, [queryClient]);

  // Transform deal room contacts into the format needed for the Autocomplete
  // Only include contacts that have a userId value
  const autocompleteContacts = useMemo(() => {
    if (!dealRoom?.contacts) return [];

    return dealRoom.contacts
      .filter(
        (contact) => contact.userId !== null && contact.userId !== undefined,
      )
      .map((contact) => ({
        id: contact.id.toString(),
        name: contact.name || '',
        email: contact.email || '',
        userId: contact.userId,
      }));
  }, [dealRoom?.contacts]);

  // Get list of available activity types from ANALYTICS_TYPES
  const availableActivityTypes = useMemo(() => {
    return Object.keys(ANALYTICS_TYPES) as UserActivityType[];
  }, []);

  // Combine loading states from both queries
  const isLoading =
    isArtifactsLoading || isDealRoomLoading || isActivitiesLoading;

  // Get internal domains from organization data
  const internalDomains: string[] = useMemo(() => {
    if (!organization?.domainRules?.length) {
      return [];
    }
    return organization.domainRules.map(
      (rule: { domain: string }) => rule.domain,
    );
  }, [organization?.domainRules]);

  // For contact filtering, get the list of unique emails
  const uniqueContactEmails = useMemo(() => {
    if (!dealRoom?.contacts) return new Set<string>();

    return new Set(
      dealRoom.contacts
        .filter((contact) => Boolean(contact.email))
        .map((contact) => contact.email as string),
    );
  }, [dealRoom?.contacts]);

  const sortedActivities = useMemo(() => {
    const allActivities =
      activitiesData?.pages?.flatMap((page) => page.activities) || [];

    // Filter out internal contacts if showInternal is false
    if (!showInternal && internalDomains.length > 0) {
      return allActivities.filter((activity) => {
        // If not showing internal, filter out activities from users with internal domains
        const contact = dealRoom?.contacts?.find(
          (c: { userId?: string | number | null }) =>
            c.userId !== null &&
            c.userId !== undefined &&
            String(c.userId) === String(activity.userId),
        );
        if (!contact?.email) return true;

        const emailDomain = contact.email.split('@')[1];
        return !emailDomain || !internalDomains.includes(emailDomain);
      });
    }

    return [...allActivities];
  }, [activitiesData, showInternal, dealRoom?.contacts, internalDomains]);

  const columns = useMemo(() => {
    const baseColumns: Column<UserActivityWithId, OrderBy>[] = [
      {
        id: 'createdAt',
        label: 'Time',
        width: 130,
        style: {
          padding: '.25rem 1rem .25rem 1rem',
        },
        sortKey: 'createdAt',
        getValue: (activity: UserActivityWithId) => (
          <Box sx={{ display: 'flex', alignItems: 'center', height: 32 }}>
            <Typography variant="caption" noWrap>
              {humanizeDateTime(activity.createdAt, {
                displayComponents: ['date', 'time'],
                dateFormatOptions: {
                  day: 'numeric',
                  month: 'short',
                },
                timeFormatOptions: {
                  hour: 'numeric',
                  minute: 'numeric',
                },
              })}
            </Typography>
          </Box>
        ),
      },
      {
        id: 'user',
        label: 'User',
        width: 120,
        style: {
          padding: '.25rem 1rem .25rem 1rem',
        },
        getValue: (activity: UserActivityWithId) => {
          const user = activity.user;
          // If user has first name or last name, use them to display the user name
          const currentUserName =
            [user?.firstName, user?.lastName].filter(Boolean).join(' ') || '';

          // Try to find matching contact if user info is missing
          const contact = dealRoom?.contacts?.find(
            (c) => c.userId === activity.userId,
          );

          const name = contact?.name || currentUserName || '';

          // Create a handler to navigate to contact page
          const handleContactClick = () => {
            if (contact?.id) {
              setSearchParams({
                contact: contact.id.toString(),
                tab: 'activity',
              });
            }
          };

          return (
            <DSContactAvatarAndDetails
              contact={
                contact ||
                ({
                  name,
                  email: activity.user?.email || '',
                  avatarUrl: user?.avatarFileUrl || user?.avatarUrl || null,
                } as Contact)
              }
              size="xsmall"
              hideSocials
              hideEmailAddress
              onClick={contact?.id ? handleContactClick : undefined}
            />
          );
        },
      },
    ];

    baseColumns.push({
      id: 'activity',
      label: 'Activity',
      style: {
        padding: '.25rem .5rem',
      },
      getValue: (activity: UserActivityWithId) => {
        const activityType = ANALYTICS_TYPES[activity.type];

        return (
          <Box sx={{ flex: 1 }}>
            {dealRoom?.id &&
              activityType?.renderer &&
              activityType.renderer({
                activity,
                organizationSlug: organization?.slug,
                dealRoomId: dealRoom.id.toString(),
                allArtifacts: allArtifactsData?.data || [],
                allActionItemIds,
              })}
          </Box>
        );
      },
    });

    return baseColumns;
  }, [
    dealRoom,
    organization?.slug,
    allArtifactsData,
    allActionItemIds,
    setSearchParams,
  ]);

  // Group activities by day and type for visualization
  const chartData = useMemo(() => {
    if (!sortedActivities) return [];

    if (!sortedActivities || sortedActivities.length === 0) return [];

    // Create a map to count activities by day and type
    const activityCountsByDay = new Map<
      string,
      Record<string, number | string>
    >();

    // Track min and max dates as strings (YYYY-MM-DD format)
    let minDateStr: string | null = null;
    let maxDateStr: string | null = null;

    // Process each activity
    sortedActivities.forEach((activity) => {
      // Parse the activity date
      const activityDate = new Date(activity.createdAt);

      // Format as YYYY-MM-DD (daily format)
      const dayKey = activityDate.toISOString().split('T')[0];

      // Update min/max date strings
      if (!minDateStr || dayKey < minDateStr) minDateStr = dayKey;
      if (!maxDateStr || dayKey > maxDateStr) maxDateStr = dayKey;

      // Get the activity type label
      const activityType =
        ANALYTICS_TYPES[activity.type]?.label ||
        activity.type
          .split('_')
          .map((word) => word.toLowerCase())
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

      // Initialize the day entry if it doesn't exist
      if (!activityCountsByDay.has(dayKey)) {
        activityCountsByDay.set(dayKey, { day: dayKey, total: 0 });
      }

      // Get the current day entry
      const dayEntry = activityCountsByDay.get(dayKey)!;

      // Increment the total count
      dayEntry.total =
        (typeof dayEntry.total === 'number' ? (dayEntry.total as number) : 0) +
        1;

      // Increment the count for this specific activity type
      if (!dayEntry[activityType]) {
        dayEntry[activityType] = 0;
      }
      dayEntry[activityType] =
        (typeof dayEntry[activityType] === 'number'
          ? (dayEntry[activityType] as number)
          : 0) + 1;
    });

    // If we have min and max dates, fill in the gaps with zero values
    if (minDateStr && maxDateStr) {
      // Get all unique activity types from existing data
      const allActivityTypes = new Set<string>();
      activityCountsByDay.forEach((entry) => {
        Object.keys(entry).forEach((key) => {
          if (key !== 'day' && key !== 'total') {
            allActivityTypes.add(key);
          }
        });
      });

      // Create a template for empty days
      const createEmptyDayEntry = (dayKey: string) => {
        const entry: Record<string, number | string> = {
          day: dayKey,
          total: 0,
        };
        allActivityTypes.forEach((type) => {
          entry[type] = 0;
        });
        return entry;
      };

      // Fill in all days between min and max date
      const minDate = new Date(minDateStr);
      const maxDate = new Date(maxDateStr);

      // Reset hours to ensure we're comparing full days
      minDate.setHours(0, 0, 0, 0);
      maxDate.setHours(0, 0, 0, 0);

      const currentDate = new Date(minDate);

      // Loop through each day and fill in gaps
      while (currentDate <= maxDate) {
        const dayKey = currentDate.toISOString().split('T')[0];

        if (!activityCountsByDay.has(dayKey)) {
          activityCountsByDay.set(dayKey, createEmptyDayEntry(dayKey));
        }

        // Move to next day
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    // Convert the map to an array and sort by day
    return Array.from(activityCountsByDay.values()).sort((a, b) =>
      (a.day as string).localeCompare(b.day as string),
    );
  }, [sortedActivities]);

  // Get unique activity types for chart colors
  const uniqueActivityTypes = useMemo(() => {
    const types = new Set<string>();
    if (!chartData || chartData.length === 0) return [];

    chartData.forEach((dayData) => {
      Object.keys(dayData).forEach((key) => {
        if (key !== 'day' && key !== 'total') {
          types.add(key);
        }
      });
    });

    return Array.from(types);
  }, [chartData]);

  const [tableExpanded, setTableExpanded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // Effect to handle specific cases where we need to collapse the table
  useEffect(() => {
    // Only collapse the table view when there are no activities
    if (sortedActivities.length === 0 && tableExpanded) {
      setTableExpanded(false);
      setModalOpen(false);
    }
  }, [sortedActivities.length, tableExpanded]);

  // Preserve drawer open state during filter changes
  const drawerOpenRef = useRef(false);

  useEffect(() => {
    // Only update the ref when modalOpen changes directly (not due to filter changes)
    drawerOpenRef.current = modalOpen;
  }, [modalOpen]);

  // Create wrapped filter change handlers that preserve drawer state
  const handleStartDateChange = (newValue: DateTime | null) => {
    // Save current drawer state
    const wasOpen = drawerOpenRef.current;

    // Apply the filter change
    setStartDate(newValue);

    // Clear activity selection when changing filters
    setSelectedActivity(null);

    // If end date is before the new start date, update end date to match start date
    if (endDate && newValue && endDate < newValue) {
      setEndDate(newValue);
    }

    // Restore drawer state on next tick after state updates
    if (wasOpen) {
      setTimeout(() => {
        setModalOpen(true);
      }, 0);
    }
  };

  const handleEndDateChange = (newValue: DateTime | null) => {
    // Save current drawer state
    const wasOpen = drawerOpenRef.current;

    // Prevent setting end date earlier than start date
    if (newValue && startDate && newValue < startDate) {
      // Show error message or keep current value
      return;
    }

    // Apply the filter change
    setEndDate(newValue);

    // Clear activity selection when changing filters
    setSelectedActivity(null);

    // Restore drawer state on next tick after state updates
    if (wasOpen) {
      setTimeout(() => {
        setModalOpen(true);
      }, 0);
    }
  };

  // Handle contact filter changes with explicit URL update
  const handleContactsChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: unknown,
    _reason: AutocompleteChangeReason,
    _details?: AutocompleteChangeDetails<unknown>,
  ) => {
    const newContacts = value as AutocompleteContact[];
    setSelectedContacts(newContacts);

    // Explicitly update URL if URL sync is enabled
    if (enableUrlSync) {
      const newParams = new URLSearchParams(searchParams);

      if (newContacts.length > 0) {
        // Get user IDs from selected contacts
        const userIds = newContacts
          .filter((c) => c.userId !== null)
          .map((c) => c.userId);

        if (userIds.length > 0) {
          newParams.set('userFilter', userIds.join(','));
        } else {
          newParams.delete('userFilter');
        }
      } else {
        // If no contacts selected, remove the userFilter param
        newParams.delete('userFilter');
      }

      // Update URL
      setSearchParams(newParams);
    }
  };

  const handleActivityTypesChange = (
    event: React.SyntheticEvent,
    value: unknown,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown>,
  ) => {
    // Save current drawer state
    const wasOpen = drawerOpenRef.current;

    // Apply the filter change
    setSelectedActivityTypes(value as UserActivityType[]);

    // Clear activity selection when changing filters
    setSelectedActivity(null);

    // Restore drawer state on next tick after state updates
    if (wasOpen) {
      setTimeout(() => {
        setModalOpen(true);
      }, 0);
    }
  };

  const handleExpandClick = () => {
    const newExpandedState = !tableExpanded;
    setTableExpanded(newExpandedState);
    setModalOpen(newExpandedState);
    drawerOpenRef.current = newExpandedState;
  };

  const handleModalClose = () => {
    setTableExpanded(false);
    setModalOpen(false);
    drawerOpenRef.current = false;
  };

  // Function to handle backdrop clicks, preventing accidental drawer closing
  const handleDrawerClose = (event: React.SyntheticEvent) => {
    // We want to close the drawer when clicking outside (backdrop clicks)
    // but prevent closing when interacting with filters

    // For click events on the backdrop, close the drawer
    if (event.target === event.currentTarget) {
      handleModalClose();
      return;
    }

    // For keydown events, allow ESC key to close the drawer
    if (event.type === 'keydown') {
      const keyEvent = event as React.KeyboardEvent;
      if (keyEvent.key === 'Escape') {
        handleModalClose();
      }
    }
  };

  const handleRequestSort = (property: OrderBy) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <MainContainer>
      <FilterContainer expanded={tableExpanded}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            slotProps={{
              textField: {
                ...compactDatePickerTextFieldProps,
                error: startDate ? !startDate.isValid : undefined,
                helperText:
                  startDate && !startDate.isValid ? 'Invalid date' : undefined,
              },
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            slotProps={{
              textField: {
                ...compactDatePickerTextFieldProps,
                error: endDate ? !endDate.isValid : undefined,
                helperText:
                  endDate && !endDate.isValid ? 'Invalid date' : undefined,
              },
            }}
            minDate={startDate || undefined}
          />
        </LocalizationProvider>
        {!hideUserFilter && (
          <CompactAutocomplete
            multiple
            id="contact-filter"
            options={autocompleteContacts}
            value={selectedContacts}
            onChange={handleContactsChange}
            getOptionLabel={(option) =>
              (option as AutocompleteContact).name ||
              (option as AutocompleteContact).email ||
              'Unknown Contact'
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter by User"
                placeholder="Select users"
                size="small"
              />
            )}
          />
        )}
        <CompactAutocomplete
          multiple
          id="activity-type-filter"
          options={availableActivityTypes}
          value={selectedActivityTypes}
          onChange={handleActivityTypesChange}
          getOptionLabel={(option) => {
            const activityType = ANALYTICS_TYPES[option as UserActivityType];
            return (
              activityType?.label ||
              (option as string)
                .split('_')
                .map((word) => word.toLowerCase())
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Filter by Activity"
              placeholder="Select activity"
              size="small"
            />
          )}
        />
        <ButtonContainer>
          {/* Filter Button */}
          <IconButton
            id="filter-button"
            aria-controls={filterMenuOpen ? 'filter-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={filterMenuOpen ? 'true' : undefined}
            onClick={handleFilterClick}
            size="small"
            sx={{
              marginRight: '8px',
              color: DEALROOMS_COLORS.cloudburst,
            }}
          >
            <FilterListIcon fontSize="small" />
          </IconButton>

          {/* Filter Menu */}
          <Menu
            id="filter-menu"
            anchorEl={filterAnchorEl}
            open={filterMenuOpen}
            onClose={handleFilterClose}
            MenuListProps={{
              'aria-labelledby': 'filter-button',
              dense: true,
            }}
          >
            <MenuItem onClick={handleShowInternalToggle}>
              <Checkbox checked={showInternal} size="small" color="primary" />
              <Typography variant="body2" sx={{ ml: 1 }}>
                Show Internal
              </Typography>
              <DSTooltip
                title="When enabled, shows all people. When disabled, only shows external people."
                placement="right"
                arrow
              >
                <IconButton size="small" sx={{ ml: 1 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </DSTooltip>
            </MenuItem>
          </Menu>

          {allowExpand && sortedActivities.length > 0 && (
            <DSTooltip title={`${tableExpanded ? 'Hide' : 'Show'} Table View`}>
              <DSButton
                onClick={handleExpandClick}
                iconOnly
                startIcon={<TableViewIcon />}
                sx={{
                  backgroundColor: tableExpanded
                    ? DEALROOMS_COLORS.cloudburst
                    : DEALROOMS_COLORS.white,
                  color: tableExpanded
                    ? DEALROOMS_COLORS.white
                    : DEALROOMS_COLORS.cloudburst,
                }}
              >
                Table
              </DSButton>
            </DSTooltip>
          )}
        </ButtonContainer>
      </FilterContainer>

      <Box
        sx={{
          padding: '1rem',
        }}
      >
        <DSSellerHubMemberActivityBubbleChart
          chartData={chartData}
          uniqueActivityTypes={uniqueActivityTypes}
          activities={sortedActivities}
          isLoading={isLoading}
          hideTotal={selectedContacts.length === 1} // Hide total row when filtering by a single user
        />
      </Box>

      <CollapsibleTableContainer expanded={false}></CollapsibleTableContainer>

      {/* Drawer for expanded table view */}
      <DSResponsiveDrawer
        open={modalOpen}
        onClose={handleDrawerClose}
        anchor="right"
        widthPercentage={70}
        minWidth={600}
        maxWidth={1200}
        padding="1rem"
        onClick={(e) => {
          // Prevent event propagation to stop it from reaching the backdrop
          e.stopPropagation();
        }}
        ModalProps={{
          BackdropProps: {
            onClick: (e) => {
              // Only close if clicking directly on the backdrop
              if (e.target === e.currentTarget) {
                handleModalClose();
              }
            },
          },
          // Prevent closing when interacting with elements inside
          disableAutoFocus: true,
          disableEnforceFocus: true,
          disableRestoreFocus: true,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {/* Include filter bar inside the drawer */}
          <FilterContainer expanded={true}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                slotProps={{
                  textField: {
                    ...compactDatePickerTextFieldProps,
                    error: startDate ? !startDate.isValid : undefined,
                    helperText:
                      startDate && !startDate.isValid
                        ? 'Invalid date'
                        : undefined,
                  },
                }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={handleEndDateChange}
                slotProps={{
                  textField: {
                    ...compactDatePickerTextFieldProps,
                    error: endDate ? !endDate.isValid : undefined,
                    helperText:
                      endDate && !endDate.isValid ? 'Invalid date' : undefined,
                  },
                }}
                minDate={startDate || undefined}
              />
            </LocalizationProvider>
            {!hideUserFilter && (
              <CompactAutocomplete
                multiple
                id="contact-filter-drawer"
                options={autocompleteContacts}
                value={selectedContacts}
                onChange={handleContactsChange}
                getOptionLabel={(option) =>
                  (option as AutocompleteContact).name ||
                  (option as AutocompleteContact).email ||
                  'Unknown Contact'
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Filter by User"
                    placeholder="Select users"
                    size="small"
                  />
                )}
              />
            )}
            <CompactAutocomplete
              multiple
              id="activity-type-filter-drawer"
              options={availableActivityTypes}
              value={selectedActivityTypes}
              onChange={handleActivityTypesChange}
              getOptionLabel={(option) => {
                const activityType =
                  ANALYTICS_TYPES[option as UserActivityType];
                return (
                  activityType?.label ||
                  (option as string)
                    .split('_')
                    .map((word) => word.toLowerCase())
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Filter by Activity"
                  placeholder="Select activity"
                  size="small"
                />
              )}
            />
            <ButtonContainer>
              {/* Filter Button */}
              <IconButton
                id="filter-button-drawer"
                aria-controls={
                  filterMenuOpen ? 'filter-menu-drawer' : undefined
                }
                aria-haspopup="true"
                aria-expanded={filterMenuOpen ? 'true' : undefined}
                onClick={handleFilterClick}
                size="small"
                sx={{
                  marginRight: '8px',
                  color: DEALROOMS_COLORS.cloudburst,
                }}
              >
                <FilterListIcon fontSize="small" />
              </IconButton>

              {/* Close Drawer Button */}
              <DSTooltip title="Close Table View">
                <DSButton
                  onClick={handleModalClose}
                  iconOnly
                  startIcon={<CloseIcon />}
                  sx={{
                    backgroundColor: DEALROOMS_COLORS.cloudburst,
                    color: DEALROOMS_COLORS.white,
                  }}
                  data-close-drawer="true"
                >
                  Close
                </DSButton>
              </DSTooltip>
            </ButtonContainer>
          </FilterContainer>

          {/* Table content */}
          <FlexibleContainer>
            <DSListAndDetailPanel<UserActivityWithId, string, OrderBy>
              objectList={sortedActivities}
              selectedObjectId={selectedActivity?.id || null}
              onSelectObject={(id) => {
                const activity = sortedActivities.find((a) => a.id === id);
                if (activity) {
                  setSelectedActivity(activity);
                  // Preserve other URL parameters when selecting an activity
                  const newParams = new URLSearchParams(searchParams);
                  newParams.set('activity', activity.id);
                  setSearchParams(newParams);
                }
              }}
              onCloseObjectPanel={() => {
                setSelectedActivity(null);
                // Preserve other URL parameters when closing the activity panel
                const newParams = new URLSearchParams(searchParams);
                newParams.delete('activity');
                setSearchParams(newParams);
              }}
              objectTypeKey="activity"
              getItemId={(activity) => activity.id}
              columns={columns}
              sortBy={orderBy}
              sortOrder={order}
              onSort={handleRequestSort}
              isLoading={isLoading}
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={Boolean(hasNextPage)}
              onLoadMore={() => {
                if (!isFetchingNextPage && hasNextPage) {
                  fetchNextPage();
                }
              }}
              renderDetail={(activity: UserActivityWithId) => <></>}
              listOnly
              customMaxHeight="calc(100vh - 10rem)" /* Adjust height to account for filter bar */
            />
          </FlexibleContainer>
        </Box>
      </DSResponsiveDrawer>
    </MainContainer>
  );
};
