import React from 'react';
import {
  CompaniesApiClient,
  ContactsApiClient,
} from '../../../../Services/NetworkCommon';
import { useQuery } from 'react-query';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography, Divider, Box, Chip } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { DSStatusMessage } from '../../Components/DSStatusMessage';
import {
  PendingOutlined,
  ErrorOutline,
  HourglassEmpty,
  Psychology,
  InfoOutlined,
} from '@mui/icons-material';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Tooltip,
  TooltipProps,
} from 'recharts';
import {
  PersonalityContainer,
  AssessmentColumn,
  ChartContainer,
  TraitLegend,
  TraitRow,
  TraitLabel,
  chartAxisStyle,
  radarChartStyle,
  dotStyle,
  StyledResponsiveContainer,
  AdjectiveChipsContainer,
  AdjectiveChip,
} from './DSContactDetailPersonality.styles';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import DSTooltip from '../../DS/DSTooltip';

// Trait description constants
const TRAIT_DESCRIPTIONS = {
  DISC: {
    D: 'People high in Dominance tend to be assertive, direct, and focused on achieving results. They are often confident, competitive, and independent.',
    I: 'People high in Influence tend to be outgoing, enthusiastic, and persuasive. They are often charismatic, sociable, and optimistic.',
    S: 'People high in Steadiness tend to be patient, dependable, and supportive. They are often calm, loyal, and empathetic.',
    C: 'People high in Conscientiousness tend to be analytical, detail-oriented, and systematic. They are often precise, logical, and disciplined.',
  },
  OCEAN: {
    O: 'Openness reflects willingness to try new things and engage in imaginative activities. People high in openness are typically creative, curious, and appreciative of art and beauty.',
    C: 'Conscientiousness reflects self-discipline, organization, and goal-directed behavior. People high in conscientiousness are typically reliable, hardworking, and detail-oriented.',
    E: 'Extraversion reflects sociability and assertiveness. People high in extraversion are typically outgoing, energetic, and draw energy from social interactions.',
    A: 'Agreeableness reflects concern for social harmony and cooperation. People high in agreeableness are typically compassionate, helpful, and trusting of others.',
    N: 'Neuroticism (opposite of Emotional Stability) reflects tendency to experience negative emotions. People high in neuroticism may experience more anxiety, mood swings, and emotional reactivity.',
  },
};

interface DSContactDetailPersonalityProps {
  contactId: number;
}

const getTraitColor = (trait: string, model: 'DISC' | 'OCEAN') => {
  if (model === 'DISC') {
    switch (trait) {
      case 'D':
        return DEALROOMS_COLORS.crimson;
      case 'I':
        return DEALROOMS_COLORS.peacock;
      case 'S':
        return DEALROOMS_COLORS.plum100;
      case 'C':
        return DEALROOMS_COLORS.orchid;
      default:
        return DEALROOMS_COLORS.cloudburst;
    }
  } else {
    switch (trait) {
      case 'O':
        return DEALROOMS_COLORS.crimson;
      case 'C':
        return DEALROOMS_COLORS.peacock;
      case 'E':
        return DEALROOMS_COLORS.plum100;
      case 'A':
        return DEALROOMS_COLORS.orchid;
      case 'N':
        return DEALROOMS_COLORS.plum50;
      default:
        return DEALROOMS_COLORS.cloudburst;
    }
  }
};

const getContrastColor = (color: string) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Calculate relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

const getChipColor = (text: string) => {
  const lowerText = text.toLowerCase();

  // DISC traits
  if (lowerText.includes('domin')) return getTraitColor('D', 'DISC');
  if (lowerText.includes('influen')) return getTraitColor('I', 'DISC');
  if (lowerText.includes('stead')) return getTraitColor('S', 'DISC');
  if (lowerText.includes('calcul')) return getTraitColor('C', 'DISC');

  // OCEAN traits
  if (lowerText.includes('open')) return getTraitColor('O', 'OCEAN');
  if (lowerText.includes('consc')) return getTraitColor('C', 'OCEAN');
  if (lowerText.includes('extro')) return getTraitColor('E', 'OCEAN');
  if (lowerText.includes('agree')) return getTraitColor('A', 'OCEAN');
  if (lowerText.includes('neuro')) return getTraitColor('N', 'OCEAN');

  return DEALROOMS_COLORS.cloudburst;
};

// Function to get color with intensity based on trait score
const getTraitIntensityColor = (
  trait: string,
  model: 'DISC' | 'OCEAN',
  score: number,
) => {
  const baseColor = getTraitColor(trait, model);

  // Calculate opacity based on score (0-10 scale)
  // Min opacity 0.3 (30%) for visibility, max 1.0 (100%)
  // Clamp score between 0-10 to prevent invalid opacity values
  const opacity = 0.3 + (Math.min(Math.max(score, 0), 10) / 10) * 0.7;

  // Convert hex to rgba
  const hex = baseColor.replace('#', '');
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity.toFixed(2)})`;
};

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const item = payload[0];
    const trait = item.payload.trait.toLowerCase();
    const assessment = item.payload.assessment;
    // Determine which model we're using based on available properties in the assessment
    const isDISC =
      assessment && ('dominance' in assessment || 'influence' in assessment);

    const level =
      assessment?.[
        trait === 'd'
          ? 'dominance'
          : trait === 'i'
            ? 'influence'
            : trait === 's'
              ? 'steadiness'
              : trait === 'c'
                ? isDISC
                  ? 'calculativeness'
                  : 'conscientiousness'
                : trait === 'o'
                  ? 'openness'
                  : trait === 'e'
                    ? 'extraversion'
                    : trait === 'a'
                      ? 'agreeableness'
                      : 'emotional_stability'
      ]?.level;

    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: '8px',
          border: '1px solid #ccc',
        }}
      >
        <div>{item.payload.fullLabel}</div>
        <div>Level: {level}</div>
      </div>
    );
  }
  return null;
};

export const DSContactDetailPersonality: React.FC<
  DSContactDetailPersonalityProps
> = ({ contactId }) => {
  const organizationSlug = useOrganizationSlug();
  const { organization } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();
  const {
    data: contactData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['contact', organization?.slug, contactId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const response = await ContactsApiClient.getContact(
        organization?.slug ?? '',
        contactId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return response;
    },
    enabled: !!organization?.slug,
  });

  const contact = contactData?.data;

  if (isLoading) {
    return (
      <DSStatusMessage
        icon={HourglassEmpty}
        title="Loading Personality Analysis..."
        iconSize={48}
      />
    );
  }

  if (error) {
    return (
      <DSStatusMessage
        icon={ErrorOutline}
        title="Unable to Load Personality Analysis"
        subtitle="There was an error loading the personality analysis. Please try again later."
        iconColor="error.main"
        titleColor="error"
        iconSize={48}
      />
    );
  }

  if (!contact) {
    return null;
  }

  if (
    contact.humanticStatus === 'PENDING' ||
    contact.humanticStatus === 'IN_PROGRESS'
  ) {
    return (
      <DSStatusMessage
        icon={PendingOutlined}
        title="Analysis in Progress"
        subtitle={`Please check back later.`}
        iconColor={DEALROOMS_COLORS.neutralDark}
      />
    );
  }

  if (
    contact.humanticStatus === 'COMPLETED_FAILURE' ||
    !contact.humanticData?.personality_analysis
  ) {
    return (
      <DSStatusMessage
        icon={Psychology}
        title="Analysis Not Available"
        subtitle={`A personality analysis for ${contact.name} is not available.`}
        iconColor="error.main"
        titleColor="error"
        iconSize={48}
      />
    );
  }

  // Transform DISC data for the radar chart
  const discData = [
    {
      trait: 'D',
      fullLabel: 'Dominance',
      value:
        contact.humanticData?.personality_analysis?.disc_assessment?.dominance
          ?.score ?? 0,
      fullMark: 10,
    },
    {
      trait: 'I',
      fullLabel: 'Influence',
      value:
        contact.humanticData?.personality_analysis?.disc_assessment?.influence
          ?.score ?? 0,
      fullMark: 10,
    },
    {
      trait: 'S',
      fullLabel: 'Steadiness',
      value:
        contact.humanticData?.personality_analysis?.disc_assessment?.steadiness
          ?.score ?? 0,
      fullMark: 10,
    },
    {
      trait: 'C',
      fullLabel: 'Calculativeness',
      value:
        contact.humanticData?.personality_analysis?.disc_assessment
          ?.calculativeness?.score ?? 0,
      fullMark: 10,
    },
  ];

  // Transform OCEAN data for the radar chart
  const oceanData = [
    {
      trait: 'O',
      fullLabel: 'Openness',
      value:
        contact.humanticData?.personality_analysis?.ocean_assessment?.openness
          ?.score ?? 0,
      fullMark: 10,
    },
    {
      trait: 'C',
      fullLabel: 'Conscientiousness',
      value:
        contact.humanticData?.personality_analysis?.ocean_assessment
          ?.conscientiousness?.score ?? 0,
      fullMark: 10,
    },
    {
      trait: 'E',
      fullLabel: 'Extraversion',
      value:
        contact.humanticData?.personality_analysis?.ocean_assessment
          ?.extraversion?.score ?? 0,
      fullMark: 10,
    },
    {
      trait: 'A',
      fullLabel: 'Agreeableness',
      value:
        contact.humanticData?.personality_analysis?.ocean_assessment
          ?.agreeableness?.score ?? 0,
      fullMark: 10,
    },
    {
      trait: 'N',
      fullLabel: 'Neuroticism',
      value:
        contact.humanticData?.personality_analysis?.ocean_assessment
          ?.emotional_stability?.score ?? 0,
      fullMark: 10,
    },
  ];

  return (
    <Box>
      <PersonalityContainer>
        {/* DISC Column */}
        <AssessmentColumn>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h6" color={DEALROOMS_COLORS.woodsmoke}>
                DISC
              </Typography>
              {contact.humanticData?.personality_analysis?.disc_assessment
                ?.archetype && (
                <Typography variant="h6" color={DEALROOMS_COLORS.cloudburst}>
                  {
                    contact.humanticData.personality_analysis.disc_assessment
                      .archetype
                  }
                </Typography>
              )}
            </Box>
          </Box>
          {/* DISC Radar Chart */}
          <ChartContainer>
            <StyledResponsiveContainer>
              <RadarChart
                data={discData.map((d) => ({
                  ...d,
                  assessment:
                    contact.humanticData?.personality_analysis?.disc_assessment,
                }))}
              >
                <PolarGrid />
                <PolarAngleAxis
                  dataKey="trait"
                  tick={({ payload, x, y }) => {
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          x={0}
                          y={0}
                          dy={5}
                          textAnchor="middle"
                          fill={getTraitColor(payload.value, 'DISC')}
                          style={{ fontSize: 16, fontWeight: '500' }}
                        >
                          {payload.value}
                        </text>
                      </g>
                    );
                  }}
                />
                <PolarRadiusAxis
                  domain={[0, 10]}
                  angle={45}
                  style={{
                    display: 'none',
                  }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Radar
                  name="DISC Profile"
                  dataKey="value"
                  {...radarChartStyle}
                  dot={({ cx, cy, payload }) => {
                    return (
                      <circle
                        cx={cx}
                        cy={cy}
                        {...dotStyle}
                        fill={
                          payload?.name
                            ? getTraitColor(payload.name, 'DISC')
                            : 'transparent'
                        }
                        stroke={payload?.name ? '#fff' : 'transparent'}
                      />
                    );
                  }}
                />
              </RadarChart>
            </StyledResponsiveContainer>
          </ChartContainer>

          <AdjectiveChipsContainer>
            {/* DISC Trait Chips with intensity based on score */}
            {contact.humanticData?.personality_analysis?.disc_assessment && (
              <>
                {contact.humanticData.personality_analysis.disc_assessment
                  .dominance && (
                  <DSTooltip
                    title={TRAIT_DESCRIPTIONS.DISC.D}
                    placement="top"
                  >
                    <Chip
                      key="D"
                      label={
                        <>
                          <strong>D:</strong>{' '}
                          {contact.humanticData.personality_analysis.disc_assessment.dominance.level
                            .charAt(0)
                            .toUpperCase() +
                            contact.humanticData.personality_analysis.disc_assessment.dominance.level.slice(
                              1,
                            )}
                        </>
                      }
                      sx={{
                        ...AdjectiveChip,
                        backgroundColor: getTraitIntensityColor(
                          'D',
                          'DISC',
                          contact.humanticData.personality_analysis
                            .disc_assessment.dominance.score,
                        ),
                        color: getContrastColor(getTraitColor('D', 'DISC')),
                      }}
                    />
                  </DSTooltip>
                )}
                {contact.humanticData.personality_analysis.disc_assessment
                  .influence && (
                  <DSTooltip
                    title={TRAIT_DESCRIPTIONS.DISC.I}
                    placement="top"
                  >
                    <Chip
                      key="I"
                      label={
                        <>
                          <strong>I:</strong>{' '}
                          {contact.humanticData.personality_analysis.disc_assessment.influence.level
                            .charAt(0)
                            .toUpperCase() +
                            contact.humanticData.personality_analysis.disc_assessment.influence.level.slice(
                              1,
                            )}
                        </>
                      }
                      sx={{
                        ...AdjectiveChip,
                        backgroundColor: getTraitIntensityColor(
                          'I',
                          'DISC',
                          contact.humanticData.personality_analysis
                            .disc_assessment.influence.score,
                        ),
                        color: getContrastColor(getTraitColor('I', 'DISC')),
                      }}
                    />
                  </DSTooltip>
                )}
                {contact.humanticData.personality_analysis.disc_assessment
                  .steadiness && (
                  <DSTooltip
                    title={TRAIT_DESCRIPTIONS.DISC.S}
                    placement="top"
                  >
                    <Chip
                      key="S"
                      label={
                        <>
                          <strong>S:</strong>{' '}
                          {contact.humanticData.personality_analysis.disc_assessment.steadiness.level
                            .charAt(0)
                            .toUpperCase() +
                            contact.humanticData.personality_analysis.disc_assessment.steadiness.level.slice(
                              1,
                            )}
                        </>
                      }
                      sx={{
                        ...AdjectiveChip,
                        backgroundColor: getTraitIntensityColor(
                          'S',
                          'DISC',
                          contact.humanticData.personality_analysis
                            .disc_assessment.steadiness.score,
                        ),
                        color: getContrastColor(getTraitColor('S', 'DISC')),
                      }}
                    />
                  </DSTooltip>
                )}
                {contact.humanticData.personality_analysis.disc_assessment
                  .calculativeness && (
                  <DSTooltip
                    title={TRAIT_DESCRIPTIONS.DISC.C}
                    placement="top"
                  >
                    <Chip
                      key="C"
                      label={
                        <>
                          <strong>C:</strong>{' '}
                          {contact.humanticData.personality_analysis.disc_assessment.calculativeness.level
                            .charAt(0)
                            .toUpperCase() +
                            contact.humanticData.personality_analysis.disc_assessment.calculativeness.level.slice(
                              1,
                            )}
                        </>
                      }
                      sx={{
                        ...AdjectiveChip,
                        backgroundColor: getTraitIntensityColor(
                          'C',
                          'DISC',
                          contact.humanticData.personality_analysis
                            .disc_assessment.calculativeness.score,
                        ),
                        color: getContrastColor(getTraitColor('C', 'DISC')),
                      }}
                    />
                  </DSTooltip>
                )}
              </>
            )}
          </AdjectiveChipsContainer>

          <Box
            sx={{ marginTop: '1rem', marginBottom: '1rem', maxWidth: '85%' }}
          >
            <Typography variant="body2" color={DEALROOMS_COLORS.cloudburst}>
              DISC measures behavioral traits: Dominance (direct), Influence
              (outgoing), Steadiness (patient), and Conscientiousness
              (analytical). DISC tries to show how someone communicates and
              interacts.
            </Typography>
          </Box>

          {/* DISC Legend - Commented out
          <TraitLegend>
            {discData.map((item) => (
              <TraitRow key={item.trait}>
                <TraitLabel color={getTraitColor(item.trait, 'DISC')}>
                  <DSTooltip
                    title={
                      item.trait === 'D'
                        ? 'People high in Dominance tend to be assertive, direct, and focused on achieving results. They are often confident, competitive, and independent.'
                        : item.trait === 'I'
                        ? 'People high in Influence tend to be outgoing, enthusiastic, and persuasive. They are often charismatic, sociable, and optimistic.'
                        : item.trait === 'S'
                        ? 'People high in Steadiness tend to be patient, dependable, and supportive. They are often calm, loyal, and empathetic.'
                        : 'People high in Conscientiousness tend to be analytical, detail-oriented, and systematic. They are often precise, logical, and disciplined.'
                    }
                    placement="top"
                  >
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                      {item.fullLabel}
                      <InfoOutlined
                        fontSize="small"
                        sx={{ ml: 0.5, fontSize: '0.875rem', opacity: 0.7, color: DEALROOMS_COLORS.cloudburst }}
                      />
                    </span>
                  </DSTooltip>
                </TraitLabel>
              </TraitRow>
            ))}
          </TraitLegend>
          */}
        </AssessmentColumn>

        <Divider orientation="vertical" flexItem />

        {/* OCEAN Column */}
        <AssessmentColumn>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="h6" color={DEALROOMS_COLORS.woodsmoke}>
                OCEAN
              </Typography>
            </Box>
          </Box>

          {/* OCEAN Radar Chart */}
          <ChartContainer>
            <StyledResponsiveContainer>
              <RadarChart
                data={oceanData.map((d) => ({
                  ...d,
                  assessment:
                    contact.humanticData?.personality_analysis
                      ?.ocean_assessment,
                }))}
              >
                <PolarGrid />
                <PolarAngleAxis
                  dataKey="trait"
                  tick={({ payload, x, y }) => {
                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          x={0}
                          y={0}
                          dy={5}
                          textAnchor="middle"
                          fill={getTraitColor(payload.value, 'OCEAN')}
                          style={{ fontSize: 16, fontWeight: 'bold' }}
                        >
                          {payload.value}
                        </text>
                      </g>
                    );
                  }}
                />
                <PolarRadiusAxis
                  domain={[0, 10]}
                  angle={45}
                  style={{
                    display: 'none',
                  }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Radar
                  name="OCEAN Profile"
                  dataKey="value"
                  {...radarChartStyle}
                  dot={({ cx, cy, payload }) => {
                    return (
                      <circle
                        cx={cx}
                        cy={cy}
                        {...dotStyle}
                        fill={
                          payload?.name
                            ? getTraitColor(payload.name, 'OCEAN')
                            : 'transparent'
                        }
                        stroke={payload?.name ? '#fff' : 'transparent'}
                      />
                    );
                  }}
                />
              </RadarChart>
            </StyledResponsiveContainer>
          </ChartContainer>

          <AdjectiveChipsContainer>
            {/* OCEAN Trait Chips with intensity based on score */}
            {contact.humanticData?.personality_analysis?.ocean_assessment && (
              <>
                {contact.humanticData.personality_analysis.ocean_assessment
                  .openness && (
                  <DSTooltip
                    title={TRAIT_DESCRIPTIONS.OCEAN.O}
                    placement="top"
                  >
                    <Chip
                      key="O"
                      label={
                        <>
                          <strong>O:</strong>{' '}
                          {contact.humanticData.personality_analysis.ocean_assessment.openness.level
                            .charAt(0)
                            .toUpperCase() +
                            contact.humanticData.personality_analysis.ocean_assessment.openness.level.slice(
                              1,
                            )}
                        </>
                      }
                      sx={{
                        ...AdjectiveChip,
                        backgroundColor: getTraitIntensityColor(
                          'O',
                          'OCEAN',
                          contact.humanticData.personality_analysis
                            .ocean_assessment.openness.score,
                        ),
                        color: getContrastColor(getTraitColor('O', 'OCEAN')),
                      }}
                    />
                  </DSTooltip>
                )}
                {contact.humanticData.personality_analysis.ocean_assessment
                  .conscientiousness && (
                  <DSTooltip
                    title={TRAIT_DESCRIPTIONS.OCEAN.C}
                    placement="top"
                  >
                    <Chip
                      key="C"
                      label={
                        <>
                          <strong>C:</strong>{' '}
                          {contact.humanticData.personality_analysis.ocean_assessment.conscientiousness.level
                            .charAt(0)
                            .toUpperCase() +
                            contact.humanticData.personality_analysis.ocean_assessment.conscientiousness.level.slice(
                              1,
                            )}
                        </>
                      }
                      sx={{
                        ...AdjectiveChip,
                        backgroundColor: getTraitIntensityColor(
                          'C',
                          'OCEAN',
                          contact.humanticData.personality_analysis
                            .ocean_assessment.conscientiousness.score,
                        ),
                        color: getContrastColor(getTraitColor('C', 'OCEAN')),
                      }}
                    />
                  </DSTooltip>
                )}
                {contact.humanticData.personality_analysis.ocean_assessment
                  .extraversion && (
                  <DSTooltip
                    title={TRAIT_DESCRIPTIONS.OCEAN.E}
                    placement="top"
                  >
                    <Chip
                      key="E"
                      label={
                        <>
                          <strong>E:</strong>{' '}
                          {contact.humanticData.personality_analysis.ocean_assessment.extraversion.level
                            .charAt(0)
                            .toUpperCase() +
                            contact.humanticData.personality_analysis.ocean_assessment.extraversion.level.slice(
                              1,
                            )}
                        </>
                      }
                      sx={{
                        ...AdjectiveChip,
                        backgroundColor: getTraitIntensityColor(
                          'E',
                          'OCEAN',
                          contact.humanticData.personality_analysis
                            .ocean_assessment.extraversion.score,
                        ),
                        color: getContrastColor(getTraitColor('E', 'OCEAN')),
                      }}
                    />
                  </DSTooltip>
                )}
                {contact.humanticData.personality_analysis.ocean_assessment
                  .agreeableness && (
                  <DSTooltip
                    title={TRAIT_DESCRIPTIONS.OCEAN.A}
                    placement="top"
                  >
                    <Chip
                      key="A"
                      label={
                        <>
                          <strong>A:</strong>{' '}
                          {contact.humanticData.personality_analysis.ocean_assessment.agreeableness.level
                            .charAt(0)
                            .toUpperCase() +
                            contact.humanticData.personality_analysis.ocean_assessment.agreeableness.level.slice(
                              1,
                            )}
                        </>
                      }
                      sx={{
                        ...AdjectiveChip,
                        backgroundColor: getTraitIntensityColor(
                          'A',
                          'OCEAN',
                          contact.humanticData.personality_analysis
                            .ocean_assessment.agreeableness.score,
                        ),
                        color: getContrastColor(getTraitColor('A', 'OCEAN')),
                      }}
                    />
                  </DSTooltip>
                )}
                {contact.humanticData.personality_analysis.ocean_assessment
                  .emotional_stability && (
                  <DSTooltip
                    title={TRAIT_DESCRIPTIONS.OCEAN.N}
                    placement="top"
                  >
                    <Chip
                      key="N"
                      label={
                        <>
                          <strong>N:</strong>{' '}
                          {contact.humanticData.personality_analysis.ocean_assessment.emotional_stability.level
                            .charAt(0)
                            .toUpperCase() +
                            contact.humanticData.personality_analysis.ocean_assessment.emotional_stability.level.slice(
                              1,
                            )}
                        </>
                      }
                      sx={{
                        ...AdjectiveChip,
                        backgroundColor: getTraitIntensityColor(
                          'N',
                          'OCEAN',
                          contact.humanticData.personality_analysis
                            .ocean_assessment.emotional_stability.score,
                        ),
                        color: getContrastColor(getTraitColor('N', 'OCEAN')),
                      }}
                    />
                  </DSTooltip>
                )}
              </>
            )}
          </AdjectiveChipsContainer>

          <Box
            sx={{ marginTop: '1rem', marginBottom: '1rem', maxWidth: '85%' }}
          >
            <Typography variant="body2" color={DEALROOMS_COLORS.cloudburst}>
              OCEAN evaluates five personality dimensions: Openness (curious),
              Conscientiousness (organized), Extraversion (outgoing),
              Agreeableness (compassionate), and Neuroticism (anxious). OCEAN
              attempts to reveal core character traits.
            </Typography>
          </Box>

          {/* OCEAN Legend - Commented out
          <TraitLegend>
            {oceanData.map((item) => (
              <TraitRow key={item.trait}>
                <TraitLabel color={getTraitColor(item.trait, 'OCEAN')}>
                  <DSTooltip
                    title={
                      item.trait === 'O'
                        ? 'Openness reflects willingness to try new things and engage in imaginative activities. People high in openness are typically creative, curious, and appreciative of art and beauty.'
                        : item.trait === 'C'
                        ? 'Conscientiousness reflects self-discipline, organization, and goal-directed behavior. People high in conscientiousness are typically reliable, hardworking, and detail-oriented.'
                        : item.trait === 'E'
                        ? 'Extraversion reflects sociability and assertiveness. People high in extraversion are typically outgoing, energetic, and draw energy from social interactions.'
                        : item.trait === 'A'
                        ? 'Agreeableness reflects concern for social harmony and cooperation. People high in agreeableness are typically compassionate, helpful, and trusting of others.'
                        : 'Neuroticism (opposite of Emotional Stability) reflects tendency to experience negative emotions. People high in neuroticism may experience more anxiety, mood swings, and emotional reactivity.'
                    }
                    placement="top"
                  >
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                      {item.fullLabel}
                      <InfoOutlined
                        fontSize="small"
                        sx={{ ml: 0.5, fontSize: '0.875rem', opacity: 0.7, color: DEALROOMS_COLORS.cloudburst }}
                      />
                    </span>
                  </DSTooltip>
                </TraitLabel>
              </TraitRow>
            ))}
          </TraitLegend>
          */}
        </AssessmentColumn>
      </PersonalityContainer>
    </Box>
  );
};
