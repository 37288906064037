import React from 'react';
import {
  StyledArtifactContainer,
  StyledMediaContainer,
  StyledContentContainer,
  StyledArtifactName,
  StyledArtifactDescription,
  StyledArtifactTimestamp,
  StyledArtifactHeader,
} from './DSWelcomeArtifactCard.styles';
import { DSContactAvatar } from '../DS/DSContactAvatar';
import { DateTime } from 'luxon';
import { Box, alpha } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

interface DSWelcomeExampleArtifactProps {
  type: string;
  name: string;
  description: string;
  icon: React.ReactNode;
  creatorName?: string;
}

export const DSWelcomeExampleArtifact: React.FC<
  DSWelcomeExampleArtifactProps
> = ({ type, name, description, icon, creatorName = 'Example User' }) => {
  // Format date for display - use current date for examples
  const formatDate = () => {
    const date = DateTime.now();
    return date.toFormat('MMM d, yyyy');
  };

  // Create example creator for avatar
  const exampleCreator = {
    id: 0,
    firstName: creatorName.split(' ')[0] || 'Example',
    lastName: creatorName.split(' ')[1] || 'User',
    name: creatorName,
    email: 'example@example.com',
  };

  return (
    <StyledArtifactContainer
      sx={{
        border: `2px dashed ${alpha(DEALROOMS_COLORS.themeSecondary, 0.4)}`,
        '&:hover': {
          boxShadow: 'none',
        },
      }}
    >
      <StyledMediaContainer
        sx={{
          opacity: 0.4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            color: DEALROOMS_COLORS.white,
          }}
        >
          {icon}
        </Box>
      </StyledMediaContainer>
      <StyledContentContainer
        sx={{
          opacity: 0.4,
        }}
      >
        <StyledArtifactHeader>
          <DSContactAvatar
            contact={exampleCreator}
            size={28}
            sx={{ marginRight: 1 }}
          />
          <StyledArtifactName>{name}</StyledArtifactName>
        </StyledArtifactHeader>

        <StyledArtifactDescription variant="body2">
          {description}
        </StyledArtifactDescription>

        <StyledArtifactTimestamp variant="caption">
          {formatDate()}
        </StyledArtifactTimestamp>
      </StyledContentContainer>
    </StyledArtifactContainer>
  );
};

export default DSWelcomeExampleArtifact;
