import { ReactNode } from 'react';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import DSDetail from './DSDetail';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import FlagIcon from '@mui/icons-material/Flag';
import WorkIcon from '@mui/icons-material/Work';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { toast } from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import { OrganizationDealRoomsQuery } from '../../../../QueryNames';
import { DealState } from '@meetingflow/common/Api/data-contracts';
import { OutlinedFlag } from '@mui/icons-material';

interface DSDetailDealStateProps {
  editable?: boolean;
}

export const DSDetailDealState = ({
  editable = true,
}: DSDetailDealStateProps) => {
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { dealRoom, refetch: refetchDealRoom } = useDealRoom();
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  // Format deal state for display
  const formatDealState = (state: string) => {
    return state.charAt(0) + state.slice(1).toLowerCase();
  };

  // Get deal state options
  const dealStateOptions = ['New', 'Open', 'Closed', 'Lost', 'Won'];

  // Handle deal state update
  const handleDealStateUpdate = async (newValue: string) => {
    if (!organizationSlug || !dealRoomId) return;

    try {
      const token = await getAccessTokenSilently();
      // Convert display format (e.g., "New") to API format (e.g., "NEW")
      // Ensure the value is a valid DealState
      const apiValue = newValue.toUpperCase() as DealState;

      const payload = { dealState: apiValue };

      const response = await toast.promise(
        DealRoomsApiClient.updateDealRoom(
          organizationSlug,
          dealRoomId,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
        {
          loading: 'Updating deal state...',
          success: 'Deal state updated successfully',
          error: 'Failed to update deal state',
        },
      );

      // Force a refetch of the deal room data to ensure we have the latest state
      queryClient.invalidateQueries([
        OrganizationDealRoomsQuery,
        organizationSlug,
        dealRoomId,
      ]);
      await refetchDealRoom();
    } catch (error) {
      console.error('Error updating deal state:', error);
    }
  };

  // Get appropriate icon based on deal state
  const getDealStateIcon = (state?: string): ReactNode => {
    if (!state)
      return (
        <FlagIcon
          sx={{ fontSize: '20px', color: DEALROOMS_COLORS.cloudburst }}
        />
      );

    switch (state.toUpperCase()) {
      case 'NEW':
        return (
          <OutlinedFlag
            sx={{ fontSize: '20px', color: DEALROOMS_COLORS.orchid }}
          />
        );
      case 'OPEN':
        return (
          <WorkIcon
            sx={{ fontSize: '20px', color: DEALROOMS_COLORS.cloudburst }}
          />
        );
      case 'CLOSED':
        return (
          <CloseIcon
            sx={{ fontSize: '20px', color: DEALROOMS_COLORS.cloudburst }}
          />
        );
      case 'LOST':
        return (
          <CancelIcon
            sx={{ fontSize: '20px', color: DEALROOMS_COLORS.crimson }}
          />
        );
      case 'WON':
        return (
          <EmojiEventsIcon
            sx={{ fontSize: '20px', color: DEALROOMS_COLORS.successDarkGreen }}
          />
        );
      default:
        return (
          <FlagIcon
            sx={{ fontSize: '20px', color: DEALROOMS_COLORS.cloudburst }}
          />
        );
    }
  };

  // Return null if not editable and dealState is not set
  if (!editable && !dealRoom?.dealState) {
    return null;
  }

  return (
    <DSDetail
      label="Deal State"
      value={dealRoom?.dealState ? formatDealState(dealRoom.dealState) : 'N/A'}
      icon={getDealStateIcon(dealRoom?.dealState)}
      editable={editable}
      type="select"
      options={dealStateOptions}
      onSave={handleDealStateUpdate}
    />
  );
};
