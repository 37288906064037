/**
 * Notification List Component
 *
 * Displays a list of notifications with both single-selection (for detail view) and
 * multi-selection (for bulk operations) capabilities.
 *
 * Features:
 * - Checkbox-based multi-select for bulk operations
 * - Action toolbar appears when notifications are selected
 * - Bulk actions:
 *   - Archive/Recover (mutually exclusive based on context)
 *   - Mark as Read
 *   - Clear Selection
 * - Visual indication of active notification (for detail view)
 * - Responsive width based on detail view state
 *
 * @property {DealRoomNotification[]} notifications - Array of notifications to display
 * @property {DealRoomNotification} activeNotification - Currently active notification shown in detail view
 * @property {Set<string>} selectedNotifications - Set of notification IDs selected for bulk operations
 * @property {Function} onNotificationClick - Callback when a notification is clicked to view details
 * @property {Function} onNotificationSelect - Callback when a notification checkbox is toggled
 * @property {Function} onClearSelectedNotifications - Callback to clear all selected notifications
 * @property {Function} onDeleteSelectedNotifications - Callback to archive selected notifications (mutually exclusive with onUndeleteSelectedNotifications)
 * @property {Function} onUndeleteSelectedNotifications - Callback to recover archived notifications (mutually exclusive with onDeleteSelectedNotifications)
 * @property {Function} onMarkSelectedNotificationsAsRead - Callback to mark selected notifications as read
 * @property {Function} onSelectAllNotifications - Optional callback to select all notifications
 * @property {Function} onDeselectAllNotifications - Optional callback to deselect all notifications
 */
import {
  DSInboxNotificationsList,
  DSInboxNotificationListItem,
  DSInboxNotificationCheckbox,
  DSInboxNotificationsListWrapper,
  DSInboxEmptyStateWrapper,
  DSInboxEmptyStateTitle,
  DSInboxEmptyStateSubtitle,
  DSInboxEmptyStateIcon,
} from './styles';
import { DSInboxNotificationPreview } from './NotificationPreview';
import { DSInboxToolbar } from './DSInboxToolbar';
import { DealRoomNotification } from '@meetingflow/common/Api/data-contracts';
import { useQuery } from 'react-query';
import { OrganizationDealRoomArtifactsQuery } from '../../../../QueryNames';
import { useAuth0 } from '@auth0/auth0-react';
import { isForbiddenError } from '../../../../Helpers/AxiosHelpers';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useEffect, useMemo } from 'react';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';

/**
 * Props interface for the DSInboxNotificationList component
 * @property {DealRoomNotification[]} notifications - Array of notifications to display in the list
 * @property {DealRoomNotification} activeNotification - Optional currently selected notification
 * @property {Set<string>} selectedNotifications - Set of notification IDs that are currently selected
 * @property {Function} onNotificationClick - Optional callback function triggered when a notification is clicked
 * @property {Function} onNotificationSelect - Optional callback function triggered when a notification checkbox is toggled
 * @property {Function} onClearSelectedNotifications - Optional callback to clear all selected notifications
 * @property {Function} onDeleteSelectedNotifications - Optional callback to delete selected notifications
 * @property {Function} onUndeleteSelectedNotifications - Optional callback to undelete selected notifications
 * @property {Function} onMarkSelectedNotificationsAsRead - Optional callback to mark selected notifications as read
 * @property {Function} onMarkSelectedNotificationsAsUnread - Optional callback to mark selected notifications as unread
 * @property {Function} onSelectAllNotifications - Optional callback to select all notifications
 * @property {Function} onDeselectAllNotifications - Optional callback to deselect all notifications
 * @property {string} noNotificationsMessageTitle - Optional title to display when there are no notifications
 * @property {string} noNotificationsMessageSubtitle - Optional subtitle to display when there are no notifications
 */
interface DSInboxNotificationListProps {
  notifications: DealRoomNotification[];
  activeNotification?: DealRoomNotification;
  selectedNotifications: Set<string>;
  noNotificationsMessageTitle?: string;
  noNotificationsMessageSubtitle?: string;
  onNotificationClick?: (notification: DealRoomNotification) => void;
  onNotificationSelect?: (notification: DealRoomNotification) => void;
  onClearSelectedNotifications?: () => void;
  onDeleteSelectedNotifications?: () => void;
  onUndeleteSelectedNotifications?: () => void;
  onMarkSelectedNotificationsAsRead?: () => void;
  onMarkSelectedNotificationsAsUnread?: () => void;
  onSelectAllNotifications?: () => void;
  onDeselectAllNotifications?: () => void;
  dealRoomId?: number | string;
}

/**
 * Renders a list of notification previews with selection and click handling capabilities.
 * Each notification is rendered as a DSInboxNotificationPreview component with its own
 * selection state and click handler.
 *
 * @param {DSInboxNotificationListProps} props - Component props
 * @returns {JSX.Element} A styled list container with notification preview items
 */
export const DSInboxNotificationList = ({
  notifications,
  activeNotification,
  selectedNotifications,
  noNotificationsMessageTitle = 'No new notifications',
  noNotificationsMessageSubtitle = 'When you receive notifications, they will appear here',
  onNotificationClick,
  onNotificationSelect,
  onClearSelectedNotifications,
  onDeleteSelectedNotifications,
  onUndeleteSelectedNotifications,
  onMarkSelectedNotificationsAsRead,
  onMarkSelectedNotificationsAsUnread,
  onSelectAllNotifications,
  onDeselectAllNotifications,
  dealRoomId,
}: DSInboxNotificationListProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const orgSlug = useOrganizationSlug();
  const currentDealRoomId = useDealRoomId();

  const inheritedDealRoomId =
    typeof dealRoomId === 'string' || typeof dealRoomId === 'number'
      ? Number.isNaN(+dealRoomId)
        ? undefined
        : +dealRoomId
      : undefined;

  // we need this because the notification appears both in the deal room and in the inbox
  const dealRoomIdToUse = inheritedDealRoomId || currentDealRoomId;

  const { data: dealRoomArtifacts, refetch: refetchDealRoomArtifacts } =
    useQuery(
      OrganizationDealRoomArtifactsQuery(orgSlug, dealRoomIdToUse),
      async () => {
        const token = await getAccessTokenSilently();
        return DealRoomsApiClient.listArtifacts(
          {
            organizationSlug: orgSlug!,
            dealRoomId: dealRoomIdToUse!,
            includeDeleted: true,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      {
        enabled: !!orgSlug && !!dealRoomIdToUse,
        retry: (failureCount, error) => {
          if (isForbiddenError(error)) {
            return false;
          }
          return failureCount < 3;
        },
      },
    );

  useEffect(() => {
    // only refetch deal room artifacts if there are artifact notifications
    if (
      refetchDealRoomArtifacts &&
      notifications?.some((n) => n.notificationCategory === 'ARTIFACT')
    ) {
      refetchDealRoomArtifacts();
    }
  }, [refetchDealRoomArtifacts, notifications]);

  const dealRoomArtifactsIdsAndDeletedAt = useMemo(() => {
    return (dealRoomArtifacts?.data || []).map((artifact) => {
      return {
        id: artifact.id,
        deletedAt: artifact.deletedAt,
      };
    });
  }, [dealRoomArtifacts]);

  return (
    <>
      <DSInboxToolbar
        notifications={notifications}
        selectedNotifications={selectedNotifications}
        onSelectAllNotifications={onSelectAllNotifications}
        onDeselectAllNotifications={onDeselectAllNotifications}
        onClearSelectedNotifications={onClearSelectedNotifications}
        onDeleteSelectedNotifications={onDeleteSelectedNotifications}
        onUndeleteSelectedNotifications={onUndeleteSelectedNotifications}
        onMarkSelectedNotificationsAsRead={onMarkSelectedNotificationsAsRead}
        onMarkSelectedNotificationsAsUnread={
          onMarkSelectedNotificationsAsUnread
        }
      />
      <DSInboxNotificationsList hasActiveNotification={!!activeNotification}>
        {/* Map through notifications array to render individual preview components */}
        <DSInboxNotificationsListWrapper>
          {notifications.length === 0 ? (
            <DSInboxEmptyStateWrapper>
              <DSInboxEmptyStateIcon />
              <DSInboxEmptyStateTitle>
                {noNotificationsMessageTitle}
              </DSInboxEmptyStateTitle>
              <DSInboxEmptyStateSubtitle>
                {noNotificationsMessageSubtitle}
              </DSInboxEmptyStateSubtitle>
            </DSInboxEmptyStateWrapper>
          ) : (
            notifications.map((notification) => (
              <DSInboxNotificationListItem
                key={notification.id}
                active={notification.id === activeNotification?.id}
              >
                <DSInboxNotificationCheckbox
                  size="small"
                  checked={selectedNotifications.has(notification.id)}
                  onChange={(e) => {
                    e.stopPropagation(); // Prevent triggering the notification click
                    onNotificationSelect?.(notification);
                  }}
                />
                <DSInboxNotificationPreview
                  key={notification.id}
                  notification={notification}
                  selected={notification.id === activeNotification?.id} // Determine if this notification is currently selected
                  onClick={() => onNotificationClick?.(notification)} // Optional chaining ensures safe callback execution
                  dealRoomArtifactsIdsAndDeletedAt={
                    dealRoomArtifactsIdsAndDeletedAt
                  }
                />
              </DSInboxNotificationListItem>
            ))
          )}
        </DSInboxNotificationsListWrapper>
      </DSInboxNotificationsList>
    </>
  );
};
