import { Box, Button, Typography, ListItem } from '@mui/material';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { styled } from '@mui/material/styles';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { DSButton } from '../../DS';

export const StyledUserMeetingflowList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: 'white',
  padding: 0,
  borderRadius: '.25rem',
  border: `1px solid ${theme.palette.divider}`,
  maxHeight: 'calc(100vh - 12rem)',
}));

export const StyledHeaderSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  gap: '16px',
}));

export const StyledSearchBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

export const StyledDateNavigation = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  justifyContent: 'space-between',
}));

export const StyledListContainer = styled('div')(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  padding: '0',
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: '8px 16px 8px 8px',
  transition: 'background-color 0.3s',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .MeetingContent': {
    display: 'flex',
    flex: 1,
    minWidth: 0, // Important for text truncation
    gap: '8px',
    alignItems: 'center',
  },
  '& .MeetingCard': {
    flex: 1,
    minWidth: 0, // Important for text truncation
  },
  '& .AddButton': {
    flexShrink: 0,
    marginLeft: 'auto',
    paddingLeft: '16px',
  },
}));

export const StyledEmptyState = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  textAlign: 'center',
  height: 'fit-content',
  color: theme.palette.text.secondary,
}));

export const StyledDayHeader = styled(Typography)(({ theme }) => ({
  backgroundColor: DEALROOMS_COLORS.financialLighterGray,
  padding: '8px 16px',
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
  fontWeight: 500,
  borderBottom: '1px solid',
  borderColor: DEALROOMS_COLORS.financialLightGray,
}));

export const StyledLoadingContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',
}));

export const StyledMeetingThumbnail = styled(Box)(() => ({
  width: '100px',
  height: '56.25px',
  borderRadius: '.125rem',
  objectFit: 'cover',
  flexShrink: 0,
  backgroundColor: DEALROOMS_COLORS.neutralLight,
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
}));

export const StyledThumbnailImage = styled('img')(() => ({
  width: '100px',
  height: '56.25px',
  borderRadius: '.125rem',
  objectFit: 'cover',
  position: 'relative',
  top: '2px',
}));

export const StyledThumbnailPlaceholder = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

export const StyledPlaceholderIcon = styled(VideocamOffIcon)(() => ({
  color: DEALROOMS_COLORS.neutralDark,
  opacity: 0.5,
  fontSize: '24px',
}));

export const StyledAddButton = styled(DSButton)(({ theme }) => ({
  backgroundColor: DEALROOMS_COLORS.cloudburst,
  borderColor: DEALROOMS_COLORS.themeSecondary,
  color: DEALROOMS_COLORS.white,
  '& .MuiButton-startIcon': {
    marginRight: '4px',
    marginLeft: '-4px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
  },
}));
