import {
  Box,
  IconButton,
  styled,
  TableCell,
  TableRow,
  Paper,
  TableContainer,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

const LEFT_SIDEBAR_WIDTH = 89; // Width of base layout nav in pixels

interface TabsWrapperProps {
  isCollapsed?: boolean;
  tabPanelHeightPercentage?: number;
  tabPanelMaxHeight?: string;
}

export const TabsWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapsed' &&
    prop !== 'tabPanelHeightPercentage' &&
    prop !== 'tabPanelMaxHeight',
})<TabsWrapperProps>(
  ({
    isCollapsed = false,
    tabPanelHeightPercentage = 33,
    tabPanelMaxHeight,
  }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    display: 'flex',
    flexDirection: 'column',
    height: isCollapsed ? '48px' : `${tabPanelHeightPercentage}vh`,
    maxHeight: isCollapsed ? '48px' : tabPanelMaxHeight,
    minHeight: isCollapsed ? '48px' : 'auto',
    overflow: 'hidden',
    transition: 'all 0.3s ease-in-out',
    paddingTop: '1rem',

    '& .MuiTabs-scroller': {
      padding: '0 1rem',
    },
  }),
);

export const CollapseButton = styled(IconButton)({
  position: 'absolute',
  right: '1rem',
  top: '1rem',
  zIndex: 1,
  padding: '4px',
  '& svg': {
    transition: 'transform 0.3s ease-in-out',
  },
});

export const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  position: 'relative',
  overflow: 'hidden',
});

export const TabContentWrapper = styled(Box)({
  flex: 1,
  overflow: 'auto',
  paddingTop: '1rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
});

export const ListWrapper = styled(Box)({
  flex: 1,
  overflow: 'hidden',
  // paddingBottom: '1rem',
});

export const DetailWrapper = styled(Box)({
  flex: 1,
  overflow: 'auto',
  position: 'relative',
});

export const SearchWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '.5rem',
  gap: '.5rem',
  marginBottom: '.5rem',
});

export const ListPanelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: '4px',
});

export const DetailPanelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: '4px',
  minHeight: 0,
});

export const TableWrapper = styled('div')({
  flex: 1,
  overflow: 'auto',
});

export const LoadingWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2rem',
  gap: '.5rem',
});

export const HeaderWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '1rem',
  gap: '1rem',
  position: 'relative',
  borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  backgroundColor: 'transparent',
  zIndex: 1,
  flex: '0 0 auto', // Don't grow or shrink, use auto height

  '& > li': {
    width: 'auto',
  },
});

export const StyledTableCell = styled(TableCell)({
  borderColor: DEALROOMS_COLORS.neutralLight,
});

export const StyledTableRow = styled(TableRow)({
  // transition: '.3s ease-in-out all',
  '&:hover': {
    backgroundColor: 'transparent', //DEALROOMS_COLORS.neutralLighter,
  },
});

export const DetailsWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapsed' && prop !== 'tabPanelHeightPercentage',
})<TabsWrapperProps>(
  ({ isCollapsed = false, tabPanelHeightPercentage = 33 }) => ({
    flex: 1,
    minHeight: 0,
    overflow: 'auto',
    height: isCollapsed
      ? '100vh'
      : `calc(${100 - tabPanelHeightPercentage}vh - 90px)`,
    transition: 'all 0.3s ease-in-out',
    maxHeight: isCollapsed
      ? '100vh'
      : `calc(${100 - tabPanelHeightPercentage}vh - 90px)`,
  }),
);

export const StyledPaper = styled(Paper)(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    maxWidth: {
      xs: '100%', // Full width on mobile
      sm: `calc(100vw - ${LEFT_SIDEBAR_WIDTH}px - 2rem)`,
      md: `calc(100vw - ${LEFT_SIDEBAR_WIDTH}px - 4rem)`,
    },
    display: 'block',
    overflow: 'hidden',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    height: '100%',
    boxSizing: 'border-box',
  }),
);

interface StyledTableContainerProps {
  tableMaxHeightOffset?: number;
  customMaxHeight?: string;
}

export const StyledTableContainer = styled(TableContainer, {
  shouldForwardProp: (prop) => 
    prop !== 'tableMaxHeightOffset' && prop !== 'customMaxHeight',
})<StyledTableContainerProps>(({ tableMaxHeightOffset = 215, customMaxHeight, theme }) => ({
  // Use customMaxHeight if provided, otherwise calculate based on offset
  maxHeight: customMaxHeight || `calc(100vh - ${tableMaxHeightOffset}px)`,
  backgroundColor: DEALROOMS_COLORS.white,
  borderRadius: '.25rem',
  overflow: 'auto',
  height: '100%',
  minHeight: '100%',
  width: '100%',
  '& table': {
    tableLayout: 'fixed',
    width: '100%',
  },
  '& td, & th': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
