import { styled } from '@mui/material/styles';
import { Box, Typography, Paper } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const ChartContainer = styled(Box)({
  padding: '.5rem',
  paddingLeft: '0',
  paddingTop: '0',
  paddingBottom: '0',
  bgcolor: 'background.paper',
  borderRadius: 1,
  width: '100%',
});

export const LegendToggleBox = styled(Box)({
  position: 'absolute',
  top: '-.5rem',
  right: '.5rem',
  zIndex: 10,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  border: '1px solid #ddd',
  borderRadius: '4px',
  padding: '4px 8px',
  cursor: 'pointer',
  fontSize: '12px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
  },
});

export const LegendColorBox = styled(Box)({
  width: '10px',
  height: '10px',
  backgroundColor: 'inherit',
  marginRight: '5px',
  borderRadius: '2px',
});

export const LegendPanel = styled(Box)({
  zIndex: 100,
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  border: '1px solid #ddd',
  borderRadius: '4px',
  padding: '16px',
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
  maxWidth: 'none',
  width: 'auto',
  maxHeight: '400px',
  overflowY: 'auto',
});

export const LegendColumnBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLastColumn',
})<{ isLastColumn?: boolean }>(({ isLastColumn = false }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '200px',
  marginRight: isLastColumn ? 0 : 16,
  marginBottom: 8,
}));

export const LegendItemRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 0.5,
});

export const ChartLegendColorBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ color }) => ({
  width: '10px',
  height: '10px',
  backgroundColor: color,
  marginRight: '8px',
  borderRadius: '2px',
}));

export const LegendCaptionText = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'visible',
  maxWidth: '100%',
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: 1.75,
});

export const CenteredContentContainer = styled(Box)`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserRowContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '4px',
  padding: '4px 0',
});

export const TooltipPaper = styled(Paper)({
  padding: '1.5rem',
  maxWidth: 280,
  backgroundColor: 'rgba(255, 255, 255, 0.98)',
  borderRadius: 1,
  border: '1px solid #eaeaea',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
});

export const TooltipItemRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.75rem',
  '&:last-child': { marginBottom: 0 },
});

export const TooltipActivityIndicator = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: string }>(({ color }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: '1.5rem',
  flexShrink: 0,
  border: `1px solid ${color}`,
}));

export const DateHeaderRow = styled(Box)({
  display: 'flex',
  height: '38px',
  margin: '0 1rem 0 4rem',
  marginBottom: '.5rem',
  marginTop: 0,
});

export const FlexColumnContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const DateHeaderCell = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLabelVisible',
})<{ isLabelVisible?: boolean }>(({ isLabelVisible = false }) => ({
  flex: 1,
  textAlign: 'center',
  fontSize: '0.75rem',
  color: 'text.secondary',
  fontWeight: isLabelVisible ? 'medium' : 'normal',
  paddingBottom: '5px',
}));

export const UserActivityRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderBottom: 'none',
  padding: '3px 0',
  height: '38px',
  backgroundColor: 'rgba(255, 255, 255, .75)',
  borderRadius: '.5rem',
  marginBottom: '.25rem',
  paddingRight: '1rem',
});

export const UserBubblesContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
});

export const HorizontalConnectingLine = styled(Box)({
  position: 'absolute',
  height: '2px',
  backgroundColor: DEALROOMS_COLORS.cloudburst,
  opacity: 0.3,
  width: '100%',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 0,
});

export const EmptyDayCell = styled(Box)({
  flex: 1,
  position: 'relative',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ActivityDayCell = styled(Box)({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  height: '100%',
  borderRight: 'none',
});

export const BubbleContainer = styled(Box)({
  position: 'relative',
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'transform 0.2s ease',
  zIndex: 1,
  margin: '0 auto',
  '&:hover': {
    transform: 'scale(1.75)',
    '& > div': {
      opacity: 1,
    },
  },
});

export const ActivityBubble = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'size' &&
    prop !== 'colorIndex' &&
    prop !== 'bIndex' &&
    prop !== 'chartColor',
})<{
  size: number;
  colorIndex: number;
  bIndex: number;
  chartColor: string;
}>(({ size, bIndex, chartColor }) => ({
  width: `${size}px`,
  height: `${size}px`,
  borderRadius: '50%',
  backgroundColor: chartColor,
  opacity: 0.85,
  border: `1px solid ${chartColor}`,
  position: 'absolute',
  zIndex: bIndex + 1,
  transition: 'all 0.2s ease-in-out',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const TotalRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderBottom: 'none',
  padding: '3px 0',
  height: '38px',
  paddingRight: '1rem',
});

export const TotalBubblesContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
});

export const TotalRowConnectingLine = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color?: string }>(({ color = DEALROOMS_COLORS.cloudburst }) => ({
  position: 'absolute',
  height: '2px',
  backgroundColor: color,
  opacity: 0.3,
  width: '100%',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 0,
}));

export const TotalDayCell = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLast',
})<{ isLast?: boolean }>(({ isLast = false }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

export const TotalBubbleContainer = styled(Box)({
  position: 'relative',
  width: '32px',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'transform 0.2s ease',
  zIndex: 1,
  margin: '0 auto',
  '&:hover': {
    transform: 'scale(1.75)',
    '& > div': {
      opacity: 1,
    },
  },
});

export const TotalActivityBubble = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'size' &&
    prop !== 'colorIndex' &&
    prop !== 'bIndex' &&
    prop !== 'chartColor',
})<{
  size: number;
  colorIndex: number;
  bIndex: number;
  chartColor: string;
}>(({ size, bIndex, chartColor }) => ({
  width: `${size}px`,
  height: `${size}px`,
  borderRadius: '50%',
  backgroundColor: chartColor,
  opacity: 0.85,
  border: `1px solid ${chartColor}`,
  position: 'absolute',
  zIndex: bIndex + 1,
  transition: 'all 0.2s ease-in-out',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}));
