import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { IButton } from '@fluentui/react';
import { useTheme, CircularProgress } from '@mui/material';
import { useCallback, useMemo, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useNavigate } from '../../../Hooks/useNavigate';
import { useQueryClient } from 'react-query';
import { DSResponsiveModal } from '../DS';
import { TabsRef } from '../DS/DSTabs';
import { UploadForm } from './UploadAttachmentComponents/UploadForm';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';
import { useBoolean } from '@fluentui/react-hooks';
import { meetingflowUrlValidator } from './CommonUploadArtifacts';
import { ensureValidProtocol, isUrl } from '../../../Helpers/URLHelpers';

interface UploadDealRoomAttachmentsDialogProps {
  organizationSlug: string;
  dealRoomId: number;
  onAdd: (item: DealRoomArtifact, keepOpen?: boolean) => void;
  onDismiss: () => void;
  file?: File;
}

/**
 * Dialog component for uploading attachments to a deal room.
 * Supports both file uploads and URL attachments.
 */
export const UploadDealRoomAttachmentsDialog = ({
  organizationSlug,
  dealRoomId,
  onAdd,
  onDismiss,
  file,
}: UploadDealRoomAttachmentsDialogProps) => {
  // State management
  const [selectedFile, setSelectedFile] = useState<File | null>(file || null);
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTab, setSelectedTab] = useState('file');
  const [keepOpen, setKeepOpen] = useState(false);
  const [
    isFileUploading,
    { setTrue: startFileUploading, setFalse: stopFileUploading },
  ] = useBoolean(false);

  // Refs
  const submitButtonRef = useRef<IButton>(null);
  const pivotRef = useRef<TabsRef>(null);

  // Hooks
  const client = useQueryClient();
  const { dealRoomRole } = useDealRoom();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();

  const handleAdd = useCallback(
    async (item: DealRoomArtifact) => {
      // Only call onAdd if we're not keeping open
      if (!keepOpen) {
        onAdd(item);
      }
      // When keepOpen is true, we don't call onAdd at all to keep the dialog mounted
    },
    [keepOpen, onAdd],
  );

  // Navigation guard
  useEffect(() => {
    if (!dealRoomId || !organizationSlug) {
      navigate(`/`);
    }
  }, [dealRoomId, organizationSlug, navigate]);

  const validateMeetingflowUrl = useCallback(
    () => meetingflowUrlValidator(url, organizationSlug),
    [organizationSlug, url],
  );

  const { isMeetingflowUrl, isMeetingflowUrlInCurrentOrg } =
    validateMeetingflowUrl();

  const handleUploadArtifact = useCallback(async () => {
    try {
      // Handle file upload
      startFileUploading();
      if (selectedTab === 'file' && selectedFile) {
        const token = await getAccessTokenSilently();
        const result = await DealRoomsApiClient.uploadFileArtifact(
          organizationSlug,
          dealRoomId,
          {
            file: selectedFile,
            ...(title ? { name: title } : {}),
            ...(description ? { description } : {}),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        handleAdd(result.data);
        toast.success(`Successfully uploaded artifact`);
        stopFileUploading();

        // Reset form if keeping open
        if (keepOpen) {
          setTitle('');
          setDescription('');
          setSelectedFile(null);
          setUrl('');
        }

        // Refetch artifacts
        await client.refetchQueries(
          OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
        );

        return Promise.resolve();
      }
      // Handle URL upload
      else if (selectedTab === 'url' && url) {
        const token = await getAccessTokenSilently();

        if (isMeetingflowUrl && !isMeetingflowUrlInCurrentOrg) {
          toast.error(
            'It looks like this is a Meetingflow from a different organization. To add a Meetingflow, provide a URL from the current organization.',
          );
          stopFileUploading();
          return Promise.reject();
        }

        const result = await DealRoomsApiClient.createLinkArtifact(
          organizationSlug,
          dealRoomId,
          {
            type: 'LINK',
            url: ensureValidProtocol(url),
            name: title || ensureValidProtocol(url),
            description: description || undefined,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        handleAdd(result.data);
        toast.success(`Successfully uploaded artifact`);
        stopFileUploading();

        // Reset form if keeping open
        if (keepOpen) {
          setTitle('');
          setDescription('');
          setSelectedFile(null);
          setUrl('');
        }

        // Refetch artifacts
        await client.refetchQueries(
          OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
        );

        return Promise.resolve();
      }
      stopFileUploading();
      return Promise.reject();
    } catch (error) {
      toast.error('Something went wrong! Please try again later.');
      stopFileUploading();
      return Promise.reject(error);
    }
  }, [
    startFileUploading,
    selectedTab,
    selectedFile,
    url,
    stopFileUploading,
    getAccessTokenSilently,
    organizationSlug,
    dealRoomId,
    client,
    isMeetingflowUrl,
    isMeetingflowUrlInCurrentOrg,
    keepOpen,
    title,
    description,
    handleAdd,
  ]);

  const uploadDisabled = useMemo(() => {
    if (selectedTab === 'file') {
      if (!selectedFile) { return true; }
      if (!title) { return true; }
      return false;
    }

    if (selectedTab === 'url') {
      if (!isUrl(url)) { return true; }
      if (!title) { return true; }
      return false;
    }

    return true;
  }, [selectedFile, title, url, selectedTab]);


  // Submit on Enter key press
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !uploadDisabled) {
        handleUploadArtifact();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUploadArtifact, uploadDisabled]);

  // Focus on submit button when file is selected
  useEffect(() => {
    if (selectedFile) {
      submitButtonRef.current?.focus();
    }
  }, [selectedFile]);


  return (
    <DSResponsiveModal
      open={true}
      onDismiss={onDismiss}
      maxWidth="sm"
      customMaxWidth={538}
      title="Add New Artifact"
      showKeepOpenToggle={true}
      keepOpenToggleLabel="Create more"
      keepOpenToggleValue={keepOpen}
      onKeepOpenToggleChange={setKeepOpen}
      primaryFooterButtons={[
        {
          children: (
            <>
              Create Artifact{' '}
              {isFileUploading ? <CircularProgress size="1rem" /> : null}
            </>
          ),
          onClick: handleUploadArtifact,
          disabled: uploadDisabled || isFileUploading,
        },
      ]}
      secondaryFooterButtons={[
        {
          children: 'Cancel',
          onClick: onDismiss,
        },
      ]}
    >
      <UploadForm
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        url={url}
        setUrl={setUrl}
        dealRoomRole={dealRoomRole}
        pivotRef={pivotRef}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </DSResponsiveModal>
  );
};
