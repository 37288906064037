import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationSlug } from './useOrganizationSlug';
import { useDealRoomId } from './useDealRoomId';
import { useQuery, useQueryClient } from 'react-query';
import { OrganizationMutualPlanTemplatesQuery } from '../QueryNames';
import { DealRoomsApiClient, TemplatesApiClient } from '../Services/NetworkCommon';
import { isForbiddenError } from '../Helpers/AxiosHelpers';
import { useCallback } from 'react';

export const useMutualPlanTemplates = (organizationSlug: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();

  const {
    data: mutualPlanTemplatesData,
    isLoading: mutualPlanTemplatesLoading,
    isFetched: mutualPlanTemplatesIsFetched,
    isError: mutualPlanTemplatesIsError,
    error: mutualPlanTemplatesError,
    refetch: refetchMutualPlanTemplates,
  } = useQuery(
    OrganizationMutualPlanTemplatesQuery(organizationSlug),
    async () => {
      const token = await getAccessTokenSilently();
      return TemplatesApiClient.listMutualPlanTemplates(
        organizationSlug,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug,
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );

  const refetchAll = useCallback(async () => {
    await client.invalidateQueries(
      OrganizationMutualPlanTemplatesQuery(organizationSlug),
    );
  }, [client, organizationSlug]);

  return {
    mutualPlanTemplates: mutualPlanTemplatesData?.data,
    isLoading: mutualPlanTemplatesLoading,
    isFetched: mutualPlanTemplatesIsFetched,
    isError: mutualPlanTemplatesIsError,
    error: mutualPlanTemplatesError,
    refetch: refetchMutualPlanTemplates,
    refetchAll,
  };
};
