/**
 * Milestone component represents a single milestone in the deal room.
 * It displays the milestone title, due date, and associated action items.
 * Users can request artifacts, add tasks, and manage the milestone's due date.
 */

import {
  DealRoomMilestone,
  DealRoomActionItem,
} from '@meetingflow/common/Api/data-contracts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { useMilestonesSummary } from '../../../Hooks/useMilestonesSummary';
import { useRequestAttachmentDialog } from '../../../Hooks/Modals/DealRoom/useRequestAttachmentDialog';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { MilestoneActionItem } from './MilestoneActionItem';
import { NoTasksAvailable } from './NoTasksAvailable';
import { MilestoneHeader } from './MilestoneHeader';
import { isSameDueDate } from './milestonesUtils';
import toast from 'react-hot-toast';
import { MilestoneContainer, TasksList } from './Milestone.styles';
import { DateTime } from 'luxon';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/useField';
import useMilestonesActionItemsFiltersStore from '../../../Stores/milestonesActionItemsFiltersStore';

type MilestoneProps = {
  isSeller: boolean;
  milestone: DealRoomMilestone;
  organizationSlug: string;
  dealRoomId: number;
  onActionItemClick: (
    actionItemId: number,
    milestone: DealRoomMilestone,
    isCreating: boolean,
  ) => void;
  handleSelectMilestoneForAddTask: (milestone: DealRoomMilestone) => void;
  setNoActionItemPanelKey: (newValue: number) => void;
  setTaskIdForComments: (taskId: number) => void;
};

export const Milestone = ({
  isSeller,
  milestone,
  organizationSlug,
  dealRoomId,
  onActionItemClick,
  handleSelectMilestoneForAddTask,
  setNoActionItemPanelKey,
  setTaskIdForComments,
}: MilestoneProps) => {
  const { user } = useAuth0();
  const [milestoneDueDate, setMilestoneDueDate] = useState<string | null>(
    milestone.dueDate,
  );
  // State to track if the milestone is expanded or collapsed
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const { selectedStatuses, startDate, endDate, selectedOwnerEmails } =
    useMilestonesActionItemsFiltersStore();

  const { getAccessTokenSilently } = useAuth0();

  const {
    createDeferred: requestAttachmentDeferred,
    dialog: requestAttachmentDialog,
  } = useRequestAttachmentDialog({
    organizationSlug,
    dealRoomId,
    milestoneId: milestone.id,
  });

  // Fetch mutual plan and milestones data
  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const { milestonesSummary, refetch: refetchMilestonsSummary } =
    useMilestonesSummary(organizationSlug, dealRoomId);

  const filteredActionItems = useMemo(() => {
    if (!milestone.actionItems) return [];

    return milestone.actionItems.filter((actionItem) => {
      // Filter by status (e.g., "In Progress", "Completed")
      if (
        selectedStatuses.length > 0 &&
        !selectedStatuses.includes(actionItem.status)
      ) {
        return false;
      }

      // Filter by owner emails (including no owner case)
      if (selectedOwnerEmails.length > 0) {
        const assigneeEmail = actionItem?.assignee?.email || '';

        if (selectedOwnerEmails.includes('no-owner') && !assigneeEmail) {
          return true;
        }

        return selectedOwnerEmails.includes(assigneeEmail);
      }

      // Filter by due date range
      if (startDate || endDate) {
        const actionItemDate = actionItem.dueDate
          ? DateTime.fromISO(actionItem.dueDate)
          : null;

        // Exclude items without a due date
        if (!actionItemDate) {
          return false;
        }

        // Exclude items due before the start date
        if (startDate && actionItemDate < startDate) {
          return false;
        }

        // Exclude items due after the end date
        if (endDate && actionItemDate > endDate) {
          return false;
        }
      }

      // Include the action item if it passes all filters
      return true;
    });
  }, [
    milestone.actionItems,
    selectedStatuses,
    selectedOwnerEmails,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    // When the milestone's due date changes, update the local state
    setMilestoneDueDate(milestone?.dueDate || null);
  }, [milestone?.dueDate]);

  // Handles updating the milestone's title
  const handleTitleChange = useCallback(
    async (newTitle: string, callback?: () => void) => {
      try {
        const token = await getAccessTokenSilently();

        await toast.promise(
          DealRoomsApiClient.updateMilestone(
            organizationSlug,
            dealRoomId,
            milestone.id,
            { title: newTitle },
            { headers: { Authorization: `Bearer ${token}` } },
          ),
          {
            loading: 'Updating milestone title...',
            success: () => {
              // Refresh data after successful update
              refetchMutualPlan();
              return 'Milestone title updated successfully';
            },
            error: () => {
              // Call the callback function if provided (e.g., to reset the milestone title local state)
              if (callback) callback();
              return 'Failed to update milestone title';
            },
          },
        );
      } catch (error) {
        // Handle any unexpected errors
        // Call the callback function if provided (e.g., to reset the milestone title local state)
        if (callback) callback();
        toast.error('Failed to update milestone title');
      }
    },
    [
      dealRoomId,
      getAccessTokenSilently,
      milestone.id,
      organizationSlug,
      refetchMutualPlan,
    ],
  );

  // Handles updating the milestone's due date
  const handleDueDateChange = useCallback(
    async (
      newDate: DateTime | null,
      context: FieldChangeHandlerContext<string | null>,
    ) => {
      if (!newDate || !Array.isArray(milestonesSummary)) return;

      // If the due date hasn't changed, do nothing
      if (
        milestone?.dueDate &&
        isSameDueDate(milestone.dueDate, newDate.toISO())
      )
        return;

      // Update the local state
      setMilestoneDueDate(newDate.toISO());

      // We should create a new array with all milestones and update the due date of the current milestone and send it do the BE
      const updatedMilestones = milestonesSummary.map((currentMilestone) => ({
        id: currentMilestone.id,
        dueDate:
          milestone.id === currentMilestone.id
            ? newDate.toISO()
            : currentMilestone.dueDate,
        visible: currentMilestone.visible,
        title: currentMilestone.title,
      }));

      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.saveMilestones(
          organizationSlug,
          dealRoomId,
          updatedMilestones,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Updating due date',
          success: () => {
            // Refreshes the mutual plan and milestones summary after successful update
            Promise.all([refetchMilestonsSummary(), refetchMutualPlan()]);
            return 'Successfully updated the due date';
          },
          error: () => {
            // Reset the local state
            setMilestoneDueDate(milestone?.dueDate || null);
            return 'Something went wrong updating the due date';
          },
        },
      );
    },
    [
      dealRoomId,
      getAccessTokenSilently,
      milestone.dueDate,
      milestone.id,
      milestonesSummary,
      organizationSlug,
      refetchMilestonsSummary,
      refetchMutualPlan,
    ],
  );

  // Handles requesting an attachment, swallowing any errors for now
  const handleRequestAttachment = useCallback(async () => {
    try {
      await requestAttachmentDeferred().promise;
    } catch (err) {}
  }, [requestAttachmentDeferred]);

  const handleAddTask = () => {
    handleSelectMilestoneForAddTask(milestone);
    setNoActionItemPanelKey(Date.now());
  };

  const containerStyles = useMemo(() => {
    if (!isSeller || milestone.visible) return {};
    return {
      opacity: 0.5,
    };
  }, [isSeller, milestone?.visible]);

  return (
    <MilestoneContainer
      hasActionItems={!!milestone?.actionItems?.length}
      sx={containerStyles}
    >
      <MilestoneHeader
        milestone={milestone}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        onDueDateChange={handleDueDateChange}
        onRequestArtifact={handleRequestAttachment}
        onAddTask={handleAddTask}
        onTitleChange={handleTitleChange}
        milestoneDueDate={milestoneDueDate}
        isSeller={isSeller}
        isExpanded={isExpanded}
        onToggleExpand={() => setIsExpanded(!isExpanded)}
      />
      {isExpanded && filteredActionItems.length > 0 ? (
        <TasksList>
          {[...filteredActionItems]
            .sort((a, b) => {
              // Handle cases where either date is null
              if (!a.dueDate && !b.dueDate) return 0;
              if (!a.dueDate) return 1;
              if (!b.dueDate) return -1;

              // Compare timestamps for dates
              return (
                new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
              );
            })
            .map((actionItem, idx) => {
              const fullActionItem = actionItem as DealRoomActionItem;
              const isLastItem = milestone.actionItems
                ? idx === milestone.actionItems.length - 1
                : false;
              return (
                <div key={fullActionItem.id}>
                  <MilestoneActionItem
                    actionItem={fullActionItem}
                    onActionItemClick={() =>
                      onActionItemClick(fullActionItem.id, milestone, true)
                    }
                    setTaskIdForComments={setTaskIdForComments}
                    isLastItem={isLastItem}
                  />
                </div>
              );
            })}
        </TasksList>
      ) : null}
      {requestAttachmentDialog}
    </MilestoneContainer>
  );
};
