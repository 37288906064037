import { useCallback, useRef, useEffect } from 'react';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { UserActivity, UserActivityType } from '@meetingflow/common/Api/data-contracts';
import { debounce, throttle } from 'lodash';
import { useInfiniteQuery } from 'react-query';

/**
 * Options for recording a user activity in a deal room
 */
type ActivityMetadataValue = string | number | boolean | null | undefined;
type ActivityMetadata = {
  [key: string]: ActivityMetadataValue | Record<string, ActivityMetadataValue>;
};

interface RecordActivityOptions {
  /** Organization slug for the activity context */
  organizationSlug?: string;
  /** ID of the deal room */
  dealRoomId?: number;
  /** Type of activity being recorded */
  activityType?: UserActivityType;
  /** Additional metadata to store with the activity */
  metadata?: ActivityMetadata;
  /** Callback when activity is successfully recorded */
  onSuccess?: (activity: UserActivity) => void;
  /** Callback when activity recording fails */
  onError?: (error: Error) => void;
  /** If true, errors will be thrown instead of handled silently */
  throwErrors?: boolean;
  /** Maximum number of retry attempts (default: 3) */
  maxRetries?: number;
  /** Delay between retry attempts in ms (default: 1000) */
  retryDelay?: number;
  /** Debounce delay in ms. If set, activity will only be recorded after this delay with no new calls */
  debounceMs?: number;
  /** Minimum time in ms between activity recordings. Calls within this window will be ignored */
  throttleMs?: number;
}

/**
 * Helper function to create a delay
 * @param ms Delay duration in milliseconds
 */
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Hook for recording user activities in deal rooms with retry, debounce, and throttle capabilities
 *
 * Features:
 * - Automatic retry with exponential backoff
 * - Debouncing to prevent rapid-fire API calls (using lodash.debounce)
 * - Throttling to limit API call frequency (using lodash.throttle)
 * - Silent error handling with optional callbacks
 *
 * @example
 * ```typescript
 * const { recordActivity } = useDealRoomActivity();
 *
 * // Simple usage
 * await recordActivity({
 *   organizationSlug: 'org-1',
 *   dealRoomId: 123,
 *   activityType: 'VIEW_DEAL_ROOM',
 * });
 *
 * // With all options
 * await recordActivity({
 *   organizationSlug: 'org-1',
 *   dealRoomId: 123,
 *   activityType: 'VIEW_DEAL_ROOM',
 *   metadata: { source: 'dashboard' },
 *   onSuccess: (activity) => console.log('Recorded:', activity),
 *   onError: (error) => console.error('Failed:', error),
 *   maxRetries: 3,
 *   retryDelay: 1000,
 *   debounceMs: 500,
 *   throttleMs: 2000,
 * });
 * ```
 */
export const useDealRoomActivity = () => {
  const { getAccessTokenSilently } = useAuth0();

  /**
   * Internal function that handles the API call with retry logic
   */
  const recordActivityWithRetry = useCallback(
    async (
      options: RecordActivityOptions,
      attempt: number = 1,
    ): Promise<UserActivity | null> => {
      const {
        organizationSlug,
        dealRoomId,
        activityType,
        metadata = {},
        maxRetries = 3,
        retryDelay = 1000,
      } = options;

      if (!organizationSlug || !dealRoomId || !activityType) {
        return null;
      }

      try {
        const token = await getAccessTokenSilently();
        const response = await DealRoomsApiClient.recordDealRoomUserActivity(
          organizationSlug,
          dealRoomId,
          {
            activityType,
            metadata,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        options.onSuccess?.(response.data);
        return response.data;
      } catch (error) {
        const err =
          error instanceof Error ? error : new Error('Unknown error occurred');

        if (attempt < maxRetries) {
          console.warn(`Retry attempt ${attempt} of ${maxRetries}...`);
          await sleep(retryDelay * attempt); // Exponential backoff
          return recordActivityWithRetry(options, attempt + 1);
        }

        console.error('Failed to record deal room activity:', err);
        options.onError?.(err);

        if (options.throwErrors) {
          throw err;
        }
        return null;
      }
    },
    [getAccessTokenSilently],
  );

  // Create refs to store debounced/throttled versions
  const debouncedRef = useRef<
    ReturnType<typeof debounce> & { delay?: number }
  >();
  const throttledRef = useRef<
    ReturnType<typeof throttle> & { delay?: number }
  >();

  // Cleanup function for debounced/throttled versions
  useEffect(() => {
    return () => {
      debouncedRef.current?.cancel();
      throttledRef.current?.cancel();
    };
  }, []);

  /**
   * Main function to record a user activity with debounce and throttle handling
   */
  const recordActivity = useCallback(
    async (
      options: RecordActivityOptions = {},
    ): Promise<UserActivity | null> => {
      const { debounceMs, throttleMs } = options;

      // Create or update debounced/throttled versions if needed
      if (
        debounceMs &&
        (!debouncedRef.current || debouncedRef.current.delay !== debounceMs)
      ) {
        debouncedRef.current?.cancel();
        debouncedRef.current = debounce(recordActivityWithRetry, debounceMs);
      }

      if (
        throttleMs &&
        (!throttledRef.current || throttledRef.current.delay !== throttleMs)
      ) {
        throttledRef.current?.cancel();
        throttledRef.current = throttle(recordActivityWithRetry, throttleMs);
      }

      // Use the appropriate version of the function
      if (debouncedRef.current) {
        return debouncedRef.current(options);
      }
      if (throttledRef.current) {
        return throttledRef.current(options);
      }

      return recordActivityWithRetry(options);
    },
    [recordActivityWithRetry],
  );

  return { recordActivity };
};

interface ListActivitiesOptions {
  organizationSlug?: string;
  dealRoomId?: number;
  page?: number;
  size?: number;
  sort?: string;
  userIds?: string;
  activityTypes?: string;
  startDate?: string;
  endDate?: string;
  enabled?: boolean; // Add enabled property to control when the query runs
}

interface ActivitiesResponse {
  activities: UserActivityWithId[];
  totalCount: number;
  hasMore: boolean;
  currentPage: number;
}

interface UserActivityWithId extends UserActivity {
  id: string;
}

export const useListDealRoomActivities = (options: ListActivitiesOptions) => {
  const { getAccessTokenSilently } = useAuth0();
  const { organizationSlug, dealRoomId, page = 0, size = 20, sort, userIds, activityTypes, startDate, endDate, enabled } = options;

  return useInfiniteQuery<ActivitiesResponse, Error>(
    [
      'dealroom-activities',
      dealRoomId,
      organizationSlug,
      sort,
      userIds,
      activityTypes,
      startDate,
      endDate,
    ],
    async ({ pageParam = 0 }) => {
      if (!organizationSlug || !dealRoomId) {
        return {
          activities: [] as UserActivityWithId[],
          totalCount: 0,
          hasMore: false,
          currentPage: 0,
        };
      }

      const token = await getAccessTokenSilently();

      const response = await DealRoomsApiClient.listDealRoomUserActivities(
        {
          organizationSlug,
          dealRoomId,
          page: pageParam,
          size,
          sort,
          ...(userIds ? { userIds } : {}),
          ...(activityTypes ? { activityTypes } : {}),
          ...(startDate ? { startDate } : {}),
          ...(endDate ? { endDate } : {}),
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // Create a unique ID for each activity using createdAt timestamp and userId
      // This ensures uniqueness even if multiple activities happen in the same millisecond
      return {
        activities: response.data.content.map(activity => ({
          ...activity,
          id: `${activity.createdAt}_${activity.userId}_${activity.type}`,
        })) as UserActivityWithId[],
        totalCount: response.data.totalElements,
        hasMore: response.data.number < response.data.totalPages - 1,
        currentPage: response.data.number,
      };
    },
    {
      enabled: enabled !== undefined ? enabled && Boolean(organizationSlug) && Boolean(dealRoomId) : Boolean(organizationSlug) && Boolean(dealRoomId),
      getNextPageParam: (lastPage) => {
        return lastPage.hasMore ? lastPage.currentPage + 1 : undefined;
      },
    },
  );
};
