import { Typography, Avatar } from '@mui/material';
import {
  DealRoomArtifact,
  DealRoomArtifactBase,
} from '@meetingflow/common/Api/data-contracts';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import React, { PropsWithChildren } from 'react';
import { useShareDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import { useConfirmationDialog } from '../../../Hooks/Modals/useConfirmationDialog';
import { useUserProfile } from '../../../Hooks/useProfile';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useQueryClient } from 'react-query';
import { ArtifactsTableActions } from './ArtifactsTableActions';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import { avatarPropsFromString } from '../DS/DSUserAvatar';
import { getMUIIconForArtifactType } from '../../../Helpers/IconHelpers';
import { DSTooltip } from '../DS';
import {
  StyledCard,
  Header,
  TitleContainer,
  ActionsButton,
  Title,
  Preview,
  Activity,
} from './BaseArtifactCard.styles';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';

export type BaseArtifactCardProps = {
  className?: string;
  artifact: DealRoomArtifact;
  onClick?: (artifactId: DealRoomArtifact['id']) => void;
  onDelete?: (artifactId: number) => Promise<void>;
};

export const BaseArtifactCard = ({
  className,
  artifact,
  onClick,
  onDelete,
  children,
}: PropsWithChildren<BaseArtifactCardProps>) => {
  const { userId } = useUserProfile();
  const organizationSlug = useOrganizationSlug() || '';
  const dealRoomId = useDealRoomId() || 0;
  const { dealRoomRole, refetch: refreshDealRoom } = useDealRoom();
  const client = useQueryClient();

  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete Artifact?`,
    subText:
      'Deleting this Artifact will delete it for all users and cannot be undone. Are you sure you want to delete this Artifact?',
    primaryAction: 'CANCEL',
  });

  const {
    createDeferred: createConfirmShareDeferred,
    dialog: confirmShareDialog,
  } = useShareDealRoomArtifactDialog({
    organizationSlug,
    artifactName: artifact?.name || '',
  });

  return (
    <>
      <StyledCard
        className={className}
        onClick={onClick && artifact ? () => onClick(artifact.id) : undefined}
      >
        <Header>
          <TitleContainer>
            {artifact && React.createElement(
              getMUIIconForArtifactType(
                artifact.type,
                ('mimeType' in artifact) ? artifact.mimeType as string : undefined
              ),
              {
                sx: {
                  width: 20,
                  height: 20,
                  color: DEALROOMS_COLORS.cloudburst,
                },
              },
            )}
            <Title>{artifact?.label || artifact?.name || ''}</Title>
          </TitleContainer>
          <ActionsButton>
            <ArtifactsTableActions
              userId={userId}
              dealRoomRole={dealRoomRole}
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
              refreshDealRoom={refreshDealRoom}
              onDelete={onDelete}
              refetchArtifacts={async () => {
                await Promise.allSettled([
                  client.refetchQueries({
                    queryKey: ['artifacts', dealRoomId, organizationSlug],
                  }),
                  client.refetchQueries(
                    OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
                  ),
                ]);
              }}
              artifact={artifact}
            />
          </ActionsButton>
        </Header>
        <Preview>{children}</Preview>
        <Activity>
          {artifact?.creator && (artifact.creator.avatarFileUrl || artifact.creator.avatarUrl) && (
            <DSTooltip
              title={
                <>
                  Added by {artifact.creator?.name || artifact.creator?.email} <br />
                  {humanizeDateTime(artifact.createdAt, { displayComponents: ['date'] })}
                </>
              }
            >
              <Avatar
                {...avatarPropsFromString(artifact.creator?.name || artifact.creator?.email || '')}
                src={artifact.creator?.avatarFileUrl || artifact.creator?.avatarUrl || undefined}
                sx={{ width: 24, height: 24 }}
              />
            </DSTooltip>
          )}
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {artifact?.description || ''}
          </Typography>
        </Activity>
      </StyledCard>
      {confirmDeleteDialog}
      {confirmShareDialog}
    </>
  );
};
