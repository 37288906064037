import React from 'react';
import { Box, Typography } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DSTooltip } from '../../DS/DSTooltip';
import { Link, useNavigate } from 'react-router-dom';
import {
  QuoteContainer,
  QuoteText,
  ClassificationChip,
  MetadataContainer,
  SpeakerInfo,
  MeetingTitleLine,
  DateTimeLine,
  MeetingInfoContainer,
  getClassificationColor,
  TimestampContainer,
  TimestampIcon,
  TimestampText,
} from './DSKeyStatement.styles';
import { KeyStatement, Contact } from '@meetingflow/common/Api/data-contracts';
import { useMeetingflow } from '../../Components/Journey/hooks/useMeetingflow';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { DateTime } from 'luxon';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

interface DSKeyStatementProps {
  statement: KeyStatement;
  contact?: Contact;
  displaySpeakerName?: boolean;
  displayMeetingInfo?: boolean;
}

export const DSKeyStatement: React.FC<DSKeyStatementProps> = ({
  statement,
  contact,
  displaySpeakerName = false,
  displayMeetingInfo = false,
}) => {
  const { meetingflow } = useMeetingflow(statement.meetingPlanId);
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const navigate = useNavigate();

  // Format timestamp (seconds) to hh:mm:ss
  const formatTimestamp = (seconds: number | null | undefined): string => {
    if (seconds === null || seconds === undefined) return '00:00';

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    return [
      hours > 0 ? hours.toString().padStart(2, '0') : '',
      minutes.toString().padStart(2, '0'),
      secs.toString().padStart(2, '0'),
    ]
      .filter(Boolean)
      .join(':');
  };

  return (
    <QuoteContainer classification={statement.classification}>
      <FormatQuoteIcon
        sx={(theme) => ({
          transform: 'rotate(180deg)',
          fontSize: '2.5rem',
          position: 'absolute',
          top: theme.spacing(1),
          left: theme.spacing(1),
          color: getClassificationColor(statement.classification),
        })}
      />
      <QuoteText>
        {statement.text}
        {displaySpeakerName && (
          <>
            <span> — {contact?.name || statement.speaker}</span>
            {statement.timestamp !== undefined && statement.timestamp !== null && (
              <DSTooltip
                title={
                  <Typography
                    sx={{
                      display: 'block',
                      textAlign: 'center',
                      width: '100%',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    View meeting video and transcript at this timestamp
                  </Typography>
                }
                placement="top"
              >
                <TimestampContainer
                  onClick={() => {
                    const timestamp = formatTimestamp(statement.timestamp);
                    const url = dealRoomId
                      ? `/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${statement.meetingPlanId}&recordingTimestamp=${statement.timestamp}`
                      : `/organization/${organizationSlug}/meetings?meeting=${statement.meetingPlanId}&recordingTimestamp=${statement.timestamp}`;
                    navigate(url);
                  }}
                >
                  <TimestampIcon>
                    <AccessTimeIcon
                      fontSize="small"
                      sx={{ fontSize: '0.875rem' }}
                    />
                  </TimestampIcon>
                  <TimestampText>
                    {formatTimestamp(statement.timestamp)}
                  </TimestampText>
                </TimestampContainer>
              </DSTooltip>
            )}
          </>
        )}
      </QuoteText>
      <MetadataContainer>
        <SpeakerInfo>
          {displayMeetingInfo && meetingflow && (
            <Link
              to={dealRoomId
                ? `/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${meetingflow.id}${statement.timestamp ? `&recordingTimestamp=${statement.timestamp}` : ''}`
                : `/organization/${organizationSlug}/meetings?meeting=${meetingflow.id}${statement.timestamp ? `&recordingTimestamp=${statement.timestamp}` : ''}`
              }
              style={{
                textDecoration: 'none',
              }}
            >
              <MeetingInfoContainer>
                <Box>
                  <MeetingTitleLine>{meetingflow.title}</MeetingTitleLine>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {meetingflow.startTime && (
                      <DateTimeLine>
                        {DateTime.fromISO(meetingflow.startTime).toFormat(
                          'MMM d, yyyy h:mm a',
                        )}
                      </DateTimeLine>
                    )}
                  </Box>
                </Box>
              </MeetingInfoContainer>
            </Link>
          )}
        </SpeakerInfo>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ClassificationChip
            label={statement.classification}
            classification={statement.classification}
            size="small"
          />
        </Box>
      </MetadataContainer>
    </QuoteContainer>
  );
};
