import React, { ReactNode } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { DateTime } from 'luxon';
import { CircularProgress, Box } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import HistoryIcon from '@mui/icons-material/History';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import { useNavigate } from 'react-router-dom';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { OrganizationMeetingPlansQuery } from '../../../../QueryNames';
import { MeetingflowsApiClient } from '../../../../Services/NetworkCommon';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import DSDetail from './DSDetail';
import { BasicMeetingflow } from '@meetingflow/common/Api/data-contracts';

/**
 * A component that displays the last and next upcoming meetings in the Decision Site
 */
export const DSDetailTouchpoint: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const organizationSlug = useOrganizationSlug();
  const { dealRoomId } = useDealRoom();
  const navigate = useNavigate();

  // Fetch meeting plans for this deal room
  const { data: meetingPlansData, isLoading } = useQuery(
    [
      ...OrganizationMeetingPlansQuery(
        organizationSlug || '',
        '',
        20, // ensure we have enough meetings to find both past and upcoming
        0,
        dealRoomId,
      ),
    ],
    async () => {
      const token = await getAccessTokenSilently();
      const apiParams = {
        organizationSlug: organizationSlug!,
        limit: 20,
        skip: 0,
        dealRoomId,
      };
      const response = await MeetingflowsApiClient.listPlans(apiParams, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    },
    {
      enabled: !!organizationSlug && !!dealRoomId,
      refetchOnWindowFocus: false,
    },
  );

  // Find the last and next upcoming meetings
  const { lastMeeting, nextMeeting } = React.useMemo(() => {
    if (!meetingPlansData?.data || meetingPlansData.data.length === 0) {
      return { lastMeeting: null, nextMeeting: null };
    }

    const now = DateTime.now();
    const meetings = meetingPlansData.data as BasicMeetingflow[];

    // Sort meetings by start time
    const sortedMeetings = [...meetings].sort((a, b) => {
      const aTime = DateTime.fromISO(a.startTime);
      const bTime = DateTime.fromISO(b.startTime);
      return aTime.toMillis() - bTime.toMillis();
    });

    // Find past and upcoming meetings
    const pastMeetings = sortedMeetings.filter(
      (meeting) => DateTime.fromISO(meeting.startTime) <= now,
    );

    const upcomingMeetings = sortedMeetings.filter(
      (meeting) => DateTime.fromISO(meeting.startTime) > now,
    );

    // Get the most recent past meeting and the next upcoming meeting
    const lastMeeting =
      pastMeetings.length > 0 ? pastMeetings[pastMeetings.length - 1] : null;
    const nextMeeting =
      upcomingMeetings.length > 0 ? upcomingMeetings[0] : null;

    return { lastMeeting, nextMeeting };
  }, [meetingPlansData]);

  // Format meeting date for display
  const formatMeetingDate = (meeting: BasicMeetingflow | null) => {
    if (!meeting) return '';

    const meetingDate = DateTime.fromISO(meeting.startTime);
    const now = DateTime.now();
    const isUpcoming = meetingDate > now;

    // Compact date format
    if (isUpcoming) {
      if (meetingDate.hasSame(now, 'day')) {
        return `Today ${meetingDate.toFormat('h:mm a')}`;
      } else if (meetingDate < now.plus({ days: 7 })) {
        return `${meetingDate.toFormat('ccc')} ${meetingDate.toFormat('h:mm a')}`;
      } else {
        return meetingDate.toFormat('MMM d');
      }
    } else {
      if (meetingDate.hasSame(now, 'day')) {
        return `Today ${meetingDate.toFormat('h:mm a')}`;
      } else if (meetingDate > now.minus({ days: 7 })) {
        return `${meetingDate.toFormat('ccc')} ${meetingDate.toFormat('h:mm a')}`;
      } else {
        return meetingDate.toFormat('MMM d');
      }
    }
  };

  // Render icons for the different meeting types
  const renderLastMeetingIcon = (): ReactNode => {
    return (
      <HistoryIcon
        sx={{ fontSize: '20px', color: DEALROOMS_COLORS.cloudburst }}
      />
    );
  };

  const renderNextMeetingIcon = (): ReactNode => {
    return (
      <UpcomingIcon
        sx={{ fontSize: '20px', color: DEALROOMS_COLORS.cloudburst }}
      />
    );
  };

  // Navigation handlers for meetings
  const navigateToMeeting = (meetingId: string) => {
    if (organizationSlug && dealRoomId) {
      navigate(
        `/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${meetingId}`,
      );
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <DSDetail
          label="Last Meeting (Loading...)"
          value="Loading..."
          icon={
            <CircularProgress
              size={20}
              sx={{ color: DEALROOMS_COLORS.cloudburst }}
            />
          }
          editable={false}
          alignment="left"
        />
        <DSDetail
          label="Next Meeting (Loading...)"
          value="Loading..."
          icon={
            <CircularProgress
              size={20}
              sx={{ color: DEALROOMS_COLORS.cloudburst }}
            />
          }
          editable={false}
          alignment="left"
        />
      </Box>
    );
  }

  // If there are no meetings at all
  if (!lastMeeting && !nextMeeting) {
    return (
      <DSDetail
        label="Meetings"
        value="No meetings scheduled"
        icon={<EventIcon fontSize="small" color="action" />}
        editable={false}
        alignment="left"
      />
    );
  }

  // If we have both meetings, return both components
  if (lastMeeting && nextMeeting) {
    return (
      <>
        <Box sx={{ display: 'flex', position: 'relative' }}>
          <DSDetail
            label={`${formatMeetingDate(lastMeeting)}`}
            value={lastMeeting.title}
            icon={renderLastMeetingIcon()}
            editable={false}
            maxWidth="150px"
            onClick={() => navigateToMeeting(lastMeeting.id)}
            alignment="left"
            overline="Last Meeting"
          />
        </Box>
        <Box sx={{ display: 'flex', position: 'relative' }}>
          <DSDetail
            label={`${formatMeetingDate(nextMeeting)}`}
            value={nextMeeting.title}
            icon={renderNextMeetingIcon()}
            editable={false}
            maxWidth="150px"
            onClick={() => navigateToMeeting(nextMeeting.id)}
            alignment="left"
            overline="Next Meeting"
          />
        </Box>
      </>
    );
  }

  // If we only have one meeting, return just that one
  if (lastMeeting) {
    return (
      <Box sx={{ display: 'flex', position: 'relative' }}>
        <DSDetail
          label={`${formatMeetingDate(lastMeeting)}`}
          value={lastMeeting.title}
          icon={renderLastMeetingIcon()}
          editable={false}
          maxWidth="150px"
          onClick={() => navigateToMeeting(lastMeeting.id)}
          alignment="left"
          overline="Last Meeting"
        />
      </Box>
    );
  }

  if (nextMeeting) {
    return (
      <Box sx={{ display: 'flex', position: 'relative' }}>
        <DSDetail
          label={`${formatMeetingDate(nextMeeting)}`}
          value={nextMeeting.title}
          icon={renderNextMeetingIcon()}
          editable={false}
          maxWidth="150px"
          onClick={() => navigateToMeeting(nextMeeting.id)}
          alignment="left"
          overline="Next Meeting"
        />
      </Box>
    );
  }

  // This should never be reached due to the earlier check for no meetings
  return null;
};

export default DSDetailTouchpoint;
