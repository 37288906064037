import { useNavigate, useLocation } from 'react-router';
import { DSButton, DSSellerContactButton } from '../DS';
import {
  Share,
  Edit,
  Home as HomeIcon,
  Description as DescriptionIcon,
  Folder as FolderIcon,
  Dashboard as DashboardIcon,
} from '@mui/icons-material';
import {
  StyledNavbar,
  StyledBreadcrumbs,
  BreadcrumbsContainer,
  LastBreadcrumbIconContainer,
  BreadcrumbLink,
  BreadcrumbIconContainer,
  StyledTypography,
  NavbarContentContainer,
  LogoContainer,
  LogoEditOverlay,
  EditIconStyled,
  StyledCompanyLogo,
} from './DealRoomBuyersNavbar.styles';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useDealRooms } from '../../../Hooks/useDealRooms';
import {
  CircularProgress,
  IconButton,
  TextField,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useState, useCallback, useMemo } from 'react';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import useDeviceType from '../../../Hooks/deviceDetection';
import { useBreadcrumb } from '../../../Context/BreadcrumbContext';
import { useDecisionSiteNavigationStore } from '../../../Stores/decisionSiteNavigationStore';
import { DSLogoManagementModal } from '../Sidebar/DSLogoManagementModal';

const ROUTES = {
  SHARE: (orgSlug: string, dealRoomId: number) =>
    `/organization/${orgSlug}/decisionsite/${dealRoomId}/settings?group=invite`,
} as const;

export const DealRoomBuyersNavbar = () => {
  const navigate = useNavigate();
  const organizationSlug = useOrganizationSlug();
  const {
    dealRoomId,
    dealRoom,
    dealRoomRole,
    isLoading,
    refetch: refetchDealRoom,
  } = useDealRoom();
  const { dealRooms, refetch: refetchDealRooms } = useDealRooms(
    organizationSlug || '',
  );
  const { isMobile } = useDeviceType();
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(dealRoom?.name || '');
  const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const { isDecisionSitesOpen, setIsDecisionSitesOpen } =
    useDecisionSiteNavigationStore();

  const handleSaveName = useCallback(async () => {
    const trimmedName = newName.trim();

    // Reset to original name and exit edit mode if the new name is empty or unchanged
    if (!trimmedName || trimmedName === dealRoom?.name) {
      setNewName(dealRoom?.name || '');
      setIsEditing(false);
      return;
    }

    // Exit if we don't have required data
    if (!organizationSlug || !dealRoomId) {
      setIsEditing(false);
      return;
    }

    try {
      const token = await getAccessTokenSilently();
      await DealRoomsApiClient.updateDealRoom(
        organizationSlug,
        dealRoomId,
        {
          name: trimmedName,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      toast.success('Decision Site name updated successfully');
      refetchDealRooms();
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update deal room name:', error);
      toast.error('Failed to update decision site name');
      setNewName(dealRoom?.name || '');
      setIsEditing(false);
    }
  }, [
    organizationSlug,
    dealRoomId,
    newName,
    dealRoom?.name,
    getAccessTokenSilently,
    refetchDealRooms,
  ]);

  const handleLogoUpdate = useCallback(() => {
    Promise.allSettled([refetchDealRoom(), refetchDealRooms()]);
  }, [refetchDealRoom, refetchDealRooms]);

  const handleShare = () => {
    if (!organizationSlug || !dealRoomId) {
      return;
    }
    try {
      navigate(ROUTES.SHARE(organizationSlug, dealRoomId));
    } catch (error) {
      console.error('Navigation failed:', error);
    }
  };

  // TODO: Find a way to bring this back
  const EditableTitle = (
    <Box
      className="navbar-title"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        '&:hover .edit-icon':
          dealRoomRole === 'SELLER'
            ? {
                opacity: isMobile ? 1 : 0.7,
                visibility: 'visible',
              }
            : {},
      }}
    >
      {isEditing && dealRoomRole === 'SELLER' ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <TextField
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            size="small"
            autoFocus
            onBlur={handleSaveName}
            sx={{ minWidth: 200 }}
          />
        </Box>
      ) : (
        <>
          <Box
            role="button"
            tabIndex={0}
            onClick={() => {
              if (dealRoomRole === 'SELLER') {
                setIsEditing(true);
                setNewName(dealRoom?.name || '');
              }
            }}
            sx={{ cursor: dealRoomRole === 'SELLER' ? 'pointer' : 'default' }}
            aria-label={`Current Decision Site: ${dealRoom?.name}`}
          >
            {dealRoom?.name}
          </Box>
          {dealRoomRole === 'SELLER' && (
            <IconButton
              className="edit-icon"
              onClick={() => {
                setIsEditing(true);
                setNewName(dealRoom?.name || '');
              }}
              size="small"
              sx={{
                opacity: isMobile ? 1 : 0,
                visibility: isMobile ? 'visible' : 'hidden',
                transition: 'opacity 0.2s',
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );

  const ActionButtons = (
    <div className="navbar-actions">
      <div className="action-buttons">
        <DSButton
          className="action-button"
          onClick={handleShare}
          startIcon={<Share />}
          branded
          sx={{
            '&&': {
              minHeight: '28px',
              height: '28px',
              maxHeight: '28px',
            },
          }}
        >
          <span className="button-label">Share</span>
        </DSButton>

        <DSSellerContactButton
          branded
          avatarOnly={useMediaQuery(useTheme().breakpoints.down('sm'))}
        />
      </div>
    </div>
  );

  // Get breadcrumbs from context
  const { breadcrumbs } = useBreadcrumb();
  const location = useLocation();

  // Helper function to render breadcrumbs with logo
  const renderBreadcrumbsWithLogo = (breadcrumbsElement: React.ReactNode) => (
    <BreadcrumbsContainer>
      {dealRoom?.buyerLogoUrl && (
        <LogoContainer>
          <StyledCompanyLogo
            variant="rounded"
            src={dealRoom.buyerLogoUrl}
            alt={dealRoom.name || 'Company Logo'}
            isSeller={dealRoomRole === 'SELLER'}
            onClick={() =>
              dealRoomRole === 'SELLER' && setIsLogoModalOpen(true)
            }
          />
          {dealRoomRole === 'SELLER' && (
            <LogoEditOverlay
              className="edit-overlay"
              onClick={() => setIsLogoModalOpen(true)}
            >
              <EditIconStyled>
                <Edit />
              </EditIconStyled>
            </LogoEditOverlay>
          )}
        </LogoContainer>
      )}
      {breadcrumbsElement}
    </BreadcrumbsContainer>
  );

  // Function to generate breadcrumbs based on the URL and context
  const getBreadcrumbs = () => {
    // If we have breadcrumbs from context, use those
    if (breadcrumbs.length > 0) {
      const breadcrumbsElement = (
        <StyledBreadcrumbs
          primaryColor={dealRoom?.primaryColor || undefined}
          aria-label="breadcrumb"
        >
          {breadcrumbs.map((crumb, index) => {
            const isLast = index === breadcrumbs.length - 1;

            return isLast ? (
              <StyledTypography
                key={index}
                primaryColor={dealRoom?.primaryColor || undefined}
              >
                {index > 0 && crumb.icon && (
                  <LastBreadcrumbIconContainer
                    primaryColor={dealRoom?.primaryColor || undefined}
                  >
                    {crumb.icon}
                  </LastBreadcrumbIconContainer>
                )}
                {crumb.label}
              </StyledTypography>
            ) : (
              <BreadcrumbLink
                key={index}
                primaryColor={dealRoom?.primaryColor || undefined}
                color="inherit"
                href={crumb.path}
                onClick={(e) => {
                  if (crumb.path) {
                    e.preventDefault();
                    navigate(crumb.path);
                  }
                }}
              >
                {index > 0 && crumb.icon && (
                  <BreadcrumbIconContainer
                    primaryColor={dealRoom?.primaryColor || undefined}
                  >
                    {crumb.icon}
                  </BreadcrumbIconContainer>
                )}
                {crumb.label}
              </BreadcrumbLink>
            );
          })}
        </StyledBreadcrumbs>
      );

      return renderBreadcrumbsWithLogo(breadcrumbsElement);
    }

    // Default breadcrumbs based on URL if no context breadcrumbs are available
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const dealRoomIndex = pathSegments.findIndex(
      (segment) => segment === 'decisionsite',
    );

    if (dealRoomIndex >= 0 && dealRoomIndex + 1 < pathSegments.length) {
      // Get segments after 'decisionsite/[id]'
      const relevantSegments = pathSegments.slice(dealRoomIndex + 2);

      if (relevantSegments.length > 0) {
        const breadcrumbsElement = (
          <StyledBreadcrumbs
            primaryColor={dealRoom?.primaryColor || undefined}
            aria-label="breadcrumb"
          >
            <BreadcrumbLink
              primaryColor={dealRoom?.primaryColor || undefined}
              color="inherit"
              href={`/organization/${organizationSlug}/decisionsite/${dealRoomId}/overview`}
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `/organization/${organizationSlug}/decisionsite/${dealRoomId}/overview`,
                );
              }}
            >
              {/* No icon for the first breadcrumb - it uses the buyer logo */}
              {dealRoom?.name || 'Decision Site'}
            </BreadcrumbLink>

            {relevantSegments.map((segment, index) => {
              // Format the segment for display (capitalize, replace hyphens)
              const formattedSegment = segment
                .replace(/-/g, ' ')
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');

              const isLast = index === relevantSegments.length - 1;

              // Determine icon based on segment name
              let icon = null;
              if (segment.includes('artifact')) {
                icon = <DescriptionIcon fontSize="small" />;
              } else if (segment === 'overview') {
                icon = <DashboardIcon fontSize="small" />;
              } else if (segment.includes('folder')) {
                icon = <FolderIcon fontSize="small" />;
              } else if (segment === 'home') {
                icon = <HomeIcon fontSize="small" />;
              }

              if (isLast) {
                return (
                  <StyledTypography
                    key={index}
                    primaryColor={dealRoom?.primaryColor || undefined}
                  >
                    {icon && (
                      <LastBreadcrumbIconContainer
                        primaryColor={dealRoom?.primaryColor || undefined}
                      >
                        {icon}
                      </LastBreadcrumbIconContainer>
                    )}
                    {formattedSegment}
                  </StyledTypography>
                );
              }

              // Build the path for this breadcrumb
              const path = `/organization/${organizationSlug}/decisionsite/${dealRoomId}/${relevantSegments
                .slice(0, index + 1)
                .join('/')}`;

              return (
                <BreadcrumbLink
                  key={index}
                  primaryColor={dealRoom?.primaryColor || undefined}
                  color="inherit"
                  href={path}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(path);
                  }}
                >
                  {icon && (
                    <BreadcrumbIconContainer
                      primaryColor={dealRoom?.primaryColor || undefined}
                    >
                      {icon}
                    </BreadcrumbIconContainer>
                  )}
                  {formattedSegment}
                </BreadcrumbLink>
              );
            })}
          </StyledBreadcrumbs>
        );

        return renderBreadcrumbsWithLogo(breadcrumbsElement);
      }
    }

    // If no relevant segments, just show the title
    return null;
  };

  // Use useMemo to memoize the breadcrumbs
  const breadcrumbsElement = useMemo(
    () => getBreadcrumbs(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      location.pathname,
      breadcrumbs,
      dealRoom?.name,
      dealRoom?.primaryColor,
      dealRoom?.buyerLogoUrl,
      dealRoomRole,
      organizationSlug,
      dealRoomId,
      navigate,
      setIsLogoModalOpen,
    ],
  );

  return (
    <>
      <DSLogoManagementModal
        open={isLogoModalOpen}
        onDismiss={() => setIsLogoModalOpen(false)}
        organizationSlug={organizationSlug || ''}
        dealRoomId={dealRoomId || 0}
        buyerLogoUrl={dealRoom?.buyerLogoUrl || undefined}
        onLogoUpdate={handleLogoUpdate}
      />
      <StyledNavbar
        primaryColor={dealRoom?.primaryColor || undefined}
        isDecisionSitesOpen={isDecisionSitesOpen}
      >
        <NavbarContentContainer>{breadcrumbsElement}</NavbarContentContainer>
        {ActionButtons}
      </StyledNavbar>
    </>
  );
};
