import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Alert,
  CircularProgress,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { useOrganization } from '../../../../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';
import { MutualPlanTemplate } from '@meetingflow/common/Api/data-contracts';
import { useMutualPlanTemplates } from '../../../../../Hooks/useMutualPlanTemplates';
import { DSMutualPlanApplyTemplateCard } from './DSMutualPlanApplyTemplateCard';

interface DSMutualPlanApplyTemplateDialogProps {
  open: boolean;
  onClose: () => void;
  organizationSlug: string;
  dealRoomId: number;
  onApplyTemplate: (template: MutualPlanTemplate) => Promise<void>;
}

export const DSMutualPlanApplyTemplateDialog = ({
  open,
  onClose,
  organizationSlug,
  dealRoomId,
  onApplyTemplate,
}: DSMutualPlanApplyTemplateDialogProps) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<MutualPlanTemplate | null>(null);
  const [isApplying, setIsApplying] = useState(false);
  const { organization } = useOrganization();

  const { getAccessTokenSilently } = useAuth0();

  // Fetch templates
  const {
    mutualPlanTemplates: templates,
    isLoading,
    error,
  } = useMutualPlanTemplates(organizationSlug);

  // Reset selection when dialog opens
  useEffect(() => {
    if (open) {
      setSelectedTemplate(null);
    }
  }, [open]);

  const handleTemplateClick = (template: MutualPlanTemplate) => {
    setSelectedTemplate(template);
  };

  const handleApplyTemplate = async () => {
    // Check if a template is selected and not already applying
    if (!selectedTemplate || isApplying) return;

    // Set applying state to true to prevent multiple submissions
    setIsApplying(true);

    try {
      // Apply the selected template
      await onApplyTemplate(selectedTemplate);

      // Close the dialog on successful application
      onClose();
    } catch (err) {
      // Log any errors that occur during template application
      console.error('Error applying template:', err);
    } finally {
      // Reset applying state after a short delay
      setTimeout(() => {
        setIsApplying(false);
      }, 500);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        sx: { p: 1 },
      }}
      fullWidth
    >
      <DialogTitle>
        Add from Mutual Plan Template
        <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 1 }}>
          Adding a template will not affect your existing milestones and tasks.
        </Typography>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">
            Error loading templates. Please try again.
          </Alert>
        ) : templates && templates.length > 0 ? (
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {templates.map((template) => (
              <DSMutualPlanApplyTemplateCard
                key={template.id}
                template={template}
                isSelected={selectedTemplate?.id === template.id}
                onClick={() => handleTemplateClick(template)}
              />
            ))}
          </Grid>
        ) : (
          <Box sx={{ textAlign: 'center', p: 3 }}>
            <Typography color="secondary">
              No templates available. Please ask your organization admin to
              create one.
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleApplyTemplate}
          variant="contained"
          disabled={!selectedTemplate || isLoading || isApplying}
          sx={{
            bgcolor: DEALROOMS_COLORS.themePrimary,
            '&:hover': {
              bgcolor: DEALROOMS_COLORS.neutralDark,
            },
          }}
        >
          Add from Template
        </Button>
      </DialogActions>
    </Dialog>
  );
};
