import React, { ReactNode } from 'react';
import { Avatar } from '@mui/material';
import {
  BasicContact,
  Contact,
  User,
} from '@meetingflow/common/Api/data-contracts';
import { avatarPropsFromString } from './DSUserAvatar';
import { stringToColor } from '@meetingflow/common/StringHelpers';
import { DSTooltip } from '../DS';

interface DealRoomContact {
  id: number;
  name?: string;
  email: string;
  avatarUrl?: string;
  avatarFileUrl?: string;
}

export interface DSContactAvatarProps {
  contact: DealRoomContact | BasicContact | Contact | User;
  size?: number;
  sx?: React.CSSProperties;
  titleOverride?: string;
  hideEmailAddress?: boolean; // When true, email is hidden in UI but shown in tooltip
}

export const DSContactAvatar: React.FC<DSContactAvatarProps> = ({
  contact,
  size = 24,
  sx,
  titleOverride,
  hideEmailAddress = false,
}) => {
  const displayName = contact.name || contact.email;
  const avatarImage =
    contact?.avatarFileUrl ||
    // @ts-ignore humanticData is not on all contacts, but use it if it exists
    contact.humanticData?.user_profile_image ||
    contact.avatarUrl ||
    undefined;

  const avatarProps = {
    sx: {
      bgcolor: stringToColor(displayName),
      width: size,
      height: size,
      fontSize: `${Math.floor(size * 0.55)}px`,
      ...sx,
    },
    children: displayName.charAt(0).toUpperCase(),
  };

  // Get the title from the contact object or use the override if provided
  const title = titleOverride || (contact as Contact).title;
  
  // Create tooltip content that includes name, title, and email
  // Each item will be on its own line using React elements
  const tooltipContent = (
    <>
      <div>{displayName}</div>
      {title && title !== displayName && <div>{title}</div>}
      {/* Show email in tooltip when it's hidden in the UI (hideEmailAddress=true) */}
      {hideEmailAddress && contact.email && contact.email !== displayName && (
        <div>{contact.email}</div>
      )}
    </>
  );

  return (
    <DSTooltip title={tooltipContent}>
      <Avatar alt={displayName} src={avatarImage} sx={avatarProps.sx}>
        {avatarProps.children}
      </Avatar>
    </DSTooltip>
  );
};
