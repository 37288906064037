/**
 * DecisionSiteMeetingflowList Component
 *
 * A paginated table component that displays a list of meetingflows (meetings) in a Decision Site.
 * Each row shows meeting details including the meeting info, attendees, organizer, and creator.
 *
 * Features:
 * - Paginated view of meetings
 * - Clickable rows that navigate to meeting details
 * - Empty state handling
 * - Responsive table layout
 * - Integration with Decision Site context
 */

import { useNavigate } from 'react-router-dom';
import { toContentPath } from '../../../../Helpers/FileHelpers';
import {
  InfiniteData,
  InfiniteQueryObserverResult,
  FetchNextPageOptions,
} from 'react-query';
import { AxiosResponse } from 'axios';
import {
  Meetingflow,
  ListPlansData,
} from '@meetingflow/common/Api/data-contracts';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import {
  StyledMeetingflowListRoot,
  StyledMeetingflowList,
  StyledEmptyStateContainer,
  StyledEmptyStateText,
} from './DecisionSiteMeetingflowList.styles';
import { useRef, useEffect } from 'react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { DSStatusMessage } from '../../Components/DSStatusMessage';
import DSJourneyMeetingflowRow from './DSJourneyMeetingflowRow';
import useUserCalendar from './hooks/useUserCalendar';
import { DateTime } from 'luxon';

/**
 * Props for the DecisionSiteMeetingflowList component
 * @interface DecisionSiteMeetingflowListProps
 * @property {InfiniteData<AxiosResponse<ListPlansData>>} meetingPlansPages - Paginated meeting data from react-query
 * @property {number} currentPage - Current active page number
 * @property {function} onPageChange - Callback function when page is changed
 * @property {React.RefObject<HTMLDivElement>} listRef - Optional ref for the list container
 * @property {string} className - Optional CSS class name for styling
 * @property {function} onSelectMeetingflow - Optional callback function when a meetingflow is selected
 * @property {function} fetchNextPage - Function to fetch the next page of data
 * @property {boolean} hasNextPage - Whether there is a next page of data
 * @property {boolean} isFetchingNextPage - Whether the next page of data is being fetched
 */
interface DecisionSiteMeetingflowListProps {
  meetingPlansPages: InfiniteData<AxiosResponse<ListPlansData>> | undefined;
  currentPage: number;
  onPageChange: (page: number) => void;
  listRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  onSelectMeetingflow?: (meetingflowId: string) => void;
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<
    InfiniteQueryObserverResult<AxiosResponse<ListPlansData>, unknown>
  >;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isLoading?: boolean;
}

/**
 * Renders a paginated list of meetingflows in a table format
 *
 * @param {DecisionSiteMeetingflowListProps} props - Component props
 * @returns {React.ReactElement | null} Returns the meetingflow list UI or null if context is missing
 */
const DecisionSiteMeetingflowList: React.FC<
  DecisionSiteMeetingflowListProps
> = ({
  meetingPlansPages,
  currentPage,
  onPageChange,
  listRef,
  className,
  onSelectMeetingflow,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading = false,
}) => {
  // Navigation and context hooks
  const navigate = useNavigate();
  const { slug: organizationSlug } = useOrganization();
  const { dealRoomId } = useDealRoom();

  const { refetchEvents } = useUserCalendar({
    searchString: '',
    selectedDate: DateTime.now().startOf('week'),
    showInternal: false,
  });

  // Intersection Observer for infinite scroll
  const loadMoreRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 0.1 },
    );

    const currentRef = loadMoreRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  // Guard clause: return null if required context is missing
  if (!organizationSlug || !dealRoomId) {
    return null;
  }

  // Check if any plans have thumbnails
  const hasAnyThumbnails = meetingPlansPages?.pages.some((page) =>
    page?.data?.some((plan) => plan.callRecording?.thumbnailFileName),
  );

  return (
    <StyledMeetingflowListRoot ref={listRef} className={className}>
      <StyledMeetingflowList>
        {/* Loading state */}
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 4,
            }}
          >
            <CircularProgress />
          </Box>
        ) : !meetingPlansPages?.pages[0]?.data ||
          meetingPlansPages.pages[0].data.length === 0 ? (
          <DSStatusMessage
            icon={VideocamOffIcon}
            title="No meetings found."
            subtitle="Meetings will appear here after they have been added to the Decision Site."
          />
        ) : (
          // Meeting list table
          <TableContainer component={Paper}>
            <Table size="small">
              <TableBody>
                {meetingPlansPages?.pages.map((page) =>
                  page?.data?.map((plan) => (
                    <TableRow
                      key={plan.id}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        },
                      }}
                      onClick={() => onSelectMeetingflow?.(plan.id)}
                    >
                      {/* Thumbnail cell - only show if any plans have thumbnails */}
                      {hasAnyThumbnails && (
                        <TableCell
                          className="thumbnail"
                          sx={{
                            width: '100px',
                            padding: 0,
                            paddingRight: '.5rem',
                            verticalAlign: 'middle',
                          }}
                        >
                          <Box
                            sx={{
                              width: '100px',
                              height: '56.25px',
                              borderRadius: '.125rem',
                              objectFit: 'cover',
                              backgroundColor: DEALROOMS_COLORS.neutralLight,
                            }}
                          >
                            {plan.callRecording?.thumbnailFileName ? (
                              <img
                                src={toContentPath(
                                  plan?.callRecording?.thumbnailFileName,
                                  organizationSlug,
                                  plan.id,
                                )}
                                alt="Meeting thumbnail"
                                style={{
                                  width: '100px',
                                  height: '56.25px',
                                  borderRadius: '.125rem',
                                  objectFit: 'cover',
                                  position: 'relative',
                                  top: '2px',
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '100%',
                                }}
                              >
                                <VideocamOffIcon
                                  aria-hidden={true}
                                  sx={{
                                    color: DEALROOMS_COLORS.neutralDark,
                                    opacity: 0.5,
                                    fontSize: '24px',
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        </TableCell>
                      )}
                      {/* Meeting details cell */}
                      <TableCell
                        className="meetingflow"
                        sx={{
                          padding: '8px',
                          verticalAlign: 'middle',
                        }}
                      >
                        <DSJourneyMeetingflowRow
                          meetingflow={plan}
                          showAddToDecisionSites={false}
                          showConferenceJoinButton={true}
                          showRecordButton={true}
                          showStatusButton={true}
                          refetchEvents={refetchEvents}
                        />
                      </TableCell>
                    </TableRow>
                  )),
                )}
              </TableBody>
            </Table>
            {/* Infinite scroll loading indicator */}
            <div ref={loadMoreRef} style={{ height: '20px', margin: '10px 0' }}>
              {isFetchingNextPage ? (
                <Typography variant="body2" align="center">
                  Loading more...
                </Typography>
              ) : hasNextPage ? (
                <Typography variant="body2" align="center">
                  Scroll for more
                </Typography>
              ) : null}
            </div>
          </TableContainer>
        )}
      </StyledMeetingflowList>
    </StyledMeetingflowListRoot>
  );
};

export default DecisionSiteMeetingflowList;
