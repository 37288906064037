import { FC, useEffect } from 'react';
import { useJourneySummary, USE_MOCK_DATA } from '../hooks/useJourneySummary';

interface DSJourneySummaryFetcherProps {
  setHasJourneySummary: (hasJourneySummary: boolean) => void;
}

/**
 * Invisible component that only fetches journey summary data
 * and updates the parent's hasJourneySummary state.
 * Does not render any visible UI.
 */
export const DSJourneySummaryFetcher: FC<DSJourneySummaryFetcherProps> = ({
  setHasJourneySummary,
}) => {
  // Handle mock data case (for development)
  useEffect(() => {
    if (USE_MOCK_DATA) {
      setHasJourneySummary(true);
    }
  }, [setHasJourneySummary]);

  // Use the shared hook to fetch journey summary data
  const { data, isLoading } = useJourneySummary();

  // Update hasJourneySummary when data is loaded
  useEffect(() => {
    if (!isLoading) {
      // Check if we have any valid summary data
      const hasSummary = !!(
        data &&
        (data.overview ||
          data.key_changes ||
          data.next_steps ||
          data.risks_opportunities)
      );
      
      // Only set to true if we actually have data
      if (hasSummary) {
        setHasJourneySummary(true);
      }
    }
  }, [data, isLoading, setHasJourneySummary]);

  // This component doesn't render anything visible
  return null;
};
