import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueryClient } from 'react-query';
import { UserApiClient } from '../Services/NetworkCommon';
import {
  OrganizationUserActionItemsQuery,
} from '../QueryNames';
import {
  ListUserActionItemsData,
} from '@meetingflow/common/Api/data-contracts';
import { useCallback, useRef, useMemo } from 'react';
import { useUserProfile } from './useProfile';

export const useActionItems = (
  organizationSlug: string
): {
  actionItems: ListUserActionItemsData;
  refetch: () => void;
  isLoading: boolean;
  error: unknown;
} => {
  const { getAccessTokenSilently, user } = useAuth0();
  const client = useQueryClient();

  const {
    data: actionItems,
    refetch: refetchActionItems,
    isLoading: actionItemsLoading,
    error: actionItemsError,
  } = useQuery<ListUserActionItemsData>(
    OrganizationUserActionItemsQuery(organizationSlug),
    async () => {
      const token = await getAccessTokenSilently();
      const response = await UserApiClient.listUserActionItems(
        { organizationSlug },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    },
    {
      enabled: !!organizationSlug,
      retry: (failureCount, error) => failureCount < 3,
    },
  );

  const refetch = useCallback(async () => {
    client.invalidateQueries(OrganizationUserActionItemsQuery(organizationSlug));
    await refetchActionItems();
  }, [refetchActionItems, client, organizationSlug]);

  return {
    actionItems: actionItems ?? [],
    refetch,
    isLoading: actionItemsLoading,
    error: actionItemsError,
  };
};
