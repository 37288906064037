import { DatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material';

export const DSDatePickerRoot = styled(DatePicker)(({ theme }) => `
  & .MuiOutlinedInput-notchedOutline {
    transition: border-color 0.2s ease-in-out;
  }
  
  & .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.primary.main};
    border-width: 1px;
  }
  
  & .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.text.primary};
  }
  
  & .MuiIconButton-root {
    color: ${theme.palette.text.secondary};
  }
`) as typeof DatePicker;
