import React from 'react';
import { ContactsApiClient } from '../../../../Services/NetworkCommon';
import { useQuery } from 'react-query';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress } from '@mui/material';
import { DSStatusMessage } from '../../Components/DSStatusMessage';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { LoadingContainer } from './DSContactDetailKeyStatements.styles';
import { DSKeyStatement } from './DSKeyStatement';
import {
  GetContactKeyStatementsData,
  ApiValidationError,
  BadRequestError,
  UnauthorizedError,
  ForbiddenError,
} from '@meetingflow/common/Api/data-contracts';

// Mock data that matches the API response structure
const MOCK_KEY_STATEMENTS: GetContactKeyStatementsData = {
  keyStatements: [
    {
      speaker: 'andrew@augment.co',
      text: "By the way, in the investor call today, Yes. Marcel made the point strongly that While it was interesting to have internal meetings that Progres deal flows. It was gonna be necessary because that's what the Grid X guys are doing. It was gonna be necessary for the sellers to decide what is and isn't progressive for the deal room. So even if the Internal meeting was pertinent to the deal room progress, it wasn't necessarily ready for the clients to see it. And in other cases, it might be. So make sure that the seller version of the deal room allowed for the possibility of internal meetings that don't get published. Makes sense?",
      sentimentScore: 0.8679,
      classification: 'CRITICAL',
      confidence: 0.9,
      timestamp: 72,
      meetingPlanId: 'f3b68575-9fa1-43d9-9ab9-af6f967f4863',
    },
    {
      speaker: 'andrew@augment.co',
      text: 'I love that idea.',
      sentimentScore: 0.6369,
      classification: 'SUPPORTIVE',
      confidence: 0.9,
      timestamp: 243,
      meetingPlanId: 'f3b68575-9fa1-43d9-9ab9-af6f967f4863',
    },
    {
      speaker: 'andrew@augment.co',
      text: "I love that idea. I think it's really smart. Okay. What else in the collaborate section? Because it's such a crucial section. This notion of the buying committee showing up and interacting And they're giving things up and down. They're oh, what about the What about the notion that The buyer can navigate stages of the buyer cycle. And promote the deal room the next stage Cahen appropriate.",
      sentimentScore: 0.9242,
      classification: 'SUPPORTIVE',
      confidence: 0.9,
      timestamp: 49,
      meetingPlanId: 'f3b68575-9fa1-43d9-9ab9-af6f967f4863',
    },
    {
      speaker: 'julien@augment.co',
      text: "Well, yeah. Yeah. Full recording. Yeah. Yeah. And Yeah. For the the pretty much a Meetingflow flow view needs to be seen by the seller and the buyer. Next step. Like, suggested next step. Once you're done with the call, I'd like to roast this point. What what you should try to work for towards So action plan for seller. So it's not just the extension plan. Like, right, AI could even create an email template that recaps the call. Not only you have the trans transcript, but it creates an email based on the transcript That recaps it. Thanks Cahen for attending. And then suggest the next step. Or recaps the next step that was discussed on the call if one was discussed on the call.",
      sentimentScore: 0.9418,
      classification: 'SUPPORTIVE',
      confidence: 0.9,
      timestamp: null,
      meetingPlanId: 'f3b68575-9fa1-43d9-9ab9-af6f967f4863',
    },
    {
      speaker: 'julien@augment.co',
      text: "Yeah. So that's right. Share content like, or dislike content, you should be able to do that. Get a history of all your meaningful moments Oh, meaningful sorry. Money meaningful events. That happened get access to full library of the artifacts that have been shared with you so far. The collaboration. Being able to annotate or comment or edit in some Cahen. Artifacts either by you, the buyer, or I Another buyer you invited? Like, basic stuff, like, scheduling or calendaring a meeting with a with your seller with your assigned seller, Chatting with your assigned seller within the deal room if you want.",
      sentimentScore: 0.9419,
      classification: 'SUPPORTIVE',
      confidence: 0.85,
      timestamp: 4,
      meetingPlanId: 'f3b68575-9fa1-43d9-9ab9-af6f967f4863',
    },
  ],
};

// Toggle for mock data - can be controlled by env var or feature flag
const USE_MOCK_DATA = false;

interface DSContactDetailKeyStatementsProps {
  contactId: number;
}

export const DSContactDetailKeyStatements: React.FC<
  DSContactDetailKeyStatementsProps
> = ({ contactId }) => {
  const { slug: organizationSlug, organization } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();
  const {
    data: contactData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['contact', organizationSlug, contactId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const response = await ContactsApiClient.getContact(
        organizationSlug ?? '',
        contactId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response;
    },
    enabled: !!organizationSlug,
  });

  const contact = contactData?.data;

  const { data: keyStatementsData, isLoading: isLoadingKeyStatements } =
    useQuery<
      GetContactKeyStatementsData,
      ApiValidationError | BadRequestError | UnauthorizedError | ForbiddenError
    >({
      queryKey: ['contactKeyStatements', organizationSlug, contactId],
      queryFn: async (): Promise<GetContactKeyStatementsData> => {
        if (USE_MOCK_DATA) {
          // Simulate network delay
          await new Promise((resolve) => setTimeout(resolve, 500));
          return MOCK_KEY_STATEMENTS;
        }

        const token = await getAccessTokenSilently();
        const response = await ContactsApiClient.getContactKeyStatements(
          {
            organizationSlug: organizationSlug ?? '',
            contactIdOrEmail: contactId,
          },
          { headers: { Authorization: `Bearer ${token}` } },
        );
        return response.data;
      },
      enabled: !!organizationSlug && !!contactId,
    });

  if (isLoading || isLoadingKeyStatements) {
    return (
      <DSStatusMessage
        icon={FormatQuoteIcon}
        title="Loading Key Statements..."
        iconSize={48}
      />
    );
  }

  if (error) {
    return (
      <DSStatusMessage
        icon={FormatQuoteIcon}
        title="Unable to Load Key Statements"
        subtitle="There was an error loading the key statements. Please try again later."
        iconColor="error.main"
        titleColor="error"
        iconSize={48}
      />
    );
  }

  if (!contact) {
    return null;
  }

  if (!keyStatementsData?.keyStatements?.length) {
    return (
      <DSStatusMessage
        icon={FormatQuoteIcon}
        title="No Key Statements Available"
        subtitle="No key statements have been analyzed for this contact yet."
        iconColor="text.secondary"
        titleColor="text.secondary"
        iconSize={48}
      />
    );
  }

  return (
    <Box
      sx={{
        paddingTop: '.5rem',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
      }}
    >
      {keyStatementsData.keyStatements.map((statement, index) => (
        <DSKeyStatement
          key={index}
          statement={statement}
          contact={contact}
          displayMeetingInfo
          displaySpeakerName
        />
      ))}
    </Box>
  );
};
