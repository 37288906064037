import React, { useCallback, useState } from 'react';
import { DescriptionPanelContainer } from './DealRoomArtifactDescriptionPanel.styles';
import { useQuery, useQueryClient } from 'react-query';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { ArtifactDescription } from './ArtifactDetailsViewComponents/ArtifactDescription';
import {
  OrganizationDealRoomArtifactQuery,
  OrganizationDealRoomArtifactsQuery,
} from '../../../QueryNames';
import toast from 'react-hot-toast';

interface DealRoomArtifactDescriptionPanelProps {
  organizationSlug: string;
  dealRoomId: number;
  artifactId: number;
}

export const DealRoomArtifactDescriptionPanel: React.FC<
  DealRoomArtifactDescriptionPanelProps
> = ({ organizationSlug, dealRoomId, artifactId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [description, setDescription] = useState<string>();

  const { data: artifact, refetch: refetchArtifact } = useQuery(
    [
      OrganizationDealRoomArtifactQuery,
      organizationSlug,
      dealRoomId,
      artifactId,
    ],
    async () => {
      const token = await getAccessTokenSilently();
      const response = await DealRoomsApiClient.getArtifact(
        organizationSlug,
        dealRoomId,
        artifactId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      setDescription(response.data.description || undefined);
      return response.data;
    },
  );

  const handleOnBlurDescription = useCallback(async () => {
    try {
      if (!artifact || (description || '') === (artifact.description || '')) {
        setIsEditingDescription(false);
        return;
      }

      const token = await getAccessTokenSilently();

      await toast.promise(
        DealRoomsApiClient.updateArtifact(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            description,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Updating description',
          success: () => {
            setIsEditingDescription(false);
            // Invalidate both queries to ensure all views update
            queryClient.invalidateQueries(
              OrganizationDealRoomArtifactQuery(
                organizationSlug,
                dealRoomId,
                artifactId,
              ),
            );
            queryClient.invalidateQueries(
              OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
            );
            refetchArtifact();
            return 'Successfully updated description.';
          },
          error: () =>
            'Something went wrong updating the description, please try again later.',
        },
      );
    } catch (err) {
      console.error(err);
    }
  }, [
    description,
    organizationSlug,
    dealRoomId,
    artifactId,
    artifact,
    getAccessTokenSilently,
    queryClient,
    refetchArtifact,
  ]);

  if (!artifact) {
    return null;
  }

  return (
    <DescriptionPanelContainer>
      <ArtifactDescription
        description={description}
        isEditingDescription={isEditingDescription}
        onStartEditDescription={() => setIsEditingDescription(true)}
        onBlurDescription={handleOnBlurDescription}
        onChangeDescription={setDescription}
        artifact={artifact}
      />
    </DescriptionPanelContainer>
  );
};
