import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueryClient } from 'react-query';
import { DealRoomsApiClient } from '../Services/NetworkCommon';
import {
  OrganizationDealRoomsQuery,
  OrganizationNotificationsDataQuery,
} from '../QueryNames';
import {
  ListDealRoomsParams,
  ListDealRoomsData,
  DealRoomNotification,
} from '@meetingflow/common/Api/data-contracts';
import { useCallback, useRef, useMemo } from 'react';
import { useCollabProvider } from './useCollabProvider';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import * as Y from 'yjs';
import randomColor from 'randomcolor';
import { useUserProfile } from './useProfile';
import toast from 'react-hot-toast';
import { getNotificationTitle } from '../Components/DealRoom/Tabs/Inbox/utils';

export const useDealRooms = (
  organizationSlug: string,
  showNotificationPopups: boolean = false,
): {
  dealRooms: ListDealRoomsData;
  refetch: () => void;
  isLoading: boolean;
  error: unknown;
  allNotifications: DealRoomNotification[];
  totalUnreadCount: number;
} => {
  const { getAccessTokenSilently, user } = useAuth0();
  const { user: userProfile } = useUserProfile();
  const client = useQueryClient();
  const previousNotificationsRef = useRef<DealRoomNotification[]>([]);
  const isInitialLoadRef = useRef(true);

  const ydoc = useMemo(() => new Y.Doc(), []);

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  // const onMessage = useCallback(
  //   (messageType: number) => {
  //     switch (messageType) {
  //       case WS_MESSAGE.REFRESH_DEALROOM_NOTIFICATIONS:
  //         client.invalidateQueries(
  //           OrganizationNotificationsDataQuery(organizationSlug),
  //         );
  //         return true;
  //       default:
  //         return false;
  //     }
  //   },
  //   [client, organizationSlug],
  // );

  // const { provider: _provider } = useCollabProvider({
  //   providerName: 'DealRooms',
  //   documentName: organizationSlug ? `DealRooms__${organizationSlug}` : '',
  //   ydoc,
  //   color,
  //   email: userProfile?.email || user!.email!,
  //   name: userProfile?.name || user!.name,
  //   picture:
  //     userProfile?.avatarFileUrl || userProfile?.avatarUrl || user?.picture,
  //   onMessage,
  //   connect: !!organizationSlug,
  // });

  const {
    data: dealRoomsData,
    refetch: refetchDealRooms,
    isLoading: dealRoomsLoading,
    error: dealRoomsError,
  } = useQuery<ListDealRoomsData>(
    [OrganizationDealRoomsQuery, organizationSlug],
    async () => {
      const token = await getAccessTokenSilently();
      const response = await DealRoomsApiClient.listDealRooms(
        { organizationSlug },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    },
    {
      enabled: !!organizationSlug,
      retry: (failureCount, error) => failureCount < 3,
    },
  );

  // const { data: notificationData, isLoading: notificationDataLoading } =
  //   useQuery(
  //     OrganizationNotificationsDataQuery(organizationSlug),
  //     async () => {
  //       const token = await getAccessTokenSilently();
  //       // @ts-ignore for now since endpoint not ready
  //       const response = await DealRoomsApiClient.listAllNotifications(
  //         { organizationSlug },
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         },
  //       );
  //       return response.data;
  //     },
  //     {
  //       enabled: !!organizationSlug,
  //       onSuccess: (data) => {
  //         if (!isInitialLoadRef.current && showNotificationPopups) {
  //           const newNotifications = data.filter(
  //             (notification: DealRoomNotification) =>
  //               !previousNotificationsRef.current.find(
  //                 (prev) => prev.id === notification.id,
  //               ),
  //           );

  //           newNotifications.forEach((notification: DealRoomNotification) => {
  //             toast(getNotificationTitle(notification), {
  //               duration: 4000,
  //               position: 'bottom-right',
  //             });
  //           });
  //         }
  //         isInitialLoadRef.current = false;
  //         previousNotificationsRef.current = data;
  //       },
  //     },
  //   );

  // const isLoading = dealRoomsLoading || notificationDataLoading;
  const isLoading = dealRoomsLoading;

  // const totalUnreadCount =
  //   notificationData?.filter((n: DealRoomNotification) => n.status === 'UNREAD')
  //     .length ?? 0;

  const refetch = useCallback(async () => {
    client.invalidateQueries(OrganizationDealRoomsQuery(organizationSlug));
    await refetchDealRooms();
  }, [refetchDealRooms, client, organizationSlug]);

  return {
    dealRooms: dealRoomsData ?? [],
    refetch,
    isLoading,
    error: dealRoomsError,
    allNotifications: [], // Not ready
    totalUnreadCount: 0, // Not ready
  };
};
