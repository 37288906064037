import React from 'react';
import {
  SurveyDocumentIllustration,
  SurveyGraphIllustration,
  SurveyTableIllustration,
  SurveyPersonIllustration,
  SurveyEmailIllustration,
  SurveyDesktopIllustration,
  SurveyChatIllustration,
  SurveyMessageIllustration,
  PersonRibbon,
  PersonQuestion,
  BotSparkle,
} from './BuyerOrientationIllustrations';
import {
  StepIndexesBasedOnName,
  StepNames,
  StepNamesBasedOnIndex,
  SurveyOptions,
  SurveyStepComponents,
} from '../../../../types/BuyerOrientationSurveyTypes';
import { BuyerOrientationPersonalDetails } from './BuyerOrientationSteps/BuyerOrientationPersonalDetails';
import { BuyerOrientationAssistance } from './BuyerOrientationSteps/BuyerOrientationAssistance';
import { BuyerOrientationConsumption } from './BuyerOrientationSteps/BuyerOrientationConsumption';
import { BuyerOrientationPriorities } from './BuyerOrientationSteps/BuyerOrientationPriorities';
import { BuyerOrientationCommunication } from './BuyerOrientationSteps/BuyerOrientationCommunication';
import { BuyerOrientationInvitations } from './BuyerOrientationSteps/BuyerOrientationInvitations';

export const stepNames: StepNames = {
  personalDetails: 'personalDetails',
  assistancePreference: 'assistancePreference',
  consumptionPreference: 'consumptionPreference',
  communicationPreference: 'communicationPreference',
  priorities: 'priorities',
  invitations: 'invitations',
};

export const stepNamesBasedOnIndex: StepNamesBasedOnIndex = {
  // 1: stepNames.personalDetails,
  1: stepNames.assistancePreference,
  2: stepNames.consumptionPreference,
  3: stepNames.communicationPreference,
  4: stepNames.priorities,
  // 6: stepNames.invitations,
};

export const stepIndexesBasedOnName: StepIndexesBasedOnName = {
  // [stepNames.personalDetails]: 1,
  [stepNames.assistancePreference]: 1,
  [stepNames.consumptionPreference]: 2,
  [stepNames.communicationPreference]: 3,
  [stepNames.priorities]: 4,
  // [stepNames.invitations]: 6,
};

export const buyerOrientationSurveyOptions: SurveyOptions = {
  personalDetails: { title: '', subtitle: '', options: [] },
  assistancePreference: {
    title: 'How much help do you want from me?',
    subtitle: 'I can take the lead, or you can — or we can meet in the middle.',
    options: [
      {
        optionId: 1,
        title: 'Self-serve please!',
        subtitle:
          'I like to do things on my own and I will ask if I need help.',
        value: 'SELF_SERVE',
        icon: <PersonRibbon />,
      },
      {
        optionId: 2,
        title: 'A bit of both',
        subtitle:
          'I am okay starting on my own but I will probably need guidance here and there.',
        value: 'MIXED',
        icon: <PersonQuestion />,
      },
      {
        optionId: 3,
        title: 'Do it for me!',
        subtitle:
          'I would like you to assist and guide me every step of the way.',
        value: 'FULL_SERVE',
        icon: <BotSparkle />,
      },
    ],
  },
  consumptionPreference: {
    title: 'How do you prefer to consume content?',
    subtitle:
      'I can tailor your experience to the ways you like to get caught up. Select as many as you like.',
    options: [
      {
        optionId: 1,
        title: 'Words',
        subtitle: 'Written documents',
        value: 'PROSE',
        icon: <SurveyDocumentIllustration />,
      },
      {
        optionId: 2,
        title: 'Pictures',
        subtitle: 'Graphs and visuals',
        value: 'VISUAL',
        icon: <SurveyGraphIllustration />,
      },
      {
        optionId: 3,
        title: 'Numbers',
        subtitle: 'Charts and Tables',
        value: 'CHARTS',
        icon: <SurveyTableIllustration />,
      },
      {
        optionId: 4,
        title: 'Voice',
        subtitle: 'Voice and Video memos',
        value: 'AURAL',
        icon: <SurveyPersonIllustration />,
      },
    ],
  },
  communicationPreference: {
    title: 'What’s your preferred method of communication?',
    subtitle:
      'We’ll use your preferred method whenever possible. Don’t worry, we won’t spam you. Select one.',
    options: [
      {
        optionId: 1,
        title: 'Email',
        subtitle: '',
        value: 'EMAIL',
        icon: <SurveyEmailIllustration />,
      },
      {
        optionId: 2,
        title: 'Phone / Conference Call',
        subtitle: '',
        value: 'CALL',
        icon: <SurveyDesktopIllustration />,
      },
      {
        optionId: 3,
        title: 'In Decision Site',
        subtitle: '',
        value: 'CHAT',
        icon: <SurveyChatIllustration />,
      },
      {
        optionId: 4,
        title: 'Text \n' + '(SMS)',
        subtitle: '',
        value: 'SMS',
        icon: <SurveyMessageIllustration />,
      },
    ],
  },
  priorities: {
    title: 'What are your top priorities?',
    subtitle:
      'Knowing your role and priorities helps us tailor the content and information surfaced to you. Select as many as you like.',
    options: [
      {
        optionId: 1,
        title: 'Cost Savings',
        subtitle:
          'I am facing cost pressure from my current vendor and need a solution that is helping me save money.',
        value: 'COST_SAVINGS',
        icon: null,
      },
      {
        optionId: 2,
        title: 'Performance',
        subtitle:
          'I want a solution with unmatched features and functions.',
        value: 'PERFORMANCE',
        icon: null,
      },
      {
        optionId: 3,
        title: 'Reliability',
        subtitle:
          'I want a proven and tested solution with reliable services.',
        value: 'RELIABILITY',
        icon: null,
      },
      {
        optionId: 4,
        title: 'Legal',
        subtitle:
          'I review contract language and/or support the contracting and negotiation phase.',
        value: 'LEGAL',
        icon: null,
      },
      {
        optionId: 5,
        title: 'Compliance + Security',
        subtitle:
          'I want a solution that meets our internal and industry compliance and security standards.',
        value: 'COMPLIANCE_SECURITY',
        icon: null,
      },
      {
        optionId: 6,
        title: 'Commercials',
        subtitle: 'I am here to decide if the commercial terms are compelling and meet our company\'s financial agenda.',
        value: 'COMMERCIALS',
        icon: null,
      },
      {
        optionId: 7,
        title: 'Business Needs',
        subtitle:
          'I have unique business needs that are unmet and/or my current vendor no longer meets my business needs.',
        value: 'BUSINESS_NEEDS',
        icon: null,
      },
      {
        optionId: 8,
        title: 'Coordination',
        subtitle:
          'I want access to breakthrough research and to engage with industry subject matter experts.',
        value: 'RESEARCH_THOUGHT_LEADERSHIP',
        icon: null,
      },
      {
        optionId: 9,
        title: 'Other',
        subtitle:
          'Tell us in your own words why you are here and what is your top priority.',
        value: 'OTHER',
        icon: null,
      },
    ],
  },
  invitations: { title: '', subtitle: '', options: [] },
};

export const closeIconProps = { iconName: 'ChromeClose' };

export const surveyStepComponents: SurveyStepComponents = {
  personalDetails: BuyerOrientationPersonalDetails,
  assistancePreference: BuyerOrientationAssistance,
  consumptionPreference: BuyerOrientationConsumption,
  communicationPreference: BuyerOrientationCommunication,
  priorities: BuyerOrientationPriorities,
  invitations: BuyerOrientationInvitations,
};

// TODO - change department values after we get BE support
export const surveyDepartmentOptions = [
  { key: '1', text: 'Sales & Business Development', value: '1' },
  { key: '2', text: 'Marketing & Communications', value: '2' },
  { key: '3', text: 'Customer Success & Support', value: '3' },
  { key: '4', text: 'Operations & Supply Chain', value: '4' },
  { key: '5', text: 'Finance & Accounting', value: '5' },
  { key: '6', text: 'Human Resources (HR)', value: '6' },
  { key: '7', text: 'Information Technology (IT) & Systems', value: '7' },
  { key: '8', text: 'Product Management & Development', value: '8' },
  { key: '9', text: 'Legal & Compliance', value: '9' },
  { key: '10', text: 'Executive Leadership (C-Level / VP)', value: '10' },
];

export const optionValuesThatNeedPhoneNumber = ['CALL', 'SMS'];
