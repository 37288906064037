import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';

// Set to true to use mock data instead of making an API call
export const USE_MOCK_DATA = false;

// Mock data for development and testing
export const MOCK_SUMMARY_DATA = {
  overview:
    "The recent meetings have highlighted a strategic focus on integrating Conner Jevning into Augment AI, leveraging his technical expertise to drive innovation. Key priorities include finalizing Conner's role, implementing artifact recommendation features, and enhancing user experiences. Challenges such as access control complexity and stakeholder alignment on the new CTO's role need addressing. Opportunities exist in differentiating user experiences and deploying AI-generated recommendations to strengthen competitive positioning. Ensuring stakeholder buy-in and technical feasibility will be critical to advancing the deal.",
  key_changes:
    'Recent meetings indicate a shift towards integrating Conner Jevning into Augment AI, with a focus on his technical background and experience at Chegg. Stakeholder priorities have evolved to emphasize technical leadership and innovation, as seen in discussions about the new CTO role and AI-generated recommendations. Technical requirements have expanded to include artifact recommendation features and differentiated user experiences. Commercial terms are being influenced by the need for seed extension financing and the strategic hiring of key personnel like Conner. New influencers include Noah and Julian, who are pivotal in defining technical outcomes.',
  next_steps:
    "To advance the deal, prioritize Conner's technical interview and work trial to finalize his role at Augment AI. Focus on completing the technical plan for artifact recommendations and collaborate with Noah and Julian to define feature outcomes. Engage with stakeholders like Andrew, the CEO, to ensure alignment on Conner's integration and the new CTO's responsibilities. Mitigate risks by addressing the complexity of access control features and ensuring clarity in role descriptions. Leverage opportunities by enhancing user experiences and implementing AI-generated recommendations.",
  risks_opportunities:
    "Potential delays could arise from the complexity of implementing access control and artifact recommendation features. Stakeholder alignment gaps may exist regarding the exact role of the new CTO and Conner's integration. Market factors, such as the need for innovative features, could impact competitive positioning. Upside scenarios include successful implementation of differentiated user experiences and AI-generated recommendations, enhancing platform value. Assumptions about technical feasibility and stakeholder buy-in need validation to ensure project success. Potential delays could arise from the complexity of implementing access control and artifact recommendation features. Stakeholder alignment gaps may exist regarding the exact role of the new CTO and Conner's integration. Market factors, such as the need for innovative features, could impact competitive positioning. Upside scenarios include successful implementation of differentiated user experiences and AI-generated recommendations, enhancing platform value. Assumptions about technical feasibility and stakeholder buy-in need validation to ensure project success.",
  generated_at: '2025-03-18T20:39:52.868386+00:00',
};

export interface SummaryResponse {
  overview?: string;
  key_changes?: string;
  next_steps?: string;
  risks_opportunities?: string;
  generated_at?: string;
}

/**
 * Custom hook for fetching journey summary data
 * This centralizes the query logic to avoid duplicate API calls
 */
export const useJourneySummary = () => {
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { getAccessTokenSilently } = useAuth0();

  const getSummaryData = async () => {
    // Return mock data if USE_MOCK_DATA is true
    if (USE_MOCK_DATA) {
      // Simulate network delay for more realistic development experience
      await new Promise((resolve) => setTimeout(resolve, 500));
      return MOCK_SUMMARY_DATA;
    }

    // Otherwise, make the actual API call
    const token = await getAccessTokenSilently();
    const response = await DealRoomsApiClient.getDealRoomSummary(
      {
        organizationSlug: organizationSlug || '',
        dealRoomId: dealRoomId || 0,
        type: 'journey',
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status !== 200) {
      throw new Error(`Failed to fetch summary: ${response.status} - ${response.statusText}`);
    }

    return response.data;
  };

  return useQuery<SummaryResponse>({
    queryKey: ['journey-summary', organizationSlug || '', dealRoomId || 0],
    queryFn: getSummaryData,
    refetchInterval: 30000,
    staleTime: 0,
    enabled: !!organizationSlug && !!dealRoomId,
  });
};
