import { useAuth0 } from '@auth0/auth0-react';
import { Duration } from 'luxon';
import {
  CheckboxVisibility,
  Dialog,
  DialogFooter,
  DialogType,
  FontSizes,
  FontWeights,
  IColumn,
  IconButton,
  mergeStyles,
  NeutralColors,
  Selection,
  SelectionMode,
  PrimaryButton,
  Text,
} from '@fluentui/react';
import { useBoolean, useForceUpdate } from '@fluentui/react-hooks';
import {
  Attendee,
  CallRecording,
  Contact,
  DetailedMeetingflow,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { inflect } from 'inflection';
import { orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import * as Y from 'yjs';
import {
  isGoogleUser,
  isMicrosoftUser,
} from '../../../Helpers/IdentityHelpers';
import { hasContent } from '../../../Helpers/yjsHelpers';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MeetingPlanShare } from '../../../Models/MeetingPlan';
import { ApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import MeetingPlanNotesSummaryForm from '../../Common/NotesSummaryForm';
import { AsyncDefaultButton, AsyncPrimaryButton } from '../../HOC/AsyncButton';
import { StyledDetailsList } from '../../StyledDetailsList';
import { CompanyCard, ContactCard } from '../MeetingPlanAttendees';
import { BaseSidePanel } from './BaseSidePanel';

type PersonWithKey = Contact & {
  key: string;
  share?: MeetingPlanShare;
  [key: string]: unknown;
};

export type EmailFollowupSidePanelProps = {
  internalDomains: string[];
  organizationSlug: string;
  meetingplan?: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'title'
    | 'startTime'
    | 'location'
    | 'conferenceInfo'
    | 'organizer'
    | 'creator'
    | 'attendees'
    | 'companies'
    | 'textSummary'
    | 'callRecording'
  >;
  notesYArray: Y.XmlText;
  tasks: Task[];
  initialSummary?: string;
  onBack?: () => void;
  onClose: () => void;
  onSuccess?: (
    to: Attendee[],
    cc: Attendee[],
    subject: string,
    body: string,
  ) => void;
};

export const EmailFollowupSidePanel = ({
  internalDomains,
  organizationSlug,
  meetingplan,
  notesYArray,
  initialSummary,
  tasks,
  onBack,
  onClose,
  onSuccess,
}: EmailFollowupSidePanelProps) => {
  const forceUpdate = useForceUpdate();
  const { user } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [subject, setSubject] = useState<string>(meetingplan?.title || '');
  const [body, setBody] = useState<string>(initialSummary || '');
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();
  const [hideConfirmDialog, { toggle: toggleConfirmDialog }] = useBoolean(true);

  const selection = useMemo(() => {
    return new Selection({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: forceUpdate,
      getKey: (item: Attendee) => item.email,
    });
  }, [forceUpdate]);

  useEffect(() => {
    if (meetingplan) {
      selection.setItems(meetingplan?.attendees);
      selection.setAllSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalDomains, meetingplan?.attendees]);

  const contentClass = mergeStyles({
    '.attendees': {
      paddingTop: '1rem',
      textAlign: 'center',
    },
  });

  if (!meetingplan) {
    return null;
  }

  const internalAttendees = meetingplan.attendees.filter((a) =>
    internalDomains.includes(a.emailDomain),
  );
  const externalAttendees = meetingplan.attendees.filter(
    (a) => !internalDomains.includes(a.emailDomain),
  );

  const columns: IColumn[] = [
    {
      key: 'company',
      name: 'Company',
      minWidth: 36,
      maxWidth: 36,
      fieldName: 'company',
      onRender: (person: PersonWithKey) => {
        const company = meetingplan.companies.find((c) =>
          c?.domains
            ?.map((domain) => domain.domain)
            .includes(person.emailDomain),
        );
        return (
          <div style={{ paddingTop: '2px' }}>
            {company ? (
              <CompanyCard
                company={company}
                organizationSlug={organizationSlug}
                logoOnly
                onClick={() => {
                  navigate(
                    `/organization/${organizationSlug}/library/companies/${company.id}`,
                  );
                }}
              />
            ) : undefined}
          </div>
        );
      },
    },
    {
      key: 'email',
      name: 'Email',
      minWidth: 100,
      fieldName: 'email',
      onRender: (person: PersonWithKey) => (
        <ContactCard
          contact={person}
          organizationSlug={organizationSlug}
          domain={person.emailDomain}
          hideBottomBorder
          hideSocialIcons
        />
      ),
    },
  ];

  const ShowEmptyGroupsGroupProps = {
    showEmptyGroups: false,
  };

  const getItemKey = (item: Contact, index?: number | undefined): string =>
    item.email;

  const items = [
    ...orderBy(internalAttendees, ['emailDomain']),
    ...orderBy(externalAttendees, ['emailDomain']),
  ];

  const groups = [
    {
      key: 'internal',
      name: 'Internal Attendees',
      startIndex: 0,
      count: internalAttendees.length,
      level: 0,
      isCollapsed: false,
    },
    {
      key: 'external',
      name: 'External Attendees',
      startIndex: internalAttendees.length,
      count: externalAttendees.length,
      level: 0,
      isCollapsed: false,
    },
  ];

  const sectionHeaderClass = mergeStyles({
    display: 'block',
    marginTop: '2rem',
    marginBottom: '.5rem',
    fontSize: FontSizes.medium,
    fontWeight: FontWeights.semibold,
    color: isDark ? NeutralColors.white : NeutralColors.gray180,
    lineHeight: '1.25rem',

    span: {
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.medium,
      textTransform: 'lowercase',
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.teal
        : MEETINGFLOW_COLORS.tealLight,
      borderRadius: '.75rem',
      color: MEETINGFLOW_COLORS.white,
      width: '1.25rem',
      height: '1.25rem',
      display: 'inline-block',
      textAlign: 'center',
    },
  });

  const selectionInternal = selection
    .getSelection()
    .filter((a) => internalDomains.includes(a.emailDomain));
  const selectionExternal = selection
    .getSelection()
    .filter((a) => !internalDomains.includes(a.emailDomain));

  const confirmDialogContentProps = {
    type: DialogType.normal,
    title: 'Are you sure?',
    closeButtonAriaLabel: 'Close',
    subText: `This will send an email to ${
      selection.getSelection().length
    } recipients. The email will be sent by Meetingflow and replies to it will come directly to you. Are you sure you want to send it?`,
  };

  return (
    <BaseSidePanel title="Email Follow-up" onBack={onBack} onClose={onClose}>
      <div className={contentClass}>
        <Text
          block
          style={{
            fontSize: FontSizes.small,
            color: NeutralColors.gray120,
          }}
        >
          <strong style={{ fontWeight: FontWeights.semibold }}>
            It's a best practice to send meeting attendees a follow-up email.
          </strong>{' '}
          {hasContent(notesYArray)
            ? `We've asked GPT to create a draft of a follow-up email. You can use it, edit it, or delete it entirely.`
            : `We will ask GPT to draft a follow-up email for you once you've taken some notes!`}
        </Text>

        <Text className={sectionHeaderClass}>Compose Email</Text>

        <div
          style={{
            padding: '.5rem',
            borderRadius: '.25rem',
            backgroundColor: isDark
              ? NeutralColors.gray200
              : MEETINGFLOW_COLORS.purpleUltraLight,
          }}
        >
          <MeetingPlanNotesSummaryForm
            organizationSlug={organizationSlug}
            meetingplan={meetingplan}
            summaryType={'FOLLOWUP_EMAIL'}
            initialSummaryValue={initialSummary}
            summarizeDisabled={
              !hasContent(notesYArray) &&
              !meetingplan?.callRecording?.transcript?.length
            }
            surfaceName="EMAIL_FOLLOW_UP"
            onSubjectChange={setSubject}
            onSummaryChange={setBody}
            onSummaryGenerated={setBody}
            disabledMessage="You must take notes before you can draft a follow-up email."
          />
        </div>

        <div className="recipients" style={{ position: 'relative' }}>
          <IconButton
            key="copy to clipboard"
            title="Copy selected email addresses to clipboard"
            text="Copy Selected"
            iconProps={{
              iconName: 'Copy',
              styles: {
                root: {
                  fontSize: '10px',
                },
              },
            }}
            styles={{
              root: {
                width: '1rem',
                height: '1rem',
                position: 'absolute',
                top: '.5rem',
                right: '.25rem',
                zIndex: '500',
              },
              icon: { fontSize: '10px' },
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              const emailAddresses: string[] = selection
                .getSelection()
                .map((a) => a.email);
              navigator.clipboard.writeText(emailAddresses.join(', '));
              toast.success(
                `Copied ${emailAddresses.length} ${inflect(
                  'email address',
                  emailAddresses.length,
                )} to the clipboard.`,
              );
            }}
          />
          <Text className={sectionHeaderClass}>Select Recipients</Text>

          <StyledDetailsList
            items={items}
            groups={groups}
            columns={columns}
            selectionMode={SelectionMode.multiple}
            // @ts-ignore
            selection={selection}
            checkboxVisibility={CheckboxVisibility.always}
            getKey={getItemKey}
            setKey={'multiple'}
            isHeaderVisible={false}
            compact
            groupProps={ShowEmptyGroupsGroupProps}
            noDataMessage={`There are no attendees.`}
          />
        </div>

        <div
          style={{
            marginTop: '1rem',
            display: 'flex',
            direction: 'rtl',
            columnGap: '.25rem',
          }}
        >
          <AsyncPrimaryButton
            styles={{
              root: {
                height: '24px !important',
                border: 'none !important',
                position: 'relative',
              },
              label: {
                fontSize: FontSizes.small,
              },
            }}
            onClick={async () => toggleConfirmDialog()}
            disabled={
              !subject.length || !body.length
              // selectionExternal.length === 0 ||
            }
          >
            <span
              style={{
                fontSize: FontSizes.small,
                fontWeight: FontWeights.semibold,
              }}
            >
              <>
                Send to {selection.count} Attendee
                {selection.count !== 1 ? 's' : ''}
              </>
            </span>
          </AsyncPrimaryButton>
          {isGoogleUser(user!.sub!) ? (
            <AsyncDefaultButton
              styles={{
                root: {
                  height: '24px !important',
                  border: 'none !important',
                  position: 'relative',
                  backgroundColor: isDark
                    ? NeutralColors.gray180
                    : NeutralColors.gray80,
                },
                rootHovered: {
                  backgroundColor: isDark
                    ? NeutralColors.gray160
                    : NeutralColors.gray60,
                },
                label: {
                  fontSize: FontSizes.small,
                },
              }}
              disabled={!subject.length || !body.length}
              onClick={async () => {
                const emailAddresses: string[] = selection
                  .getSelection()
                  .map((a) => a.email);
                appInsights.trackEvent({
                  name: 'SEND_FOLLOWUP_EMAIL_OPEN_GMAIL',
                  properties: {
                    organizationSlug,
                    meetingPlanId: meetingplan.id,
                    emailCount: emailAddresses.length,
                  },
                });
                window.open(
                  `https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddresses}&su=${encodeURIComponent(
                    subject,
                  )}&body=${encodeURIComponent(body)}`,
                );
              }}
            >
              Open in Gmail
            </AsyncDefaultButton>
          ) : null}
          {isMicrosoftUser(user!.sub!) ? (
            <AsyncDefaultButton
              styles={{
                root: {
                  height: '24px !important',
                  border: 'none !important',
                  position: 'relative',
                  backgroundColor: isDark
                    ? NeutralColors.gray180
                    : NeutralColors.gray80,
                },
                rootHovered: {
                  backgroundColor: isDark
                    ? NeutralColors.gray160
                    : NeutralColors.gray60,
                },
                label: {
                  fontSize: FontSizes.small,
                },
              }}
              disabled={!subject.length || !body.length}
              onClick={async () => {
                const emailAddresses: string[] = selection
                  .getSelection()
                  .map((a) => a.email);
                appInsights.trackEvent({
                  name: 'SEND_FOLLOWUP_EMAIL_OPEN_GMAIL',
                  properties: {
                    organizationSlug,
                    meetingPlanId: meetingplan.id,
                    emailCount: emailAddresses.length,
                  },
                });
                window.open(
                  `https://outlook.office365.com/mail/deeplink/compose?to=${emailAddresses}&subject=${encodeURIComponent(
                    subject,
                  )}&body=${encodeURIComponent(body)}`,
                );
              }}
            >
              Open in Outlook
            </AsyncDefaultButton>
          ) : null}
        </div>
        <Dialog
          hidden={hideConfirmDialog}
          onDismiss={toggleConfirmDialog}
          dialogContentProps={confirmDialogContentProps}
        >
          <DialogFooter>
            <AsyncDefaultButton
              onClick={async () => toggleConfirmDialog()}
              text="Cancel"
            />
            <AsyncPrimaryButton
              onClick={async () => {
                const token = await getAccessTokenSilently();
                try {
                  await toast.promise(
                    ApiClient.post(
                      `/organization/${organizationSlug}/plan/${meetingplan.id}/followup`,
                      {
                        internalRecipients: selectionInternal.map(
                          (a) => a.email,
                        ),
                        externalRecipients: selectionExternal.map(
                          (a) => a.email,
                        ),
                        subject,
                        message: body,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      },
                    ),
                    {
                      loading: 'Sending follow-up email...',
                      success: () => {
                        appInsights.trackEvent({
                          name: 'SEND_FOLLOWUP_EMAIL',
                          properties: {
                            organizationSlug,
                            meetingPlanId: meetingplan.id,
                            internalRecipientCount: selectionInternal.length,
                            externalRecipientCount: selectionExternal.length,
                          },
                        });

                        return `Sent a follow-up email to ${selection.getSelectedCount()} ${inflect(
                          'person',
                          selection.getSelectedCount(),
                        )} via email!`;
                      },
                      error: (err) => {
                        return `Something went wrong sending your message, please try again`;
                      },
                    },
                  );

                  onSuccess &&
                    onSuccess(
                      selectionInternal,
                      selectionExternal,
                      subject,
                      body,
                    );
                  toggleConfirmDialog();
                } catch {}
              }}
              text="Send"
            />
          </DialogFooter>
        </Dialog>
      </div>
    </BaseSidePanel>
  );
};
