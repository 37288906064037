import { Box, Paper, styled } from '@mui/material';
import ReactMarkdown from 'react-markdown';

export const HighlightsCard = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'showBorder',
})<{ showBorder?: boolean }>(({ theme, showBorder = true }) => ({
  padding: showBorder ? theme.spacing(2) : 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  border: showBorder ? `1px solid ${theme.palette.divider}` : 'none',
  borderRadius: '.25rem',
  boxSizing: 'border-box',
  height: '100%',
  width: showBorder ? 'calc(100% - 4rem)' : '100%', // Slightly narrower to allow for adjacent cards to be visible
  margin: '0 auto', // Center the card within its container
  transition: 'box-shadow 0.3s ease',
}));

export const HighlightsCardHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.5rem',
});

export const HighlightsIconWrapper = styled(Box)({
  width: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const HighlightsCardContent = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  padding: '0 0 0 2rem',
  flex: 1,
  position: 'relative',
  // Hide scrollbar
  '&::-webkit-scrollbar': {
    width: '0px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'transparent',
  },
  // Only show the fade effect when content is scrollable
  '&.has-overflow::after': {
    content: '""',
    position: 'sticky',
    display: 'block',
    bottom: 0,
    left: 0,
    right: 0,
    marginTop: '-40px', // Pull the gradient up to overlay the content
    height: '40px',
    background: `linear-gradient(to bottom, rgba(255,255,255,0) 0%, ${theme.palette.background.default} 100%)`,
    pointerEvents: 'none',
  },
}));

export const HighlightsStyledMarkdown = styled(ReactMarkdown)`
  min-height: 100%;
  margin-bottom: 0;

  h1 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0.75rem;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0.75rem;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
  }

  ul {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }

  li {
    line-height: 1.25rem;
  }
`;
