import { FC, useEffect, useState, useRef } from 'react';
import { Typography } from '@mui/material';
import {
  HighlightsCard as StyledHighlightsCard,
  HighlightsCardHeader,
  HighlightsCardContent,
  HighlightsStyledMarkdown,
  HighlightsIconWrapper,
} from './DSHighlightsCard.styles';

export const DSHighlightsCard: FC<{
  title: string;
  content: string;
  icon: React.ReactNode;
  showBorder?: boolean;
}> = ({ title, content, icon, showBorder = true }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  // Check for overflow when component mounts and when content changes
  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const scrollHeight = contentRef.current.scrollHeight;
        const clientHeight = contentRef.current.clientHeight;
        const hasContentOverflow = scrollHeight > clientHeight;
        setHasOverflow(hasContentOverflow);
      }
    };

    // Initial check
    checkOverflow();

    // Check after a longer delay to ensure content is fully rendered
    const timer = setTimeout(checkOverflow, 500);

    // Check one more time after a longer delay
    const secondTimer = setTimeout(checkOverflow, 1000);

    return () => {
      clearTimeout(timer);
      clearTimeout(secondTimer);
    };
  }, [content, title]); // Re-check when content or title changes

  // Handle scroll events to detect when at the bottom
  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        // Check if scrolled to bottom (with a small buffer of 5px)
        const bottom = scrollHeight - scrollTop - clientHeight < 5;
        setIsAtBottom(bottom);
      }
    };

    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', handleScroll);
      // Initial check
      handleScroll();
    }

    return () => {
      if (contentElement) {
        contentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <StyledHighlightsCard elevation={0} showBorder={showBorder}>
      <HighlightsCardHeader>
        <HighlightsIconWrapper>{icon}</HighlightsIconWrapper>
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          {title}
        </Typography>
      </HighlightsCardHeader>
      <HighlightsCardContent
        ref={contentRef}
        className={`highlights-card-content ${hasOverflow && !isAtBottom ? 'has-overflow' : ''}`}
      >
        <HighlightsStyledMarkdown>{content}</HighlightsStyledMarkdown>
      </HighlightsCardContent>
    </StyledHighlightsCard>
  );
};

export default DSHighlightsCard;
