import { FC } from 'react';
import { DateTime } from 'luxon';
import {
  StyledArtifactContainer,
  StyledMediaContainer,
  StyledContentContainer,
  StyledArtifactHeader,
  StyledArtifactName,
  StyledArtifactDescription,
  StyledArtifactTimestamp,
  StyledActionsContainer,
} from './DSWelcomeArtifactCard.styles';
import { DealRoomImageArtifact } from '@meetingflow/common/Api/data-contracts';
import { DSContactAvatar } from '../DS/DSContactAvatar';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from '../Artifacts/ArtifactIconPreview';
import { useNavigate as useRouterNavigate } from 'react-router-dom';
import { getArtifactLink } from '../utils/artifactUtils';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import { ArtifactsTableActions } from '../Artifacts/ArtifactsTableActions';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useQueryClient } from 'react-query';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';

interface DSWelcomeImageArtifactProps {
  imageArtifact: DealRoomImageArtifact;
}

export const DSWelcomeImageArtifact: FC<DSWelcomeImageArtifactProps> = ({
  imageArtifact,
}) => {
  const navigate = useRouterNavigate();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { userId } = useUserProfile();
  const { dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);
  const queryClient = useQueryClient();

  if (!imageArtifact) return null;

  // Format date for display
  const formatDate = (dateString?: string) => {
    if (!dateString) return '';
    const date = DateTime.fromISO(dateString);
    return date.toFormat('MMM d, yyyy');
  };

  const handleClick = (e: React.MouseEvent) => {
    // Don't navigate if clicking on the actions menu
    if (
      e.target instanceof HTMLElement &&
      (e.target.closest('.artifacts-actions-menu') ||
        e.target.closest('.ms-Button'))
    ) {
      e.stopPropagation();
      return;
    }

    // Navigate to the artifact detail page
    if (dealRoomId && organizationSlug) {
      const link = getArtifactLink(organizationSlug, dealRoomId, imageArtifact);

      // Use standard navigation with replace: false to ensure proper history
      navigate(link, { replace: false });
    }
  };

  const refreshDealRoom = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries([
        'dealRoom',
        organizationSlug,
        dealRoomId,
      ]);
    }
  };

  const refetchArtifacts = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries(
        OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
      );
    }
  };

  const handleDelete = async () => {
    await refetchArtifacts();
    await refreshDealRoom();
  };

  return (
    <StyledArtifactContainer onClick={handleClick}>
      <StyledActionsContainer className="artifacts-actions-menu">
        <ArtifactsTableActions
          userId={userId}
          dealRoomRole={dealRoomRole}
          organizationSlug={organizationSlug || ''}
          dealRoomId={dealRoomId || 0}
          refreshDealRoom={refreshDealRoom}
          refetchArtifacts={refetchArtifacts}
          onDelete={handleDelete}
          artifact={imageArtifact}
        />
      </StyledActionsContainer>
      <StyledMediaContainer>
        <ImageWithFallback
          src={imageArtifact.thumbnailUrl || imageArtifact.fileUrl}
          fallbackSrc={
            imageArtifact.thumbnailUrl ? imageArtifact.fileUrl : undefined
          }
          alt={imageArtifact.name || 'Image'}
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'contain',
          }}
          fallback={
            <ArtifactIconPreview
              type="IMAGE"
              fileExtension={imageArtifact.fileName?.split('.')?.pop() || ''}
              mimeType={imageArtifact.mimeType}
            />
          }
        />
      </StyledMediaContainer>
      <StyledContentContainer>
        <StyledArtifactHeader>
          {imageArtifact.creator && (
            <DSContactAvatar
              contact={imageArtifact.creator}
              size={28}
              sx={{ marginRight: 1 }}
            />
          )}
          <StyledArtifactName>
            {imageArtifact.label ||
              imageArtifact.name ||
              `An Image from ${imageArtifact.creator?.name || 'the Team'}`}
          </StyledArtifactName>
        </StyledArtifactHeader>
        {imageArtifact.description && (
          <StyledArtifactDescription variant="body2">
            {imageArtifact.description}
          </StyledArtifactDescription>
        )}
        <StyledArtifactTimestamp>
          {formatDate(imageArtifact.createdAt)}
        </StyledArtifactTimestamp>
      </StyledContentContainer>
    </StyledArtifactContainer>
  );
};
