import React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  TouchSensor,
  MouseSensor,
  DragStartEvent,
  DragMoveEvent,
} from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { DSSortableItem } from './DSSortableItem';
import { getDSSortableGridStyles } from './DSSortableGrid.styles';

interface DSSortableGridProps<T extends { id?: number; order: number }> {
  items: T[];
  onDragEnd: (event: DragEndEvent) => void;
  renderItem: (item: T, index: number) => React.ReactNode;
  className?: string;
  disableDrag?: boolean;
}

export function DSSortableGrid<T extends { id?: number; order: number }>({
  items,
  onDragEnd,
  renderItem,
  className,
  disableDrag = false,
}: DSSortableGridProps<T>) {
  const styles = getDSSortableGridStyles();

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 8,
      distance: 5,
    },
  });

  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const handleDragStart = (event: DragStartEvent) => {
    // If drag is disabled, prevent drag from starting
    if (disableDrag) {
      return;
    }
    const { active } = event;
    if (!active) return;
  };

  const handleDragMove = (event: DragMoveEvent) => {
    // If drag is disabled, prevent drag movement
    if (disableDrag) {
      return;
    }
    const { active } = event;
    if (!active) return;
  };

  const handleDragEnd = (event: DragEndEvent) => {
    // If drag is disabled, don't process drag end
    if (disableDrag) {
      return;
    }
    onDragEnd(event);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
      // Disable drag sensors entirely if disableDrag is true
      autoScroll={!disableDrag}
    >
      <SortableContext
        items={items.map((item) => item.id || item.order)}
        strategy={rectSortingStrategy}
      >
        <div className={className || styles.grid}>
          {items.map((item, index) => (
            <DSSortableItem
              key={item.id || item.order}
              id={item.id || item.order}
              disableDrag={disableDrag}
            >
              {renderItem(item, index)}
            </DSSortableItem>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}
