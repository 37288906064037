import { IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { useMilestonesSummary } from '../../../Hooks/useMilestonesSummary';
import { DSMenu, DSMenuItem } from '../DS';
import toast from 'react-hot-toast';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DSConfigurationMutualPlanDeleteMilestoneDialog } from '../Configuration/Pages/DSConfigurationMutualPlan/DSConfigurationMutualPlanDeleteMilestoneDialog';

type MilestoneOptionsProps = {
  isSeller: boolean;
  milestoneId: number;
  organizationSlug: string;
  dealRoomId: number;
  isMobile?: boolean;
  isVisible?: boolean;
  onRequestArtifact?: () => Promise<void>;
  onEditDate?: () => void;
};

export const MilestoneMoreOptions = ({
  isSeller,
  milestoneId,
  organizationSlug,
  dealRoomId,
  isMobile,
  isVisible,
  onRequestArtifact,
  onEditDate,
}: MilestoneOptionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const { refetch: refetchMilestonesSummary } = useMilestonesSummary(
    organizationSlug,
    dealRoomId,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentMilestone = useMemo(() => {
    if (!Array.isArray(mutualPlan?.milestones)) return null;
    return mutualPlan.milestones.find((m) => m.id === milestoneId);
  }, [mutualPlan?.milestones, milestoneId]);

  const handleHideMilestone = async () => {
    if (!Array.isArray(mutualPlan?.milestones)) return;

    const updatedMilestones = mutualPlan?.milestones.map((milestone) => ({
      id: milestone.id,
      title: milestone.title || '',
      visible:
        milestone.id === milestoneId ? !milestone.visible : milestone.visible,
      dueDate: milestone.dueDate,
    }));

    const token = await getAccessTokenSilently();
    await toast.promise(
      DealRoomsApiClient.saveMilestones(
        organizationSlug,
        dealRoomId,
        updatedMilestones,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      ),
      {
        loading: 'Updating milestones',
        success: () => {
          Promise.all([refetchMilestonesSummary(), refetchMutualPlan()]);
          return 'Successfully updated the milestones list';
        },
        error: () => 'Something went wrong updating the milestones list',
      },
    );

    setAnchorEl(null);
  };

  const handleDeleteMilestone = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.deleteMilestone(
          organizationSlug,
          dealRoomId,
          milestoneId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Deleting milestone...',
          success: () => {
            Promise.all([refetchMilestonesSummary(), refetchMutualPlan()]);
            return 'Milestone deleted successfully';
          },
          error: 'Failed to delete milestone',
        },
      );
      setAnchorEl(null);
    } catch (error) {
      console.error('Error deleting milestone:', error);
      toast.error('Failed to delete milestone');
    }
  }, [
    dealRoomId,
    getAccessTokenSilently,
    milestoneId,
    organizationSlug,
    refetchMilestonesSummary,
    refetchMutualPlan,
  ]);

  const openDeleteDialog = () => {
    setAnchorEl(null);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const showMenu = useMemo(() => {
    // Seller can always see the menu
    // Mobile users can see the menu if they have an edit date or request artifact action
    return (
      isSeller || (isVisible && isMobile && (onEditDate || onRequestArtifact))
    );
  }, [isSeller, isVisible, isMobile, onEditDate, onRequestArtifact]);

  useEffect(() => {
    if (!showMenu && anchorEl) {
      setAnchorEl(null);
    }
  }, [showMenu, anchorEl]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        onClick={handleClick}
        size="small"
        disabled={!showMenu}
        sx={{
          color: DEALROOMS_COLORS.cloudburst,
        }}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <DSMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isVisible && isMobile && onEditDate && (
          <DSMenuItem
            onClick={() => {
              handleClose();
              onEditDate();
            }}
            icon={<CalendarTodayIcon fontSize="small" />}
            primaryText="Edit Due Date"
          />
        )}
        {isVisible && isMobile && onRequestArtifact && (
          <DSMenuItem
            onClick={() => {
              handleClose();
              onRequestArtifact();
            }}
            icon={<MoveToInboxIcon fontSize="small" />}
            primaryText="Request Artifact"
          />
        )}
        {isSeller && (
          <DSMenuItem
            onClick={handleHideMilestone}
            icon={<VisibilityOffIcon fontSize="small" />}
            primaryText={
              !currentMilestone?.visible ? 'Show Milestone' : 'Hide Milestone'
            }
          />
        )}

        {isSeller && (
          <DSMenuItem
            onClick={openDeleteDialog}
            icon={<DeleteIcon fontSize="small" />}
            primaryText="Delete Milestone"
            sx={{
              color: DEALROOMS_COLORS.crimson,
              '& .MuiListItemIcon-root': {
                color: DEALROOMS_COLORS.crimson,
              },
            }}
          />
        )}
      </DSMenu>

      {currentMilestone && (
        <DSConfigurationMutualPlanDeleteMilestoneDialog
          isOpen={isDeleteDialogOpen}
          milestoneName={currentMilestone.title || 'Untitled Milestone'}
          onConfirm={handleDeleteMilestone}
          onDismiss={closeDeleteDialog}
        />
      )}
    </div>
  );
};
