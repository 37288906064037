import { FC, useState } from 'react';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { DealRoomBuyersJourneyTabContainer } from './DealRoomBuyersJourneyTab.styles';
import { DecisionSiteMeetingPanel } from '../Components/Journey/DecisionSiteMeetingPanel';
import { useOrganization } from '../../../Hooks/useOrganization';
import DSResponsiveDrawer from '../../../Components/Common/DSResponsiveDrawer';
import DecisionSiteMeetingflowListWithFilters from '../Components/Journey/DecisionSiteMeetingflowListWithFilters';
import DecisionSiteJourneySummary from '../Components/Journey/DecisionSiteJourneySummary';
import { useSearchParams } from 'react-router-dom';
import { DecisionSiteJourneySummaryPanel } from '../Components/Journey/DecisionSiteJourneySummaryPanel';
import UserMeetingflowList from '../Components/Journey/UserMeetingflowList';

interface DealRoomBuyersJourneyTabProps {}

export const DealRoomBuyersJourneyTab: FC<
  DealRoomBuyersJourneyTabProps
> = () => {
  const { dealRoomId, dealRoomRole } = useDealRoom();
  const { slug: organizationSlug } = useOrganization();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasSummary, setHasSummary] = useState<boolean | null>(null);

  if (!dealRoomId || !organizationSlug) return null;

  const showSummary = dealRoomRole === 'SELLER';
  const isSeller = dealRoomRole === 'SELLER';
  const selectedMeetingflowId = searchParams.get('meeting') || undefined;

  const handleSelectMeetingflow = (meetingflowId: string) => {
    // Update URL with meeting parameter and remove tab parameter
    const newParams = new URLSearchParams(searchParams);
    newParams.set('meeting', meetingflowId);
    newParams.delete('tab');
    setSearchParams(newParams, { replace: true });
  };

  const handleCloseMeetingPanel = () => {
    // Remove meeting and tab parameters from URL
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('meeting');
    newParams.delete('tab');
    setSearchParams(newParams, { replace: true });
  };

  const handleOpenSummary = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('summary', '');
    setSearchParams(newParams, { replace: true });
  };

  return (
    <DealRoomBuyersJourneyTabContainer>
      {hasSummary !== false && showSummary && (
        <div className="header">
          <div
            className="summary"
            onClick={handleOpenSummary}
            style={{ cursor: 'pointer' }}
          >
            <DecisionSiteJourneySummary
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
              fields={['overview']}
              layout="horizontal"
              showTimestamp={false}
              onQueryStateChange={setHasSummary}
            />
          </div>
        </div>
      )}
      <div className="content">
        <div className="column">
          <DecisionSiteMeetingflowListWithFilters
            onSelectMeetingflow={handleSelectMeetingflow}
          />
        </div>
        {isSeller && (
          <div className="column">
            <UserMeetingflowList 
              onAddMeetingflow={handleSelectMeetingflow}
            />
          </div>
        )}
      </div>
      <DSResponsiveDrawer
        anchor="right"
        widthPercentage={70}
        mobileWidthPercentage={90}
        open={Boolean(selectedMeetingflowId)}
        padding={'0'}
        maxWidth={800}
      >
        {selectedMeetingflowId && (
          <DecisionSiteMeetingPanel
            meetingPlanId={selectedMeetingflowId}
            onClickCloseButton={handleCloseMeetingPanel}
          />
        )}
      </DSResponsiveDrawer>
      {showSummary ? (
        <DecisionSiteJourneySummaryPanel
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
        />
      ) : null}
    </DealRoomBuyersJourneyTabContainer>
  );
};
