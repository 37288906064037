import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface DecisionSiteNavigationState {
  isDecisionSitesOpen: boolean;
  sortDirection: 'asc' | 'desc';
  showOnlyMine: boolean;
  setIsDecisionSitesOpen: (isOpen: boolean) => void;
  setSortDirection: (direction: 'asc' | 'desc') => void;
  setShowOnlyMine: (show: boolean) => void;
  reset: () => void;
}

export const useDecisionSiteNavigationStore =
  create<DecisionSiteNavigationState>()(
    persist(
      (set) => ({
        isDecisionSitesOpen: false,
        sortDirection: 'desc',
        showOnlyMine: false,
        setIsDecisionSitesOpen: (isOpen) =>
          set({ isDecisionSitesOpen: isOpen }),
        setSortDirection: (direction) => set({ sortDirection: direction }),
        setShowOnlyMine: (show) => set({ showOnlyMine: show }),
        reset: () =>
          set({
            isDecisionSitesOpen: false,
            sortDirection: 'desc',
            showOnlyMine: false,
          }),
      }),
      {
        name: 'decision-site-navigation',
      },
    ),
  );
