import { useInfiniteQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { ContactsApiClient } from '../../../../../Services/NetworkCommon';
import { OrganizationContactsQueryInfinite } from '../../../../../QueryNames';
import { Contact } from '@meetingflow/common/Api/data-contracts';
import { useDealRoomId } from '../../../../../Hooks/useDealRoomId';

// Default to a high value to get all contacts unless overridden
const DEFAULT_CONTACTS_PER_PAGE = 500;
const myFilter = false;
const includeInternalFilter = true;

export type SortKey =
  | 'name'
  | 'email'
  | 'createdAt'
  | 'timesMet30d'
  | 'timesMet90d'
  | 'lastMeetingDate'
  | 'nextMeetingDate';

interface UseDecisionSiteContactsProps {
  organizationSlug: string;
  sortBy: SortKey;
  sortOrder: 'asc' | 'desc';
  searchString: string;
  decisionSiteIds?: number[];
  perPage?: number;
}

export const useDecisionSiteContacts = ({
  organizationSlug,
  sortBy,
  sortOrder,
  searchString,
  decisionSiteIds,
  perPage = DEFAULT_CONTACTS_PER_PAGE,
}: UseDecisionSiteContactsProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const dealRoomId = useDealRoomId();

  const {
    data: contactsData,
    isLoading,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    [
      OrganizationContactsQueryInfinite(organizationSlug, true),
      sortBy,
      sortOrder,
      searchString,
      decisionSiteIds,
    ],
    async ({ pageParam }) => {
      const token = await getAccessTokenSilently();
      const params = {
        organizationSlug,
        limit: perPage,
        skip: pageParam || 0,
        name: searchString !== '' ? searchString : undefined,
        my: myFilter,
        hasPlans: true,
        isInternal: includeInternalFilter,
        sortBy,
        sortOrder,
        dealRoomIds: decisionSiteIds ?? (dealRoomId ? [dealRoomId] : undefined),
      };
      const response = await ContactsApiClient.listContacts(params, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    },
    {
      getPreviousPageParam: (previousPage) => {
        if (
          !previousPage?.config?.params?.skip ||
          previousPage.config.params.skip < perPage
        ) {
          return undefined;
        }
        return previousPage.config.params.skip - perPage;
      },
      getNextPageParam: (previousPage) => {
        if (!previousPage?.data?.length || previousPage.data.length < perPage) {
          return undefined;
        }

        return previousPage?.config?.params?.skip
          ? previousPage.config.params.skip + perPage
          : perPage;
      },
    },
  );

  const contacts = contactsData?.pages.flatMap((page) => page.data) ?? [];

  return {
    contacts,
    isLoading,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  };
};
