import React from 'react';
import { Box, Typography, SxProps } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

interface DSStatusMessageProps {
  title: string;
  subtitle?: string;
  icon: SvgIconComponent;
  iconColor?: string;
  titleColor?: string;
  iconSize?: number;
  sx?: SxProps;
}

export const DSStatusMessage: React.FC<DSStatusMessageProps> = ({
  title,
  subtitle,
  icon: Icon,
  iconColor = 'text.secondary',
  titleColor = 'text.secondary',
  iconSize = 40,
  sx,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="75%"
      height="75%"
      sx={{
        overflow: 'visible',
        padding: '2rem',
        flex: 1,
        position: 'relative',
        margin: 'auto',
        maxHeight: '75%',
        ...sx,
      }}
    >
      <Icon sx={{ fontSize: iconSize, color: iconColor }} />
      <Typography variant="h6" color={titleColor}>
        {title}
      </Typography>
      {subtitle && (
        <Typography variant="body1" color="text.secondary">
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};
