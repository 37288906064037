import { Select, MenuItem, FormControl, InputLabel, Box } from '@mui/material';
import {
  dealRoomActionItemStatusOptions,
  dealRoomActionItemStatusValues,
  getStatusBackgroundColor,
  getStatusTextColor,
} from './milestonesUtils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDynamicBreadcrumb } from '../../../Hooks/useDynamicBreadcrumb';
import { MilestoneArtifactsTable } from './MilestoneArtifactsTable';
import { useDeleteTaskDialog } from '../../../Hooks/Modals/DealRoom/useDeleteTaskDialog';
import {
  DealRoomMilestone,
  DealRoomActionItemStatus,
  DealRoomActionItem,
  Contact,
} from '@meetingflow/common/Api/data-contracts';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { useMilestonesSummary } from '../../../Hooks/useMilestonesSummary';
import { useAddAttachmentDialog } from '../../../Hooks/Modals/DealRoom/useAddAttachmentDialog';
import { useFulfillRequestAttachment } from '../../../Hooks/Modals/DealRoom/useFulfillRequestAttachment';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useActionItems } from '../../../Hooks/useActionItems';
import {
  Wrapper,
  Content,
  AttachmentsContainer,
  AttachmentsContainerTitle,
  dropdownMenuStyles,
} from './MilestoneActionItemPanel.styles';
import { ActionItemPanelFooter } from './ActionItemPanelFooter';
import { ActionItemPanelHeader } from './ActionItemPanelHeader';
import { DSResponsiveDrawer } from '../../Common/DSResponsiveDrawer';
import { DSTextField, DSDatePicker, DSContactAvatar, DSMenuItem } from '../DS';
import { DateTime } from 'luxon';
import { useDealRoomActivity } from '../Analytics/hooks/useDealRoomActivity';
import AssignmentIcon from '@mui/icons-material/Assignment';

type MilestoneActionItemPanelProps = {
  isOpen: boolean;
  onDismiss: () => void;
  currentActionItemId: number | null;
  organizationSlug: string;
  dealRoomId: number;
  parentMilestone: DealRoomMilestone | null;
  isEditing: boolean;
  handleUpdateSelectedMilestone: (milestoneId: number) => void;
};

const getContactDisplayName = (contact: Contact) => {
  return contact.name || contact.email;
};

export const MilestoneActionItemPanel = ({
  isOpen,
  onDismiss,
  currentActionItemId,
  organizationSlug,
  dealRoomId,
  parentMilestone,
  isEditing,
  handleUpdateSelectedMilestone,
}: MilestoneActionItemPanelProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const wasInitialValuesSelected = useRef(false);
  const oldTitle = useRef('');
  const oldDescription = useRef('');
  const oldMilestoneId = useRef(parentMilestone?.id);
  const oldOwnerId = useRef(-1);
  const oldStatus = useRef(dealRoomActionItemStatusValues.notStarted.key);
  const oldDueDate = useRef<string | null>(new Date().toISOString());

  const [actionItem, setActionItem] = useState<DealRoomActionItem | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState<DealRoomActionItemStatus>(
    dealRoomActionItemStatusValues.notStarted.key,
  );
  const [selectedMilestoneId, setSelectedMilestoneId] = useState<
    number | undefined
  >(parentMilestone?.id);
  const [dueDate, setDueDate] = useState<string | null>(
    parentMilestone?.dueDate
      ? parentMilestone.dueDate
      : new Date().toISOString(),
  );
  const [ownerId, setOwnerId] = useState<number | null>(null);

  const { recordActivity } = useDealRoomActivity();

  // Create path for breadcrumb URLs
  const path = `/organization/${organizationSlug}/decisionsite/${dealRoomId}`;

  // get data for all UI and the refetch functions to refetch data from the other components
  const { userId, user: mfUser } = useUserProfile();
  const { dealRoom } = useDealRoom(organizationSlug, dealRoomId);
  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const { milestonesSummary, refetch: refetchMilestonesSummary } =
    useMilestonesSummary(organizationSlug, dealRoomId);
  const { actionItems, refetch: refetchOrganizationActionItems } =
    useActionItems(organizationSlug);

  // used to show the AddAttachmentDialog component when the user clicks on the
  // "Add Attachment" button
  const { createDeferred: addAttachmentDeferred, dialog: addAttachmentDialog } =
    useAddAttachmentDialog({
      organizationSlug,
      dealRoomId,
      actionItemId: actionItem?.id || null,
    });

  // used to show the DeleteTaskDialog component when the user clicks on the
  // "Delete task" button
  const { createDeferred: deleteTaskDeferred, dialog: deleteTaskDialog } =
    useDeleteTaskDialog({
      organizationSlug,
      dealRoomId,
      taskId: actionItem?.id || null,
    });

  // used to show the FulfillRequestAttachmentDialog component when the user
  // clicks on the "Fulfill Request for Artifact" button
  const {
    createDeferred: fulfillRequestAttachmentDeferred,
    dialog: fulfillRequestAttachmentDialog,
  } = useFulfillRequestAttachment({
    organizationSlug,
    dealRoomId,
    actionItemId: actionItem?.id || null,
    ownerName: actionItem?.creator?.name || 'A contact',
    description: actionItem?.description || 'Please upload the specific file',
  });

  const handleSetTaskInfo = useCallback(
    async (actionItemId: number | null, callback?: () => void) => {
      try {
        if (!parentMilestone) return;

        if (!actionItemId) {
          setDueDate(parentMilestone?.dueDate || new Date().toISOString());
          setSelectedMilestoneId(parentMilestone?.id);
          if (callback) callback();
          return;
        }

        const token = await getAccessTokenSilently();
        const { data: response } = await DealRoomsApiClient.getActionItem(
          organizationSlug,
          dealRoomId,
          actionItemId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        if (!response) return;
        setActionItem(response);
        setTitle(response.actionItem || '');
        setDescription(response.description || '');
        setStatus(
          response.status || dealRoomActionItemStatusValues.notStarted.key,
        );
        const currentDueDate = response.dueDate || null;
        setDueDate(currentDueDate);

        // IMPORTANT: ID Mapping in the Action Items system
        // There are two types of IDs we need to handle:
        // 1. User IDs: Used by the API/backend (response.assignee.id is a User ID)
        // 2. Contact IDs: Used by the UI components (dealRoom.contacts[].id are Contact IDs)
        //
        // When working with owners/assignees:
        // - API responses/requests use User IDs (assignee.id, assigneeId)
        // - UI components use Contact IDs (for Select values, state management)
        // - We must convert between them using dealRoom.contacts (contact.userId === User ID)
        const assigneeContact = response.assignee?.id
          ? dealRoom?.contacts?.find((c) => c.userId === response.assignee?.id)
          : undefined;
        setOwnerId(assigneeContact?.id || null);

        setSelectedMilestoneId(response.milestone?.id);

        // set initial states
        oldTitle.current = response.actionItem || '';
        oldDescription.current = response.description || '';
        oldOwnerId.current = assigneeContact?.id || -1;
        oldMilestoneId.current = response.milestone?.id;
        oldStatus.current =
          response.status || dealRoomActionItemStatusValues.notStarted.key;
        oldDueDate.current = currentDueDate;
        if (callback) callback();
      } catch (error) {}
    },
    [
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      parentMilestone,
      dealRoom?.contacts,
    ],
  );

  useEffect(() => {
    // Reset the wasInitialValuesSelected every time we close the panel
    if (!isOpen) {
      wasInitialValuesSelected.current = false;
    }
  }, [isOpen]);

  useDynamicBreadcrumb(
    actionItem
      ? {
          label: actionItem.actionItem || `Task ${actionItem.id}`,
          icon: <AssignmentIcon fontSize="small" />,
          path: `${path}/mutual-action-plan?task=${actionItem.id}`,
        }
      : null,
    [actionItem, isOpen],
    {
      shouldReset: false, // We're handling reset manually in the onDismiss handler
      pathCheck: `${path}/mutual-action-plan`,
      resetBreadcrumbs: [],
    },
  );

  useEffect(() => {
    if (wasInitialValuesSelected.current) return;

    // Set the initial values only if we have the data needed for this action
    const canSetTheInitialValues = isEditing
      ? currentActionItemId !== null && !!parentMilestone
      : !!parentMilestone;

    if (!canSetTheInitialValues) return;

    handleSetTaskInfo(currentActionItemId, () => {
      // Set the wasInitialValuesSelected to true so we don't do the handleSetTaskInfo again
      wasInitialValuesSelected.current = true;
    }).then();
  }, [
    actionItem,
    currentActionItemId,
    handleSetTaskInfo,
    isEditing,
    parentMilestone,
  ]);

  // Handles deleting a task from header but the UI from ActionItemPanelHeader components is commented out for now
  // Wrap the onDismiss handler to ensure breadcrumbs are properly reset when the panel is closed
  const handleDismiss = useCallback(() => {
    // The breadcrumbs will be automatically reset by BreadcrumbManager
    // when the URL no longer contains the task parameter
    onDismiss();
  }, [onDismiss]);

  const handleClickDeleteTask = useCallback(async () => {
    try {
      await deleteTaskDeferred().promise;
      handleDismiss();
    } catch (err) {}
  }, [deleteTaskDeferred, handleDismiss]);

  // Handles requesting an attachment, ignoring any errors for now
  const handleAddAttachment = useCallback(async () => {
    try {
      await addAttachmentDeferred().promise;
      await handleSetTaskInfo(actionItem?.id || null);
    } catch (err) {}
  }, [actionItem?.id, addAttachmentDeferred, handleSetTaskInfo]);

  // Handles fulfilling an attachment request, ignoring any errors for now
  const handleClickFulfillRequestAttachment = useCallback(async () => {
    try {
      await fulfillRequestAttachmentDeferred().promise;
      await handleSetTaskInfo(actionItem?.id || null);
    } catch (err) {}
  }, [actionItem?.id, fulfillRequestAttachmentDeferred, handleSetTaskInfo]);

  const handleCreateTask = useCallback(async () => {
    try {
      if (!parentMilestone) return;
      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.createMilestoneActionItem(
          organizationSlug,
          dealRoomId,
          parentMilestone?.id || 0,
          {
            actionItem: title,
            dueDate,
            ...(ownerId
              ? {
                  assigneeId: ownerId,
                }
              : {}),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Creating task',
          success: (result) => {
            const createTaskResponse = result?.data;
            if (
              createTaskResponse &&
              'id' in createTaskResponse &&
              'milestone' in createTaskResponse &&
              createTaskResponse.milestone
            ) {
              setActionItem(createTaskResponse);
              // Record the activity for adding a mutual action item
              recordActivity({
                activityType: 'ADD_MUTUAL_ACTION_ITEM',
                organizationSlug,
                dealRoomId,
                metadata: {
                  dealRoomActionItemId: createTaskResponse.id,
                },
              });
            }
            // Refetch the milestones summary and mutual plan after creating a task
            // This makes sure the task is visible in all components that use the milestones and tasks data
            Promise.all([refetchMilestonesSummary(), refetchMutualPlan()]);
            return 'Successfully created the task';
          },
          error: 'Something went wrong creating the task',
        },
      );
    } catch (error) {}
  }, [
    dealRoomId,
    dueDate,
    getAccessTokenSilently,
    organizationSlug,
    ownerId,
    parentMilestone,
    recordActivity,
    refetchMilestonesSummary,
    refetchMutualPlan,
    title,
  ]);

  const handleUpdateTask = useCallback(
    async (dataToBeSent: Object) => {
      if (!Object.keys(dataToBeSent).length || !actionItem) return;

      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.updateActionItem(
            organizationSlug,
            dealRoomId,
            actionItem.id,
            dataToBeSent,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Updating task',
            success: (result) => {
              // Refetch the milestones summary and mutual plan after updating a task
              // This makes sure the task updates are visible in all components that use the milestones and tasks data
              Promise.all([
                refetchMilestonesSummary(),
                refetchMutualPlan(),
                refetchOrganizationActionItems(),
              ]);
              return 'Successfully updated the task';
            },
            error: 'Something went wrong updating the task',
          },
        );
      } catch (err) {}
    },
    [
      actionItem,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchMilestonesSummary,
      refetchMutualPlan,
      refetchOrganizationActionItems,
    ],
  );

  const handleOnBlurName = useCallback(async () => {
    if (!title) return;

    if (actionItem) {
      // don't call the function with the same value
      if (title === oldTitle.current) return;
      oldTitle.current = title;
      await handleUpdateTask({ actionItem: title });
    } else {
      oldTitle.current = title;
      await handleCreateTask();
    }
  }, [actionItem, handleCreateTask, handleUpdateTask, title]);

  const handleOnBlurDescription = useCallback(async () => {
    if (!actionItem) return;

    // don't call the function with the same value
    if (description === oldDescription.current) return;
    oldDescription.current = description;

    try {
      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.updateActionItemDescription(
          organizationSlug,
          dealRoomId,
          actionItem.id,
          { description },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Updating task',
          success: (result) => {
            // Refetch the mutual plan after updating a task
            // This makes sure the task updates are visible in all components that use the milestones and tasks data
            refetchMutualPlan().then();
            return 'Successfully updated the task';
          },
          error: 'Something went wrong updating the task',
        },
      );
    } catch (err) {}
  }, [
    actionItem,
    dealRoomId,
    description,
    getAccessTokenSilently,
    organizationSlug,
    refetchMutualPlan,
  ]);

  const handleUpdateDueDate = useCallback(
    (newValue: DateTime | null) => {
      const newDueDate = newValue ? newValue.toISO() : null;
      // don't update if the value hasn't changed
      if (newDueDate === oldDueDate.current) return;
      setDueDate(newDueDate);
      oldDueDate.current = newDueDate;
      if (actionItem) {
        handleUpdateTask({ dueDate: newDueDate });
      }
    },
    [actionItem, handleUpdateTask],
  );

  // Handles updating the owner of an action item
  // newContactId: The Contact ID (not User ID) of the new owner
  // The UI works with Contact IDs, but we need to convert to User IDs when calling the API
  const handleUpdateOwner = useCallback(
    async (newContactId: number | null) => {
      if (!actionItem?.id || !dealRoomId) {
        return;
      }

      const token = await getAccessTokenSilently();
      if (!token) {
        return;
      }

      // Convert Contact ID to User ID for the API
      // Consider both null and 0 as "Unassigned"
      const isUnassigned = newContactId === null || newContactId === 0;

      const newOwnerContact = !isUnassigned
        ? dealRoom?.contacts?.find((c) => c.id === newContactId)
        : undefined;

      // Use toast.promise for consistent UX with other updates
      await toast.promise(
        DealRoomsApiClient.updateActionItem(
          organizationSlug,
          dealRoomId,
          actionItem.id,
          {
            assigneeId: isUnassigned ? null : newOwnerContact?.userId || null,
            actionItem: actionItem.actionItem,
            dueDate: actionItem.dueDate,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
          .then((response) => {
            if (!response?.data) throw new Error('No response data');
            setActionItem(response.data);
            setOwnerId(newContactId); // UI state uses Contact ID
            oldOwnerId.current = newContactId || -1;
            return response;
          })
          .catch((error) => {
            throw error;
          }),
        {
          loading: 'Updating owner...',
          success: () => {
            Promise.all([
              refetchMilestonesSummary(),
              refetchMutualPlan(),
              refetchOrganizationActionItems(),
            ]);
            return 'Owner updated successfully';
          },
          error: 'Failed to update owner',
        },
      );
    },
    [
      actionItem?.id,
      actionItem?.actionItem,
      actionItem?.dueDate,
      dealRoomId,
      organizationSlug,
      dealRoom?.contacts,
      getAccessTokenSilently,
      refetchOrganizationActionItems,
      refetchMilestonesSummary,
      refetchMutualPlan,
    ],
  );

  const handleUpdateStatus = useCallback(
    async (newStatus: string | number | undefined) => {
      if (typeof newStatus !== 'string' || newStatus === oldStatus.current)
        return;
      setStatus(newStatus as DealRoomActionItemStatus);
      oldStatus.current = newStatus as DealRoomActionItemStatus;
      if (!actionItem) return;
      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.updateActionItemStatus(
            organizationSlug,
            dealRoomId,
            actionItem.id,
            { status: newStatus as DealRoomActionItemStatus },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Updating task',
            success: (result) => {
              // Refetch the milestones summary mutual plan after updating a task
              // This makes sure the task updates are visible in all components that use the milestones and tasks data
              Promise.all([
                refetchMilestonesSummary(),
                refetchMutualPlan(),
                refetchOrganizationActionItems(),
              ]);
              return 'Successfully updated the task';
            },
            error: 'Something went wrong updating the task',
          },
        );
      } catch (err) {}
    },
    [
      actionItem,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchMilestonesSummary,
      refetchMutualPlan,
      refetchOrganizationActionItems,
    ],
  );

  const handleUpdateTaskMilestone = useCallback(
    async (newMilestoneId: number | undefined) => {
      if (!newMilestoneId || newMilestoneId === oldMilestoneId.current) {
        return;
      }

      setSelectedMilestoneId(newMilestoneId);
      oldMilestoneId.current = newMilestoneId;

      if (!actionItem) {
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.updateActionItemMilestone(
            organizationSlug,
            dealRoomId,
            actionItem.id,
            { milestoneId: newMilestoneId },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Updating task',
            success: (result) => {
              // Refetch the milestones summary mutual plan after updating a task
              // This makes sure the task updates are visible in all components that use the milestones and tasks data
              Promise.all([
                refetchMilestonesSummary(),
                refetchMutualPlan(),
              ]).then((response) => {
                handleUpdateSelectedMilestone(newMilestoneId);
              });
              return 'Successfully updated the task';
            },
            error: 'Something went wrong updating the task',
          },
        );
      } catch (err) {}
    },
    [
      actionItem,
      dealRoomId,
      getAccessTokenSilently,
      handleUpdateSelectedMilestone,
      organizationSlug,
      refetchMilestonesSummary,
      refetchMutualPlan,
    ],
  );

  const handleClickDeleteArtifact = useCallback(
    async (artifactId: number) => {
      if (actionItem === null) return;
      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.deleteActionItemArtifact(
            organizationSlug,
            dealRoomId,
            actionItem.id,
            artifactId,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Deleting attachment',
            success: () => {
              // Refetch the mutual plan and task details after updating a task
              // This makes sure the task updates are visible in all components that use the milestones and tasks data
              Promise.all([
                refetchMutualPlan(),
                handleSetTaskInfo(actionItem?.id || null),
              ]);
              return 'Successfully deleted the attachment';
            },
            error: 'Something went wrong deleting the attachment',
          },
        );
      } catch (err) {}
    },
    [
      actionItem,
      dealRoomId,
      getAccessTokenSilently,
      handleSetTaskInfo,
      organizationSlug,
      refetchMutualPlan,
    ],
  );

  // The list of contacts for assigning tasks
  const dealRoomContactsOptions = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return [];

    return dealRoom.contacts.map((contact) => ({
      key: contact.id,
      text: contact.name || contact.email,
      hidden: !contact.userId,
    }));
  }, [dealRoom?.contacts]);

  // Use only visible milestone types for the dropdown

  const milestones = useMemo(() => {
    if (!mutualPlan) return [];
    return mutualPlan.milestones;
  }, [mutualPlan]);

  const milestoneOptions = useMemo(() => {
    if (!milestones) return [];
    return milestones
      .filter((milestone) => milestone.visible)
      .map((milestone) => ({
        key: milestone.id,
        text: milestone.title,
      }));
  }, [milestones]);

  const shouldShowFulFillAttachmentButton = useMemo(() => {
    // Determine if the "Fulfill Attachment" button should be shown
    // The button is shown when:
    // 1. The current user (mfUser) exists and has an assigned task (actionItem).
    // 2. The task has an assignee.
    // 3. The current user's email matches the assignee's email, and the task status is 'DOC_REQUESTED'.
    if (!mfUser || !actionItem) return false;

    if (!actionItem.assignee) return false;

    return (
      mfUser.email.toLowerCase() === actionItem.assignee.email.toLowerCase() &&
      actionItem.status === 'DOC_REQUESTED'
    );
  }, [actionItem, mfUser]);

  return (
    <DSResponsiveDrawer
      anchor="right"
      open={isOpen}
      onClose={onDismiss}
      widthPercentage={70}
      mobileWidthPercentage={90}
    >
      <Wrapper>
        <ActionItemPanelHeader
          actionItemId={actionItem?.id || null}
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          onClickCloseButton={handleDismiss}
          handleClickDeleteTask={handleClickDeleteTask}
        />
        <Content>
          <DSTextField
            value={title}
            label="Task name"
            multiline
            placeholder="Enter task name..."
            onChange={(e) => {
              setTitle(e.target.value || '');
            }}
            onBlur={handleOnBlurName}
            fullWidth
            sx={{
              backgroundColor: '#FFFFFF',
            }}
          />

          <FormControl fullWidth>
            <InputLabel id="owner-select-label" shrink={true}>
              Owner
            </InputLabel>
            <Select
              labelId="owner-select-label"
              id="owner-select"
              value={ownerId?.toString() || ''}
              label="Owner"
              disabled={!actionItem}
              onChange={async (event) => {
                const value = event.target.value;
                const selectedOption = dealRoomContactsOptions.find(
                  (opt) => opt.key.toString() === value,
                );
                await handleUpdateOwner(value ? +value : null);
              }}
              sx={dropdownMenuStyles}
              renderValue={(value) => {
                if (value === '') {
                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <DSContactAvatar
                        contact={{
                          id: 0,
                          email: 'unassigned@example.com',
                          name: 'Unassigned',
                        }}
                        size={24}
                      />
                      Unassigned
                    </Box>
                  );
                }
                const contact = dealRoom?.contacts.find(
                  (c) => c.id.toString() === value,
                );
                if (!contact) {
                  return 'Unknown';
                }
                return (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <DSContactAvatar contact={contact} size={24} />
                    {dealRoomContactsOptions.find(
                      (opt) => opt.key?.toString() === value,
                    )?.text || 'Unassigned'}
                  </Box>
                );
              }}
              displayEmpty
            >
              <DSMenuItem
                primaryText={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <DSContactAvatar
                      contact={{
                        id: 0,
                        email: 'unassigned@example.com',
                        name: 'Unassigned',
                      }}
                      size={24}
                    />
                    Unassigned
                  </Box>
                }
              />
              {dealRoom?.contacts.map((contact) => (
                <DSMenuItem
                  key={contact.id}
                  value={contact.id}
                  disabled={!contact.userId}
                  primaryText={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <DSContactAvatar contact={contact} size={24} />
                      {getContactDisplayName(contact)}
                    </Box>
                  }
                />
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <DSDatePicker
              label="Due Date"
              value={dueDate ? DateTime.fromISO(dueDate) : null}
              disabled={!actionItem}
              sx={{
                backgroundColor: '#FFFFFF',
              }}
              onChange={(newValue) => {
                // Only accept valid dates after year 2000
                if (
                  newValue?.isValid &&
                  newValue.year > 2000 &&
                  newValue.year < 2100
                ) {
                  handleUpdateDueDate(newValue);
                }
              }}
              minDate={DateTime.fromObject({ year: 2000, month: 1, day: 1 })}
              maxDate={DateTime.fromObject({ year: 2100, month: 12, day: 31 })}
              slotProps={{
                textField: {
                  inputProps: {
                    min: '2000-01-01',
                    max: '2100-12-31',
                    placeholder: 'MM/DD/YYYY',
                  },
                },
              }}
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={status}
              onChange={async (event) => {
                const value = event.target.value;
                await handleUpdateStatus(value);
              }}
              displayEmpty
              sx={{
                backgroundColor: getStatusBackgroundColor(status),
                color: getStatusTextColor(status),
              }}
              disabled={!actionItem}
              renderValue={(value) => {
                return (
                  <span>
                    {dealRoomActionItemStatusOptions.find(
                      (opt) => opt.key === value,
                    )?.text || 'Unknown'}
                  </span>
                );
              }}
            >
              {dealRoomActionItemStatusOptions.map((option) => (
                <MenuItem
                  key={option.key}
                  value={option.key}
                  disabled={option.disabled}
                >
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Milestone</InputLabel>
            <Select
              label="Milestone"
              value={selectedMilestoneId}
              onChange={async (event) => {
                const value = event.target.value;
                await handleUpdateTaskMilestone(value as number);
              }}
              displayEmpty
              sx={dropdownMenuStyles}
              disabled={!actionItem}
            >
              <MenuItem value="">
                <em>Select milestone</em>
              </MenuItem>
              {milestoneOptions.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <DSTextField
            value={description}
            label="Description"
            multiline
            minRows={3}
            placeholder="Enter task description..."
            disabled={!actionItem}
            onChange={(e) => {
              setDescription(e.target.value || '');
            }}
            onBlur={handleOnBlurDescription}
            fullWidth
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: '#FFFFFF',
              },
            }}
          />
          {actionItem &&
            Array.isArray(actionItem.artifacts) &&
            actionItem.artifacts.length > 0 && (
              <AttachmentsContainer>
                <AttachmentsContainerTitle>Artifacts</AttachmentsContainerTitle>
                <MilestoneArtifactsTable
                  artifacts={actionItem.artifacts}
                  organizationSlug={organizationSlug}
                  dealRoomId={dealRoomId}
                  handleClickDeleteArtifact={handleClickDeleteArtifact}
                />
              </AttachmentsContainer>
            )}
          <ActionItemPanelFooter
            showFooter={!!actionItem}
            shouldShowFulFillAttachmentButton={
              shouldShowFulFillAttachmentButton
            }
            handleClickFulfillRequestAttachment={
              handleClickFulfillRequestAttachment
            }
            handleAddAttachment={handleAddAttachment}
          />
        </Content>
        {/*render dialog containers used in the action item panel*/}
        {deleteTaskDialog}
        {addAttachmentDialog}
        {fulfillRequestAttachmentDialog}
      </Wrapper>
    </DSResponsiveDrawer>
  );
};
