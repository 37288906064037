import { 
  Box, 
  Typography, 
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { MutualPlanTemplate } from '@meetingflow/common/Api/data-contracts';
import { DSTooltip } from '../../../DS';

export const DSMutualPlanApplyTemplateCard = ({
    template,
    isSelected,
    onClick
  }: {
    template: MutualPlanTemplate;
    isSelected: boolean;
    onClick: () => void;
  }) => {
    return (
      <Grid item xs={12} sm={6} md={4} key={template.id}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            border: isSelected 
              ? `2px solid ${DEALROOMS_COLORS.themePrimary}` 
              : `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            opacity: isSelected ? 1 : 0.9,
            boxShadow: 0,
            cursor: 'pointer',
            '&:hover': {
              borderColor: isSelected 
                ? DEALROOMS_COLORS.themePrimary 
                : DEALROOMS_COLORS.neutralDark,
              opacity: 1
            }
          }}
          onClick={onClick}
        >
          <CardContent sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <Typography variant="h6" component="div" gutterBottom>
                {template.title}
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {template.description}
            </Typography>
            <Typography variant="subtitle2" gutterBottom sx={{ mt: 1 }}>
              Milestones ({template.milestones.length})
            </Typography>
            <Box sx={{ pl: 1 }}>
              {template.milestones.slice(0, 3).map((milestone) => (
                <Typography key={milestone.id} variant="body2" sx={{ py: 0.5 }}>
                  • {milestone.title}
                </Typography>
              ))}
              {template.milestones.length > 3 && (
                <DSTooltip 
                  title={
                    <Box>
                      {template.milestones.slice(3).map((milestone, idx) => (
                        <Typography key={idx} variant="body2">
                          {milestone.title}
                        </Typography>
                      ))}
                    </Box>
                  }
                >
                  <Typography variant="body2" color="text.secondary" sx={{ cursor: 'pointer' }}>
                    + {template.milestones.length - 3} more
                  </Typography>
                </DSTooltip>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  