import {
  styled,
  Box,
  Avatar,
  Breadcrumbs,
  Link,
  Typography,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

// Using MUI's getContrastText function from the theme

export const BreadcrumbsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const CompanyLogo = styled(Avatar)(() => ({
  width: 24,
  height: 24,
  marginRight: '.5rem',
  variant: 'rounded',
}));

export const StyledBreadcrumbs = styled(Breadcrumbs, {
  shouldForwardProp: (prop) => prop !== 'primaryColor',
})<{ primaryColor?: string }>(({ theme, primaryColor }) => ({
  marginLeft: 2,
  fontSize: '.875rem !important',
  whiteSpace: 'nowrap',
  display: 'flex',
  flexWrap: 'nowrap',

  '& *': {
    fontSize: '.875rem',
  },
  '& .MuiBreadcrumbs-separator': {
    color: primaryColor
      ? theme.palette.getContrastText(primaryColor)
      : 'inherit',
    opacity: 0.5,
  },
  '& .MuiBreadcrumbs-ol': {
    flexWrap: 'nowrap',
  },
  '& .MuiBreadcrumbs-li': {
    whiteSpace: 'nowrap',
  },
}));

export const LastBreadcrumbIconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'primaryColor',
})<{ primaryColor?: string }>(({ theme, primaryColor }) => ({
  marginRight: '.5rem',
  display: 'inline-flex',
  alignItems: 'center',
  verticalAlign: 'text-bottom',
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
    opacity: '66%',
    color: primaryColor
      ? theme.palette.getContrastText(primaryColor)
      : DEALROOMS_COLORS.cloudburst,
  },
}));

export const BreadcrumbLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'primaryColor',
})<{ primaryColor?: string }>(({ theme, primaryColor }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  fontSize: '.875rem !important',
  color: primaryColor
    ? theme.palette.getContrastText(primaryColor)
    : DEALROOMS_COLORS.cloudburst,
  '&:hover': {
    textDecoration: 'none',
    color: primaryColor
      ? theme.palette.getContrastText(primaryColor)
      : DEALROOMS_COLORS.woodsmoke,
    opacity: 0.8,
  },
}));

export const BreadcrumbIconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'primaryColor',
})<{ primaryColor?: string }>(({ theme, primaryColor }) => ({
  marginRight: '.5rem',
  display: 'flex',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
    opacity: '66%',
    color: primaryColor
      ? theme.palette.getContrastText(primaryColor)
      : DEALROOMS_COLORS.cloudburst,
  },
}));

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'primaryColor',
})<{ primaryColor?: string }>(({ theme, primaryColor }) => ({
  color: primaryColor ? theme.palette.getContrastText(primaryColor) : 'inherit',
  fontSize: '.875rem !important',
}));

export const NavbarContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const StyledNavbar = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'primaryColor' && prop !== 'isDecisionSitesOpen',
})<{ primaryColor?: string; isDecisionSitesOpen?: boolean }>(
  ({ theme, primaryColor, isDecisionSitesOpen }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: primaryColor || theme.palette.background.default,
    padding: '.25rem 2rem .25rem 2rem',
    paddingLeft: isDecisionSitesOpen ? '2rem' : '2.75rem',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '1rem',
    },
    justifyContent: 'space-between',
    gap: '1rem',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    containerType: 'inline-size',
    transition: 'padding-left 0.3s ease-in-out',
    height: '36px',

    '.navbar-title': {
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '24px',
      color: theme.palette.text.primary,
      paddingLeft: '1rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '100%',

      '@container (max-width: 600px)': {
        maxWidth: '200px',
      },
    },

    '.navbar-actions': {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',

      '.action-buttons': {
        display: 'flex',
        alignItems: 'center',
        gap: '.5rem',

        '@container (max-width: 600px)': {
          gap: '0.5rem',
        },
      },

      '.action-button': {
        padding: '0.5rem 1rem',
        transition: '.3s ease-in-out all',
        backgroundColor: `rgba(255,255,255, .1)`,

        '.ms-Button-label': {
          fontSize: '15px',
          lineHeight: '24px',
          fontWeight: '400',
        },

        i: {
          marginRight: '1rem',

          '@container (max-width: 600px)': {
            marginRight: '0',
          },
        },

        'i[data-icon-name*=Chevron]': {
          marginLeft: '1rem',
          marginRight: '0',
        },

        '@container (max-width: 600px)': {
          '.button-label': {
            display: 'none',
          },

          '.MuiButton-startIcon': {
            marginRight: '0',
          },

          '.MuiButton-endIcon': {
            display: 'none',
          },
        },
      },
    },
  }),
);

export const LogoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  '&:hover .edit-overlay': {
    opacity: 0.7,
    visibility: 'visible',
  },
}));

export const LogoEditOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '24px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  borderRadius: '.25rem',
  opacity: 0,
  visibility: 'hidden',
  cursor: 'pointer',
  transition: 'opacity 0.2s, visibility 0.2s',
}));

export const EditIconStyled = styled('div')(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: 'white',
    fontSize: 16,
  },
}));

interface StyledCompanyLogoProps {
  isSeller?: boolean;
}

export const StyledCompanyLogo = styled(CompanyLogo, {
  shouldForwardProp: (prop) => prop !== 'isSeller',
})<StyledCompanyLogoProps>(({ isSeller }) => ({
  cursor: isSeller ? 'pointer' : 'default',
  '& img': {
    objectFit: 'contain',
  },
}));
