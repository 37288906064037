import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationSlug } from './useOrganizationSlug';
import { useDealRoomId } from './useDealRoomId';
import { useQuery, useQueryClient } from 'react-query';
import { OrganizationKeyDetailsQuery } from '../QueryNames';
import { DealRoomsApiClient } from '../Services/NetworkCommon';
import { isForbiddenError } from '../Helpers/AxiosHelpers';
import { useCallback } from 'react';
import { DealRoomFact } from '@meetingflow/common/Api/data-contracts';

export const useKeyDetails = (orgSlug?: string, dealRoomId?: number) => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();

  const slg = useOrganizationSlug();
  const drId = useDealRoomId();
  const orgSlugFinal = orgSlug || slg;
  const dealRoomIdFinal = dealRoomId || drId;

  const {
    data: keyDetailsData,
    isLoading: keyDetailsLoading,
    isFetched: keyDetailsIsFetched,
    isError: keyDetailsIsError,
    error: keyDetailsError,
    refetch: refetchKeyDetails,
  } = useQuery(
    OrganizationKeyDetailsQuery(orgSlugFinal, dealRoomIdFinal),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listKeyFacts(
        orgSlugFinal!,
        dealRoomIdFinal!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!orgSlugFinal && !!dealRoomIdFinal && !isNaN(dealRoomIdFinal),
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );

  const refetchAll = useCallback(async () => {
    await client.invalidateQueries(
      OrganizationKeyDetailsQuery(orgSlugFinal, dealRoomIdFinal),
    );
  }, [client, dealRoomIdFinal, orgSlugFinal]);

  return {
    keyDetails: (keyDetailsData?.data as DealRoomFact[]) ?? [],
    isLoading: keyDetailsLoading,
    isFetched: keyDetailsIsFetched,
    isError: keyDetailsIsError,
    error: keyDetailsError,
    refetch: refetchKeyDetails,
    refetchAll,
  };
};