import {
  DoneAll,
  Clear,
  Archive,
  Restore,
  Markunread,
} from '@mui/icons-material';
import {
  FormControlLabel,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DSButton } from '../../DS';
import { DealRoomNotification } from '@meetingflow/common/Api/data-contracts';
import {
  DSInboxNotificationCheckbox,
  DSInboxNotificationsListToolbar,
  DSToolbarContainer,
} from './styles';

interface DSInboxToolbarProps {
  notifications: DealRoomNotification[];
  selectedNotifications: Set<string>;
  onSelectAllNotifications?: () => void;
  onDeselectAllNotifications?: () => void;
  onClearSelectedNotifications?: () => void;
  onDeleteSelectedNotifications?: () => void;
  onUndeleteSelectedNotifications?: () => void;
  onMarkSelectedNotificationsAsRead?: () => void;
  onMarkSelectedNotificationsAsUnread?: () => void;
}

export const DSInboxToolbar = ({
  notifications,
  selectedNotifications,
  onSelectAllNotifications,
  onDeselectAllNotifications,
  onClearSelectedNotifications,
  onDeleteSelectedNotifications,
  onUndeleteSelectedNotifications,
  onMarkSelectedNotificationsAsRead,
  onMarkSelectedNotificationsAsUnread,
}: DSInboxToolbarProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (notifications.length === 0) {
    return null;
  }

  return (
    <DSToolbarContainer>
      <DSInboxNotificationsListToolbar>
        <FormControlLabel
          control={
            <DSInboxNotificationCheckbox
              size="small"
              checked={selectedNotifications.size === notifications.length}
              indeterminate={
                selectedNotifications.size > 0 &&
                selectedNotifications.size < notifications.length
              }
              onChange={(e) => {
                e.stopPropagation();
                if (selectedNotifications.size === notifications.length) {
                  onDeselectAllNotifications?.();
                } else {
                  onSelectAllNotifications?.();
                }
              }}
            />
          }
          label={isMobile ? '' : 'Select All'}
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '0.875rem',
              color: (theme) => theme.palette.text.primary,
            },
          }}
        />
        <DSButton
          variant="text"
          size="small"
          startIcon={<Clear />}
          onClick={(e) => {
            e.stopPropagation();
            onClearSelectedNotifications?.();
          }}
          iconOnly={isMobile}
          disabled={selectedNotifications.size === 0}
        >
          Clear
        </DSButton>
        <Stack
          direction="row"
          sx={{
            marginLeft: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '.5rem',
          }}
        >
          {onMarkSelectedNotificationsAsRead && (
            <DSButton
              color="primary"
              variant="contained"
              size="small"
              startIcon={<DoneAll />}
              onClick={(e) => {
                e.stopPropagation();
                onMarkSelectedNotificationsAsRead?.();
              }}
              iconOnly={isMobile}
              disabled={selectedNotifications.size === 0}
            >
              Mark Read
            </DSButton>
          )}
          {onMarkSelectedNotificationsAsUnread && (
            <DSButton
              color="primary"
              variant="contained"
              size="small"
              startIcon={<Markunread />}
              onClick={(e) => {
                e.stopPropagation();
                onMarkSelectedNotificationsAsUnread?.();
              }}
              iconOnly={isMobile}
              disabled={selectedNotifications.size === 0}
            >
              Mark Unread
            </DSButton>
          )}
          <DSButton
            color="secondary"
            variant="outlined"
            size="small"
            startIcon={
              onUndeleteSelectedNotifications ? <Restore /> : <Archive />
            }
            onClick={(e) => {
              e.stopPropagation();
              if (onUndeleteSelectedNotifications) {
                onUndeleteSelectedNotifications();
              } else {
                onDeleteSelectedNotifications?.();
              }
            }}
            iconOnly={isMobile}
            disabled={selectedNotifications.size === 0}
          >
            {onUndeleteSelectedNotifications ? 'Recover' : 'Archive'}
          </DSButton>
        </Stack>
      </DSInboxNotificationsListToolbar>
    </DSToolbarContainer>
  );
};
