import { Box, styled, IconButton, Typography } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const HighlightsCarouselContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  pointerEvents: 'none', // Allow clicks to pass through to child elements
});

export const CarouselBoxContainer = styled(Box)<{ height?: string }>(
  ({ height = '250px' }) => ({
    height,
    maxWidth: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden !important',
    pointerEvents: 'none', // Allow clicks to pass through to child elements
  }),
);

export const CarouselCloneCard = styled(Box)({
  position: 'absolute',
  left: '50%',
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 5, // Lower z-index
  transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
  opacity: 0.7,
  pointerEvents: 'auto', // Enable pointer events on clone cards
  cursor: 'pointer', // Show pointer cursor to indicate clickability
});

export const CarouselItem = styled(Box)<{ isActive?: boolean }>(
  ({ isActive }) => ({
    position: 'absolute',
    left: '50%',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: isActive ? 20 : 5, // Active card has higher z-index
    transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
    opacity: isActive ? 1 : 0.7,
    pointerEvents: 'auto', // Enable pointer events on all cards
    cursor: isActive ? 'default' : 'pointer', // Show pointer cursor on non-active cards
  }),
);

export const TimestampTypography = styled(Typography)({
  marginTop: '8px',
  fontSize: '11px',
  position: 'absolute',
  right: '1rem',
  bottom: '-.25rem',
  zIndex: 20,
});

export const HighlightsStateContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
}));

export const HighlightsLoadingContainer = styled(HighlightsStateContainer)({
  flexDirection: 'column',
  '& > *:first-of-type': {
    marginBottom: 16,
  },
});

// New custom carousel components
export const CustomCarouselContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'visible',
  pointerEvents: 'none', // Allow clicks to pass through to child elements
});

export const CarouselTrack = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'visible',
  touchAction: 'pan-y', // Allow vertical scrolling on touch devices
  marginBottom: '1rem',
  pointerEvents: 'auto', // Make the track clickable
  cursor: 'pointer', // Show pointer cursor to indicate clickability
});

export const CarouselSlide = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ active, theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center', // Center content horizontally
  alignItems: 'center', // Center content vertically
  userSelect: 'none',
  transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
  opacity: active ? 1 : 0.5,
  transform: active ? 'scale(1)' : 'scale(0.85)',
  filter: active ? 'none' : 'blur(1px)',
  position: 'relative', // Add position relative for absolute positioning of overlay
  '& a, & button, & [role="button"], & input, & .clickable': {
    // Apply to all interactive elements
    pointerEvents: active ? 'auto' : 'none', // Only allow interactions on active slides
  },
  '&::after': !active
    ? {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999, // Ensure overlay is on top of all content
        pointerEvents: 'auto', // Ensure overlay captures all pointer events
        cursor: 'pointer', // Show pointer cursor to indicate clickability
      }
    : {},
}));

export const CarouselNavigation = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // marginTop: '8px',
  gap: '8px',
  zIndex: 30, // Higher z-index to ensure it's above other elements
  position: 'relative',
  pointerEvents: 'all', // Make sure navigation dots are clickable
  bottom: '.5rem',
});

export const CarouselDot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ active, theme }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: active ? DEALROOMS_COLORS.peacock : theme.palette.divider,
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  transform: active ? 'scale(1.2)' : 'scale(1)',
  '&:hover': {
    backgroundColor: active ? DEALROOMS_COLORS.peacock : theme.palette.divider,
    opacity: 0.8,
  },
}));

export const CarouselButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 2,
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 1px 2px rgba(0,0,0,0.08)',
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
  width: '24px',
  height: '24px',
  color: DEALROOMS_COLORS.peacock,
  opacity: 0.75,
}));

export const PrevButton = styled(CarouselButton)({
  left: '2px',
  '&:disabled': {
    opacity: 0.3,
    cursor: 'not-allowed',
  },
});

export const NextButton = styled(CarouselButton)({
  right: '2px',
  '&:disabled': {
    opacity: 0.3,
    cursor: 'not-allowed',
  },
});
