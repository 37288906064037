import { Company, Contact } from '@meetingflow/common/Api/data-contracts';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import { useDSActivity } from '../../Analytics/context/DSActivityContext';
import { useLocalStorageState } from '../../../../Hooks/useLocalStorageState';
import { useOrganization } from '../../../../Hooks/useOrganization';
import DSListAndDetailPanel, { Column } from '../../DS/DSListAndDetailPanel';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
  CircularProgress,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Checkbox,
  Collapse,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';

import { DSCompanyAvatarAndDetails } from './DSCompanyAvatarAndDetails';
import { DSCompanyDetail } from './DSCompanyDetail';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-hot-toast';
import { DSTooltip } from '../../DS';
import { useDecisionSiteContacts } from '../Contacts/hooks/useDecisionSiteContacts';
import {
  useDecisionSiteCompanies,
  SortKey,
} from './hooks/useDecisionSiteCompanies';
import { DSContactAvatarAndDetails } from '../Contacts/DSContactAvatarAndDetails';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import {
  ContactRowContainer,
  ContactDetailsContainer,
  BorderlessTableRow,
  CompanyIconCell,
  CompanyDetailsCell,
  ContactsCollapseCell,
  GroupHeaderContainer,
  GroupHeaderContent,
  SectionHeader,
  ControlsContainer,
  SearchField,
  InviteButton,
  StyledInfoIcon,
  StyledSmallInfoIcon,
} from './DSCompaniesListDetail.styles';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';

// These values intentionally set very high so that we get ALL companies and contacts
// TODO: Add endpoint on backend that returns companies we contacts and properly paginates
// so we don't have to do this aggregation on the frontend.
const CONTACTS_PER_PAGE = 1000;

// Type for company enriched with contacts
interface CompanyWithContacts extends Company {
  contacts: Contact[];
}

interface CompanyRowProps {
  company: CompanyWithContacts;
}

interface DSCompaniesListDetailContextType {
  selectedContactId: string | null;
  setSelectedContactId: (id: string | null) => void;
  showMembersOnly: boolean;
  setShowMembersOnly: (show: boolean) => void;
  showInternal: boolean;
  setShowInternal: (show: boolean) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  inviteDialogOpen: boolean;
  setInviteDialogOpen: (open: boolean) => void;
  contactToInvite: Contact | null;
  setContactToInvite: (contact: Contact | null) => void;
  inviteMessage: string;
  setInviteMessage: (message: string) => void;
  isInviting: boolean;
  setIsInviting: (inviting: boolean) => void;
}

const DSCompaniesListDetailContext =
  createContext<DSCompaniesListDetailContextType>({
    selectedContactId: null,
    setSelectedContactId: () => {},
    showMembersOnly: true,
    setShowMembersOnly: () => {},
    showInternal: true,
    setShowInternal: () => {},
    searchQuery: '',
    setSearchQuery: () => {},
    inviteDialogOpen: false,
    setInviteDialogOpen: () => {},
    contactToInvite: null,
    setContactToInvite: () => {},
    inviteMessage: '',
    setInviteMessage: () => {},
    isInviting: false,
    setIsInviting: () => {},
  });

const InviteDialog = () => {
  const {
    inviteDialogOpen,
    setInviteDialogOpen,
    contactToInvite,
    setContactToInvite,
    inviteMessage,
    setInviteMessage,
    isInviting,
    setIsInviting,
  } = useContext(DSCompaniesListDetailContext);

  const { getAccessTokenSilently } = useAuth0();
  const params = useParams<{ organizationSlug: string; dealRoomId: string }>();
  const organizationSlug = params.organizationSlug;
  const dealRoomId = params.dealRoomId;
  const { refetch } = useDealRoom();

  const handleClose = () => {
    setInviteDialogOpen(false);
    setContactToInvite(null);
    setInviteMessage('');
  };

  const handleInvite = async () => {
    if (!contactToInvite || !organizationSlug || !dealRoomId) return;

    setIsInviting(true);
    try {
      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.addContactToDealRoom(
          organizationSlug,
          parseInt(dealRoomId, 10),
          contactToInvite.email,
          {
            role: 'BUYER', // Default to BUYER role
            message: inviteMessage,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: `Inviting ${contactToInvite.name || contactToInvite.email}...`,
          success: `Successfully invited ${contactToInvite.name || contactToInvite.email}`,
          error: `Failed to invite ${contactToInvite.name || contactToInvite.email}`,
        },
      );

      // Refetch dealRoom data to update the UI
      await refetch();

      // Close the dialog and reset state
      handleClose();
    } catch (error) {
      console.error('Error inviting contact:', error);
    } finally {
      setIsInviting(false);
    }
  };

  return (
    <Dialog
      open={inviteDialogOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Invite Contact</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Are you sure you want to invite {contactToInvite?.name} to this
          Decision Site?
        </Typography>
        <TextField
          label="Optional Message"
          multiline
          rows={4}
          fullWidth
          value={inviteMessage}
          onChange={(e) => setInviteMessage(e.target.value)}
          placeholder="Add a personal message to your invitation"
          variant="outlined"
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isInviting}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isInviting}
          onClick={handleInvite}
        >
          {isInviting ? 'Sending...' : 'Send Invite'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CompanyRow = ({ company }: CompanyRowProps) => {
  const [open, setOpen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const dealRoomId = useDealRoomId();
  const { dealRoom } = useDealRoom();
  const organizationSlug = useOrganizationSlug();
  const {
    setSelectedContactId,
    showMembersOnly,
    searchQuery,
    setContactToInvite,
    setInviteDialogOpen,
  } = useContext(DSCompaniesListDetailContext);

  // Access activity data from context
  const { activitiesData } = useDSActivity();

  // Filter contacts based on search query
  const filterContact = (contact: Contact) => {
    if (!searchQuery) return true;
    const query = searchQuery.toLowerCase();
    return (
      contact.name?.toLowerCase().includes(query) ||
      contact.email?.toLowerCase().includes(query)
    );
  };

  const allMembers = company.contacts.filter((c) => c.userId);
  const allContacts = company.contacts.filter((c) => !c.userId);

  // Apply search filter
  const members = allMembers.filter(filterContact);
  const contacts = allContacts.filter(filterContact);

  const handleExpandClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const navigate = useNavigate();

  return (
    <>
      <BorderlessTableRow>
        <CompanyIconCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleExpandClick}
            sx={{
              padding: '2px',
              '& svg': { fontSize: '1.25rem' },
            }}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </CompanyIconCell>
        <CompanyDetailsCell>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <DSCompanyAvatarAndDetails
              company={company}
              count={{
                members: members.length,
                contacts: contacts.length,
              }}
              size="default"
              avatarSize={24}
              showMembersOnly={showMembersOnly}
              searchTerm={searchQuery}
              showSocialIcons={false}
            />
          </Box>
        </CompanyDetailsCell>
      </BorderlessTableRow>
      <BorderlessTableRow>
        <ContactsCollapseCell colSpan={6} $showMembersOnly={showMembersOnly}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              {(() => {
                // Use the filtered contacts based on search query
                const members = allMembers.filter(filterContact);
                const contacts = allContacts.filter(filterContact);

                return (
                  <>
                    {/* Members Section */}
                    {members.length > 0 && (
                      <>
                        {/* Add Members header when toggle is false */}
                        {!showMembersOnly && (
                          <GroupHeaderContainer>
                            <GroupHeaderContent>
                              <SectionHeader
                                variant="body2"
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop: '.5rem',
                                }}
                              >
                                Members
                                <DSTooltip title="Members are users who have joined the Decision Site and can access shared content.">
                                  <StyledInfoIcon />
                                </DSTooltip>
                              </SectionHeader>
                            </GroupHeaderContent>
                          </GroupHeaderContainer>
                        )}
                        {/* Members are always visible */}
                        {members.map((contact, index) => (
                          <ContactRowContainer
                            key={contact.id}
                            $isLastMember={index === members.length - 1}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedContactId(contact.id.toString());
                              setSearchParams((prev) => {
                                prev.set('contact', contact.id.toString());
                                return prev;
                              });
                            }}
                          >
                            <ContactDetailsContainer>
                              <DSContactAvatarAndDetails
                                contact={contact}
                                size="small"
                                titleOverride={contact.title || undefined}
                                hideEmailAddress
                              />
                            </ContactDetailsContainer>
                            {/* Add Activity button for members */}
                            <InviteButton
                              className="activity-button"
                              size="small"
                              onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                // Set the search params to filter the activity table by this user
                                // Ensure userId is not null before using it
                                const userId = contact.userId;
                                if (userId) {
                                  // Instead of creating a new URLSearchParams object, use navigate
                                  const analyticsTabParams =
                                    new URLSearchParams();
                                  analyticsTabParams.set(
                                    'userFilter',
                                    userId.toString(),
                                  );

                                  // Navigate to the analytics tab
                                  navigate(
                                    `/organization/${organizationSlug}/decisionsite/${dealRoomId}/hub?${analyticsTabParams.toString()}`,
                                  );
                                }
                              }}
                            >
                              Activity
                            </InviteButton>
                          </ContactRowContainer>
                        ))}
                      </>
                    )}

                    {/* Contacts Section - Only shown when showMembersOnly is false */}
                    {!showMembersOnly && contacts.length > 0 && (
                      <>
                        <GroupHeaderContainer>
                          <GroupHeaderContent>
                            <SectionHeader
                              variant="body2"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '.5rem',
                              }}
                            >
                              Contributors
                              <DSTooltip title="Contributors are meeting contacts who have not yet joined the Decision Site. You can invite them to become members.">
                                <StyledInfoIcon />
                              </DSTooltip>
                            </SectionHeader>
                          </GroupHeaderContent>
                        </GroupHeaderContainer>
                        {contacts.map((contact, index) => (
                          <ContactRowContainer
                            key={contact.id}
                            $isLastMember={index === contacts.length - 1}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedContactId(contact.id.toString());
                              setSearchParams((prev) => {
                                prev.set('contact', contact.id.toString());
                                return prev;
                              });
                            }}
                          >
                            <ContactDetailsContainer>
                              <DSContactAvatarAndDetails
                                contact={contact}
                                size="small"
                                titleOverride={contact.title || undefined}
                                hideEmailAddress
                              />
                            </ContactDetailsContainer>
                            {/* For non-members, show the Invite or Pending button */}
                            <InviteButton
                              className="invite-button"
                              size="small"
                              disabled={dealRoom?.contacts?.some(
                                (c) =>
                                  c.email.toLowerCase() ===
                                    contact.email.toLowerCase() && !c.userId,
                              )}
                              onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                // Check if this contact is pending (invited but not a member)
                                const isPending = dealRoom?.contacts?.some(
                                  (c) =>
                                    c.email.toLowerCase() ===
                                      contact.email.toLowerCase() && !c.userId,
                                );
                                if (isPending) return;
                                setContactToInvite(contact);
                                setInviteDialogOpen(true);
                              }}
                              sx={{
                                backgroundColor: dealRoom?.contacts?.some(
                                  (c) =>
                                    c.email.toLowerCase() ===
                                      contact.email.toLowerCase() && !c.userId,
                                )
                                  ? DEALROOMS_COLORS.cloudburst
                                  : undefined,
                                '&.Mui-disabled': {
                                  color: '#fff',
                                  cursor: 'not-allowed !important',
                                },
                                '&:hover': {
                                  backgroundColor: dealRoom?.contacts?.some(
                                    (c) =>
                                      c.email.toLowerCase() ===
                                        contact.email.toLowerCase() &&
                                      !c.userId,
                                  )
                                    ? DEALROOMS_COLORS.cloudburst
                                    : undefined,
                                },
                              }}
                            >
                              {dealRoom?.contacts?.some(
                                (c) =>
                                  c.email.toLowerCase() ===
                                    contact.email.toLowerCase() && !c.userId,
                              )
                                ? 'Pending'
                                : 'Invite'}
                            </InviteButton>
                          </ContactRowContainer>
                        ))}
                      </>
                    )}
                  </>
                );
              })()}
            </Box>
          </Collapse>
        </ContactsCollapseCell>
      </BorderlessTableRow>
    </>
  );
};

const columns: Column<CompanyWithContacts, SortKey>[] = [
  {
    id: 'name',
    label: '',
    getValue: (company) => <CompanyRow company={company} />,
    sortKey: 'name',
    style: { width: '100%', padding: '.5rem' },
  },
];

export const DSCompaniesListDetail = () => {
  const { slug: organizationSlug } = useOrganization();
  const dealRoomId = useDealRoomId();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedContactId, setSelectedContactId] = useState<string | null>(
    searchParams.get('contact'),
  );
  const [showMembersOnly, setShowMembersOnly] = useLocalStorageState<boolean>(
    'dealroom-show-members-only',
    true,
  );
  const [showInternal, setShowInternal] = useLocalStorageState<boolean>(
    'dealroom-show-internal',
    false,
  );
  const [searchQuery, setSearchQuery] = useState<string>('');

  // State for the filter menu
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const isFilterMenuOpen = Boolean(filterAnchorEl);

  // Handle opening the filter menu
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  // Handle closing the filter menu
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  // State for invite dialog
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [contactToInvite, setContactToInvite] = useState<Contact | null>(null);
  const [inviteMessage, setInviteMessage] = useState('');
  const [isInviting, setIsInviting] = useState(false);

  const { contacts: contactsData, isLoading: contactsIsLoading } =
    useDecisionSiteContacts({
      organizationSlug: organizationSlug!,
      sortBy: 'name',
      sortOrder: 'asc',
      searchString: '',
      decisionSiteIds: dealRoomId ? [dealRoomId] : undefined,
      perPage: CONTACTS_PER_PAGE,
    });

  const list = useMemo(() => {
    return <DSCompaniesListDetailInner contacts={contactsData} />;
  }, [contactsData]);

  if (contactsIsLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  // This function was moved to DSCompaniesListDetailInner

  return (
    <DSCompaniesListDetailContext.Provider
      value={{
        selectedContactId,
        setSelectedContactId,
        showMembersOnly,
        setShowMembersOnly,
        showInternal,
        setShowInternal,
        searchQuery,
        setSearchQuery,
        inviteDialogOpen,
        setInviteDialogOpen,
        contactToInvite,
        setContactToInvite,
        inviteMessage,
        setInviteMessage,
        isInviting,
        setIsInviting,
      }}
    >
      {/* Controls bar with toggle and search */}
      <ControlsContainer>
        {/* Search field */}
        <SearchField
          placeholder="Search contacts..."
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            },
          }}
        />

        {/* Filter Button */}
        <IconButton
          onClick={handleFilterClick}
          size="small"
          color="secondary"
          aria-label="filter contacts"
          aria-controls={isFilterMenuOpen ? 'filter-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isFilterMenuOpen ? 'true' : undefined}
        >
          <FilterListIcon />
        </IconButton>

        {/* Filter Menu */}
        <Menu
          id="filter-menu"
          anchorEl={filterAnchorEl}
          open={isFilterMenuOpen}
          onClose={handleFilterClose}
          MenuListProps={{
            'aria-labelledby': 'filter-button',
            dense: true,
          }}
        >
          <MenuItem
            onClick={() => {
              setShowMembersOnly(!showMembersOnly);
              handleFilterClose();
            }}
          >
            <Checkbox checked={showMembersOnly} size="small" color="primary" />
            <Typography variant="body2" sx={{ ml: 1 }}>
              Members Only
            </Typography>
            <DSTooltip
              title="Only show people who are members of this Decision Site (excludes Contributors)."
              placement="right"
              arrow
            >
              <IconButton size="small" sx={{ ml: 1 }}>
                <StyledSmallInfoIcon />
              </IconButton>
            </DSTooltip>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowInternal(!showInternal, true); // Pass true to emit storage event
              handleFilterClose();
            }}
          >
            <Checkbox checked={showInternal} size="small" color="primary" />
            <Typography variant="body2" sx={{ ml: 1 }}>
              Show Internal
            </Typography>
            <DSTooltip
              title="When enabled, shows all people. When disabled, only shows external people."
              placement="right"
              arrow
            >
              <IconButton size="small" sx={{ ml: 1 }}>
                <StyledSmallInfoIcon />
              </IconButton>
            </DSTooltip>
          </MenuItem>
        </Menu>
      </ControlsContainer>
      {list}
    </DSCompaniesListDetailContext.Provider>
  );
};

const DSCompaniesListDetailInner = ({ contacts }: { contacts: Contact[] }) => {
  const { slug: organizationSlug, internalDomains } = useOrganization();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(
    null,
  );
  const { searchQuery, showInternal } = useContext(
    DSCompaniesListDetailContext,
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  // Calculate dynamic height for mobile based on window height
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Access activity data from context
  const { activitiesData, isActivitiesLoading } = useDSActivity();

  // Extract unique domains from contacts
  const contactDomains = useMemo(() => {
    if (!contacts.length) return [];
    const domains = contacts.map((contact) => contact.emailDomain);
    return [...new Set(domains)]; // Remove duplicates
  }, [contacts]);

  // Use the existing hook for companies with default sorting and domain filtering
  const {
    companies: rawCompanies,
    isLoading: isLoadingCompanies,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useDecisionSiteCompanies({
    organizationSlug: organizationSlug!,
    sortBy: 'name',
    sortOrder: 'asc',
    searchString: '',
    domains: contactDomains,
  });

  // Enrich companies with their associated contacts and filter out companies without contacts
  const companies = useMemo(() => {
    if (contacts.length === 0 || !rawCompanies?.length) {
      return [];
    }

    return (
      rawCompanies
        .map((company) => {
          const relatedContacts = contacts.filter((contact) =>
            company.domains.some((d) => d.domain === contact.emailDomain),
          );

          return {
            ...company,
            contacts: relatedContacts,
          };
        })
        // Only include companies that have at least one related contact
        .filter(
          (company) => company.contacts.length > 0,
        ) as CompanyWithContacts[]
    );
  }, [rawCompanies, contacts]);

  // Filter companies based on search query and showInternal toggle
  const filteredCompanies = useMemo(() => {
    // First, filter companies based on the showInternal toggle
    // When showInternal is false, we need to filter out companies that ONLY have internal contacts
    const companiesWithFilteredContacts = companies
      .map((company) => {
        if (showInternal) {
          // If showInternal is true, keep all contacts
          return company;
        } else {
          // If showInternal is false, filter out internal contacts
          const externalContacts = company.contacts.filter((contact) => {
            return (
              !contact.emailDomain ||
              !internalDomains.includes(contact.emailDomain)
            );
          });

          return {
            ...company,
            contacts: externalContacts,
          };
        }
      })
      .filter((company) => company.contacts.length > 0); // Remove companies with no contacts after filtering

    // Then apply search query filter if present
    if (!searchQuery) {
      return companiesWithFilteredContacts;
    }

    return companiesWithFilteredContacts.filter((company) => {
      return company.contacts.some((contact) => {
        const query = searchQuery.toLowerCase();
        return (
          contact.name?.toLowerCase().includes(query) ||
          contact.email?.toLowerCase().includes(query)
        );
      });
    });
  }, [companies, searchQuery, showInternal, internalDomains]);

  // Effect to handle URL parameters
  useEffect(() => {
    const companyId = searchParams.get('company');
    if (companyId && companyId !== selectedCompanyId) {
      setSelectedCompanyId(companyId);
    }
  }, [searchParams, selectedCompanyId]);

  if (isLoadingCompanies) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        p={4}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <DSListAndDetailPanel<CompanyWithContacts, string, SortKey>
        objectList={filteredCompanies}
        selectedObjectId={selectedCompanyId}
        onSelectObject={(id) => setSelectedCompanyId(id)}
        onCloseObjectPanel={() => setSelectedCompanyId(null)}
        objectTypeKey={'company'}
        renderTableHeader={false}
        isLoading={isLoadingCompanies}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        onLoadMore={fetchNextPage}
        renderDetail={(company) => <DSCompanyDetail companyId={company.id} />}
        getItemId={(company) => company.id.toString()}
        columns={columns}
        tableMaxHeightOffset={isMobile ? windowHeight - 220 : 260}
        noDataMessage="No matching people found"
      />

      {/* Invite Confirmation Dialog */}
      <InviteDialog />
    </>
  );
};

export default DSCompaniesListDetail;
