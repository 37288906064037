import { useInfiniteQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { CompaniesApiClient } from '../../../../../Services/NetworkCommon';
import { OrganizationCompaniesInfinite } from '../../../../../QueryNames';
import { Company } from '@meetingflow/common/Api/data-contracts';

const DEFAULT_COMPANIES_PER_PAGE = 25;
const myFilter = false;
const includeInternalFilter = true;

export type SortKey =
  | 'name'
  | 'createdAt'
  | 'timesMet30d'
  | 'timesMet90d'
  | 'lastMeetingDate'
  | 'nextMeetingDate';

interface UseDecisionSiteCompaniesProps {
  organizationSlug: string;
  sortBy: SortKey;
  sortOrder: 'asc' | 'desc';
  searchString: string;
  domains?: string[];
  perPage?: number;
}

export const useDecisionSiteCompanies = ({
  organizationSlug,
  sortBy,
  sortOrder,
  searchString,
  domains,
  perPage = DEFAULT_COMPANIES_PER_PAGE,
}: UseDecisionSiteCompaniesProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data: companiesData,
    isLoading,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    [
      OrganizationCompaniesInfinite(organizationSlug, true),
      sortBy,
      sortOrder,
      searchString,
      domains,
    ],
    async ({ pageParam }) => {
      const token = await getAccessTokenSilently();
      return CompaniesApiClient.listCompanies(
        {
          organizationSlug,
          limit: perPage,
          skip: pageParam || 0,
          q: searchString !== '' ? searchString : undefined,
          domains: domains?.length ? domains : undefined,
          my: myFilter,
          hasPlans: true,
          isInternal: includeInternalFilter ? undefined : false,
          sortBy,
          sortOrder,
          nulls: 'last',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug,
      getPreviousPageParam: (previousPage) => {
        if (
          !previousPage?.config?.params?.skip ||
          previousPage.config.params.skip < perPage
        ) {
          return undefined;
        }
        return previousPage.config.params.skip - perPage;
      },
      getNextPageParam: (previousPage) => {
        if (!previousPage?.data?.length || previousPage.data.length < perPage) {
          return undefined;
        }
        return previousPage?.config?.params?.skip
          ? previousPage.config.params.skip + perPage
          : perPage;
      },
    },
  );

  const companies = companiesData?.pages.flatMap((page) => page.data) ?? [];

  return {
    companies,
    isLoading,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  };
};
