import React, { useEffect, useState, useRef, useCallback } from 'react';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { useLocation, matchPath } from 'react-router-dom';
import { useBreadcrumb } from '../../../Context/BreadcrumbContext';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import InboxIcon from '@mui/icons-material/Inbox';
import TimelineIcon from '@mui/icons-material/Timeline';
import AssignmentIcon from '@mui/icons-material/Assignment';

/**
 * BreadcrumbManager is responsible for setting breadcrumbs based on the current route
 * This centralizes breadcrumb management instead of having each component manage its own breadcrumbs
 */
// Helper function to debounce function calls
function debounce<T extends unknown[]>(func: (...args: T) => void, wait: number) {
  let timeout: NodeJS.Timeout | null = null;
  return function (...args: T) {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

export const BreadcrumbManager: React.FC = () => {
  const location = useLocation();
  const { setBreadcrumbs, breadcrumbs } = useBreadcrumb();
  const { dealRoom, dealRoomId } = useDealRoom();
  const organizationSlug = useOrganizationSlug();
  const lastPathRef = useRef<string>('');
  const isUpdatingRef = useRef<boolean>(false);

  // Create a stable debounced version of setBreadcrumbs
  const debouncedSetBreadcrumbs = useCallback(
    (newBreadcrumbs: typeof breadcrumbs) => {
      if (!isUpdatingRef.current) {
        isUpdatingRef.current = true;
        setBreadcrumbs(newBreadcrumbs);
        setTimeout(() => {
          isUpdatingRef.current = false;
        }, 200);
      }
    },
    [setBreadcrumbs],
  );
  
  // Apply debounce to the callback
  const debouncedSetBreadcrumbsWithDelay = useRef(
    debounce<[typeof breadcrumbs]>(debouncedSetBreadcrumbs, 50)
  ).current;

  useEffect(() => {
    if (!dealRoom || !dealRoomId || !organizationSlug) return;

    // Check if we're on a detail page (artifact, meeting, or task) by looking for query parameters
    const searchParams = new URLSearchParams(location.search);
    const hasDetailParams =
      searchParams.has('artifact') ||
      searchParams.has('meeting') ||
      searchParams.has('task');

    // If we're on a detail page and the breadcrumbs length is 3 (base + section + detail),
    // don't override the breadcrumbs as they were likely set by a dynamic component
    if (hasDetailParams && breadcrumbs.length === 3) {
      // Skip breadcrumb update for detail page when breadcrumbs are already set
      return;
    }

    // If the path hasn't changed, don't update breadcrumbs
    // This prevents conflicts with dynamic breadcrumbs
    if (lastPathRef.current === location.pathname && hasDetailParams) {
      return;
    }

    lastPathRef.current = location.pathname;
    // Current pathname and search parameters are used to determine breadcrumbs
    const path = `/organization/${organizationSlug}/decisionsite/${dealRoomId}`;
    // The root breadcrumb doesn't have an icon because it uses the buyer logo instead
    const baseBreadcrumb = {
      label: dealRoom.name || 'Decision Site',
      path: `${path}/overview`,
      // No icon property here - the first segment uses the buyer logo
    };

    // Query parameters were already extracted above, reuse them
    const artifactId = searchParams.get('artifact');
    const taskId = searchParams.get('task');
    const tab = searchParams.get('tab');

    // Get artifact name from location state if available
    const artifactName = location.state?.artifactName || 'Artifact';

    // Match routes and set appropriate breadcrumbs
    if (matchPath(`${path}/overview`, location.pathname)) {
      debouncedSetBreadcrumbsWithDelay([
        baseBreadcrumb,
        {
          label: 'Overview',
          icon: <DashboardIcon fontSize="small" />,
        },
      ]);
    } else if (matchPath(`${path}/artifacts`, location.pathname)) {
      // For artifacts, we only show the artifact breadcrumb if the panel is open
      // The panel component will handle adding/removing the artifact breadcrumb
      debouncedSetBreadcrumbsWithDelay([
        baseBreadcrumb,
        {
          label: 'Artifacts',
          path: `${path}/artifacts`,
          icon: <FolderIcon fontSize="small" />,
        },
      ]);
    } else if (matchPath(`${path}/journey`, location.pathname)) {
      debouncedSetBreadcrumbsWithDelay([
        baseBreadcrumb,
        {
          label: 'Journey',
          path: `${path}/journey`,
          icon: <TimelineIcon fontSize="small" />,
        },
      ]);
    } else if (matchPath(`${path}/contacts`, location.pathname)) {
      debouncedSetBreadcrumbsWithDelay([
        baseBreadcrumb,
        {
          label: 'Contacts',
          path: `${path}/contacts`,
          icon: <PeopleIcon fontSize="small" />,
        },
      ]);
    } else if (matchPath(`${path}/companies`, location.pathname)) {
      debouncedSetBreadcrumbsWithDelay([
        baseBreadcrumb,
        {
          label: 'Companies',
          path: `${path}/companies`,
          icon: <BusinessIcon fontSize="small" />,
        },
      ]);
    } else if (matchPath(`${path}/inbox`, location.pathname)) {
      debouncedSetBreadcrumbsWithDelay([
        baseBreadcrumb,
        {
          label: 'Inbox',
          path: `${path}/inbox`,
          icon: <InboxIcon fontSize="small" />,
        },
      ]);
    } else if (matchPath(`${path}/settings`, location.pathname)) {
      debouncedSetBreadcrumbsWithDelay([
        baseBreadcrumb,
        {
          label: 'Settings',
          path: `${path}/settings`,
          icon: <SettingsIcon fontSize="small" />,
        },
      ]);
    } else if (matchPath(`${path}/mutual-action-plan`, location.pathname)) {
      // Check if we're on a task detail page
      if (taskId) {
        // If we're on a task detail page and breadcrumbs are already set by a dynamic component, don't override
        if (breadcrumbs.length === 3) {
          return;
        }

        // Get task name from location state if available
        const taskName = location.state?.taskName || `Task ${taskId}`;

        debouncedSetBreadcrumbsWithDelay([
          baseBreadcrumb,
          {
            label: 'Mutual Action Plan',
            path: `${path}/mutual-action-plan`,
            icon: <AssignmentIcon fontSize="small" />,
          },
          {
            label: taskName,
            path: `${path}/mutual-action-plan?task=${taskId}`,
            icon: <AssignmentIcon fontSize="small" />,
          },
        ]);
      } else {
        debouncedSetBreadcrumbsWithDelay([
          baseBreadcrumb,
          {
            label: 'Mutual Action Plan',
            path: `${path}/mutual-action-plan`,
            icon: <AssignmentIcon fontSize="small" />,
          },
        ]);
      }
    } else if (matchPath(`${path}/hub`, location.pathname)) {
      debouncedSetBreadcrumbsWithDelay([
        baseBreadcrumb,
        {
          label: 'Hub',
          path: `${path}/hub`,
          icon: <AnalyticsIcon fontSize="small" />,
        },
      ]);
    } else {
      // Default case - just show the deal room name
      debouncedSetBreadcrumbsWithDelay([baseBreadcrumb]);
    }
  }, [
    location.pathname,
    location.search,
    dealRoom,
    dealRoomId,
    organizationSlug,
    setBreadcrumbs,
    location.state,
    breadcrumbs,
    debouncedSetBreadcrumbsWithDelay,
  ]);

  return null; // This is a utility component that doesn't render anything
};
