import {
  styled,
  Box,
  TextField,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DSButton } from '../DS';
import ReactMarkdown from 'react-markdown';

export const CalloutCardsGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
  gridAutoFlow: 'dense',
  gridAutoRows: 'auto',
  gap: '1.5rem',
  width: '100%',
  '@container (max-width: 600px)': {
    gridTemplateColumns: 'none',
  },
}));

export const CalloutCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '0',
  borderRadius: '.25rem',
  padding: '1rem',
  backgroundColor: DEALROOMS_COLORS.white,
  border: `1px solid ${DEALROOMS_COLORS.financialLightGray}`,
  position: 'relative',
  height: 'calc(100% - 2rem - 2px)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    border: `1px solid ${DEALROOMS_COLORS.woodsmoke}`,
  },
  '&:hover .action-buttons': {
    opacity: 1,
  },
  '&[data-draggable="true"]': {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
}));

export const CalloutCardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '1rem',
  marginBottom: '0',
  width: 'calc(100% - 3rem)',
}));

export const CalloutCardTitle = styled(Typography)(({ theme }) => ({
  color: DEALROOMS_COLORS.themePrimary,
  lineHeight: '1rem',
  marginRight: '2rem',
}));

interface EditButtonProps {
  isActive: boolean;
}

export const EditButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<EditButtonProps>(({ theme, isActive }) => ({
  padding: '2px',
  opacity: isActive ? 1 : 0.6,
  color: isActive
    ? DEALROOMS_COLORS.themePrimary
    : DEALROOMS_COLORS.themeSecondary,
  backgroundColor: isActive ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
  '&:hover': {
    opacity: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  width: '24px',
  height: '24px',
}));

export const EditContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  opacity: 0,
  transition: 'opacity 0.2s ease-in-out',
  gap: '0.25rem',
  className: 'action-buttons',
  zIndex: 10,
}));

export const DeleteButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<EditButtonProps>(({ theme, isActive }) => ({
  padding: '2px',
  opacity: isActive ? 1 : 0.6,
  color: '#d32f2f',
  backgroundColor: isActive ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
  '&:hover': {
    opacity: 1,
    backgroundColor: 'rgba(211, 47, 47, 0.04)',
  },
  width: '24px',
  height: '24px',
}));

export const StyledInput = styled('input', {
  shouldForwardProp: (prop) => prop !== 'isEditing',
})<{ isEditing: boolean }>(({ theme, isEditing }) => ({
  border: 'none',
  outline: 'none',
  background: isEditing ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
  fontSize: '0.875rem',
  fontWeight: 500,
  color: DEALROOMS_COLORS.themePrimary,
  padding: isEditing ? '0 8px' : '0',
  margin: '0',
  width: '100%',
  position: 'relative',
  left: '-.5rem',
  fontFamily: 'inherit',
  borderRadius: '4px',
}));

export const TextAreaField = styled('textarea', {
  shouldForwardProp: (prop) => prop !== 'isLongText' && prop !== 'isEditing',
})<{ isLongText: boolean; isEditing: boolean }>(
  ({ theme, isLongText, isEditing }) => ({
    border: 'none',
    outline: 'none',
    background: isEditing ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
    fontSize: isLongText ? '.875rem' : '1.5rem',
    lineHeight: isLongText ? '1.25rem' : '1rem',
    color: DEALROOMS_COLORS.themePrimary,
    padding: isEditing ? '0 8px' : '0',
    margin: '0',
    position: 'relative',
    left: '-.5rem',
    width: '100%',
    fontFamily: 'inherit',
    resize: 'none',
    overflow: 'auto',
    minHeight: '2.5rem',
    height: 'auto',
    borderRadius: '4px',
  }),
);

export const CalloutCardValue = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isLongText',
})<{ isLongText: boolean }>(({ isLongText, theme }) => ({
  fontSize: isLongText ? '.875rem' : '1.5rem',
  lineHeight: isLongText ? '1.25rem' : '1rem',
  paddingTop: isLongText ? '0' : '.5rem',
  color: DEALROOMS_COLORS.themePrimary,
}));

export const CalloutCardMarkdown = styled(ReactMarkdown)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${DEALROOMS_COLORS.themePrimary};
  width: 100%;
  margin: 0;
  padding: 0;

  p {
    margin: 0;
    padding: 0;
  }

  a {
    color: ${DEALROOMS_COLORS.themePrimary};
    text-decoration: underline;
  }

  ul,
  ol {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
  }

  code {
    background-color: rgba(0, 0, 0, 0.04);
    padding: 0.2rem 0.4rem;
    border-radius: 3px;
    font-family: monospace;
  }

  blockquote {
    margin: 0.5rem 0;
    padding-left: 1rem;
    border-left: 3px solid ${DEALROOMS_COLORS.financialLightGray};
    color: ${DEALROOMS_COLORS.themeSecondary};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0.5rem 0 0.25rem 0;
    font-weight: 500;
  }

  h1 {
    font-size: 1.25rem;
  }
  h2 {
    font-size: 1.1rem;
  }
  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0.5rem 0;
  }

  th,
  td {
    border: 1px solid ${DEALROOMS_COLORS.financialLightGray};
    padding: 0.25rem 0.5rem;
    text-align: left;
  }

  th {
    background-color: rgba(0, 0, 0, 0.02);
  }
`;

export const ExampleCalloutCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '0',
  borderRadius: '.25rem',
  padding: '1rem',
  backgroundColor: DEALROOMS_COLORS.white,
  border: `2px dashed ${DEALROOMS_COLORS.themeSecondary}`,
  position: 'relative',
  height: 'calc(100% - 2rem - 4px)', // Adjusted for thicker border
  opacity: 0.4,
  transition: 'opacity 0.3s ease-out',
}));

export const AddCalloutCardButton = styled(DSButton)({
  display: 'inline-flex',
  minWidth: 'unset',
  marginLeft: '8px',
  padding: '0',
  position: 'relative',
  top: '-1px',
  color: `${DEALROOMS_COLORS.cloudburst}99`, // 60% opacity (99 in hex is ~60%)
  transition: 'all 0.3s ease-in-out',
  '& .MuiButton-startIcon': {
    marginRight: 0,
  },
  '& .MuiButton-startIcon > *:nth-of-type(1)': {
    fontSize: 16,
  },
  '& .MuiButton-endIcon': {
    display: 'none',
  },
  '&:hover': {
    color: `${DEALROOMS_COLORS.cloudburst}`,
    backgroundColor: 'transparent',
  },
});

// CSS for the callout cards grid
export const calloutCardsGridStyle = `
.callout-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1.5rem;
  width: 100%;
}

@media (max-width: 600px) {
  .callout-cards-grid {
    grid-template-columns: 1fr;
  }
}
`;
