import { useMemo } from 'react';
import { DSMenuButton } from '../DS';
import DSMenuItem from './DSMenuItem';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { Mail, Phone, Event, AccountCircle } from '@mui/icons-material';
import { Avatar, CircularProgress } from '@mui/material';

const CONTACT_METHODS = {
  EMAIL: 'email',
  PHONE: 'phone',
  CALENDAR: 'calendar',
} as const;

interface DSSellerContactButtonProps {
  branded?: boolean;
  avatarOnly?: boolean;
}

const DSSellerContactButton = ({ branded, avatarOnly }: DSSellerContactButtonProps) => {
  const { dealRoom, isLoading } = useDealRoom();
  const dealRoomOwner =
    dealRoom?.owner?.firstName || dealRoom?.owner?.name || 'Seller';
  const dealRoomOwnerEmail = dealRoom?.owner?.email || '';
  const dealRoomOwnerPhoneNumber = dealRoom?.owner?.phoneNumber || '';
  const dealRoomOwnerSchedulingUrl = dealRoom?.owner?.schedulingUrl || '';

  const sanitizeUrl = useMemo(() => {
    const fn = (url: string): string => {
      return url.startsWith('mailto:') ||
        url.startsWith('tel:') ||
        url.startsWith('https://') ||
        url.startsWith('http://')
        ? url
        : '';
    };
    return fn;
  }, []);

  const openUrl = useMemo(() => {
    return (url: string): void => {
      const sanitizedUrl = sanitizeUrl(url);
      if (sanitizedUrl) {
        window.open(sanitizedUrl, '_blank');
      }
    };
  }, [sanitizeUrl]);

  const contactMenuItems = useMemo(() => {
    const items = [];

    if (dealRoomOwnerEmail) {
      items.push(
        <DSMenuItem
          key={CONTACT_METHODS.EMAIL}
          onClick={() => {
            openUrl(`mailto:${dealRoomOwnerEmail}`);
          }}
          primaryText={`Email ${dealRoomOwner}`}
          secondaryText={dealRoomOwnerEmail}
          icon={<Mail />}
          branded
        />,
      );
    }

    if (dealRoomOwnerPhoneNumber) {
      items.push(
        <DSMenuItem
          key={CONTACT_METHODS.PHONE}
          onClick={() => {
            openUrl(`tel:${dealRoomOwnerPhoneNumber}`);
          }}
          primaryText={`Call ${dealRoomOwner}`}
          secondaryText={dealRoomOwnerPhoneNumber}
          icon={<Phone />}
          branded
        />,
      );
    }

    if (dealRoomOwnerSchedulingUrl) {
      items.push(
        <DSMenuItem
          key={CONTACT_METHODS.CALENDAR}
          onClick={() => {
            openUrl(dealRoomOwnerSchedulingUrl);
          }}
          primaryText="Schedule a meeting"
          icon={<Event />}
          branded
        />,
      );
    }

    return items;
  }, [
    dealRoomOwner,
    dealRoomOwnerEmail,
    dealRoomOwnerPhoneNumber,
    dealRoomOwnerSchedulingUrl,
    openUrl,
  ]);

  // Always calculate the avatar memo regardless of loading state or menu items
  // to maintain consistent hook call order
  const ownerAvatar = useMemo(() => {
    const avatarUrl =
      dealRoom?.owner?.avatarFileUrl || dealRoom?.owner?.avatarUrl || undefined;
    return avatarUrl ? (
      <Avatar
        src={avatarUrl}
        sx={{ width: 22, height: 22 }}
        alt={dealRoomOwner}
      />
    ) : (
      <AccountCircle />
    );
  }, [
    dealRoom?.owner?.avatarFileUrl,
    dealRoom?.owner?.avatarUrl,
    dealRoomOwner,
  ]);

  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  if (contactMenuItems.length === 0) {
    return null;
  }

  return (
    <DSMenuButton
      label={avatarOnly ? '' : `Contact ${dealRoomOwner}`}
      startIcon={ownerAvatar}
      menuItems={contactMenuItems}
      branded={branded}
      iconOnly={avatarOnly}
      className="action-button contact-button"
      size="medium"
      sx={{
        '&&': {
          minHeight: '28px',
          maxHeight: '28px',
          height: '28px',
          ...(avatarOnly && {
            padding: '4px',
            minWidth: '28px',
            width: '28px',
          }),
        },
      }}
    />
  );
};

export default DSSellerContactButton;
