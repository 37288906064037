import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  DealRoomArtifact,
  DetailedDealRoom,
} from '@meetingflow/common/Api/data-contracts';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { isBadRequest, isForbiddenError } from '../../../Helpers/AxiosHelpers';
import { useNavigate } from '../../../Hooks/useNavigate';
import { useTitle } from '../../../Hooks/useTitle';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { AudioArtifactCard } from '../Artifacts/AudioArtifactCard';
import { DocumentArtifactCard } from '../Artifacts/DocumentArtifactCard';
import { FileArtifactCard } from '../Artifacts/FileArtifactCard';
import { ImageArtifactCard } from '../Artifacts/ImageArtifactCard';
import { LinkArtifactCard } from '../Artifacts/LinkArtifactCard';
import { MeetingflowArtifactCard } from '../Artifacts/MeetingflowArtifactCard';
import { VideoArtifactCard } from '../Artifacts/VideoArtifactCard';
import { ExampleArtifactCard } from '../Artifacts/ExampleArtifactCard';
import useDeviceType from '../../../Hooks/deviceDetection';
import { getArtifactLink } from '../utils/artifactUtils';
import { useDealRoomActivity } from '../Analytics/hooks/useDealRoomActivity';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import {
  Container,
  NoArtifacts,
  ExampleArtifactsContainer,
  ArtifactScroll,
} from './DSFeaturedArtifacts.styles';

type DealRoomArtifactsProps = {
  className?: string;
  artifactContainerClassName?: string;
  organizationSlug: string;
  dealRoomId: number;
  activity?: DetailedDealRoom['activity'];
  artifacts?: DetailedDealRoom['artifacts'];
  refreshDealRoom: () => Promise<unknown>;
  onClick?: (id: DealRoomArtifact['id']) => unknown;
  onDelete?: (id: DealRoomArtifact['id']) => Promise<unknown>;
};

export const DSFeaturedArtifacts = ({
  className,
  artifactContainerClassName,
  organizationSlug,
  dealRoomId,
  activity,
  artifacts,
  onClick,
  onDelete,
}: DealRoomArtifactsProps) => {
  const { isMobile } = useDeviceType();

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { recordActivity } = useDealRoomActivity();
  const { dealRoom, dealRoomRole } = useDealRoom();
  const isSeller = useMemo(() => dealRoomRole === 'SELLER', [dealRoomRole]);

  useTitle('Artifacts');

  const {
    data: dealRoomArtifacts,
    isLoading: artifactsLoading,
    refetch: refetchArtifacts,
  } = useQuery(
    OrganizationDealRoomArtifactsQuery(
      organizationSlug,
      dealRoomId,
      true, // featured
      false, // includeDeleted
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listArtifacts(
        {
          organizationSlug,
          dealRoomId,
          featured: true,
          includeDeleted: false,
          sortBy: 'featuredAt',
          sortOrder: 'desc',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !artifacts && !!organizationSlug && !!dealRoomId,
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        if (isBadRequest(error)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

  useEffect(() => {
    refetchArtifacts();
  }, [refetchArtifacts]);

  const drArtifacts = useMemo(() => {
    if (artifacts) {
      return artifacts
        .filter((a) => a.featuredAt)
        .sort((a, b) => {
          if (a.placement === 'WELCOME' && b.placement !== 'WELCOME') return -1;
          if (b.placement === 'WELCOME' && a.placement !== 'WELCOME') return 1;
          return (
            new Date(b.featuredAt || 0).getTime() -
            new Date(a.featuredAt || 0).getTime()
          );
        });
    }

    return dealRoomArtifacts?.data || [];
  }, [artifacts, dealRoomArtifacts?.data]);

  const refetch = useCallback(async () => {
    await Promise.all([refetchArtifacts()]);
  }, [refetchArtifacts]);

  const handleOnClick = useCallback(
    (id: DealRoomArtifact['id']) => {
      if (onClick) {
        onClick(id);
      }

      const artifact = drArtifacts.find((a) => a.id === id);

      if (!artifact) {
        return;
      }

      recordActivity({
        activityType: 'DS_OVERVIEW_CLICK_FEATURED_ARTIFACT',
        organizationSlug,
        dealRoomId,
        metadata: {
          artifactId: id,
          artifactName: artifact.name,
          artifactType: artifact.type,
          decisionSiteId: dealRoomId,
          decisionSiteName: dealRoom?.name,
        },
      });

      navigate(getArtifactLink(organizationSlug, dealRoomId, artifact), {
        preserveQuery: true,
      });
    },
    [
      dealRoomId,
      dealRoom?.name,
      navigate,
      onClick,
      organizationSlug,
      recordActivity,
      drArtifacts,
    ],
  );

  const handleOnDelete = useCallback(
    async (id: DealRoomArtifact['id']) => {
      if (onDelete) {
        await onDelete(id);
      }
    },
    [onDelete],
  );

  const hasArtifacts = drArtifacts?.length > 0;
  const showExampleArtifact = isSeller && !hasArtifacts && !artifactsLoading;

  return (
    <Container id="dealroom-artifacts" className={className}>
      {hasArtifacts ? (
        <ArtifactScroll className={artifactContainerClassName}>
          {drArtifacts.map((a) => {
            const viewCount =
              DeduplicateArray(
                activity
                  ?.filter(
                    (activity) =>
                      activity.type === 'VIEW_ARTIFACT' &&
                      activity.additionalDetails.artifactId === a.id,
                  )
                  .map((a) => a.userId) ?? [],
              ).length ?? 0;
            switch (a.type) {
              case 'MEETINGFLOW':
                return (
                  <MeetingflowArtifactCard
                    key={a.id}
                    artifact={a}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'AUDIO':
                return (
                  <AudioArtifactCard
                    key={a.id}
                    artifact={a}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'DOCUMENT':
                return (
                  <DocumentArtifactCard
                    key={a.id}
                    artifact={a}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'FILE':
                return (
                  <FileArtifactCard
                    key={a.id}
                    artifact={a}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'IMAGE':
                return (
                  <ImageArtifactCard
                    key={a.id}
                    artifact={a}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'LINK':
                return (
                  <LinkArtifactCard
                    key={a.id}
                    artifact={a}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'VIDEO':
                return (
                  <VideoArtifactCard
                    key={a.id}
                    artifact={a}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );
            }
          })}
        </ArtifactScroll>
      ) : (
        <NoArtifacts
          justifyContent={showExampleArtifact ? 'space-around' : 'center'}
        >
          {artifactsLoading ? (
            <CircularProgress />
          ) : showExampleArtifact ? (
            <ExampleArtifactsContainer>
              <ExampleArtifactCard
                orgSlug={organizationSlug}
                dealRoomId={dealRoomId}
                type="DOCUMENT"
                label="Featured Document (Example)"
                name="Featured Document"
                description="Add Artifacts to your Decision Site and feature them to display them here."
              />
              <ExampleArtifactCard
                orgSlug={organizationSlug}
                dealRoomId={dealRoomId}
                type="VIDEO"
                label="Featured Video (Example)"
                name="Featured Video"
                description="Add Artifacts to your Decision Site and feature them to display them here."
              />
              <ExampleArtifactCard
                orgSlug={organizationSlug}
                dealRoomId={dealRoomId}
                type="LINK"
                label="Featured Link (Example)"
                name="Featured Link"
                description="Add Artifacts to your Decision Site and feature them to display them here."
              />
            </ExampleArtifactsContainer>
          ) : (
            <Typography>No Featured Artifacts</Typography>
          )}
        </NoArtifacts>
      )}
    </Container>
  );
};
