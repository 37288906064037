import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';

type HandleOnWelcomeProps = {
  organizationSlug: string | undefined;
  dealRoomId: number | undefined;
  refetchArtifacts: () => Promise<unknown>;
};

export const useHandleOnWelcome = ({
  organizationSlug,
  dealRoomId,
  refetchArtifacts,
}: HandleOnWelcomeProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const handleOnWelcome = useCallback(
    async (welcome: boolean, artifactId: number) => {
      try {
        if (
          !organizationSlug ||
          !dealRoomId ||
          !artifactId ||
          !getAccessTokenSilently
        ) {
          return;
        }

        const token = await getAccessTokenSilently();

        if (welcome) {
          await toast.promise(
            DealRoomsApiClient.makeWelcomeArtifact(
              organizationSlug,
              dealRoomId,
              artifactId,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
            {
              loading: 'Adding to welcome section...',
              success: 'Added to welcome section',
              error: 'Failed to add to welcome section',
            },
          );
        } else {
          await toast.promise(
            DealRoomsApiClient.unwelcomeArtifact(
              organizationSlug,
              dealRoomId,
              artifactId,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
            ),
            {
              loading: 'Removing from welcome section...',
              success: 'Removed from welcome section',
              error: 'Failed to remove from welcome section',
            },
          );
        }
        await refetchArtifacts();
      } catch (err: unknown) {
        console.error('Error handling welcome artifact:', err);
      }
    },
    [organizationSlug, dealRoomId, getAccessTokenSilently, refetchArtifacts],
  );
  return { handleOnWelcome };
};
