import { useState } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { toast } from 'react-hot-toast';
import { MutualPlanTemplate } from '@meetingflow/common/Api/data-contracts';
import { DSButton } from '../../../DS';
import { DSMutualPlanApplyTemplateDialog } from './DSMutualPlanApplyTemplateDialog';
// Button component to open the template dialog
export const DSMutualPlanApplyTemplateButton = ({
    organizationSlug,
    dealRoomId,
    refetchMilestones,
    refetchMutualPlan
  }: {
    organizationSlug: string;
    dealRoomId: number;
    refetchMilestones: () => void;
    refetchMutualPlan: () => void;
  }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleApplyTemplate = async (template: MutualPlanTemplate) => {
      try {
        const token = await getAccessTokenSilently();
  
        await toast.promise(
          DealRoomsApiClient.saveMilestones(
            organizationSlug,
            dealRoomId,
            template.milestones.map((milestone, index) => ({
              title: milestone.title,
              dueDate: new Date(Date.now() + index * 1000).toISOString(),
              visible: true
            })),
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Applying template...',
            success: () => {
              refetchMilestones();
              refetchMutualPlan();
              
              return 'Template applied successfully';
            },
            error: 'Failed to apply template',
          },
        );
        
        // Refetch data
        refetchMilestones();
        refetchMutualPlan();
      } catch (error) {
        console.error('Error applying template:', error);
      }
    };
  
    return (
      <>
        {/* Button to open apply template dialog */}
        <DSButton
          onClick={handleOpen}
          startIcon={<AddIcon />}
          size="small"
          color="secondary"
        >
          Add from Template
        </DSButton>
        
        <DSMutualPlanApplyTemplateDialog
          open={open}
          onClose={handleClose}
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          onApplyTemplate={handleApplyTemplate}
        />
      </>
    );
  };
  