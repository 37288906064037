import { ReactNode, useState } from 'react';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import DSDetail from './DSDetail';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { toast } from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { RequestQuote } from '@mui/icons-material';

interface DSDetailCostEstimateProps {
  editable?: boolean;
}

export const DSDetailCostEstimate = ({
  editable = true,
}: DSDetailCostEstimateProps) => {
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { dealRoom, refetch: refetchDealRoom } = useDealRoom();
  const { getAccessTokenSilently } = useAuth0();

  // Format currency for display
  const formatCurrency = (amount: number | null | undefined) => {
    if (amount === null || amount === undefined) return 'N/A';
    // Don't include the currency symbol as DSDetail will add it
    return amount.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  // Determine if we should use currency type or text type
  const getDetailType = (amount: number | null | undefined) => {
    return amount === null || amount === undefined ? 'text' : 'currency';
  };

  // State to track pending updates for optimistic UI
  const [pendingValue, setPendingValue] = useState<number | null | undefined>(undefined);

  // Handle cost estimate update
  const handleCostEstimateUpdate = async (newValue: string) => {
    if (!organizationSlug || !dealRoomId) return;

    try {
      const token = await getAccessTokenSilently();
      // Parse currency value by removing all non-numeric characters except decimal point
      const numericValue = newValue
        ? parseFloat(newValue.replace(/[^0-9.]/g, ''))
        : null;
      
      // Set pending value for optimistic UI update
      setPendingValue(numericValue);

      await toast.promise(
        DealRoomsApiClient.updateDealRoom(
          organizationSlug,
          dealRoomId,
          { costEstimate: numericValue },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
        {
          loading: 'Updating cost estimate...',
          success: 'Cost estimate updated successfully',
          error: 'Failed to update cost estimate',
        },
      );

      await refetchDealRoom();
      // Clear pending value after successful update
      setPendingValue(undefined);
    } catch (error) {
      console.error('Error updating cost estimate:', error);
      // Reset pending value on error
      setPendingValue(undefined);
    }
  };

  // Return null if not editable and costEstimate is not set
  if (
    !editable &&
    (dealRoom?.costEstimate === null || dealRoom?.costEstimate === undefined)
  ) {
    return null;
  }

  // Use pending value if available, otherwise use the value from dealRoom
  const displayValue = pendingValue !== undefined ? pendingValue : dealRoom?.costEstimate;

  return (
    <DSDetail
      label="Cost Estimate"
      value={formatCurrency(displayValue)}
      icon={
        <RequestQuote
          sx={{ fontSize: '20px', color: DEALROOMS_COLORS.cloudburst }}
        />
      }
      editable={editable}
      type={getDetailType(displayValue)}
      onSave={handleCostEstimateUpdate}
    />
  );
};
