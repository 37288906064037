import { useAuth0 } from '@auth0/auth0-react';
import { styled } from '@mui/material/styles';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { createMeetingflow } from '../../../../utils/DecisionSiteMeetingflowHelpers';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { CalendarEvent } from '@meetingflow/common/Api/data-contracts';
import { ActionButton } from './DSMeetingsCalendarEventRow.styles';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-hot-toast';
import { useMeetingflow } from '../../Components/Journey/hooks/useMeetingflow';

const RecordIcon = styled('span')({
    display: 'inline-block',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: DEALROOMS_COLORS.white,
    marginRight: '6px',
});

interface Props {
    event: CalendarEvent;
}

export const DSMeetingRecordButton = ({ event }: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const { organization } = useOrganization();
    const appInsights = useAppInsightsContext();
    const queryClient = useQueryClient();
    const [isLoading, setIsLoading] = useState(false);
    const { meetingflow, refetchMeetingflow } = useMeetingflow(event?.meetingplanId);

    // Check if the meeting is in the future or currently happening
    const isMeetingActive = useMemo(() => {
        if (!event) return false;
        return DateTime.fromISO(event.endTime).diffNow().milliseconds > 0;
    }, [event]);

    // Check if the meeting has conference info
    const hasConferenceInfo = useMemo(() => {
        return !!event?.conferenceInfo?.joinUri;
    }, [event]);

    const handleRecordClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        
        if (isLoading) return;
        if (!organization || !organization.slug) return;
        
        setIsLoading(true);
        
        try {
            const token = await getAccessTokenSilently();
            await createMeetingflow({
                event,
                organizationSlug: organization.slug,
                scheduleCallRecording: true,
                token,
                appInsights,
                queryClient,
            });
            refetchMeetingflow();
            setIsLoading(false);
        } catch (error) {
            console.error('Error scheduling meetingflow:', error);
            toast.error('Error scheduling meeting recording');
            setIsLoading(false);
        }
    };

    if (!isMeetingActive || !hasConferenceInfo) {
        return null;
    }

    if (event.meetingplanId && meetingflow?.callRecording?.lastStatus !== 'deleted') {
        return null;
    }

    return (
        <ActionButton 
            hoverBackgroundColor={DEALROOMS_COLORS.themePrimary}
            backgroundColor={DEALROOMS_COLORS.crimson} 
            color={DEALROOMS_COLORS.white}
            onClick={handleRecordClick}
            disabled={isLoading}
        >
            {isLoading ? (
                <>
                    <CircularProgress size={16} color="inherit" />
                    Setting up...
                </>
            ) : (
                <>
                    <RecordIcon />
                    Record Meeting
                </>
            )}
        </ActionButton>
    );
};