import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { SxProps, Typography } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import {
  InfoSectionContainer,
  InfoSectionHeader,
  InfoSectionContent,
} from './InfoSection.styles';
import { DSSectionTitle } from '../../DS/DSSectionTitle';

interface InfoSectionProps {
  icon?: ReactElement;
  title?: string | ReactNode;
  direction?: 'row' | 'column';
  gap?: string | number;
  sx?: SxProps;
  contentSx?: SxProps;
}

export const InfoSection = ({
  icon,
  title = '',
  direction = 'column',
  gap = 0,
  children,
  sx,
  contentSx,
}: PropsWithChildren<InfoSectionProps>) => {
  return (
    <InfoSectionContainer sx={sx}>
      <InfoSectionHeader>
        <DSSectionTitle icon={icon} title={title} />
      </InfoSectionHeader>
      <InfoSectionContent direction={direction} gap={gap} sx={contentSx}>
        {children}
      </InfoSectionContent>
    </InfoSectionContainer>
  );
};
