import { Box, Typography, styled, keyframes } from '@mui/material';
import { ResponsiveContainer } from 'recharts';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const PersonalityContainer = styled(Box)({
  display: 'flex',
  gap: '1rem',
});

export const AssessmentColumn = styled(Box)({
  flex: 1,
  width: 'calc(100% - 2rem)',
  padding: '0 1rem',
});

export const ChartContainer = styled(Box)({
  width: '100%',
  height: 200,
});

export const TraitLegend = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '1rem',
});

export const TraitRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: 'calc(100% - 1rem)',
  padding: '0.1rem 0.5rem',
});

export const TraitLabel = styled(Typography)<{ color?: string }>(
  ({ color }) => ({
    color: color || DEALROOMS_COLORS.cloudburst,
    fontWeight: '400',
    flex: 1,
    fontSize: '.875rem',
  }),
);

export const TraitValue = styled(Typography)({
  width: '48px',
  textAlign: 'right',
  fontFamily: `"MatterSQ Mono", sans-serif !important`,
  color: DEALROOMS_COLORS.cloudburst,
  fontSize: '.875rem',
});

const rotateChart = keyframes`
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

export const StyledResponsiveContainer = styled(ResponsiveContainer)({
  backgroundColor: DEALROOMS_COLORS.financialLighterGray,
  margin: '0',
  maxWidth: '100%',
  borderRadius: '.5rem',
  '& .recharts-wrapper': {
    animation: `${rotateChart} 0.6s ease-out forwards`,
  },
});

// Shared chart configurations
export const chartAxisStyle = {
  tick: {
    fill: DEALROOMS_COLORS.cloudburst,
    fontSize: 16,
    fontWeight: '400',
  },
};

export const radarChartStyle = {
  stroke: DEALROOMS_COLORS.cloudburst,
  fill: DEALROOMS_COLORS.financialLightGray,
  fillOpacity: 0.8,
};

export const dotStyle = {
  r: 6,
  strokeWidth: 2,
};

export const AdjectiveChipsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '.25rem',
  minHeight: '24px',
  paddingBottom: '8px',
  marginTop: '1rem',
});

export const AdjectiveChip = {
  backgroundColor: DEALROOMS_COLORS.financialLighterGray,
  color: DEALROOMS_COLORS.cloudburst,
  fontSize: '12px',
  height: '24px',
  marginBottom: '.25rem',
  '& .MuiChip-label': {
    fontWeight: 400,
    fontSize: '12px',
  },
};
