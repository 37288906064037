import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { ContentWrapper } from './DSCompanyDetail.styles';

interface DSOrgChartLoadingErrorProps {
  errorMessage?: string;
}

export const DSOrgChartLoadingError: React.FC<DSOrgChartLoadingErrorProps> = ({
  errorMessage,
}) => {
  return (
    <ContentWrapper>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            textAlign: 'center',
            maxWidth: 450,
            p: 4,
            borderRadius: 2,
            backgroundColor: 'rgba(158, 158, 158, 0.08)',
            border: '1px solid rgba(158, 158, 158, 0.2)',
          }}
        >
          <SentimentDissatisfiedIcon
            sx={{
              fontSize: 48,
              color: 'primary.main',
              mb: 2,
              opacity: 0.8,
            }}
          />
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 500,
              color: 'primary.main',
              mb: 1.5,
            }}
          >
            Error
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              lineHeight: 1.6,
              mb: 1,
            }}
          >
            We're having trouble loading the organization chart.
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              lineHeight: 1.5,
              fontStyle: 'italic',
            }}
          >
            {errorMessage ||
              'This may be a temporary issue. Please try again in a moment.'}
          </Typography>
        </Paper>
      </Box>
    </ContentWrapper>
  );
};
