import React, { useState, useCallback, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
  FormHelperText,
} from '@mui/material';
import { CalloutCard } from './DSCalloutCards';
import toast from 'react-hot-toast';
import {
  CalloutCard as CalloutCardStyled,
  CalloutCardTitle,
  CalloutCardValue,
} from './DSCalloutCards.styles';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { ensureValidProtocol, isUrl } from '../../../Helpers/URLHelpers';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { Save, Cancel, CheckCircle } from '@mui/icons-material';
import { DSButton } from '../DS';

interface DSCalloutCardCreateModeProps {
  organizationSlug: string | undefined;
  dealRoomId: number | undefined;
  card: CalloutCard;
  onSave: (
    card: CalloutCard,
    title: string,
    description: string,
    ctaLabel: string,
    link: string | null,
    artifactId: number | null,
  ) => void;
  onCancel: () => void;
  artifacts: DealRoomArtifact[];
}

export const DSCalloutCardCreateMode = ({
  organizationSlug,
  dealRoomId,
  card,
  onSave,
  onCancel,
  artifacts,
}: DSCalloutCardCreateModeProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [title, setTitle] = useState(card.title || '');
  const [description, setDescription] = useState(card.description || '');
  const [ctaLabel, setCtaLabel] = useState(card.ctaLabel || '');
  const [link, setLink] = useState(card.link || '');
  const [artifactId, setArtifactId] = useState<number | null>(
    card.artifactId || null,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Check if either link or artifact is selected
  const isValid = useMemo(() => {
    return Boolean(title.trim()) && Boolean(description.trim()) && (Boolean(link.trim()) || artifactId !== null);
  }, [title, description, link, artifactId]);

  const handleSave = useCallback(async () => {
    if (!title.trim()) {
      toast.error('Title is required');
      return;
    }

    if (!description.trim()) {
      toast.error('Description is required');
      return;
    }

    if (!link.trim() && artifactId === null) {
      toast.error('Either a link or an artifact must be selected');
      return;
    }

    // Validate link if provided
    if (link && !isUrl(link)) {
      toast.error('Please enter a valid URL');
      return;
    }

    setIsSubmitting(true);

    try {
      const token = await getAccessTokenSilently();

      // Check if organizationSlug and dealRoomId are defined
      if (!organizationSlug || dealRoomId === undefined) {
        console.error('Organization slug or dealRoomId is undefined');
        return;
      }

      // Prepare link value with proper protocol
      const formattedLink = link ? ensureValidProtocol(link) : null;

      // Call onSave
      onSave(
        card,
        title,
        description,
        ctaLabel,
        formattedLink,
        artifactId,
      );
    } catch (error) {
      console.error('Error creating callout card:', error);
      toast.error('Failed to create card');
    } finally {
      setIsSubmitting(false);
    }
  }, [
    title,
    description,
    ctaLabel,
    link,
    artifactId,
    getAccessTokenSilently,
    organizationSlug,
    dealRoomId,
    card,
    onSave,
  ]);

  return (
    <CalloutCardStyled>
      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="dense"
        required
        disabled={isSubmitting}
        size="small"
        autoFocus
      />

      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        margin="dense"
        multiline
        rows={3}
        disabled={isSubmitting}
        size="small"
        required
      />

      <TextField
        label="Button Label"
        value={ctaLabel}
        onChange={(e) => setCtaLabel(e.target.value)}
        fullWidth
        margin="dense"
        disabled={isSubmitting}
        size="small"
        helperText="Leave empty for no button"
      />

      {/* Conditional rendering for link or artifact selection */}
      {artifacts.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 1 }}>
          <FormControl fullWidth size="small" sx={{ mb: 1 }}>
            <InputLabel id="artifact-select-label">Artifact</InputLabel>
            <Select
              labelId="artifact-select-label"
              value={artifactId || ''}
              onChange={(e) => {
                const value = e.target.value;
                setArtifactId(value === '' ? null : Number(value));
                if (value !== '') {
                  setLink(''); // Clear link if artifact is selected
                }
              }}
              label="Artifact"
              disabled={isSubmitting || Boolean(link)}
              required={!link}
            >
              <MenuItem value="">None</MenuItem>
              {artifacts.map((artifact) => (
                <MenuItem key={artifact.id} value={artifact.id}>
                  {artifact.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {!artifactId && !link ? "An artifact or link is required" : ""}
            </FormHelperText>
          </FormControl>

          {/* Or separator */}
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              my: 1 
            }}
          >
            <Typography 
              variant="body2" 
              color="text.secondary" 
              sx={{ 
                px: 2, 
                fontWeight: 'medium' 
              }}
            >
              Or
            </Typography>
          </Box>

          <TextField
            label="External Link"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
              if (e.target.value) {
                setArtifactId(null); // Clear artifactId if link is provided
              }
            }}
            fullWidth
            margin="dense"
            disabled={isSubmitting || Boolean(artifactId)}
            size="small"
            placeholder="https://..."
            required={!artifactId}
            helperText={!artifactId && !link ? "An artifact or link is required" : ""}
          />
        </Box>
      ) : (
        <TextField
          label="External Link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          fullWidth
          margin="dense"
          disabled={isSubmitting}
          size="small"
          placeholder="https://..."
          required
          helperText={!link ? "A link is required when no artifacts are available" : ""}
        />
      )}

      {/* Buttons at the bottom */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 2,
          gap: 1,
          marginLeft: 'auto',
        }}
      >
        <DSButton
          variant="outlined"
          color="secondary"
          onClick={onCancel}
          disabled={isSubmitting}
          iconOnly
          size="small"
          startIcon={<Cancel />}
        />
        <DSButton
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={isSubmitting || !isValid}
          size="small"
          iconOnly
          startIcon={<CheckCircle />}
        />
      </Box>
    </CalloutCardStyled>
  );
};
