import { mergeStyles, Stack } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useCalloutCards } from '../../../Hooks/useCalloutCards';
import { InfoSection } from './InfoSection/InfoSection';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { DSSortableGrid } from '../Configuration/Components/DSSortableGrid';
import { DragEndEvent } from '@dnd-kit/core';
import { DSCalloutCardItem } from './DSCalloutCardItem';
import {
  CalloutCardsGrid,
  ExampleCalloutCard,
  AddCalloutCardButton,
  calloutCardsGridStyle,
} from './DSCalloutCards.styles';
import toast from 'react-hot-toast';

export interface CalloutCard {
  id: number;
  title: string;
  description: string;
  link?: string | null;
  ctaLabel?: string | null;
  artifactId?: number | null;
  order: number;
}

// Example cards to show when no cards exist
const exampleCards = [
  {
    title: 'Product Demo (Example)',
    description: 'Schedule a demo to see our product in action',
    ctaLabel: 'Schedule Demo',
  },
  {
    title: 'Resources (Example)',
    description: 'Access documentation, guides, and other resources',
    ctaLabel: 'View Resources',
  },
  {
    title: 'Contact Us (Example)',
    description: 'Reach out to our team with any questions',
    ctaLabel: 'Contact Us',
  },
];

export const DSCalloutCards = () => {
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { dealRoomRole } = useDealRoom();
  const {
    calloutCards,
    refetch: refetchCalloutCards,
    isLoading,
  } = useCalloutCards();
  const [localCalloutCards, setLocalCalloutCards] = useState<CalloutCard[]>([]);
  const [nextTempId, setNextTempId] = useState(-1);
  // Track cards that are currently being saved to prevent duplicate submissions
  const [savingCardIds, setSavingCardIds] = useState<number[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  // Initialize local cards from fetched cards
  useEffect(() => {
    if (calloutCards && calloutCards.length > 0) {
      // Make sure all cards have an order property
      const cardsWithOrder = calloutCards.map((card, index) => ({
        ...card,
        order: card.order !== undefined ? card.order : index,
      }));
      setLocalCalloutCards(cardsWithOrder);
    } else {
      setLocalCalloutCards([]);
    }
  }, [calloutCards]);

  // Determine if the user can edit (SELLER role)
  const canEdit = useMemo(() => {
    return dealRoomRole === 'SELLER';
  }, [dealRoomRole]);

  const styles = mergeStyles({
    gap: '2rem',
    boxShadow: '0px 2px 2px 0px #00000005',

    '.basic-actions-container': {
      gap: '1rem',
    },
  });

  const sortedCalloutCards = useMemo(() => {
    if (localCalloutCards.length === 0) return [];

    return [...localCalloutCards].sort((a, b) => a.order - b.order);
  }, [localCalloutCards]);

  // Add a new card
  const handleAddCard = useCallback(() => {
    const newCard: CalloutCard = {
      id: nextTempId,
      title: '',
      description: '',
      ctaLabel: '',
      link: null,
      artifactId: null,
      order: localCalloutCards.length,
    };

    setLocalCalloutCards((prev) => [...prev, newCard]);
    setNextTempId((prev) => prev - 1);
  }, [localCalloutCards.length, nextTempId]);

  // Delete a card
  const handleDeleteCard = useCallback((cardId: number) => {
    setLocalCalloutCards((prev) => prev.filter((card) => card.id !== cardId));
  }, []);

  // Save a card (new or updated)
  const handleSaveCard = useCallback(
    async (
      card: CalloutCard,
      title: string,
      description: string,
      ctaLabel: string,
      link: string | null,
      artifactId: number | null,
    ) => {
      // Prevent duplicate submissions
      if (savingCardIds.includes(card.id)) {
        return;
      }

      // Add this card's ID to the saving list
      setSavingCardIds((prev) => [...prev, card.id]);

      // For new cards (negative ID)
      if (card.id < 0) {
        try {
          const token = await getAccessTokenSilently();
          const response = await DealRoomsApiClient.createCalloutCard(
            organizationSlug!,
            dealRoomId!,
            {
              title,
              description,
              ctaLabel: ctaLabel || null,
              link,
              artifactId,
              order: card.order,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );

          // Update local cards with the new server-generated ID
          setLocalCalloutCards((prev) =>
            prev.map((c) =>
              c.id === card.id
                ? {
                    ...c,
                    id: response.data.id,
                    title,
                    description,
                    ctaLabel: ctaLabel || null,
                    link,
                    artifactId,
                  }
                : c,
            ),
          );

          toast.success('Card created successfully');
          refetchCalloutCards();
        } catch (error) {
          toast.error('Failed to create card');
        } finally {
          // Remove this card from the saving list after successful creation or error
          setSavingCardIds((prev) => prev.filter((id) => id !== card.id));
        }
      } else {
        // For existing cards, the DSCalloutCardItem component handles the update
        // Just update the local state
        setLocalCalloutCards((prev) =>
          prev.map((c) =>
            c.id === card.id
              ? {
                  ...c,
                  title,
                  description,
                  ctaLabel: ctaLabel || null,
                  link,
                  artifactId,
                }
              : c,
          ),
        );

        // Refetch cards from the server to ensure we have the latest data
        refetchCalloutCards();

        // Remove this card from the saving list
        setSavingCardIds((prev) => prev.filter((id) => id !== card.id));
      }
    },
    [
      organizationSlug,
      dealRoomId,
      getAccessTokenSilently,
      refetchCalloutCards,
      savingCardIds,
    ],
  );

  // Handle drag end event for reordering
  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (dealRoomRole !== 'SELLER') return;
      if (!organizationSlug || !dealRoomId) return;
      if (sortedCalloutCards.length <= 1) return; // Disable drag if only one card

      const { active, over } = event;
      if (!active || !over || active.id === over.id) return;

      // Find the indices of the dragged item and the drop target
      const oldIndex = sortedCalloutCards.findIndex(
        (card) => card.id === active.id,
      );
      const newIndex = sortedCalloutCards.findIndex(
        (card) => card.id === over.id,
      );

      if (oldIndex === -1 || newIndex === -1) return;

      // Create a new array with the item moved to the new position
      const reorderedCards = [...sortedCalloutCards];
      const [movedCard] = reorderedCards.splice(oldIndex, 1);
      reorderedCards.splice(newIndex, 0, movedCard);

      // Update the order property for all cards
      const updatedCards = reorderedCards.map((card, index) => ({
        ...card,
        order: index,
      }));

      setLocalCalloutCards(updatedCards);

      try {
        // Update orders on the server for all cards
        const token = await getAccessTokenSilently();

        const promises = updatedCards
          .filter((card) => card.id > 0) // Only update cards that exist on the server
          .map((card) =>
            DealRoomsApiClient.updateCalloutCard(
              organizationSlug!,
              dealRoomId!,
              card.id,
              { ...card, order: card.order },
              { headers: { Authorization: `Bearer ${token}` } },
            ),
          );

        await Promise.all(promises);
        toast.success('Card order updated successfully');
      } catch (error) {
        console.error('Error updating card order:', error);
        toast.error('Failed to update card order');
      }
    },
    [
      sortedCalloutCards,
      organizationSlug,
      dealRoomId,
      getAccessTokenSilently,
      dealRoomRole,
    ],
  );

  // Add the CSS for the callout cards grid to the document
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = calloutCardsGridStyle;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  // Render the grid with sortable and editable cards
  const renderCalloutCards = () => (
    <DSSortableGrid<CalloutCard>
      items={sortedCalloutCards}
      onDragEnd={handleDragEnd}
      className="callout-cards-grid"
      disableDrag={dealRoomRole !== 'SELLER'}
      renderItem={(item, index) => (
        <DSCalloutCardItem
          key={item.id}
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          card={item}
          onDelete={() => handleDeleteCard(item.id)}
          onSave={handleSaveCard}
          totalCards={sortedCalloutCards.length}
        />
      )}
    />
  );

  // Render example cards when there are no cards
  const renderExampleCards = () => (
    <Box sx={{ textAlign: 'left' }}>
      <CalloutCardsGrid>
        {exampleCards.map((example, index) => (
          <ExampleCalloutCard key={index}>
            <Typography
              variant="subtitle2"
              sx={{
                color: DEALROOMS_COLORS.themePrimary,
                lineHeight: '1rem',
                marginRight: '2rem',
              }}
            >
              {example.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: '.875rem',
                lineHeight: '1.25rem',
                color: DEALROOMS_COLORS.themePrimary,
                marginTop: '0.5rem',
              }}
            >
              {example.description}
            </Typography>
            {example.ctaLabel && (
              <Box sx={{ marginTop: '1rem' }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ textTransform: 'none' }}
                >
                  {example.ctaLabel}
                </Button>
              </Box>
            )}
          </ExampleCalloutCard>
        ))}
      </CalloutCardsGrid>
    </Box>
  );

  // Render loading spinner
  const renderLoadingSpinner = () => (
    <Box sx={{ textAlign: 'center', padding: '2rem' }}>
      <CircularProgress size={24} />
    </Box>
  );

  // Don't render anything for buyers when there are no cards
  if (sortedCalloutCards.length === 0 && !canEdit) {
    return null;
  }

  return (
    <Stack className={styles}>
      <InfoSection
        icon={<DescriptionOutlinedIcon fontSize="medium" color="secondary" />}
        title={
          <>
            Callout Cards
            {canEdit && (
              <AddCalloutCardButton
                onClick={handleAddCard}
                size="small"
                variant="text"
                color="secondary"
                startIcon={<Add fontSize="small" />}
              >
                Add
              </AddCalloutCardButton>
            )}
          </>
        }
      >
        {isLoading
          ? renderLoadingSpinner()
          : sortedCalloutCards.length > 0
            ? renderCalloutCards()
            : canEdit && renderExampleCards()}
      </InfoSection>
    </Stack>
  );
};
