import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useKeyDetails } from '../../../../Hooks/useKeyDetails';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { InfoSection } from './InfoSection';
import toast from 'react-hot-toast';
import { Star, Add } from '@mui/icons-material';
import { DragEndEvent } from '@dnd-kit/core';
import { DSSortableGrid } from '../../Configuration/Components/DSSortableGrid';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { DealRoomFact } from '@meetingflow/common/Api/data-contracts';
import { useQueryClient } from 'react-query';
import { OrganizationKeyDetailsQuery } from '../../../../QueryNames';
import { DealRoomKeyFactCard } from './DealRoomKeyFactCard';
import {
  ExampleKeyFactCard,
  KeyFactTitle,
  KeyFactValue,
  AddKeyFactButton,
} from './DealRoomKeyFacts.styles';
import { DSButton } from '../../DS';

// Add CSS for the key facts grid
const keyFactsGridStyle = `
.key-facts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-flow: dense;
  grid-auto-rows: auto;
  gap: 1rem;
  box-sizing: border-box;
  padding: 0 0 1rem 0;
  overflow: hidden;
}
@container (max-width: 600px) {
  .key-facts-grid {
    grid-template-columns: none;
  }
}
`;

// Define the structure for callout card form data
interface KeyFactData {
  id: number;
  title: string;
  value: string;
  editableByBuyer: boolean;
  order?: number;
}

// Example Key Fact Card components
const ExampleKeyFactCardComponent = () => {
  return (
    <ExampleKeyFactCard>
      <KeyFactTitle
        variant="subtitle2"
        sx={{
          marginBottom: '0.5rem',
        }}
      >
        Add a Key Detail (Example)
      </KeyFactTitle>
      <KeyFactValue variant="body1" isLongText={true}>
        Key Details can be any information about this opportunity you'd like the
        buyer to know about.
      </KeyFactValue>
    </ExampleKeyFactCard>
  );
};

const ExampleBudgetKeyFactCardComponent = () => {
  return (
    <ExampleKeyFactCard>
      <KeyFactTitle variant="subtitle2" sx={{ marginBottom: '0.5rem' }}>
        Budget (Example)
      </KeyFactTitle>
      <KeyFactValue variant="body1" isLongText={false}>
        $1,000,000
      </KeyFactValue>
    </ExampleKeyFactCard>
  );
};

export const DealRoomKeyFacts = () => {
  const { getAccessTokenSilently } = useAuth0();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { dealRoom, dealRoomRole } = useDealRoom();
  const [newKeyFact, setNewKeyFact] = useState<DealRoomFact | null>(null);
  const queryClient = useQueryClient();
  const [showExampleCard, setShowExampleCard] = useState(true);

  const { keyDetails = [], refetchAll, isLoading } = useKeyDetails();

  // Effect to hide example card when key facts are added
  useEffect(() => {
    if (keyDetails.length > 0 || newKeyFact) {
      setShowExampleCard(false);
    } else {
      setShowExampleCard(true);
    }
  }, [keyDetails.length, newKeyFact]);

  const sortedKeyFacts = useMemo(() => {
    if (keyDetails.every((fact) => 'order' in fact)) {
      return [...keyDetails].sort(
        (a, b) => (a.order as number) - (b.order as number),
      );
    }
    return keyDetails;
  }, [keyDetails]);

  const handleAddKeyFact = useCallback(() => {
    if (dealRoomRole !== 'SELLER') return;

    const newOrder =
      sortedKeyFacts.length > 0
        ? Math.max(...sortedKeyFacts.map((fact) => fact.order || 0)) + 1
        : 0;

    setNewKeyFact({
      id: -1000 - Math.floor(Math.random() * 1000), // Smaller negative ID that fits in INT4
      title: '',
      value: '',
      editableByBuyer: false,
      order: newOrder,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    } as DealRoomFact);
  }, [sortedKeyFacts, dealRoomRole]);

  const handleSaveNewKeyFact = useCallback(
    async (keyFact: DealRoomFact, title: string, value: string) => {
      if (!organizationSlug || !dealRoomId) return;

      // Validate required fields
      if (!title.trim() || !value.trim()) {
        toast.error('Both title and value are required');
        return;
      }

      try {
        const token = await getAccessTokenSilently();

        await DealRoomsApiClient.createKeyFact(
          organizationSlug,
          dealRoomId,
          {
            title,
            value,
            editableByBuyer: keyFact.editableByBuyer,
            order: keyFact.order,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        toast.success('Key Detail created successfully');
        setNewKeyFact(null);
        refetchAll();
      } catch (error) {
        toast.error('Failed to create Key Detail');
      }
    },
    [organizationSlug, dealRoomId, getAccessTokenSilently, refetchAll],
  );

  const handleCancelNewKeyFact = useCallback(() => {
    setNewKeyFact(null);
  }, []);

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (dealRoomRole !== 'SELLER') return;
      if (!organizationSlug) return; // Add this check to prevent undefined errors
      if (sortedKeyFacts.length <= 1) return; // Disable drag if only one card

      const { active, over } = event;
      if (!over || active.id === over.id) {
        return;
      }

      // Find the old and new indices
      const oldIndex = sortedKeyFacts.findIndex(
        (fact) => fact.id === active.id,
      );
      const newIndex = sortedKeyFacts.findIndex((fact) => fact.id === over.id);

      if (oldIndex === -1 || newIndex === -1) {
        return;
      }

      // Create a new array with updated orders
      const reorderedFacts = [...sortedKeyFacts];
      const [movedFact] = reorderedFacts.splice(oldIndex, 1);
      reorderedFacts.splice(newIndex, 0, movedFact);

      // Update order values for all facts
      const updatedFacts = reorderedFacts.map((fact, index) => ({
        ...fact,
        order: index,
      }));

      // Optimistically update the UI immediately
      queryClient.setQueryData(
        OrganizationKeyDetailsQuery(organizationSlug, dealRoomId),
        { data: updatedFacts },
      );

      try {
        // Get the access token
        const token = await getAccessTokenSilently();

        // Check if organizationSlug and dealRoomId are defined
        if (!organizationSlug || dealRoomId === undefined) {
          console.error('Organization slug or dealRoomId is undefined');
          return;
        }

        // Update the order of key facts on the server
        await toast.promise(
          (async () => {
            // Update each key fact with its new order
            for (const fact of updatedFacts) {
              // Skip temporary key facts with negative IDs
              if (fact.id < 0) continue;

              await DealRoomsApiClient.updateKeyFact(
                organizationSlug,
                dealRoomId,
                fact.id,
                {
                  title: fact.title || undefined,
                  value: fact.value || undefined,
                  editableByBuyer:
                    fact.editableByBuyer === null
                      ? undefined
                      : fact.editableByBuyer,
                  order: fact.order,
                },
                {
                  headers: { Authorization: `Bearer ${token}` },
                },
              );
            }
          })(),
          {
            loading: 'Updating Key Details order...',
            success: 'Key Details order updated',
            error: 'Failed to update Key Details order',
          },
        );

        // No need to refetch on success since we've already updated the state
      } catch (error) {
        console.error('Error updating Key Details order:', error);
        // Only refetch on error to restore the correct state
        refetchAll();
      }
    },
    [
      sortedKeyFacts,
      getAccessTokenSilently,
      organizationSlug,
      dealRoomId,
      refetchAll,
      queryClient,
      dealRoomRole,
    ],
  );

  if (isLoading) {
    return (
      <InfoSection
        icon={<Star fontSize="medium" color="secondary" />}
        title={
          <>
            Key Details
            {dealRoomRole === 'SELLER' && (
              <AddKeyFactButton
                onClick={handleAddKeyFact}
                size="small"
                variant="text"
                color="secondary"
                startIcon={<Add fontSize="small" />}
              >
                Add
              </AddKeyFactButton>
            )}
          </>
        }
      >
        <style>{keyFactsGridStyle}</style>
        <Box className="key-facts-grid" sx={{ display: 'grid' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={24} />
          </Box>
        </Box>
      </InfoSection>
    );
  }

  if (keyDetails.length === 0 && !newKeyFact) {
    if (!organizationSlug || !dealRoomId) {
      return null;
    }

    // If user is a buyer and there are no key facts, show nothing
    if (dealRoomRole !== 'SELLER') {
      return null;
    }

    return (
      <InfoSection
        icon={<Star fontSize="medium" color="secondary" />}
        title={
          <>
            Key Details
            {dealRoomRole === 'SELLER' && (
              <AddKeyFactButton
                onClick={handleAddKeyFact}
                size="small"
                variant="text"
                color="secondary"
                startIcon={<Add fontSize="small" />}
              >
                Add
              </AddKeyFactButton>
            )}
          </>
        }
      >
        <style>{keyFactsGridStyle}</style>
        <Box className="key-facts-grid" sx={{ display: 'grid' }}>
          <ExampleKeyFactCardComponent />
          <ExampleBudgetKeyFactCardComponent />
        </Box>
      </InfoSection>
    );
  }

  if (!organizationSlug || !dealRoomId) {
    return null;
  }

  return (
    <InfoSection
      icon={<Star fontSize="medium" color="secondary" />}
      title={
        <>
          Key Details
          {dealRoomRole === 'SELLER' && (
            <AddKeyFactButton
              onClick={handleAddKeyFact}
              size="small"
              variant="text"
              color="secondary"
              startIcon={<Add fontSize="small" />}
            >
              Add
            </AddKeyFactButton>
          )}
        </>
      }
    >
      <style>{keyFactsGridStyle}</style>
      <DSSortableGrid
        className="key-facts-grid"
        items={[
          ...sortedKeyFacts.map((fact) => ({
            id: fact.id,
            order: fact.order as number,
          })),
          ...(newKeyFact
            ? [{ id: newKeyFact.id, order: newKeyFact.order as number }]
            : []),
        ]}
        onDragEnd={handleDragEnd}
        // Disable drag sensors if user is not a seller
        disableDrag={dealRoomRole !== 'SELLER'}
        renderItem={(item, index) => {
          // Check if this is the new key fact
          if (newKeyFact && item.id === newKeyFact.id) {
            return (
              <DealRoomKeyFactCard
                key={newKeyFact.id}
                organizationSlug={organizationSlug}
                dealRoomId={dealRoomId}
                keyFact={newKeyFact}
                onCancel={handleCancelNewKeyFact}
                onSave={handleSaveNewKeyFact}
              />
            );
          }

          // Otherwise, it's an existing key fact
          const keyFact = sortedKeyFacts.find((f) => f.id === item.id);
          if (!keyFact) return null;
          return (
            <DealRoomKeyFactCard
              key={keyFact.id}
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
              keyFact={keyFact}
              onDelete={refetchAll}
              sortedKeyFacts={sortedKeyFacts}
            />
          );
        }}
      />
    </InfoSection>
  );
};
