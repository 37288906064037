import React from 'react';
import { DSDetailDecisionSiteName } from './DSDetailDecisionSiteName';
import { DSDetailOwner } from './DSDetailOwner';
import { DSDetailCostEstimate } from './DSDetailCostEstimate';
import { DSDetailInitialBudget } from './DSDetailInitialBudget';
import { DSDetailDealState } from './DSDetailDealState';
import { DSDetailTargetClose } from './DSDetailTargetClose';
import { DSDetailActualClose } from './DSDetailActualClose';
import { DSDetailTouchpoint } from './DSDetailTouchpoint';
import DSDetailBar from './DSDetailBar';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { SxProps, Theme } from '@mui/material';

interface DSDecisionSiteDetailsProps {
  editable?: boolean;
  version: 'BUYER' | 'SELLER';
  sx?: SxProps<Theme>;
}

/**
 * A component that encapsulates all decision site detail components
 */
export const DSDecisionSiteDetails: React.FC<DSDecisionSiteDetailsProps> = ({
  editable = false,
  version,
  sx,
}) => {
  return (
    <DSDetailBar sx={sx} isSeller={version === 'SELLER'}>
      {version === 'SELLER' ? (
        <DSDetailDecisionSiteName editable={editable} />
      ) : null}
      <DSDetailTouchpoint />
      <DSDetailCostEstimate editable={editable} />
      <DSDetailInitialBudget editable={editable} />
      {version === 'SELLER' ? (
        <>
          <DSDetailDealState editable={editable} />
          <DSDetailTargetClose editable={editable} />
        </>
      ) : null}
      <DSDetailActualClose editable={editable} />
    </DSDetailBar>
  );
};
