/**
 * DSListAndDetailPanel is a reusable component that implements a master-detail pattern with optional tabs.
 * It provides a two-panel layout where users can:
 * 1. View and select items from a list (master view)
 * 2. View details of the selected item (detail view)
 * 3. Access additional content through collapsible tabs
 *
 * Features:
 * - Searchable list with customizable rendering
 * - Configurable table columns with sorting
 * - Infinite scrolling support
 * - URL-synchronized tab state
 * - Collapsible tab panel
 * - Responsive layout
 */

import { useSearchParams } from 'react-router-dom';
import DSResponsiveDrawer from '../../../Components/Common/DSResponsiveDrawer';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  CircularProgress,
  Box,
  Typography,
  TableSortLabel,
  Paper,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { KeyboardArrowUp } from '@mui/icons-material';
import { DSTabs, DSTab } from './DSTabs';
import {
  ListPanelWrapper,
  DetailPanelWrapper,
  TabsWrapper,
  ListWrapper,
  DetailsWrapper,
  ContentWrapper,
  HeaderWrapper,
  CollapseButton,
  TableWrapper,
  LoadingWrapper,
  SearchWrapper,
  TabContentWrapper,
  StyledTableCell,
  StyledTableRow,
  StyledPaper,
  StyledTableContainer,
} from './DSListAndDetailPanel.styles';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

/**
 * Configuration for table columns in the list view.
 * @template T - The type of items in the list
 * @template SortKey - The type of keys used for sorting
 */
export interface Column<T, SortKey extends string = string> {
  id: string;
  label: string;
  minWidth?: number;
  width?: number;
  align?: 'left' | 'right' | 'center';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: (value: any) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValue: (item: T) => any;
  sortKey?: SortKey; // The key to use for sorting, if sortable
  style?: React.CSSProperties; // Add style property for custom cell styling
}

/**
 * Configuration for individual tabs in the detail panel.
 */
export interface DSTabConfig {
  value: string;
  label: React.ReactNode;
  panel: React.ReactNode;
  count?: number;
}

/**
 * Props for the DSListAndDetailPanel component
 * @template T - Type of items in the list
 * @template IdType - Type of the item IDs (string or number)
 * @template SortKey - Type of keys used for sorting
 */
interface DSListAndDetailPanelProps<
  T,
  IdType extends string | number = string | number,
  SortKey extends string = string,
> {
  objectList: T[]; // Array of items to display in the list
  selectedObjectId: IdType | null; // ID of the currently selected item
  onSelectObject: (objectId: IdType) => void; // Callback when an item is selected
  onCloseObjectPanel: () => void; // Callback when the detail panel is closed
  objectTypeKey?: string; // Optional key to identify the type of objects being displayed
  renderDetail: (item: T) => React.ReactNode; // Function to render the detail view
  renderHeader?: (item: T) => React.ReactNode; // Optional function to render the detail header
  getItemId: (item: T) => IdType; // Function to extract the ID from an item

  // Table configuration
  renderTableHeader?: boolean;
  columns: Column<T, SortKey>[]; // Column definitions for the list view table

  // Sorting
  sortBy?: SortKey; // Current sort key
  sortOrder?: 'asc' | 'desc'; // Current sort order
  onSort?: (sortKey: SortKey) => void; // Callback when sort changes

  // Search configuration
  searchEnabled?: boolean; // Whether to show the search input
  searchPlaceholder?: string; // Placeholder text for search input
  onSearch?: (searchString: string) => void; // Callback when search query changes

  // Infinite loading props
  isLoading?: boolean; // Whether the list is currently loading
  isFetchingNextPage?: boolean; // Whether the next page is being fetched
  hasNextPage?: boolean; // Whether there are more items to load
  onLoadMore?: () => void; // Callback to load more items
  loadingPlaceholder?: React.ReactNode; // Content to display while loading
  noDataMessage?: React.ReactNode; // Custom message to display when no data is found

  // Tab configuration
  tabs?: DSTabConfig[]; // Array of tab configurations
  areTabsCollapsible?: boolean; // Whether tabs can be collapsed
  defaultTab?: string; // Initial tab to select
  defaultIsTabsCollapsed?: boolean; // Whether tabs should be initially collapsed
  tabPanelHeightPercentage?: number; // Height of the tab panel in viewport height percentage (default: 33)
  tabPanelMaxHeight?: string; // Maximum height of the tab panel
  onTabChange?: (tab: string | number) => void; // Callback when tab changes
  panelOnly?: boolean; // Whether to only show the detail panel
  listOnly?: boolean; // Whether to only show the list panel
  tableMaxHeightOffset?: number; // Custom offset value for table max height in pixels
  customMaxHeight?: string; // Custom max height for the table container
}

/**
 * A flexible master-detail panel component with optional tabs and infinite scrolling.
 * Handles URL state synchronization and responsive layout.
 *
 * @template T - Type of items in the list
 * @template IdType - Type of item IDs (string or number)
 * @template SortKey - Type of keys used for sorting
 */
const DSListAndDetailPanel = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
  IdType extends string | number = string | number,
  SortKey extends string = string,
>(
  props: DSListAndDetailPanelProps<T, IdType, SortKey>,
) => {
  const {
    objectList,
    selectedObjectId,
    onSelectObject,
    searchEnabled = false,
    searchPlaceholder = 'Search...',
    onSearch,
    onCloseObjectPanel,
    objectTypeKey = 'object',
    renderDetail,
    renderHeader,
    getItemId,
    renderTableHeader = true,
    columns,
    sortBy,
    sortOrder = 'asc',
    onSort,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    onLoadMore,
    loadingPlaceholder,
    tabs,
    areTabsCollapsible = true,
    defaultTab,
    defaultIsTabsCollapsed = true,
    tabPanelHeightPercentage = 33,
    tabPanelMaxHeight,
    onTabChange,
    panelOnly,
    listOnly,
  } = props;

  // Hooks for URL state and organization context
  const { dealRoomId } = useDealRoom();
  const { slug: organizationSlug } = useOrganization();
  const [searchParams, setSearchParams] = useSearchParams();
  const observerTarget = useRef<HTMLDivElement>(null);
  const [isTabsCollapsed, setIsTabsCollapsed] = useState(
    defaultIsTabsCollapsed,
  );
  const [selectedTab, setSelectedTab] = useState<string | number>(
    defaultTab || (tabs?.[0]?.value ?? ''),
  );

  // State for managing loading indicator display
  const [showLoadingMore, setShowLoadingMore] = useState(false);
  const loadingTimer = useRef<NodeJS.Timeout>();

  // Debounced search handling
  const [searchInputValue, setSearchInputValue] = useState('');
  const searchTimeout = useRef<NodeJS.Timeout>();

  // Tab state management - sync with URL parameters
  const currentTab =
    searchParams.get('tab') || defaultTab || (tabs?.[0]?.value ?? '');

  // Find the currently selected object from the list
  const selectedObject = objectList.find(
    (item) => getItemId(item) === selectedObjectId,
  );

  // Reset tab state when selected object changes
  useEffect(() => {
    setIsTabsCollapsed(defaultIsTabsCollapsed);
    setSelectedTab(defaultTab || (tabs?.[0]?.value ?? ''));
  }, [selectedObjectId, defaultTab, tabs, defaultIsTabsCollapsed]);

  // Handle loading indicator display with minimum duration
  useEffect(() => {
    if (isFetchingNextPage) {
      setShowLoadingMore(true);
      // Clear any existing timer
      if (loadingTimer.current) {
        clearTimeout(loadingTimer.current);
      }
    } else if (showLoadingMore) {
      // When loading finishes, set a minimum display time
      loadingTimer.current = setTimeout(() => {
        setShowLoadingMore(false);
      }, 500); // Minimum display time of 500ms
    }

    return () => {
      if (loadingTimer.current) {
        clearTimeout(loadingTimer.current);
      }
    };
  }, [isFetchingNextPage, showLoadingMore]);

  // Set up intersection observer for infinite scrolling
  useEffect(() => {
    // Don't set up observer if:
    // 1. There's no next page
    // 2. No load more callback
    // 3. Currently fetching
    // 4. No items yet (initial load)
    // 5. Loading placeholder is being shown
    if (
      !hasNextPage ||
      !onLoadMore ||
      isFetchingNextPage ||
      objectList.length === 0 ||
      (isLoading && !isFetchingNextPage)
    )
      return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          onLoadMore();
        }
      },
      { threshold: 0.1, rootMargin: '100px' },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [
    hasNextPage,
    onLoadMore,
    isFetchingNextPage,
    objectList.length,
    isLoading,
  ]);

  if (!organizationSlug) return null;

  // Handle selection of an object from the list
  const handleSelectObject = (objectId: IdType) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(objectTypeKey, objectId.toString());
    setSearchParams(newParams, { replace: true });
    onSelectObject(objectId);
  };

  // Handle closing the detail panel
  const handleCloseObjectPanel = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete(objectTypeKey);
    newParams.delete('tab'); // Remove tab parameter when closing panel
    setSearchParams(newParams, { replace: true });
    onCloseObjectPanel();
  };

  // Handle column sorting
  const handleSort = (column: Column<T, SortKey>) => {
    if (!column.sortKey || !onSort) return;
    onSort(column.sortKey);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInputValue(value);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      onSearch?.(value);
    }, 300);
  };

  // Handle tab changes and collapse state
  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: string | number,
  ) => {
    // If clicking the currently selected tab while panel is open, collapse it
    // Otherwise, ensure panel is open and switch to the new tab
    if (newValue === currentTab && !isTabsCollapsed) {
      setIsTabsCollapsed(true);
    } else {
      setIsTabsCollapsed(false);
      const newParams = new URLSearchParams(searchParams);
      newParams.set('tab', String(newValue));
      setSearchParams(newParams);
      onTabChange?.(newValue);
    }
  };

  // Render the detail panel content with optional tabs
  const renderDetailContent = () => {
    if (!selectedObject) return null;

    const detailContent = (
      <>
        {renderHeader && (
          <HeaderWrapper>{renderHeader(selectedObject)}</HeaderWrapper>
        )}
        <DetailsWrapper
          isCollapsed={isTabsCollapsed}
          tabPanelHeightPercentage={tabPanelHeightPercentage}
        >
          {renderDetail(selectedObject)}
        </DetailsWrapper>
      </>
    );

    if (!tabs) {
      return detailContent;
    }

    const currentTabConfig = tabs.find((tab) => tab.value === currentTab);

    return (
      <ContentWrapper>
        {detailContent}
        <TabsWrapper
          isCollapsed={isTabsCollapsed}
          tabPanelHeightPercentage={tabPanelHeightPercentage}
          tabPanelMaxHeight={tabPanelMaxHeight}
        >
          {areTabsCollapsible && (
            <CollapseButton
              onClick={() => setIsTabsCollapsed(!isTabsCollapsed)}
              size="small"
              sx={{
                '& svg': {
                  transform: isTabsCollapsed
                    ? 'rotate(0deg)'
                    : 'rotate(180deg)',
                },
              }}
            >
              <KeyboardArrowUp />
            </CollapseButton>
          )}
          <DSTabs
            className="detail-panel-tabs"
            value={currentTab}
            onChange={handleTabChange}
          >
            {tabs.map((tab) => (
              <DSTab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                count={tab.count}
              />
            ))}
          </DSTabs>
          <TabContentWrapper>{currentTabConfig?.panel}</TabContentWrapper>
        </TabsWrapper>
      </ContentWrapper>
    );
  };

  return (
    <ListPanelWrapper
      style={
        panelOnly ? { display: 'flex', justifyContent: 'flex-end' } : undefined
      }
    >
      {!panelOnly && (
        <ListWrapper>
          {/* Search input */}
          {searchEnabled && (
            <SearchWrapper>
              <TextField
                size="small"
                fullWidth
                placeholder={searchPlaceholder}
                value={searchInputValue}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </SearchWrapper>
          )}
          {/* Loading state, empty state, or table content */}
          {isLoading && !isFetchingNextPage ? (
            loadingPlaceholder || (
              <LoadingWrapper>
                <CircularProgress size={24} />
              </LoadingWrapper>
            )
          ) : !isLoading && objectList.length === 0 ? (
            <LoadingWrapper>
              <Box sx={{ textAlign: 'center', p: 3 }}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  {props.noDataMessage ?? `No ${objectTypeKey} found`}
                </Typography>
              </Box>
            </LoadingWrapper>
          ) : (
            <div>
              <StyledTableContainer
                tableMaxHeightOffset={props.tableMaxHeightOffset}
                customMaxHeight={props.customMaxHeight}
              >
                {/* Table header with sorting */}
                <Table stickyHeader>
                  {renderTableHeader && (
                    <TableHead>
                      <StyledTableRow>
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              ...(column.style || {}),
                              width: column.width,
                              // Dynamic minWidth based on column definition, with smaller values on small screens
                              minWidth: column.minWidth
                                ? Math.min(column.minWidth, 80)
                                : 80,
                            }}
                            onClick={() => handleSort(column)}
                          >
                            {column.sortKey ? (
                              <TableSortLabel
                                active={sortBy === column.sortKey}
                                direction={sortOrder}
                              >
                                {column.label}
                              </TableSortLabel>
                            ) : (
                              column.label
                            )}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    </TableHead>
                  )}
                  {/* Table body with item rows */}
                  <TableBody>
                    {objectList.map((item) => (
                      <StyledTableRow
                        // hover
                        key={getItemId(item)}
                        onClick={
                          !listOnly
                            ? () => handleSelectObject(getItemId(item))
                            : undefined
                        }
                        selected={getItemId(item) === selectedObjectId}
                        sx={{ cursor: !listOnly ? 'pointer' : 'default' }}
                      >
                        {columns.map((column) => {
                          const value = column.getValue(item);
                          return (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                ...(column.style || {}),
                                width: column.width,
                                // Dynamic minWidth based on column definition, with smaller values on small screens
                                minWidth: column.minWidth
                                  ? Math.min(column.minWidth, 80)
                                  : 80,
                              }}
                            >
                              {column.format ? column.format(value) : value}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                {/* Infinite scroll observer and loading indicator */}
                {!isLoading && hasNextPage && (
                  <>
                    <div
                      ref={observerTarget}
                      style={{ height: '20px', width: '100%' }}
                    />
                    <Box
                      sx={{
                        height: showLoadingMore ? 'auto' : 0,
                        overflow: 'hidden',
                        transition: 'height 0.2s ease-in-out',
                      }}
                    >
                      <LoadingWrapper
                        style={{
                          padding: '12px',
                          borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
                          opacity: showLoadingMore ? 1 : 0,
                          transition: 'opacity 0.2s ease-in-out',
                        }}
                      >
                        <CircularProgress size={20} />
                        <Box sx={{ ml: 1 }}>
                          <Typography variant="body2" color="textSecondary">
                            Loading more items...
                          </Typography>
                        </Box>
                      </LoadingWrapper>
                    </Box>
                  </>
                )}
              </StyledTableContainer>
            </div>
          )}
        </ListWrapper>
      )}
      {!listOnly && (
        <DSResponsiveDrawer
          anchor="right"
          widthPercentage={70}
          mobileWidthPercentage={90}
          open={Boolean(selectedObjectId)}
          padding={'0'}
          onClose={handleCloseObjectPanel}
        >
          {renderDetailContent()}
        </DSResponsiveDrawer>
      )}
    </ListPanelWrapper>
  );
};

export default DSListAndDetailPanel;
