import {
  styled,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Stack,
  Box,
  Chip,
  Badge,
  Typography,
  IconButton,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

interface StyledProps {
  $size?: 'default' | 'large';
}

export const CompanyListItem = styled(ListItem)(() => ({
  margin: 0,
  padding: 0,
  alignItems: 'center',
  display: 'flex',
  gap: '.75rem',
  position: 'relative',
  height: '32px',
}));

export const CompanyListItemAvatar = styled(ListItemAvatar)(() => {
  const baseSize = 32;
  return {
    width: `${baseSize}px`,
    minWidth: `${baseSize}px`,
    position: 'relative',
    height: `${baseSize}px`,
  };
});

export const CompanyListItemText = styled(ListItemText)(() => ({
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '0rem',
  position: 'relative',
  '& .MuiListItemText-primary': {
    fontSize: '1rem',
    lineHeight: '1rem',
  },
  '& .MuiListItemText-secondary': {
    fontSize: '0.875rem',
    lineHeight: '.875rem',
    color: DEALROOMS_COLORS.cloudburst,
    '& a': {
      fontSize: 'inherit',
      lineHeight: 'inherit',
      color: DEALROOMS_COLORS.cloudburst,
    },
  },
}));

interface CompanyAvatarProps {
  $size?: number;
  $hasError?: boolean;
}

export const CompanyAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== '$size' && prop !== '$hasError',
})<CompanyAvatarProps>(({ $size, $hasError }) => ({
  backgroundColor: 'transparent',
  width: $size || 40,
  height: $size || 40,
  ...($hasError && {
    bgcolor: '#f5f5f5',
    color: '#9e9e9e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  '& img': {
    objectFit: 'contain',
    margin: 'auto',
  },
}));

interface CompanyContainerProps extends StyledProps {
  $stacked?: boolean;
}

export const CompanyContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== '$size' && prop !== '$stacked',
})<CompanyContainerProps>(({ $size, $stacked }) => ({
  position: 'relative',
  height: $stacked ? 'auto' : $size === 'large' ? '42px' : '32px',
  width: '100%',
}));

export const CompanyContentStack = styled(Stack)({
  padding: 1,
  flex: 1,
  height: '32px',
  alignItems: 'center',
});

export const AvatarWrapper = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const SocialIconsStack = styled(Stack)({
  position: 'relative',
  left: '-.125rem',
});

export const AbsoluteSocialIconsStack = styled(Stack)({
  position: 'absolute',
  top: '1.25rem',
  left: '3.125rem',
  alignItems: 'center',
});

export const CompanyNameContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
});

export const CompanyName = styled(Box)({
  fontSize: '0.875rem',
  fontWeight: 500,
});

export const CompanyUrl = styled('a')({
  color: DEALROOMS_COLORS.neutralDark,
  fontSize: '.875rem',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const TagsContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '.25rem',
  // marginTop: '.5rem',
});

export const BadgesContainer = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
  overflow: 'hidden',
  gap: '8px',
});

export const ContainerBox = styled(Box)({
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const TooltipContentBox = styled(Box)({
  padding: '4px',
});

export const CountBadgeContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '16px',
  marginRight: '8px',
});

export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    fontSize: '0.666rem',
    height: '20px',
    lineHeight: '20px',
    minWidth: '20px',
    padding: '0 4px',
    backgroundColor: DEALROOMS_COLORS.cloudburst,
    position: 'static',
    transform: 'none',
    borderRadius: '10px',
    color: 'white',
  },
});

export const LinkedInBadge = styled('a', {
  shouldForwardProp: (prop) => prop !== '$size',
})<StyledProps>(({ $size }) => {
  const badgeSize = $size === 'large' ? 20 : 16;
  const iconSize = $size === 'large' ? 15 : 12;
  return {
    position: 'absolute',
    bottom: $size === 'large' ? -8 : -4,
    right: $size === 'large' ? -8 : -4,
    width: badgeSize,
    height: badgeSize,
    borderRadius: '50%',
    backgroundColor: '#0077b5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid white',
    '& svg': {
      width: iconSize,
      height: iconSize,
      color: 'white',
    },
  };
});

export const TooltipTypography = styled(Typography)({
  display: 'block',
  marginBottom: '8px',
});

export const SocialIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'component',
})({
  color: DEALROOMS_COLORS.neutralDark,
  height: '24px',
  width: '24px',
  padding: 0,
  margin: 0,
  transition: 'all 0.3s ease',
  backgroundColor: 'transparent',
  '&:hover': {
    color: DEALROOMS_COLORS.neutralDarker,
    backgroundColor: 'transparent',
  },
});

export const BusinessIconStyled = styled(BusinessIcon)(
  ({ size }: { size?: number }) => ({
    fontSize: size ? size * 0.6 : 'inherit',
  }),
);

export const CompanyStackContainer = styled(Stack)({
  width: '100%',
});

export const TagsStackContainer = styled(Stack)({
  overflow: 'hidden',
  width: '100%',
});

export const TagsRowStack = styled(Stack)({
  flexWrap: 'wrap',
  width: '100%',
  maxWidth: '250px',
});

export const TagChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== '$bgColor' && prop !== '$textColor',
})<{ $bgColor: string; $textColor: string }>(({ $bgColor, $textColor }) => ({
  backgroundColor: $bgColor,
  color: $textColor,
  border: 'none',
  fontWeight: 500,
  flexShrink: 0,
  '&:hover': {
    backgroundColor: $bgColor,
  },
}));

export const MoreTagsChip = styled(Chip)({
  backgroundColor: '#F5F5F5',
  color: '#666666',
  border: 'none',
  fontWeight: 500,
  flexShrink: 0,
});
