import { Company } from '@meetingflow/common/Api/data-contracts';
import { OmitUndefinedValues } from '@meetingflow/common/ObjectHelpers';
import {
  Link,
  Badge,
  IconButton,
  Stack,
  Typography,
  Chip,
  Box,
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DSButton, DSTooltip } from '../../DS';
import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import {
  CompanyAvatar,
  LinkedInBadge,
  CompanyContainer,
  CompanyContentStack,
  CompanyNameContainer,
  CompanyName,
  CompanyUrl,
  TagsContainer,
  BadgesContainer,
  AvatarWrapper,
  SocialIconsStack,
  AbsoluteSocialIconsStack,
  ContainerBox,
  TooltipContentBox,
  CountBadgeContainer,
  StyledBadge,
  TooltipTypography,
  SocialIconButton,
  BusinessIconStyled,
  CompanyStackContainer,
  TagsStackContainer,
  TagsRowStack,
  TagChip,
  MoreTagsChip,
} from './DSCompanyAvatarAndDetails.styles';
import { useNavigate } from 'react-router';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import React from 'react';

interface DSCompanyAvatarAndDetailsProps {
  company: Company;
  primaryText?: string;
  avatarSize?: number;
  avatarOnly?: boolean;
  size?: 'default' | 'large';
  stacked?: boolean;
  count?: {
    members: number;
    contacts: number;
  };
  showMembersOnly?: boolean;
  searchTerm?: string;
  showSocialIcons?: boolean;
}

const simplifyUrl = (url: string): string => {
  try {
    // Remove protocol and www
    return url
      .replace(/^https?:\/\//, '')
      .replace(/^www\./, '')
      .replace(/\/$/, ''); // Remove trailing slash
  } catch (e) {
    return url;
  }
};

export const DSCompanyAvatarAndDetails = ({
  company,
  primaryText,
  avatarSize,
  count,
  size = 'default',
  stacked = false,
  showMembersOnly = false,
  searchTerm,
  showSocialIcons = true,
}: DSCompanyAvatarAndDetailsProps) => {
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const companyUrl = company.url;
  const computedAvatarSize = avatarSize ?? (size === 'large' ? 40 : 32);
  const navigate = useNavigate();
  const [imageError, setImageError] = React.useState(false);

  const badgeCount = count
    ? showMembersOnly
      ? count.members
      : count.members + count.contacts
    : 0;

  return (
    <CompanyContainer $size={size} $stacked={stacked}>
      <ContainerBox>
        <CompanyContentStack
          direction={stacked ? 'column' : 'row'}
          alignItems={stacked ? 'flex-start' : 'center'}
          spacing={size === 'large' ? 2 : 1}
        >
          {!stacked ? (
            <>
              <AvatarWrapper
                onClick={() =>
                  navigate(
                    `/organization/${organizationSlug}/decisionsite/${dealRoomId}/hub?company=${company.id}`,
                  )
                }
              >
                <DSTooltip
                  title={
                    <TooltipContentBox>
                      {company.tags && company.tags.length > 0 && (
                        <DSCompanyTags company={company} />
                      )}
                    </TooltipContentBox>
                  }
                  placement="right"
                  arrow
                >
                  <CompanyAvatar
                    $size={computedAvatarSize}
                    $hasError={imageError}
                    variant="rounded"
                    src={!imageError ? company?.logo || undefined : undefined}
                    onError={() => setImageError(true)}
                  >
                    {imageError ? (
                      <BusinessIconStyled size={computedAvatarSize} />
                    ) : (
                      company.name?.charAt(0).toUpperCase()
                    )}
                  </CompanyAvatar>
                </DSTooltip>
                {showSocialIcons && (
                  <AbsoluteSocialIconsStack direction="row" spacing={1}>
                    <DSCompanySocialsList company={company} />
                  </AbsoluteSocialIconsStack>
                )}
              </AvatarWrapper>

              <CompanyNameContainer
                sx={{
                  position: 'relative',
                  top: showSocialIcons ? '-.65rem' : undefined,
                }}
              >
                <CompanyName>{primaryText || company.name}</CompanyName>
              </CompanyNameContainer>

              <BadgesContainer />
            </>
          ) : (
            <CompanyStackContainer spacing={0.5} alignItems="flex-start">
              <AvatarWrapper
                onClick={() =>
                  navigate(
                    `/organization/${organizationSlug}/decisionsite/${dealRoomId}/hub?company=${company.id}`,
                  )
                }
              >
                <DSTooltip
                  title={
                    <TooltipContentBox>
                      {company.tags && company.tags.length > 0 && (
                        <DSCompanyTags company={company} />
                      )}
                    </TooltipContentBox>
                  }
                  placement="right"
                  arrow
                >
                  <CompanyAvatar
                    $size={computedAvatarSize}
                    $hasError={imageError}
                    variant="rounded"
                    src={!imageError ? company?.logo || undefined : undefined}
                    onError={() => setImageError(true)}
                  >
                    {imageError ? (
                      <BusinessIconStyled size={computedAvatarSize} />
                    ) : (
                      company.name?.charAt(0).toUpperCase()
                    )}
                  </CompanyAvatar>
                </DSTooltip>
              </AvatarWrapper>

              <CompanyNameContainer>
                <CompanyName>{primaryText || company.name}</CompanyName>
              </CompanyNameContainer>

              {showSocialIcons && (
                <SocialIconsStack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                >
                  <DSCompanySocialsList company={company} />
                </SocialIconsStack>
              )}

              <BadgesContainer />
            </CompanyStackContainer>
          )}
        </CompanyContentStack>
        {count && badgeCount > 0 && (
          <CountBadgeContainer>
            <DSTooltip
              title={
                <TooltipContentBox>
                  {searchTerm ? (
                    <>
                      {count.members > 0 && (
                        <TooltipTypography variant="caption">
                          {count.members}{' '}
                          {count.members === 1 ? 'Member' : 'Members'} matching
                          "{searchTerm}"
                        </TooltipTypography>
                      )}
                      {!showMembersOnly && count.contacts > 0 && (
                        <TooltipTypography variant="caption">
                          {count.contacts}{' '}
                          {count.contacts === 1 ? 'Contact' : 'Contacts'}{' '}
                          matching "{searchTerm}"
                        </TooltipTypography>
                      )}
                    </>
                  ) : (
                    <>
                      {count.members > 0 && (
                        <TooltipTypography variant="caption">
                          {count.members}{' '}
                          {count.members === 1 ? 'Member' : 'Members'}
                        </TooltipTypography>
                      )}
                      {!showMembersOnly && count.contacts > 0 && (
                        <TooltipTypography variant="caption">
                          {count.contacts}{' '}
                          {count.contacts === 1 ? 'Contact' : 'Contacts'}
                        </TooltipTypography>
                      )}
                    </>
                  )}
                </TooltipContentBox>
              }
              placement="top"
              arrow
            >
              <StyledBadge badgeContent={badgeCount} color="secondary" />
            </DSTooltip>
          </CountBadgeContainer>
        )}
      </ContainerBox>
    </CompanyContainer>
  );
};

const DSCompanySocialsList = ({ company }: { company: Company }) => {
  const socials = OmitUndefinedValues({
    website: company.url ?? undefined,
    linkedIn: company.linkedin_handle ?? undefined,
    twitter: company.twitter_handle ?? undefined,
    facebook: company.facebook_handle ?? undefined,
    crunchbase: company.crunchbase_handle ?? undefined,
  });

  return (
    <Stack direction="row" spacing={0}>
      {Object.entries(socials).map(([platform, handle]) => {
        let icon;
        let url;
        switch (platform) {
          case 'website':
            icon = <HomeIcon fontSize="small" />;
            url = handle;
            break;

          case 'linkedIn':
            icon = <LinkedInIcon fontSize="small" />;
            url = `https://linkedin.com/in/${handle}`;
            break;
          case 'twitter':
            icon = <TwitterIcon fontSize="small" />;
            url = `https://twitter.com/${handle}`;
            break;
          case 'facebook':
            icon = <FacebookIcon fontSize="small" />;
            url = `https://facebook.com/${handle}`;
            break;
          case 'crunchbase':
            // BITODO - show the real crunchbase logo here
            icon = <BusinessIcon fontSize="small" />;
            url = `https://crunchbase.com/${handle}`;
            break;
          default:
            return null;
        }

        return (
          <Link
            key={platform}
            href={url}
            target="_blank"
            rel="noopener"
            onClick={(e) => e.stopPropagation()}
            style={{ textDecoration: 'none' }}
          >
            <SocialIconButton size="small" onClick={(e) => e.stopPropagation()}>
              {icon}
            </SocialIconButton>
          </Link>
        );
      })}
    </Stack>
  );
};
const NUM_VISIBLE_TAGS = 8;

const DSCompanyTags = ({ company }: { company: Company }) => {
  // BITODO - Generate a consistent color based on the category of the tag - need to get tag values from BigPicture
  // and actually do the mapping manually.
  const getTagColor = (tag: string) => {
    const colors = [
      { bg: '#E3F2FD', text: '#0D47A1' }, // Blue
      { bg: '#E8F5E9', text: '#1B5E20' }, // Green
      { bg: '#FFF3E0', text: '#E65100' }, // Orange
      { bg: '#F3E5F5', text: '#4A148C' }, // Purple
      { bg: '#E0F7FA', text: '#006064' }, // Cyan
      { bg: '#FBE9E7', text: '#BF360C' }, // Deep Orange
    ];

    // Generate a simple hash of the tag to get a consistent color
    let hash = 0;
    for (let i = 0; i < tag.length; i++) {
      hash = tag.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return colors[hash % colors.length];
  };

  const tags = company.tags ?? [];
  const visibleTags = tags.slice(0, NUM_VISIBLE_TAGS);
  const remainingCount = Math.max(0, tags.length - NUM_VISIBLE_TAGS);

  if (!tags.length) return null;

  return (
    <TagsStackContainer>
      <TagsRowStack direction="row" gap=".5rem">
        {visibleTags.map((tag) => {
          const color = getTagColor(tag);
          return (
            <TagChip
              key={tag}
              label={tag}
              size="small"
              $bgColor={color.bg}
              $textColor={color.text}
            />
          );
        })}
        {remainingCount > 0 && (
          <DSTooltip
            title={
              <Stack spacing={0.5}>
                {tags.slice(3).map((tag) => (
                  <Typography key={tag} variant="body2">
                    {tag}
                  </Typography>
                ))}
              </Stack>
            }
          >
            <Chip
              size="small"
              label={`+${remainingCount}`}
              sx={{
                backgroundColor: '#F5F5F5',
                color: '#666666',
                border: 'none',
                fontWeight: 500,
                flexShrink: 0,
              }}
            />
          </DSTooltip>
        )}
      </TagsRowStack>
    </TagsStackContainer>
  );
};
