import { ReactNode, useState } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import Edit from '@mui/icons-material/Edit';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import DSDetail from './DSDetail';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { OrganizationDealRoomsQuery } from '../../../../QueryNames';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { styled, Avatar, Box } from '@mui/material';
import { DSLogoManagementModal } from '../../Sidebar/DSLogoManagementModal';

// Styled components for the logo display
const LogoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 32,
  height: 32,
  '&:hover .edit-overlay': {
    opacity: 0.7,
    visibility: 'visible',
  },
}));

const LogoEditOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '32px',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  borderRadius: '.25rem',
  opacity: 0,
  visibility: 'hidden',
  cursor: 'pointer',
  transition: 'opacity 0.2s, visibility 0.2s',
}));

const EditIconStyled = styled('div')(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: 'white',
    fontSize: 16,
  },
}));

const CompanyLogo = styled(Avatar)(({ theme }) => ({
  width: 32,
  height: 32,
  marginRight: '.5rem',
  variant: 'rounded',
  cursor: 'pointer',
}));

interface DSDetailDecisionSiteNameProps {
  onLogoUpdate?: () => Promise<void>;
  editable?: boolean;
}

export const DSDetailDecisionSiteName = ({
  onLogoUpdate,
  editable = true,
}: DSDetailDecisionSiteNameProps) => {
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { dealRoom, refetch: refetchDealRoom } = useDealRoom();
  const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);
  
  // Handle logo click to open modal
  const handleLogoClick = () => {
    setIsLogoModalOpen(true);
  };
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  
  // Handle logo update
  const handleLogoUpdate = async () => {
    await refetchDealRoom();
  };

  // Handle deal room name update
  const handleDealRoomNameUpdate = async (newName: string) => {
    if (!organizationSlug || !dealRoomId || !newName) return;

    try {
      const token = await getAccessTokenSilently();

      await toast.promise(
        DealRoomsApiClient.updateDealRoom(
          organizationSlug,
          dealRoomId,
          { name: newName },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        ),
        {
          loading: 'Updating Decision Site name...',
          success: 'Decision Site name updated successfully',
          error: 'Failed to update Decision Site name',
        },
      );

      await refetchDealRoom();
      // Also refresh the global sidebar's deal rooms list
      await queryClient.invalidateQueries([OrganizationDealRoomsQuery]);
    } catch (error) {
      console.error('Error updating Decision Site name:', error);
    }
  };

  // Render the icon based on whether a logo URL exists
  const renderIcon = (): ReactNode => {
    if (dealRoom?.buyerLogoUrl) {
      return (
        <LogoContainer>
          <CompanyLogo
            variant="rounded"
            src={dealRoom.buyerLogoUrl}
            alt={dealRoom.name || 'Company Logo'}
            onClick={editable ? handleLogoClick : undefined}
            sx={{ cursor: editable ? 'pointer' : 'default' }}
          />
          {editable && (
            <LogoEditOverlay
              className="edit-overlay"
              onClick={handleLogoClick}
            >
              <EditIconStyled>
                <Edit />
              </EditIconStyled>
            </LogoEditOverlay>
          )}
        </LogoContainer>
      );
    } else {
      return (
        <BusinessIcon
          sx={{ fontSize: '20px', color: DEALROOMS_COLORS.cloudburst }}
        />
      );
    }
  };

  return (
    <>
      <DSLogoManagementModal
        open={isLogoModalOpen}
        onDismiss={() => setIsLogoModalOpen(false)}
        organizationSlug={organizationSlug || ''}
        dealRoomId={dealRoomId || 0}
        buyerLogoUrl={dealRoom?.buyerLogoUrl || undefined}
        onLogoUpdate={handleLogoUpdate}
      />

      <DSDetail
        label="Decision Site"
        value={dealRoom?.name || ''}
        icon={renderIcon()}
        editable={editable}
        onSave={handleDealRoomNameUpdate}
      />
    </>
  );
};

export default DSDetailDecisionSiteName;
