/**
 * Custom hook for managing call recording status in the Meetingflow system.
 * Provides real-time tracking of recording state and integrates with organization-level
 * entitlements to control access to recording features.
 *
 * Key features:
 * - Automatic status polling with react-query
 * - Organization-level entitlement checks
 * - Auth0 integration for secure API calls
 * - Local state management for recording status
 */

import { useEffect, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '../../../../../Hooks/useOrganization';
import { MeetingPlanCallRecorderStatusQuery } from '../../../../../QueryNames';
import { MeetingflowsApiClient } from '../../../../../Services/NetworkCommon';
import {
  CallRecordingStatus,
  GetCallRecordingStatusData,
} from '@meetingflow/common/Api/data-contracts';

/**
 * Props for the useMeetingflowRecorderStatus hook
 * @property meetingPlanId - Unique identifier for the meeting plan
 * @property meetingPlanCallRecordingId - ID of the specific recording session, required for status tracking
 * @property meetingPlanCallRecordingLastStatus - Previous known status, used to detect changes
 */
interface UseMeetingflowRecorderStatusProps {
  meetingPlanId: string | undefined;
  meetingPlanCallRecordingId?: string | null;
  meetingPlanCallRecordingLastStatus?: string | null;
}

export const useMeetingflowRecorderStatus = ({
  meetingPlanId,
  meetingPlanCallRecordingId,
  meetingPlanCallRecordingLastStatus,
}: UseMeetingflowRecorderStatusProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { organization, hasEntitlement } = useOrganization();

  // Internal state to track recording status changes
  const [recorderStatus, setRecorderStatus] =
    useState<CallRecordingStatus | null>(null);

  // Fetch and maintain recording status using react-query
  // This automatically handles caching and periodic refetching
  const {
    data: callRecorderStatusResponse,
    isLoading: callRecorderStatusLoading,
    refetch: refetchCallRecorderStatus,
  } = useQuery<GetCallRecordingStatusData>(
    MeetingPlanCallRecorderStatusQuery(
      organization?.slug || '',
      meetingPlanId || '',
    ),
    async () => {
      if (!organization?.slug || !meetingPlanId) {
        throw new Error(
          'Missing required parameters: organization slug or meeting plan ID',
        );
      }
      const token = await getAccessTokenSilently();
      const response = await MeetingflowsApiClient.getCallRecordingStatus(
        organization.slug,
        meetingPlanId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    },
    {
      // Query is only enabled when we have all required data and proper entitlements
      enabled:
        !!organization?.slug &&
        !!meetingPlanId &&
        hasEntitlement('CALL_RECORDING') &&
        // Only check status if we have a recording ID or were previously recording
        (!!meetingPlanCallRecordingId ||
          meetingPlanCallRecordingLastStatus === 'in_call_recording'),
      // Polling configuration for active recordings
      refetchInterval: () => {
        if (meetingPlanCallRecordingLastStatus !== 'in_call_recording') {
          return false;
        }
        return 3000; // Poll every 3 seconds during active recording
      },
      // Add caching to prevent unnecessary refetches
      staleTime: 3000, // Consider data fresh for 3 seconds
      cacheTime: 60000, // Keep in cache for 1 minute
    },
  );

  // Unwrap the response data for easier access
  const callRecorderStatus = callRecorderStatusResponse;

  // Update recorder status when response changes
  useEffect(() => {
    if (callRecorderStatus?.code) {
      setRecorderStatus(callRecorderStatus.code);
    }
  }, [callRecorderStatus]);

  // Trigger a refetch when recording details or organization context changes
  useEffect(() => {
    if (
      !!organization?.slug &&
      !!meetingPlanId &&
      !!meetingPlanCallRecordingId &&
      hasEntitlement('CALL_RECORDING')
    ) {
      refetchCallRecorderStatus();
    }
  }, [
    meetingPlanCallRecordingId,
    meetingPlanCallRecordingLastStatus,
    meetingPlanId,
    organization?.slug,
    refetchCallRecorderStatus,
    hasEntitlement,
  ]);

  return {
    recorderStatus,
    isLoading: callRecorderStatusLoading,
    refetch: refetchCallRecorderStatus as () => Promise<
      UseQueryResult<GetCallRecordingStatusData>
    >,
    callRecorderStatus:
      callRecorderStatus || ({} as GetCallRecordingStatusData),
  };
};
