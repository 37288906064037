import { DSConfigurationHeaderRow } from '../Components/DSConfigurationHeaderRow';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { UserDetails } from '../../../../types/BuyerOrientationSurveyTypes';
import { DealRoomFirstTimeUserExperienceInner } from '../../DealRoomFirstTimeUserExperience';
import { useState, useCallback, useMemo } from 'react';
import { PrimaryButton, Stack } from '@fluentui/react';
import toast from 'react-hot-toast';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import useDeviceType from '../../../../Hooks/deviceDetection';
import { useOrganization } from '../../../../Hooks/useOrganization';

export const DSConfigurationFirstTimeUserExperience = () => {
  const { isMobile } = useDeviceType();

  const [showExperience, setShowExperience] = useState(false);
  const { user } = useUserProfile();
  const { dealRoom } = useDealRoom();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { isAdmin } = useOrganization(organizationSlug);

  // Create mock user details for admin to simulate first-time user experience
  const createDummyUserDetails = useCallback(() => {
    if (!user || !user.email) return null;
    const now = new Date().toISOString();
    const adminUserDetails = {
      // User identification
      email: user.email,
      name: user.name || 'Admin User',
      role: 'SELLER',
      userId: user.id || 0,
      // First-time experience flags
      hasWatchedWelcomeVideo: false,
      hasCompletedBuyerOrientation: false,
      // Required BasicContact fields
      id: 0,
      emailDomain: user.email.split('@')[1] || '',
      firstMet: now,
      lastMet: now,
      lastMeetingDate: now,
      nextMeetingDate: null,
      timesMet30d: 0,
      timesMet90d: 0,
      avatarFileUrl: null,
      avatarUrl: null,
      linkedInUrl: null,
      openTasks: 0,
      statsUpdated: now,
      // Additional UserDetails fields
      assistancePreference: null,
      consumptionPreference: null,
      communicationPreference: null,
      phoneNumber: null,
      priorities: null,
      otherPriorityText: null,
      isAdmin: true,
    };
    return adminUserDetails as UserDetails;
  }, [user]);

  // Find current user's details from deal room contacts
  const currentUserDetails = useMemo(() => {
    if (!dealRoom) return null;
    if (!user || !user.email) return null;
    if (!Array.isArray(dealRoom.contacts)) return null;

    const contact = dealRoom.contacts.find(
      (contact) => contact.email.toLowerCase() === user.email.toLowerCase(),
    );

    if (!contact && !isAdmin) {
      toast.error(
        `Insufficient privileges: You must be a Decision Site contact or have organization admin permissions to access the first-time user experience.
                 Please ask the Decision Site owner to invite you as a contact.`,
      );
      return null;
    }

    if (!contact && isAdmin) {
      // Create dummy user details for admin in order to be able to display first-time user experience
      const adminUserDetails = createDummyUserDetails();
      return adminUserDetails as UserDetails;
    }

    return (contact as UserDetails) || null;
  }, [dealRoom, user, isAdmin, createDummyUserDetails]);

  const dealRoomOwner = useMemo(() => {
    if (!dealRoom) return null;
    return dealRoom.owner;
  }, [dealRoom]);

  // Get list of deal room contacts excluding current user
  const dealRoomContacts = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return [];
    if (!user) return [];

    return dealRoom.contacts
      .filter(
        (contact) =>
          contact.userId !== user.id && contact.email !== user?.email,
      )
      .map((contact) => contact.name || 'N/A');
  }, [dealRoom?.contacts, user]);

  if (!organizationSlug || !dealRoomId) return null;
  if (!user || !dealRoom) return null;
  if (!dealRoomOwner) return null;

  const stackStyles = {
    root: {
      padding: isMobile ? '1rem' : '0',
      display: 'flex',
      flexFlow: isMobile ? 'column wrap' : 'row wrap',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      button: {
        borderRadius: '4px',
        padding: '0.3rem',
      },
      '> *': {
        margin: '0 !important',
      },
    },
  };

  return (
    <DSConfigurationHeaderRow description="See what your buyers will see when they first enter your Decision Site.">
      <Stack
        horizontal
        horizontalAlign="end"
        tokens={{ childrenGap: '10px' }}
        styles={stackStyles}
      >
        <PrimaryButton
          text="Show First Time User Experience"
          onClick={() => setShowExperience(true)}
        />

        {showExperience && (
          <DealRoomFirstTimeUserExperienceInner
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            dealRoomOwner={dealRoomOwner}
            dealRoomContacts={dealRoomContacts}
            currentUser={user}
            currentUserDetails={currentUserDetails}
            displayWelcomeVideo={true}
            displayBuyerOrientation={true}
            setHasWatchedWelcomeVideo={async () => {}}
            setHasCompletedBuyerOrientation={async () => {
              setShowExperience(false);
            }}
            isConfigurationTabActive={true}
          />
        )}
      </Stack>
    </DSConfigurationHeaderRow>
  );
};
