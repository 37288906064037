import { Stack, Text } from '@fluentui/react';
import {
  CalendarMonth as CalendarIcon,
  Videocam as VideoIcon,
  MoreVert as MoreIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { CalendarEvent } from '@meetingflow/common/Api/data-contracts';
import MeetIcon from '../../../../Static/Images/meet.png';
import SkypeForBusinessIcon from '../../../../Static/Images/sfb.png';
import SkypeIcon from '../../../../Static/Images/skype.png';
import WebExIcon from '../../../../Static/Images/webex.png';
import ZoomIcon from '../../../../Static/Images/zoom.png';
import { ActionButton } from './DSMeetingsCalendarEventRow.styles';

const VideoConferenceIcon = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > svg': {
    fontSize: '16px',
  },
}));

const DISPLAY_NAMES: Record<
  NonNullable<CalendarEvent['conferenceInfo']>['type'],
  string
> = {
  msTeams: 'Teams',
  zoom: 'Zoom',
  googleMeet: 'Meet',
  skypeForBusiness: 'Skype',
  skype: 'Skype',
  ciscoWebEx: 'WebEx',
};

interface Props {
  event: CalendarEvent;
}

export const DSMeetingJoinButton = ({ event }: Props) => {
  const iconSrc = useMemo(() => {
    if (!event.conferenceInfo?.type) {
      return;
    }

    switch (event.conferenceInfo.type) {
      case 'zoom':
        return ZoomIcon;
      case 'googleMeet':
        return MeetIcon;
      case 'skypeForBusiness':
        return SkypeForBusinessIcon;
      case 'skype':
        return SkypeIcon;
      case 'msTeams':
        return 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/teams_48x1.svg';
      case 'ciscoWebEx':
        return WebExIcon;
      default:
        return;
    }
  }, [event.conferenceInfo?.type]);

  if (!event.conferenceInfo?.joinUri) {
    return null;
  }

  return (
    <div>
      <ActionButton
        hoverBackgroundColor={DEALROOMS_COLORS.themePrimary}
        color={DEALROOMS_COLORS.white}
        backgroundColor={DEALROOMS_COLORS.themeSecondary}
        onClick={() => window.open(event.conferenceInfo!.joinUri, '_blank')}
      >
        <VideoConferenceIcon>
          <VideoIcon />
        </VideoConferenceIcon>
        Join {DISPLAY_NAMES[event.conferenceInfo.type]} Meeting
      </ActionButton>
    </div>
  );
};
