import {
  styled,
  Box,
  TableContainer,
  Typography,
  TextFieldProps,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { ElementType } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const Container = styled('div')({
  width: '100%',
  height: 'calc(100% - 2rem)',
  overflow: 'auto',
});

export const ArtifactLink = styled(Link)({
  color: 'inherit',
  fontWeight: '500',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const HighlightedText = styled('span')({
  color: 'inherit',
  fontWeight: '500',
});

export const ActivityIcon = styled('span')({
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: '8px',
});

// Using a generic type parameter for the styled component
export const CompactAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%', // Allow full width on smaller screens
  '& .MuiOutlinedInput-root': {
    padding: '2px 8px',
  },
  '& .MuiInputBase-input': {
    fontSize: '0.875rem',
    padding: '4px 0',
  },
  '& input::placeholder': {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiAutocomplete-tag': {
    margin: '1px',
    height: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '& .MuiChip-label': {
      fontSize: '0.75rem',
      padding: '0 6px',
      color: 'rgba(0, 0, 0, 0.7)',
    },
    '& .MuiChip-deleteIcon': {
      fontSize: '0.75rem',
      margin: '0 2px 0 -4px',
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '100%', // Full width on mobile
  },
}));

export const ActivityTableContainer = styled(TableContainer)({
  backgroundColor: 'white',
  borderRadius: '.25rem',
  '& .MuiAutocomplete-tag': {
    margin: '1px',
    height: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '& .MuiChip-label': {
      fontSize: '0.75rem',
      padding: '0 6px',
      color: 'rgba(0, 0, 0, 0.7)',
    },
    '& .MuiChip-deleteIcon': {
      fontSize: '0.75rem',
      margin: '0 2px 0 -4px',
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },
  // Ensure recharts tooltips are always on top
  '& .recharts-tooltip-wrapper': {
    zIndex: 1000,
  },
});

export const CollapsibleTableContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  overflow: 'auto',
  transition: 'max-height 0.3s ease',
  minHeight: expanded ? '200px' : '0px',
  maxHeight: expanded ? 'calc(100vh - 438px)' : '0px',
  opacity: expanded ? 1 : 0,
  visibility: expanded ? 'visible' : 'hidden',
}));

export const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
});

// Use theme.unstable_sx to exactly match sx prop behavior
export const FilterContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded?: boolean }>(({ theme, expanded }) =>
  theme.unstable_sx({
    display: 'flex',
    flexWrap: { xs: 'wrap', lg: 'nowrap' }, // Allow wrapping on xs, sm, md screens
    flexDirection: { xs: 'column', lg: 'row' }, // Stack vertically on xs, sm, md screens, horizontal only on lg and up
    gap: 1,
    p: 1,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    overflowX: { xs: 'visible', lg: 'visible' },
    alignItems: { xs: 'flex-start', lg: 'center' },
    justifyContent: { lg: 'space-between' },
    backgroundColor: `#f9f9f9`,
  }),
);

// Define the props for the DatePicker's TextField to ensure exact styling
// Using proper type to ensure compatibility with DatePicker slot props
export const compactDatePickerTextFieldProps: TextFieldProps = {
  size: 'small' as const, // Use const assertion to narrow the type
  sx: {
    width: { xs: '100%', lg: 130 }, // Full width on xs, sm, md screens, fixed width on lg and up
    minWidth: { xs: '100%', lg: 130 }, // Ensure minimum width on desktop
    '& .MuiOutlinedInput-root': { padding: '2px 8px' },
    '& .MuiInputBase-input': {
      fontSize: '0.875rem',
      padding: '4px 0',
    },
    '& .MuiInputLabel-root': { fontSize: '0.875rem' },
  },
};

// Container for buttons with right alignment on desktop
export const ButtonContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    ml: { xs: 0, md: 'auto' },
    button: {
      height: '36px !important',
      maxHeight: '36px !important',
    },
  }),
);

// Flexible container with hidden overflow
export const FlexibleContainer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    flex: 1,
    overflow: 'hidden',
  }),
);
