import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { ImageWithFallback } from '../../../Components/Common/ImageWithFallback';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';

export type DocumentArtifactCardProps = Omit<BaseArtifactCardProps, 'artifact'> & {
  artifact: DealRoomArtifact;
};

export const DocumentArtifactCard = ({
  artifact,
  ...rest
}: DocumentArtifactCardProps) => {
  // Safe access to properties that might not exist on all artifact types
  const thumbnailUrl = artifact && 'thumbnailUrl' in artifact ? artifact.thumbnailUrl : undefined;
  const mimeType = artifact && 'mimeType' in artifact ? artifact.mimeType : '';

  return (
    <BaseArtifactCard
      className="document-artifact"
      artifact={artifact}
      {...rest}
    >
      <ImageWithFallback
        src={thumbnailUrl || undefined}
        fallback={<ArtifactIconPreview type="DOCUMENT" mimeType={mimeType} />}
      />
    </BaseArtifactCard>
  );
};
