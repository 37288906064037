import { mergeStyles } from '@fluentui/react';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';

export type ImageArtifactCardProps = Omit<BaseArtifactCardProps, 'artifact'> & {
  artifact: DealRoomArtifact;
};

export const ImageArtifactCard = ({
  artifact,
  ...rest
}: ImageArtifactCardProps) => {
  const imageStyles = mergeStyles({
    width: '100%',
    objectFit: 'contain',
  });

  // Safe access to properties that might not exist on all artifact types
  const fileName = artifact && 'fileName' in artifact ? artifact.fileName : '';
  const fileUrl = artifact && 'fileUrl' in artifact ? artifact.fileUrl : undefined;
  const thumbnailUrl = artifact && 'thumbnailUrl' in artifact ? artifact.thumbnailUrl : undefined;
  const mimeType = artifact && 'mimeType' in artifact ? artifact.mimeType : '';

  return (
    <BaseArtifactCard
      className="image-artifact"
      artifact={artifact}
      {...rest}
    >
      <ImageWithFallback
        src={thumbnailUrl || fileUrl}
        fallbackSrc={thumbnailUrl ? fileUrl : undefined}
        alt={fileName}
        className={imageStyles}
        fallback={
          <ArtifactIconPreview
            type="IMAGE"
            fileExtension={fileName?.split('.')?.pop() || ''}
            mimeType={mimeType}
          />
        }
      />
    </BaseArtifactCard>
  );
};
