import { DealRole } from '@meetingflow/common/Api/data-contracts';

export const isBuyer = (userRole: DealRole | null | undefined) => {
  const buyerRoles = [
    'BUYER',
    'BUYER_ADMIN',
    'BUYER_CONTRIBUTOR',
    'BUYER_REVIEWER',
  ];
  return buyerRoles.includes(userRole || '');
};

export const validUploadImageTypes = [
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/png',
];

// Regular expression to validate LinkedIn URL
export const linkedInUrlRegex =
  /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[\w-]{5,100}\/?$/;

// Regular expression to validate URL format
// Allows optional http(s):// prefix, domain with subdomains, and optional path
export const scheduleMeetingUrlRegex =
  /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;

export const hasValidFileExtension = (file: File): boolean => {
  return file?.name?.lastIndexOf('.') > 0;
};
const mimeTypes: { [key: string]: string } = {
  // Images
  gif: 'image/gif',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  webp: 'image/webp',
  svg: 'image/svg+xml',
  tiff: 'image/tiff',
  bmp: 'image/bmp',
  // Documents
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  odt: 'application/vnd.oasis.opendocument.text',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odp: 'application/vnd.oasis.opendocument.presentation',
  txt: 'text/plain',
  rtf: 'application/rtf',
  // Videos
  mp4: 'video/mp4',
  avi: 'video/x-msvideo',
  mov: 'video/quicktime',
  wmv: 'video/x-ms-wmv',
  flv: 'video/x-flv',
  webm: 'video/webm',
  mkv: 'video/x-matroska',
  // Audio
  mp3: 'audio/mpeg',
  wav: 'audio/wav',
  ogg: 'audio/ogg',
  m4a: 'audio/mp4',
  // Additional types
  gz: 'application/gzip',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  eot: 'application/vnd.ms-fontobject',
  pptm: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  '7z': 'application/x-7z-compressed',
  bz: 'application/x-bzip',
  rar: 'application/x-rar-compressed',
  tar: 'application/x-tar',
  zip: 'application/zip',
  zlib: 'application/zlib',
  woff: 'font/woff',
  woff2: 'font/woff2',
  mpg: 'video/mpeg',
  mpeg: 'video/mpeg',
};

/**
 * Determines and assigns the correct MIME type for a given file.
 * If the file already has a type, it's returned as-is.
 * For files without a type, it attempts to infer the MIME type from the file extension.
 *
 * @param file - The File object to process
 * @returns A new File object with the correct MIME type, or the original file if type can't be determined
 */
export const getFileWithMimeType = (file: File): File => {
  // If the file already has a type, return it unchanged
  if (file.type) return file;

  // Extract the file extension (if any) and convert to lowercase
  const extension = file.name.split('.').pop()?.toLowerCase();
  // If no extension is found, return the original file
  if (!extension) return file;

  // Look up the MIME type based on the extension, defaulting to 'application/octet-stream' if not found
  const mimeType = mimeTypes[extension] || 'application/octet-stream';

  // Create and return a new File object with the determined MIME type
  return new File([file], file.name, {
    type: mimeType,
    lastModified: file.lastModified,
  });
};

// List of common non-work email domains
// These are domains commonly associated with personal email accounts rather than work accounts
export const NON_WORK_DOMAINS = [
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'protonmail.com',
  'zoho.com',
  'yandex.com',
];

// This pattern allows for a wide range of valid email addresses, but may not be exhaustive
// It is intended to be a sanity check for the domain check below
export const emailPattern =
  /^[a-zA-Z0-9._%+-]{2,}@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,}$/;

// Checks if an email address is a valid work email
export const isWorkEmail = (email: string) => {
  // Empty strings are not valid work emails
  if (!email) return false;
  // If the email address does not match the sanity check pattern, it's not a valid work email
  if (!emailPattern.test(email)) return false;
  const domain = email.split('@')[1].toLowerCase();
  // If the domain is on the list of non-work domains, it's not a valid work email
  return !NON_WORK_DOMAINS.includes(domain);
};
