import {
  DealRoom,
  DetailedDealRoom,
  Organization,
} from '@meetingflow/common/Api/data-contracts';
import { useMemo } from 'react';

import { useUserProfile } from '../../../Hooks/useProfile';
import { isBuyer } from '../../../utils/buyerDecisionSiteUtils';

import { DealRoomUserPrioritiesContainer } from '../Components/DealRoomUserPrioritiesContainer';
import { DSCalloutCards } from '../Components/DSCalloutCards';

import { useTitle } from '../../../Hooks/useTitle';
import { DealRoomKeyFacts } from '../Components/InfoSection/DealRoomKeyFacts';
import { useAuth0 } from '@auth0/auth0-react';
import { useCalloutCards } from '../../../Hooks/useCalloutCards';
import { Add, EmojiPeople, ImportContacts } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { DSFeaturedArtifacts } from '../Components/DSFeaturedArtifacts';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { DSDecisionSiteDetails } from '../Analytics/components/DSDecisionSiteDetails';
import {
  OverviewContainer,
  OverviewHeader,
  OverviewContent,
  ContentMain,
  ContentSidebar,
  AddArtifactButton,
} from './DealRoomBuyersOverviewTab.styles';
import { useWelcomeArtifact } from '../../../Hooks/useWelcomeArtifact';
import { InfoSection } from '../Components/InfoSection/InfoSection';
import { DSWelcomeArtifactCarousel } from '../Components/DSWelcomeArtifactCarousel';
import { useUploadDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useUploadDealRoomArtifactDialog';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';

type DealRoomBuyersOverviewTabProps = {
  className?: string;
  organizationSlug: Organization['slug'];
  dealRoomId: DealRoom['id'];
  dealRoom: DetailedDealRoom;
  refreshDealRoom: () => Promise<unknown>;
};

export const DealRoomBuyersOverviewTab = ({
  className,
  organizationSlug,
  dealRoomId,
  dealRoom,
  refreshDealRoom,
}: DealRoomBuyersOverviewTabProps) => {
  const { dealRoomRole } = useDealRoom();
  const { getAccessTokenSilently } = useAuth0();
  const isSeller = useMemo(() => dealRoomRole === 'SELLER', [dealRoomRole]);
  const { user } = useUserProfile();
  const name = user?.firstName || user?.name?.split(/\\s/)?.[0];

  useTitle('Overview');

  const { calloutCards } = useCalloutCards(organizationSlug, dealRoomId);
  const { welcomeVideo, refetch: refetchWelcomeArtifacts } = useWelcomeArtifact(
    { organizationSlug, dealRoomId },
  );

  const hasCalloutCards = useMemo(
    () => (calloutCards ? calloutCards.length > 0 : false),
    [calloutCards],
  );

  const hasWelcomeVideo = !!welcomeVideo;

  const hasFeaturedArtifacts = useMemo(
    () => dealRoom.artifacts?.some((a) => a.featuredAt) || false,
    [dealRoom.artifacts],
  );

  const welcomeArtifacts = useMemo(
    () =>
      dealRoom.artifacts
        ?.filter((a) => a.placement === 'WELCOME')
        .sort(
          (a, b) =>
            new Date(b.addedToWelcomeAt || b.createdAt).getTime() -
            new Date(a.addedToWelcomeAt || a.createdAt).getTime(),
        ),
    [dealRoom.artifacts],
  );

  const hasWelcomeArtifacts = useMemo(
    () => welcomeArtifacts?.length > 0 || false,
    [welcomeArtifacts],
  );

  const showWelcomeSection = useMemo(() => {
    return hasWelcomeArtifacts || isSeller;
  }, [hasWelcomeArtifacts, isSeller]);

  const showBuyerPriorities = useMemo(() => {
    const currentUserDetails = dealRoom?.contacts?.find(
      (contact) => contact.email.toLowerCase() === user?.email?.toLowerCase(),
    );
    if (!currentUserDetails) return false;

    return isBuyer(currentUserDetails.role);
  }, [dealRoom?.contacts, user?.email]);

  const {
    createDeferred: showAddDealRoomArtifactDialog,
    dialog: addDealRoomArtifactDialog,
  } = useUploadDealRoomArtifactDialog({
    organizationSlug,
    dealRoomId,
  });

  const handleAddWelcomeArtifact = async () => {
    try {
      const result = await showAddDealRoomArtifactDialog().promise;
      if (result) {
        // Add the artifact to the welcome section using makeWelcomeArtifact
        const token = await getAccessTokenSilently();
        await DealRoomsApiClient.makeWelcomeArtifact(
          organizationSlug,
          dealRoomId,
          result.id,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        // Refresh the deal room to show the new welcome artifact
        await refreshDealRoom();
        // Also explicitly refetch welcome artifacts to ensure the UI updates
        await refetchWelcomeArtifacts();
        toast.success(
          'Successfully added new artifact to the welcome section.',
        );
      }
    } catch (error) {
      // Ignore synthetic events and cancellation
      if (
        (error && typeof error === 'object' && '_reactName' in error) ||
        error === 'Cancelled' ||
        error === undefined
      ) {
        return;
      }
      toast.error('Failed to add new artifact to welcome section.');
      console.error(error);
    }
  };

  const handleAddFeaturedArtifact = async () => {
    try {
      const result = await showAddDealRoomArtifactDialog().promise;
      if (result) {
        // Add the artifact to the featured section using featureArtifact
        const token = await getAccessTokenSilently();
        await DealRoomsApiClient.featureArtifact(
          {
            organizationSlug,
            dealRoomId,
            artifactId: result.id,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        // Refresh the deal room to show the new featured artifact
        await refreshDealRoom();
        toast.success(
          'Successfully added new artifact to the featured section.',
        );
      }
    } catch (error) {
      // Ignore synthetic events and cancellation
      if (
        (error && typeof error === 'object' && '_reactName' in error) ||
        error === 'Cancelled' ||
        error === undefined
      ) {
        return;
      }
      toast.error('Failed to add new artifact to featured section.');
      console.error(error);
    }
  };

  return (
    <OverviewContainer className="overview">
      <OverviewHeader className="overview-header">
        {showBuyerPriorities && (
          <DealRoomUserPrioritiesContainer
            dealRoomId={dealRoomId}
            organizationSlug={organizationSlug}
          />
        )}
        <DSDecisionSiteDetails
          editable={false}
          version="BUYER"
          sx={{
            position: 'relative',
            top: '0',
            marginBottom: '2rem',
          }}
        />
        {showWelcomeSection && (
          <InfoSection
            icon={<EmojiPeople fontSize="medium" color="secondary" />}
            title={
              <>
                Welcome, {name}!
                {isSeller && (
                  <AddArtifactButton
                    onClick={handleAddWelcomeArtifact}
                    size="small"
                    variant="text"
                    color="secondary"
                    startIcon={<Add fontSize="small" />}
                  >
                    Add
                  </AddArtifactButton>
                )}
              </>
            }
            sx={{
              borderRadius: '.25rem',
              marginBottom: '2rem',
            }}
            contentSx={{
              paddingTop: '.5rem',
              paddingBottom: '.5rem',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              overflow: 'hidden',
              backgroundColor: `rgba(0,0,0,0.05)`,
            }}
          >
            <DSWelcomeArtifactCarousel
              artifacts={welcomeArtifacts || []}
              ownerName={dealRoom.owner?.name || dealRoom.owner?.firstName}
            />
          </InfoSection>
        )}
      </OverviewHeader>
      <OverviewContent className="overview-content">
        <ContentMain className="content-main">
          <DealRoomKeyFacts />
          {(hasFeaturedArtifacts || isSeller) && (
            <InfoSection
              icon={<ImportContacts fontSize="medium" color="secondary" />}
              title={
                <>
                  Featured Artifacts
                  {isSeller && (
                    <AddArtifactButton
                      onClick={handleAddFeaturedArtifact}
                      size="small"
                      variant="text"
                      color="secondary"
                      startIcon={<Add fontSize="small" />}
                    >
                      Add
                    </AddArtifactButton>
                  )}
                </>
              }
            >
              <DSFeaturedArtifacts
                organizationSlug={organizationSlug}
                dealRoomId={dealRoomId}
                refreshDealRoom={refreshDealRoom}
                artifacts={dealRoom.artifacts}
                activity={dealRoom.activity}
              />
            </InfoSection>
          )}
        </ContentMain>
        {(hasCalloutCards || hasWelcomeVideo || isSeller) && (
          <ContentSidebar>
            <DSCalloutCards />
          </ContentSidebar>
        )}
      </OverviewContent>
      {addDealRoomArtifactDialog}
    </OverviewContainer>
  );
};
