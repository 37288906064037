import React from 'react';
import { Box } from '@mui/material';
import { DealRoomVideoArtifact } from '@meetingflow/common/Api/data-contracts';
import {
  StyledArtifactContainer,
  StyledVideoContainer,
  StyledContentContainer,
  StyledArtifactName,
  StyledArtifactDescription,
  StyledArtifactTimestamp,
  StyledArtifactVideo,
  StyledArtifactHeader,
  StyledActionsContainer,
} from './DSWelcomeArtifactCard.styles';
import { DSContactAvatar } from '../DS/DSContactAvatar';
import { DateTime } from 'luxon';
import { useNavigate as useRouterNavigate } from 'react-router-dom';
import { getArtifactLink } from '../utils/artifactUtils';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import { ArtifactsTableActions } from '../Artifacts/ArtifactsTableActions';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useQueryClient } from 'react-query';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from '../Artifacts/ArtifactIconPreview';

interface DSWelcomeVideoArtifactProps {
  videoArtifact: DealRoomVideoArtifact;
}

export const DSWelcomeVideoArtifact: React.FC<DSWelcomeVideoArtifactProps> = ({
  videoArtifact,
}) => {
  const navigate = useRouterNavigate();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { userId } = useUserProfile();
  const { dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);
  const queryClient = useQueryClient();

  if (!videoArtifact) return null;

  // Format date for display
  const formatDate = (dateString?: string) => {
    if (!dateString) return '';
    const date = DateTime.fromISO(dateString);
    return date.toFormat('MMM d, yyyy');
  };

  const handleClick = (e: React.MouseEvent) => {
    // Don't navigate if the click was on the video element or its controls
    if (
      e.target instanceof HTMLVideoElement ||
      (e.target instanceof HTMLElement && e.target.closest('video'))
    ) {
      return;
    }

    // Don't navigate if clicking on the actions menu
    if (
      e.target instanceof HTMLElement &&
      (e.target.closest('.artifacts-actions-menu') ||
        e.target.closest('.ms-Button'))
    ) {
      e.stopPropagation();
      return;
    }

    // Navigate to the artifact detail page
    if (dealRoomId && organizationSlug) {
      const link = getArtifactLink(organizationSlug, dealRoomId, videoArtifact);

      navigate(link, { replace: false });
    }
  };

  const refreshDealRoom = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries([
        'dealRoom',
        organizationSlug,
        dealRoomId,
      ]);
    }
  };

  const refetchArtifacts = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries(
        OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
      );
    }
  };

  const handleDelete = async () => {
    await refetchArtifacts();
    await refreshDealRoom();
  };

  return (
    <StyledArtifactContainer onClick={handleClick}>
      <StyledActionsContainer className="artifacts-actions-menu">
        <ArtifactsTableActions
          userId={userId}
          dealRoomRole={dealRoomRole}
          organizationSlug={organizationSlug || ''}
          dealRoomId={dealRoomId || 0}
          refreshDealRoom={refreshDealRoom}
          refetchArtifacts={refetchArtifacts}
          onDelete={handleDelete}
          artifact={videoArtifact}
        />
      </StyledActionsContainer>
      <StyledVideoContainer>
        {videoArtifact.fileUrl ? (
          <StyledArtifactVideo
            controls
            src={`${videoArtifact.fileUrl}#t=0.5`}
            preload="auto"
            playsInline
            onClick={(e) => e.stopPropagation()}
          >
            Your browser does not support the video tag.
          </StyledArtifactVideo>
        ) : (
          <ImageWithFallback
            src={videoArtifact.thumbnailUrl || undefined}
            alt={videoArtifact.name || 'Video'}
            fallback={
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ArtifactIconPreview type="VIDEO" mimeType="video/mp4" />
              </Box>
            }
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
          />
        )}
      </StyledVideoContainer>
      <StyledContentContainer>
        <StyledArtifactHeader>
          {videoArtifact.creator && (
            <DSContactAvatar
              contact={videoArtifact.creator}
              size={28}
              sx={{ marginRight: 1 }}
            />
          )}
          <StyledArtifactName>
            {videoArtifact.label ||
              videoArtifact.name ||
              `A Welcome from ${videoArtifact.creator?.name || 'the Team'}`}
          </StyledArtifactName>
        </StyledArtifactHeader>

        {videoArtifact.description && (
          <StyledArtifactDescription variant="body2">
            {videoArtifact.description}
          </StyledArtifactDescription>
        )}

        <StyledArtifactTimestamp variant="caption">
          {formatDate(videoArtifact.createdAt)}
        </StyledArtifactTimestamp>
      </StyledContentContainer>
    </StyledArtifactContainer>
  );
};
