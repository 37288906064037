import { useCallback, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDealRoomActivity } from '../../Analytics/hooks/useDealRoomActivity';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import { Edit, Delete } from '@mui/icons-material';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box
} from '@mui/material';
import {
  KeyFactCard,
  EditButton,
  EditContainer,
  DeleteButton,
  KeyFactTitle,
  KeyFactValue,
  KeyFactMarkdown,
} from './DealRoomKeyFacts.styles';
import { DealRoomFact } from '@meetingflow/common/Api/data-contracts';
import toast from 'react-hot-toast';
import remarkGfm from 'remark-gfm';
import { DealRoomKeyFactCreateMode } from './DealRoomKeyFactCreateMode';
import { DealRoomKeyFactEditMode } from './DealRoomKeyFactEditMode';

interface DealRoomKeyFactCardProps {
  organizationSlug: string | undefined;
  dealRoomId: number | undefined;
  keyFact: DealRoomFact;
  onDelete?: () => void;
  onSave?: (keyFact: DealRoomFact, title: string, value: string) => void;
  onCancel?: () => void;
  dragHandleProps?: React.DOMAttributes<HTMLDivElement>;
  isDragging?: boolean;
  sortedKeyFacts?: DealRoomFact[];
}

export const DealRoomKeyFactCard = ({
  organizationSlug,
  dealRoomId,
  keyFact,
  onDelete,
  onSave,
  onCancel,
  dragHandleProps,
  isDragging,
  sortedKeyFacts,
}: DealRoomKeyFactCardProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const isNew = keyFact.id < 0; // Negative ID indicates a new key fact
  const [isEditing, setIsEditing] = useState(isNew);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { recordActivity } = useDealRoomActivity();
  const { dealRoom, dealRoomRole } = useDealRoom();
  const [isHovered, setIsHovered] = useState(false);

  const handleDeleteKeyFact = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      // Check if organizationSlug and dealRoomId are defined
      if (!organizationSlug || dealRoomId === undefined) {
        console.error('Organization slug or dealRoomId is undefined');
        return;
      }

      await DealRoomsApiClient.deleteKeyFact(
        organizationSlug,
        dealRoomId,
        keyFact.id,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      if (recordActivity && organizationSlug && dealRoomId && dealRoom) {
        recordActivity({
          activityType: 'UPDATE_KEY_DETAILS',
          organizationSlug,
          dealRoomId: dealRoomId,
          metadata: {
            keyFactId: keyFact.id,
            keyFactTitle: keyFact.title,
            decisionSiteId: dealRoomId,
            decisionSiteName: dealRoom?.name,
            action: 'delete',
          },
        });
      }

      toast.success('Key Detail deleted successfully');

      // Call the onDelete callback to refresh the list
      if (onDelete) {
        onDelete();
      }
    } catch (error) {
      toast.error('Failed to delete Key Detail');
    } finally {
      setIsDeleteDialogOpen(false);
    }
  }, [
    getAccessTokenSilently,
    organizationSlug,
    dealRoomId,
    keyFact.id,
    keyFact.title,
    recordActivity,
    onDelete,
    dealRoom,
  ]);

  const handleEditButtonClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  // Determine if the user can edit this key fact
  const canEdit = useMemo(() => {
    return (
      dealRoomRole === 'SELLER' ||
      (dealRoomRole === 'BUYER' && keyFact.editableByBuyer)
    );
  }, [dealRoomRole, keyFact.editableByBuyer]);

  // Determine if the user can delete this key fact (only sellers can delete)
  const canDelete = useMemo(() => {
    return dealRoomRole === 'SELLER';
  }, [dealRoomRole]);

  // Only apply drag handle props if not in edit mode
  const effectiveDragHandleProps = isEditing ? undefined : dragHandleProps;

  // Render based on mode
  if (isNew) {
    return (
      <DealRoomKeyFactCreateMode
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        keyFact={keyFact}
        onSave={(updatedKeyFact, title, value) => {
          if (onSave) {
            onSave(updatedKeyFact, title, value);
          }
        }}
        onCancel={() => {
          if (onCancel) {
            onCancel();
          } else if (onDelete) {
            onDelete();
          }
        }}
      />
    );
  }

  if (isEditing) {
    return (
      <DealRoomKeyFactEditMode
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        keyFact={keyFact}
        onSave={(updatedKeyFact, title, value) => {
          setIsEditing(false);
          if (onDelete) {
            onDelete(); // Refresh the parent component
          }
          if (onSave) {
            onSave(updatedKeyFact, title, value);
          }
        }}
        onCancel={() => setIsEditing(false)}
      />
    );
  }

  // View mode
  return (
    <KeyFactCard
      {...effectiveDragHandleProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-draggable={
        canEdit && !isEditing && sortedKeyFacts && sortedKeyFacts.length > 1
          ? 'true'
          : 'false'
      }
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <KeyFactTitle
          variant="subtitle2"
          sx={{
            position: 'relative',
          }}
        >
          {keyFact.title}
        </KeyFactTitle>
        
        {/* Edit and Delete buttons */}
        {canEdit && !isEditing && (
          <EditContainer
            className="edit-container"
            sx={{
              opacity: isHovered ? 1 : 0,
            }}
          >
            <EditButton
              isActive={false}
              onClick={(e) => {
                e.stopPropagation();
                handleEditButtonClick();
              }}
            >
              <Edit fontSize="small" />
            </EditButton>
            {/* Only show delete button for sellers */}
            {canDelete && !isNew && (
              <DeleteButton
                isActive={false}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteDialogOpen(true);
                }}
              >
                <Delete fontSize="small" />
              </DeleteButton>
            )}
          </EditContainer>
        )}
      </Box>

      <KeyFactValue
        variant="body1"
        isLongText={(keyFact.value || '').length > 12}
        sx={{
          width: '100%',
          marginTop: '0.5rem',
          position: 'relative',
        }}
      >
        {keyFact.value ? (
          <KeyFactMarkdown remarkPlugins={[remarkGfm]}>
            {keyFact.value}
          </KeyFactMarkdown>
        ) : (
          'N/A'
        )}
      </KeyFactValue>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Key Detail</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete "{keyFact.title}"? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDeleteDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleDeleteKeyFact} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </KeyFactCard>
  );
};
