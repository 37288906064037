import {
  Box,
  styled,
  Paper,
  alpha,
  keyframes,
  IconButton,
} from '@mui/material';

export const MomentsContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  height: '100%',
  position: 'relative',
  display: 'flex',
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(0.5),
}));

export const RefreshButtonContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  height: 'fit-content',
  marginLeft: 'auto',
  width: '40px',
  paddingTop: theme.spacing(0.5),
}));

export const RefreshButton = styled(IconButton)(({ theme }) => ({
  width: '40px',
  height: '40px',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.secondary.main,
  boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.04)}, 0 2px 4px ${alpha(theme.palette.common.black, 0.02)}`,
  border: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
  transition: 'all 0.25s ease-in-out',
  animation: `${fadeIn} 0.4s ease-out`,
  '&:hover': {
    boxShadow: `0 8px 20px ${alpha(theme.palette.common.black, 0.05)}, 0 4px 8px ${alpha(theme.palette.common.black, 0.03)}`,
  },
}));

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const RecommendationCard = styled(Paper)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
  boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.04)}, 0 2px 4px ${alpha(theme.palette.common.black, 0.02)}`,
  border: `1px solid ${alpha(theme.palette.secondary.main, 0.6)}`,
  transition: 'all 0.25s ease-in-out',
  animation: `${fadeIn} 0.4s ease-out`,
  '&:hover': {
    boxShadow: `0 8px 20px ${alpha(theme.palette.common.black, 0.05)}, 0 4px 8px ${alpha(theme.palette.common.black, 0.03)}`,
  },
}));

export const RecommendationTitle = styled(Box)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '1.1rem',
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  position: 'relative',
}));

export const RecommendationSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  '&:not(:first-of-type)': {
    paddingTop: theme.spacing(2),
  },
}));

export const SectionContent = styled(Box)(({ theme }) => ({
  fontSize: '0.95rem',
  lineHeight: 1.6,
  color: theme.palette.text.secondary,
}));

export const CallCitationBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  margin: theme.spacing(0.5, 0, 1, 0),
  backgroundColor: alpha(theme.palette.grey[200], 0.5),
  borderRadius: theme.shape.borderRadius,
  borderLeft: `4px solid ${theme.palette.grey[400]}`,
  boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.03)}`,
  '& > div': {
    fontSize: '0.95rem',
    lineHeight: 1.0,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    position: 'relative',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  '&::before': {
    content: '"\\201C"', // Open quote mark
    position: 'absolute',
    left: theme.spacing(1.2),
    top: theme.spacing(1),
    fontSize: '1.5rem',
    lineHeight: 1,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  '&::after': {
    content: '"\\201D"', // Close quote mark
    position: 'absolute',
    right: theme.spacing(1.2),
    bottom: theme.spacing(0),
    fontSize: '1.5rem',
    lineHeight: 1,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
}));

export const List = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(0.5),
  '& > *': {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'flex-start',
  },
}));

export const ListItemDot = styled(Box)(({ theme }) => ({
  width: 6,
  height: 6,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.7),
  marginTop: 8,
  marginRight: theme.spacing(1.5),
  flexShrink: 0,
}));

export const EmptyStateContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: alpha(theme.palette.background.paper, 0.7),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(4),
  minHeight: 240,
  border: `1px dashed ${theme.palette.divider}`,
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 56,
  height: 56,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  marginBottom: theme.spacing(2),
  '& svg': {
    color: theme.palette.primary.main,
    fontSize: 28,
  },
}));
