import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useUserProfile } from '../../Hooks/useProfile';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { UserDetails } from '../../types/BuyerOrientationSurveyTypes';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../Services/NetworkCommon';
import { BuyerOrientationDialog } from './Dialogs/BuyerOrientationDialog';
import { User } from '@meetingflow/common/Api/data-contracts';
import { useWelcomeArtifact } from '../../Hooks/useWelcomeArtifact';
import { useOrganization } from '../../Hooks/useOrganization';

interface DealRoomFirstTimeUserExperienceProps {
  organizationSlug: string;
  dealRoomId: number;
}

export const DealRoomFirstTimeUserExperience = ({
  organizationSlug,
  dealRoomId,
}: DealRoomFirstTimeUserExperienceProps) => {
  const { user } = useUserProfile();
  const { dealRoom, refetch: refetchDealRoomData } = useDealRoom(
    organizationSlug,
    dealRoomId,
  );
  const { isGuest } = useOrganization(organizationSlug);
  const { getAccessTokenSilently } = useAuth0();

  // Find current user's details from deal room contacts
  const currentUserDetails = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return null;
    if (!user?.email) return null;
    return (
      (dealRoom?.contacts?.find(
        (contact) => contact.email.toLowerCase() === user.email.toLowerCase(),
      ) as UserDetails) || null
    );
  }, [dealRoom?.contacts, user?.email]);

  const dealRoomOwner = useMemo(() => {
    if (!dealRoom) return null;
    return dealRoom.owner;
  }, [dealRoom]);

  // Get list of deal room contacts excluding current user
  const dealRoomContacts = useMemo(() => {
    if (!Array.isArray(dealRoom?.contacts)) return [];
    if (!user) return [];

    return dealRoom.contacts
      .filter(
        (contact) => contact.userId !== user.id && contact.email !== user.email,
      )
      .map((contact) => contact.name || 'N/A');
  }, [dealRoom?.contacts, user]);

  const hasWelcomeVideo = useMemo(() => {
    if (!dealRoom) return false;
    if (!dealRoom.artifacts) return false;
    return (
      dealRoom.artifacts.find((artifact) => artifact.placement === 'WELCOME') ??
      false
    );
  }, [dealRoom]);

  const hasWatchedWelcomeVideo = useMemo(() => {
    if (!currentUserDetails) {
      return false;
    }

    return !!currentUserDetails.hasWatchedWelcomeVideo;
  }, [currentUserDetails]);

  const hasCompletedBuyerOrientation = useMemo(() => {
    if (!currentUserDetails) {
      return false;
    }

    return !!currentUserDetails.hasCompletedBuyerOrientation;
  }, [currentUserDetails]);

  const setHasWatchedWelcomeVideo = useCallback(async () => {
    if (!currentUserDetails) return;

    const token = await getAccessTokenSilently();

    const dataToUpdate = {
      role: currentUserDetails.role || null,
      hasWatchedWelcomeVideo: true,
    };

    try {
      await DealRoomsApiClient.updateDealRoomContact(
        organizationSlug!,
        dealRoomId!,
        currentUserDetails.email,
        dataToUpdate,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (refetchDealRoomData) {
        await refetchDealRoomData();
      }
    } catch (err) {
      console.error('Error updating welcome video status:', err);
    }
  }, [
    currentUserDetails,
    dealRoomId,
    getAccessTokenSilently,
    organizationSlug,
    refetchDealRoomData,
  ]);

  const setHasCompletedBuyerOrientation = useCallback(async () => {
    if (!currentUserDetails) return;

    const token = await getAccessTokenSilently();

    const dataToUpdate = {
      role: currentUserDetails.role || null,
      hasCompletedBuyerOrientation: true,
    };

    try {
      await DealRoomsApiClient.updateDealRoomContact(
        organizationSlug!,
        dealRoomId!,
        currentUserDetails.email,
        dataToUpdate,
        { headers: { Authorization: `Bearer ${token}` } },
      );

      if (refetchDealRoomData) {
        await refetchDealRoomData();
      }
    } catch (err) {
      console.error('Error updating welcome video status:', err);
    }
  }, [
    currentUserDetails,
    dealRoomId,
    getAccessTokenSilently,
    organizationSlug,
    refetchDealRoomData,
  ]);

  if (!user) {
    return null;
  }
  if (!currentUserDetails) {
    return null;
  }
  if (!dealRoomOwner) {
    return null;
  }
  if (!currentUserDetails.role) {
    return null;
  }
  if (currentUserDetails.role !== 'BUYER' && !isGuest) {
    return null;
  }

  const displayWelcomeVideo = hasWelcomeVideo && !hasWatchedWelcomeVideo;
  const displayBuyerOrientation = !hasCompletedBuyerOrientation;

  if (!displayWelcomeVideo && !displayBuyerOrientation) {
    return null;
  }

  return (
    <DealRoomFirstTimeUserExperienceInner
      organizationSlug={organizationSlug}
      dealRoomId={dealRoomId}
      dealRoomOwner={dealRoomOwner}
      dealRoomContacts={dealRoomContacts}
      currentUser={user}
      currentUserDetails={currentUserDetails}
      displayWelcomeVideo={displayWelcomeVideo}
      displayBuyerOrientation={displayBuyerOrientation}
      setHasWatchedWelcomeVideo={setHasWatchedWelcomeVideo}
      setHasCompletedBuyerOrientation={setHasCompletedBuyerOrientation}
    />
  );
};

interface DealRoomFirstTimeUserExperienceInnerProps {
  organizationSlug: string;
  dealRoomId: number;
  dealRoomOwner: User;
  dealRoomContacts: string[];
  currentUser: User;
  currentUserDetails: UserDetails;
  displayWelcomeVideo: boolean;
  displayBuyerOrientation: boolean;
  isConfigurationTabActive?: boolean; // for the case when we show the dialog from  the configuration tab
  refetchDealRoomData?: () => Promise<void>;
  setHasWatchedWelcomeVideo: () => Promise<void>;
  setHasCompletedBuyerOrientation: () => Promise<void>;
}
export const DealRoomFirstTimeUserExperienceInner = ({
  organizationSlug,
  dealRoomId,
  dealRoomOwner,
  dealRoomContacts,
  currentUser,
  currentUserDetails,
  displayWelcomeVideo,
  displayBuyerOrientation,
  isConfigurationTabActive,
  refetchDealRoomData,
  setHasWatchedWelcomeVideo,
  setHasCompletedBuyerOrientation,
}: DealRoomFirstTimeUserExperienceInnerProps) => {
  const { welcomeVideo } = useWelcomeArtifact({
    organizationSlug,
    dealRoomId,
  });

  const [showWelcomeVideo, setShowWelcomeVideo] = useState<boolean>(false);
  const [showBuyerOrientation, setShowBuyerOrientation] =
    useState<boolean>(false);

  useEffect(() => {
    setShowBuyerOrientation(
      displayBuyerOrientation &&
        (!displayWelcomeVideo || (!!isConfigurationTabActive && !welcomeVideo)),
    );
  }, [
    displayBuyerOrientation,
    isConfigurationTabActive,
    welcomeVideo,
    displayWelcomeVideo,
  ]);

  const handleBuyerOrientationDismiss = useCallback(async () => {
    setShowBuyerOrientation(false);
    setHasCompletedBuyerOrientation();
  }, [setHasCompletedBuyerOrientation]);

  if (!currentUser) {
    return null;
  }
  if (!currentUserDetails) {
    return null;
  }
  if (!dealRoomOwner) {
    return null;
  }
  if (!currentUserDetails.role) {
    return null;
  }

  if (showBuyerOrientation) {
    return (
      <BuyerOrientationDialog
        handleDismiss={handleBuyerOrientationDismiss}
        ownerAvatarUrl={
          dealRoomOwner?.avatarFileUrl || dealRoomOwner?.avatarUrl
        }
        ownerName={dealRoomOwner.firstName || dealRoomOwner.name || ''}
        userName={currentUser.name ?? ''}
        currentUserDetails={currentUserDetails}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        dealRoomContacts={dealRoomContacts}
        userEmail={currentUser.email}
        userRole={currentUserDetails.role}
        refetchDealRoomData={refetchDealRoomData}
      />
    );
  }
};
