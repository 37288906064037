import {
  Box,
  Button,
  Collapse,
  IconButton,
  Typography,
  Divider,
  Stack,
  Tooltip,
  Badge,
} from '@mui/material';
import useMilestonesActionItemsFiltersStore from '../../../../Stores/milestonesActionItemsFiltersStore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { StatusFilter } from './StatusFilter';
import { OwnerFilter } from './OwnerFilter';
import { DateRangeFilter } from './DateRangeFilter';
import { FilterList, FilterListOff } from '@mui/icons-material';
import useDeviceType from '../../../../Hooks/deviceDetection';
import { DSConfigurationMutualPlanCreateMilestoneButton } from '../../Configuration/Pages/DSConfigurationMutualPlan/DSConfigurationMutualPlanCreateMilestoneDialog';
import { DSMutualPlanApplyTemplateButton } from './MutualPlanTemplate/DSMutualPlanApplyTemplateButton';
import {
  OrganizationMutualPlanQuery,
  OrganizationMilestonesSummaryQuery,
} from '../../../../QueryNames';
import { QueryClient } from 'react-query';

type MilestonesActionItemsFiltersProps = {
  organizationSlug: string;
  dealRoomId: number;
  isSeller?: boolean;
  queryClient?: QueryClient;
};

export const MilestonesActionItemsFilters = ({
  organizationSlug,
  dealRoomId,
  isSeller = false,
  queryClient,
}: MilestonesActionItemsFiltersProps) => {
  const { isMobile } = useDeviceType();
  const [filtersExpanded, setFiltersExpanded] = useState(false);

  // Store values and setters
  const selectedStatuses = useMilestonesActionItemsFiltersStore(
    (state) => state.selectedStatuses,
  );
  const startDate = useMilestonesActionItemsFiltersStore(
    (state) => state.startDate,
  );
  const endDate = useMilestonesActionItemsFiltersStore(
    (state) => state.endDate,
  );
  const selectedOwnerEmails = useMilestonesActionItemsFiltersStore(
    (state) => state.selectedOwnerEmails,
  );
  const resetFilters = useMilestonesActionItemsFiltersStore(
    (state) => state.resetFilters,
  );

  // Reset all filters to initial values
  const handleResetFilters = useCallback(() => {
    resetFilters();
  }, [resetFilters]);

  // Check if any filter is active
  const hasActiveFilters = useCallback(() => {
    return (
      selectedStatuses.length > 0 ||
      startDate !== null ||
      endDate !== null ||
      selectedOwnerEmails.length > 0
    );
  }, [selectedStatuses, startDate, endDate, selectedOwnerEmails]);

  // Get active filter count
  const activeFilterCount = useMemo(() => {
    let count = 0;
    if (selectedStatuses.length > 0) count++;
    if (startDate !== null || endDate !== null) count++;
    if (selectedOwnerEmails.length > 0) count++;
    return count;
  }, [selectedStatuses, startDate, endDate, selectedOwnerEmails]);

  // Auto-expand filters if any are active
  useEffect(() => {
    if (hasActiveFilters() && !filtersExpanded) {
      setFiltersExpanded(true);
    }
  }, [hasActiveFilters, filtersExpanded]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, [resetFilters]);

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      }}
    >
      {/* Filter header with toggle button */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '0.75rem 1.5rem',
        }}
      >
        {isSeller && queryClient && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              flexWrap: 'wrap',
            }}
          >
            <DSConfigurationMutualPlanCreateMilestoneButton
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
              refetchMilestones={() =>
                queryClient.invalidateQueries(
                  OrganizationMilestonesSummaryQuery(
                    organizationSlug,
                    dealRoomId,
                  ),
                )
              }
              refetchMutualPlan={() =>
                queryClient.invalidateQueries(
                  OrganizationMutualPlanQuery(organizationSlug, dealRoomId),
                )
              }
            />

            {isSeller && (
              <DSMutualPlanApplyTemplateButton
                organizationSlug={organizationSlug}
                dealRoomId={dealRoomId}
                refetchMilestones={() =>
                  queryClient.invalidateQueries(
                    OrganizationMilestonesSummaryQuery(
                      organizationSlug,
                      dealRoomId,
                    ),
                  )
                }
                refetchMutualPlan={() =>
                  queryClient.invalidateQueries(
                    OrganizationMutualPlanQuery(organizationSlug, dealRoomId),
                  )
                }
              />
            )}
          </Box>
        )}

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: hasActiveFilters() ? 600 : 400,
              color: hasActiveFilters()
                ? DEALROOMS_COLORS.themePrimary
                : DEALROOMS_COLORS.themeSecondary,
            }}
          >
            {hasActiveFilters()
              ? `Filtered by ${activeFilterCount} criteria`
              : 'Filter tasks'}
          </Typography>
          <Tooltip title={filtersExpanded ? 'Hide filters' : 'Show filters'}>
            <IconButton
              onClick={() => setFiltersExpanded(!filtersExpanded)}
              size="small"
              sx={{
                color: hasActiveFilters()
                  ? DEALROOMS_COLORS.themePrimary
                  : DEALROOMS_COLORS.themeSecondary,
                backgroundColor: hasActiveFilters()
                  ? 'rgba(0, 120, 212, 0.08)'
                  : 'transparent',
                '&:hover': {
                  backgroundColor: hasActiveFilters()
                    ? 'rgba(0, 120, 212, 0.12)'
                    : 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <FilterList fontSize="small" />
            </IconButton>
          </Tooltip>
          {hasActiveFilters() && (
            <Button
              variant="text"
              size="small"
              onClick={handleResetFilters}
              sx={{
                fontSize: '0.75rem',
                textTransform: 'none',
                color: DEALROOMS_COLORS.themePrimary,
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                },
                ml: 1,
              }}
            >
              Clear all
            </Button>
          )}
        </Box>
      </Box>

      {/* Collapsible filter content */}
      <Collapse in={filtersExpanded}>
        <Box
          sx={{
            padding: '0.5rem 1.5rem 1.25rem',
            backgroundColor: 'rgba(0, 0, 0, 0.01)',
            borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
          }}
        >
          <Stack
            direction={isMobile ? 'column' : 'row'}
            gap="1rem"
            sx={{
              alignItems: isMobile ? 'stretch' : 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            <StatusFilter />
            <OwnerFilter
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
            />
            <DateRangeFilter />
          </Stack>
        </Box>
      </Collapse>
    </Box>
  );
};
