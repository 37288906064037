import {
  FontIcon,
  FontSizes,
  FontWeights,
  IconButton,
  NeutralColors,
  Text,
  TextField,
  TooltipHost,
  mergeStyles,
  PrimaryButton,
} from '@fluentui/react';
import {
  DetailedMeetingflow,
  MeetingflowSummaryType,
  CallRecording,
} from '@meetingflow/common/Api/data-contracts';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import useSummarizeMeetingflowDialog from '../../Hooks/Modals/useSummarizeMeetingflowDialog';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { AsyncLink } from '../HOC/AsyncLink';
import { Duration } from 'luxon';

// Format seconds into a human-readable timestamp (HH:MM:SS)
const formatSeconds = (seconds: number) =>
  Duration.fromObject({ seconds }).toFormat('h:mm:ss');

interface MeetingPlanNotesSummaryFormProps {
  organizationSlug: string;
  meetingplan: Pick<DetailedMeetingflow, 'id' | 'title' | 'callRecording'>;
  summaryType?: MeetingflowSummaryType;
  displaySubject?: boolean;
  subjectLabel?: string;
  bodyLabel?: string;
  summarizeDisabled?: boolean;
  initialSummaryValue?: string;
  onSubjectChange?: (value: string) => void;
  onSummaryGenerated?: (value: string) => void;
  onSummaryChange?: (value: string) => void;
  onSummaryBlur?: (value: string) => void;
  surfaceName: string;
  hideLabels?: boolean;
  hideAllGpt?: boolean;
  disabledMessage?: string;
  summarizeLabel?: string;
  summarizeButtonTooltipTitle?: string;
  summarizeButtonTooltipContent?: string;
}

const MeetingPlanNotesSummaryForm = ({
  organizationSlug,
  meetingplan,
  summaryType,
  displaySubject = true,
  subjectLabel = 'Subject',
  bodyLabel = 'Body',
  summarizeLabel = 'Suggest content',
  summarizeDisabled,
  initialSummaryValue,
  onSubjectChange,
  onSummaryChange,
  onSummaryGenerated,
  onSummaryBlur,
  hideLabels = false,
  hideAllGpt = false,
  disabledMessage,
}: MeetingPlanNotesSummaryFormProps) => {
  const { hasEntitlement } = useOrganization();

  const {
    createDeferred: createSummarizeMeetingflowDeferred,
    dialog: summarizeMeetingflowDialog,
  } = useSummarizeMeetingflowDialog({
    organizationSlug,
    meetingflowId: meetingplan.id,
  });

  const [subject, setSubject] = useState<string>('');
  const [summary, setSummary] = useState<string>(initialSummaryValue || '');

  const { isDark } = useLightOrDarkMode();

  useEffect(() => {
    if (meetingplan) {
      setSubject(`Follow-up: ${meetingplan.title}`);
    }
  }, [meetingplan]);

  const notesSummaryFormClass = mergeStyles({
    marginBottom: '.5rem',
    '.prompt': {
      paddingTop: '.5rem',
      transition: '.3s ease-in-out all',
      '.ms-Label': {
        fontSize: FontSizes.smallPlus,
      },
    },

    '.summarize': {
      '.summarize-button': {
        float: 'right',
      },
      '.new-summarize-button': {
        display: 'inline-block',
        fontSize: FontSizes.small,
        fontWeight: FontWeights.semibold,
        height: '1.5rem',
        lineHeight: '1.5rem',

        ':disabled': {
          opacity: '.25',
          'i, span': {
            color: isDark ? NeutralColors.gray80 : NeutralColors.gray160,
          },
        },

        i: {
          float: 'right',
          display: 'inline-block',
          fontSize: FontSizes.large,
          color: MEETINGFLOW_COLORS.teal,
          fontWeight: FontWeights.regular,
          height: '1.5rem',
          width: '1.5rem',
          lineHeight: '1.5rem',
          marginLeft: '.25rem',
        },

        span: {
          display: 'inline-block',
          height: '1.5rem',
          lineHeight: '1.5rem',
          position: 'relative',
          top: '1px',
          color: isDark
            ? MEETINGFLOW_COLORS.purpleSecondary
            : MEETINGFLOW_COLORS.purplePrimary,
        },
      },
    },

    '.subject': {
      position: 'relative',

      '.subject-input': {
        input: {
          border: isDark
            ? `1px solid ${NeutralColors.gray180}`
            : `1px solid rgba(200, 198, 196)`,
          borderRadius: '.25rem',
          backgroundColor: isDark
            ? NeutralColors.gray220
            : MEETINGFLOW_COLORS.white,
        },
      },
    },

    '.summary': {
      position: 'relative',
      '.summary-input': {
        '.ms-TextField-fieldGroup': {
          width: '100%',
          border: 'none',

          textarea: {
            minHeight: '16rem',
            fontSize: '12px',
            lineHeight: '1.2rem',

            padding: '.5rem 2rem .5rem .5rem',
            width: '100%',
            height: 'auto',
            boxSizing: 'border-box',
            border: isDark
              ? `1px solid ${NeutralColors.gray180}`
              : `1px solid rgba(200, 198, 196)`,
            borderRadius: '.25rem',
            flexDirection: 'column',
            overflowWrap: 'break-word',
            overflow: 'auto',
            backgroundColor: isDark
              ? NeutralColors.gray220
              : MEETINGFLOW_COLORS.white,

            animationName: 'fadeInAnimation',
            animationDuration: '.3s',
            transitionTimingFunction: 'linear',
            animationIterationCount: '1',
            animationFillMode: 'forwards',
            transition: '.5s ease-in-out all',

            ':disabled': {
              color: 'white !important',
              backgroundColor: MEETINGFLOW_COLORS.teal,
              fontWeight: FontWeights.semibold,
              boxShadow: '0 0 10px inset rgba(0,0,0,.1)',
              cursor: 'not-allowed',

              border: isDark
                ? `5px solid ${MEETINGFLOW_COLORS.purpleGrey}`
                : `5px solid ${MEETINGFLOW_COLORS.purpleGrey}`,
            },
          },
        },
      },
    },

    '.gpt-controls': {
      transition: '.3s ease-in-out all',
      borderRadius: '.25rem',
      display: !hideAllGpt ? 'block' : 'none',
      backgroundColor: 'transparent',
      padding: '.25rem .25rem .25rem 0',
      margin: '.25rem 0',

      '.disclosure-link': {
        cursor: 'pointer',
        fontSize: FontSizes.small,
        color: isDark ? NeutralColors.gray120 : NeutralColors.gray140,

        i: {
          fontSize: FontSizes.xSmall,
          color: isDark ? NeutralColors.gray120 : NeutralColors.gray140,
          marginRight: '.25rem',
        },
      },

      '.content': {
        transition: '.3s ease-in-out all',
        maxHeight: '0',
        opacity: 0,
        // backgroundColor: MEETINGFLOW_COLORS.purpleGrey,
        padding: '0 .25rem',
      },
    },
  });

  return (
    <div className={notesSummaryFormClass}>
      {displaySubject ? (
        <div className="subject">
          <IconButton
            iconProps={{ iconName: 'Copy' }}
            onClick={() => {
              navigator.clipboard.writeText(subject);
              toast.success(`Copied ${subjectLabel} to the clipboard.`);
            }}
            styles={{
              root: {
                width: '1rem',
                height: '1rem',
                position: 'absolute',
                top: '2rem',
                right: '.25rem',
                zIndex: '500',
              },
              icon: { fontSize: '10px' },
            }}
            title="Copy subject to clipboard"
          />
          <TextField
            className="subject-input"
            label={hideLabels ? undefined : subjectLabel}
            value={subject}
            onChange={(_e, newValue) => {
              setSubject(newValue ?? '');
              onSubjectChange?.(newValue ?? '');
            }}
            placeholder={subjectLabel}
          />
        </div>
      ) : null}

      <div className="summary">
        {navigator.clipboard && !summarizeDisabled ? (
          <IconButton
            iconProps={{ iconName: 'Copy' }}
            onClick={() => {
              navigator.clipboard.writeText(summary);
              toast.success(`Copied ${bodyLabel} to the clipboard.`);
            }}
            styles={{
              root: {
                width: '1rem',
                height: '1rem',
                position: 'absolute',
                top: '2rem',
                right: '1rem',
                zIndex: '500',
              },
              icon: { fontSize: '10px' },
            }}
            title="Copy body to clipboard"
          />
        ) : null}
        <TextField
          className="summary-input"
          errorMessage={
            disabledMessage && summarizeDisabled ? disabledMessage : undefined
          }
          // Ignore because I want some JSX here, which works fine, but TS wants it to be a string. - jcroft
          // @ts-ignore
          label={
            <div>
              {hideLabels ? undefined : bodyLabel}
              {hasEntitlement('CHAT_GPT') && !hideAllGpt ? (
                <TooltipHost
                  content={
                    <>
                      <Text
                        variant="small"
                        block
                        style={{
                          fontWeight: FontWeights.semibold,
                          marginBottom: '.5rem',
                          color: MEETINGFLOW_COLORS.white,
                        }}
                      >
                        GPT-based Summarization
                      </Text>
                      <Text
                        variant="xSmall"
                        block
                        style={{
                          marginBottom: '.5rem',
                          color: MEETINGFLOW_COLORS.white,
                        }}
                      >
                        Have GPT automatically generate a draft based on your
                        notes. You can use it as a starting point, edit it as
                        you see fit, or delete it entirely if it doesn't work
                        for you.
                      </Text>
                    </>
                  }
                  styles={{
                    root: {
                      position: 'relative',
                      top: '-1px',
                      float: 'right',
                    },
                  }}
                  calloutProps={{
                    backgroundColor: MEETINGFLOW_COLORS.teal,
                    styles: {
                      root: {
                        padding: 0,
                        maxWidth: '18rem',
                        color: MEETINGFLOW_COLORS.white,
                      },
                      calloutMain: {
                        padding: '.5rem',
                        color: MEETINGFLOW_COLORS.white,
                      },
                    },
                  }}
                >
                  <AsyncLink
                    className={'new-summarize-button'}
                    onClick={async () => {
                      if (summarizeDisabled) {
                        return;
                      }
                      const generated =
                        await createSummarizeMeetingflowDeferred({
                          summaryType: summaryType!,
                        }).promise;
                      setSummary(generated.summary);
                      onSummaryGenerated?.(generated.summary);
                      onSummaryChange?.(generated.summary);
                    }}
                  >
                    <FontIcon iconName="AISparkle" />
                    <span>{summarizeLabel}</span>
                  </AsyncLink>
                </TooltipHost>
              ) : null}
            </div>
          }
          value={summary}
          multiline
          onChange={(_e, newValue) => {
            setSummary(newValue ?? '');
            onSummaryChange?.(newValue ?? '');
          }}
          onBlur={(_e) => {
            onSummaryBlur && onSummaryBlur(summary);
          }}
        />
      </div>

      {['MEETING_SUMMARY', 'SUGGESTED_NOTES', 'PRE_MEETING_EMAIL', 'FOLLOWUP_EMAIL', 'REENGAGEMENT_EMAIL'].includes(summaryType as string) && (
        <div style={{ marginTop: '1rem' }}>
          <PrimaryButton
            text="Add Call Insights"
            onClick={() => {
              const transcriptAnalysis = meetingplan?.callRecording?.transcriptAnalysis?.valueOf() as CallRecording['transcriptAnalysis'];
              if (transcriptAnalysis?.topics?.length) {
                // Create a base URL for linking to specific timestamps in the recording
                const externalShareBaseUrl = `${window.location.origin}/share/${organizationSlug}/${meetingplan.id}?recordingTimestamp=`;

                // Format topics as HTML list items with links to timestamps
                const topicsText = transcriptAnalysis.topics.map((topic, idx) => {
                  const topicNumber = idx + 1;
                  const topicTitle = topic.title;
                  const topicTimestamp = formatSeconds(topic.timestamp);
                  const topicSummary = topic.summary;
                  const topicUrl = `${externalShareBaseUrl}${topic.timestamp}`;

                  return `${topicNumber}. ${topicTitle} (${topicTimestamp})\n
   ${topicUrl}\n
   ${topicSummary}
`;
                }).join('\n');

                const meetingTopicsHeader = '\nMeeting Topics:\n';
                const topicsContent = meetingTopicsHeader + topicsText;

                setSummary(summary + topicsContent);
                onSummaryChange?.(summary + topicsContent);
              } else {
                toast.error('No topics available in transcript analysis');
              }
            }}
            disabled={!meetingplan?.callRecording?.transcriptAnalysis}
          />
        </div>)}

      {summarizeMeetingflowDialog}
    </div>
  );
};

export default MeetingPlanNotesSummaryForm;
